import { HTMLAttributes, ReactNode } from 'react'
import './FlexxibleCard.component.scss'
import { Card } from '@flexxibleit/flexxible-ui'

interface Props {
  cardProps?: HTMLAttributes<HTMLDivElement>
  styleNames?: string
  children: ReactNode
}

const FlexxibleCard = (props: Props) => {
  return (
    <Card cardProps={props.cardProps} styleNames={props.styleNames}>
      {props.children}
    </Card>
  )
}

export default FlexxibleCard
