import { Dropdown, IDropdownOption, IDropdownStyles, Separator } from '@fluentui/react'
import { DateTime } from 'luxon'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import graphClient from '../../../query-client/graphClient'
import { getReportsQuery } from '../../../query-client/queries/reportQuery'
import { Business, ProductConfig } from '../../../query-client/types'
import { ReportsChart } from '../../../components/reports-chart/reports-chart.component'
import StatusBadge, { BadgeMode } from 'app/components/status-badge/status-badge.component'
import FlexxibleCard from 'app/components/FlexxibleCard/FlexxibleCard.component'
import { getProductIcon } from 'app/services/product-icon.service'
import useMediaQuery from 'app/hooks/utils/useMediaQuery'
import { MOBILE_QUERY } from 'app/config/media-query'
import { dateFormatter } from 'app/services/date-formatter'

interface Props {
  isAdmin: boolean,
  organizationId: string,
  product: ProductConfig,
  organization: Business,
}

const dropdownStylesDesktop: Partial<IDropdownStyles> = {
  root: {
    width: 400
  }
}

const dropdownStylesMobile: Partial<IDropdownStyles> = {
  root: {
    width: '100%',
    marginTop: 20
  }
}

export const ExplorerContent = ({ isAdmin, organization, organizationId, product }: Props) => {
  const { t } = useTranslation('reports');
  const navigate = useNavigate()
  const [ isMobile ] = useMediaQuery(MOBILE_QUERY)
  const defaultStartDate = DateTime.now().minus({ days: 30 }).startOf('day')
  const defaultEndDate = DateTime.now().endOf('day')
  const [ dropdownSelected, setDropdownSelected ] = useState<string>(product._id || '')
  const [ startDate, setStartDate ] = useState(defaultStartDate)
  const [ endDate, setEndDate ] = useState(defaultEndDate)
  const [ isLoading, setIsLoading ] = useState(false)
  const [ reportList, setReportList ] = useState(undefined)

  useEffect(() => {
    setStartDate(defaultStartDate)
    setEndDate(defaultEndDate)
    fetchReports(defaultStartDate, defaultEndDate)
  }, [product])

  const fetchReports = async (startDate: DateTime, endDate: DateTime) => {
    setIsLoading(true)

    graphClient
      .request(getReportsQuery, {
        filter: {
          minDate: `${startDate.toMillis()}`,
          maxDate: `${endDate.toMillis()}`,
          productConfig: product._id,
        },
      })
      .then(
        (data: any) => {
          setReportList(data.getReports)
          setIsLoading(false)
        },
        (error) => console.log(error)
      )
  }

  const getProductReports = (filterStartDate: DateTime, filterEndDate: DateTime) => {
    setStartDate(filterStartDate)
    setEndDate(filterEndDate)

    fetchReports(filterStartDate, filterEndDate)
  }


  let dropdownOptions: IDropdownOption[] = organization.productConfigs.filter(productConfig => productConfig.active).map((productConfig) => ({
    key: productConfig._id,
    text: productConfig.product.name,
    data: { environment: productConfig.environment}
  }))

  const onDropdownChange = (event?: React.FormEvent<HTMLDivElement>, item?: IDropdownOption) => {
    setDropdownSelected(String(item?.key))
    isAdmin ? navigate(`/organizations/${organizationId}/${item?.key}`) : navigate(`/products/${item?.key}`)
  }

  const onRenderOption = (option?: IDropdownOption): JSX.Element => {

    return (
      <div className={isMobile ? "d-flex d-flexCol": "d-flex d-flexRow" }>
        <span>{option?.text}</span>
        { option?.data.environment &&
          <span style={isMobile? { fontSize: 11 } : { fontSize: 11, marginLeft: 4 }} >
            ({t('ENVIRONMENT_LABEL')}: {option?.data.environment})
          </span>
        }
      </div>
    )
  }

  const onRenderTitle = (options?: IDropdownOption[]): JSX.Element => {
    const option = options?.shift()

    return (
      <>
        <span>{option?.text}</span>
        { option?.data.environment &&
          <span style={{ fontSize: 11, marginLeft: 4 }} >
            ({t('ENVIRONMENT_LABEL')}: {option?.data.environment})
          </span>
        }
      </>
    );
  }

  return (
    <>
    { product && (
      <>
        <div className={isMobile ? 'd-flex d-flexCol' : 'd-flex d-flexRow d-flexAlignItemsCenter d-flexRowReverse'}>
          <Dropdown
            selectedKey={dropdownSelected}
            onChange={onDropdownChange}
            placeholder="Select an option"
            onRenderOption={onRenderOption}
            onRenderTitle={onRenderTitle}
            options={dropdownOptions}
            styles={isMobile? dropdownStylesMobile : dropdownStylesDesktop}
          ></Dropdown>
        </div>
        <div style={{ marginTop: 39, paddingBottom: 10 }}>
          <FlexxibleCard>
            <div className="d-flex d-flexRow d-flexAlignItemsCenter">
              <div className="d-flexCol d-flexGrow1">
                <div>{ t('ENVIRONMENT_LABEL') }</div>
                <div style={{ fontWeight: 'bold'}}>{ product.environment }</div>
              </div>

              { !isMobile && (
                <>
                  <div className="d-flexCol d-flexGrow1">
                    <div>{ t('PRODUCT_TYPE_LABEL') }</div>
                    <span className="d-flex d-flexAlignItemsCenter">
                      <span className="d-flex d-flexAlignItemsCenter" style={{ marginRight: 5}}>
                        { getProductIcon(product.product.name, 18, 18) }
                      </span>
                      <span style={{ fontWeight: 'bold'}}>{ product.product.name }</span>
                    </span>
                  </div>

                  <div className="d-flexCol d-flexGrow1">
                    <div>{ t('CREATION_DATE') }</div>
                    <div style={{ fontWeight: 'bold'}}>{ dateFormatter(t, product.created_at) }</div>
                  </div>

                  {isAdmin && (
                    <div className="d-flexCol d-flexGrow1">
                      <div>{ t('STATUS') }</div>
                      <div style={{ fontWeight: 'bold'}}>
                        <StatusBadge active={product.active} mode={BadgeMode.circle} size={8}/>
                      </div>
                    </div>
                  )}
                </>
              )}
            </div>
            <Separator />
            <ReportsChart
                baseline={product.baseline}
                reports={reportList || []}
                showTable={true}
                startDate={startDate}
                endDate={endDate}
                showFilters={true}
                isLoading={isLoading}
                onFilterChange={getProductReports}/>
          </FlexxibleCard>
        </div>
      </>
      )}
    </>
  )
}
