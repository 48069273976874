import { ContentHeader } from "app/components/content-header/content-header"
import { PRODUCT_ENV } from 'app/config/Consts'
import { ProductEnvTypes } from 'app/config/productEnv.enum'
import { useOrganizationSelection } from 'app/globals/useOrganizationSelection'
import { useUpdateEnabledMicroserviceExecutionSettings } from 'app/hooks/microservices/useUpdateEnabledMicroserviceExecutionSettings'
import { useCreateFormV2 } from 'app/hooks/useCreateForm'
import { MicroserviceTargetGroup } from 'app/models/microservices'
import { MicroserviceUserExecution } from 'app/pages/microservices/details/target-groups/MicroserviceEndUser'
import { MicroserviceTargetGroups } from 'app/pages/microservices/details/target-groups/MicroserviceTargetGroup'
import MicroserviceTargetGroupForm from 'app/pages/microservices/details/target-groups/MicroserviceTargetGroupForm'
import { useTranslation } from 'react-i18next'

interface Props {
  microservice: {
    _id: string
    workspaceGroupExecution: MicroserviceTargetGroup
    individualExecution: MicroserviceTargetGroup
    endUserExecutionWorkspaceGroups: string[]
  }
  canEdit: boolean
}

export const MicroserviceOrganizationTargetsTab = ({ microservice, canEdit }: Props) => {
  const { t } = useTranslation('microservices')
  const { selectedOrganizationId } = useOrganizationSelection()
  const { mutateAsync } = useUpdateEnabledMicroserviceExecutionSettings()
  const isFxxOne = PRODUCT_ENV === ProductEnvTypes.FXXONE

  const onSubmitEdit = async (data: {
    workspaceGroupExecution: MicroserviceTargetGroup
    individualExecution: MicroserviceTargetGroup
  }) => {
    try {
      await mutateAsync({
        microserviceId: microservice._id,
        organizationId: selectedOrganizationId ?? '',
        input: {
          workspaceGroupExecution: data.workspaceGroupExecution,
          individualExecution: data.individualExecution,
        },
      })
      setSuccess({
        message: t('DESIGNER.MICROSERVICE_FORM.UPDATE_SUCCESS'),
      })
    } catch (error) {
      setError({
        message: t('DESIGNER.MICROSERVICE_FORM.UPDATE_ERROR'),
      })
    }
  }

  const {
    open: openEditForm,
    setError,
    setSuccess,
  } = useCreateFormV2(
    {
      title: t('DETAILS.EXECUTION.EDIT_ORGANIZATION_TITLE'),
      isFooterAtBottom: true,
    },
    <MicroserviceTargetGroupForm
      initialData={{
        workspaceGroupExecution: microservice.workspaceGroupExecution,
        individualExecution: microservice.individualExecution,
      }}
      microserviceId={microservice._id}
      onSubmit={onSubmitEdit}
    />
  )

  return (
    <>
      <div style={{ marginTop: 20, marginLeft: 10 }}>
        <ContentHeader title={t('DETAILS.EXECUTION.TARGET_GROUP_TITLE')} />
      </div>
      <MicroserviceTargetGroups microservice={microservice} canEdit={canEdit} onEdit={openEditForm} />

      <>
        <div style={{ marginTop: 40, marginLeft: 10 }}>
          <ContentHeader title={t('DETAILS.EXECUTION.END_USER_TITLE')} />
        </div>
        <MicroserviceUserExecution microservice={microservice} canEdit={canEdit} />
      </>
    </>
  )
}