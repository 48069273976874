
export enum Languages {
  'es-ES' = 'es-ES',
  en = 'en',
  'pt-BR' = 'pt-BR',
  ca = 'ca',
  eu = 'eu',
}

const defaultLanguages: Record<string, Languages> = {
  es: Languages['es-ES'],
  pt: Languages['pt-BR'],
}

export interface MultiLanguageField {
  code: Languages
  text: string
  translatedByIA: boolean
}

export const getPreferredLanguage = (language: string) => {
  const shortCode = new Intl.Locale(language).language

  const resolvedLanguage = defaultLanguages[shortCode] ?? shortCode

  return resolvedLanguage
}

export const getTranslation = (field: MultiLanguageField[], language: string) => {
  const resolvedLanguage = getPreferredLanguage(language)

  return field.find((i: MultiLanguageField) => i.code === resolvedLanguage) ?? { text: '', translatedByIA: false }
}

export const createMultiLanguageFields = () => {
  return Object.values(Languages).map((code) => ({
    code,
    text: '',
    translatedByIA: false,
  }))
}