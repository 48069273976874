import { useMutation, useQueryClient } from '@tanstack/react-query'
import { gql } from 'graphql-request'
import graphClient from '../../query-client/graphClient'

interface ArchiveMicroservicePayload {
  _id: string
}

interface Response {
  archiveMicroservice: ArchiveMicroservicePayload
}

const archiveMicroserviceQuery = gql`
  mutation archiveMicroservice($id: ID!) {
    archiveMicroservice(id: $id) {
      _id
    }
  }
`

const archiveMicroservice = async (data: string): Promise<ArchiveMicroservicePayload> => {
  return graphClient.request(archiveMicroserviceQuery, { id: data }).then((res: Response) => res.archiveMicroservice)
}

export const useArchiveMicroservice = () => {
  const queryClient = useQueryClient()

  return useMutation(['archiveMicroservice'], (id: string) => archiveMicroservice(id), {
    onSuccess: (id) => {
      queryClient.invalidateQueries(['microservices'])
      queryClient.invalidateQueries(['microservices', id])
      queryClient.invalidateQueries(['enabled-microservices'])
    },
  })
}
