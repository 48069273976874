import { useMutation, useQueryClient } from '@tanstack/react-query'
import graphClient from 'app/query-client/graphClient'
import { gql } from 'graphql-request'

interface UpdateMicroserviceLicenseRequest {
  microserviceId: string
  input: {
    license: string
  }
}

const query = gql`
  mutation ($input: UpdateMicroserviceLicenseGraphqlRequest!, $microserviceId: ID!) {
    updateMicroserviceLicense(id: $microserviceId, input: $input) {
      _id
    }
  }
`

const updateMicroserviceLicense = async (request: UpdateMicroserviceLicenseRequest): Promise<void> => {
  const response = await graphClient.request(query, request)

  return response.updateMicroserviceLicense._id
}

export const useUpdateMicroserviceLicense = () => {
  const queryClient = useQueryClient()

  return useMutation(
    ['updateMicroserviceLicense'],
    ({ microserviceId, input }: UpdateMicroserviceLicenseRequest) => updateMicroserviceLicense({ microserviceId, input }),
    {
      onSuccess: (microserviceId) => {
        queryClient.invalidateQueries(['microservices'])
        queryClient.invalidateQueries(['microservices', microserviceId])
      },
    }
  )
}
