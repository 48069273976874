import graphClient from 'app/query-client/graphClient'
import { gql } from 'graphql-request'
import { User } from '../../query-client/types'
import { useQuery } from '@tanstack/react-query'

const query = gql`
  query Request($businessId: ID!) {
    getBusinessUsers(businessId: $businessId) {
      _id
      surname
      name
      userRole
      email
      department
      language
    }
  }
`

function getBusinessUsers(businessId: string) {
  return graphClient.request(query, { businessId }).then((response) => {
    return (response.getBusinessUsers as User[]) || []
  })
}

export default function (businessId: string) {
  return useQuery(['businessUsers', businessId], () => getBusinessUsers(businessId), {
    refetchOnWindowFocus: false,
    enabled: false,
  })
}
