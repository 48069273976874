import { useMutation, useQueryClient } from "@tanstack/react-query"
import graphClient from "app/query-client/graphClient"
import { gql } from "graphql-request"

interface UpdateMicroserviceReadmeRequest {
  microserviceId: string
  input: {
    readme: string
  }
}

const query = gql`
  mutation ($input: UpdateMicroserviceReadmeGraphqlRequest!, $microserviceId: ID!) {
    updateMicroserviceReadme(id: $microserviceId, input: $input) {
      _id
    }
  }
`

const updateMicroserviceReadme = async (request: UpdateMicroserviceReadmeRequest): Promise<void> => {
  const response = await graphClient.request(query, request)

  return response.updateMicroserviceReadme._id
}

export const useUpdateMicroserviceReadme = () => {
  const queryClient = useQueryClient()

  return useMutation(
    ['updateMicroserviceReadme'],
    ({ microserviceId, input }: UpdateMicroserviceReadmeRequest) => updateMicroserviceReadme({ microserviceId, input }),
    {
      onSuccess: (microserviceId) => {
        queryClient.invalidateQueries(['microservices'])
        queryClient.invalidateQueries(['microservices', microserviceId])
      },
    }
  )
}