import { getPaginatedQueryParamsBlock, pageInfoBlock, paginatedVarsBlock } from 'app/config/pagination'
import { gql } from 'graphql-request'

export const useWorkspaceGroupsPaginatedQuery = () => {
  const queryName = 'getWorkspaceGroupsByBusinessPaginated'
  const query = gql`
  query Request(
    $businessId: ID!
    ${getPaginatedQueryParamsBlock('WorkspaceGroupFilter', 'WorkspaceGroupSorting')}
  ) {
    getWorkspaceGroupsByBusinessPaginated(
      businessId: $businessId
      ${paginatedVarsBlock}
    ) {
      data {
          workspaceGroup {
          _id
          name
          type
        }
        workspaceCount
      }
      ${pageInfoBlock}
    }
  }
`
  return {
    query,
    queryName,
  }
}
