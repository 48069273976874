import { useTranslation } from 'react-i18next'
import { LoadingSpinner, MOBILE_QUERY, useMediaQuery } from '@flexxibleit/flexxible-ui'
import { PatchesStatusByPatchPolicyColumnChart } from './components/PatchesStatusByPatchPolicyColumnChart'
import { PatchesBySeverityAndStatusChart } from './components/PatchesBySeverityAndStatusChart'
import { DashboardMetricCard } from '../../../components/Dashboard/DashboardMetricCard/DashboardMetricCard'
import useGetPatchesDashboard from '../../../hooks/patches/useGetPatchesDashboard'
import { useEffect, useState } from 'react'
import { PatchesDashboard } from '../../../query-client/types'
import { MessageBar } from '@fluentui/react'

interface Props {
  organizationId: string | undefined
}

export const PatchesDashboardPage = (props: Props) => {
  const { t } = useTranslation('patch_management')
  const [isMobile] = useMediaQuery(MOBILE_QUERY)
  const [patchesDashboard, setPatchesDashboard] = useState<PatchesDashboard>({
    totalAvailablePatches: 0,
    totalApprovedPatches: 0,
    totalNotApprovedPatches: 0,
    totalApprovedPatchesByPatchPolicy: [],
    totalNotApprovedPatchesByPatchPolicy: [],
    patchesBySeverityAndStatus: [],
  })

  const { data: patchesDashboardData, isLoading } = useGetPatchesDashboard(props.organizationId)

  useEffect(() => {
    if (!isLoading && patchesDashboardData) {
      setPatchesDashboard(patchesDashboardData)
    }
  }, [patchesDashboardData])

  if (isLoading) {
    return <LoadingSpinner />
  }

  return (
    <>
      <div
        style={{
          display: 'flex',
          alignItems: 'stretch',
          justifyContent: 'center',
          gap: 20,
          flexWrap: 'wrap',
          marginTop: '20px',
        }}
      >
        <MessageBar messageBarType={0} isMultiline={true} className="mb-2" style={{ width: '100%' }}>
          {t('DASHBOARD.TABS.PATCHES_TAB.INFORMATION_BAR')}
        </MessageBar>
        <DashboardMetricCard
          title={t('DASHBOARD.TABS.PATCHES_TAB.METRIC_CARDS.TOTAL_PATCHES_TITLE')}
          tooltip={t('DASHBOARD.TABS.PATCHES_TAB.METRIC_CARDS.TOTAL_PATCHES_TITLE_TOOLTIP')}
          value={patchesDashboard.totalAvailablePatches.toLocaleString()}
          icon={{
            name: 'UpgradeAnalysis',
          }}
        />
        <DashboardMetricCard
          title={t('DASHBOARD.TABS.PATCHES_TAB.METRIC_CARDS.TOTAL_APPROVED_PATCHES')}
          tooltip={t('DASHBOARD.TABS.PATCHES_TAB.METRIC_CARDS.TOTAL_APPROVED_PATCHES_TOOLTIP')}
          value={patchesDashboard.totalApprovedPatches.toLocaleString()}
          icon={{
            name: 'CheckMark',
            color: 'green',
          }}
        />
        <DashboardMetricCard
          title={t('DASHBOARD.TABS.PATCHES_TAB.METRIC_CARDS.TOTAL_NOT_APPROVED_PATCHES')}
          tooltip={t('DASHBOARD.TABS.PATCHES_TAB.METRIC_CARDS.TOTAL_NOT_APPROVED_PATCHES_TOOLTIP')}
          value={patchesDashboard.totalNotApprovedPatches.toLocaleString()}
          icon={{
            name: 'Warning',
            color: 'orange',
          }}
        />
      </div>
      <div
        style={{
          display: 'flex',
          marginTop: 20,
          flexDirection: isMobile ? 'column' : 'row',
          alignItems: 'stretch',
          justifyContent: 'space-around',
          gap: 20,
        }}
      >
        <PatchesStatusByPatchPolicyColumnChart
          totalApprovedPatchesByPatchPolicy={patchesDashboard.totalApprovedPatchesByPatchPolicy}
          totalNotApprovedPatchesByPatchPolicy={patchesDashboard.totalNotApprovedPatchesByPatchPolicy}
        />
        <PatchesBySeverityAndStatusChart patchesBySeverityAndStatus={patchesDashboard.patchesBySeverityAndStatus} />
      </div>
    </>
  )
}
