import { ICommandItem } from '@flexxibleit/flexxible-ui'
import { PaginatedEligibleProduct } from 'app/query-client/types'
import ElegibleProductsForm from './ElegibleProductsForm'
import { TFunction } from 'react-i18next'
import useCreateForm from 'app/hooks/useCreateForm'
import { useFeatureRender } from '../../../permissions/useFeatureRender'
import { FEATURE_NAMES } from '../../../permissions/FeatureName.enum'

type UseOrgEligibleProductsActionsProps = {
  t: TFunction
  organizationId: string
  eligibleProducts: PaginatedEligibleProduct[]
  onExportClicked: () => void
}

export const useOrgEligibleProductsActions = ({
  t,
  organizationId,
  eligibleProducts,
  onExportClicked,
}: UseOrgEligibleProductsActionsProps) => {
  const { checkFeature } = useFeatureRender()

  const openUpdateElegibleProductForm = (item: PaginatedEligibleProduct) => {
    return useCreateForm(
      {
        title: t('ELEGIBLE_PRODUCTS.FORM.DETAILS_TITLE'),
        isFooterAtBottom: true,
      },
      <ElegibleProductsForm
        allElegibleProducts={eligibleProducts}
        elegibleProduct={item}
        isCreate={false}
        organizationId={organizationId}
      />
    )
  }

  const onAddElegibleProductClicked = useCreateForm(
    {
      title: t('ELEGIBLE_PRODUCTS.FORM.ADD_TITLE'),
      isFooterAtBottom: true,
    },
    <ElegibleProductsForm allElegibleProducts={eligibleProducts} organizationId={organizationId} isCreate={true} />
  )

  const commandBarActions: ICommandItem[] = checkFeature(FEATURE_NAMES.ELIGIBLE_PRODUCT_CREATE)
    ? [
        {
          key: 'create',
          text: t('general:BUTTON.CREATE'),
          iconProps: { iconName: 'Add' },
          onClick: () => onAddElegibleProductClicked(),
        },
        {
          key: 'export',
          text: t('general:BUTTON.EXPORT'),
          iconProps: { iconName: 'ExcelDocument' },
          onClick: onExportClicked,
        },
      ]
    : []

  return {
    openUpdateElegibleProductForm,
    onAddElegibleProductClicked,
    commandBarActions,
  }
}
