import { ColumnActionsMode, DetailsList, IColumn, SelectionMode } from '@fluentui/react'
import EditButton from 'app/components/Buttons/EditButton.component'
import { DisplayField } from 'app/components/DisplayField'
import FlexxibleCard from 'app/components/FlexxibleCard/FlexxibleCard.component'
import Col from 'app/components/Layouts/Col'
import { MOBILE_QUERY } from 'app/config/media-query'
import { useOrganizationSelection } from 'app/globals/useOrganizationSelection'
import useCreateForm from 'app/hooks/useCreateForm'
import useMediaQuery from 'app/hooks/utils/useMediaQuery'
import useWorkspaceGroupsByOrganization from 'app/hooks/workspaceGroup/useWorkspaceGroupsByOrganization'
import { MicroserviceEndUserForm } from 'app/pages/microservices/details/target-groups/MicroserviceEndUserForm'
import { WorkspaceGroupWithWorkspaceCount } from 'app/query-client/types'
import { useTranslation } from 'react-i18next'

interface Props {
  microservice: {
    _id: string
    endUserExecutionWorkspaceGroups: string[]
  }
  canEdit: boolean
}

export const MicroserviceUserExecution = ({ microservice, canEdit }: Props) => {
  const { t } = useTranslation('microservices')
  const { selectedOrganizationId } = useOrganizationSelection()
  const { data } = useWorkspaceGroupsByOrganization(selectedOrganizationId ?? '')
  const [isMobile] = useMediaQuery(MOBILE_QUERY)

  const columns: IColumn[] = [
    {
      key: 'name',
      name: t('organization_details:WORKSPACE_GROUPS.TABLE.NAME'),
      fieldName: 'name',
      minWidth: 100,
      isRowHeader: true,
      isPadded: true,
      onRender: ({ workspaceGroup }: WorkspaceGroupWithWorkspaceCount) => {
        return <span>{workspaceGroup.name}</span>
      },
      columnActionsMode: ColumnActionsMode.disabled,
    },
    {
      key: 'workspaceCount',
      name: t('organization_details:WORKSPACE_GROUPS.TABLE.NUMBER_OF_WORKSPACES'),
      fieldName: 'workspaceCount',
      isPadded: true,
      minWidth: isMobile ? 20 : 100,
      isRowHeader: true,
      onRender: ({ workspaceCount }: WorkspaceGroupWithWorkspaceCount) => {
        return <span>{workspaceCount}</span>
      },
      columnActionsMode: ColumnActionsMode.disabled,
    },
  ]

  const openEditForm = useCreateForm(
    {
      title: t('DETAILS.EXECUTION.EDIT_END_USER_TITLE'),
      isFooterAtBottom: true,
    },
    <MicroserviceEndUserForm
      initialData={{
        endUserExecutionWorkspaceGroups: microservice.endUserExecutionWorkspaceGroups,
        enabled: microservice.endUserExecutionWorkspaceGroups.length > 0,
      }}
      microserviceId={microservice._id}
    />
  )

  const workspaceGroups =
    data?.filter((item) => microservice.endUserExecutionWorkspaceGroups.includes(item.workspaceGroup._id)) ?? []
  return (
    <FlexxibleCard cardProps={{ style: { marginTop: 30 } }}>
      <Col className="col-xs-12 col-lg-6">
        <DisplayField label={t('DETAILS.EXECUTION.END_USER_INPUT_LABEL')}>
          {microservice.endUserExecutionWorkspaceGroups.length
            ? t('MICROSERVICE_STATUS.ACTIVE')
            : t('MICROSERVICE_STATUS.INACTIVE')}
        </DisplayField>
      </Col>

      {microservice.endUserExecutionWorkspaceGroups.length > 0 && (
        <Col className="col-xs-12 col-lg-6">
          <DisplayField label={t('DETAILS.EXECUTION.ENABLED_WORKSPACE_GROUPS_TITLE')}>
            <DetailsList columns={columns} items={workspaceGroups} selectionMode={SelectionMode.none} />
          </DisplayField>
        </Col>
      )}

      <div className="d-flex d-flexRowReverse">
        {canEdit ? <EditButton onClick={openEditForm} style={{ marginLeft: '20px' }} /> : null}
      </div>
    </FlexxibleCard>
  )
}
