import { FieldTypes, FilterField } from '@flexxibleit/flexxible-ui'
import { useLocalStorageSearchParams } from 'app/hooks/utils/useLocalStorageSearchParams'
import { TFunction } from 'react-i18next'

type UseOrgProductsFilters = {
  t: TFunction
}

export const useOrgProductsFilters = ({ t }: UseOrgProductsFilters) => {
  useLocalStorageSearchParams()

  const filterFields: FilterField[] = [
    {
      field: 'environment',
      text: t('PRODUCTS.TABLE.ENVIRONMENT'),
      type: FieldTypes.STRING,
    },
    {
      field: 'name',
      text: t('PRODUCTS.TABLE.PRODUCT_TYPE'),
      type: FieldTypes.STRING,
    },
    {
      field: 'region',
      text: t('PRODUCTS.TABLE.REGION'),
      type: FieldTypes.STRING,
    },
    {
      field: 'active',
      text: t('PRODUCTS.TABLE.STATUS'),
      type: FieldTypes.BOOLEAN,
      options: [
        { key: 'true', text: t('general:STATUS.ACTIVE') },
        { key: 'false', text: t('general:STATUS.INACTIVE') },
      ],
    }
  ]

  return {
    filterFields,
  }
}
