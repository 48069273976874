import { TFunction } from 'i18next'

export const useWorkspaceGroupsBuildExport = (t: TFunction) => {
  const userTableExportColumns = [
    {
      key: 'name',
      parent: 'workspaceGroup',
      header: t('WORKSPACE_GROUPS.TABLE.NAME'),
      width: 10,
    },
    {
      key: 'type',
      parent: 'workspaceGroup',
      header: t('WORKSPACE_GROUPS.TABLE.TYPE'),
      width: 10,
    },
    {
      key: 'workspaceCount',
      header: t('WORKSPACE_GROUPS.TABLE.NUMBER_OF_WORKSPACES'),
      width: 10,
    },
  ]
  return { userTableExportColumns }
}
