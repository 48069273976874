import { useQuery, UseQueryResult } from '@tanstack/react-query'
import { gql } from 'graphql-request'
import graphClient from '../../../../../query-client/graphClient'
import { InstalledAppVersion } from '../InstalledAppVersion'

const getQuery = (): { queryName: string, query: string } => {
  const queryName = 'getInstalledAppVersions'
  const query = gql`
    query Request($installedAppId: ID!) {
      getExportableInstalledAppVersions(installedAppId: $installedAppId) {
        version
        workspacesCount
        discoveredAt
        reportedAt
      }
    }
  `

  return { query, queryName }
}

const getInstalledAppVersions = (installedAppId: string): Promise<InstalledAppVersion[]> => {
  const { query } = getQuery()
  return graphClient.request(query, { installedAppId }).then((response) => {
    return response.getExportableInstalledAppVersions ?? []
  })
}

export const useInstalledAppVersions = (installedAppId: string): UseQueryResult<InstalledAppVersion[]> => {
  return useQuery(['getInstalledAppVersions', installedAppId], () => getInstalledAppVersions(installedAppId), {
    refetchOnWindowFocus: false,
    enabled: false,
  })
}