import { LoadingSpinner } from '@flexxibleit/flexxible-ui'
import Highcharts, { PointOptionsObject } from 'highcharts/highstock'
import { useParams } from 'react-router-dom'
import { useGetWorkspaceGroupMetrics } from '../../../hooks/workspaceGroup/useGetWorkspaceGroupMetrics'
import { chartColors } from '../../../components/chart/ChartColors.constants'
import { WorkspaceGroupMetric } from '../../../query-client/types'
import useMediaQuery from '../../../hooks/utils/useMediaQuery'
import { MOBILE_QUERY } from '../../../config/media-query'
import { ChartComponent } from '../../../components/chart/Chart.component'
import { useMemo } from 'react'
import EmptyStatus from '../../../components/status/empty-status'
import { useTranslation } from 'react-i18next'

export const WorkspacesByDayChart = () => {
  const { workspaceGroupId } = useParams()
  const { data: workspaceGroupMetrics, isLoading } =
    useGetWorkspaceGroupMetrics(workspaceGroupId || '')
  const { t } = useTranslation('organization_details')
  const [isMobile] = useMediaQuery(MOBILE_QUERY)
  const buildChartData = (): PointOptionsObject[] => {
    const MAX_METRICS_TO_SHOW_ON_CHART = 30
    return (
      workspaceGroupMetrics
        ?.sort((a: WorkspaceGroupMetric, b: WorkspaceGroupMetric) => {
          return Number(a.created_at) - Number(b.created_at)
        })
        .slice(-MAX_METRICS_TO_SHOW_ON_CHART)
        .map((workspaceGroupMetric: WorkspaceGroupMetric) => {
          const date = new Date(Number(workspaceGroupMetric.created_at))
          date.setHours(0, 0, 0, 0)

          return {
            x: date.getTime(),
            y: workspaceGroupMetric.workspaceCount,
          }
        }) || []
    )
  }
  const chartData: PointOptionsObject[] = useMemo(buildChartData, [
    workspaceGroupMetrics,
  ])
  const chartOptions: Highcharts.Options = {
    chart: {
      type: 'column',
      zooming: {
        type: 'x',
      },
    },
    time: {
      useUTC: false,
    },
    title: {
      text: undefined,
    },
    colors: chartColors,
    credits: {
      enabled: false,
    },
    series: [
      {
        name: t(
          'WORKSPACE_GROUPS.WORKSPACE_GROUP_METRICS.WORKSPACES_BY_DAY_Y_AXIS'
        ),
        type: 'column',
        data: chartData,
        color: '#FF6500',
      },
    ],
    xAxis: {
      title: {
        text: t(
          'WORKSPACE_GROUPS.WORKSPACE_GROUP_METRICS.WORKSPACES_BY_DAY_X_AXIS'
        ),
        margin: 20,
      },
      type: 'datetime',
      zoomEnabled: true,
      startOnTick: true,
      endOnTick: true,
      labels: {
        formatter: (ctx: any) => {
          const date = new Date(ctx.value)
          const day = date.getDate()
          const month = date.toLocaleString('default', { month: 'short' })
          const nthNumber = (day: number) => {
            if (day > 3 && day < 21) return 'th'
            switch (day % 10) {
              case 1:
                return 'st'
              case 2:
                return 'nd'
              case 3:
                return 'rd'
              default:
                return 'th'
            }
          }
          const suffix = nthNumber(day)

          return `${month} ${day}${suffix}`
        },
      },
    },
    yAxis: {
      title: {
        text: t(
          'WORKSPACE_GROUPS.WORKSPACE_GROUP_METRICS.WORKSPACES_BY_DAY_Y_AXIS'
        ),
      },
      stackLabels: {
        enabled: true,
        style: {
          fontWeight: 'bold',
          color: 'gray',
        },
      },
      allowDecimals: false,
    },
    legend: {
      enabled: false,
    },
  }

  const renderLoading = () => {
    return (
      <div style={{ height: 300 }}>
        <LoadingSpinner />
      </div>
    )
  }
  const renderChart = () => {
    if (chartData.length === 0) {
      return (
        <div style={{ margin: 20 }}>
          <EmptyStatus
            message={t(
              'WORKSPACE_GROUPS.WORKSPACE_GROUP_METRICS.NO_HISTORY_DATA'
            )}
          />
        </div>
      )
    }

    return (
      <>
        <ChartComponent options={chartOptions} />
      </>
    )
  }

  return (
    <div
      style={{
        padding: 20,
        marginTop: 20,
        backgroundColor: 'white',
        boxShadow: '0 0 5px 0 rgba(0,0,0,0.1)',
      }}
    >
      <div
        className={
          isMobile
            ? 'd-flex d-flexCol'
            : 'd-flex d-flexRow d-flexAlignItemsCenter d-flexJustifyBetween'
        }
      >
        <div
          style={{
            margin: '20px 10px',
            fontSize: 17,
            fontWeight: 700,
            color: '#8F9193',
          }}
        >
          {t('WORKSPACE_GROUPS.WORKSPACE_GROUP_METRICS.WORKSPACES_BY_DAY')}
        </div>
      </div>
      {isLoading ? renderLoading() : renderChart()}
    </div>
  )
}
