import { EmptyStatus } from '@flexxibleit/flexxible-ui'
import EmptyElegibleProduct from '../../../assets/img/empty_white_box.svg'
import { TFunction } from 'react-i18next'
import { useFeatureRender } from '../../../permissions/useFeatureRender'
import { FEATURE_NAMES } from '../../../permissions/FeatureName.enum'

type EmptyOrgEligibleProductsProps = {
  t: TFunction
  onAddElegibleProductClicked: () => void
}

export const EmptyOrgEligibleProducts = ({ t, onAddElegibleProductClicked }: EmptyOrgEligibleProductsProps) => {
  const { checkFeature } = useFeatureRender()

  return (
    <div style={{ marginTop: 100 }}>
      <EmptyStatus
        img={EmptyElegibleProduct}
        message={t('ELEGIBLE_PRODUCTS.EMPTY_MESSAGE')}
        {...(checkFeature(FEATURE_NAMES.ELIGIBLE_PRODUCT_CREATE) && {
          buttonProps: {
            text: t('general:BUTTON.CREATE'),
            iconProps: { iconName: 'Add' },
            onClick: onAddElegibleProductClicked,
          },
        })}
      />
    </div>
  )
}
