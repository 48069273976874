import { roleFeature, RoleName } from '../RoleFeature.enum'
import { FEATURE_NAME } from '../FeatureName.enum'
import { RolesOnBusiness } from '../../models/user/RolesOnBusiness'
import { authorizationExceptions } from './AuthorizationExceptions'

export const authorizeWithPortalRole = (portalRole: RoleName, feature: FEATURE_NAME) => {
  return roleFeature[portalRole].includes(feature)
}

export const authorizeFeature = function (rolesOnBusiness: RolesOnBusiness, feature: FEATURE_NAME) {
  if (!authorizeWithPortalRole(rolesOnBusiness.portal as RoleName, feature)) {
    return false
  }

  if (authorizationExceptions[feature] !== undefined) {
    return authorizationExceptions[feature]?.(rolesOnBusiness)
  }

  return true
}
