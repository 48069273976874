import { MOBILE_QUERY } from 'app/config/media-query'
import useMediaQuery from 'app/hooks/utils/useMediaQuery'
import { FEATURE_NAMES } from 'app/permissions/FeatureName.enum'
import { useFeatureRender } from 'app/permissions/useFeatureRender'
import { withFeatureRender } from 'app/permissions/withFeatureRender'
import { UserRole } from 'app/query-client/types'
import { i18n } from 'i18next'
import { useEffect, useState } from 'react'
import { TFunction } from 'react-i18next'
import { useSearchParams } from 'react-router-dom'
import { ViewDetailsActionButton } from '@flexxibleit/flexxible-ui'
import { ColumnActionsMode } from '@fluentui/react'
import { IPaginatedTableColumn } from '@flexxibleit/flexxible-ui/dist/lib/components/paginated-table/paginatedTable.types'
import { CellWithFeatureLink } from 'app/components/Table/CellWithFeatureLink'
import { dateFormatter } from 'app/services/date-formatter'
import { CellWithFeatureGreenLink } from 'app/components/table-page-body/CellWithFeatureGreenLink'

type UseReportingGroupRolesBuildTableProps = {
  t: TFunction
  i18n: i18n
  onViewDetails: (role: UserRole) => void
}

export const useReportingGroupRolesBuildTable = ({ t, i18n, onViewDetails }: UseReportingGroupRolesBuildTableProps) => {
  const [isMobile] = useMediaQuery(MOBILE_QUERY)
  const [columns, setColumns] = useState<IPaginatedTableColumn[]>([])
  const [searchParams] = useSearchParams()

  const { checkFeature } = useFeatureRender()
  const tableActionsFeatures = [FEATURE_NAMES.ROLE_READ]
  const canSeeActions = tableActionsFeatures.some((feature) => checkFeature(feature))

  useEffect(() => {
    setColumns([
      {
        key: 'name',
        name: t('ROLES.TABLE.NAME'),
        fieldName: 'name',
        minWidth: 20,
        isRowHeader: true,
        isPadded: true,
        columnActionsMode: ColumnActionsMode.disabled,
        onRender: (item: UserRole) => {
          return (
            <CellWithFeatureGreenLink feature={FEATURE_NAMES.ROLE_READ} callback={() => onViewDetails(item)}>
              {item.name}
            </CellWithFeatureGreenLink>
          )
        },
      },
      {
        key: 'assignedUsers',
        name: t('ROLES.TABLE.ASSIGNED_USERS'),
        fieldName: 'assignedUsers',
        minWidth: 20,
        maxWidth: 400,
        isRowHeader: true,
        isPadded: true,
        columnActionsMode: ColumnActionsMode.disabled,
      },
      {
        key: 'assignedOrganizations',
        name: t('ROLES.TABLE.ASSIGNED_ORGANIZATIONS'),
        fieldName: 'assignedOrganizations',
        minWidth: 20,
        maxWidth: 400,
        isRowHeader: true,
        isPadded: true,
        columnActionsMode: ColumnActionsMode.disabled,
      },
      {
        key: 'created_by',
        name: t('ROLES.TABLE.CREATED_BY'),
        fieldName: 'created_by',
        minWidth: 20,
        isRowHeader: true,
        isPadded: true,
        columnActionsMode: ColumnActionsMode.disabled,
        onRender: (item: UserRole) => {
          if (!item.created_by) {
            return <span>{t('ROLES.TABLE.USER_DOES_NOT_EXIST')}</span>
          }
          return (
            <span>
              {item?.created_by?.name} {item?.created_by?.surname}
            </span>
          )
        },
      },
      {
        key: 'updated_by',
        name: t('ROLES.TABLE.UPDATED_BY'),
        fieldName: 'updated_by',
        minWidth: 20,
        isRowHeader: true,
        isPadded: true,
        columnActionsMode: ColumnActionsMode.disabled,
        onRender: (item: UserRole) => {
          if (!item.updated_by) {
            return <span>{t('ROLES.TABLE.USER_DOES_NOT_EXIST')}</span>
          }
          return (
            <span>
              {item?.updated_by?.name} {item?.updated_by?.surname}
            </span>
          )
        },
      },

      {
        key: 'created_at',
        name: t('ROLES.TABLE.CREATED_AT'),
        fieldName: 'created_at',
        minWidth: 20,
        isRowHeader: true,
        isPadded: true,
        columnActionsMode: ColumnActionsMode.disabled,
        onRender: (item: UserRole) => {
          return <span>{dateFormatter(t, item.created_at)}</span>
        },
      },

      {
        key: 'updated_at',
        name: t('ROLES.TABLE.UPDATED_AT'),
        fieldName: 'updated_at',
        minWidth: 20,
        isRowHeader: true,
        isPadded: true,
        columnActionsMode: ColumnActionsMode.disabled,
        onRender: (item: UserRole) => {
          return <span>{dateFormatter(t, item.created_at)}</span>
        },
      },
      ...(canSeeActions
        ? [
            {
              key: 'actions',
              name: t('ROLES.TABLE.ACTION'),
              minWidth: 20,
              onRender: (item: UserRole) => {
                return (
                  <span className="d-flex d-flexRow">
                    <span style={isMobile ? { marginRight: 10 } : { marginRight: 20 }}>
                      {withFeatureRender(
                        ViewDetailsActionButton,
                        FEATURE_NAMES.ROLE_READ
                      )({
                        locale: i18n.language,
                        isMobile: isMobile,
                        onClick: () => onViewDetails(item),
                      })}
                    </span>
                  </span>
                )
              },
              isPadded: true,
              columnActionsMode: ColumnActionsMode.disabled,
            },
          ]
        : []),
    ])
  }, [searchParams])

  return {
    columns,
  }
}
