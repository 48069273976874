import { useMutation, useQueryClient } from '@tanstack/react-query'
import { WorkspaceGroupLocationFormType } from 'app/pages/organization-details/workspace-groups/location/WorspaceGroupLocationForm'
import graphClient from 'app/query-client/graphClient'
import { gql } from 'graphql-request'

const query = gql`
  mutation ($input: WorkspaceGroupBaseUpdateLocationInput!, $workspaceGroupId: ID!) {
    updateWorkspaceGroupLocation(input: $input, id: $workspaceGroupId) {
      _id
    }
  }
`

const updateWorkspaceGroupLocation = async (workspaceGroupId: string, input: WorkspaceGroupLocationFormType) => {
  const response = await graphClient.request(query, {
    workspaceGroupId,
    input,
  })
  return response.updateWorkspaceGroupLocation
}

export const useUpdateWorkspaceGroupLocation = () => {
  const queryClient = useQueryClient()

  return useMutation(
    ['updateWorkspaceGroupLocation'],
    ({ workspaceGroupId, input }: { workspaceGroupId: string; input: WorkspaceGroupLocationFormType }) =>
      updateWorkspaceGroupLocation(workspaceGroupId, input),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['getWorkspaceGroupsByBusiness'])
        queryClient.invalidateQueries(['getWorkspaceGroupById'])
      },
    }
  )
}
