import { IPivotItemProps, IPivotProps, IPivotStyles, IRenderFunction, Pivot, PivotItem } from '@fluentui/react'
import { MOBILE_QUERY } from 'app/config/media-query'
import useMediaQuery from 'app/hooks/utils/useMediaQuery'
import { FunctionComponent } from 'react'
import { StringParam, useQueryParam } from 'use-query-params'

export interface Tab {
  component?: JSX.Element
  title: string
  key: string
}

export interface TabPanelProps {
  tabs: Tab[]
  pivotProps?: IPivotProps
  onRenderItemLink?: IRenderFunction<IPivotItemProps>
  queryParameterName?: string
  onLinkClick?: (item?: PivotItem) => void
}
export const TAB_QUERY_PARAMETER_NAME = 'tab'

export const TabPanel: FunctionComponent<TabPanelProps> = (props: TabPanelProps) => {
  const [tabQueryParameter, setTabQueryParameter] = useQueryParam(
    props.queryParameterName ?? TAB_QUERY_PARAMETER_NAME,
    StringParam
  )
  const [isMobile] = useMediaQuery(MOBILE_QUERY)

  const pivotStyle: Partial<IPivotStyles> = {
    link: {
      marginLeft: 0,
    },
  }

  const pivotStyleMobile: Partial<IPivotStyles> = {
    link: {
      padding: '0 4px',
      margin: 0,
    },
  }

  const handlePivotChange = (item?: PivotItem) => {
    setTabQueryParameter(item?.props.itemKey, !props.queryParameterName ? 'replace' : undefined)
  }

  return (
    <Pivot
      overflowBehavior={'menu'}
      onLinkClick={props.onLinkClick ? props.onLinkClick : handlePivotChange}
      styles={isMobile ? pivotStyleMobile : pivotStyle}
      selectedKey={tabQueryParameter}
      {...props.pivotProps}
    >
      {props.tabs.map((tab: Tab) => {
        return (
          <PivotItem headerText={tab.title} itemKey={tab.key} key={tab.key} onRenderItemLink={props.onRenderItemLink}>
            {tab.component}
          </PivotItem>
        )
      })}
    </Pivot>
  )
}
