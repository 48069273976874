export const useOrgTenantsBuildExport = () => {
  const tenantTableExportColumns = [
    {
      key: 'action',
      header: 'Action',
      width: 14,
      formulae: [`"Add/Update,Delete"`],
    },
    {
      key: 'name',
      header: 'Name',
      width: 20,
    },
    {
      key: 'product_name',
      header: 'Product',
      width: 20,
    },
    {
      key: 'policy_name',
      header: 'Policy',
      width: 20,
    },
    {
      key: 'created_at',
      header: 'Creation date',
      width: 10,
    },
  ]

  return { tenantTableExportColumns }
}
