import LogoFlexxible from '../../assets/img/logo-negative.svg'
import './HeaderBar.scss'
import { FontIcon } from '@fluentui/react/lib/Icon'
import { PersonaComponent } from '../persona/Persona.component'
import { PersonaSize } from '@fluentui/react/lib/Persona'
import { useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { LogoutFeature } from '../user-context-menu/feature/Logout.feature'
import { MobileUserContextMenu } from '../user-context-menu/mobile/MobileUserContextMenu.component'
import { ContextMenuButton } from '../user-context-menu/user-context-menu-button/ContextMenuButton'
import { useTranslation } from 'react-i18next'
import useMediaQuery from '../../hooks/utils/useMediaQuery'
import { MOBILE_QUERY, TABLET_QUERY } from '../../config/media-query'
import { SidebarOrganizationSelector } from '../sidebar/organization-selector/SidebarOrganizationSelector.component'
import { useGetOrganizationsForSelection } from '../../hooks/organization/useGetOrganizationsForSelection'
import { useMyOrganization } from '../../hooks/organization/useMyOrganization'
import { AppSwitcher } from '../app-switcher/AppSwitcher'
import { roleLocalizationKeyDictionary } from '../../permissions/RoleFeature.enum'
import { useMe } from '../../hooks/me/useMe'
import { useOrganizationSelection } from 'app/globals/useOrganizationSelection'
import { FeatureRender } from '../../permissions/FeatureRender'
import { FEATURE_NAMES } from '../../permissions/FeatureName.enum'
import { FlexxibleUniqueCode } from '../../permissions/OrganizationCodes'
import { useFeatureRender } from '../../permissions/useFeatureRender'

interface Props {
  onOpen: () => void
}

export const HeaderBar = ({ onOpen }: Props) => {
  const { t } = useTranslation()
  const [userMenuOpen, setUserMenuOpen] = useState(false)
  const [isMobile] = useMediaQuery(MOBILE_QUERY)
  const [isTablet] = useMediaQuery(TABLET_QUERY)
  const { data: organizationList } = useGetOrganizationsForSelection(false)
  const { data: userOrganization } = useMyOrganization()
  const navigate = useNavigate()
  const userMenuRef = useRef<HTMLDivElement>(null)
  const { data: user } = useMe()
  const { rolesOnBusiness } = useOrganizationSelection()
  const { checkFeature } = useFeatureRender()

  const isFlexxibleUser = user?.business?.uniqueCode === FlexxibleUniqueCode

  const renderCommonMenu = () => {
    return (
      <MobileUserContextMenu open={userMenuOpen}>
        <FeatureRender feature={FEATURE_NAMES.ALL_ORGANIZATION_READ}>
          <ContextMenuButton name={t('organizations:TITLE')} icon="Org" onClick={() => navigate('/organizations')} />
        </FeatureRender>
        {checkFeature(FEATURE_NAMES.BUILD_OPERATIONS_LOG_READ) && isFlexxibleUser && (
          <ContextMenuButton
            name={'Operation logs'}
            key={'orgaflexxbuild-operations-logsnizations'}
            icon={'History'}
            onClick={() => {
              navigate('/flexxbuild-operations-logs')
            }}
          />
        )}

        <ContextMenuButton name={t('user_logins:TITLE')} icon="FollowUser" onClick={() => navigate('/logins')} />
        <ContextMenuButton name={t('general:SETTINGS')} icon="settings" onClick={() => navigate('/settings')} />
        <LogoutFeature />
      </MobileUserContextMenu>
    )
  }

  const renderUserMenu = () => {
    if (isMobile || isTablet) {
      return (
        <div className="header-bar__persona" onClick={() => setUserMenuOpen(!userMenuOpen)}>
          <button className="header-bar__persona__button">
            <PersonaComponent size={PersonaSize.size32} />
          </button>
          {renderCommonMenu()}
        </div>
      )
    }

    return (
      <>
        {user && rolesOnBusiness && (
          <div ref={userMenuRef} className="header-bar__user__info" onClick={() => setUserMenuOpen(!userMenuOpen)}>
            <div className={'header-bar__user__info__container'}>
              <span className="header-bar__user__info__name">
                {t(roleLocalizationKeyDictionary[rolesOnBusiness.portal])}
              </span>
              <span className="header-bar__user__info__email">{`${user.name} ${user.surname}`}</span>
            </div>
            <span className="header-bar__user__info__settings">
              <PersonaComponent size={PersonaSize.size32} />
            </span>
          </div>
        )}
        {renderCommonMenu()}
      </>
    )
  }

  return (
    <header className="header-bar" onMouseLeave={() => setUserMenuOpen(false)}>
      <div className={`header-bar__logo-container`}>
        {(isMobile || isTablet) && (
          <button className="header-bar__menu" onClick={onOpen}>
            <FontIcon aria-label="Menu" iconName="GlobalNavButton" className="header-bar__icons" />
          </button>
        )}
        <img
          src={LogoFlexxible}
          width="306px"
          alt="logo"
          className="header-bar__logo"
          onClick={() => {
            navigate('/')
          }}
        />
        <AppSwitcher />
      </div>

      <div className={`header-bar__actions-container`}>
        <div className="header-bar__organization_selector_container">
          <SidebarOrganizationSelector organizationList={organizationList} userOrganization={userOrganization} />
        </div>
        <div className="header-bar__persona" onClick={() => setUserMenuOpen(!userMenuOpen)}>
          {renderUserMenu()}
        </div>
      </div>
    </header>
  )
}
