import { ActionButton, IButtonStyles, IIconProps } from '@fluentui/react'
import { useTranslation } from 'react-i18next'

interface Props {
  isMobile: boolean
  onClick?: () => void
}

const actionButtonStylesDesktop: IButtonStyles = {
  root: {
    height: 22,
    padding: 0,
  },
  icon: {
    margin: '0 0 4px 0',
  },
}

const actionButtonStylesMobile: IButtonStyles = {
  root: {
    height: 22,
    padding: 0,
  },
}

const DownloadAgentButton = ({ isMobile, onClick }: Props) => {
  const { t } = useTranslation('general')
  const viewDetailIcon: IIconProps = { iconName: 'CloudDownload' }
  const text = isMobile ? '' : t('BUTTON.DOWNLOAD_AGENT')
  const style = isMobile ? actionButtonStylesMobile : actionButtonStylesDesktop
  const className = isMobile ? 'd-flex d-flexRow d-flexJustifyCenter' : 'd-flex d-flexRow'

  return (
    <div className={className}>
      <ActionButton onClick={onClick} styles={style} iconProps={viewDetailIcon} allowDisabledFocus>
        {text}
      </ActionButton>
    </div>
  )
}

export default DownloadAgentButton
