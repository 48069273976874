import { gql } from 'graphql-request'
import graphClient from '../../query-client/graphClient'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { CreateWindowsPatchPolicyFormType } from 'app/pages/patch-management/windows-patch-policies/components/CreateWindowsPatchPolicyForm'

const createWindowsPatchPolicyRequest = gql`
  mutation Request($organizationId: String!, $input: CreateWindowsPatchPolicyGraphqlRequest!) {
    createWindowsPatchPolicy(organizationId: $organizationId, input: $input) {
      _id
    }
  }
`

const createWindowsPatchPolicy = async (
  windowsPatchPolicy: CreateWindowsPatchPolicyFormType,
  organizationId: string
) => {
  const response = await graphClient.request(createWindowsPatchPolicyRequest, {
    organizationId: organizationId,
    input: windowsPatchPolicy,
  })

  return response.createWindowsPatchPolicy
}

export const useCreateWindowsPatchPolicy = (organizationId: string) => {
  const queryClient = useQueryClient()

  return useMutation(
    ['createWindowsPatchPolicy'],
    (windowsPatchPolicy: CreateWindowsPatchPolicyFormType) =>
      createWindowsPatchPolicy(windowsPatchPolicy, organizationId),
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries(['getWindowsPatchPoliciesByOrganization'])
      },
    }
  )
}
