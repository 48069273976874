import PageBody from '../../../components/PageBody/PageBody.component'
import { useEffect } from 'react'
import { WorkspaceGroupDetails } from './WorkspaceGroupDetails'
import useGetWorkspaceGroupById from '../../../hooks/workspaceGroup/useGetWorkspaceGroupById'
import { useNavigate, useParams } from 'react-router-dom'
import { WorkspaceGroupsDetailPageOrigin } from './WorkspaceGroupsDetailPageOrigin'
import { useWorkspaceGroupDetailPageConfigurationForOrigin } from './useWorkspaceGroupDetailPageConfigurationForOrigin'
import { GroupedWorkspaces } from './GroupedWorkspaces'
import { useTranslation } from 'react-i18next'
import { WorkspacesByDayChart } from './WorkspacesByDayChart'
import { Tab, TabPanel } from '../../../components/tab-panel/TabPanel'
import { WorkspaceGroupLocation } from 'app/pages/organization-details/workspace-groups/location/WorkspaceGroupLocation'
import { useOrganizationSelection } from 'app/globals/useOrganizationSelection'

interface Props {
  origin: WorkspaceGroupsDetailPageOrigin
}

export const WorkspaceGroupDetailPage = ({ origin }: Props) => {
  const { workspaceGroupId } = useParams()
  const {
    isLoading,
    data: workspaceGroupWithWorkspaceCount,
    isFetching,
  } = useGetWorkspaceGroupById(workspaceGroupId || '')
  const { selectedOrganizationId } = useOrganizationSelection()
  const navigate = useNavigate()
  const { t } = useTranslation('organization_details')
  const { getBreadcrumbItems } = useWorkspaceGroupDetailPageConfigurationForOrigin(origin)

  useEffect(() => {
    if (
      workspaceGroupWithWorkspaceCount?.workspaceGroup?.business?._id &&
      workspaceGroupWithWorkspaceCount?.workspaceGroup?.business?._id !== selectedOrganizationId
    ) {
      navigate(`/workspace-groups`)
    }
  }, [workspaceGroupWithWorkspaceCount, selectedOrganizationId])

  const buildTabs = (): Tab[] => {
    return [
      {
        title: t('WORKSPACE_GROUPS.WORKSPACE_GROUP_DETAILS_PIVOT'),
        component: (
          <WorkspaceGroupDetails
            workspaceGroup={workspaceGroupWithWorkspaceCount?.workspaceGroup}
            workspaceCount={workspaceGroupWithWorkspaceCount?.workspaceCount}
          />
        ),
        key: 'wb_groups',
      },
      {
        title: t('WORKSPACE_GROUPS.WORKSPACES_PIVOT'),
        component: <GroupedWorkspaces workspaceGroup={workspaceGroupWithWorkspaceCount?.workspaceGroup} />,
        key: 'workspaces',
      },
      {
        title: t('WORKSPACE_GROUPS.HISTORY_PIVOT'),
        component: <WorkspacesByDayChart />,
        key: 'history',
      },
      {
        title: t('WORKSPACE_GROUPS.LOCATION_PIVOT'),
        component: <WorkspaceGroupLocation workspaceGroup={workspaceGroupWithWorkspaceCount?.workspaceGroup} />,
        key: 'location',
      },
    ]
  }

  return (
    <PageBody
      title={workspaceGroupWithWorkspaceCount?.workspaceGroup.name || ''}
      isLoading={isLoading || isFetching}
      breadcrumbItems={getBreadcrumbItems(workspaceGroupWithWorkspaceCount?.workspaceGroup)}
    >
      <TabPanel tabs={buildTabs()} />
    </PageBody>
  )
}
