import { useTranslation } from 'react-i18next'
import { Dialog as FluentuiDialog, DialogFooter, DialogType } from '@fluentui/react/lib/Dialog'
import { ReactNode } from 'react'
import { ICSSPixelUnitRule, ICSSRule } from '@fluentui/merge-styles'
import { DefaultButton, PrimaryButton } from '@fluentui/react/lib/Button'

interface SpinnerProps {
  title: string
  description: string
  actionButton: string
  dismissButton?: string
  callback: () => void
  hidden: boolean
  onDismiss?: () => void
  children?: ReactNode
  maxWidth?: ICSSRule | ICSSPixelUnitRule
  actionButtonDisabled?: boolean
}

const Dialog = ({ title, description, callback, actionButton, dismissButton, hidden, onDismiss, children, maxWidth, actionButtonDisabled = false }: SpinnerProps) => {
  const { t } = useTranslation('management')

  const defaultDialogContentProps = {
    type: DialogType.normal,
    title: title,
    subText: description,
  }

  return (
    <FluentuiDialog
        hidden={ hidden }
        onDismiss={ onDismiss }
        dialogContentProps={ defaultDialogContentProps }
        maxWidth={maxWidth}
    >
      { children }
        <DialogFooter>
        <PrimaryButton onClick={ callback } text={ actionButton } disabled={ actionButtonDisabled }/>
          { dismissButton && onDismiss && (<DefaultButton onClick={ onDismiss } text={ dismissButton } />)}
        </DialogFooter>
    </FluentuiDialog>
  )
}

export default Dialog
