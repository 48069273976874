import { MOBILE_QUERY, useMediaQuery } from '@flexxibleit/flexxible-ui'
import { BusinessType } from 'app/models/business/BusinessType'
import { getOrganizationTypeIcon } from 'app/services/organization-icon-service'

interface Props {
  title: string
  type?: BusinessType
}

const TitleComponent = ({ title, type }: Props) => {
  const [isMobile] = useMediaQuery(MOBILE_QUERY)

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'start',
      }}
    >
      {type && <div className="mr-1 vertical-center">{getOrganizationTypeIcon(type)}</div>}
      {isMobile ? <h3>{title}</h3> : <h1>{title}</h1>}
    </div>
  )
}

export default TitleComponent
