import { ViewDetailsActionButton } from '@flexxibleit/flexxible-ui'
import {
  ColumnActionsMode,
  ContextualMenuItemType,
  DetailsListLayoutMode,
  IColumn,
  IContextualMenuItem,
  Link,
  MessageBar,
  SelectionMode,
  TooltipDelay,
  TooltipHost,
  TooltipOverflowMode,
} from '@fluentui/react'
import Table from '../../../components/Table/Table.component'
import useMediaQuery from '../../../hooks/utils/useMediaQuery'
import { MOBILE_QUERY } from '../../../config/media-query'
import { useNavigate } from 'react-router-dom'
import EmptyStatus from '../../../components/status/empty-status'
import { useTranslation } from 'react-i18next'
import { ContextualMenuButton } from '../../../components/Buttons/ActionButtons/ContextualMenu.component'
import { CSSProperties, useState } from 'react'
import Dialog from '../../../components/Dialog/Dialog'
import { useBoolean } from '@fluentui/react-hooks'
import { useArchiveMicroservice } from '../../../hooks/microservices/useArchiveMicroservice'
import { useUnarchiveMicroservice } from '../../../hooks/microservices/useUnarchiveMicroservice'
import { useFeedbackMessage } from '../../../context/feedback-message/FeedbackMessageContext'
import { ConfirmationDialog } from 'app/components/ConfirmationDialog/ConfirmationDialog'
import { DesignerMicroserviceView } from '../../../hooks/microservices/useGetDesignerMicroservices'
import { Badge } from 'app/components/Badge/Badge'
import StatusBadge, { BadgeMode } from '../../../components/status-badge/status-badge.component'
import i18next from 'i18next'
import { getTranslation } from 'app/models/microservices'
import IconLanguage from 'app/components/IconLanguage/IconLanguage'
import { microserviceTypeLabel } from 'app/models/microservices/MicroserviceType'
import { useMe } from 'app/hooks/me/useMe'
import { SpecialPermission } from 'app/query-client/types'

export interface MicroservicesTableModeProps {
  microservices: DesignerMicroserviceView[]
  contextActionsEnabled?: boolean
}

export const MicroservicesDesignerTable = (props: MicroservicesTableModeProps) => {
  const navigate = useNavigate()
  const [isMobile] = useMediaQuery(MOBILE_QUERY)
  const { t, i18n } = useTranslation('microservices')
  const [hideArchiveDialog, { toggle: toggleHideArchiveDialog }] = useBoolean(true)
  const [hideUnarchiveDialog, { toggle: toggleHideUnarchiveDialog }] = useBoolean(true)
  const archiveMicroservice = useArchiveMicroservice()
  const unarchiveMicroservice = useUnarchiveMicroservice()
  const [selectedMicroservice, setSelectedMicroservice] = useState<DesignerMicroserviceView | null>(null)
  const { setSuccessMessage, setErrorMessage } = useFeedbackMessage()
  const { data: me } = useMe()

  const buildContextActions = (microservice: DesignerMicroserviceView): IContextualMenuItem[] => {
    const iconStyles: CSSProperties = {
      fontSize: 'var(--font-size-regular)',
      color: 'var(--color-primary)',
    }
    return [
      {
        key: 'view',
        text: t('DESIGNER.ACTION.VIEW_DETAILS'),
        iconProps: {
          iconName: 'NavigateExternalInline',
          style: iconStyles,
        },
        onClick: () => navigate(`/microservices-designer/${microservice?.id}`),
      },
      ...(microservice.canEdit
        ? [
            {
              key: 'edit',
              text: t('DESIGNER.ACTION.EDIT'),
              iconProps: {
                iconName: 'Edit',
                style: iconStyles,
              },
              onClick: () => navigate(`/microservices-designer/${microservice?.id}/edit`),
            },
            {
              key: 'divider_1',
              itemType: ContextualMenuItemType.Divider,
            },
            microservice.archivedAt
              ? {
                  key: 'unarchive',
                  text: t('DESIGNER.ACTION.UNARCHIVE'),
                  iconProps: {
                    iconName: 'PublishContent',
                    style: {
                      ...iconStyles,
                      color: '#d83b01',
                    },
                  },
                  onClick: () => {
                    setSelectedMicroservice(microservice)
                    toggleHideUnarchiveDialog()
                  },
                }
              : {
                  key: 'archive',
                  text: t('DESIGNER.ACTION.ARCHIVE'),
                  iconProps: {
                    iconName: 'Archive',
                    style: {
                      ...iconStyles,
                      color: '#d83b01',
                    },
                  },
                  onClick: () => {
                    setSelectedMicroservice(microservice)
                    toggleHideArchiveDialog()
                  },
                },
          ]
        : []),
    ]
  }

  const handleOnClickViewDetails = (item: DesignerMicroserviceView) => {
    navigate(`/microservices-designer/${item.id}`)
  }

  const renderMicroserviceName = (item: DesignerMicroserviceView) => {
    const name = getTranslation(item.name, i18next.language)
    const itemName = name.text
    const showIcon = name.translatedByIA

    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'flex-start',
        }}
      >
        <div
          style={{
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }}
        >
          <TooltipHost
            content={itemName?.toString()}
            delay={TooltipDelay.zero}
            overflowMode={TooltipOverflowMode.Parent}
          >
            <Link onClick={() => handleOnClickViewDetails(item)}>
              {itemName?.toString()} {showIcon && <IconLanguage tam="16" />}
            </Link>
          </TooltipHost>
        </div>
      </div>
    )
  }

  const renderMicroserviceCategory = (item: DesignerMicroserviceView) => {
    const category = getTranslation(item.category, i18next.language)
    const itemCategory = category.text
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'flex-start',
        }}
      >
        <div
          style={{
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }}
        >
          <TooltipHost
            content={itemCategory?.toString()}
            delay={TooltipDelay.zero}
            overflowMode={TooltipOverflowMode.Parent}
          >
            {itemCategory?.toString()}
          </TooltipHost>
        </div>
      </div>
    )
  }

  const canSeeNonStandardMicroservices =
    me?.specialPermissions?.includes(SpecialPermission.CONDITION_MICROSERVICE) ||
    me?.specialPermissions?.includes(SpecialPermission.CUSTOM_FIELDS_MICROSERVICE)

  const columns: IColumn[] = [
    {
      key: 'name',
      name: t('DESIGNER.TABLE.NAME'),
      fieldName: 'name',
      minWidth: 300,
      isRowHeader: true,
      onRender: renderMicroserviceName,
      columnActionsMode: ColumnActionsMode.disabled,
    },
    {
      key: 'category',
      name: t('MARKETPLACE.TABLE.CATEGORY'),
      fieldName: 'category',
      minWidth: 200,
      isRowHeader: true,
      onRender: renderMicroserviceCategory,
      columnActionsMode: ColumnActionsMode.disabled,
    },
    ...(canSeeNonStandardMicroservices
      ? [
          {
            key: 'type',
            name: t('DESIGNER.TABLE.TYPE'),
            fieldName: 'type',
            minWidth: 100,
            isRowHeader: true,
            isCollapsible: true,
            columnActionsMode: ColumnActionsMode.disabled,
            onRender: (item: DesignerMicroserviceView) => {
              return microserviceTypeLabel(t)[item.type]
            },
          },
        ]
      : []),
    {
      key: 'library',
      name: t('DESIGNER.TABLE.LIBRARY'),
      fieldName: 'library',
      minWidth: 100,
      isRowHeader: true,
      columnActionsMode: ColumnActionsMode.disabled,
      onRender: (item: DesignerMicroserviceView) => {
        return (
          <>
            {item.organization.isTrial && <Badge>{t('organizations:TRIAL')}</Badge>}
            <span>{item.organization.name}</span>
          </>
        )
      },
    },
    {
      key: 'archived',
      name: t('DESIGNER.TABLE.ARCHIVED'),
      fieldName: 'archived',
      minWidth: 60,
      isPadded: true,
      isRowHeader: true,
      columnActionsMode: ColumnActionsMode.disabled,
      onRender: (item: DesignerMicroserviceView) => {
        return (
          <StatusBadge
            active={!item.archivedAt}
            mode={BadgeMode.circle}
            size={8}
            labels={{
              active: t('DESIGNER.UNARCHIVED'),
              inactive: t('DESIGNER.ARCHIVED'),
            }}
          />
        )
      },
    },
    {
      key: 'actions',
      name: t('DESIGNER.TABLE.ACTIONS'),
      minWidth: 100,
      onRender: (item: DesignerMicroserviceView) => {
        if (props.contextActionsEnabled) {
          return <ContextualMenuButton items={buildContextActions(item)} />
        }

        return (
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'start',
              justifyContent: 'center',
            }}
          >
            <ViewDetailsActionButton
              locale={i18n.language}
              isMobile={isMobile}
              onClick={() => handleOnClickViewDetails(item)}
            />
          </div>
        )
      },
      columnActionsMode: ColumnActionsMode.disabled,
    },
  ]

  const handleArchive = (id?: string) => {
    if (!id) return

    archiveMicroservice
      .mutateAsync(id)
      .then(() => setSuccessMessage(t('DESIGNER.ARCHIVE_DIALOG.SUCCESS')))
      .catch(() => setErrorMessage(t('DESIGNER.ARCHIVE_DIALOG.ERROR')))
  }

  const handleUnarchive = (id?: string) => {
    if (!id) return

    unarchiveMicroservice
      .mutateAsync(id)
      .then(() => setSuccessMessage(t('DESIGNER.UNARCHIVE_DIALOG.SUCCESS')))
      .catch(() => setErrorMessage(t('DESIGNER.UNARCHIVE_DIALOG.ERROR')))
  }

  const renderArchiveConfirmation = (): JSX.Element => {
    const name = getTranslation(selectedMicroservice ? selectedMicroservice.name : [], i18next.language)
    const itemName = name.text

    return (
      <ConfirmationDialog
        title={t('DESIGNER.ARCHIVE_DIALOG.TITLE')}
        description={t('DESIGNER.ARCHIVE_DIALOG.DESCRIPTION')}
        textRequired={itemName}
        actionButton={t('DESIGNER.ARCHIVE_DIALOG.BUTTON_ACCEPT')}
        dismissButton={t('DESIGNER.ARCHIVE_DIALOG.BUTTON_CANCEL')}
        hidden={hideArchiveDialog}
        onDismiss={toggleHideArchiveDialog}
        callback={() => {
          toggleHideArchiveDialog()
          handleArchive(selectedMicroservice?.id)
          setSelectedMicroservice(null)
        }}
      >
        <MessageBar
          messageBarType={3}
          isMultiline={true}
          dismissButtonAriaLabel={t('general:CLOSE_LABEL')}
          className="mb-2"
          style={{ width: '100%' }}
        >
          {t('DESIGNER.ARCHIVE_DIALOG.WARNING')}
        </MessageBar>
      </ConfirmationDialog>
    )
  }

  const renderUnarchiveConfirmation = (): JSX.Element => {
    return (
      <Dialog
        title={t('DESIGNER.UNARCHIVE_DIALOG.TITLE')}
        description={t('DESIGNER.UNARCHIVE_DIALOG.DESCRIPTION')}
        actionButton={t('DESIGNER.UNARCHIVE_DIALOG.BUTTON_ACCEPT')}
        dismissButton={t('DESIGNER.UNARCHIVE_DIALOG.BUTTON_CANCEL')}
        hidden={hideUnarchiveDialog}
        onDismiss={toggleHideUnarchiveDialog}
        callback={() => {
          toggleHideUnarchiveDialog()
          handleUnarchive(selectedMicroservice?.id)
          setSelectedMicroservice(null)
        }}
      >
        <MessageBar
          messageBarType={3}
          isMultiline={true}
          dismissButtonAriaLabel={t('general:CLOSE_LABEL')}
          className="mb-2"
          style={{ width: '100%' }}
        >
          {t('DESIGNER.UNARCHIVE_DIALOG.WARNING')}
        </MessageBar>
      </Dialog>
    )
  }

  const renderMicroservicesTable = () => {
    if (!props.microservices.length) {
      return (
        <div style={{ margin: '100px' }}>
          <EmptyStatus message={t('MARKETPLACE.EMPTY_STATUS_MESSAGE')} />
        </div>
      )
    }

    return (
      <>
        <Table
          tableId="microservices-designer"
          tableProps={{
            columns,
            items: props.microservices,
            compact: false,
            selectionMode: SelectionMode.none,
            layoutMode: DetailsListLayoutMode.justified,
            isHeaderVisible: true,
          }}
        />
      </>
    )
  }

  return (
    <>
      {renderMicroservicesTable()}
      {renderArchiveConfirmation()}
      {renderUnarchiveConfirmation()}
    </>
  )
}
