export default [
  { key: '1', text: 'Industries and sectors' },
  { key: '2', text: 'Agriculture; plantations; other rural sectors' },
  { key: '3', text: 'Basic Metal Production' },
  { key: '4', text: 'Chemical industries' },
  { key: '5', text: 'Commerce' },
  { key: '6', text: 'Construction' },
  { key: '7', text: 'Education' },
  { key: '8', text: 'Financial services; professional services' },
  { key: '9', text: 'Food; drink; tobacco' },
  { key: '10', text: 'Forestry; wood; pulp and paper' },
  { key: '11', text: 'Health services' },
  { key: '12', text: 'Hotels; tourism; catering' },
  { key: '13', text: 'Mining(coal; other mining)' },
  { key: '14', text: 'Mechanical and electrical engineering' },
  { key: '15', text: 'Media; culture; graphical' },
  { key: '16', text: 'Oil and gas production; oil refining' },
  { key: '17', text: 'Postal and telecommunications services' },
  { key: '18', text: 'Public service' },
  { key: '19', text: 'Shipping; ports; fisheries; inland waterways' },
  { key: '20', text: 'Textiles; clothing; leather; footwear' },
  { key: '21', text: 'Transport(including civil aviation; railways; road transport)' },
  { key: '22', text: 'Transport equipment manufacturing' },
  { key: '23', text: 'Utilities(water; gas; electricity)' },
]