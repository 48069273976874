import { useQuery } from '@tanstack/react-query'
import graphClient from 'app/query-client/graphClient'
import { gql } from 'graphql-request'
import { getTranslation, MultiLanguageField } from 'app/models/microservices'
import i18next from 'i18next'
import { secondsToLabel } from 'app/models/flows/FlowCooldown'
import { TFunction, useTranslation } from 'react-i18next'
import { FlowType } from 'app/models/flows/FlowType'

export interface FlowListItem {
  id: string
  name: MultiLanguageField[]
  description: MultiLanguageField[]
  type: FlowType
  detectionOnly: boolean
  cooldown: number
  enabled: boolean
}

export interface FlowListItemExport {
  id: string
  name: string
  description: string
  type: string
  detectionOnly: string
  cooldown: string
}

interface Response {
  getFlowsByOrganization: FlowListItem[]
}

const query = gql`
  query ($organizationId: ID!) {
    getFlowsByOrganization(organizationId: $organizationId) {
      id
      name {
        code
        text
        translatedByIA
      }
      description {
        code
        text
        translatedByIA
      }
      type
      detectionOnly
      cooldown
      enabled
    }
  }
`

function getFlowsByOrganization(organizationId: string, t: TFunction): Promise<FlowListItemExport[]> {
  return graphClient.request<Response>(query, { organizationId }).then((response) => {
    if (!response.getFlowsByOrganization) {
      return []
    }

    const _flows: FlowListItemExport[] = response.getFlowsByOrganization.map((flow) => {
      const _name = getTranslation(flow.name, i18next.language).text
      const _description = getTranslation(flow.description, i18next.language).text
      const _detectionOnly = flow.detectionOnly ? t('general:YES') : t('general:NO')

      return {
        id: flow.id,
        name: _name,
        description: _description,
        type: flow.type,
        detectionOnly: _detectionOnly,
        cooldown: secondsToLabel(t, flow.cooldown),
      }
    })

    return _flows
  })
}

export default function (organizationId: string, t: TFunction) {
  if (!organizationId) {
    return useQuery(['flows'], () => [], {
      refetchOnWindowFocus: false,
      enabled: false,
    })
  }

  return useQuery(['flows', organizationId], () => getFlowsByOrganization(organizationId, t), {
    refetchOnWindowFocus: false,
    enabled: false,
  })
}
