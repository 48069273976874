import { LinuxPatchPolicy, PatchPolicyTarget, ReportingGroup, WindowsPatchPolicy } from '../../../../query-client/types'
import { ColumnActionsMode, DetailsListLayoutMode, IColumn, SelectionMode } from '@fluentui/react'
import { Table } from '@flexxibleit/flexxible-ui'
import { useTranslation } from 'react-i18next'
import { CellWithFeatureLink } from 'app/components/Table/CellWithFeatureLink'
import { FEATURE_NAMES } from 'app/permissions/FeatureName.enum'
import React from 'react'
import { useOrganizationSelection } from 'app/globals/useOrganizationSelection'
import { useNavigate } from 'react-router-dom'

interface LinuxPatchPolicyTargetsTableProps {
  linuxPatchPolicy: LinuxPatchPolicy
}

export const LinuxPatchPolicyTargetsTable = (props: LinuxPatchPolicyTargetsTableProps) => {
  const { t } = useTranslation('patch_management')

  const { selectedOrganization } = useOrganizationSelection()
  const navigate = useNavigate()

  const onClickViewDetailsTarget = (patchPolicyTargetId: string) => {
    navigate(`/organization/${selectedOrganization?._id}/patch-management/patch-policy-targets/${patchPolicyTargetId}`)
  }

  const patchPolicyTargets: Partial<PatchPolicyTarget>[] = props.linuxPatchPolicy.patchPolicyTargets ?? []

  const patchPolicyTargetsColumns: IColumn[] = [
    {
      key: 'name',
      name: t('PATCH_POLICIES.SUMMARY.TARGETS_TABLE.NAME_COLUMN'),
      minWidth: 100,
      isRowHeader: true,
      isPadded: true,
      columnActionsMode: ColumnActionsMode.disabled,
      maxWidth: 200,
      onRender: (item: PatchPolicyTarget) => {
        return (
          <React.Fragment key={item._id}>
            <CellWithFeatureLink
              feature={FEATURE_NAMES.PATCH_MANAGEMENT_READ}
              callback={() => onClickViewDetailsTarget(item._id)}
            >
              {item.name}
            </CellWithFeatureLink>
          </React.Fragment>
        )
      },
    },
  ]

  return (
    <Table
      tableProps={{
        items: patchPolicyTargets,
        columns: patchPolicyTargetsColumns,
        compact: false,
        selectionMode: SelectionMode.none,
        layoutMode: DetailsListLayoutMode.justified,
        isHeaderVisible: true,
      }}
      isEmpty={patchPolicyTargets.length === 0}
      emptyMessage={t('PATCH_POLICIES.SUMMARY.TARGETS_TABLE.EMPTY_MESSAGE')}
    />
  )
}
