import Highcharts from 'highcharts/highstock'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { DashboardChartCard } from '../../../../components/Dashboard/DashboardChartCard/DashboardChartCard'
import { AreaChart } from '../../../../components/Charts/AreaChart/AreaChart'

interface Item {
  severity: string,
  status: string,
  value: number
}

interface Props {
  patchesBySeverityAndStatus: Item[] | []
}

export const PatchesBySeverityAndStatusChart = (props: Props) => {
  const { t } = useTranslation('patch_management')

  const severities = ['Critical', 'Important', 'Moderate', 'Low', 'Unspecified'];

  const orderAndCompleteBySeverity = (data: Item[]) => {
    const results = new Map(severities.map(severity => [severity, { severity, value: 0 }]));
    data.forEach(item => {
      results.set(item.severity, item);
    });

    return Array.from(results.values());
  }

  const approvedOrdered = orderAndCompleteBySeverity(props.patchesBySeverityAndStatus.filter(item => item.status === 'APPROVED'));
  const notApprovedOrdered = orderAndCompleteBySeverity(props.patchesBySeverityAndStatus.filter(item => item.status === 'NOT_APPROVED'));

  const series: Highcharts.SeriesAreaOptions[] = [
    {
      name: t('DASHBOARD.TABS.PATCHES_TAB.AREA_CHART.APPROVED'),
      type: 'area',
      data: approvedOrdered.map((patch) => patch.value),
      pointPlacement: 'on',
      color: 'green',
      opacity: 0.5,
    },
    {
      name: t('DASHBOARD.TABS.PATCHES_TAB.AREA_CHART.NOT_APPROVED'),
      type: 'area',
      data: notApprovedOrdered.map((patch) => patch.value),
      pointPlacement: 'on',
      color: 'red',
      opacity: 0.5,
    },
  ];

  const xAxisCategories = ['Critical', 'Important', 'Moderate', 'Low', 'Unspecified'];
  const responsiveConfiguration: Partial<Highcharts.Options> = {
    responsive: {
      rules: [
        {
          condition: {
            maxWidth: 500,
          },
          chartOptions: {
            legend: {
              align: 'center',
              verticalAlign: 'bottom',
              layout: 'horizontal',
            },
            pane: {
              size: '70%',
            },
          },
        },
      ],
    },
  }

  return (
    <DashboardChartCard
      title={t('DASHBOARD.TABS.PATCHES_TAB.AREA_CHART.TITLE')}
      tooltip={t('DASHBOARD.TABS.PATCHES_TAB.AREA_CHART.TOOLTIP')}
    >
      <AreaChart
        series={series}
        categories={xAxisCategories}
        highchartsConfig={responsiveConfiguration}
      />
    </DashboardChartCard>
  )
}
