import { TFunction } from 'i18next'

export enum FlowTargetType {
  ALL_WORKSPACES = 'ALL_WORKSPACES',
  WORKSPACES = 'WORKSPACES',
  WORKSPACE_GROUPS = 'WORKSPACE_GROUPS',
  REPORTING_GROUPS = 'REPORTING_GROUPS',
}

export const flowTargetTypeOptions = (t: TFunction) =>
  Object.values(FlowTargetType).map((type) => ({
    key: type,
    text: targetTypeLabel(t)[type],
  }))

export const targetTypeLabel = (t: TFunction): { [key in FlowTargetType]: string } => ({
  [FlowTargetType.ALL_WORKSPACES]: t('flows:TARGET_TYPE.ALL_WORKSPACES'),
  [FlowTargetType.WORKSPACES]: t('flows:TARGET_TYPE.WORKSPACES'),
  [FlowTargetType.WORKSPACE_GROUPS]: t('flows:TARGET_TYPE.WORKSPACE_GROUPS'),
  [FlowTargetType.REPORTING_GROUPS]: t('flows:TARGET_TYPE.REPORTING_GROUPS'),
})
