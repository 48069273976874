import { SummaryRule } from '../../usePatchManagementRuleEngine'
import { PatchSummary } from '../../../entities/PatchSummary'
import { PatchSummaryRuleSentences, SummarySectionType, SummaryType } from '../../types'
import { AllTargetsMustHavePatchPolicyRuleCard } from './AllTargetsMustHavePatchPolicyRuleCard'

export const AllTargetsMustHavePatchPolicyRule: SummaryRule = {
  rule: PatchSummaryRuleSentences.ALL_TARGETS_MUST_HAVE_PATCH_POLICY,
  alertSummaryItem: {
    card: <AllTargetsMustHavePatchPolicyRuleCard />,
    type: SummaryType.Alert,
    section: SummarySectionType.Patches,
  },
  infoSummaryItem: {
    type: SummaryType.Information,
    section: SummarySectionType.Patches,
    card: <AllTargetsMustHavePatchPolicyRuleCard isInfo />,
  },
  passRule: (patchSummary: PatchSummary) => {

    return patchSummary.targets.every((target) => target.windowsPatchPolicyId !== null)
  },
}
