import { useQuery } from '@tanstack/react-query'
import graphClient from '../../query-client/graphClient'
import { gql } from 'graphql-request'
import { MultiLanguageField } from 'app/models/microservices'

const getMultilanguageFieldsQuery = gql`
query ($text: String!, $initialLanguage: String!, $fields: [MultiLanguageFieldInput!]!) {
  getMultilanguageFields(text: $text, initialLanguage: $initialLanguage, fields: $fields) {
      translations {
          code
          text
          translatedByIA
      }
  }
}
`

export async function getMultilanguageFields(text: string, initialLanguage: string, fields: MultiLanguageField[]): Promise<MultiLanguageField[]> {
  const input =  { text, initialLanguage, fields }
  let r = await graphClient.request(getMultilanguageFieldsQuery, input)
  return await r.getMultilanguageFields
}

export const useGetMultilanguageFields = (text: string, initialLanguage: string, fields: MultiLanguageField[], shouldFetch: boolean) => {
  return useQuery(
    ['multilanguageFields', text, initialLanguage, fields],
    () => getMultilanguageFields(text, initialLanguage, fields),
    {
      refetchOnWindowFocus: false,
      enabled: shouldFetch,
    }
  );
};