import { useQuery } from '@tanstack/react-query'
import graphClient from 'app/query-client/graphClient'
import { gql } from 'graphql-request'

export interface WindowsProduct {
  id: string
  title: string
  description: string
}

interface Response {
  getWindowsPatchProducts: WindowsProduct[]
}

const query = gql`
  query {
    getWindowsPatchProducts {
      id
      title
      description
    }
  }
`

async function getWindowsProducts(): Promise<WindowsProduct[]> {
  return graphClient.request<Response>(query).then((r) => r.getWindowsPatchProducts)
}

export default function useGetWindowsProducts() {
  return useQuery(['getWindowsProducts'], getWindowsProducts, {
    refetchOnWindowFocus: false,
  })
}
