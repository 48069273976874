import { HighchartsReact } from 'highcharts-react-official'
import Highcharts from 'highcharts/highstock'

interface Props {
  series: Highcharts.SeriesAreaOptions[]
  categories?: string[]
  highchartsConfig?: Partial<Highcharts.Options>
}

export const AreaChart = (props: Props) => {
  const chartOptions: Highcharts.Options = {
    chart: {
      type: 'line',
      polar: true,
      numberFormatter: (value: number) => value.toLocaleString(),
    },
    title: {
      text: '',
    },
    credits: {
      enabled: false,
    },
    legend: {
      enabled: true,
      align: 'right',
      verticalAlign: 'middle',
      layout: 'vertical',
    },
    xAxis: {
      categories: props.categories || [],
      tickmarkPlacement: 'on',
      lineWidth: 0,
    },
    series: props.series,
    ...props.highchartsConfig,
  }

  return <HighchartsReact highcharts={Highcharts} options={chartOptions} />
}
