import FlexxibleCard from '../../../components/FlexxibleCard/FlexxibleCard.component'
import { DefaultOrganizationSelector } from './DefaultOrganizationSelector'
import { User } from '../../../query-client/types'
import { DefaultLanguageSelector } from './DefaultLanguageSelector'
import { cardProps } from './UserInfoCard'
import { DefaultRegionSelector } from 'app/pages/user-settings/components/DefaultRegionSelector'
import { AdvancedMenuToggle } from './AdvancedMenuToggle'
import DoptSettings from './DoptSettings'

interface UserSettingsPanelProps {
  user: User
}

export const UserSettingsPanel = (props: UserSettingsPanelProps) => {
  return (
    <FlexxibleCard
      cardProps={{
        style: {
          ...cardProps.style,
          alignItems: 'start',
        },
      }}
    >
      <div
        style={{
          width: '100%',
        }}
      >
        <DefaultOrganizationSelector userOrganization={props.user.business} />

        <div className={'mt-2'}>
          <DefaultLanguageSelector />
        </div>
        <div className={'mt-2'}>
          <DefaultRegionSelector />
        </div>
        <div className={'mt-2'}>
          <AdvancedMenuToggle />
        </div>
        <DoptSettings />
      </div>
    </FlexxibleCard>
  )
}
