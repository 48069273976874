import { TFunction } from 'i18next'

export enum ConditionEnvironment {
  development = 'development',
  production = 'production',
}

export const conditionEnvironmentLabel = (t: TFunction): { [key in ConditionEnvironment]: string } => ({
  [ConditionEnvironment.development]: t('microservices:CONDITION_ENVIRONMENT.DEVELOPMENT'),
  [ConditionEnvironment.production]: t('microservices:CONDITION_ENVIRONMENT.PRODUCTION'),
})

export const conditionEnvironmentOptions = (t: TFunction) =>
  Object.keys(ConditionEnvironment).map((key) => ({
    key,
    text: conditionEnvironmentLabel(t)[key as ConditionEnvironment],
  }))
