import { useMutation, useQueryClient } from '@tanstack/react-query'
import graphClient from 'app/query-client/graphClient'
import { gql } from 'graphql-request'

const mutation = gql`
  mutation Request($id: ID!) {
    deleteWindowsPatchPolicy(id: $id)
  }
`

const deleteWindowsPatchPolicy = async (id: string): Promise<string> => {
  await graphClient.request(mutation, { id })

  return id
}

export const useDeleteWindowsPatchPolicy = () => {
  const queryClient = useQueryClient()

  return useMutation(['deleteWindowsPatchPolicy'], (id: string) => deleteWindowsPatchPolicy(id), {
    onSuccess: async (id) => {
      await queryClient.invalidateQueries(['getWindowsPatchPoliciesByOrganization'])
    },
  })
}
