import { useMutation, useQueryClient } from '@tanstack/react-query'
import { MicroserviceFormType } from 'app/pages/microservices/components/MicroserviceForm'
import graphClient from 'app/query-client/graphClient'
import { gql } from 'graphql-request'

interface Payload {
  _id: string
}
interface Response {
  createConditionMicroservice: Payload
}

const createConditionMicroserviceQuery = gql`
  mutation Request($input: CreateConditionMicroserviceGraphqlRequest!) {
    createConditionMicroservice(input: $input) {
      _id
    }
  }
`

const createConditionMicroservice = async (
  microservice: MicroserviceFormType,
  defaultLang: string
): Promise<Payload> => {
  const { createConditionMicroservice }: Response = await graphClient.request(createConditionMicroserviceQuery, {
    input: microservice,
    defaultLang,
  })
  return createConditionMicroservice
}

export const useCreateConditionMicroservice = () => {
  const queryClient = useQueryClient()

  return useMutation(
    ['createConditionMicroservice'],
    ({ microservice, defaultLang }: { microservice: MicroserviceFormType; defaultLang: string }) =>
      createConditionMicroservice(microservice, defaultLang),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['microservices'])
      },
    }
  )
}
