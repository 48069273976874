import { LoadingSpinner } from '@flexxibleit/flexxible-ui'
import * as Sentry from '@sentry/react'
import { FrontConfig, useFrontConfig } from 'app/hooks/frontConfig/useFrontConfig'
import React, { createContext, PropsWithChildren, useContext, useEffect } from 'react'
import { createRoutesFromChildren, matchRoutes, useLocation, useNavigationType } from 'react-router-dom'
import { ENV } from '../../config/Consts'

const GlobalConfigContext = createContext<FrontConfig | undefined>(undefined)

export const useGlobalConfig = () => useContext(GlobalConfigContext)

export const GlobalConfigProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const { data } = useFrontConfig()
  const [isReady, setIsReady] = React.useState(false)

  useEffect(() => {
    if (data?.sentryDns) {
      Sentry.init({
        environment: ENV?.toLowerCase(),
        dsn: data.sentryDns,
        integrations: [
          new Sentry.BrowserTracing({
            routingInstrumentation: Sentry.reactRouterV6Instrumentation(
              useEffect,
              useLocation,
              useNavigationType,
              createRoutesFromChildren,
              matchRoutes
            ),
          }),
          new Sentry.Replay({
            maskAllText: false,
            blockAllMedia: false,
          }),
        ],
        // Session Replay
        replaysSessionSampleRate: 0.25,
        replaysOnErrorSampleRate: 1.0,
        // Tracing
        tracesSampleRate: 0.5,
      })
    }
    setIsReady(!!data)
  }, [data])

  if (!isReady || !data) {
    return <LoadingSpinner />
  }

  return (
    <GlobalConfigContext.Provider
      value={{
        auth0ClientId: data.auth0ClientId,
        auth0Domain: data.auth0Domain,
        gmApiKey: data.gmApiKey,
        sentryDns: data.sentryDns,
        doptBlocksApiKey: data.doptBlocksApiKey,
        doptUsersApiKey: data.doptUsersApiKey,
      }}
    >
      {children}
    </GlobalConfigContext.Provider>
  )
}
