import { useQuery } from '@tanstack/react-query'
import graphClient from 'app/query-client/graphClient'
import { gql } from 'graphql-request'

const query = gql`
  query Request($businessId: ID!) {
    getBusinessTenants(businessId: $businessId) {
      _id
      name
      product_name
      policy_name
      created_at
    }
  }
`

function getBusinessTenants(businessId: string) {
  return graphClient.request(query, { businessId }).then((response) => {
    return response.getBusinessTenants || []
  })
}

export default function (businessId: string) {
  return useQuery(['getBusinessTenants', businessId], () => getBusinessTenants(businessId), {
    refetchOnWindowFocus: false,
    enabled: false,
  })
}
