import {
  CreateButton,
  MOBILE_QUERY,
  SearchBox,
  ViewDetailsActionButton,
  useMediaQuery,
} from '@flexxibleit/flexxible-ui'
import { ColumnActionsMode, DetailsListLayoutMode, IColumn, SelectionMode, Stack } from '@fluentui/react'
import Table from 'app/components/Table/Table.component'
import useCreateForm, { useCreateFormV2 } from 'app/hooks/useCreateForm'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { CreateExcludeRuleLinuxPatchPolicyForm } from '../components/CreateExcludeRuleLinuxPatchPolicyForm'
import { LinuxPatchPolicy, PatchPolicyExclude } from 'app/query-client/types'
import { CellWithFeatureLink } from 'app/components/Table/CellWithFeatureLink'
import { FEATURE_NAMES } from 'app/permissions/FeatureName.enum'
import { FeatureRender } from '../../../../permissions/FeatureRender'

interface LinuxPatchPoliciesExcludeListTabProps {
  linuxPatchPolicy: LinuxPatchPolicy
}
export const LinuxPatchPoliciesExcludeListTab = ({ linuxPatchPolicy }: LinuxPatchPoliciesExcludeListTabProps) => {
  const { t, i18n } = useTranslation('patch_management')
  const [isMobile] = useMediaQuery(MOBILE_QUERY)
  const { organizationId } = useParams()

  const [excludes, setExcludes] = useState<PatchPolicyExclude[]>(linuxPatchPolicy.excludes)

  const onClickViewDetails = (index: number) => {
    const rules = linuxPatchPolicy.excludes[index]
    return useCreateForm(
      {
        title: t('LINUX_PATCH_POLICIES.EXCLUSION_RULE.EDIT_FORM'),
        isFooterAtBottom: true,
      },
      <CreateExcludeRuleLinuxPatchPolicyForm exclude={rules} linuxPatchPolicy={linuxPatchPolicy} index={index} />
    )
  }

  useEffect(() => {
    setExcludes(linuxPatchPolicy.excludes)
  }, [linuxPatchPolicy])

  const _onFilter = (event: any, newValue?: string) => {
    if (newValue) {
      setExcludes(
        linuxPatchPolicy.excludes.filter(
          (exclude) =>
            exclude.name.toLowerCase().includes(newValue.toLowerCase()) ||
            exclude.rule.toLowerCase().includes(newValue.toLowerCase())
        )
      )
    } else {
      setExcludes(linuxPatchPolicy.excludes)
    }
  }

  const patchPoliciesColumns: IColumn[] = [
    {
      key: 'name',
      name: t('LINUX_PATCH_POLICIES.TABLE.NAME'),
      fieldName: 'name',
      minWidth: 100,
      flexGrow: 1,
      maxWidth: 600,
      isRowHeader: true,
      columnActionsMode: ColumnActionsMode.disabled,
      onRender: (item: LinuxPatchPolicy, index?: number) => {
        return (
          <CellWithFeatureLink feature={FEATURE_NAMES.PATCH_MANAGEMENT_READ} callback={onClickViewDetails(index ?? 0)}>
            {item.name}
          </CellWithFeatureLink>
        )
      },
    },
    {
      key: 'rule',
      name: t('LINUX_PATCH_POLICIES.TABLE.RULE'),
      fieldName: 'rules',
      minWidth: 100,
      maxWidth: 500,
      isRowHeader: true,
      columnActionsMode: ColumnActionsMode.disabled,
    },

    {
      key: 'actions',
      name: t('LINUX_PATCH_POLICIES.TABLE.ACTIONS'),
      minWidth: 100,
      isRowHeader: true,
      columnActionsMode: ColumnActionsMode.disabled,
      onRender: (item: any, index) => {
        return <ViewDetailsActionButton isMobile={isMobile} onClick={onClickViewDetails(index ?? 0)} />
      },
    },
  ]

  const onCreate = useCreateForm(
    {
      title: t('LINUX_PATCH_POLICIES.EXCLUSION_RULE.CREATE_FORM'),
      isFooterAtBottom: true,
    },
    <CreateExcludeRuleLinuxPatchPolicyForm linuxPatchPolicy={linuxPatchPolicy} />
  )

  return (
    <>
      <Stack
        horizontal={!isMobile}
        horizontalAlign="space-between"
        tokens={{ padding: '20px 0 0 0' }}
        className="mb-sm-2"
      >
        <SearchBox
          placeholder={t('LINUX_PATCH_POLICIES.EXCLUSION_RULE.SEARCH_PLACEHOLDER')}
          onChange={_onFilter}
          style={{ width: isMobile ? '100%' : '300px' }}
        />
        <FeatureRender feature={FEATURE_NAMES.PATCH_MANAGEMENT_CREATE}>
          <CreateButton locale={i18n.language} onClick={onCreate} />
        </FeatureRender>
      </Stack>
      <Table
        tableId="linux-patch-policies"
        isEmpty={excludes.length === 0}
        emptyMessage={t('general:EMPTY_FILTER')}
        tableProps={{
          items: excludes,
          columns: patchPoliciesColumns,
          compact: false,
          selectionMode: SelectionMode.none,
          layoutMode: DetailsListLayoutMode.justified,
          isHeaderVisible: true,
        }}
      />
    </>
  )
}
