import { ChoiceGroup as FluentChoiceGroup, IChoiceGroupProps } from "@fluentui/react";
import Controlled from "../Controlled";

export interface ChoiseGroupProps {
  name: string;
  choiceGroupProps: IChoiceGroupProps;
}

function ChoiceGroup({ name, choiceGroupProps }: ChoiseGroupProps) {

  return (
    <>
    <Controlled
      name={name}
      render={({ field }) => (
        <FluentChoiceGroup 
          {...field}
          {...choiceGroupProps}
          onChange={(e: any, o: any) => {
            field.onChange(o.key)
          }}
        />)}
      />
    </>
  )
}


export default ChoiceGroup