import { useMutation } from "@tanstack/react-query";
import { gql } from "graphql-request";
import graphClient from "../../query-client/graphClient";
import queryClient from "../../query-client/queryClient";

const deleteModuleQuery = gql`mutation Request($id: ID!) {
  deleteModule(id: $id)
}`

function deleteModule(id: string) {
  return graphClient
    .request(deleteModuleQuery, { id: id })
    .then(response => response)
}

export default function () {
  return useMutation(
    ['deleteModule'],
    ({ moduleId }: { moduleId: string }) => deleteModule(moduleId), {
      onSuccess: () => {
        queryClient.invalidateQueries(['getBusinessById'])
      },
    },
  )
}
