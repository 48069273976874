import { IRenderFunction, ITextFieldProps, TextField } from '@fluentui/react'
import { useFormContext, useFormState } from 'react-hook-form'
import { TFunction } from 'react-i18next'
import Controlled from '../Controlled'
import '../forms.scss'
import IconLanguage from 'app/components/IconLanguage/IconLanguage'
import { TooltipInfo } from 'app/components/Tooltip/TooltipInfo'

export interface ControlledTextFieldProps {
  name: string
  translator: TFunction
  required?: boolean
  textFieldProps?: ITextFieldProps
  multiLangIcon?: boolean
  multiLang?: boolean
  index?: number
  tooltip?: string
}

function ControlledTextField({
  name,
  multiLang,
  index,
  translator,
  required = false,
  textFieldProps,
  multiLangIcon,
  tooltip,
}: ControlledTextFieldProps) {
  const { control, clearErrors } = useFormContext()
  const { errors } = useFormState({ control })

  let errorMessageKey = errors[name]?.message
  let errorMessage = !!errors[name] ? translator(`ERRORS.${name}_${(errorMessageKey as string)?.toLowerCase()}`) : ''
  let invalid = !!errors[name]

  if (multiLang) {
    const indexText: number = name.indexOf('[')
    const newName = name.substring(0, indexText)
    errorMessageKey = errors[newName]?.message
    errorMessage = !!errors[newName]
      ? translator(`ERRORS.${newName}_${(errorMessageKey as string)?.toLowerCase()}`)
      : ''
    invalid = !!errors[newName]
  }

  const handleChange = (event: any, newValue: string | undefined) => {
    if (textFieldProps?.onChange) {
      textFieldProps.onChange(event, newValue)
    }

    if (multiLang) {
      const baseFieldName = name.substring(0, name.indexOf('['))
      clearErrors(baseFieldName)
    } else {
      clearErrors(name)
    }
  }

  const onWrapDefaultLabelRenderer: IRenderFunction<ITextFieldProps> = (props, defaultRender) => {
    return (
      <>
        {defaultRender ? (
          <span className={required ? 'required' : ''} style={{ display: 'flex', alignItems: 'center' }}>
            {defaultRender(props)}
            {multiLangIcon && <IconLanguage tam="16" />}
            {tooltip && (
              <div style={{ alignSelf: 'end' }}>
                <TooltipInfo text={tooltip} />
              </div>
            )}
          </span>
        ) : null}
      </>
    )
  }

  return (
    <>
      <Controlled
        name={name}
        render={({ field: { value, onChange, ...others } }) => (
          <TextField
            {...textFieldProps}
            {...others}
            onRenderLabel={onWrapDefaultLabelRenderer}
            value={value}
            onChange={(event, newValue) => {
              if (onChange) {
                onChange(event, newValue)
                handleChange(event, newValue)
              }
              if (textFieldProps?.onChange) {
                textFieldProps.onChange(event, newValue)
              }
            }}
            invalid={invalid}
            errorMessage={errorMessage}
          />
        )}
      />
    </>
  )
}

export default ControlledTextField
