import { gql } from 'graphql-request'
import {
  PatchesDashboard,
} from '../../query-client/types'
import graphClient from '../../query-client/graphClient'
import { useQuery } from '@tanstack/react-query'

const getPatchesDashboard = gql`
  query($organizationId: String!) {
  getPatchManagementDashboard(organizationId: $organizationId) {
    totalAvailablePatches
    totalApprovedPatches
    totalNotApprovedPatches
    totalApprovedPatchesByPatchPolicy {
      patchPolicy
      value
    }
    totalNotApprovedPatchesByPatchPolicy {
      patchPolicy
      value
    }
    patchesBySeverityAndStatus {
      severity
      status
      value
    }
  }
}
`

function useGetPatchesDashboard(organizationId: string | undefined
): Promise<PatchesDashboard> {
  return graphClient
    .request(getPatchesDashboard, {organizationId})
    .then((response) => {
      return response.getPatchManagementDashboard
    })
}

export default function (organizationId: string | undefined) {
  return useQuery(
    ['getPatchesDashboard', organizationId],
    () => useGetPatchesDashboard(organizationId),
    {
      refetchOnWindowFocus: false,
      keepPreviousData: true,
    }
  )
}
