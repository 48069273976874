import { BadgeMode, MOBILE_QUERY, StatusBadge, useMediaQuery, ViewDetailsActionButton } from '@flexxibleit/flexxible-ui'
import { IPaginatedTableColumn } from '@flexxibleit/flexxible-ui/dist/lib/components/paginated-table/paginatedTable.types'
import { ColumnActionsMode, Link } from '@fluentui/react'
import ReportsButton from 'app/components/Buttons/ActionButtons/Reports.component'
import SettingsButton from 'app/components/Buttons/ActionButtons/Settings.component'
import { CellWithFeatureGreenLink } from 'app/components/table-page-body/CellWithFeatureGreenLink'
import { FEATURE_NAMES } from 'app/permissions/FeatureName.enum'
import { Product, ProductConfig } from 'app/query-client/types'
import { dateFormatter } from 'app/services/date-formatter'
import { getProductIcon } from 'app/services/product-icon.service'
import { i18n } from 'i18next'
import { useEffect, useState } from 'react'
import { TFunction } from 'react-i18next'
import { FeatureRender } from '../../../permissions/FeatureRender'

type UseProductsBuildTableProps = {
  t: TFunction
  i18n: i18n
  products: Product[]
  openEditProduct: (product: ProductConfig) => () => void
  openAgentSettingsProduct: (product: ProductConfig) => () => void
}

interface ProductConfigRow extends ProductConfig {
  isExpanded: boolean
}

export const useProductsBuildTable = ({
  t,
  i18n,
  products,
  openEditProduct,
  openAgentSettingsProduct,
}: UseProductsBuildTableProps) => {
  const [isMobile] = useMediaQuery(MOBILE_QUERY)
  const [columns, setColumns] = useState<IPaginatedTableColumn[]>([])

  useEffect(() => {
    setColumns([
      {
        key: 'environment',
        name: t('PRODUCTS.TABLE.ENVIRONMENT'),
        fieldName: 'environment',
        minWidth: 25,
        flexGrow: 1,
        isRowHeader: true,
        onRender: (item: ProductConfigRow) => {
          return (
            <CellWithFeatureGreenLink feature={FEATURE_NAMES.ENVIRONMENT_READ} callback={openEditProduct(item)}>
              {item.environment}
            </CellWithFeatureGreenLink>
          )
        },
      },
      {
        key: 'type',
        name: t('PRODUCTS.TABLE.PRODUCT_TYPE'),
        fieldName: 'product',
        minWidth: 25,
        flexGrow: 1,
        isRowHeader: true,
        onRender: (item: ProductConfigRow) => {
          return (
            <span className="d-flex d-flexAlignItemsCenter">
              <span className="d-flex d-flexAlignItemsCenter" style={{ marginRight: 5 }}>
                {getProductIcon(item.product.name, 18, 18)}
              </span>
              <span>{item.product.name}</span>
            </span>
          )
        },
      },
      {
        key: 'region',
        name: t('PRODUCTS.TABLE.REGION'),
        fieldName: 'region',
        minWidth: 25,
        flexGrow: 1,
        isRowHeader: true,
        onRender: (item: ProductConfigRow) => {
          const productId = item?.product?._id
          const deploymentCode = item?.region
          let name: string = ''
          if (deploymentCode) {
            const product = products?.find((p) => p._id === productId)
            if (product) {
              product.regions.map((region) => {
                if (region.deploymentCode === deploymentCode) {
                  name = region.name
                }
              })
            }
          }
          return name
        },
      },
      {
        key: 'creation_date',
        name: t('PRODUCTS.TABLE.CREATION_DATE'),
        fieldName: 'created_at',
        minWidth: 25,
        flexGrow: 1,
        isRowHeader: true,
        onRender: (item: ProductConfigRow) => {
          return <span>{dateFormatter(t, item.created_at)}</span>
        },
      },
      {
        key: 'status',
        name: t('PRODUCTS.TABLE.STATUS'),
        fieldName: 'active',
        minWidth: 25,
        flexGrow: 1,
        isRowHeader: true,
        onRender: (item: ProductConfigRow) => {
          return <StatusBadge active={item.active} mode={BadgeMode.circle} size={8} />
        },
      },
      {
        key: 'actions',
        name: t('PRODUCTS.TABLE.ACTION'),
        minWidth: 25,
        flexGrow: 1,
        isResizable: true,
        onRender: (item: ProductConfigRow) => {
          return (
            <span className="d-flex d-flexRow">
              <FeatureRender feature={FEATURE_NAMES.ENVIRONMENT_READ}>
                <span style={isMobile ? { marginRight: 10 } : { marginRight: 20 }}>
                  <ViewDetailsActionButton locale={i18n.language} isMobile={isMobile} onClick={openEditProduct(item)} />
                </span>
              </FeatureRender>
              <FeatureRender feature={FEATURE_NAMES.ENVIRONMENT_AGENT_SETTINGS_READ}>
                <span style={isMobile ? { marginRight: 10 } : { marginRight: 20 }}>
                  <SettingsButton
                    text={t('AGENT_SETTINGS.NAME')}
                    isMobile={isMobile}
                    onClick={openAgentSettingsProduct(item)}
                  />
                </span>
              </FeatureRender>
              <ReportsButton isMobile={isMobile} isExpanded={item.isExpanded} showChevron={!isMobile} />
            </span>
          )
        },
      },
    ])
  }, [products])

  return { columns }
}
