import { createContext, FC, PropsWithChildren, useContext, useState } from 'react'

export enum FeedbackMessageType {
  SUCCESS = 'success',
  ERROR = 'error',
}

export interface FeedbackMessage {
  content: string
  type: FeedbackMessageType
}

interface FeedbackMessageContextType {
  feedbackMessage: FeedbackMessage | undefined
  setSuccessMessage: (message: string) => void
  setErrorMessage: (message: string) => void
  clearFeedbackMessage: () => void
}

const FeedbackMessageContext = createContext<FeedbackMessageContextType | undefined>(undefined)

const MESSAGE_DELAY_MILIS = 3000

export const FeedbackMessageProvider: FC<PropsWithChildren> = ({ children }) => {
  const [message, setMessage] = useState<FeedbackMessage | undefined>()
  const [timer, setTimer] = useState<ReturnType<typeof setTimeout> | undefined>(undefined)

  const applyDelay = () => {
    if (timer) {
      clearTimeout(timer)
    }

    setTimer(
      setTimeout(() => {
        setMessage(undefined)
      }, MESSAGE_DELAY_MILIS)
    )
  }

  const setFeedbackMessage = (message: FeedbackMessage | undefined) => {
    setMessage(message)
    if (message) {
      applyDelay()
    }
  }

  const setSuccessMessage = (content: string) => {
    setFeedbackMessage({ content, type: FeedbackMessageType.SUCCESS })
  }

  const setErrorMessage = (content: string) => {
    setFeedbackMessage({ content, type: FeedbackMessageType.ERROR })
  }

  const clearFeedbackMessage = () => {
    setFeedbackMessage(undefined)
  }

  return (
    <FeedbackMessageContext.Provider
      value={{ feedbackMessage: message, setSuccessMessage, setErrorMessage, clearFeedbackMessage }}
    >
      {children}
    </FeedbackMessageContext.Provider>
  )
}

export const useFeedbackMessage = () => {
  const context = useContext(FeedbackMessageContext)
  if (context === undefined) {
    throw new Error('useFeedbackMessage must be used within a FeedbackMessageProvider')
  }
  return context
}
