import { MOBILE_QUERY, useMediaQuery, ViewDetailsActionButton } from '@flexxibleit/flexxible-ui'
import { IPaginatedTableColumn } from '@flexxibleit/flexxible-ui/dist/lib/components/paginated-table/paginatedTable.types'
import { CellWithFeatureGreenLink } from 'app/components/table-page-body/CellWithFeatureGreenLink'
import CellMultiLanguage from 'app/components/Table/CellMultiLanguage'
import { FlowListItem } from 'app/hooks/flows/useGetFlows'
import { secondsToLabel } from 'app/models/flows/FlowCooldown'
import { typeLabel } from 'app/models/flows/FlowType'
import { FEATURE_NAMES } from 'app/permissions/FeatureName.enum'
import { FeatureRender } from 'app/permissions/FeatureRender'
import { useFeatureRender } from 'app/permissions/useFeatureRender'
import { i18n } from 'i18next'
import { useEffect, useState } from 'react'
import { TFunction } from 'react-i18next'

type Props = {
  t: TFunction
  i18n: i18n
  onGoToDetail: (element: FlowListItem) => void
}

export const useFlowsBuildTable = ({ t, i18n, onGoToDetail }: Props) => {
  const [isMobile] = useMediaQuery(MOBILE_QUERY)
  const [columns, setColumns] = useState<IPaginatedTableColumn[]>([])

  const { checkFeature } = useFeatureRender()
  const tableActionsFeatures = [FEATURE_NAMES.FLOWS_READ]
  const canSeeActions = tableActionsFeatures.some((feature) => checkFeature(feature))

  useEffect(() => {
    setColumns([
      {
        key: 'nameText',
        name: t('TABLE.NAME'),
        fieldName: 'name',
        isRowHeader: true,
        flexGrow: 1,
        onRender: (item: FlowListItem) => {
          return (
            <CellWithFeatureGreenLink
              className="d-flex d-flexAlignItemsCenter"
              feature={FEATURE_NAMES.FLOWS_READ}
              callback={() => onGoToDetail(item)}
            >
              <CellMultiLanguage field={item.name} />
            </CellWithFeatureGreenLink>
          )
        },
      },
      {
        key: 'descriptionText',
        name: t('TABLE.DESCRIPTION'),
        fieldName: 'description',
        isRowHeader: true,
        flexGrow: 1,
        onRender: (item: FlowListItem) => {
          return <CellMultiLanguage field={item.description} />
        },
      },
      {
        key: 'type',
        name: t('TABLE.TYPE'),
        fieldName: 'type',
        isRowHeader: true,
        flexGrow: 1,
        onRender: (item: FlowListItem) => {
          return typeLabel(t)[item.type]
        },
      },
      {
        key: 'detectionOnly',
        name: t('TABLE.DETECTIONONLY'),
        fieldName: 'detectionOnly',
        isRowHeader: true,
        flexGrow: 1,
        onRender: (item: FlowListItem) => {
          return item.detectionOnly
            ? t('organization_details:MODULES.FORM.YES')
            : t('organization_details:MODULES.FORM.NO')
        },
      },
      {
        key: 'secondsCooldown',
        name: t('TABLE.COOLDOWN'),
        fieldName: 'cooldown',
        isRowHeader: true,
        flexGrow: 1,
        onRender: (item: FlowListItem) => {
          return secondsToLabel(t, item.cooldown)
        },
      },
      ...(canSeeActions
        ? [
            {
              key: 'actions',
              name: t('TABLE.ACTION'),
              flexGrow: 1,
              isResizable: true,
              onRender: (item: FlowListItem) => {
                return (
                  <FeatureRender feature={FEATURE_NAMES.FLOWS_READ}>
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'start',
                        justifyContent: 'center',
                      }}
                    >
                      <ViewDetailsActionButton
                        locale={i18n.language}
                        isMobile={isMobile}
                        onClick={() => onGoToDetail(item)}
                      />
                    </div>
                  </FeatureRender>
                )
              },
            },
          ]
        : []),
    ])
  }, [isMobile])

  return { columns }
}
