import { Action, Location } from '@remix-run/router'

const createBrowserHistory = require('history').createBrowserHistory

export default createBrowserHistory()

export interface Transition {
  action: Action
  location: Location
  retry(): void
}
