import React from 'react'
import HighchartsReact from 'highcharts-react-official'
import Highcharts from 'highcharts/highstock'
// @ts-ignore
import hcRounded from 'highcharts-rounded-corners'
import hcMore from 'highcharts/highcharts-more'
import './Chart.component.scss'

hcMore(Highcharts)
hcRounded(Highcharts)

export const ChartComponent = ({ options }: any) => {

    return (
        <HighchartsReact
            className="chart__container"
            highcharts={Highcharts}
            options={options}
        />
    )
}
