import { PaginationArgs } from '@flexxibleit/flexxible-ui/dist/lib/components/table/Paginator'
import {
  ColumnActionsMode,
  DetailsListLayoutMode,
  IColumn,
  SelectionMode,
  TooltipHost,
  TooltipOverflowMode,
} from '@fluentui/react'
import Table from 'app/components/Table/Table.component'
import { FlexxBuildProcess, useFindAllFlexxBuildProcess } from 'app/hooks/flexxBuildProcess/useFindAllFlexxBuildProcess'
import { DateFormat, dateFormatter } from 'app/services/date-formatter'
import { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

export const FlexxBuildOperationsLogTable = () => {
  const { t } = useTranslation('flexxbuild')
  const [paginationState, setPaginationState] = useState<PaginationArgs>(() => ({
    page: 1,
    perPage: 20,
  }))
  const { data, isLoading, isFetching } = useFindAllFlexxBuildProcess(paginationState)

  const processesPage: FlexxBuildProcess[] = useMemo(() => data?.results || [], [data])

  const columns: IColumn[] = [
    {
      key: 'business',
      name: t('AUDIT_LOG.TABLE.BUSINESS', 'Business'),
      fieldName: 'business',
      minWidth: 100,
      maxWidth: 200,
      isRowHeader: true,
      columnActionsMode: ColumnActionsMode.disabled,
      onRender: (item: FlexxBuildProcess) => {
        return item.business.name
      },
    },
    {
      key: 'flexxBuildProcessId',
      name: t('AUDIT_LOG.TABLE.FLEXX_BUILD_PROCESS_ID', 'Flexx Build Process ID'),
      fieldName: 'flexxBuildProcessId',
      minWidth: 200,
      maxWidth: 200,
      isRowHeader: true,
      columnActionsMode: ColumnActionsMode.disabled,
    },
    {
      key: 'processStatus',
      name: t('AUDIT_LOG.TABLE.PROCESS_STATUS', 'Process Status'),
      fieldName: 'processStatus',
      minWidth: 100,
      maxWidth: 150,
      isRowHeader: true,
      columnActionsMode: ColumnActionsMode.disabled,
    },
    {
      key: 'processName',
      name: t('AUDIT_LOG.TABLE.PROCESS_NAME', 'Process Name'),
      fieldName: 'processName',
      minWidth: 100,
      maxWidth: 200,
      isRowHeader: true,
      columnActionsMode: ColumnActionsMode.disabled,
    },
    {
      key: 'error',
      name: t('AUDIT_LOG.TABLE.ERROR', 'Error'),
      fieldName: 'error',
      minWidth: 100,
      maxWidth: 200,
      isRowHeader: true,
      isPadded: true,
      columnActionsMode: ColumnActionsMode.disabled,
      onRender: (item: FlexxBuildProcess) => {
        const text = item.error ?? ''
        return (
          <TooltipHost id={item._id} content={text} overflowMode={TooltipOverflowMode.Parent}>
            <span aria-describedby={item._id}>{text}</span>
          </TooltipHost>
        )
      },
      className: 'cell-ellipsis',
    },
    {
      key: 'initiatorFeature',
      name: t('AUDIT_LOG.TABLE.INITIATOR_FEATURE', 'Initiator Feature'),
      fieldName: 'initiatorFeature',
      minWidth: 100,
      maxWidth: 200,
      isRowHeader: true,
      columnActionsMode: ColumnActionsMode.disabled,
    },
    {
      key: 'created_at',
      name: t('AUDIT_LOG.TABLE.CREATED_AT', 'Created At'),
      fieldName: 'created_at',
      minWidth: 100,
      maxWidth: 150,
      isRowHeader: true,
      columnActionsMode: ColumnActionsMode.disabled,
      onRender: (item: FlexxBuildProcess) => {
        return dateFormatter(t, item.created_at, DateFormat.DATETIME)
      },
    },
    {
      key: 'updated_at',
      name: t('AUDIT_LOG.TABLE.UPDATED_AT', 'Updated At'),
      fieldName: 'updated_at',
      minWidth: 100,
      maxWidth: 150,
      isRowHeader: true,
      columnActionsMode: ColumnActionsMode.disabled,
      onRender: (item: FlexxBuildProcess) => {
        return dateFormatter(t, item.updated_at, DateFormat.DATETIME)
      },
    },
  ]

  const handleOnPaginationChange = (newPaginationState: PaginationArgs) => {
    setPaginationState(newPaginationState)
  }

  return (
    <Table
      tableId="flexxbuild-operations"
      isLoading={isLoading || isFetching}
      isEmpty={processesPage?.length === 0}
      tableProps={{
        columns,
        items: processesPage,
        compact: false,
        selectionMode: SelectionMode.none,
        layoutMode: DetailsListLayoutMode.justified,
        isHeaderVisible: true,
      }}
      paginationProps={
        data
          ? {
              pagination: paginationState,
              onPaginationChange: handleOnPaginationChange,
              paginatedData: data,
              isFetching: isFetching,
            }
          : undefined
      }
    />
  )
}
