import { DatePicker } from '@fluentui/react'
import { MOBILE_QUERY } from 'app/config/media-query'
import useMediaQuery from 'app/hooks/utils/useMediaQuery'
import { DateFormat, dateFormatter } from 'app/services/date-formatter'
import { DateTime } from 'luxon'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

interface Props {
  startDate: Date
  endDate: Date
  onDateFromSelected: (date: Date | null | undefined) => void
  onDateToSelected: (date: Date | null | undefined) => void
}

export const DateFromToPicker = ({ startDate, endDate, onDateFromSelected, onDateToSelected }: Props) => {
  const { t } = useTranslation('efficiency_operations')
  const [isMobile] = useMediaQuery(MOBILE_QUERY)

  const formatFilterDate = (date?: Date) => {
    if (!date) return ''

    return dateFormatter(t, date, DateFormat.Medium)
  }

  return (
    <div
      style={isMobile ? { marginBottom: 30 } : {}}
      className="d-flex d-flexRow d-flexAlignItemsCenter d-flexJustifyBetween"
    >
      <DatePicker
        value={startDate}
        formatDate={formatFilterDate}
        style={{ width: 160, marginRight: 27 }}
        maxDate={endDate || DateTime.now().endOf('day').toJSDate()}
        placeholder={t('FROM')}
        onSelectDate={onDateFromSelected}
      />
      <DatePicker
        value={endDate}
        formatDate={formatFilterDate}
        style={{ width: 160 }}
        minDate={startDate}
        maxDate={DateTime.now().endOf('day').toJSDate()}
        placeholder={t('TO')}
        onSelectDate={onDateToSelected}
      />
    </div>
  )
}
