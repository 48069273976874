import { createContext, useContext } from 'react'

export interface OperationsFilterState {
  success: boolean,
  warning: boolean,
  failed: boolean,
  endUserMicroservice: boolean,
  automatic: boolean,
  manual: boolean
}

interface OperationsFilterContextType {
  state: OperationsFilterState
  setState: (filters: OperationsFilterState) => void
}

export const OperationsFilterContext = createContext<OperationsFilterContextType | undefined>(undefined)

export function useOperationsFilter() {
  const context = useContext(OperationsFilterContext)
  if (context === undefined) {
    throw new Error('useOperationFilters must be used within a OperationsFilterContext.Provider')
  }

  return context
}
