import { FeedbackMessageBar } from 'app/components/FeedbackMessageBar/FeedbackMessageBar'
import { useOrganizationSelection } from 'app/globals/useOrganizationSelection'
import { FEATURE_NAMES } from 'app/permissions/FeatureName.enum'
import { withFeatureRender } from 'app/permissions/withFeatureRender'
import { useTranslation } from 'react-i18next'
import { FlowsFeature } from './list/FlowsFeature'
import { TablePageBody } from '@flexxibleit/flexxible-ui'
import { useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { useLocalStorageSearchParams } from 'app/hooks/utils/useLocalStorageSearchParams'
import TitleComponent from 'app/components/PageBody/PageTitle.component'

const Flows = () => {
  const { selectedOrganizationId, selectedOrganization } = useOrganizationSelection()

  const { t } = useTranslation('flows')

  const { getPersistedSearchParams } = useLocalStorageSearchParams(false)
  const navigate = useNavigate()
  const location = useLocation()
  const baseUrl = location.pathname.split('/')[1]

  useEffect(() => {
    if (!selectedOrganizationId) {
      return
    }

    const finalSearchParams = location.search !== '' ? location.search : getPersistedSearchParams(location.pathname)
    navigate(`/${baseUrl}/${finalSearchParams}`)
  }, [selectedOrganizationId])

  return (
    <TablePageBody
      title={t('TITLE')}
      titleComponent={
        <TitleComponent title={`${selectedOrganization?.name} - ${t('TITLE')}`} type={selectedOrganization?.type} />
      }
      isLoading={false}
    >
      <FeedbackMessageBar />
      {selectedOrganizationId && <FlowsFeature organizationId={selectedOrganizationId} />}
    </TablePageBody>
  )
}

export const FlowsPage = withFeatureRender(Flows, FEATURE_NAMES.FLOWS_READ)
