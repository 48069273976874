import { ViewDetailsActionButton } from '@flexxibleit/flexxible-ui'
import { ColumnActionsMode, DetailsListLayoutMode, IColumn, Link, SelectionMode } from '@fluentui/react'
import Table from '../../../components/Table/Table.component'
import { MicroserviceEvent } from '../../../models/microservices/MicroserviceEvent'
import { FC } from 'react'
import useMediaQuery from '../../../hooks/utils/useMediaQuery'
import { MOBILE_QUERY } from '../../../config/media-query'
import { useNavigate } from 'react-router-dom'
import { label } from '../../../models/microservices/MicroserviceEventType'
import { useTranslation } from 'react-i18next'
import EmptyStatus from '../../../components/status/empty-status'
import { labelStatus } from '../../../models/microservices/MicroserviceEventStatus'
import { DateFormat, dateFormatter } from 'app/services/date-formatter'
import i18next from 'i18next'
import IconLanguage from 'app/components/IconLanguage/IconLanguage'
import { getTranslation } from 'app/models/microservices'

interface Props {
  microserviceEvents: Array<MicroserviceEvent>
}

export const MicroservicesAuditLogTable: FC<Props> = ({ microserviceEvents }) => {
  const navigate = useNavigate()
  const [isMobile] = useMediaQuery(MOBILE_QUERY)
  const { t, i18n } = useTranslation('microservices')

  const onViewDetailsClicked = (microserviceEvent: MicroserviceEvent) => {
    navigate(`/microservices-events/${microserviceEvent._id}`)
  }

  const columns: IColumn[] = [
    {
      key: 'microservice',
      name: t('AUDIT_LOG.TABLE.MICROSERVICE'),
      fieldName: 'microservice',
      minWidth: 200,
      isRowHeader: true,
      onRender: (item: MicroserviceEvent) => {
        const name = getTranslation(item.microservice.name, i18next.language)
        const itemName = name.text
        const showNameIcon = name.translatedByIA

        return (
          <Link onClick={() => onViewDetailsClicked(item)}>
            {itemName}
            {showNameIcon && <IconLanguage tam="16" />}
          </Link>
        )
      },
      columnActionsMode: ColumnActionsMode.disabled,
    },
    {
      key: 'type',
      name: t('AUDIT_LOG.TABLE.TYPE'),
      fieldName: 'type',
      minWidth: 100,
      isRowHeader: true,
      onRender: (item: MicroserviceEvent) => <span>{label(t)[item.type]}</span>,
      columnActionsMode: ColumnActionsMode.disabled,
    },
    {
      key: 'status',
      name: t('AUDIT_LOG.TABLE.STATUS'),
      fieldName: 'status',
      minWidth: 100,
      isRowHeader: true,
      onRender: (item: MicroserviceEvent) => <span>{labelStatus(t, item.status ?? '')}</span>,
      columnActionsMode: ColumnActionsMode.disabled,
    },
    {
      key: 'initiatedBy',
      name: t('AUDIT_LOG.TABLE.INITIATED_BY'),
      fieldName: 'initiatedBy',
      minWidth: 300,
      isRowHeader: true,
      columnActionsMode: ColumnActionsMode.disabled,
    },
    {
      key: 'loggedAt',
      name: t('AUDIT_LOG.TABLE.LOGGED_AT'),
      fieldName: 'loggedAt',
      minWidth: 200,
      isRowHeader: true,
      onRender: (item: MicroserviceEvent) => (
        <span>{dateFormatter(t, new Date(item.loggedAt), DateFormat.DATETIME)}</span>
      ),
      columnActionsMode: ColumnActionsMode.disabled,
    },
    {
      key: 'actions',
      name: t('AUDIT_LOG.TABLE.ACTIONS'),
      minWidth: 100,
      onRender: (item: MicroserviceEvent) => {
        return (
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'start',
              justifyContent: 'center',
            }}
          >
            <ViewDetailsActionButton
              locale={i18n.language}
              isMobile={isMobile}
              onClick={() => onViewDetailsClicked(item)}
            />
          </div>
        )
      },
      columnActionsMode: ColumnActionsMode.disabled,
    },
  ]

  if (microserviceEvents.length === 0) {
    return (
      <div style={{ margin: '100px' }}>
        <EmptyStatus message={t('AUDIT_LOG.EMPTY_STATUS_MESSAGE')} />
      </div>
    )
  }

  return (
    <Table
      tableId="microservices-audit-log"
      tableProps={{
        columns,
        items: microserviceEvents,
        compact: false,
        selectionMode: SelectionMode.none,
        layoutMode: DetailsListLayoutMode.justified,
        isHeaderVisible: true,
      }}
    />
  )
}
