import { ViewDetailsActionButton } from '@flexxibleit/flexxible-ui'
import {
  ColumnActionsMode,
  DetailsListLayoutMode,
  IColumn,
  Link,
  SelectionMode,
  Spinner,
  TooltipDelay,
  TooltipHost,
  TooltipOverflowMode,
} from '@fluentui/react'
import Table from '../../../components/Table/Table.component'
import useMediaQuery from '../../../hooks/utils/useMediaQuery'
import { MOBILE_QUERY } from '../../../config/media-query'
import { useNavigate } from 'react-router-dom'
import EmptyStatus from '../../../components/status/empty-status'
import { useTranslation } from 'react-i18next'
import { lazy, Suspense } from 'react'
import { MarketplaceMicroserviceView } from '../../../hooks/microservices/useGetMarketplaceMicroservices'
import { Badge } from '../../../components/Badge/Badge'
import StatusBadge, { BadgeMode } from '../../../components/status-badge/status-badge.component'
import i18next from 'i18next'
import { getTranslation } from 'app/models/microservices'
import IconLanguage from 'app/components/IconLanguage/IconLanguage'

const BootstrapIcon = lazy(() => import('app/components/BootstrapIcon/BootstrapIcon'))

export interface MicroservicesTableModeProps {
  microservices: MarketplaceMicroserviceView[]
}

export const MicroservicesMarketplaceTable = ({ microservices }: MicroservicesTableModeProps) => {
  const navigate = useNavigate()
  const [isMobile] = useMediaQuery(MOBILE_QUERY)
  const { t, i18n } = useTranslation('microservices')

  const handleOnClickViewDetails = (item: MarketplaceMicroserviceView) => {
    navigate(`/microservices/${item.id}`, {
      state: { fromMarketplace: true },
    })
  }

  const renderMicroserviceCategory = (item: MarketplaceMicroserviceView) => {
    const category = getTranslation(item.category, i18next.language)
    const itemCategory = category.text
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'flex-start',
        }}
      >
        <div
          style={{
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }}
        >
          <TooltipHost
            content={itemCategory?.toString()}
            delay={TooltipDelay.zero}
            overflowMode={TooltipOverflowMode.Parent}
          >
            {itemCategory?.toString()}
          </TooltipHost>
        </div>
      </div>
    )
  }

  const renderMicroserviceName = (item: MarketplaceMicroserviceView) => {
    const name = getTranslation(item.name, i18next.language)
    const itemName = name.text
    const showIcon = name.translatedByIA

    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'flex-start',
        }}
      >
        <div
          style={{
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }}
        >
          <TooltipHost
            content={itemName?.toString()}
            delay={TooltipDelay.zero}
            overflowMode={TooltipOverflowMode.Parent}
          >
            <Link onClick={() => handleOnClickViewDetails(item)}>
              {itemName?.toString()} {showIcon && <IconLanguage tam="16" />}
            </Link>
          </TooltipHost>
        </div>
      </div>
    )
  }

  const columns: IColumn[] = [
    {
      key: 'iconName',
      name: t('MARKETPLACE.TABLE.ICON'),
      fieldName: 'iconName',
      minWidth: 50,
      isRowHeader: true,
      isIconOnly: true,
      maxWidth: 50,
      onRender: (item: MarketplaceMicroserviceView) => {
        if (!item.iconName) return null

        return (
          <Suspense fallback={<Spinner />}>
            <BootstrapIcon
              iconName={item.iconName}
              size={24}
              style={item.iconBackground ? { color: item.iconBackground } : {}}
            />
          </Suspense>
        )
      },
      columnActionsMode: ColumnActionsMode.disabled,
    },
    {
      key: 'name',
      name: t('MARKETPLACE.TABLE.NAME'),
      fieldName: 'name',
      minWidth: 300,
      isRowHeader: true,
      onRender: renderMicroserviceName,
      columnActionsMode: ColumnActionsMode.disabled,
    },
    {
      key: 'category',
      name: t('MARKETPLACE.TABLE.CATEGORY'),
      fieldName: 'category',
      minWidth: 200,
      isRowHeader: true,
      onRender: renderMicroserviceCategory,
      columnActionsMode: ColumnActionsMode.disabled,
    },
    {
      key: 'library',
      name: t('MARKETPLACE.TABLE.LIBRARY'),
      fieldName: 'library',
      minWidth: 180,
      isRowHeader: true,
      columnActionsMode: ColumnActionsMode.disabled,
      onRender: (item: MarketplaceMicroserviceView) => {
        return (
          <>
            {item.organization.isTrial && <Badge>{t('organizations:TRIAL')}</Badge>}
            <span>{item.organization.name}</span>
          </>
        )
      },
    },
    {
      key: 'active',
      name: t('MICROSERVICE_STATUS.ACTIVE'),
      fieldName: 'active',
      minWidth: 0,
      isPadded: true,
      isRowHeader: true,
      columnActionsMode: ColumnActionsMode.disabled,
      onRender: (item: MarketplaceMicroserviceView) => {
        return (
          <StatusBadge
            active={item.active || false}
            mode={BadgeMode.circle}
            size={8}
            labels={{ active: t('MICROSERVICE_STATUS.ACTIVE'), inactive: t('MICROSERVICE_STATUS.INACTIVE') }}
          />
        )
      },
    },
    {
      key: 'actions',
      name: t('MARKETPLACE.TABLE.ACTIONS'),
      minWidth: isMobile ? 50 : 100,
      onRender: (item: MarketplaceMicroserviceView) => {
        return (
          <ViewDetailsActionButton
            locale={i18n.language}
            isMobile={isMobile}
            onClick={() => handleOnClickViewDetails(item)}
          />
        )
      },
      columnActionsMode: ColumnActionsMode.disabled,
    },
  ]

  const renderMicroservicesTable = () => {
    if (!microservices.length) {
      return (
        <div style={{ margin: '100px' }}>
          <EmptyStatus message={t('MARKETPLACE.EMPTY_STATUS_MESSAGE')} />
        </div>
      )
    }

    return (
      <Table
        tableId="microservices-marketplace"
        tableProps={{
          columns,
          items: microservices,
          compact: false,
          selectionMode: SelectionMode.none,
          layoutMode: DetailsListLayoutMode.justified,
          isHeaderVisible: true,
        }}
      />
    )
  }

  return renderMicroservicesTable()
}
