import React, { ComponentType } from 'react'
import { FEATURE_NAME } from './FeatureName.enum'
import { useFeatureRender } from './useFeatureRender'
import { UnauthorizedPage } from '../pages/unauthorized/unauthorized.page'
import { useOrganizationSelection } from '../globals/useOrganizationSelection'
import { LoadingSpinner } from '@flexxibleit/flexxible-ui'

export const withFeatureRender = (Component: ComponentType<any>, featureName: FEATURE_NAME) => {
  return ({ ...props }) => {
    const { rolesOnBusiness } = useOrganizationSelection()
    const { hasFeature } = useFeatureRender(featureName)

    if (!rolesOnBusiness) {
      return <LoadingSpinner />
    }

    if (!hasFeature) {
      return <UnauthorizedPage />
    }

    return <Component {...props} />
  }
}
