import { GraphQLClient } from 'graphql-request'
import { GRAPH_BASE_URL, STORAGE_USER_KEY } from '../config/Consts'

function requestMiddleware(request: any) {
  const item: any = localStorage.getItem(STORAGE_USER_KEY);
  const token = JSON.parse(item)?.token || "";
  return {
    ...request,
    headers: { ...request.headers, 'Authorization': `Bearer ${token}` },
  }
}

function responseMiddleware(response: any) {
  const formattedResponse = JSON.parse(JSON.stringify(response)).response
  if (
    formattedResponse &&
    formattedResponse.errors &&
    (formattedResponse.errors[0].message.includes('jwt must be provided') ||
      formattedResponse.errors[0].message.includes('You are not authenticated.'))) {
    location.href = '/login'
  }
}

export default new GraphQLClient(GRAPH_BASE_URL, {
  requestMiddleware,
  responseMiddleware,
});
