import { useMutation, useQueryClient } from '@tanstack/react-query'
import { FlowTargetType } from 'app/models/flows/FlowTargetType'
import graphClient from 'app/query-client/graphClient'
import { gql } from 'graphql-request'


interface Request {
  targetType: FlowTargetType
  workspaces: string[]
  workspaceGroups: string[]
  reportingGroups: string[]
}

const mutation = gql`
  mutation ($id: ID!, $input: UpdateFlowTargetGraphqlRequest!) {
    updateFlowTarget(id: $id, input: $input)
  }
`

const updateFlowTarget = async (id: string, input: Request): Promise<string> => {
  await graphClient.request(mutation, { id, input })
  return id
}

export const useUpdateFlowTarget = () => {
  const queryClient = useQueryClient()

  return useMutation(
    ['updateFlowTarget'],
    ({ id, input }: { id: string; input: Request }) => updateFlowTarget(id, input),
    {
      onSuccess: (id) => {
        queryClient.invalidateQueries(['flow', id])
      },
    }
  )
}
