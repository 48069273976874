import { useNavigate, useParams } from 'react-router-dom'
import {
  DashboardCardAction,
  DashboardMetricCard,
} from '../../../../../../components/Dashboard/DashboardMetricCard/DashboardMetricCard'
import { usePatchManagementSummaryData } from '../../usePatchManagementSummaryData'
import { useTranslation } from "react-i18next";

interface Props {
  isInfo?: boolean
}

export const MustHaveApprovedPatchesCard = (props: Props) => {
  const { organizationId } = useParams()
  const { data: patchSummary } = usePatchManagementSummaryData()
  const navigate = useNavigate()
  const {t} = useTranslation('patch_management')

  const totalPatchCount = patchSummary?.patchesBySeverityAndStatus.reduce((acc, patch) => acc + patch.value, 0) || 0
  const totalNotApprovedPatchCount =
    patchSummary?.patchesBySeverityAndStatus.reduce((acc, patch) => {
      if (patch.status === 'NOT_APPROVED') {
        return acc
      }
      return acc + patch.value
    }, 0) || 0

  const action = (): DashboardCardAction | undefined => {
    return {
      onClick: () => {
        navigate(`/organization/${organizationId}/patch-management/windows-patch-policies/`)
      },
      label: 'Fix',
    }
  }

  return (
    <DashboardMetricCard
      title={t('SUMMARY.RULES.MUST_HAVE_APPROVED_PATCHES.SUMMARY_ITEM_CARD.TITLE')}
      subtitle={`${totalPatchCount.toLocaleString()} ${t('SUMMARY.RULES.MUST_HAVE_APPROVED_PATCHES.SUMMARY_ITEM_CARD.SUBTITLE')}`}
      icon={{
        name: props.isInfo ? 'CheckMark' : 'Warning',
        color: props.isInfo ? 'green' : 'orange',
      }}
      value={totalNotApprovedPatchCount}
      action={props.isInfo ? undefined : action()}
    />
  )
}
