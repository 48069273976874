export const useRolesBuildExport = () => {
  const exportColumns = [
    {
      key: 'name',
      header: 'Name',
      width: 20,
    },
    {
      key: 'assignedUsers',
      header: 'Assigned users',
      width: 20,
    },
    {
      key: 'assignedOrganizations',
      header: 'Assigned organizations',
      width: 20,
    },
    {
      key: 'created_by',
      header: 'Created by',
      width: 20,
    },
    {
      key: 'updated_by',
      header: 'Last update by',
      width: 20,
    },
    {
      key: 'created_at',
      header: 'Creation date',
      width: 20,
    },
    {
      key: 'updated_at',
      header: 'Last update date',
      width: 20,
    },
  ]

  return { exportColumns }
}
