import { DisplayField } from 'app/components/DisplayField'
import { dateFormatter } from 'app/services/date-formatter'
import { useTranslation } from 'react-i18next'
import DataTableIcon from '../../../assets/img/data-table-svgrepo-com.svg'
import LayersIcon from '../../../assets/img/layers-svgrepo-com.svg'
import UserMultipleIcon from '../../../assets/img/user-multiple-svgrepo-com.svg'
import EditButton from '../../../components/Buttons/EditButton.component'
import FlexxibleCard from '../../../components/FlexxibleCard/FlexxibleCard.component'
import Col from '../../../components/Layouts/Col'
import Row from '../../../components/Layouts/Row'
import { MOBILE_QUERY, TABLET_QUERY } from '../../../config/media-query'
import useCreateForm from '../../../hooks/useCreateForm'
import useMediaQuery from '../../../hooks/utils/useMediaQuery'
import { WorkspaceGroup } from '../../../query-client/types'
import SummaryCard from '../components/SummaryCard/SummaryCard'
import WorkspaceGroupForm from './WorkspaceGroupForm'
import { WorkspaceGroupType } from 'app/models/workspaces-groups/WorkspaceGroupType'
import { FeatureRender } from '../../../permissions/FeatureRender'
import { FEATURE_NAMES } from '../../../permissions/FeatureName.enum'

interface Props {
  workspaceGroup?: WorkspaceGroup
  workspaceCount?: number
}

export const WorkspaceGroupDetails = ({ workspaceGroup, workspaceCount }: Props) => {
  const { t } = useTranslation('organization_details')

  const [isMobile] = useMediaQuery(MOBILE_QUERY)
  const [isTablet] = useMediaQuery(TABLET_QUERY)
  const cardProps = {
    style: { marginTop: 30 },
  }

  const _onEditWorkspaceGroupDetailsClicked = useCreateForm(
    {
      title: t('WORKSPACE_GROUPS.FORM.EDIT_TITLE'),
      isFooterAtBottom: true,
    },
    <WorkspaceGroupForm workspaceGroup={workspaceGroup} />
  )

  const renderWorkspaceGroupCreatorFullName = (): string => {
    if (!workspaceGroup) {
      return ''
    }
    if (!workspaceGroup.created_by) {
      return t('general:USER.DELETED')
    }
    return `${workspaceGroup.created_by.name ?? ''} ${workspaceGroup.created_by.surname ?? ''}`
  }

  const numberOfWorkspaces = workspaceCount ?? 0

  return (
    <div
      className={isMobile || isTablet ? 'd-flex d-flexCol d-flexColReverse' : 'd-flex d-flexRow col-xs-12'}
      style={isMobile || isTablet ? { gap: 0 } : { gap: 30, paddingRight: 20 }}
    >
      <FlexxibleCard cardProps={cardProps} styleNames="col-md-9 d-flex d-flexCol d-flexJustifyBetween">
        <>
          <Row>
            <Col className="col-xs-12 col-lg-6">
              <DisplayField label={t('WORKSPACE_GROUPS.TABLE.NAME')}>{workspaceGroup?.name}</DisplayField>
            </Col>
          </Row>

          <Row>
            <Col className="col-xs-12 col-lg-6">
              <DisplayField label={t('WORKSPACE_GROUPS.TABLE.DESCRIPTION')}>{workspaceGroup?.description}</DisplayField>
            </Col>
          </Row>
          {workspaceGroup?.type === WorkspaceGroupType.DYNAMIC && (
            <Row>
              <Col className="col-xs-12 col-lg-6">
                <DisplayField label={t('WORKSPACE_GROUPS.TABLE.FILTER')}>{workspaceGroup?.filter}</DisplayField>
              </Col>
            </Row>
          )}
          <Row>
            <Col className="col-xs-12 col-lg-6">
              <DisplayField label={t('WORKSPACE_GROUPS.TABLE.TYPE')}>
                {workspaceGroup?.type === WorkspaceGroupType.DYNAMIC
                  ? t('WORKSPACE_GROUPS.TYPE.DYNAMIC')
                  : t('WORKSPACE_GROUPS.TYPE.STATIC')}
              </DisplayField>
            </Col>
          </Row>

          <FeatureRender feature={FEATURE_NAMES.WORKSPACE_GROUPS_UPDATE}>
            <div className="d-flex d-flexRowReverse">
              <EditButton onClick={_onEditWorkspaceGroupDetailsClicked} />
            </div>
          </FeatureRender>
        </>
      </FlexxibleCard>

      <div className="col-md-3 summary-container d-flexJustifyCenter d-flexAlignItemsCenter">
        <>
          <div
            className={isMobile || isTablet ? 'd-flex d-flexRow d-flexWrap' : 'd-flex d-flexCol '}
            style={{ gap: 20 }}
          >
            <SummaryCard
              Icon={LayersIcon}
              title={t('WORKSPACE_GROUPS.TABLE.NUMBER_OF_WORKSPACES')}
              text={numberOfWorkspaces.toString()}
            />

            <SummaryCard
              Icon={UserMultipleIcon}
              title={t('WORKSPACE_GROUPS.TABLE.CREATED_BY')}
              text={renderWorkspaceGroupCreatorFullName()}
            />

            <SummaryCard
              Icon={DataTableIcon}
              title={t('WORKSPACE_GROUPS.TABLE.CREATION_DATE')}
              text={workspaceGroup?.created_at ? dateFormatter(t, workspaceGroup?.created_at) : ''}
            />

            {workspaceGroup?.type === WorkspaceGroupType.DYNAMIC && (
              <SummaryCard
                Icon={DataTableIcon}
                title={t('WORKSPACE_GROUPS.TABLE.LAST_UPDATED')}
                text={workspaceGroup?.lastUpdated ? dateFormatter(t, workspaceGroup?.lastUpdated) : ''}
              />
            )}
          </div>
        </>
      </div>
    </div>
  )
}
