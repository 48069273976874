import { TFunction } from 'i18next'

interface Props {
  t: TFunction
}

export interface ExportColumns {
  key: string,
  header: string,
  width: number,
  isDate?: boolean
}

export const useInstalledAppVersionsBuildExport = ({ t }: Props): { installedAppVersionsTableExportColumns: ExportColumns[] } => {
  const installedAppVersionsTableExportColumns = [
    {
      key: 'version',
      header: t('INSTALLED_APPS.DETAILS.VERSIONS.TABLE.VERSION'),
      width: 20,
    },
    {
      key: 'workspacesCount',
      header: t('INSTALLED_APPS.DETAILS.VERSIONS.TABLE.WORKSPACES_COUNT'),
      width: 14,
    },
    {
      key: 'discoveredAt',
      header: t('INSTALLED_APPS.DETAILS.VERSIONS.TABLE.DISCOVERED_AT'),
      width: 18,
      isDate: true,
    },
    {
      key: 'reportedAt',
      header: t('INSTALLED_APPS.DETAILS.VERSIONS.TABLE.REPORTED_AT'),
      width: 18,
      isDate: true,
    },
  ]

  return { installedAppVersionsTableExportColumns }
}