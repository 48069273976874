import { gql } from 'graphql-request'
import graphClient from '../../query-client/graphClient'
import { useQuery, UseQueryResult } from '@tanstack/react-query'

export interface OrganizationDailyOperationsView {
  id: string
  business: string
  successCounter: number
  warningCounter: number
  failedCounter: number
  date: Date
  flexxAnalyzer: {
    successCounter: number
    warningCounter: number
    failedCounter: number
  }
  flexxWorkspaces: {
    successCounter: number
    warningCounter: number
    failedCounter: number
  }
  manual: {
    successCounter: number
    warningCounter: number
    failedCounter: number
  }
  auto: {
    successCounter: number
    warningCounter: number
    failedCounter: number
  }
  endUserMicroservice: {
    successCounter: number
    warningCounter: number
    failedCounter: number
  }
}

interface Response {
  viewOrganizationOperationsHistory: Array<OrganizationDailyOperationsView>
}

const getOrganizationOperationsHistoryQuery = gql`
  query ($input: ViewOrganizationOperationsHistoryGraphqlRequest!) {
    viewOrganizationOperationsHistory(input: $input) {
      id
      business
      successCounter
      warningCounter
      failedCounter
      date
      flexxAnalyzer {
        successCounter
        warningCounter
        failedCounter
      }
      flexxWorkspaces {
        successCounter
        warningCounter
        failedCounter
      }
      manual {
        successCounter
        warningCounter
        failedCounter
      }
      auto {
        successCounter
        warningCounter
        failedCounter
      }
      endUserMicroservice {
        successCounter
        warningCounter
        failedCounter
      }
    }
  }
`

async function getOrganizationOperationsHistory(
  organizationId: string,
  startDate: Date,
  endDate: Date
): Promise<OrganizationDailyOperationsView[]> {
  const response: Response = await graphClient.request(getOrganizationOperationsHistoryQuery, {
    input: {
      organizationId,
      startDate,
      endDate,
    },
  })
  return response.viewOrganizationOperationsHistory
}

export const useViewOrganizationOperationsHistory = (
  organizationId: string,
  startDate: Date,
  endDate: Date
): UseQueryResult<OrganizationDailyOperationsView[]> => {
  return useQuery(
    ['efficiencyOperations', organizationId, startDate, endDate],
    () => getOrganizationOperationsHistory(organizationId, startDate, endDate),
    {
      refetchOnWindowFocus: false,
    }
  )
}
