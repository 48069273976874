import { MultiLanguageField } from 'app/models/microservices'
import { FlowTargetType } from 'app/models/flows/FlowTargetType'
import { Edge } from 'reactflow'
import { create } from 'zustand'
import { CustomNode } from './editor/nodes'

interface FlowTabState {
  target?: {
    targetType?: FlowTargetType
    workspaces: string[]
    workspaceGroups: string[]
    reportingGroups: string[]
  }
  flow?: {
    nodes: CustomNode[]
    edges: Edge[]
  }
  notification?: {
    initText: MultiLanguageField[]
    okText: MultiLanguageField[]
    koText: MultiLanguageField[]
    enabled: boolean
  }
  setTarget: (target: FlowTabState['target']) => void
  setFlow: (flow: FlowTabState['flow']) => void
  setNotification: (notification: FlowTabState['notification']) => void
  clear: () => void
}

export const useFlowTabStore = create<FlowTabState>()((set) => ({
  target: undefined,
  flow: undefined,
  notification: undefined,
  setTarget: (target: FlowTabState['target']) => set({ target }),
  setFlow: (flow: FlowTabState['flow']) => set({ flow }),
  setNotification: (notification: FlowTabState['notification']) => set({ notification }),
  clear: () => set({ target: undefined, flow: undefined, notification: undefined }),
}))
