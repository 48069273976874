import FD_icons_BC from '../assets/img/backup-copies.svg'
import FD_icons_CUSTOM from '../assets/img/custom-module.svg'
import FD_icons_CM from '../assets/img/cluster-management.svg'
import FD_icons_FIREWALL from '../assets/img/firewall.svg'
import FD_icons_FA from '../assets/img/flexanalyzer.svg'
import FD_icons_FAU from '../assets/img/flexautomation.svg'
import FD_icons_FM from '../assets/img/flexmonitoring.svg'
import FD_icons_FW from '../assets/img/flexworkspaces.svg'
import FD_icons_PS from '../assets/img/perimeter-security.svg'
import FD_icons_VP from '../assets/img/virtualization-platform.svg'
import FD_icons_DL from '../assets/img/download.svg'

export const getIcon = (module: string) => {
  switch (module) {
    case 'custom':
      return FD_icons_CUSTOM
    case 'backup_copies':
      return FD_icons_BC
    case 'cluster_management':
      return FD_icons_CM
    case 'firewall':
      return FD_icons_FIREWALL
    case 'flexx_analyzer':
      return FD_icons_FA
    case 'flexx_automation':
      return FD_icons_FAU
    case 'flexx_monitoring':
      return FD_icons_FM
    case 'flexx_workspaces':
      return FD_icons_FW
    case 'perimeter_security':
      return FD_icons_PS
    case 'virtualization_platform':
      return FD_icons_VP
    case 'download':
      return FD_icons_DL
    default:
      return FD_icons_CUSTOM
  }
}

export const getModuleIcon = (module: string, width: number = 25, height: number = 25) => {
  return <img src={getIcon(module)} width={width} height={height} />
}
