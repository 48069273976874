import { ILabelStyles, Label } from '@fluentui/react'
import { CSSProperties } from 'react'
import { useTranslation } from 'react-i18next'

export enum BadgeMode {
  label = 0,
  circle = 1,
}

interface Props {
  active: boolean
  mode: BadgeMode
  size?: number,
  withText?: boolean
  labels?: {
    active: string
    inactive: string
  }
}

const labelStyles: ILabelStyles = {
  root: {
    paddingLeft: 22,
    paddingRight: 22,
    margin: 10,
    color: '#FFFFFF',
    borderRadius: 50,
    fontSize: 12,
  },
}

const circleStyles: CSSProperties = {
  height: 12,
  width: 12,
  borderRadius: '50%',
  display: 'inline-block',
}

const StatusBadge = ({ active, mode, size, labels, withText = true }: Props) => {
  const { t } = useTranslation('general')

  const activeLabel = labels?.active ?? 'STATUS.ACTIVE'
  const inactiveLabel = labels?.inactive ?? 'STATUS.INACTIVE'

  const text = active ? activeLabel : inactiveLabel
  const backgroundColor = active ? '#34c759' : '#cccccc'

  return (
    <>
      {mode === BadgeMode.label ? (
        <Label styles={labelStyles} style={{ backgroundColor }}>
          {t(text)}
        </Label>
      ) : (
        <div className="d-flex d-flexRow d-flexAlignItemsCenter">
          <span style={{ ...circleStyles, height: size, width: size, backgroundColor, marginRight: withText ? 5 : 0 }}></span>
          {withText && <span>{t(text)}</span>}
        </div>
      )}
    </>
  )
}

export default StatusBadge
