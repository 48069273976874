import PageBody from 'app/components/PageBody/PageBody.component'
import { useTranslation } from 'react-i18next'
import { MicroservicesAuditLog } from './MicroservicesAuditLog'
import { PRODUCT_ENV } from 'app/config/Consts'
import { ProductEnvTypes } from 'app/config/productEnv.enum'
import { withFeatureRender } from '../../../permissions/withFeatureRender'
import { FEATURE_NAMES } from '../../../permissions/FeatureName.enum'

const MicroservicesAuditLogPageBody = () => {
  const { t } = useTranslation('microservices')

  return (
    <PageBody
      isLoading={false}
      title={`${t('AUDIT_LOG.TITLE')}`}
      titleTag={PRODUCT_ENV !== ProductEnvTypes.FXXONE ? 'PREVIEW' : undefined}
    >
      <MicroservicesAuditLog />
    </PageBody>
  )
}

export const MicroservicesAuditLogPage = withFeatureRender(
  MicroservicesAuditLogPageBody,
  FEATURE_NAMES.MICROSERVICES_READ
)
