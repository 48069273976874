import { pageInfoBlock, paginatedVarsBlock } from 'app/config/pagination'
import { gql } from 'graphql-request'

export const usePaginatedTenantsQuery = (filtersName: string, sortingName: string) => {
  const queryName = 'getPaginatedBusinessTenants'

  const query = gql`
		query Request(
			$businessId: ID!
			$page: Int!
			$perPage: Int!
			$searchTerm: String!
			$filters: ${filtersName}!
			$sorting: ${sortingName}!
		) {
			getPaginatedBusinessTenants(
				businessId: $businessId
				${paginatedVarsBlock}
			) {
				data {
					_id
					name
					created_at
					region
					product {
						_id
						name
					}
					policy {
						_id
						name
					}
					organizationRelated {
						_id
						type
						name
					}
				}
				${pageInfoBlock}
			}
		}
	`

  return {
    query,
    queryName,
  }
}
