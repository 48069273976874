import { useOrganizationSelection } from 'app/globals/useOrganizationSelection'
import { FEATURE_NAME } from './FeatureName.enum'
import { roleFeature } from './RoleFeature.enum'
import { authorizeFeature } from './authorizer/AuthorizeWithPortalRole'

export const useFeatureRender = (feature?: FEATURE_NAME) => {
  const { rolesOnBusiness } = useOrganizationSelection()

  if (!rolesOnBusiness || (rolesOnBusiness && !(rolesOnBusiness.portal in roleFeature))) {
    return {
      hasFeature: false,
      checkFeature: () => false,
    }
  }

  return {
    hasFeature: authorizeFeature(rolesOnBusiness, feature!),
    checkFeature: (feature: FEATURE_NAME) => {
      return authorizeFeature(rolesOnBusiness, feature)
    },
  }
}
