import { useMutation, useQueryClient } from '@tanstack/react-query'
import { gql } from 'graphql-request'
import graphClient from '../../query-client/graphClient'

interface Response {
  deleteTenantPolicy: boolean
}

const deleteTenantPolicyQuery = gql`
  mutation Request($idTenantPolicy: ID!, $idBusiness: ID!) {
    deleteTenantPolicy(idTenantPolicy: $idTenantPolicy, idBusiness: $idBusiness)
  }
`

const deleteTenantPolicy = async (idTenantPolicy: string, idBusiness: string): Promise<boolean> => {
  const response: Response = await graphClient.request(deleteTenantPolicyQuery, { idTenantPolicy, idBusiness })

  return response.deleteTenantPolicy
}

export default function () {
  const queryClient = useQueryClient()

  return useMutation(
    ['deleteTenantPolicy'],
    ({ idTenantPolicy, idBusiness }: { idTenantPolicy: string; idBusiness: string }) =>
      deleteTenantPolicy(idTenantPolicy, idBusiness),
    {
      onSuccess: (id) => {
        queryClient.invalidateQueries(['getTenantPolicy', id])
      },
    }
  )
}
