import {
  ControlledTextField,
  DeleteActionButton,
  FormActionsContainer,
  LoadingSpinner,
  RowColLayout,
} from '@flexxibleit/flexxible-ui'
import { IDropdownOption, MessageBar, PrimaryButton, ResponsiveMode } from '@fluentui/react'
import { useBoolean } from '@fluentui/react-hooks'
import { zodResolver } from '@hookform/resolvers/zod'
import { ConfirmationDialog } from 'app/components/ConfirmationDialog/ConfirmationDialog'
import Dropdown from 'app/components/forms/Dropdown'
import FormActions from 'app/components/forms/FormActions'
import { useFormSidebarContext } from 'app/components/forms/FormSidebar/state/FormSidebarState'
import useDropdownOptions from 'app/components/hooks/useDropdownOptions'
import ErrorStatus from 'app/components/status/error-status'
import SuccessStatus from 'app/components/status/success-status'
import { useFeedbackMessage } from 'app/context/feedback-message/FeedbackMessageContext'
import useGetProductTypes from 'app/hooks/product/useGetProductTypes'
import useDeleteTenant from 'app/hooks/tenant/useDeleteTenant'
import useEditTenant from 'app/hooks/tenant/useEditTenant'
import { OrganizationPolicy } from 'app/query-client/types'
import { useEffect, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import * as z from 'zod'

interface Props {
  tenantId: string
  data: FormValues
  policies: OrganizationPolicy[]
  isDefaultTenant: boolean
}

interface FormValues {
  name: string
  product?: string
  region: string
  policyId?: string
}

const schema: z.ZodType<FormValues> = z.object({
  name: z.string({ required_error: 'required' }),
  product: z.string({ required_error: 'required' }),
  region: z.string({ required_error: 'required' }),
  policyId: z.string({ required_error: 'required' }).optional(),
})

export const TenantEditForm = ({ tenantId, data, policies, isDefaultTenant }: Props) => {
  const { t, i18n } = useTranslation('organization_details')
  const { isError, isLoading, isSuccess, mutate, reset } = useEditTenant()
  const deleteTenant = useDeleteTenant()
  const { data: products } = useGetProductTypes()
  const [hideDialog, { toggle: toggleHideDialog }] = useBoolean(true)
  const { setSuccessMessage, setErrorMessage } = useFeedbackMessage()
  const { closeSidebar } = useFormSidebarContext()
  const [regions, setRegions] = useState<IDropdownOption[]>([])

  const dropdownOptions: IDropdownOption[] = useDropdownOptions(products, '_id', 'name')
  const policiesOptions: IDropdownOption[] = useDropdownOptions(policies, '_id', 'name')

  const methods = useForm<FormValues>({
    resolver: zodResolver(schema),
    defaultValues: data,
  })
  const nameWatch = methods.watch('name')
  const policyIdWatch = methods.watch('policyId')

  useEffect(() => {
    const regions = [
      {
        key: data.region,
        text: data.region,
      },
    ]
    setRegions(regions ?? [])
  }, [data])

  const onSubmit = (values: FormValues) => {
    return mutate({ id: tenantId, tenant: { name: values.name } })
  }

  if (isLoading) {
    return <LoadingSpinner />
  }

  if (isError) {
    return (
      <>
        <ErrorStatus message={t('TENANTS.FORM.EDIT_ERROR_MESSAGE')} />
        <PrimaryButton
          text={t('general:BUTTON.KEEP_EDITING')}
          onClick={reset}
          iconProps={{ iconName: 'NavigateBack' }}
        />
      </>
    )
  }

  if (isSuccess) {
    return <SuccessStatus message={t('TENANTS.FORM.EDIT_SUCCESS_MESSAGE')} />
  }

  const renderDeleteConfirmation = () => {
    return (
      <ConfirmationDialog
        title={t('TENANTS.DELETE_DIALOG.TITLE')}
        description={t('TENANTS.DELETE_DIALOG.DESCRIPTION')}
        textRequired={nameWatch}
        actionButton={t('TENANTS.DELETE_DIALOG.BUTTON')}
        dismissButton={t('general:BUTTON.CANCEL')}
        hidden={hideDialog}
        onDismiss={toggleHideDialog}
        actionButtonDisabled={deleteTenant.isLoading}
        callback={() => {
          deleteTenant
            .mutateAsync({ id: tenantId })
            .then(() => {
              toggleHideDialog()
              closeSidebar()
              setSuccessMessage(t('TENANTS.DELETE_DIALOG.SUCCESS_MESSAGE'))
            })
            .catch(() => {
              toggleHideDialog()
              closeSidebar()
              setErrorMessage(t('TENANTS.DELETE_DIALOG.ERROR_MESSAGE'))
            })
        }}
      >
        <MessageBar
          messageBarType={3}
          isMultiline={true}
          dismissButtonAriaLabel={t('general:CLOSE_LABEL')}
          className="mb-2"
          style={{ width: '100%' }}
        >
          {t('TENANTS.DELETE_DIALOG.WARNING')}
        </MessageBar>
      </ConfirmationDialog>
    )
  }

  return (
    <>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <RowColLayout rowProps={{ classNames: 'mt-2' }}>
            <ControlledTextField
              name="name"
              required={true}
              translator={t}
              textFieldProps={{
                disabled: isDefaultTenant,
                label: t('TENANTS.FORM.NAME'),
                placeholder: t('USERS.FORM.ENTER_TEXT'),
              }}
            />
          </RowColLayout>
          <RowColLayout rowProps={{ classNames: 'mt-2' }}>
            <Dropdown
              name="product"
              required={true}
              translator={t}
              dropdownProps={{
                disabled: true,
                responsiveMode: ResponsiveMode.large,
                options: dropdownOptions,
                label: t('TENANTS.TABLE.PRODUCT'),
                placeholder: t('TENANTS.FORM.PRODUCT_PLACEHOLDER'),
                defaultSelectedKey: data.product,
              }}
            />
          </RowColLayout>
          <RowColLayout rowProps={{ classNames: 'mt-2' }}>
            <Dropdown
              name="region"
              required={true}
              translator={t}
              dropdownProps={{
                disabled: true,
                responsiveMode: ResponsiveMode.large,
                label: t('TENANTS.FORM.REGION'),
                placeholder: t('TENANTS.FORM.REGION_PLACEHOLDER'),
                defaultSelectedKey: data.region,
                options: regions,
              }}
            />
          </RowColLayout>
          <RowColLayout rowProps={{ classNames: 'mt-2' }}>
            <Dropdown
              name="policyId"
              translator={t}
              dropdownProps={{
                disabled: true,
                responsiveMode: ResponsiveMode.large,
                label: t('TENANTS.FORM.POLICY'),
                placeholder: t('TENANTS.FORM.POLICY_PLACEHOLDER'),
                defaultSelectedKey: policyIdWatch,
                options: policiesOptions,
              }}
            />
          </RowColLayout>
          <FormActionsContainer isEdit={!isDefaultTenant}>
            {!isDefaultTenant && <DeleteActionButton locale={i18n.language} onClick={toggleHideDialog} />}

            <FormActions
              submitProps={{
                disabled: isDefaultTenant,
                text: t('general:BUTTON.SAVE'),
                iconProps: { iconName: 'Save' },
              }}
            />
          </FormActionsContainer>
        </form>
      </FormProvider>
      {renderDeleteConfirmation()}
    </>
  )
}
