import { ICommandItem } from '@flexxibleit/flexxible-ui'
import { TFunction } from 'react-i18next'

type UseUserLoginsActionsProps = {
  t: TFunction
  onExportClicked: () => void
}

export const useUserLoginsActions = ({ t, onExportClicked }: UseUserLoginsActionsProps) => {
  const commandBarActions: ICommandItem[] = [
    {
      key: 'export',
      text: t('general:BUTTON.EXPORT'),
      iconProps: { iconName: 'ExcelDocument' },
      onClick: onExportClicked,
    },
  ]

  return { commandBarActions }
}
