import { FeaturedINavLinkGroup } from '../components/sidebar/Sidebar.elements'
import { TFunction } from 'react-i18next'
import { FEATURE_NAMES } from '../permissions/FeatureName.enum'

export function analyzerLinks(t: TFunction<'translation'>): FeaturedINavLinkGroup {
  return {
    name: `${t('analyzer:TITLE')}`,
    groupData: {
      icon: 'AnalyzerSidebar',
      isPreview: true,
    },
    collapseByDefault: true,
    links: [
      {
        name: t('analyzer:INSTALLED_APPS.TITLE'),
        url: '/analyzer/installed-apps',
        key: 'installed-apps',
        feature: FEATURE_NAMES.INSTALLED_APPS
      },
    ],
  }
}
