import { gql } from 'graphql-request'
import {
  PatchesCount, WindowsPatchFilter,
} from '../../query-client/types'
import graphClient from '../../query-client/graphClient'
import { useQuery } from '@tanstack/react-query'

const getCountAvailableWindowsPatchesQuery = gql`
  query($filter: GetCountAvailableWindowsPatchesGraphqlFilter!) {
    getCountAvailableWindowsPatches (filter: $filter) {
      dateRange
      severity
      value
    }
  }
`

function useGetCountAvailableWindowsPatches(
  filter: Partial<WindowsPatchFilter>
): Promise<PatchesCount[]> {
  return graphClient
    .request(getCountAvailableWindowsPatchesQuery, {filter})
    .then((response) => {
      return response.getCountAvailableWindowsPatches
    })
}

export default function (filter: Partial<WindowsPatchFilter>) {
  return useQuery(
    ['getCountAvailableWindowsPatches', filter],
    () => useGetCountAvailableWindowsPatches(filter),
    {
      refetchOnWindowFocus: false,
      keepPreviousData: true,
    }
  )
}
