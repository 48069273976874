import { DisplayField } from 'app/components/DisplayField'
import { useTranslation } from 'react-i18next'
import EditButton from '../../../components/Buttons/EditButton.component'
import FlexxibleCard from '../../../components/FlexxibleCard/FlexxibleCard.component'
import Col from '../../../components/Layouts/Col'
import Row from '../../../components/Layouts/Row'
import useCreateForm from '../../../hooks/useCreateForm'
import { Business } from '../../../query-client/types'
import EntraIdForm from './EntraIdForm'

interface Props {
  organization: Business
}

const EntraId = ({ organization }: Props) => {
  const { t } = useTranslation('organizations')

  const openADDForm = useCreateForm(
    {
      title: t('FORM.EDIT_AAD_TITLE'),
      isFooterAtBottom: true,
    },
    <EntraIdForm organization={organization} />
  )

  return (
    <div>
      <FlexxibleCard
        cardProps={{ style: { marginTop: 30 } }}
        styleNames="col-md-9 d-flex d-flexCol d-flexJustifyBetween"
      >
        <Row>
          <Col className="col-xs-12 col-lg-6">
            <DisplayField label={t('FORM.AAD.CLIENT_ID')}>
              {organization.activeDirectoryCredentials?.clientId ?? '-'}
            </DisplayField>
          </Col>
          <Col className="col-xs-12 col-lg-6">
            <DisplayField label={t('FORM.AAD.SECRET')}>
              {organization.activeDirectoryCredentials?.isClientSecretSet ? '********' : '-'}
            </DisplayField>
          </Col>
        </Row>
        <Row>
          <Col className="col-xs-12 col-lg-6">
            <DisplayField label={t('FORM.AAD.TENANTID')}>
              {organization.activeDirectoryCredentials?.tenantId ?? '-'}
            </DisplayField>
          </Col>
        </Row>

        <div className="d-flex d-flexRowReverse">
          <EditButton onClick={openADDForm} />
        </div>
      </FlexxibleCard>
    </div>
  )
}

export default EntraId
