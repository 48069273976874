import { useQuery } from '@tanstack/react-query'
import { gql } from 'graphql-request'
import graphClient from '../../query-client/graphClient'

const query = gql`
  query Request {
    viewUserConnectionsList {
      data {
        id
        ip
        userAgent
        application
        date
      }
    }
  }
`

function viewUserConnectionsList() {
  return graphClient.request(query).then((response) => {
    return response?.viewUserConnectionsList?.data || []
  })
}

export default function () {
  return useQuery(['viewUserConnectionsList'], () => viewUserConnectionsList(), {
    refetchOnWindowFocus: false,
    enabled: false,
  })
}
