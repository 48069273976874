import { IDropdownOption } from '@fluentui/react'
import { DateFromToPicker } from 'app/components/DateFromToPicker/DateFromToPicker'
import { DateOptionsDropDown } from 'app/components/DateOptionsDropDown/DateOptionsDropDown'
import PageBody from 'app/components/PageBody/PageBody.component'
import { MOBILE_QUERY } from 'app/config/media-query'
import useMediaQuery from 'app/hooks/utils/useMediaQuery'
import { DateTime } from 'luxon'
import { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useOrganizationSelection } from '../../globals/useOrganizationSelection'
import { useOrganizationByIdForAllRoles } from '../../hooks/organization/useOrganizationByIdForAllRoles'
import { FEATURE_NAMES } from '../../permissions/FeatureName.enum'
import { withFeatureRender } from '../../permissions/withFeatureRender'
import { ViewOrganizationOperationsHistoryFeature } from './view-organization-operations-history/ViewOrganizationOperationsHistory.feature'
import { ListOrganizationOperationsHistoryFeature } from './list_organization_operations/ListOrganizationOperationsHistoryFeature'
import { OperationsFilterContext, OperationsFilterState } from './OperationsContext'

const Operations = () => {
  const { selectedOrganizationId } = useOrganizationSelection()
  const { data, isLoading, isFetching } = useOrganizationByIdForAllRoles(selectedOrganizationId || '')

  const { t } = useTranslation('efficiency_operations')
  const [isMobile] = useMediaQuery(MOBILE_QUERY)
  const defaultStartDate = DateTime.now().minus({ days: 30 }).startOf('day')
  const defaultEndDate = DateTime.now().endOf('day')
  const [dropdownDateSelected, setDropdownDateSelected] = useState<string | null>('last_30_days')
  const [startDate, setStartDate] = useState(defaultStartDate)
  const [endDate, setEndDate] = useState(defaultEndDate)
  const [filterStartDate, setFilterStartDate] = useState<Date>(startDate.toJSDate())
  const [filterEndDate, setFilterEndDate] = useState<Date>(endDate.toJSDate())
  const [state, setState] = useState<OperationsFilterState>({
    success: false,
    warning: false,
    failed: false,
    endUserMicroservice: false,
    automatic: false,
    manual: false
  })
  // const filterContextValue = useMemo(() => ({ state: filtersState, setState: setFiltersState }), [filtersState, setFiltersState])

  const onDateOptionsDropdownChange = (event?: React.FormEvent<HTMLDivElement>, item?: IDropdownOption) => {
    setDropdownDateSelected(String(item?.key))
    let startDate = DateTime.now()
    let endDate = DateTime.now()

    switch (String(item?.key)) {
      case 'current_month':
        startDate = startDate.startOf('month')
        endDate = endDate.endOf('day')
        break
      case 'last_30_days':
        startDate = startDate.minus({ days: 30 }).startOf('day')
        endDate = endDate.endOf('day')
        break
      case 'last_3_months':
        startDate = startDate.minus({ month: 2 }).startOf('month')
        endDate = endDate.endOf('day')
        break
    }
    setFilterStartDate(startDate.toJSDate())
    setFilterEndDate(endDate.toJSDate())
  }

  const onFromSelectedDate = (date: any) => {
    setFilterStartDate(date)
    setDropdownDateSelected(null)
  }

  const onToSelectedDate = (date: any) => {
    setFilterEndDate(date)
    setDropdownDateSelected(null)
  }

  return (
    <PageBody title={t('TITLE')} titleTag={data?.name} isLoading={isLoading || isFetching}>
      <div className={isMobile ? 'd-flex d-flexCol' : 'd-flex d-flexRow d-flexAlignItemsCenter d-flexJustifyBetween'}>
        <div />
        <div className={isMobile ? 'd-flex d-flexCol' : 'd-flex d-flexRow d-flexAlignItemsCenter d-flexJustifyBetween'}>
          <DateOptionsDropDown
            dropdownDateSelected={dropdownDateSelected}
            onDropdownChange={onDateOptionsDropdownChange}
            translationModule={'efficiency_operations'}
          />
          <DateFromToPicker
            startDate={filterStartDate}
            endDate={filterEndDate}
            onDateFromSelected={onFromSelectedDate}
            onDateToSelected={onToSelectedDate}
          />
        </div>
      </div>
      <OperationsFilterContext.Provider value={{ state, setState }}>
        <ViewOrganizationOperationsHistoryFeature startDate={filterStartDate} endDate={filterEndDate} />
        <ListOrganizationOperationsHistoryFeature startDate={filterStartDate} endDate={filterEndDate} />
      </OperationsFilterContext.Provider>
    </PageBody>
  )
}

export const OperationsPage = withFeatureRender(Operations, FEATURE_NAMES.OPERATIONS_READ)
