import { IToggleProps, Toggle } from "@fluentui/react";
import { ControllerRenderProps, FieldValues } from "react-hook-form";
import Controlled from "../Controlled";

export interface ControlledToggleProps {
  name: string;
  toggleProps?: IToggleProps;
  onChange?: (checked: boolean) => void;
}


function ControlledToggleButton({ name, toggleProps, onChange }: ControlledToggleProps) {

  const _onChange = (field: ControllerRenderProps<FieldValues, string>, checked: boolean) => {
    if (onChange) {
      onChange(checked)
    }
    field.onChange(checked)
  }

  return (
    <Controlled
      name={name}
      render={({ field }) =>
      <Toggle 
        {...toggleProps}
        checked={field.value}
        onChange={(e: any, checked?: boolean) => _onChange(field, checked || false)}
      />
    }/>
  )
}

export default ControlledToggleButton