import { useMutation } from "@tanstack/react-query";
import { gql } from "graphql-request";
import graphClient from "../../query-client/graphClient";
import queryClient from "../../query-client/queryClient";

interface DeleteElegibleProductArgs {
  elegibleProductId: string;
  organizationId: string;
}

const deleteElegibleProductQuery = gql`mutation Request($elegibleProductId: ID!, $organizationId: ID!) {
  deleteElegibleProduct(elegibleProductId: $elegibleProductId, organizationId: $organizationId)
}`

function deleteElegibleProduct({ elegibleProductId, organizationId }: DeleteElegibleProductArgs) {
  return graphClient
    .request(deleteElegibleProductQuery, { elegibleProductId, organizationId })
    .then(r => r);
}

export default function useDeleteElegibleProductMutation() {
  return useMutation(
    ['deleteElegibleProduct'],
    ({ elegibleProductId, organizationId }: DeleteElegibleProductArgs) => deleteElegibleProduct({ elegibleProductId, organizationId }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['getBusinessById'])
      },
    }
  );
}
