import Highcharts from 'highcharts'
import { PatchPolicyTargetSummary } from '../../summary/MockData'
import { DashboardChartCard } from '../../../../components/Dashboard/DashboardChartCard/DashboardChartCard'
import { PieChart } from '../../../../components/Charts/PieChart/PieChart'
import { useTranslation } from 'react-i18next'

interface Props {
  patchPolicySummary: PatchPolicyTargetSummary
}

export const PatchPoliciesSummaryPieChart = (props: Props) => {
  const { t } = useTranslation('patch_management')

  const getItemCountFromPatchPolicySummary = (patchPolicySummary: PatchPolicyTargetSummary) => {
    return Object.keys(patchPolicySummary)
      .filter((key) => key !== 'targetGroup')
      .map((key) => {
        return {
          name: key,
          count: patchPolicySummary[key as keyof typeof patchPolicySummary] as number,
        }
      })
  }

  const transformItemName = (name: string) => {
    switch(name) {
      case 'unknownCount':
        return t('DASHBOARD.TARGETS.UNKNOWN_COUNT');
      case 'notApplicableCount':
        return t('DASHBOARD.TARGETS.NOT_APPLICABLE_COUNT');
      case 'notInstalledCount':
        return t('DASHBOARD.TARGETS.NOT_INSTALLED_COUNT');
      case 'downloadedCount':
        return t('DASHBOARD.TARGETS.DOWNLOAD_COUNT');
      case 'noStatus':
        return t('DASHBOARD.TARGETS.NO_STATUS_COUNT');
      case 'installedCount':
        return t('DASHBOARD.TARGETS.INSTALLED_COUNT');
      case 'installedPendingRebootCount':
        return t('DASHBOARD.TARGETS.INSTALLED_PENDING_REBOOT_COUNT');
      case 'failedCount':
        return t('DASHBOARD.TARGETS.FAILED_COUNT');
      default:
        return t('DASHBOARD.TARGETS.UNKNOWN_COUNT');
    }
  }

  const series: Highcharts.SeriesPieOptions[] = [
    {
      type: 'pie',
      animation: false,
      name: 'Patches',
      innerSize: '50%',
      data: getItemCountFromPatchPolicySummary(props.patchPolicySummary).map((item) => ({
        name: transformItemName(item.name),
        y: item.count,
      })),
    },
  ]

  return (
    <DashboardChartCard
      title={props.patchPolicySummary.targetGroup}
      tooltip={t('DASHBOARD.TABS.PATCH_POLICIES_TAB.PIE_CHART.TOOLTIP')}
    >
      <PieChart series={series} />
    </DashboardChartCard>
  )
}
