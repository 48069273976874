import { ColumnActionsMode, IColumn, TooltipHost, TooltipOverflowMode } from '@fluentui/react'
import { TFunction } from 'react-i18next'
import { BadgeMode, StatusBadge } from '@flexxibleit/flexxible-ui'
import { Activation } from '../../../../query-client/types'
import { getProductIcon } from 'app/services/product-icon.service'
import { BusinessType, label } from '../../../../models/business/BusinessType'
import { getIcon } from '../../../../services/organization-icon-service'

type UseActivationBuildTableProps = {
  t: TFunction
}

export const useActivationBuildTable = ({ t }: UseActivationBuildTableProps) => {
  const onRenderColumnNumber = (item?: any, _?: number, column?: IColumn) => {
    if (!item) return null
    if (!column) return null
    const keyColumn = column.key
    const formattedNumber = t('general:FORMAT_NUMBER', { val: item[keyColumn] })
    return <div style={{ display: 'flex', justifyContent: 'end', flex: 'auto' }}>{formattedNumber}</div>
  }

  const columns = [
    {
      key: 'name',
      name: t('TENANTS_ACTIVATIONS.TABLE.NAME'),
      fieldName: 'name',
      flexGrow: 2,
      onRender: (item?: any) => {
        return (
          <TooltipHost
            id={item._id}
            content={item.name}
            overflowMode={TooltipOverflowMode.Parent}
            styles={{ root: { display: 'flex', alignContent: 'center', alignItems: 'center' } }}
          >
            <span>{item.name}</span>
          </TooltipHost>
        )
      },
      className: 'cell-ellipsis',
    },
    {
      key: 'product',
      name: t('TENANTS_ACTIVATIONS.TABLE.PRODUCT'),
      fieldName: 'product',
      flexGrow: 1,
      onRender: (item?: any) => {
        return (
          <TooltipHost
            id={item._id}
            content={item.product}
            overflowMode={TooltipOverflowMode.Parent}
            styles={{ root: { display: 'flex', alignContent: 'center', alignItems: 'center' } }}
          >
            <span aria-describedby={item._id} style={{ marginRight: 4 }}>
              {getProductIcon(item.product, 18, 18)}
            </span>
            <span>{item.product}</span>
          </TooltipHost>
        )
      },
      className: 'cell-ellipsis',
    },
    {
      key: 'type',
      name: t('TENANTS_ACTIVATIONS.TABLE.TYPE'),
      fieldName: 'type',
      flexGrow: 1,
      onRender: (item?: any) => {
        return (
          <TooltipHost
            id={item._id}
            content={item.type}
            overflowMode={TooltipOverflowMode.Parent}
            styles={{ root: { display: 'flex', alignContent: 'center', alignItems: 'center' } }}
          >
            <img src={getIcon(item.type)} className="icon" alt={item.type} width="18" height="18" />
            <span>{label(t)[item.type as BusinessType]}</span>
          </TooltipHost>
        )
      },
      className: 'cell-ellipsis',
    },
    {
      key: 'productStatus',
      name: t('TENANTS_ACTIVATIONS.TABLE.STATUS'),
      fieldName: 'productStatus',
      flexGrow: 1,
      onRender: (item: Activation) => {
        return <StatusBadge active={item.productStatus} mode={BadgeMode.circle} size={8} />
      },
      columnActionsMode: ColumnActionsMode.disabled,
    },
    {
      key: 'last90Days',
      name: t('TENANTS_ACTIVATIONS.TABLE.90_DAYS_AGO'),
      fieldName: 'last90Days',
      flexGrow: 1,
      isNumeric: true,
      onRender: onRenderColumnNumber,
    },

    {
      key: 'last60Days',
      name: t('TENANTS_ACTIVATIONS.TABLE.60_DAYS_AGO'),
      fieldName: 'last60Days',
      flexGrow: 1,
      isNumeric: true,
      onRender: onRenderColumnNumber,
    },
    {
      key: 'last30Days',
      name: t('TENANTS_ACTIVATIONS.TABLE.30_DAYS_AGO'),
      fieldName: 'last30Days',
      flexGrow: 1,
      isNumeric: true,
      onRender: onRenderColumnNumber,
    },
    {
      key: 'last7Days',
      name: t('TENANTS_ACTIVATIONS.TABLE.7_DAYS_AGO'),
      fieldName: 'last7Days',
      flexGrow: 1,
      isNumeric: true,
      onRender: onRenderColumnNumber,
    },
    {
      key: 'lastDay',
      name: t('TENANTS_ACTIVATIONS.TABLE.YESTERDAY'),
      fieldName: 'lastDay',
      flexGrow: 1,
      isNumeric: true,
      onRender: onRenderColumnNumber,
    },
    {
      key: 'createdAt',
      name: t('TENANTS_ACTIVATIONS.TABLE.CREATED_AT'),
      fieldName: 'createdAt',
      flexGrow: 1,
    },
    {
      key: 'deleteAt',
      name: t('TENANTS_ACTIVATIONS.TABLE.DELETED_AT'),
      fieldName: 'deleteAt',
      flexGrow: 1,
    },
  ]

  return { columns }
}
