import { gql } from 'graphql-request'
import graphClient from '../../query-client/graphClient'
import { useQuery } from '@tanstack/react-query'
import { Business } from '../../query-client/types'

const query = gql`
query($id: ID!) {
  getBusinessClients(id: $id) {
    _id
    name
    type
    isTrial
  }
}
`

function getOrganizationClients(id: string): Promise<Business[]> {
  return graphClient
    .request(query, {id})
    .then((response) => response.getBusinessClients)
    .then((organizations) => organizations.sort(
      (orgA: Business, orgB: Business) => {
        const nameA = orgA.name.toLowerCase()
        const nameB = orgB.name.toLowerCase()
        if (nameA > nameB) return 1
        if (nameB > nameA) return -1
        return 0
      }
    ))
}

export function useGetOrganizationClients (id: string) {
  return useQuery(['businessClients', { forSelection: true }], () => getOrganizationClients(id), {
    refetchOnWindowFocus: false,
  })
}
