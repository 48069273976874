import useCreateForm from 'app/hooks/useCreateForm'
import { TFunction } from 'react-i18next'
import ProductForm from './ProductForm'
import { useEffect, useState } from 'react'
import { ElegibleProduct, ProductConfig } from 'app/query-client/types'
import { ICommandItem } from '@flexxibleit/flexxible-ui'
import ProductAgentSettingsForm from './ProductAgentSettingsForm'
import { useOrganizationSelection } from 'app/globals/useOrganizationSelection'
import { useFeatureRender } from '../../../permissions/useFeatureRender'
import { FEATURE_NAMES } from '../../../permissions/FeatureName.enum'

type UseOrgProductsActionsProps = {
  t: TFunction
  organizationId: string
  onExportClicked: () => void
  elegibleProducts: ElegibleProduct[] | undefined
}

export const useOrgProductsActions = ({
  t,
  organizationId,
  onExportClicked,
  elegibleProducts,
}: UseOrgProductsActionsProps) => {
  const { rolesOnBusiness } = useOrganizationSelection()
  const { checkFeature } = useFeatureRender()

  const [isAdmin] = useState(rolesOnBusiness?.isOrganizationAdminOrHigher() ?? false)

  const openAddProduct = useCreateForm(
    {
      title: t('PRODUCTS.FORM.ADD_TITLE'),
      isFooterAtBottom: true,
    },
    <ProductForm organizationId={organizationId} isAdmin={isAdmin} elegibleProducts={elegibleProducts} />
  )

  const openEditProduct = (productConfig: ProductConfig) =>
    useCreateForm(
      {
        title: t('PRODUCTS.FORM.EDIT_TITLE'),
        isFooterAtBottom: true,
      },
      <ProductForm product={productConfig} isAdmin={isAdmin} elegibleProducts={elegibleProducts} />
    )

  const openAgentSettingsProduct = (productConfig: ProductConfig) => {
    return useCreateForm(
      {
        title: t('AGENT_SETTINGS.FORM.EDIT_PRODUCT_SETTINGS_TITLE'),
        isFooterAtBottom: true,
      },
      <ProductAgentSettingsForm productConfig={productConfig} />
    )
  }

  const commandBarActions: ICommandItem[] = checkFeature(FEATURE_NAMES.ENVIRONMENT_CREATE)
    ? [
        {
          key: 'create',
          text: t('general:BUTTON.CREATE'),
          iconProps: { iconName: 'Add' },
          onClick: () => openAddProduct(),
        },
        {
          key: 'export',
          text: t('PRODUCTS.FORM.EXPORT_PRODUCTS'),
          iconProps: { iconName: 'ExcelDocument' },
          onClick: onExportClicked,
        },
      ]
    : []

  return {
    openAddProduct,
    openEditProduct,
    commandBarActions,
    openAgentSettingsProduct,
  }
}
