import { Col, Row } from '@flexxibleit/flexxible-ui'
import EditButton from 'app/components/Buttons/EditButton.component'
import { DisplayField } from 'app/components/DisplayField'
import FlexxibleCard from 'app/components/FlexxibleCard/FlexxibleCard.component'
import { MicroserviceView } from 'app/hooks/microservices/useGetDesignerMicroserviceById'
import useCreateForm from 'app/hooks/useCreateForm'
import { conditionEnvironmentLabel } from 'app/models/condition/ConditionEnvironment'
import { valueTypeLabel } from 'app/models/flows/ValueType'
import { useTranslation } from 'react-i18next'
import { MicroserviceDetailsConditionForm } from './MicroserviceDetailsConditionForm'

interface Props {
  microservice: MicroserviceView
  canEdit: boolean
}

const cardProps = { style: { marginTop: 30 } }

export const MicroserviceDetailsCondition = ({ microservice, canEdit }: Props) => {
  const { t } = useTranslation('microservices')

  const buildValue = () => {
    if (!microservice.condition?.valueType) return '-'

    return valueTypeLabel(t)[microservice.condition.valueType]
  }

  const buildEnvironment = () => {
    if (!microservice.condition?.environment) return '-'

    return conditionEnvironmentLabel(t)[microservice.condition.environment]
  }

  const openCreateOrganizationForm = useCreateForm(
    {
      title: t('DESIGNER.PRIVACY.PRIVACY_EDIT_TITLE'),
      isFooterAtBottom: true,
    },
    <MicroserviceDetailsConditionForm microservice={microservice} />
  )

  return (
    <FlexxibleCard cardProps={cardProps} styleNames="col-md-12 d-flex d-flexCol d-flexJustifyBetween">
      <Row>
        <Col>
          <DisplayField label={t('DESIGNER.MICROSERVICE_FORM.VALUE_TYPE')}>{buildValue()}</DisplayField>
        </Col>
        <Col>
          <DisplayField label={t('DESIGNER.MICROSERVICE_FORM.ENVIROMENT')}>{buildEnvironment()}</DisplayField>
        </Col>
      </Row>
      {canEdit && (
        <div className="d-flex d-flexRowReverse">
          <EditButton onClick={openCreateOrganizationForm} />
        </div>
      )}
    </FlexxibleCard>
  )
}
