import { Checkbox as FluentCheckBox, ICheckboxProps } from "@fluentui/react";
import { ControllerRenderProps, FieldValues } from "react-hook-form";
import Controlled from "../Controlled";

interface Props {
  name: string,
  checkboxProps: ICheckboxProps;
  onChange?: (checked: boolean) => void;
}

export default function CheckBox({ name, checkboxProps, onChange }: Props) {
  const _onChange = (field: ControllerRenderProps<FieldValues, string>, checked: boolean) => {
    if (onChange) {
      onChange(checked)
    }
    field.onChange(checked)
  }

  return (
    <Controlled
      name={name}
      render={({ field }) => (
        <FluentCheckBox
          {...field}
          {...checkboxProps}
          checked={field.value}
          onChange={(e: any, checked?: boolean) => _onChange(field, checked ?? false)}
        />
      )}
    />
  )
}