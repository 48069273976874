export enum MicroserviceAction {
  CLONE = 'CLONE',
  CREATE = 'CREATE',
  EDIT = 'EDIT',
  ENABLE = 'ENABLE',
  DISABLE = 'DISABLE',
  VIEW = 'VIEW',
  ARCHIVE = 'ARCHIVE',
  UNARCHIVE = 'UNARCHIVE',
  SET_PRIVACY = 'SET_PRIVACY',
  VIEW_PRIVACY = 'VIEW_PRIVACY',
  VIEW_SCRIPT = 'VIEW_SCRIPT',
  EDIT_SCRIPT = 'EDIT_SCRIPT',
  EDIT_DEFAULT_TARGETS = 'EDIT_DEFAULT_TARGETS',
  EDIT_ORGANIZATION_TARGETS = 'EDIT_ORGANIZATION_TARGETS',
  EDIT_ORGANIZATION_SETTINGS = 'EDIT_ORGANIZATION_SETTINGS',
  EDIT_LICENSE = 'EDIT_LICENSE',
  EDIT_CONDITION_SETTINGS = 'EDIT_CONDITION_SETTINGS',
}
