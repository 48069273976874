import { IDropdownOption, ResponsiveMode } from "@fluentui/react"
import { zodResolver } from "@hookform/resolvers/zod"
import FlexxibleCard from "app/components/FlexxibleCard/FlexxibleCard.component"
import Col from "app/components/Layouts/Col"
import Row from "app/components/Layouts/Row"
import Dropdown from "app/components/forms/Dropdown"
import FormActions from "app/components/forms/FormActions"
import { licenses } from "app/config/licenses"
import { MicroserviceView } from "app/hooks/microservices/useGetDesignerMicroserviceById"
import { LicenseView } from "app/pages/microservices/details/license/MicroserviceLicense"
import { FormProvider, useForm } from "react-hook-form"
import { useTranslation } from "react-i18next"
import * as z from 'zod'

interface Props {
  initialData: MicroserviceView['license']
  disabled?: boolean
  onSubmit: (data: LicenseForm) => void
  onCancel: () => void
}

interface LicenseForm {
  license: string
}

const schema: z.ZodType<Partial<LicenseForm>> = z.object({
  license: z.string({ required_error: 'required' }).min(1, { message: 'required' }),
})

export const MicroserviceCreateLicense = ({ initialData, onSubmit, onCancel, disabled = false }: Props) => {
  const { t } = useTranslation('microservices')

  const methods = useForm<LicenseForm>({
    resolver: zodResolver(schema),
    defaultValues: { license: initialData.type },
  })

  const licenseOptions: IDropdownOption[] = Object.values(licenses).map((element: any) => ({
    key: element.key,
    text: element.title,
  }))

  const watchLicense = methods.watch('license')

  return (
    <FlexxibleCard
      cardProps={{ style: { marginTop: 30 } }}
      styleNames="col-md-12 d-flex d-flexCol d-flexJustifyBetween"
    >
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)} className="mb-2">
          <Row classNames="bottom-lg">
            <Col className="col-xs-12 col-lg-6">
              <Dropdown
                name="license"
                required={true}
                translator={t}
                dropdownProps={{
                  disabled,
                  responsiveMode: ResponsiveMode.large,
                  options: licenseOptions,
                  label: t('DESIGNER.MICROSERVICE_FORM.LICENSE_INPUT_LABEL'),
                  placeholder: t('DESIGNER.MICROSERVICE_FORM.LICENSE_INPUT_PLACEHOLDER'),
                  defaultSelectedKey: watchLicense,
                }}
              />
            </Col>
            <Col className="col-xs-12 col-lg-6">
              <FormActions
                cancelProps={{ onClick: onCancel }}
                submitProps={{
                  text: t('general:PAGINATION.NEXT'),
                  iconProps: { iconName: 'Next' },
                }}
              />
            </Col>
          </Row>
        </form>
      </FormProvider>
      <LicenseView
        license={{
          type: watchLicense,
          year: initialData.year,
          holder: initialData.holder,
        }}
        showTitle={false}
      />
    </FlexxibleCard>
  )
}
