import { TFunction } from 'react-i18next'

export const FlowCooldownValues = [5, 15, 30, 60, 300, 600]

export const flowCooldownLabel = (t: TFunction): { [key: number]: string } =>
  FlowCooldownValues.reduce((acc, value) => {
    acc[value] = secondsToLabel(t, value)

    return acc
  }, {} as Record<number, string>)

export const flowCooldownOptions = (t: TFunction) =>
  FlowCooldownValues.map((value) => ({
    key: value,
    text: flowCooldownLabel(t)[value],
  }))

export const secondsToLabel = (t: TFunction, seconds: number) => {
  if (seconds < 60) {
    return t('general:TIMEPICKER.SECONDS', { count: seconds })
  } else if (seconds < 3600) {
    return t('general:TIMEPICKER.MINUTES', { count: seconds / 60 })
  }
  return t('general:TIMEPICKER.HOURS', { count: seconds / 3600 })
}