import { ContextualMenu, Icon, IContextualMenuItem } from '@fluentui/react'
import { MouseEvent, useCallback, useRef, useState } from 'react'

interface Props {
  items: IContextualMenuItem[],
  padding?: string,
}

export const ContextualMenuButton = (props: Props) => {
  const [showContextualMenu, setShowContextualMenu] = useState(false)
  const onShowContextualMenu = useCallback((ev: MouseEvent<HTMLElement>) => {
    ev.preventDefault()
    setShowContextualMenu(true)
  }, [])
  const onHideContextualMenu = useCallback(() => setShowContextualMenu(false), [])
  const linkRef = useRef(null)

  return (
    <>
      <div
        ref={linkRef}
        style={{
          fontSize: '16px',
          cursor: 'pointer',
          display: 'flex',
          justifyContent: 'start',
          alignItems: 'center',
          width: '100%',
          height: '100%',
          padding: props.padding ?? '0 18px',
        }}
        onClick={onShowContextualMenu}
      >
        <Icon
          iconName="More"
          styles={{
            root: {
              '&:hover': {
                color: 'var(--theme-primary)',
              },
            },
          }}
        />
      </div>
      <ContextualMenu
        hidden={!showContextualMenu}
        items={props.items}
        onItemClick={onHideContextualMenu}
        onDismiss={onHideContextualMenu}
        target={linkRef}
      />
    </>
  )
}
