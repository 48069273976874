import { useMutation, useQueryClient } from '@tanstack/react-query'
import graphClient from 'app/query-client/graphClient'
import { gql } from 'graphql-request'

interface CreateRolePermissionRequest {
  userRoleId: string
  organizationId: string
  organization: string
  portal: string
  workspaces: string
  analyzer: string
  allReportingGroups: boolean
  reportingGroups: string[]
}

const query = gql`
  mutation createRolePermission($input: CreateRolePermissionGraphqlRequest!) {
    createRolePermission(input: $input) {
      _id
    }
  }
`

interface CreateRolePermissionResponse {
  createRolePermission: {
    _id: string
  }
}

async function createRolePermission(request: CreateRolePermissionRequest) {
  const { createRolePermission }: CreateRolePermissionResponse = await graphClient.request(query, {
    input: request,
  })

  return createRolePermission
}

export default function () {
  const queryClient = useQueryClient()

  return useMutation(
    ['createRolePermission'],
    (request: CreateRolePermissionRequest) => createRolePermission(request),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['getPaginatedRolePermissions'])
        queryClient.invalidateQueries(['getUserRoleById'])
      },
    }
  )
}
