import { FontIcon } from "@fluentui/react"
import './BasicList.scss'

interface Props {
  items: string[]
  title: string
  iconName: string
  iconColor: string
}

export const BasicList = ({ items, title, iconName, iconColor }: Props) => {
  return (
    <ul className="basic-list">
      <h3 className="basic-list__title">{title}</h3>
      {items.map((item) => {
        return (
          <li key={item} className="basic-list__item">
            <FontIcon
              iconName={iconName}
              className="basic-list__item__icon"
              style={{ color: iconColor }}
            />
            <span className="basic-list__item__text">{item}</span>
          </li>
        )
      })}
    </ul>
  )
}