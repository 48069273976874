import { ICommandItem } from '@flexxibleit/flexxible-ui'
import { FlowListItem } from 'app/hooks/flows/useGetFlows'
import { FEATURE_NAMES } from 'app/permissions/FeatureName.enum'
import { useFeatureRender } from 'app/permissions/useFeatureRender'
import { TFunction } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

type Props = {
  t: TFunction
  onExportClicked: () => void
}

export const useFlowsActions = ({ t, onExportClicked }: Props) => {

  const { hasFeature } = useFeatureRender(FEATURE_NAMES.FLOWS_CREATE)
  const navigate = useNavigate()

  const onGoToDetail = (element: FlowListItem) => {
    navigate(`/flows/${element.id}`)
  }

  const commandBarActions: ICommandItem[] = [
    {
      key: 'export',
      text: t('general:BUTTON.EXPORT'),
      iconProps: { iconName: 'ExcelDocument' },
      onClick: onExportClicked,
    },
  ]

  if (hasFeature) {
    commandBarActions.unshift({
      key: 'create',
      text: t('general:BUTTON.CREATE'),
      iconProps: { iconName: 'Add' },
      onClick: () => navigate('/flows/create'),
    })
  }

  return {
    commandBarActions,
    onGoToDetail,
  }
}
