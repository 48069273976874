import { IBreadcrumbItem } from '@fluentui/react'
import { FeedbackMessageBar } from 'app/components/FeedbackMessageBar/FeedbackMessageBar'
import PageBody from 'app/components/PageBody/PageBody.component'
import { Tab, TabPanel } from 'app/components/tab-panel/TabPanel'
import useOrganizationById from 'app/hooks/organization/useOrganizationById'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import { useOrganizationSelection } from 'app/globals/useOrganizationSelection'
import { useEffect } from 'react'
import { RolDetails } from './tabs/details/RolDetails'
import useGetUserRoleById from 'app/hooks/roles/useGetUserRoleById'
import { RolPermissions } from './tabs/permissions/RolPermissions'
import { RolUsers } from './tabs/users/RolUsers'

const RolDetailPage = () => {
  const { t } = useTranslation('organization_details')
  const { organizationId, userRoleId } = useParams()
  const { selectedOrganizationId } = useOrganizationSelection()
  const organizationRequest = useOrganizationById(organizationId || '')
  const { isLoading, data } = useGetUserRoleById({ id: userRoleId || '', organizationId: organizationId || '' }, true)
  const navigate = useNavigate()

  useEffect(() => {
    if (selectedOrganizationId && selectedOrganizationId !== organizationId) {
      navigate(`/organization/${selectedOrganizationId}/roles`)
    }
  }, [selectedOrganizationId])


  const buildTabs = (): Tab[] => {
    return [
      {
        title: t('ROLES.ROLE_DETAILS_PIVOT'),
        component: <RolDetails userRole={data!} />,
        key: 'overview',
      },
      {
        title: t('ROLES.ROLE_PERMISSIONS_PIVOT'),
        component: <RolPermissions userRole={data!} />,
        key: 'permissions',
      },
      {
        title: t('ROLES.ROLE_USERS_PIVOT'),
        component: <RolUsers userRole={data!} organizationId={organizationId} />,
        key: 'users',
      },
    ]
  }

  const onBreadcrumbItemClicked = (event?: React.MouseEvent, item?: IBreadcrumbItem) => {
    navigate(item?.key ?? '/home')
  }
  const breadcrumbItems = [
    {
      text: organizationRequest.data?.name ?? '',
      key: `/organization/${organizationId}`,
      onClick: onBreadcrumbItemClicked,
    },
    {
      text: t('ROLES.ROLES'),
      key: `/organization/${organizationId}/roles`,
      onClick: onBreadcrumbItemClicked,
    },
    { text: data?.name || '', key: 'current', isCurrentItem: true },
  ]

  return (
    <PageBody title={data?.name ?? ''} isLoading={isLoading} breadcrumbItems={breadcrumbItems}>
      <FeedbackMessageBar />
      <TabPanel tabs={buildTabs()} />
    </PageBody>
  )
}

export default RolDetailPage
