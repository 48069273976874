import { useOrganizationSelection } from '../globals/useOrganizationSelection'
import { Outlet, useParams } from 'react-router-dom'
import { useEffect } from 'react'

export const KeepOrganizationSelected = () => {
  const { setSelectedOrganizationId } = useOrganizationSelection()
  const { organizationId } = useParams()

  useEffect(() => {
    setSelectedOrganizationId(organizationId)
  }, [])

  return <Outlet />
}
