import { UseMutateAsyncFunction, useMutation, useQueryClient } from '@tanstack/react-query'
import { gql } from 'graphql-request'
import graphClient from '../../query-client/graphClient'
import { UserSettings } from '../../query-client/types'

export interface UserSettingsInput {
  preferredLanguage?: string
  defaultOrganizationSelected?: string
  preferredRegion?: string
}

const updateMySettingsQuery = gql`
  mutation ($input: UserSettingsInput!) {
    updateMySettings(input: $input) {
      _id
      defaultOrganizationSelected
      preferredLanguage
      preferredRegion
    }
  }
`

const updateMySettings = async (userSettingsInput: UserSettingsInput): Promise<UserSettings> =>
  graphClient
    .request(updateMySettingsQuery, {
      input: userSettingsInput,
    })
    .then((r) => r.updateMySettings)

export interface UseUpdateMySettingsResult {
  updateMySettings: UseMutateAsyncFunction<UserSettings, unknown, UserSettingsInput>
}

export const useUpdateMySettings = (): UseUpdateMySettingsResult => {
  const queryClient = useQueryClient()
  const { mutateAsync } = useMutation(
    ['updateMySettings'],
    (userSettingsInput: any) => updateMySettings(userSettingsInput),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['getMySettings'])
      },
    }
  )

  return {
    updateMySettings: mutateAsync,
  }
}
