import { MOBILE_QUERY, useMediaQuery } from '@flexxibleit/flexxible-ui'
import { SummaryHeader } from './SummaryHeader'
import { ReactNode } from 'react'
import { SummaryItem } from '../hooks/usePatchManagementRuleEngine'

interface Props {
  title: string
  summaryHeaderItems: SummaryItem[]
  metricCards: ReactNode[]
}

export const SummarySection = (props: Props) => {
  const [isMobile] = useMediaQuery(MOBILE_QUERY)
  return (
    <div key={props.title}>
      <h2>{props.title}</h2>

      <SummaryHeader items={props.summaryHeaderItems} />

      <div
        style={{
          display: 'flex',
          flexDirection: isMobile ? 'column' : 'row',
          flexWrap: 'wrap',
          gap: 20,
        }}
      >
        {props.metricCards.map((card, index) => (
          <div key={index}>{card}</div>
        ))}
      </div>
    </div>
  )
}
