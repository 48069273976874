import { useQuery } from '@tanstack/react-query'
import { FlowTargetType } from 'app/models/flows/FlowTargetType'
import { FlowType } from 'app/models/flows/FlowType'
import { OperatorType } from 'app/models/flows/OperatorType'
import { MultiLanguageField } from 'app/models/microservices'
import graphClient from 'app/query-client/graphClient'
import { gql } from 'graphql-request'
import { ConditionType } from './useGetConditions'

export interface ConditionData {
  conditionTypeId: string
  checkEvery: number
  metric: string
  operator: OperatorType
  compareTo: string
  period: number | null
  conditionType: ConditionType
}

export interface FlowDetail {
  id: string
  business: string
  name: MultiLanguageField[]
  description: MultiLanguageField[]
  type: FlowType
  detectionOnly: boolean
  cooldown: number
  enabled: boolean
  conditions?: ConditionData[]
  trigger?: string
  microservice?: {
    id: string
    name: MultiLanguageField[]
    iconName?: string
    iconBackground?: string
  }

  initText?: MultiLanguageField[]
  okText?: MultiLanguageField[]
  koText?: MultiLanguageField[]

  targetType?: FlowTargetType
  workspaces?: string[]
  workspaceGroups?: {
    _id: string
    name: string
  }[]
  reportingGroups?: {
    _id: string
    name: string
  }[]
}

interface Response {
  getFlow: FlowDetail
}

const query = gql`
  query ($id: ID!) {
    getFlow(id: $id) {
      id
      business
      name {
        code
        text
        translatedByIA
      }
      description {
        code
        text
        translatedByIA
      }
      type
      detectionOnly
      cooldown
      enabled

      conditions {
        conditionTypeId
        metric
        operator
        compareTo
        period
        checkEvery
        conditionType {
          id
          name {
            code
            text
            translatedByIA
          }
          description {
            code
            text
            translatedByIA
          }
          iconName
          color
          type
          frequencyValues
          valueType
          valuePlaceholder
          period
        }
      }
      trigger
      microservice {
        id
        name {
          code
          text
          translatedByIA
        }
        iconName
        iconBackground
      }
      initText {
        code
        text
        translatedByIA
      }
      okText {
        code
        text
        translatedByIA
      }
      koText {
        code
        text
        translatedByIA
      }
      targetType
      workspaces
      workspaceGroups {
        _id
        name
      }
      reportingGroups {
        _id
        name
      }
    }
  }
`

async function getFlow(id: string): Promise<FlowDetail> {
  const response = await graphClient.request<Response>(query, { id })

  return response.getFlow
}

export const useGetFlow = (id: string) => {
  return useQuery(['flow', id], () => getFlow(id), {
    refetchOnWindowFocus: false,
  })
}
