import { ActionButton, FontIcon, IButtonStyles, IIconProps } from '@fluentui/react'
import { useTranslation } from 'react-i18next'

interface Props {
  isMobile: boolean
  onClick?: () => void
  text?: string
}

const actionButtonStylesDesktop: IButtonStyles = {
  root: {
    height: 22,
    padding: 0,
  },
  icon: {
    margin: '0 0 4px 0',
  },
  label: {
    height: 16,
  },
}

const actionButtonStylesMobile: IButtonStyles = {
  root: {
    height: 22,
    padding: 0,
  },
}

const SettingsButton = ({ isMobile, onClick, text }: Props) => {
  const { t } = useTranslation('general')
  const viewDetailIcon: IIconProps = { iconName: 'Settings' }
  const style = isMobile ? actionButtonStylesMobile : actionButtonStylesDesktop
  const className = isMobile ? 'd-flex d-flexRow d-flexJustifyCenter fix-height' : 'd-flex d-flexRow fix-height'

  return (
    <div>
      <ActionButton onClick={onClick} styles={style} iconProps={viewDetailIcon} allowDisabledFocus>
        {!isMobile && (text ? text : t('BUTTON.SETTINGS'))}
      </ActionButton>
    </div>
  )
}

export default SettingsButton
