import { EmptyStatus } from '@flexxibleit/flexxible-ui'
import EmptyUser from '../../../../../assets/img/user-icon.svg'
import { TFunction } from 'i18next'

type EmptyUserRolesProps = {
  t: TFunction
}

export const EmptyRoleUsers = ({ t }: EmptyUserRolesProps) => {
  return (
    <div style={{ marginTop: 100 }}>
      <EmptyStatus img={EmptyUser} message={t('ROLES.TABLE.USERS_EMPTY_MESSAGE')} />
    </div>
  )
}
