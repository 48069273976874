export enum SummaryType {
  Alert = 'Alert',
  Information = 'Information',
}
export enum SummarySectionType {
  Targets = 'Targets',
  Patches = 'Patches',
  Security = 'Security',
  Workspaces = 'Workspaces',
}
export enum PatchSummaryRuleSentences {
  ALL_REPORTING_GROUPS_MUST_HAVE_TARGET = 'ALL_REPORTING_GROUPS_MUST_HAVE_TARGET',
  ALL_TARGET_MUST_HAVE_SCHEDULE = 'ALL_TARGET_MUST_HAVE_SCHEDULE',
  ALL_TARGETS_MUST_HAVE_PATCH_POLICY = 'ALL_TARGETS_MUST_HAVE_PATCH_POLICY',
  MUST_HAVE_APPROVED_PATCHES = 'MUST_HAVE_APPROVED_PATCHES',
  MUST_NOT_HAVE_STALE_SECURITY_PATCHES_NOT_APPROVED = 'MUST_NOT_HAVE_STALE_SECURITY_PATCHES_NOT_APPROVED',
  ALL_WORKSPACES_MUST_HAVE_REPORTING_GROUP = 'ALL_WORKSPACES_MUST_HAVE_REPORTING_GROUP',
  MUST_BE_SOME_WORKSPACES_WITH_INSTALLED_PATCHES = 'MUST_BE_SOME_WORKSPACES_WITH_INSTALLED_PATCHES',
}
