import { useMutation, useQueryClient } from '@tanstack/react-query'
import { BusinessFormData } from 'app/pages/organizations/organization-list/OrganizationForm'
import { gql } from 'graphql-request'
import graphClient from '../../query-client/graphClient'

const updateBusinessQuery = gql`
  mutation Update($id: ID!, $input: UpdateBusinessInput!) {
    updateBusiness(id: $id, input: $input) {
      _id
    }
  }
`

const changeBusinessPartnerQuery = gql`
  mutation ChangePartner($id: ID!, $partnerId: ID) {
    changeBusinessPartner(id: $id, partnerId: $partnerId) {
      _id
    }
  }
`

const changeBusinessTypeQuery = gql`
  mutation ChangeType($id: ID!, $type: String!) {
    changeBusinessType(id: $id, type: $type) {
      _id
    }
  }
`

async function updateBusiness(business: any, organizationId: string | undefined) {
  const updateResponse = await graphClient.request(updateBusinessQuery, {
    id: organizationId,
    input: business,
  })

  if (business.partner !== undefined) {
    await graphClient.request(changeBusinessPartnerQuery, {
      id: organizationId,
      partnerId: business.partner,
    })
  }

  if (business.type !== undefined) {
    await graphClient.request(changeBusinessTypeQuery, {
      id: organizationId,
      type: business.type,
    })
  }

  return updateResponse.getBusiness
}

export default function (organizationId: string | undefined) {
  const queryClient = useQueryClient()

  return useMutation(
    ['updateBusiness'],
    (business: Partial<BusinessFormData>) => updateBusiness(business, organizationId),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['business'])
        queryClient.invalidateQueries(['getOrganizationsPaginated'])
        queryClient.invalidateQueries(['getBusinessById'])
      },
      onError: () => {
        queryClient.invalidateQueries(['business'])
        queryClient.invalidateQueries(['getOrganizationsPaginated'])
        queryClient.invalidateQueries(['getBusinessById'])
      },
    }
  )
}
