import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import { useEffect, useState } from 'react'
import useOrganizationById from '../../hooks/organization/useOrganizationById'
import { ExplorerContent } from './explorer/explorer-content.feature'
import { Business } from '../../query-client/types'
import { withFeatureRender } from '../../permissions/withFeatureRender'
import { FEATURE_NAMES } from '../../permissions/FeatureName.enum'
import PageBody from 'app/components/PageBody/PageBody.component'
import { IBreadcrumbItem } from '@fluentui/react'

const ReportsExplorerOrganization = () => {
  const { organizationId, productId } = useParams()

  const { isLoading, data, isFetching } = useOrganizationById(organizationId || '')

  const navigate = useNavigate()
  const { t } = useTranslation('reports');

  const [ productConfig, setProductConfig ] = useState<any>()
  const [ isConfigured, setConfigured ] = useState(false)

  useEffect(() => {
    if (!isLoading && !isFetching) {
      setProductConfig(findProductfromOrganization(data as Business, productId || ''))
      setConfigured(true)
    }
  }, [isLoading, isFetching])

  const onBreadcrumbItemClicked = (event?: React.MouseEvent, item?: IBreadcrumbItem) => {
    navigate(item?.key ?? '/home')
  }

  const findProductfromOrganization = (list: Business, productId: string ) => {
    return list.productConfigs.find((product) => product._id === productId)
  }

  const itemsAdmin: IBreadcrumbItem[] = [
    { text: data?.name ?? '', key: `/organization/${organizationId}`, onClick: onBreadcrumbItemClicked },
    { text: productConfig?.product?.name || '', key: 'current', isCurrentItem: true },
  ];

  return (
    <>
      { (isConfigured) && (
        <PageBody title={ t('TITLE') } breadcrumbItems={itemsAdmin} isLoading={isLoading || isFetching}>
          <ExplorerContent
            key={ 'explorer' }
            isAdmin={true}
            organizationId={organizationId || ''}
            organization={data || {} as Business}
            product={productConfig}
            />
        </PageBody>
      ) }
    </>
  )
}

export const ReportsExplorerOrganizationPage = withFeatureRender(
  ReportsExplorerOrganization,
  FEATURE_NAMES.REPORTS_READ
)
