import { CreateButton, LoadingSpinner, SearchBox } from '@flexxibleit/flexxible-ui'
import { useTranslation } from 'react-i18next'
import { ChangeEvent, useEffect, useState } from 'react'
import { Stack } from '@fluentui/react'
import { MOBILE_QUERY } from '../../../config/media-query'
import useMediaQuery from '../../../hooks/utils/useMediaQuery'
import { useNavigate } from 'react-router-dom'
import {
  DesignerMicroserviceView,
  useGetDesignerMicroservices,
} from '../../../hooks/microservices/useGetDesignerMicroservices'
import { MicroservicesDesignerTable } from './MicroservicesDesignerTable'
import i18next from 'i18next'
import { getTranslation } from 'app/models/microservices'
import { useOrganizationSelection } from 'app/globals/useOrganizationSelection'
import { useMe } from 'app/hooks/me/useMe'
import { SpecialPermission } from 'app/query-client/types'

export const MicroservicesDesigner = () => {
  const { t, i18n } = useTranslation('microservices')
  const [isMobile] = useMediaQuery(MOBILE_QUERY)
  const { selectedOrganizationId, rolesOnBusiness } = useOrganizationSelection()
  const { data: microservices, isLoading } = useGetDesignerMicroservices(selectedOrganizationId)
  const [myMicroservices, setMyMicroservices] = useState<DesignerMicroserviceView[]>()

  const handleOnSearch = (event?: ChangeEvent<HTMLInputElement>) => {
    const searchValue = event?.target.value
    if (searchValue) {
      const filteredMicroservices = myMicroservices?.filter((microservice) => {
        const name = getTranslation(microservice.name, i18next.language)
        const itemName = name.text

        return itemName.toLowerCase().includes(searchValue.toLowerCase())
      })
      setMyMicroservices(filteredMicroservices!)
      return
    }
    setMyMicroservices(microservices || [])
  }

  const renderMicroserviceTable = (): JSX.Element => {
    return <MicroservicesDesignerTable microservices={myMicroservices ?? []} contextActionsEnabled />
  }

  const renderMyMicroservices = (): JSX.Element => {
    if (isLoading || !myMicroservices) return <LoadingSpinner />

    return (
      <>
        <Stack
          horizontal={!isMobile}
          horizontalAlign="space-between"
          tokens={{ padding: '20px 0 0 0' }}
          className="mb-sm-2"
        >
          <Stack.Item grow={1} style={{ maxWidth: 400, minWidth: 196 }}>
            <SearchBox
              placeholder={t('MARKETPLACE.SEARCH_MICROSERVICES_PLACEHOLDER')}
              onChange={(newValue?: ChangeEvent<HTMLInputElement>) => handleOnSearch(newValue)}
            />
          </Stack.Item>
          <Stack.Item>
            <div
              className={`d-flex d-flexRow d-flexAlignItemsCenter ${isMobile ? 'd-flexJustifyBetween' : ''} `}
              style={isMobile ? { marginTop: 15 } : {}}
            >
              {rolesOnBusiness?.hasL2OrHigher() && <CreateMicroserviceButton />}
            </div>
          </Stack.Item>
        </Stack>
        {renderMicroserviceTable()}
      </>
    )
  }

  useEffect(() => {
    if (microservices) {
      setMyMicroservices(microservices)
    }
  }, [microservices])

  return renderMyMicroservices()
}

const CreateMicroserviceButton = () => {
  const { t, i18n } = useTranslation('microservices')
  const navigate = useNavigate()
  const { data: me } = useMe()

  if (!me || !me.specialPermissions?.length) {
    return <CreateButton locale={i18n.language} onClick={() => navigate('/microservices-designer/create')} />
  }

  const canCreateConditionMicroservice = me.specialPermissions?.includes(SpecialPermission.CONDITION_MICROSERVICE)
  const canCreateCustomFieldsMicroservice = me.specialPermissions?.includes(
    SpecialPermission.CUSTOM_FIELDS_MICROSERVICE
  )

  return (
    <CreateButton
      locale={i18n.language}
      onClick={() => navigate('/microservices-designer/create')}
      split
      menuProps={{
        items: [
          ...(canCreateConditionMicroservice
            ? [
                {
                  key: 'create',
                  iconProps: { iconName: 'TriggerAuto' },
                  text: t('DESIGNER.ACTION.CREATE_CONDITION'),
                  onClick: () => navigate('/microservices-designer/create/condition'),
                },
              ]
            : []),
          ...(canCreateCustomFieldsMicroservice
            ? [
                {
                  key: 'create2',
                  iconProps: { iconName: 'FieldChanged' },
                  text: t('DESIGNER.ACTION.CREATE_CUSTOM_FIELDS'),
                  onClick: () => navigate('/microservices-designer/create/custom-fields'),
                },
              ]
            : []),
        ],
      }}
    />
  )
}