import { Col, EditButton, MOBILE_QUERY, Row, TABLET_QUERY, useMediaQuery } from '@flexxibleit/flexxible-ui'
import { DisplayField } from 'app/components/DisplayField'
import FlexxibleCard from 'app/components/FlexxibleCard/FlexxibleCard.component'
import useCreateForm from 'app/hooks/useCreateForm'
import { DateFormat, dateFormatter } from 'app/services/date-formatter'
import { useTranslation } from 'react-i18next'
import DataTableIcon from '../../../../../assets/img/data-table-svgrepo-com.svg'
import SummaryCard from '../../../components/SummaryCard/SummaryCard'
import { ProductConfig, ReportingGroup, UserRole } from 'app/query-client/types'
import { ReportingGroupForm } from '../../ReportingGroupForm'
import useOrganizationById from 'app/hooks/organization/useOrganizationById'

const cardProps = {
  style: { marginTop: 30 },
}

interface Props {
  reportingGroup: ReportingGroup
  organizationId: string
}

export const ReportingGroupDetails = ({ reportingGroup, organizationId }: Props) => {
  const { t } = useTranslation('organization_details')
  const [isMobile] = useMediaQuery(MOBILE_QUERY)
  const [isTablet] = useMediaQuery(TABLET_QUERY)
  const isDesktop = !isMobile && !isTablet
  const { isLoading, data, isFetching, refetch } = useOrganizationById(organizationId || '')

  const openEdit = (reportingGroup: ReportingGroup) => {
    return useCreateForm(
      {
        title: t('REPORTING_GROUPS.FORM.EDIT_TITLE'),
        isFooterAtBottom: true,
      },
      <ReportingGroupForm
        id={reportingGroup._id}
        data={{
          name: reportingGroup.name,
          productConfig: reportingGroup.productConfig?._id,
          patchPolicyTargetId: reportingGroup.patchPolicyTargetId,
          fishingPattern: reportingGroup?.fishingPattern
        }}
        policyReportingGroup={reportingGroup.policyReportingGroup}
        organizationId={organizationId}
        reportingGroups={data?.reportingGroups ?? []}
        productConfigs={data?.productConfigs ?? []}
      />
    )
  }


  return (
    <div
      className={!isDesktop ? 'd-flex d-flexCol d-flexColReverse' : 'd-flex d-flexRow col-xs-12'}
      style={!isDesktop ? { gap: 0 } : { gap: 30, paddingRight: 20 }}
    >
      <FlexxibleCard cardProps={cardProps} styleNames="col-md-9 d-flex d-flexCol d-flexJustifyBetween">
        <Row>
          <Col className="col-xs-12 col-lg-6">
            <DisplayField label={t('REPORTING_GROUPS.DETAILS.NAME')}>{reportingGroup?.name}</DisplayField>
          </Col>
        </Row>
        <Row>
          <Col className="col-xs-12 col-lg-6">
            <DisplayField label={t('REPORTING_GROUPS.DETAILS.ENVIRONMENT')}>
              {reportingGroup?.productConfig?.environment}
            </DisplayField>
          </Col>
        </Row>
        <Row>
          <Col className="col-xs-12 col-lg-6">
            <DisplayField label={t('REPORTING_GROUPS.DETAILS.FISHING_PATTERN')}>
              {reportingGroup?.fishingPattern || '-'}
            </DisplayField>
          </Col>
        </Row>

        <div className="d-flex d-flexRowReverse">
          <EditButton translator={t} onClick={openEdit(reportingGroup)} />
        </div>
      </FlexxibleCard>

      <div className="col-md-3 summary-container d-flexJustifyCenter d-flexAlignItemsCenter">
        <>
          <div
            className={isMobile || isTablet ? 'd-flex d-flexRow d-flexWrap' : 'd-flex d-flexCol '}
            style={{ gap: 20 }}
          >
            <SummaryCard
              Icon={DataTableIcon}
              title={t('ROLES.SUMMARY.CREATED_AT')}
              text={dateFormatter(t, reportingGroup?.created_at, DateFormat.Short)}
            />
          </div>
        </>
      </div>
    </div>
  )
}
