import { gql } from "graphql-request";
import graphClient from "../../query-client/graphClient";
import { WorkspaceGroupMetric } from "../../query-client/types";
import { useQuery, UseQueryResult } from "@tanstack/react-query";

const workspaceGroupMetricsQyery = gql`
query($workspaceGroupId: ID!) {
  getWorkspaceGroupMetricsByWorkspaceGroup(workspace_group_id: $workspaceGroupId) {
    workspaceCount
    created_at
    workspaceGroup {
        _id
        name
    }
  }
}`

const getWorkspaceGroupMetrics = async (workspaceGroupId: string): Promise<WorkspaceGroupMetric[]> => {
  const res = await graphClient.request(workspaceGroupMetricsQyery, { workspaceGroupId })
  return res.getWorkspaceGroupMetricsByWorkspaceGroup
}

export const useGetWorkspaceGroupMetrics = (workspaceGroupId: string): UseQueryResult<WorkspaceGroupMetric[]> => {
  return useQuery(['getWorkspaceGroupMetrics', workspaceGroupId], () => getWorkspaceGroupMetrics(workspaceGroupId), {
    refetchOnWindowFocus: false,
  })
}