import { PropsWithChildren } from 'react'
import { Card } from '@flexxibleit/flexxible-ui'
import { Icon, MessageBar, MessageBarType, TooltipHost } from '@fluentui/react'

interface Props {
  title: string,
  message?: string | undefined,
  messageBarType?: MessageBarType,
  tooltip?: string
}

export const DashboardChartCard = (props: PropsWithChildren<Props>) => {
  const cardProps = {
    style: {
      width: '100%'
    }
  }

  const tooltipStyles = {
    root: {
      cursor: 'pointer',
    }
  }

  return (
    <Card cardProps={ cardProps }>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <h2
          style={{
            display: 'flex',
            alignItems: 'center',
            gap: '0.5rem',
          }}>
          {props.title}
        </h2>
        {props.tooltip && (
          <TooltipHost content={props.tooltip} styles={tooltipStyles}>
            <Icon
              iconName={'info'}
              style={{
                color: '#464A4F',
                fontSize: '1.2em',
                marginLeft: 6
              }}
            />
          </TooltipHost>
        )}
      </div>
      {props.message && (
        <MessageBar
          messageBarType={props.messageBarType || 0}
          isMultiline={true}
          className="mb-2"
          style={{ width: '100%' }}
        >
          {props.message}
        </MessageBar>
      )}
      { props.children }
    </Card>
  )
}

