import { gql } from 'graphql-request'
import { PaginationArgs, PaginationResponse, WindowsPatchFilter, WindowsPatches } from '../../query-client/types'
import graphClient from '../../query-client/graphClient'
import { useQuery } from '@tanstack/react-query'

export interface PaginationResponseWindowsPatches extends PaginationResponse<WindowsPatches> {
  lastUpdatedWindowsPatches: string | null
}

const getWindowsPatchesV2Query = gql`
  query (
    $patchPolicyId: ID!
    $filter: GetWindowsPatchesV2FilterInput!
    $pagination: GetWindowsPatchesV2PaginationInput!
  ) {
    getWindowsPatchesV2(patchPolicyId: $patchPolicyId, filter: $filter, pagination: $pagination) {
      data {
        id
        kb
        title
        category
        product
        status
        approvedAt
        approvedBy {
          _id
          name
          surname
        }
        infoUrl
        severity
        creationDate
        arrivalDate
        rebootBehavior
        rebootUninstallationBehavior
        state
        declinedAt
        declinedBy {
          _id
          name
          surname
        }
        automatic
      }
      pageInfo {
        hasNextPage
        hasPreviousPage
        totalPages
        currentPage
        total
      }
      lastUpdatedWindowsPatches
    }
  }
`

function getWindowsPatchesV2(
  patchPolicyId: string,
  filter: WindowsPatchFilter,
  pagination?: PaginationArgs
): Promise<PaginationResponseWindowsPatches> {
  return graphClient
    .request(getWindowsPatchesV2Query, {
      patchPolicyId,
      filter,
      pagination,
    })
    .then((response) => {
      return {
        results: response.getWindowsPatchesV2.data,
        pageInfo: response.getWindowsPatchesV2.pageInfo,
        lastUpdatedWindowsPatches: response.getWindowsPatchesV2.lastUpdatedWindowsPatches,
      }
    })
}

export default function (patchPolicyId: string, filter: WindowsPatchFilter, pagination?: PaginationArgs) {
  return useQuery(
    ['getWindowsPatchesV2', pagination, filter],
    () => getWindowsPatchesV2(patchPolicyId, filter, pagination),
    {
      refetchOnWindowFocus: false,
      keepPreviousData: true,
    }
  )
}
