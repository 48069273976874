import { useQuery } from '@tanstack/react-query'
import { gql } from 'graphql-request'
import graphClient from 'app/query-client/graphClient'
import { LinuxPatchPolicy } from 'app/query-client/types'

const getLinuxPatchPolicyQuery = gql`
  query GetLinuxPatchPolicy($getLinuxPatchPolicyId: String!) {
    getLinuxPatchPolicy(id: $getLinuxPatchPolicyId) {
      _id
      name
      organizationId
      createdBy {
        _id
        name
        surname
      }
      patchPolicyTargets {
        _id
        name
      }
      targetGroupId
      createdAt
      updatedAt
      excludes {
        name
        rule
      }
    }
  }
`

async function getLinuxPatchPolicy(request: any): Promise<LinuxPatchPolicy> {
  const response = await graphClient.request(getLinuxPatchPolicyQuery, { getLinuxPatchPolicyId: request.id })

  return response.getLinuxPatchPolicy
}

//TODO poner bien los tipos

export default function (request: any, shouldFetch: boolean) {
  return useQuery(['getLinuxPatchPolicy', request.id], () => getLinuxPatchPolicy(request), {
    refetchOnWindowFocus: false,
    enabled: shouldFetch,
  })
}
