import { useTranslation } from 'react-i18next'
import useMediaQuery from '../../../hooks/utils/useMediaQuery'
import { MOBILE_QUERY } from '../../../config/media-query'
import { PaginationArgs, Workspace } from '../../../query-client/types'
import { ColumnActionsMode, SelectionMode, Stack } from '@fluentui/react'
import Table from '../../../components/Table/Table.component'
import { useNavigate } from 'react-router-dom'
import useGetWorkspacesByOrganization from 'app/hooks/workspace/useGetWorkspacesByOrganization'
import { useMemo, useState } from 'react'
import { SearchBox } from '@flexxibleit/flexxible-ui'

interface Props {
  organizationId: string
  detailPageUrlBase: string
}

export const WorkspacesFeature = ({ organizationId, detailPageUrlBase }: Props) => {
  const navigate = useNavigate()
  const { t, i18n } = useTranslation('workspace_groups')
  const [isMobile] = useMediaQuery(MOBILE_QUERY)

  const [paginationState, setPaginationState] = useState<PaginationArgs>(() => ({
    page: 1,
    perPage: 10,
  }))

  const [searchTerm, setSearchTerm] = useState<string>('')

  const {
    data: workspacesPagination,
    isLoading: isLoadingWorkspaces,
    isFetching: isFetchingWorkspaces,
  } = useGetWorkspacesByOrganization(organizationId, searchTerm, paginationState)

  const availableWorkspacesPage: Workspace[] = useMemo(
    () => workspacesPagination?.results || [],
    [workspacesPagination]
  )

  const handleOnPaginationChange = (newPaginationState: PaginationArgs) => {
    setPaginationState(newPaginationState)
  }

  const getColumns = () => {
    function getUTypeDisplayValue(uType?: string): string {
      if (!uType) {
        return ''
      }
      if (uType.toLowerCase() == 'physical') {
        return 'Physical'
      }
      if (uType.toLowerCase() == 'virtual') {
        return 'Virtual'
      }
      return uType
    }

    return [
      {
        key: 'name',
        name: t('TABLE.NAME'),
        fieldName: 'name',
        minWidth: 100,
        isRowHeader: true,
        onRender: ({ name }: Workspace) => {
          return <span>{name}</span>
        },
        isPadded: true,
        columnActionsMode: ColumnActionsMode.disabled,
      },
      {
        key: 'fqdn',
        name: t('TABLE.FQDN'),
        fieldName: 'fqdn',
        minWidth: 260,
        isRowHeader: true,
        onRender: ({ fqdn }: Workspace) => {
          return <span>{fqdn}</span>
        },
        isPadded: true,
        columnActionsMode: ColumnActionsMode.disabled,
      },
      {
        key: 'ipAddress',
        name: t('TABLE.IP_ADDRESS'),
        fieldName: 'ipAddress',
        minWidth: 100,
        isRowHeader: true,
        onRender: ({ ipAddress }: Workspace) => {
          return <span>{ipAddress}</span>
        },
        isPadded: true,
        columnActionsMode: ColumnActionsMode.disabled,
      },
      {
        key: 'uOperativeSystem',
        name: t('TABLE.U_OPERATIVE_SYSTEM'),
        fieldName: 'uOperativeSystem',
        minWidth: 200,
        isRowHeader: true,
        onRender: ({ uOperativeSystem }: Workspace) => {
          return <span>{uOperativeSystem}</span>
        },
        isPadded: true,
        columnActionsMode: ColumnActionsMode.disabled,
      },
      {
        key: 'uCoresCpu',
        name: t('TABLE.U_CORES_CPU'),
        fieldName: 'uCoresCpu',
        minWidth: 50,
        isRowHeader: true,
        onRender: ({ uCoresCpu }: Workspace) => {
          return <span>{uCoresCpu}</span>
        },
        isPadded: true,
        columnActionsMode: ColumnActionsMode.disabled,
      },
      {
        key: 'uRam',
        name: t('TABLE.U_RAM'),
        fieldName: 'uRam',
        minWidth: 50,
        isRowHeader: true,
        onRender: ({ uRam }: Workspace) => {
          return <span>{uRam}</span>
        },
        isPadded: true,
        columnActionsMode: ColumnActionsMode.disabled,
      },
      {
        key: 'uType',
        name: t('TABLE.U_TYPE'),
        fieldName: 'uType',
        minWidth: 50,
        isRowHeader: true,
        onRender: ({ uType }: Workspace) => {
          return <span>{getUTypeDisplayValue(uType)}</span>
        },
        isPadded: true,
        columnActionsMode: ColumnActionsMode.disabled,
      },
      {
        key: 'uLastUser',
        name: t('TABLE.U_LAST_USER'),
        fieldName: 'uLastUser',
        minWidth: 180,
        isRowHeader: true,
        onRender: ({ uLastUser }: Workspace) => {
          return <span>{uLastUser}</span>
        },
        isPadded: true,
        columnActionsMode: ColumnActionsMode.disabled,
      },
    ]
  }

  const onSearchChange = (ev: unknown, text?: string): void => {
    setSearchTerm(text || '')
  }

  return (
    <>
      <Stack
        horizontal={!isMobile}
        horizontalAlign="space-between"
        tokens={{ padding: '20px 0 0 0' }}
        className="mb-sm-2"
      >
        <Stack.Item grow={1} style={{ maxWidth: 400, minWidth: 196 }}>
          <SearchBox placeholder={t('TABLE.SEARCH_PLACEHOLDER')} onChange={onSearchChange} />
        </Stack.Item>
      </Stack>
      <Table
        tableId="workspaces-table"
        isLoading={isLoadingWorkspaces}
        isEmpty={availableWorkspacesPage?.length === 0}
        emptyMessage={t('general:EMPTY_FILTER')}
        tableProps={{
          columns: getColumns(),
          items: availableWorkspacesPage,
          selectionMode: SelectionMode.none,
          onShouldVirtualize: () => false,
        }}
        paginationProps={
          workspacesPagination
            ? {
                pagination: paginationState,
                onPaginationChange: handleOnPaginationChange,
                paginatedData: workspacesPagination,
                isFetching: isFetchingWorkspaces,
              }
            : undefined
        }
      />
    </>
  )
}
