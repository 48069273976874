import { useQuery } from '@tanstack/react-query'
import graphClient from 'app/query-client/graphClient'
import { UserRole } from 'app/query-client/types'
import { gql } from 'graphql-request'

interface GetUserRoleByIdRequest {
  id: string
  organizationId: string
}

const query = gql`
  query Request($id: ID!, $organizationId: ID!) {
    getUserRoleById(id: $id, organizationId: $organizationId) {
      _id
      created_at
      updated_at
      name
      created_by {
        _id
        name
        surname
        email
      }
      updated_by {
        _id
        name
        surname
        email
      }
      assignedUsers
      assignedOrganizations
      organizationId
      permissions {
        allOrganizations
        organization
      }
      canBeCloned
    }
  }
`


interface GetUserRoleByIdResponse {
  getUserRoleById: UserRole
}

async function getUserRoleById(request: GetUserRoleByIdRequest) {
  const { getUserRoleById }: GetUserRoleByIdResponse = await graphClient.request(query, {
    id: request.id,
    organizationId: request.organizationId,
  })

  return getUserRoleById
}

export default function (request: GetUserRoleByIdRequest, shouldFetch: boolean) {
  return useQuery(['getUserRoleById', request.id], () => getUserRoleById(request), {
    refetchOnWindowFocus: false,
    enabled: shouldFetch,
  })
}
