import { 
  IDropdownOption, 
  IComboBoxOption,
  SelectableOptionMenuItemType, 
  Dropdown as FluentDropdown, 
  IDropdownProps } from "@fluentui/react";
import { useEffect, useState } from 'react'
import { useFormContext, useFormState } from 'react-hook-form'
import { TFunction } from 'react-i18next'
import Controlled from '../Controlled'

export interface DropdownProps {
  name: string
  options: IDropdownOption[]
  translator: TFunction
  multiselectProps: Partial<IDropdownProps>
  styles?: any
  onRenderOption?: any
  selectedList?: string[]
}

function Multiselect({
  name,
  options,
  translator,
  styles,
  onRenderOption,
  selectedList,
  multiselectProps,
}: DropdownProps) {
  const { control } = useFormContext()
  const { errors } = useFormState({ control })
  const errorMessageKey: any = errors[name]?.message
  const [selectedKeys, setSelectedKeys] = useState<string[]>(selectedList || [])

  useEffect(() => {
    setSelectedKeys(selectedList || [])
  }, [selectedList])

  const selectableOptions = options.filter(
    (option) =>
      (option.itemType === SelectableOptionMenuItemType.Normal || option.itemType === undefined) && !option.disabled
  )

  const _onChange = (option: IComboBoxOption, field: any): void => {
    const selected = option.selected
    const currentSelectedOptionKeys = selectedKeys.filter((key) => key !== 'selectAll')
    const selectAllState = currentSelectedOptionKeys.length === selectableOptions.length

    if (option) {
      if (option?.itemType === SelectableOptionMenuItemType.SelectAll) {
        selectAllState
          ? setSelectedStatus(field, [])
          : setSelectedStatus(field, ['selectAll', ...selectableOptions.map((o) => o.key as string)])
      } else {
        const updatedKeys = selected
          ? [...currentSelectedOptionKeys, option!.key as string]
          : currentSelectedOptionKeys.filter((k) => k !== option.key)
        if (updatedKeys.length === selectableOptions.length) {
          updatedKeys.push('selectAll')
        }
        setSelectedStatus(field, updatedKeys)
      }
    }
  }

  const setSelectedStatus = (field: any, selectedStatus: string[]) => {
    field.onChange(selectedStatus.filter((key) => key !== 'selectAll'))
    setSelectedKeys(selectedStatus)
  }

  const onRenderTitle = (options?: IDropdownOption[]): JSX.Element => {
    return (
      <div>
        {options
          ?.filter((option) => option.itemType !== SelectableOptionMenuItemType.SelectAll)
          .map((option) => option.text)
          .join(', ')}
      </div>
    )
  }

  return (
    <>
      <Controlled
        name={name}
        render={({ field }) => (
          <FluentDropdown
            {...field}
            {...multiselectProps}
            options={options}
            multiSelect
            styles={styles}
            onRenderTitle={onRenderTitle}
            onRenderOption={onRenderOption}
            selectedKeys={selectedKeys}
            onChange={(e: any, item: any) => {
              _onChange(item, field)
            }}
            errorMessage={!!errors[name] ? translator(`ERRORS.${name}_${errorMessageKey.toLowerCase()}`) : ''}
          />
        )}
      />
    </>
  )
}

export default Multiselect