export const serverLessOperators = {
  $eq: (a: any, b: any) => a === b,
  $eqi: (a: string, b: string) => a.toLowerCase() === b.toLowerCase(),
  $ne: (a: any, b: any) => a !== b,
  $nei: (a: string, b: string) => a.toLowerCase() !== b.toLowerCase(),
  $lt: (a: number, b: number) => a < b,
  $lte: (a: number, b: number) => a <= b,
  $gt: (a: number, b: number) => a > b,
  $gte: (a: number, b: number) => a >= b,
  $contains: (a: string | any[], b: any) => a.includes(b),
  $notContains: (a: string | any[], b: any) => !a.includes(b),
  $containsi: (a: string, b: string) => a.toLowerCase().includes(b.toLowerCase()),
  $notContainsi: (a: string, b: string) => !a.toLowerCase().includes(b.toLowerCase()),
  $null: (a: null | undefined) => a === null || a === undefined,
  $notNull: (a: null | undefined) => a !== null && a !== undefined,
  $startsWith: (a: string, b: any) => a.startsWith(b),
  $startsWithi: (a: string, b: string) => a.toLowerCase().startsWith(b.toLowerCase()),
  $endsWith: (a: string, b: any) => a.endsWith(b),
  $endsWithi: (a: string, b: string) => a.toLowerCase().endsWith(b.toLowerCase()),
} as any
