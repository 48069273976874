export const useOrgEligibleProductsBuildExport = () => {
  const exportColumns = [
    {
      key: 'name',
      header: 'Name',
      width: 20,
    },
    {
      key: 'regions',
      header: 'Regions',
      width: 50,
    },
  ]

  return { exportColumns }
}
