import { SearchableDropdown } from '../../../components/SearchableDropdown/SearchableDropdown.component'
import { defaultDropdownStyles } from '../../../components/forms/Dropdown'
import { ISelectableOption, SpinnerSize } from '@fluentui/react'
import { renderOrganizationSelectorDropdownItem } from '../../common/OrganizationSelectorDropdownItemRenderer/OrganizationSelectorDropdownItemRenderer'
import { useTranslation } from 'react-i18next'
import { Business } from '../../../query-client/types'
import { useEffect, useMemo, useState } from 'react'
import { useMySettings } from '../../../hooks/userSettings/useMySettings'
import { useUpdateMySettings } from '../../../hooks/userSettings/useUpdateMySettings'
import { useGetOrganizationsForSelection } from '../../../hooks/organization/useGetOrganizationsForSelection'
import { LoadingSpinner } from '@flexxibleit/flexxible-ui'

interface DefaultOrganizationSelectorProps {
  userOrganization: Business
}

export const DefaultOrganizationSelector = (props: DefaultOrganizationSelectorProps) => {
  const { t } = useTranslation()
  const { mySettings } = useMySettings()
  const { updateMySettings } = useUpdateMySettings()
  const userOrganization = props.userOrganization
  const [selectedDefaultOrganization, setSelectedDefaultOrganization] = useState<string>(userOrganization._id)
  const { data: selectableOrganizations } = useGetOrganizationsForSelection(true)
  const [isLoading, setIsLoading] = useState(false)

  const isDefaultSelectedOrganizationInSelectableOrganizations = () => {
    return selectableOrganizations?.some((org) => org._id === mySettings?.defaultOrganizationSelected)
  }
  const setSelectedDefaultOrganizationFromUserSettings = () => {
    if (!mySettings) {
      return
    }

    if (mySettings?.defaultOrganizationSelected && isDefaultSelectedOrganizationInSelectableOrganizations()) {
      setSelectedDefaultOrganization(mySettings?.defaultOrganizationSelected)
      return
    }
    setSelectedDefaultOrganization(userOrganization._id)
  }

  const buildOrganizationOptions = () => {
    return (
      selectableOrganizations?.map((org) => {

        return {
          key: org._id,
          text: org.name,
          data: { isTrial: org.isTrial }
        }
      }) || []
    )
  }
  const organizationOptions = useMemo(buildOrganizationOptions, [selectableOrganizations])

  const handleOnDropdownRenderOption = (
    props?: ISelectableOption,
    _?: (props?: ISelectableOption) => JSX.Element | null
  ): JSX.Element | null => {
    return renderOrganizationSelectorDropdownItem(t, props, userOrganization, props?.data?.isTrial)
  }
  const handleOnDropdownRenderTitle = (
    props?: ISelectableOption[],
    _?: (props?: ISelectableOption[]) => JSX.Element | null
  ): JSX.Element | null => {
    return renderOrganizationSelectorDropdownItem(t, props?.[0], userOrganization, props?.[0].data?.isTrial)
  }

  useEffect(setSelectedDefaultOrganizationFromUserSettings, [mySettings, selectableOrganizations])

  return (
    <>
      <SearchableDropdown
        dropdownProps={{
          label: t('user_settings:SELECT_ORGANIZATION_LABEL'),
          selectedKey: selectedDefaultOrganization,
          options: organizationOptions,
          disabled: isLoading,
          styles: {
            title: {
              height: 'fit-content',
              lineHeight: '20px',
              paddingLeft: 0,
              paddingTop: '1px',
              paddingBottom: '1px',
            },
            caretDownWrapper: {
              height: '100%',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
            },
            dropdownItem: {
              height: 'fit-content',
            },
            dropdownItemSelected: {
              ...defaultDropdownStyles,
              height: 'fit-content',
            },
          },
          onChange: (event, option) => {
            setIsLoading(true)
            const selectedOrganizationId = String(option!.key)

            setSelectedDefaultOrganization(selectedOrganizationId)
            updateMySettings({
              defaultOrganizationSelected: selectedOrganizationId,
            })
              .then(() => {
                setIsLoading(false)
              })
              .catch(() => setIsLoading(false))
          },
          onRenderOption: handleOnDropdownRenderOption,
          onRenderTitle: handleOnDropdownRenderTitle,
        }}
      />
      {isLoading && (
        <div
          style={{
            top: '-35px',
            position: 'relative',
          }}
        >
          <LoadingSpinner size={SpinnerSize.small} />
        </div>
      )}
    </>
  )
}
