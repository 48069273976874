import { MOBILE_QUERY, TablePageBody, useMediaQuery } from '@flexxibleit/flexxible-ui'
import { BusinessType } from '../../../models/business/BusinessType'
import { getOrganizationTypeIcon } from '../../../services/organization-icon-service'
import { useOrganizationSelection } from '../../../globals/useOrganizationSelection'
import { useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom'
import useOrganizationById from '../../../hooks/organization/useOrganizationById'
import { useEffect } from 'react'
import { FeedbackMessageBar } from '../../../components/FeedbackMessageBar/FeedbackMessageBar'
import { withFeatureRender } from '../../../permissions/withFeatureRender'
import { FEATURE_NAMES } from '../../../permissions/FeatureName.enum'
import { UsersFeature } from './Users.feature'
import { useTranslation } from 'react-i18next'
import { useLocalStorageSearchParams } from 'app/hooks/utils/useLocalStorageSearchParams'

const TitleComponent = ({ title, type }: { title: string; type?: BusinessType }) => {
  const [isMobile] = useMediaQuery(MOBILE_QUERY)

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'start',
      }}
    >
      {type && <div className="mr-1 vertical-center">{getOrganizationTypeIcon(type)}</div>}
      {isMobile ? <h3>{title}</h3> : <h1>{title}</h1>}
    </div>
  )
}

const OrgUsers = () => {
  const { t } = useTranslation('organization_details')
  const navigate = useNavigate()
  const { selectedOrganizationId } = useOrganizationSelection()
  const { organizationId } = useParams()
  const { isLoading, data, isFetching, refetch } = useOrganizationById(organizationId || '')
  const { getPersistedSearchParams } = useLocalStorageSearchParams(false)
  const location = useLocation()
  const baseUrl = location.pathname.split('/')[1]

  useEffect(() => {
    if (!selectedOrganizationId) {
      return
    }

    const finalSearchParams = location.search !== '' ? location.search : getPersistedSearchParams(location.pathname)
    navigate(`/${baseUrl}/${selectedOrganizationId}/users${finalSearchParams}`)
  }, [selectedOrganizationId])

  useEffect(() => {
    refetch()
  }, [organizationId])

  return (
    <TablePageBody
      title={isLoading || isFetching ? '' : data?.name || ''}
      titleComponent={<TitleComponent title={`${data?.name} - ${t('USERS_PIVOT')}`} type={data?.type} />}
      isLoading={isLoading || isFetching}
    >
      <FeedbackMessageBar />

      <UsersFeature organizationId={data?._id || ''} />
    </TablePageBody>
  )
}

export const OrgUsersPage = withFeatureRender(OrgUsers, FEATURE_NAMES.USER_READ)
