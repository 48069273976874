import { gql } from 'graphql-request'
import { PaginationArgs, PaginationResponse, Workspace } from '../../query-client/types'
import graphClient from '../../query-client/graphClient'
import { useQuery } from '@tanstack/react-query'

const getNotAddedWorkspacesListQuery = gql`
query($workspaceSysIds: [String!]!, $businessId: ID!, $perPage: Int!, $page: Int!, $searchTerm: String!) {
  getNotAddedWorkspacesList(workspaceSysIds: $workspaceSysIds, businessId: $businessId, perPage: $perPage, page: $page, searchTerm: $searchTerm) {
    workspaces {
      _id
      name
      fqdn
      company
      sysDomain
      sysId
      uLastUser
      uOperativeSystem
      uRelatedEnvironment
      uHostname
      ipAddress
      uCoresCpu
      uRam
      uType
    }
    pageInfo {
      hasNextPage
      hasPreviousPage
      totalPages
      currentPage
      total
    }
  }
}
`

function getNotAddedWorkspacesList(
  businessId: string,
  workspaceSysIds: string[],
  searchTerm: string,
  pagination: PaginationArgs,
): Promise<PaginationResponse<Workspace>> {
  return graphClient
    .request(getNotAddedWorkspacesListQuery, {
      businessId,
      workspaceSysIds,
      page: pagination.page,
      perPage: pagination.perPage,
      searchTerm,
    })
    .then((response) => {
      return {
        results: response.getNotAddedWorkspacesList.workspaces,
        pageInfo: response.getNotAddedWorkspacesList.pageInfo,
      }
    })
}

export default function (businessId: string, workspaceSysIds: string[], searchTerm: string, pagination: PaginationArgs, enabled: boolean = true) {
  return useQuery(
    ['getNotAddedWorkspacesList', businessId, workspaceSysIds, searchTerm, pagination],
    () => getNotAddedWorkspacesList(businessId, workspaceSysIds, searchTerm, pagination),
    {
      refetchOnWindowFocus: false,
      keepPreviousData: true,
      enabled
    }
  )
}
