import Dagre from '@dagrejs/dagre'
import { Edge, Node } from 'reactflow'

export const getLayoutedElements = (nodes: Node[], edges: Edge[], options: { direction: string }) => {
  const g = new Dagre.graphlib.Graph().setDefaultEdgeLabel(() => ({}))

  g.setGraph({ rankdir: options.direction, ranksep: 70 })

  edges.forEach((edge) => g.setEdge(edge.source, edge.target))
  nodes.forEach((node) => g.setNode(node.id, node as any))

  Dagre.layout(g)

  return {
    nodes: nodes.map((node) => {
      const dagreNode = g.node(node.id)
      const { x, y } = dagreNode

      return { ...node, position: { x: x, y: y } }
    }),
    edges,
  }
}
