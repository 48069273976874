import { EmptyStatus } from '@flexxibleit/flexxible-ui'
import { ColumnActionsMode, DetailsListLayoutMode, IColumn, SelectionMode } from '@fluentui/react'
import Table from 'app/components/Table/Table.component'
import { TenantPolicyTenantView } from 'app/hooks/policies/useGetPolicy'
import { dateFormatter } from 'app/services/date-formatter'
import { useTranslation } from 'react-i18next'

interface Props {
  tenants: TenantPolicyTenantView[]
}

export default function Organizations({ tenants }: Props) {
  const { t } = useTranslation('organization_details')

  const columns: IColumn[] = [
    {
      key: 'name',
      name: t('TENANTS.TABLE.NAME'),
      fieldName: 'name',
      minWidth: 100,
      maxWidth: 400,
      isRowHeader: true,
      isPadded: true,
      columnActionsMode: ColumnActionsMode.disabled,
    },
    {
      key: 'policyAppliedAt',
      name: t('TENANTS.TABLE.POLICY_APPLIED_AT'),
      fieldName: 'policyAppliedAt',
      minWidth: 150,
      isRowHeader: true,
      isPadded: true,
      columnActionsMode: ColumnActionsMode.disabled,
    },
    {
      key: 'created_at',
      name: t('TENANTS.TABLE.CREATION_DATE'),
      fieldName: 'created_at',
      minWidth: 150,
      isRowHeader: true,
      isCollapsible: true,
      onRender: (item: TenantPolicyTenantView) => {
        return <span>{dateFormatter(t, item.created_at)}</span>
      },
      isPadded: true,
      columnActionsMode: ColumnActionsMode.disabled,
    },
  ]

  if (!tenants.length) {
    return (
      <div style={{ marginTop: 100 }}>
        <EmptyStatus message={t('POLICIES.TENANTS_EMPTY_MESSAGE')} />
      </div>
    )
  }

  return (
    <Table
      tableId="organizations"
      isEmpty={tenants.length === 0}
      emptyMessage={t('general:EMPTY_FILTER')}
      tableProps={{
        columns,
        items: tenants,
        compact: false,
        selectionMode: SelectionMode.none,
        layoutMode: DetailsListLayoutMode.justified,
        isHeaderVisible: true,
      }}
    />
  )
}
