import {
  CreateButton,
  EmptyStatus,
  MOBILE_QUERY,
  useMediaQuery,
  ViewDetailsActionButton,
} from '@flexxibleit/flexxible-ui'
import {
  ColumnActionsMode,
  DetailsListLayoutMode,
  IColumn,
  Icon,
  SelectionMode,
  Stack,
  TooltipHost,
  TooltipOverflowMode,
} from '@fluentui/react'
import DownloadAgentButton from 'app/components/Buttons/ActionButtons/DownloadAgent.component'
import { GRAPH_BASE_URL, PRODUCT_ENV } from 'app/config/Consts'
import useCreateForm from 'app/hooks/useCreateForm'
import { ProductConfig, ReportingGroup, User } from 'app/query-client/types'
import { saveAs } from 'file-saver'
import { useTranslation } from 'react-i18next'
import { ReportingGroupForm } from './ReportingGroupForm'
import { ProductEnvTypes } from 'app/config/productEnv.enum'
import { useFeedbackMessage } from 'app/context/feedback-message/FeedbackMessageContext'
import { useFeatureRender } from 'app/permissions/useFeatureRender'
import { FEATURE_NAMES } from 'app/permissions/FeatureName.enum'
import { CellWithFeatureLink } from 'app/components/Table/CellWithFeatureLink'

import { GettingStartedChecklistTasks } from '../../../user-journey/getting-started-checklist/GettingStartedChecklistTasksEnum'
import { useGettingStartedChecklist } from 'app/user-journey/getting-started-checklist/useGettingStartedChecklist'
import Table from 'app/components/Table/Table.component'
import useGetPatchPolicyTargetsByOrganization from '../../../hooks/patchPolicyTarget/useGetPatchPolicyTargetsByOrganization'
import { useNavigate } from 'react-router-dom'
import SettingsButton from 'app/components/Buttons/ActionButtons/Settings.component'
import { SettingsReportingGroupForm } from './SettingsReportingGroupForm'
import { FeatureRender } from '../../../permissions/FeatureRender'

interface Props {
  organizationId: string
  reportingGroups: ReportingGroup[]
  policyName?: string
  users: User[]
  productConfigs?: ProductConfig[]
}

export default function ReportingGroups({ reportingGroups, organizationId, policyName, users, productConfigs }: Props) {
  const { t, i18n } = useTranslation('organization_details')
  const [isMobile] = useMediaQuery(MOBILE_QUERY)
  const isFxxOne = PRODUCT_ENV === ProductEnvTypes.FXXONE
  const { setErrorMessage } = useFeedbackMessage()
  const { completeGettingStartedTask } = useGettingStartedChecklist()
  const { data: patchPolicyTargets } = useGetPatchPolicyTargetsByOrganization(organizationId || '')
  const navigate = useNavigate()
  const { checkFeature } = useFeatureRender()

  const onCreate = useCreateForm(
    {
      title: t('REPORTING_GROUPS.FORM.CREATE_TITLE'),
      isFooterAtBottom: true,
    },
    <ReportingGroupForm
      organizationId={organizationId}
      reportingGroups={reportingGroups}
      productConfigs={productConfigs}
    />
  )

  const onViewDetails = (reportingGroup: ReportingGroup) => {
    navigate(`/organization/${organizationId}/reporting-groups/${reportingGroup._id}`)
  }

  const downloadAgent = (id: string) => {
    completeGettingStartedTask(GettingStartedChecklistTasks.DOWNLOAD_AGENT)
    const url = GRAPH_BASE_URL.replace(/\/api$/, '/') + `reporting-groups/${id}/download-agent`

    fetch(url)
      .then((response) => {
        if (!response.ok) {
          throw new Error('Network response was not ok')
        }

        return response.blob()
      })
      .then((blob) => {
        const filename = `FxxOneInstaller_${id}.exe`
        saveAs(blob, filename)
      })
      .catch((error) => {
        console.error('Error al descargar el archivo:', error)
        setErrorMessage(t('REPORTING_GROUPS.TABLE.ERROR_DOWNLOAD_AGENT'))
      })
  }
  const openAgentSettings = (item: ReportingGroup) => {
    const productConfig = productConfigs?.find((pc) => pc._id === item.productConfig?._id)
    return useCreateForm(
      {
        title: t('AGENT_SETTINGS.FORM.EDIT_PRODUCT_SETTINGS_TITLE'),
        isFooterAtBottom: true,
      },
      <SettingsReportingGroupForm reportingGroup={item} productConfig={productConfig} />
    )
  }

  const tableActionsFeatures = [FEATURE_NAMES.REPORTING_GROUP_UPDATE, FEATURE_NAMES.REPORTING_GROUP_AGENT_SETTINGS_READ]
  const canSeeActions = tableActionsFeatures.some((feature) => checkFeature(feature))

  const columns: IColumn[] = [
    {
      key: 'name',
      name: t('REPORTING_GROUPS.TABLE.NAME'),
      fieldName: 'name',
      minWidth: 100,
      maxWidth: 300,
      isRowHeader: true,
      isPadded: true,
      columnActionsMode: ColumnActionsMode.disabled,
      onRender: (item: ReportingGroup) => {
        return (
          <CellWithFeatureLink feature={FEATURE_NAMES.REPORTING_GROUP_UPDATE} callback={() => onViewDetails(item)}>
            {item.name}
          </CellWithFeatureLink>
        )
      },
    },
    {
      key: 'enviroment',
      name: t('REPORTING_GROUPS.TABLE.ENVIRONMENT'),
      fieldName: 'enviroment',
      minWidth: 200,
      isRowHeader: true,
      isCollapsible: true,
      isPadded: true,
      columnActionsMode: ColumnActionsMode.disabled,
      onRender: (item: ReportingGroup) => {
        const matchingProductConfig = item.productConfig
        if (matchingProductConfig) {
          return <span>{matchingProductConfig.environment}</span>
        }
      },
    },
    {
      key: 'productConfig',
      name: t('REPORTING_GROUPS.TABLE.PRODUCT'),
      fieldName: 'productConfig',
      minWidth: 200,
      isRowHeader: true,
      isCollapsible: true,
      isPadded: true,
      columnActionsMode: ColumnActionsMode.disabled,
      onRender: (item: ReportingGroup) => {
        const matchingProductConfig = productConfigs?.find((pc) => pc._id === item.productConfig?._id)
        if (matchingProductConfig) {
          return <span>{matchingProductConfig.product.name}</span>
        }
      },
    },
    {
      key: 'fishingPattern',
      name: t('REPORTING_GROUPS.TABLE.FISHING_PATTERN'),
      fieldName: 'fishingPattern',
      minWidth: 100,
      maxWidth: 300,
      isRowHeader: true,
      isPadded: true,
      columnActionsMode: ColumnActionsMode.disabled,
      
    },
    {
      key: 'patchPolicyTargetId',
      name: t('REPORTING_GROUPS.TABLE.PATCH_POLICY_TARGET'),
      fieldName: 'patchPolicyTargetId',
      minWidth: 400,
      isRowHeader: true,
      isPadded: true,
      columnActionsMode: ColumnActionsMode.disabled,
      className: 'cell-ellipsis',
      onRender: (item: ReportingGroup) => {
        const patchPolicyTarget = patchPolicyTargets?.find(
          (patchPolicyTarget) => patchPolicyTarget._id === item.patchPolicyTargetId
        )
        if (!patchPolicyTarget) return '-'
        return (
          <CellWithFeatureLink
            feature={FEATURE_NAMES.PATCH_MANAGEMENT_READ}
            callback={() =>
              navigate(
                '/organization/' + organizationId + '/patch-management/patch-policy-targets/' + patchPolicyTarget._id
              )
            }
          >
            {patchPolicyTarget.name}
          </CellWithFeatureLink>
        )
      },
    },
    {
      key: 'policyReportingGroupId',
      name: t('REPORTING_GROUPS.TABLE.POLICY'),
      fieldName: 'policyReportingGroupId',
      minWidth: 200,
      isRowHeader: true,
      isCollapsible: true,
      isPadded: true,
      columnActionsMode: ColumnActionsMode.disabled,
      onRender: (item: ReportingGroup) => {
        if (!item.policyReportingGroup) return ''

        return policyName ?? ''
      },
    },
    ...(canSeeActions
      ? [
          {
            key: 'actions',
            name: t('REPORTING_GROUPS.TABLE.ACTION'),
            minWidth: isMobile ? 50 : 400,
            onRender: (item: ReportingGroup) => {
              return (
                <span className="d-flex d-flexRow">
                  <FeatureRender feature={FEATURE_NAMES.REPORTING_GROUP_UPDATE}>
                    <span style={isMobile ? { marginRight: 10 } : { marginRight: 20 }}>
                      <ViewDetailsActionButton
                        locale={i18n.language}
                        isMobile={isMobile}
                        onClick={() => onViewDetails(item)}
                      />
                    </span>
                  </FeatureRender>

                  <FeatureRender feature={FEATURE_NAMES.REPORTING_GROUP_AGENT_SETTINGS_READ}>
                    <span style={isMobile ? { marginRight: 10 } : { marginRight: 20 }}>
                      <SettingsButton
                        text={t('AGENT_SETTINGS.NAME')}
                        isMobile={isMobile}
                        onClick={openAgentSettings(item)}
                      />
                    </span>
                  </FeatureRender>
                  {isFxxOne && <DownloadAgentButton isMobile={isMobile} onClick={() => downloadAgent(item._id)} />}
                </span>
              )
            },
            isPadded: true,
            columnActionsMode: ColumnActionsMode.disabled,
          },
        ]
      : []),
  ]

  if (!reportingGroups.length) {
    return (
      <div style={{ marginTop: 100 }}>
        <EmptyStatus
          message={t('REPORTING_GROUPS.TABLE.EMPTY_MESSAGE')}
          img={<Icon iconName="ReportLibrary" style={{ fontSize: 68 }} />}
          {...(checkFeature(FEATURE_NAMES.REPORTING_GROUP_CREATE) && {
            buttonProps: {
              text: t('general:BUTTON.CREATE'),
              iconProps: { iconName: 'Add' },
              onClick: onCreate,
            },
          })}
        />
      </div>
    )
  }

  return (
    <>
      <Stack horizontal={!isMobile} horizontalAlign={'end'} tokens={{ padding: '20px 0 0 0' }} className="mb-sm-2">
        <Stack.Item>
          <div
            className={`d-flex d-flexRow d-flexAlignItemsCenter ${isMobile ? 'd-flexJustifyBetween' : ''} `}
            style={isMobile ? { marginTop: 15 } : {}}
          >
            {checkFeature(FEATURE_NAMES.REPORTING_GROUP_CREATE) && (
              <CreateButton locale={i18n.language} onClick={onCreate} />
            )}
          </div>
        </Stack.Item>
      </Stack>

      <Table
        tableId="reporting-groups"
        isEmpty={reportingGroups.length === 0}
        emptyMessage={t('general:EMPTY_FILTER')}
        tableProps={{
          columns,
          items: reportingGroups,
          compact: false,
          selectionMode: SelectionMode.none,
          layoutMode: DetailsListLayoutMode.justified,
          isHeaderVisible: true,
        }}
      />
    </>
  )
}
