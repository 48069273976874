import { useMutation, useQueryClient } from '@tanstack/react-query'
import graphClient from 'app/query-client/graphClient'
import { gql } from 'graphql-request'

interface UpdateUserRoleRequest {
  name: string
}

interface Response {
  updateUserRole: {
    _id: string
  }
}

const mutation = gql`
  mutation Request($id: ID!, $input: UpdateUserRoleGraphqlRequest!, $organizationId: ID!) {
    updateUserRole(id: $id, input: $input, organizationId: $organizationId) {
      _id
    }
  }
`

const updateUserRole = async (id: string, input: UpdateUserRoleRequest, organizationId: string): Promise<string> => {
  const response: Response = await graphClient.request(mutation, {
    id,
    input,
    organizationId,
  })
  return response.updateUserRole._id
}

export const useUpdateUserRole = () => {
  const queryClient = useQueryClient()

  return useMutation(
    ['updateUserRole'],
    ({ id, input, organizationId }: { id: string; input: UpdateUserRoleRequest; organizationId: string }) =>
      updateUserRole(id, input, organizationId),
    {
      onSuccess: (id) => {
        queryClient.invalidateQueries(['getUserRoleById', id])
      },
    }
  )
}
