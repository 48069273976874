import { ActionButton, FontIcon, IButtonStyles, IIconProps } from '@fluentui/react'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

interface Props {
  isMobile: boolean
  onClick?: () => void
  isExpanded?: boolean
  showChevron?: boolean
}

const actionButtonStylesDesktop: IButtonStyles = {
  root: {
    height: 22,
    padding: 0,
  },
  icon: {
    margin: '0 0 4px 0',
  },
}

const actionButtonStylesMobile: IButtonStyles = {
  root: {
    height: 22,
    padding: 0,
  },
}

const ReportsButton = ({ isMobile, onClick, isExpanded, showChevron }: Props) => {
  const { t } = useTranslation('general')
  const [open, setOpen] = useState(false)
  const viewDetailIcon: IIconProps = { iconName: 'ReportDocument' }
  const text = isMobile ? '' : t('BUTTON.REPORTS')
  const style = isMobile ? actionButtonStylesMobile : actionButtonStylesDesktop
  const className = isMobile ? 'd-flex d-flexRow d-flexJustifyCenter' : 'd-flex d-flexRow'

  useEffect(() => {
    setOpen((prev) => !prev)
  }, [isExpanded])

  return (
    <div className={className}>
      <ActionButton onClick={onClick} styles={style} iconProps={viewDetailIcon} allowDisabledFocus>
        {text}
      </ActionButton>
      <div className="d-flex d-center d-flexNone" style={{ marginLeft: 5 }}>
        <FontIcon
          iconName={showChevron ? 'ChevronUp' : ''}
          style={{ transform: open ? 'rotate(0deg)' : 'rotate(-180deg)', transition: 'all 0.5s ease' }}
        />
      </div>
    </div>
  )
}

export default ReportsButton
