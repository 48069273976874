import { ConditionType } from 'app/hooks/flows/useGetConditions'
import { ConditionData, FlowDetail } from 'app/hooks/flows/useGetFlow'
import { EnabledMicroserviceView } from 'app/hooks/microservices/useGetEnabledMicroservices'
import {
  Connection,
  Edge,
  EdgeChange,
  MarkerType,
  NodeChange,
  OnConnect,
  OnEdgesChange,
  OnNodesChange,
  addEdge,
  applyEdgeChanges,
  applyNodeChanges,
} from 'reactflow'
import { create } from 'zustand'
import { CustomNode } from '../nodes'
import { getLayoutedElements } from './Layouting'
import { initialEdges, initialNodes } from './empty'

export const MAX_CONDITIONS = 4

export type RFState = {
  editing: boolean
  nodes: CustomNode[]
  edges: Edge[]
  onNodesChange: OnNodesChange
  onEdgesChange: OnEdgesChange
  onConnect: OnConnect

  restore: (nodes: CustomNode[], edges: Edge[]) => void

  setEditing: (editing: boolean) => void

  setFlow: (flow: FlowDetail) => void
  getFlow: () => { conditions: ConditionData[]; trigger: string; microserviceId: string | undefined }

  changeToCondition: (nodeId: string, condition: ConditionType) => CustomNode | undefined
  updateConditionNode: (nodeId: string, conditionData: ConditionData) => void
  addCondition: (nodeId: string, condition: ConditionType) => CustomNode
  removeCondition: (nodeId: string) => void

  addAction: (nodeId: string, microservice: EnabledMicroserviceView) => void
  replaceAction: (nodeId: string, microservice: EnabledMicroserviceView) => void
}

const useStore = create<RFState>((set, get) => ({
  editing: false,
  nodes: initialNodes,
  edges: initialEdges,
  onNodesChange: (changes: NodeChange[]) => {
    set({
      nodes: applyNodeChanges(changes, get().nodes) as CustomNode[],
    })
  },
  onEdgesChange: (changes: EdgeChange[]) => {
    set({
      edges: applyEdgeChanges(changes, get().edges),
    })
  },
  onConnect: (connection: Connection) => {
    set({
      edges: addEdge(connection, get().edges),
    })
  },

  restore: (nodes: CustomNode[], edges: Edge[]) => {
    set({ nodes, edges })
  },

  setEditing: (editing: boolean) => {
    set({ editing })
  },

  setFlow: (flow: FlowDetail) => {
    if (!flow.conditions?.length) {
      set({
        nodes: initialNodes,
        edges: initialEdges,
        editing: false,
      })
      return
    }

    const nodes: CustomNode[] = flow.conditions.map((condition, index) => {
      return generateNode({
        id: index.toString(),
        type: 'condition',
        data: {
          conditionData: condition,
          isValid: true,
          leaf: false,
        },
        position: { x: 0, y: 0 },
      })
    })

    if (flow.microservice) {
      nodes.push(
        generateNode({
          id: 'action',
          type: 'action',
          data: {
            microservice: flow.microservice!,
          },
          position: { x: 0, y: 0 },
        })
      )
    }

    const edges: Edge[] = nodes
      .slice(1)
      .map((node, index) => {
        const prev = nodes[index]
        return {
          id: `${prev.id}-${node.id}`,
          source: prev.id,
          target: node.id,
          markerEnd: { type: MarkerType.Arrow },
          type: 'smoothstep',
        }
      })
      .filter((e) => e !== undefined)

    const layouted = getLayoutedElements(nodes, edges, { direction: 'TB' })

    set({
      nodes: layouted.nodes as CustomNode[],
      edges: layouted.edges,
      editing: false,
    })
  },
  getFlow: () => {
    const conditionNodes = get().nodes.filter((node) => node.type === 'condition')

    return {
      conditions: conditionNodes.map((node) => {
        return {
          conditionTypeId: node.data.conditionData.conditionTypeId,
          metric: node.data.conditionData.metric,
          operator: node.data.conditionData.operator,
          compareTo: node.data.conditionData.compareTo,
          checkEvery: node.data.conditionData.checkEvery,
          period: node.data.conditionData.period,
          conditionType: node.data.conditionData.conditionType,
        }
      }),
      trigger: conditionNodes
        .map((node, index) => {
          return `{${index}}`
        })
        .join(' AND '),
      microserviceId: get().nodes.find((node) => node.type === 'action')?.data.microservice.id,
    }
  },

  changeToCondition: (nodeId: string, condition: ConditionType) => {
    const currentNodes = get().nodes
    const node = currentNodes.find((node) => node.id === nodeId)

    if (!node) {
      return
    }

    const newNode: CustomNode = {
      ...node,
      type: 'condition',
      data: {
        conditionData: {
          metric: condition.type,
          conditionType: condition,
          conditionTypeId: condition.id,
        },
        leaf: true,
        isValid: false,
      },
    }

    set({
      nodes: get().nodes.map((node) => {
        if (node.id !== nodeId) {
          return node
        }

        return newNode
      }),
    })

    return newNode
  },
  updateConditionNode: (nodeId: string, conditionData: ConditionData) => {
    set({
      nodes: get().nodes.map((node) => {
        if (node.id === nodeId) {
          if (!node.type || !['condition'].includes(node.type)) {
            return node
          }
          return {
            ...node,
            data: {
              ...node.data,
              conditionData: { ...conditionData },
              isValid: true,
            },
          }
        }
        return node
      }),
    })
  },
  addCondition: (nodeId: string, condition: ConditionType) => {
    const isAlreadyConnected = get().edges.find((edge) => edge.source === nodeId)
    const newNode: CustomNode = generateNode({
      id: `${get().nodes.length + 1}`,
      type: 'condition',
      data: {
        conditionData: {
          metric: condition.type,
          conditionType: condition,
          conditionTypeId: condition.id,
        },
        leaf: !isAlreadyConnected,
        isValid: false,
      },
      position: { x: 0, y: 0 },
    })

    const layouted = getLayoutedElements(
      [
        ...get().nodes.map((node) => {
          if (node.id === nodeId) {
            return {
              ...node,
              data: {
                ...node.data,
                leaf: false,
              },
            }
          }
          return node
        }),
        newNode,
      ],
      [
        ...get().edges.map((edge) => {
          if (isAlreadyConnected && edge.source === nodeId) {
            return {
              ...edge,
              target: newNode.id,
            }
          }

          return edge
        }),
        isAlreadyConnected
          ? {
              id: `${get().edges.length + 1}`,
              source: newNode.id,
              target: isAlreadyConnected.target,
              markerEnd: { type: MarkerType.Arrow },
              type: 'smoothstep',
            }
          : {
              id: `${get().edges.length + 1}`,
              source: nodeId,
              target: newNode.id,
              markerEnd: { type: MarkerType.Arrow },
              type: 'smoothstep',
            },
      ],
      { direction: 'TB' }
    )

    set({
      nodes: layouted.nodes as CustomNode[],
      edges: layouted.edges,
    })

    return newNode
  },
  removeCondition: (nodeId: string) => {
    const node = get().nodes.find((node) => node.id === nodeId)
    if (!node) {
      return
    }

    const isLast = get().nodes.filter((n) => n.type === 'condition').length === 1

    if (isLast) {
      set({
        nodes: initialNodes,
        edges: initialEdges,
      })
      return
    }

    const sourceNode = get().edges.find((edge) => edge.target === nodeId)
    const targetNode = get().edges.find((edge) => edge.source === nodeId)

    const nodes = get().nodes.filter((node) => node.id !== nodeId)
    let edges = get().edges
    if (sourceNode && targetNode) {
      edges = [
        ...edges.filter((edge) => edge.id !== sourceNode.id && edge.id !== targetNode.id),
        {
          id: `${get().edges.length + 1}`,
          source: sourceNode.source,
          target: targetNode.target,
          markerEnd: { type: MarkerType.Arrow },
          type: 'smoothstep',
        },
      ]
    } else {
      edges = edges.filter((edge) => edge.source !== nodeId && edge.target !== nodeId)
    }

    const layouted = getLayoutedElements(nodes, edges, { direction: 'TB' })

    set({
      nodes: layouted.nodes as CustomNode[],
      edges: layouted.edges,
    })
  },

  replaceAction: (nodeId: string, microservice: EnabledMicroserviceView) => {
    const node = get().nodes.find((node) => node.id === nodeId)
    if (!node) {
      return
    }

    const layouted = getLayoutedElements(
      get().nodes.map((n) => {
        if (n.id === nodeId) {
          return {
            ...n,
            data: {
              microservice: microservice,
            },
          }
        }
        return n
      }),
      get().edges,
      { direction: 'TB' }
    )

    set({
      nodes: layouted.nodes as CustomNode[],
      edges: layouted.edges,
    })
  },
  addAction: (nodeId: string, microservice: EnabledMicroserviceView) => {
    const newNode: CustomNode = generateNode({
      id: `${get().nodes.length + 1}`,
      type: 'action',
      data: {
        microservice: microservice,
      },
      position: { x: 0, y: 0 },
    })

    const layouted = getLayoutedElements(
      [
        ...get().nodes.map((node) => {
          if (node.id === nodeId) {
            return {
              ...node,
              data: {
                ...node.data,
                leaf: false,
              },
            }
          }
          return node
        }),
        newNode,
      ],
      [
        ...get().edges,
        {
          id: `${get().edges.length + 1}`,
          source: nodeId,
          target: newNode.id,
          markerEnd: { type: MarkerType.Arrow },
          type: 'smoothstep',
        },
      ],
      { direction: 'TB' }
    )

    set({
      nodes: layouted.nodes as CustomNode[],
      edges: layouted.edges,
    })
  },
}))

export default useStore

const generateNode = (node: CustomNode): CustomNode => {
  return {
    ...node,
    position: { x: 0, y: 0 },
    width: 250,
    height: 85,
    style: {
      width: 250,
      height: 85,
    },
  }
}
