import { PatchManagementDashboardData } from '../.././summary/MockData'
import Highcharts from 'highcharts/highstock'
import { DashboardChartCard } from '../../../../components/Dashboard/DashboardChartCard/DashboardChartCard';
import { BarChart } from '../../../../components/Charts/BarChart/BarChart'
import { useTranslation } from 'react-i18next'

interface Props {
  items: PatchManagementDashboardData['reportingGroupsInRisk']
}

export const ReportingGroupsInRiskChart = (props: Props) => {
  const { t } = useTranslation('patch_management');

  const chartSeries: Highcharts.SeriesBarOptions[] = [
    {
      type: 'bar',
      name: t("DASHBOARD.REPORTING_GROUPS.IN_RISK_CHART_LEGEND"),
      data: props.items.map((item) => item.value),
    }
  ];

  const categories = props.items.map((item) => item.reportingGroup);

  return (
    <DashboardChartCard
      title={t("DASHBOARD.REPORTING_GROUPS.IN_RISK")}
      tooltip={t("DASHBOARD.REPORTING_GROUPS.IN_RISK_TOOLTIP")}
      message={t("DASHBOARD.REPORTING_GROUPS.IN_RISK_MESSAGE")}
    >
      <BarChart
        series={chartSeries}
        categories={categories}
      />
    </DashboardChartCard>
  )
}
