import { PRODUCT_ENV } from 'app/config/Consts'
import { ProductEnvTypes } from 'app/config/productEnv.enum'
import { User } from 'app/query-client/types'
import { i18n } from 'i18next'
import { useEffect, useState } from 'react'
import { TFunction } from 'react-i18next'
import { useSearchParams } from 'react-router-dom'
import { IPaginatedTableColumn } from '@flexxibleit/flexxible-ui/dist/lib/components/paginated-table/paginatedTable.types'

type UseUsersBuildTableProps = {
  t: TFunction
  i18n: i18n
}

export const useUsersByRoleBuildTable = ({ t, i18n }: UseUsersBuildTableProps) => {
  const [columns, setColumns] = useState<IPaginatedTableColumn[]>([])
  const [searchParams] = useSearchParams()

  useEffect(() => {
    setColumns([
      {
        key: 'name',
        name: t('USERS.TABLE.NAME'),
        fieldName: 'name',
        minWidth: 25,
        flexGrow: 1,
        onRender: (item: User) => {
          return `${item.name} ${item.surname}`
        },
      },
      {
        key: 'email',
        name: t('USERS.TABLE.EMAIL'),
        fieldName: 'email',
        minWidth: 25,
        flexGrow: 1,
      },
      {
        key: 'department',
        name: t('USERS.TABLE.DEPARTMENT'),
        fieldName: 'department',
        minWidth: 25,
        flexGrow: 1,
      },
    ])
  }, [searchParams])

  const headerRenderPreviewWorkspaces = () => {
    return PRODUCT_ENV !== ProductEnvTypes.FXXONE ? (
      <div>
        <span>{t('USERS.TABLE.WORKSPACE_PERMISSION')}</span>
        <span id="titleTagPage" className={`content-header__title-tag`}>
          PREVIEW
        </span>
      </div>
    ) : (
      <span>{t('USERS.TABLE.WORKSPACE_PERMISSION')}</span>
    )
  }

  return {
    columns,
  }
}
