import { FormActionsContainer, LoadingSpinner } from '@flexxibleit/flexxible-ui'
import { zodResolver } from '@hookform/resolvers/zod'
import RowColLayout from 'app/components/Layouts/RowColLayout'
import FormActions from 'app/components/forms/FormActions'
import { useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import * as z from 'zod'
import TextField from '../../../../components/forms/TextField'

interface Props {
  microserviceId: string
  initialData: FormData
  onSubmit: (data: FormData) => Promise<void>
}

interface FormData {
  efficiency: number
}

const schema: z.ZodType<FormData> = z.object({
  efficiency: z
    .custom<number>()
    .refine((value) => value ?? false, 'required')
    .refine((value) => Number(value) >= 5, 'min')
    .transform((value) => Number(value)),
})

export const EnabledMicroserviceSettingsForm = ({ initialData, onSubmit }: Props) => {
  const { t } = useTranslation('microservices')
  const [isLoading, setIsLoading] = useState(false)

  const methods = useForm<FormData>({
    resolver: zodResolver(schema),
    defaultValues: initialData,
  })

  const handleSubmit = async (data: FormData) => {
    setIsLoading(true)
    try {
      await onSubmit(data)
    } finally {
      setIsLoading(false)
    }
  }

  if (isLoading) {
    return <LoadingSpinner />
  }

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(handleSubmit)}>
        <RowColLayout rowProps={{ classNames: 'mt-2' }}>
          <TextField
            name="efficiency"
            required={false}
            translator={t}
            textFieldProps={{
              label: t('DETAILS.SETTINGS.EFFICIENCY_LABEL'),
              placeholder: t('DETAILS.SETTINGS.EFFICIENCY_PLACEHOLDER'),
              type: 'number',
              min: 5,
            }}
          />
        </RowColLayout>
        <FormActionsContainer>
          <FormActions
            submitProps={{
              text: t('general:BUTTON.SAVE'),
              iconProps: { iconName: 'Save' },
              disabled: !methods.formState.isDirty,
            }}
          />
        </FormActionsContainer>
      </form>
    </FormProvider>
  )
}
