import { getPaginatedQueryParamsBlock, pageInfoBlock, paginatedVarsBlock } from 'app/config/pagination'
import { gql } from 'graphql-request'

export const usePaginatedReportingGroupRolesQuery = () => {
  const queryName = 'getPaginatedReportingGroupRoles'
  const query = gql`
query Request(
  $organizationId: ID!
  $reportingGroupId: ID!
  ${getPaginatedQueryParamsBlock('UserRoleFilter', 'UserRoleSorting')}
) {
  getPaginatedReportingGroupRoles(
    organizationId: $organizationId
    reportingGroupId: $reportingGroupId
    ${paginatedVarsBlock}
  ) {
    data {
      _id
      name
      created_at
      updated_at
      created_by {
        name
        surname
        email
      }
      updated_by {
        name
        surname
        email
      }
      assignedUsers
      assignedOrganizations
    }
    ${pageInfoBlock}
  }
}
`
  return {
    query,
    queryName,
  }
}
