import { AllReportingGroupsMustHaveTargetCard } from './AllReportingGroupsMustHaveTargetCard'
import { SummaryRule } from '../../usePatchManagementRuleEngine'
import { PatchSummary } from '../../../entities/PatchSummary'
import { PatchSummaryRuleSentences, SummarySectionType, SummaryType } from '../../types'

export const AllReportingGroupsMustHaveTargetRule: SummaryRule = {
  rule: PatchSummaryRuleSentences.ALL_REPORTING_GROUPS_MUST_HAVE_TARGET,
  alertSummaryItem: {
    title: '',
    message: '',
    card: <AllReportingGroupsMustHaveTargetCard />,
    type: SummaryType.Alert,
    section: SummarySectionType.Targets,
  },
  infoSummaryItem: {
    title: '',
    message: '',
    type: SummaryType.Information,
    section: SummarySectionType.Targets,
    card: <AllReportingGroupsMustHaveTargetCard isInfo />,
  },
  passRule: (patchSummary: PatchSummary) => {
    return patchSummary.reportingGroupsWithoutPatchPolicyTarget === 0
  },
}
