import { gql } from 'graphql-request'
import { PaginationArgs, PaginationResponse, WindowsPatchFilter, WindowsPatches } from '../../query-client/types'
import graphClient from '../../query-client/graphClient'
import { useQuery } from '@tanstack/react-query'

const getAvailableWindowsPatchesQuery = gql`
  query (
    $filter: GetAvailableWindowsPatchesFilterInput!
    $pagination: GetAvailableWindowsPatchesPaginationInput!
  ) {
    getAvailableWindowsPatches(filter: $filter, pagination: $pagination) {
      data {
        id
        kb
        title
        category
        product
        infoUrl
        severity
        state
        creationDate
      }
      pageInfo {
        hasNextPage
        hasPreviousPage
        totalPages
        currentPage
        total
      }
    }
  }
`

function useGetAvailableWindowsPatches(
  filter: WindowsPatchFilter,
  pagination?: PaginationArgs
): Promise<PaginationResponse<WindowsPatches>> {
  return graphClient
    .request(getAvailableWindowsPatchesQuery, {
      filter,
      pagination,
    })
    .then((response) => {
      return {
        results: response.getAvailableWindowsPatches.data,
        pageInfo: response.getAvailableWindowsPatches.pageInfo,
      }
    })
}

export default function (filter: WindowsPatchFilter, pagination?: PaginationArgs) {
  return useQuery(
    ['getAvailableWindowsPatches', pagination, filter],
    () => useGetAvailableWindowsPatches(filter, pagination),
    {
      refetchOnWindowFocus: false,
      keepPreviousData: true,
    }
  )
}
