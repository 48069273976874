import { Col, Row } from '@flexxibleit/flexxible-ui'
import { DisplayField } from 'app/components/DisplayField'
import IconLanguage from 'app/components/IconLanguage/IconLanguage'
import { FlowDetail } from 'app/hooks/flows/useGetFlow'
import { getTranslation } from 'app/models/microservices'
import { useTranslation } from 'react-i18next'

interface Props {
  flow: FlowDetail
}
export const FlowNotificationDisplay = ({ flow }: Props) => {
  const { t, i18n } = useTranslation('flows')

  const initText = getTranslation(flow.initText ?? [], i18n.language)
  const okText = getTranslation(flow.okText ?? [], i18n.language)
  const koText = getTranslation(flow.koText ?? [], i18n.language)

  if (!initText.text && !okText.text && !koText.text) {
    return (
      <>
        <Row>
          <Col className="col-xs-12 col-lg-12">
            <DisplayField label={t('NOTIFICATION.LABEL')}>{t('general:STATUS.INACTIVE')}</DisplayField>
          </Col>
        </Row>
      </>
    )
  }

  return (
    <>
      <Row>
        <Col className="col-xs-12 col-lg-12">
          <DisplayField label={t('NOTIFICATION.INIT')}>
            {initText.text} {initText.translatedByIA && <IconLanguage tam="16" />}
          </DisplayField>
        </Col>
      </Row>
      <Row>
        <Col className="col-xs-12 col-lg-12">
          <DisplayField label={t('NOTIFICATION.OK')}>
            {okText.text} {okText.translatedByIA && <IconLanguage tam="16" />}
          </DisplayField>
        </Col>
      </Row>
      <Row>
        <Col className="col-xs-12 col-lg-12">
          <DisplayField label={t('NOTIFICATION.KO')}>
            {koText.text} {koText.translatedByIA && <IconLanguage tam="16" />}
          </DisplayField>
        </Col>
      </Row>
    </>
  )
}
