import {
  ControlledTextField,
  DeleteActionButton,
  Dialog,
  ErrorStatus,
  FormActionsContainer,
  RowColLayout,
  SuccessStatus,
} from '@flexxibleit/flexxible-ui'
import { useBoolean } from '@fluentui/react-hooks'
import { zodResolver } from '@hookform/resolvers/zod'
import FormActions from 'app/components/forms/FormActions'
import { useFormSidebarContext } from 'app/components/forms/FormSidebar/state/FormSidebarState'
import useDeleteTenantPolicy from 'app/hooks/policies/useDeletePolicy'
import { FormProvider, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import * as z from 'zod'
import { UserRole } from 'app/query-client/types'
import useCreateUserRole from 'app/hooks/roles/useCreateUserRole'
import { useUpdateUserRole } from 'app/hooks/roles/useUpdateUserRole'
import useDeleteUserRole from 'app/hooks/roles/useDeleteUserRole'

export interface Props {
  organizationId: string
  userRole?: UserRole
}

interface FormValues {
  name: string
}

const schema: z.ZodType<FormValues> = z.object({
  name: z.string({ required_error: 'required' }).min(1, { message: 'required' }),
})

export const RolInfoForm = (props: Props) => {
  const { t } = useTranslation('organization_details')
  const createUserRole = useCreateUserRole(props.organizationId)
  const updateUserRole = useUpdateUserRole()
  const deleteUserRole = useDeleteUserRole()
  const [hideDeleteDialog, { toggle: toggleHideDeleteDialog }] = useBoolean(true)
  const { closeSidebar } = useFormSidebarContext()
  const isEdit = Boolean(props?.userRole?._id)
  const navigate = useNavigate()

  const methods = useForm<FormValues>({
    resolver: zodResolver(schema),
    defaultValues: props.userRole ?? {
      name: '',
    },
  })
  const onSubmit = (values: FormValues) => {
    if (isEdit) {
      updateUserRole.mutate({
        id: props?.userRole?._id!,
        input: {
          name: values.name,
        },
        organizationId: props?.organizationId,
      })
    } else {
      createUserRole.mutateAsync({...values }).then((newRole)=> {
        closeSidebar()
        navigate(`/organization/${props.organizationId}/roles/${newRole?._id}`)
      })
    }
  }

  const onDelete = () => {
    toggleHideDeleteDialog()
  }

  const renderDeleteConfirmation = (): JSX.Element => {
    return (
      <Dialog
        title={t('ROLES.DELETE_CONFIRMATION.TITLE')}
        description={t('ROLES.DELETE_CONFIRMATION.MESSAGE')}
        actionButton={t('ROLES.DELETE_CONFIRMATION.BUTTON_ACCEPT')}
        dismissButton={t('ROLES.DELETE_CONFIRMATION.BUTTON_CANCEL')}
        hidden={hideDeleteDialog}
        onDismiss={toggleHideDeleteDialog}
        callback={() => {
          toggleHideDeleteDialog()
          handleDeleteUserRole()
        }}
      />
    )
  }
  const handleDeleteUserRole = () => {
    if (props?.userRole?._id && props.organizationId) {
      deleteUserRole.mutateAsync({ userRoleId: props.userRole._id, organizationId: props.organizationId }).then(() => {
        closeSidebar()
        navigate(-1)
      })
    }
  }

  const getDeleteErrorMessage = (error: any) => {
    if (error?.response?.errors?.[0]?.message?.includes('This role has users assigned.')) {
        return t('ROLES.FORM.DELETE_HAS_USERS_ERROR')
    } 
    return t('ROLES.FORM.DELETE_ERROR')
  }

  if (createUserRole.isSuccess) {
    return (
      <div className="d-flex h-100">
        <SuccessStatus message={t('ROLES.FORM.ADD_SUCCESS_MESSAGE')} />
      </div>
    )
  }
  if (createUserRole.isError) {
    return (
      <div className="d-flex h-100">
        <ErrorStatus message={t('ROLES.FORM.ADD_ERROR_MESSAGE')} />
      </div>
    )
  }

  if (updateUserRole.isSuccess) {
    return (
      <div className="d-flex h-100">
        <SuccessStatus message={t('ROLES.FORM.EDIT_SUCCESS_MESSAGE')} />
      </div>
    )
  }
  if (updateUserRole.isError) {
    return (
      <div className="d-flex h-100">
        <ErrorStatus message={t('ROLES.FORM.EDIT_ERROR_MESSAGE')} />
      </div>
    )
  }

  if (deleteUserRole.isError) {
    return (
      <div className="d-flex h-100">
        <ErrorStatus message={getDeleteErrorMessage(deleteUserRole?.error)} />
      </div>
    )
  }

  if (deleteUserRole.isSuccess) {
    return (
      <div className="d-flex h-100">
        <SuccessStatus message={t('ROLES.FORM.DELETE_SUCCESS_MESSAGE')} />
      </div>
    )
  }

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <RowColLayout rowProps={{ classNames: 'mt-2' }}>
          <ControlledTextField
            name="name"
            required={true}
            translator={t}
            textFieldProps={{
              label: t('ROLES.FORM.NAME'),
              placeholder: t('ROLES.FORM.NAME_PLACEHOLDER'),
            }}
          />
        </RowColLayout>

        <ActionButtons isEdit={isEdit} onDelete={onDelete} />
      </form>
      {renderDeleteConfirmation()}
    </FormProvider>
  )
}

const ActionButtons = ({ isEdit, onDelete }: { isEdit: boolean; onDelete: () => void }) => {
  const { t, i18n } = useTranslation()

  if (isEdit) {
    return (
      <FormActionsContainer isEdit>
        <DeleteActionButton locale={i18n.language} onClick={onDelete} />
        <FormActions
          submitProps={{
            text: t('general:BUTTON.SAVE'),
            iconProps: { iconName: 'Save' },
          }}
        />
      </FormActionsContainer>
    )
  }

  return (
    <FormActionsContainer>
      <FormActions
        submitProps={{
          text: t('general:BUTTON.CREATE'),
          iconProps: { iconName: 'Add' },
        }}
      />
    </FormActionsContainer>
  )
}
