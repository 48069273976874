// @ts-nocheck
import { MockChartConfiguration } from './chart-configutations/MockChartConfiguration';
import HighchartsReact from 'highcharts-react-official'
import Highcharts from 'highcharts/highstock'

interface Props {
  height: number,
  width: number,
  size: number,
  series:  {
    name: string,
    colorByPoint?: boolean,
    data: any[]
  }[],
  onClick?: (filterValue: any) => void
}

export const PieChartComponent = ({height, width, size, series, onClick}: Props) => {
  const mouseHover = (series) => {
    let activeCounter = 0
    series.data.forEach((point) => {
      if (point.active) { activeCounter += 1 }
    })

    series.data.forEach((point) => {
      if(!activeCounter) {
        point.update({
          opacity: 1
        })
        return
      }

      if (point.active) {
        point.update({
          opacity: 1
        })
      }
      if (!point.active) {
        point.update({
          opacity: 0.4,
        });
      }
    });
  }


  return (
    <div style={{ height: `${height}px`, width: `${width}px`, display: 'flex', justifyContent: 'center' }}>
      <HighchartsReact
        highcharts={Highcharts}
        allowChartUpdate={true}
        options={{
          ...MockChartConfiguration,
          chart: {
            width: width,
            plotBackgroundColor: null,
            plotBorderWidth: null,
            plotShadow: false,
            type: 'pie',
          },
          title: {
            text: '',
          },
          tooltip: {
            valueSuffix: '',
          },
          plotOptions: {
            pie: {
              size: size
            },
            series: {
              cursor: 'pointer',
              point: {
                events: {
                  mouseOver() {
                    const series = this.series
                    mouseHover(series);
                  },
                  mouseOut() {
                    const series = this.series
                    mouseHover(series);
                  },
                  click() {
                    if (onClick) {
                      const chart = this;
                      onClick({
                        filter: chart.options.category,
                        selected: !chart.options.active
                      })
                    }
                  }
                }
              },
              states: {
                hover: {
                  opacity: 1,
                },
              },
              slicedOffset:0,
            },
          },
          series: series,
        }}
      />
    </div>
  )
}

