import { FormActionsContainer, LoadingSpinner } from '@flexxibleit/flexxible-ui'
import { zodResolver } from '@hookform/resolvers/zod'
import * as z from 'zod'
import { FormProvider, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { IDropdownOption, Position, ResponsiveMode, TextField } from '@fluentui/react'
import ControlledSpinButton from 'app/components/forms/SpinButton/SpinButton.component'
import { BASELINE_MAX_VALUE, BASELINE_MIN_VALUE, BASELINE_STEP_VALUE, PRODUCT_ENV } from 'app/config/Consts'
import useGetProductTypes from 'app/hooks/product/useGetProductTypes'
import useCreateProductConfig from 'app/hooks/productConfig/useCreateProductConfig'
import useDropdownOptions from 'app/components/hooks/useDropdownOptions'
import RowColLayout from 'app/components/Layouts/RowColLayout'
import Dropdown from 'app/components/forms/Dropdown'
import FormActions from 'app/components/forms/FormActions'
import SuccessStatus from 'app/components/status/success-status'
import ErrorStatus from 'app/components/status/error-status'
import { ElegibleProduct, ProductConfig, Region } from 'app/query-client/types'
import useEditProductConfig from 'app/hooks/productConfig/useEditProductConfig'
import { useEffect, useState } from 'react'
import ControlledToggleButton from 'app/components/forms/ToggleButton/ToggleButton.component'
import ControlledTextField from 'app/components/forms/TextField'
import Copy from 'app/components/Copy/Copy.component'
import { ControlledTextArrayField } from '../../../components/forms/TextArrayField/TextArrayField'
import { dateFormatter } from 'app/services/date-formatter'
import _ from 'lodash'
import { ProductEnvTypes } from 'app/config/productEnv.enum'
import { FEATURE_NAMES } from '../../../permissions/FeatureName.enum'
import { useFeatureRender } from '../../../permissions/useFeatureRender'
import { FeatureRender } from '../../../permissions/FeatureRender'

interface Props {
  organizationId?: string
  product?: ProductConfig
  isAdmin: boolean
  elegibleProducts?: ElegibleProduct[]
}

export interface ProductFormType {
  businessId: string
  productId?: string
  environment: string
  baseline: number
  active: boolean
  serviceNowRelatedEnvironment?: string[]
  region?: string
}

const schema: z.ZodType<Partial<ProductFormType>> = z.object({
  productId: z.string({
    required_error: 'required',
  }),
  baseline: z.number().optional(),
  environment: z.string({
    required_error: 'required',
  }),
  active: z.boolean().optional(),
  serviceNowRelatedEnvironment: z.array(z.string()).optional(),
  region: z.string().optional(),
})

const ProductForm = ({ isAdmin, product, organizationId, elegibleProducts }: Props) => {
  const { t } = useTranslation('organization_details')
  const { data, isFetching: productsLoading } = useGetProductTypes()

  const createProduct = useCreateProductConfig()
  const editProduct = useEditProductConfig(product?._id || '')

  const [isSuccess, setIsSuccess] = useState(false)
  const [isError, setIsError] = useState(false)
  const [isLoading, setIsLoading] = useState(true)
  const [regions, setRegions] = useState<IDropdownOption[]>([])
  const [showRegion, setShowRegion] = useState(false)
  const { checkFeature } = useFeatureRender()

  const [spinButtonProps, setSpinButtonProps] = useState({
    disabled: !checkFeature(FEATURE_NAMES.ENVIRONMENT_BASELINE_UPDATE) || !!product?.product?.fixedBaseline,
    value: product?.product?.fixedBaseline ?? 1,
  })

  let dropdownOptions: IDropdownOption[] = useDropdownOptions(data, '_id', 'name')

  useEffect(() => {
    setIsSuccess(createProduct.isSuccess || editProduct.isSuccess)
    setIsError(createProduct.isError || editProduct.isError)
    setIsLoading(createProduct.isLoading || editProduct.isLoading || productsLoading)
  }, [createProduct, editProduct, productsLoading])

  const defaultValues = () => {
    return {
      businessId: organizationId,
      productId: product?.product._id,
      environment: product?.environment,
      baseline: product?.baseline || 1,
      active: product?.active,
      serviceNowRelatedEnvironment: product?.serviceNowRelatedEnvironment || [],
      region: product?.region || undefined,
    }
  }

  const methods = useForm<ProductFormType>({
    resolver: zodResolver(schema),
    defaultValues: { ...defaultValues() },
  })

  const { setValue } = methods

  const productWatch = methods.watch('productId')

  useEffect(() => {
    setShowRegion(false)
    setRegions([])
    const selectedProduct = data.filter((i) => i._id === productWatch)[0]

    if (selectedProduct?.elegible) {
      setShowRegion(true)
      if (product) {
        const regionOptions: IDropdownOption[] = []
        selectedProduct.regions.map((region: Region) => {
          regionOptions.push({ key: region.deploymentCode, text: region.name })
        })
        setRegions(regionOptions)
      } else {
        if (productWatch) {
          const productId = selectedProduct._id
          const availableDeploymentCodes =
            elegibleProducts?.filter((product) => product.elegibleProduct._id === productId)?.[0]?.deploymentRegions ||
            []
          const regionOptions: IDropdownOption[] = []
          selectedProduct.regions.map((region: Region) => {
            if (availableDeploymentCodes.includes(region?.deploymentCode)) {
              regionOptions.push({ key: region.deploymentCode, text: region.name })
            }
          })
          setRegions(regionOptions)
        }
      }
    }
  }, [productWatch, data])

  const onSubmit = (data: ProductFormType) => {
    if (product) {
      delete data.productId // delete productId since is not accepted by API
      if (_.isEqual(data.serviceNowRelatedEnvironment, product?.serviceNowRelatedEnvironment)) {
        delete data.serviceNowRelatedEnvironment
      }
      editProduct.mutate(data)
    } else if (organizationId) {
      data.businessId = organizationId
      createProduct.mutate(data)
    }
  }

  const shouldShowServiceNowRelatedEnvironment = (): boolean => {
    if (isAdmin) {
      return true
    }
    if (!isAdmin && (!product?.serviceNowRelatedEnvironment || product.serviceNowRelatedEnvironment?.length === 0)) {
      return false
    }
    return true
  }

  const productChangeHandler = (item?: IDropdownOption): void => {
    if (item) {
      const fixedBaseline = data.filter((i) => i._id === item.key)[0]?.fixedBaseline
      if (fixedBaseline) {
        setValue('baseline', fixedBaseline)
        setSpinButtonProps({
          disabled: true,
          value: fixedBaseline,
        })
      } else {
        setValue('baseline', product?.baseline || 1)
        setSpinButtonProps({
          disabled: !checkFeature(FEATURE_NAMES.ENVIRONMENT_BASELINE_UPDATE),
          value: product?.baseline || 1,
        })
      }
    }
  }

  return (
    <>
      {!isLoading && !isSuccess && !isError && (
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            <RowColLayout rowProps={{ classNames: 'mt-2' }}>
              <Dropdown
                name="productId"
                required={!product}
                onChange={productChangeHandler}
                translator={t}
                dropdownProps={{
                  disabled: Boolean(product),
                  defaultSelectedKey: product?.product._id,
                  options: dropdownOptions,
                  label: t('PRODUCTS.FORM.PRODUCT_LABEL'),
                  placeholder: t('PRODUCTS.FORM.PRODUCT_PLACEHOLDER'),
                }}
              />
            </RowColLayout>

            {showRegion && (
              <RowColLayout rowProps={{ classNames: 'mt-2' }}>
                <Dropdown
                  name="region"
                  required={true}
                  translator={t}
                  dropdownProps={{
                    responsiveMode: ResponsiveMode.large,
                    disabled: Boolean(product),
                    label: t('PRODUCTS.FORM.DEPLOYMENT_REGION'),
                    placeholder: t('PRODUCTS.FORM.DEPLOYMENT_REGION_PLACEHOLDER'),
                    defaultSelectedKey: product?.region,
                    options: regions,
                  }}
                />
              </RowColLayout>
            )}
            <RowColLayout rowProps={{ classNames: 'mt-2' }}>
              <ControlledTextField
                name="environment"
                required={true}
                translator={t}
                textFieldProps={{
                  disabled: !!product && !checkFeature(FEATURE_NAMES.ENVIRONMENT_UPDATE),
                  label: t('PRODUCTS.FORM.ENVIRONMENT_LABEL'),
                  placeholder: t('PRODUCTS.FORM.ENVIRONMENT_PLACEHOLDER'),
                }}
              />
            </RowColLayout>
            <FeatureRender feature={FEATURE_NAMES.ENVIRONMENT_BASELINE_READ}>
              <RowColLayout rowProps={{ classNames: 'mt-2' }}>
                <ControlledSpinButton
                  name="baseline"
                  spinButtonProps={{
                    disabled: spinButtonProps.disabled,
                    label: t('PRODUCTS.FORM.BASELINE_LABEL'),
                    labelPosition: Position.top,
                    min: BASELINE_MIN_VALUE,
                    max: BASELINE_MAX_VALUE,
                    step: BASELINE_STEP_VALUE,
                    value: spinButtonProps.value.toString(),
                  }}
                />
              </RowColLayout>
            </FeatureRender>
            {PRODUCT_ENV !== ProductEnvTypes.FXXONE && shouldShowServiceNowRelatedEnvironment() && (
              <RowColLayout rowProps={{ classNames: 'mt-2' }}>
                <ControlledTextArrayField
                  name="serviceNowRelatedEnvironment"
                  label={t('PRODUCTS.FORM.SERVICENOW_RELATED_ENVIRONMENT_LABEL')}
                  translator={t}
                  disabled={!isAdmin}
                />
              </RowColLayout>
            )}

            {product && (
              <>
                <RowColLayout rowProps={{ classNames: 'mt-2' }}>
                  <Copy value={product.license}>
                    <div style={{ width: '100%' }}>
                      <TextField
                        disabled={!!product && !checkFeature(FEATURE_NAMES.ENVIRONMENT_UPDATE)}
                        value={product.license}
                        label={t('PRODUCTS.FORM.LICENSE_KEY_LABEL')}
                      />
                    </div>
                  </Copy>
                </RowColLayout>
                <RowColLayout rowProps={{ classNames: 'mt-2' }}>
                  <TextField
                    disabled={!!product && !checkFeature(FEATURE_NAMES.ENVIRONMENT_UPDATE)}
                    value={dateFormatter(t, product.created_at)}
                    label={t('PRODUCTS.FORM.DATE_LABEL')}
                  />
                </RowColLayout>
                <RowColLayout rowProps={{ classNames: 'mt-2' }}>
                  <ControlledToggleButton
                    name="active"
                    toggleProps={{
                      inlineLabel: true,
                      disabled: !checkFeature(FEATURE_NAMES.ENVIRONMENT_UPDATE),
                      label: t('PRODUCTS.FORM.STATUS_LABEL'),
                      onText: t('PRODUCTS.FORM.STATUS_ACTIVE'),
                      offText: t('PRODUCTS.FORM.STATUS_DISABLED'),
                    }}
                  />
                </RowColLayout>
              </>
            )}
            <FeatureRender feature={FEATURE_NAMES.ENVIRONMENT_UPDATE}>
              <FormActionsContainer>
                {product ? (
                  <FormActions
                    submitProps={{
                      text: t('general:BUTTON.SAVE'),
                      iconProps: { iconName: 'Save' },
                    }}
                  />
                ) : (
                  <FormActions
                    submitProps={{
                      text: t('general:BUTTON.CREATE'),
                      iconProps: { iconName: 'Add' },
                    }}
                  />
                )}
              </FormActionsContainer>
            </FeatureRender>
          </form>
        </FormProvider>
      )}

      {isLoading && <LoadingSpinner></LoadingSpinner>}

      {(isSuccess || isError) && (
        <>
          {createProduct.isSuccess && <SuccessStatus message={t('PRODUCTS.FORM.ADD_SUCCESS_MESSAGE')} />}

          {createProduct.isError && <ErrorStatus message={t('PRODUCTS.FORM.ADD_ERROR_MESSAGE')} />}

          {editProduct.isSuccess && <SuccessStatus message={t('PRODUCTS.FORM.EDIT_SUCCESS_MESSAGE')} />}

          {editProduct.isError && <ErrorStatus message={t('PRODUCTS.FORM.EDIT_ERROR_MESSAGE')} />}
        </>
      )}
    </>
  )
}

export default ProductForm
