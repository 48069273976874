import { useMutation } from '@tanstack/react-query'
import graphClient from 'app/query-client/graphClient'
import queryClient from 'app/query-client/queryClient'
import { gql } from 'graphql-request'

interface EditTenantRequest {
  name: string
}

const mutation = gql`
  mutation UpdateTenant($input: UpdateTenantGraphqlRequest!, $tenantId: ID!) {
    updateTenant(id: $tenantId, input: $input) {
      _id
    }
  }
`

interface EditTenantResponse {
  updateTenant: {
    _id: string
  }
}

async function editTenant(tenantId: string, tenant: EditTenantRequest) {
  const { updateTenant }: EditTenantResponse = await graphClient.request(mutation, { tenantId, input: tenant })

  return updateTenant
}

export default function () {
  return useMutation(
    ['updateTenant'],
    ({ id, tenant }: { id: string; tenant: EditTenantRequest }) => editTenant(id, tenant),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['tenant'])
        queryClient.invalidateQueries(['business'])
        queryClient.invalidateQueries(['getBusinessById'])
      },
    }
  )
}
