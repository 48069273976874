import { gql } from "graphql-request"

export const getReportsQuery = gql`
query Report($filter:ReportFilter!){
  getReports(filter:$filter) {
    _id
    group
    unit
    date
    usage {
      group
      quantity
    }
  }
}
`
