import { TFunction } from 'i18next'

export enum RemoteSupport {
  NONE = '',
  INTERACTIVE = 'INTERACTIVE',
  UNASSISTED = 'UNASSISTED',
}

export const remoteSupportOptions = (t: TFunction): { key: RemoteSupport; text: string }[] => {
  return Object.values(RemoteSupport).map((remoteSupport) => ({
    key: remoteSupport,
    text: remoteSupportLabel(t)[remoteSupport],
  }))
}

export const remoteSupportLabel = (t: TFunction): { [key in RemoteSupport]: string } => ({
  [RemoteSupport.INTERACTIVE]: t('REMOTE_SUPPORT.INTERACTIVE'),
  [RemoteSupport.UNASSISTED]: t('REMOTE_SUPPORT.UNASSISTED'),
  [RemoteSupport.NONE]: t('general:NONE'),
})
