import useCreateForm from 'app/hooks/useCreateForm'
import { TFunction } from 'react-i18next'
import { TenantForm } from './TenantForm'
import { ElegibleProduct, OrganizationPolicy, ProductConfig, Tenant } from 'app/query-client/types'
import { TenantEditForm } from './TenantEditForm'
import { ICommandItem } from '@flexxibleit/flexxible-ui'
import { BusinessType } from 'app/models/business/BusinessType'
import { useFeatureRender } from '../../../permissions/useFeatureRender'
import { FEATURE_NAMES } from '../../../permissions/FeatureName.enum'

type UseOrgTenantsActionsProps = {
  t: TFunction
  organizationId: string
  elegibleProducts: ElegibleProduct[]
  policies: OrganizationPolicy[]
  productConfigs?: ProductConfig[]
  organizationType?: BusinessType
  onExportClicked: () => void
}

export const useOrgTenantsActions = ({
  t,
  organizationId,
  elegibleProducts,
  policies,
  productConfigs,
  organizationType,
  onExportClicked,
}: UseOrgTenantsActionsProps) => {
  const { checkFeature } = useFeatureRender()

  const openAddTenant = useCreateForm(
    {
      title: t('TENANTS.FORM.ADD_TITLE'),
      isFooterAtBottom: true,
    },
    <TenantForm
      elegibleProducts={elegibleProducts}
      policies={policies}
      organizationId={organizationId}
      productConfigs={productConfigs}
      organizationType={organizationType}
    />
  )

  const openEditTenant = (tenant: Tenant) => {
    return useCreateForm(
      {
        title: t('TENANTS.FORM.EDIT_TITLE'),
        isFooterAtBottom: true,
      },
      <TenantEditForm
        tenantId={tenant._id}
        policies={policies}
        isDefaultTenant={tenant.organizationRelated._id === organizationId}
        data={{
          name: tenant.name,
          product: tenant.product?._id,
          region: tenant.region,
          policyId: tenant.policy?._id,
        }}
      />
    )
  }

  const commandBarActions: ICommandItem[] = checkFeature(FEATURE_NAMES.TENANT_CREATE)
    ? [
        {
          key: 'create',
          text: t('general:BUTTON.CREATE'),
          iconProps: { iconName: 'Add' },
          onClick: openAddTenant,
        },
        {
          key: 'export',
          text: t('general:BUTTON.EXPORT'),
          iconProps: { iconName: 'ExcelDocument' },
          onClick: onExportClicked,
        },
      ]
    : []

  return {
    openAddTenant,
    openEditTenant,
    commandBarActions,
  }
}
