import { DashboardMetricCard } from '../../../components/Dashboard/DashboardMetricCard/DashboardMetricCard'
import { NotApprovedPatchesBySeverityAndReportingGroupChart } from './components/NotApprovedPatchesBySeverityAndReportingGroupChart'
import { ReportingGroupsInRiskChart } from './components/ReportingGroupsInRiskChart'
import { TotalWorkspacesByReportingGroup } from './components/TotalWorkspacesByReportingGroup'
import { Dashboard } from '../../../components/Dashboard/Dashboard'
import { useEffect, useState } from 'react'
import { ReportingGroupsDashboard } from '../../../query-client/types'
import useGetReportingGroupsDashboard from '../../../hooks/patches/useGetReportingGroupsDashboard'
import { useTranslation } from 'react-i18next'
import { MessageBar } from '@fluentui/react'
import { LoadingSpinner } from '@flexxibleit/flexxible-ui'
import { useExtendedPatchManagementFeature } from '../../../permissions/ExtendedPatchManagement.featureFlag'

interface Props {
  organizationId: string | undefined
}

export const ReportingGroupsDashboardPage = (props: Props) => {
  const { t } = useTranslation('patch_management')

  const [reportingGroupsDashboard, setReportingGroupsDashboard] = useState<ReportingGroupsDashboard>({
    notApprovedPatchesInReportingGroupBySeverity: [],
    reportingGroupsInRisk: [],
    workspacesCount: {
      total: 0,
      windows: 0,
      linux: 0,
    },
    totalWorkspacesByReportingGroup: [],
  })

  const { data: reportingGroupsDashboardData, isLoading } = useGetReportingGroupsDashboard(props.organizationId)

  useEffect(() => {
    if (!isLoading && reportingGroupsDashboardData) {
      setReportingGroupsDashboard(reportingGroupsDashboardData)
    }
  }, [reportingGroupsDashboardData])

  if (isLoading) {
    return <LoadingSpinner />
  }

  return (
    <Dashboard>
      <MessageBar messageBarType={0} isMultiline={true} className="mb-2" style={{ width: '100%' }}>
        {t('DASHBOARD.REPORTING_GROUPS.INFORMATION_BAR')}
      </MessageBar>
      <div
        style={{
          display: 'flex',
          alignItems: 'stretch',
          justifyContent: 'center',
          gap: 20,
          flexWrap: 'wrap',
          marginTop: '20px',
          marginBottom: '20px',
        }}
      >
        <DashboardMetricCard
          title={t('DASHBOARD.REPORTING_GROUPS.TOTAL_WORKSPACES')}
          tooltip={t('DASHBOARD.REPORTING_GROUPS.TOTAL_WORKSPACES_TOOLTIP')}
          value={reportingGroupsDashboard.workspacesCount.total}
          icon={{
            name: 'PC1',
          }}
        />
        <DashboardMetricCard
          title={t('DASHBOARD.REPORTING_GROUPS.WINDOWS_WORKSPACES')}
          tooltip={t('DASHBOARD.REPORTING_GROUPS.WINDOWS_WORKSPACES_TOOLTIP')}
          value={reportingGroupsDashboard.workspacesCount.windows}
          icon={{
            name: 'WindowsLogo',
          }}
        />
        <DashboardMetricCard
          title={t('DASHBOARD.REPORTING_GROUPS.LINUX_WORKSPACES')}
          tooltip={t('DASHBOARD.REPORTING_GROUPS.LINUX_WORKSPACES_TOOLTIP')}
          value={reportingGroupsDashboard.workspacesCount.linux}
          icon={{
            name: 'LinuxLogo',
          }}
        />
      </div>

      {useExtendedPatchManagementFeature() && (
        <NotApprovedPatchesBySeverityAndReportingGroupChart
          items={reportingGroupsDashboard.notApprovedPatchesInReportingGroupBySeverity}
        />
      )}
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
          gap: 20,
          marginTop: '20px',
        }}
      >
        <ReportingGroupsInRiskChart items={reportingGroupsDashboard.reportingGroupsInRisk} />
        <TotalWorkspacesByReportingGroup items={reportingGroupsDashboard.totalWorkspacesByReportingGroup} />
      </div>
    </Dashboard>
  )
}
