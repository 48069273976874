import { TFunction } from 'react-i18next'

export enum MicroserviceLanguage {
  'POWER_SHELL' = 'POWER_SHELL',
  'PYTHON' = 'PYTHON',
  'BASH' = 'BASH',
}

export const microserviceLanguageOptions = (t: TFunction) =>
  Object.values(MicroserviceLanguage).map((language) => ({
    key: language,
    text: languageLabel(t)[language],
    disabled: !isAvailable[language],
    data: {
      preview: language === MicroserviceLanguage.BASH,
    },
  }))

export const languageLabel = (t: TFunction): { [key in MicroserviceLanguage]: string } => ({
  [MicroserviceLanguage.POWER_SHELL]: t('MICROSERVICE_LANGUAGE.POWER_SHELL'),
  [MicroserviceLanguage.PYTHON]: t('MICROSERVICE_LANGUAGE.PYTHON'),
  [MicroserviceLanguage.BASH]: t('MICROSERVICE_LANGUAGE.BASH'),
})

const isAvailable: { [key in MicroserviceLanguage]: boolean } = {
  [MicroserviceLanguage.POWER_SHELL]: true,
  [MicroserviceLanguage.PYTHON]: false,
  [MicroserviceLanguage.BASH]: true,
}
