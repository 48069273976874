import { useMutation, useQueryClient } from '@tanstack/react-query'
import { MultiLanguageField } from 'app/models/microservices'
import graphClient from 'app/query-client/graphClient'
import { gql } from 'graphql-request'

interface Request {
  initText?: MultiLanguageField[]
  okText?: MultiLanguageField[]
  koText?: MultiLanguageField[]
  language: string
}

const mutation = gql`
  mutation ($id: ID!, $input: UpdateFlowNotificationGraphqlRequest!) {
    updateFlowNotification(id: $id, input: $input)
  }
`

const updateFlowNotification = async (id: string, input: Request): Promise<string> => {
  await graphClient.request(mutation, { id, input })

  return id
}

export const useUpdateFlowNotification = () => {
  const queryClient = useQueryClient()

  return useMutation(
    ['updateFlowNotification'],
    ({ id, input }: { id: string; input: Request }) => updateFlowNotification(id, input),
    {
      onSuccess: (id) => {
        queryClient.invalidateQueries(['flow', id])
      },
    }
  )
}
