import { getPaginatedQueryParamsBlock, pageInfoBlock, paginatedVarsBlock } from 'app/config/pagination'
import { gql } from 'graphql-request'

export const usePaginatedUserRoleQuery = () => {
  const queryName = 'getPaginatedUserRoles'
  const query = gql`
query Request(
  $organizationId: ID!
  ${getPaginatedQueryParamsBlock('UserRoleFilter', 'UserRoleSorting')}
) {
  getPaginatedUserRoles(
    organizationId: $organizationId
    ${paginatedVarsBlock}
  ) {
    data {
      _id
      name
      created_at
      updated_at
      created_by {
        name
        surname
        email
      }
      updated_by {
        name
        surname
        email
      }
      assignedUsers
      assignedOrganizations
    }
    ${pageInfoBlock}
  }
}
`
  return {
    query,
    queryName,
  }
}
