import StatusLayout from "./status-layout/status-layout"
import EmptyReports from "../../../app/assets/img/Vector.svg"

interface Props {
  message: string
  explanation: string
}

const Status404 = ({ message, explanation }: Props) => {

  return (
    <StatusLayout 
      img={EmptyReports}
      message={message}
      explanation={explanation}
      imgWithContainer={true}
      renderButton={false}
    />
  )
}

export default Status404