import { Stack } from '@fluentui/react'
import { FontIcon } from '@fluentui/react/lib/Icon'
import { ReactNode, useState } from 'react'

interface Props {
  headerContent: ReactNode;
  children: ReactNode;
  opened?: boolean;
}

export const Accordion = ({ headerContent, children, opened }: Props) => {
  const [open, setOpen] = useState(opened ?? false)

  const _onAccordionClicked = (event: any) => {
    setOpen(prev => !prev)
  }

  return (
    <>
      <div className="d-flex d-flexRow" style={{ cursor: 'pointer', marginBottom: 20 }} onClick={_onAccordionClicked}>
        { headerContent }
        <div className="d-flex d-center d-flexNone" style={{marginLeft: 10}}>
          <FontIcon iconName='ChevronUp' style={{transform: open ? 'rotate(0deg)' : 'rotate(-180deg)', transition: 'all 0.5s ease'}}/>
        </div>
      </div>
      {open && (
        <Stack styles={{
          root: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between'
          }
        }}>
          { children }
        </Stack>
      )}
    </>
  )
}
