import { gql } from 'graphql-request'

export const getPaginatedQueryParamsBlock = (filtersName: string, sortingName: string) => gql`
    $page: Int!
    $perPage: Int!
    $searchTerm: String!
    $filters: ${filtersName}!
    $sorting: ${sortingName}!
`

export const paginatedVarsBlock = gql`
      page: $page
      perPage: $perPage
      searchTerm: $searchTerm
      filters: $filters
      sorting: $sorting
`

export const pageInfoBlock = gql`
    pageInfo {
      hasNextPage
      hasPreviousPage
      totalPages
      currentPage
      total
      absoluteCount
    }
`
