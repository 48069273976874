import {
  CreateButton,
  EmptyStatus,
  MOBILE_QUERY,
  ViewDetailsActionButton,
  useMediaQuery,
} from '@flexxibleit/flexxible-ui'
import {
  ColumnActionsMode,
  DetailsListLayoutMode,
  IColumn,
  Icon,
  Link,
  SelectionMode,
  Stack,
  TooltipHost,
  TooltipOverflowMode,
} from '@fluentui/react'
import { TenantPolicyReportingGroupView } from 'app/hooks/policies/useGetPolicy'
import useCreateForm from 'app/hooks/useCreateForm'
import { User } from 'app/query-client/types'
import { useTranslation } from 'react-i18next'
import { PolicyReportingGroupForm } from './PolicyReportingGroupForm'
import Table from 'app/components/Table/Table.component'

interface Props {
  reportingGroups: TenantPolicyReportingGroupView[]
  organizationId: string
  policyId: string
  users: User[]
}

export default function ReportingGroups({ reportingGroups, organizationId, policyId, users }: Props) {
  const { t, i18n } = useTranslation('organization_details')
  const [isMobile] = useMediaQuery(MOBILE_QUERY)

  const onCreate = useCreateForm(
    {
      title: t('POLICIES_REPORTING_GROUPS.FORM.CREATE_TITLE'),
      isFooterAtBottom: true,
    },
    <PolicyReportingGroupForm
      organizationId={organizationId}
      policyId={policyId}
      users={users}
      reportingGroups={reportingGroups}
    />
  )

  const openEditTenant = (reportingGroup: TenantPolicyReportingGroupView) => {
    return useCreateForm(
      {
        title: t('POLICIES_REPORTING_GROUPS.FORM.EDIT_TITLE'),
        isFooterAtBottom: true,
      },
      <PolicyReportingGroupForm
        id={reportingGroup._id}
        data={{
          name: reportingGroup.name,
        }}
        organizationId={organizationId}
        policyId={policyId}
        users={users}
        reportingGroups={reportingGroups}
      />
    )
  }

  const columns: IColumn[] = [
    {
      key: 'name',
      name: t('POLICIES_REPORTING_GROUPS.TABLE.NAME'),
      fieldName: 'name',
      minWidth: 100,
      maxWidth: 300,
      isRowHeader: true,
      isPadded: true,
      columnActionsMode: ColumnActionsMode.disabled,
      onRender: (item: TenantPolicyReportingGroupView) => {
        return <Link onClick={openEditTenant(item)}>{item.name}</Link>
      },
    },

    {
      key: 'actions',
      name: t('POLICIES_REPORTING_GROUPS.TABLE.ACTION'),
      minWidth: isMobile ? 50 : 100,
      onRender: (item: TenantPolicyReportingGroupView) => {
        return (
          <span className="d-flex d-flexRow">
            <span style={isMobile ? { marginRight: 10 } : { marginRight: 20 }}>
              <ViewDetailsActionButton locale={i18n.language} isMobile={isMobile} onClick={openEditTenant(item)} />
            </span>
          </span>
        )
      },
      isPadded: true,
      columnActionsMode: ColumnActionsMode.disabled,
    },
  ]

  if (!reportingGroups.length) {
    return (
      <div style={{ marginTop: 100 }}>
        <EmptyStatus
          message={t('POLICIES_REPORTING_GROUPS.TABLE.EMPTY_MESSAGE')}
          img={<Icon iconName="ReportLibrary" style={{ fontSize: 68 }} />}
          buttonProps={{
            text: t('general:BUTTON.CREATE'),
            iconProps: { iconName: 'Add' },
            onClick: onCreate,
          }}
        />
      </div>
    )
  }

  return (
    <>
      <Stack horizontal={!isMobile} horizontalAlign={'end'} tokens={{ padding: '20px 0 0 0' }} className="mb-sm-2">
        <Stack.Item>
          <div
            className={`d-flex d-flexRow d-flexAlignItemsCenter ${isMobile ? 'd-flexJustifyBetween' : ''} `}
            style={isMobile ? { marginTop: 15 } : {}}
          >
            <CreateButton locale={i18n.language} onClick={onCreate} />
          </div>
        </Stack.Item>
      </Stack>

      <Table
        tableId="reporting-groups-policy"
        isEmpty={reportingGroups.length === 0}
        emptyMessage={t('general:EMPTY_FILTER')}
        tableProps={{
          columns,
          items: reportingGroups,
          compact: false,
          selectionMode: SelectionMode.none,
          layoutMode: DetailsListLayoutMode.justified,
          isHeaderVisible: true,
        }}
      />
    </>
  )
}
