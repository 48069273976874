import { useQuery } from '@tanstack/react-query'
import graphClient from '../../../../../query-client/graphClient'
import { gql } from 'graphql-request'

export interface InstalledAppView {
  id: string,
  name: string,
  organizationId: string,
}

interface Response {
  getInstalledAppDetail: InstalledAppView
}

interface Variables {
  id: string,
}

const getInstalledAppByIdQuery = gql`
  query Request($id: ID!) {
    getInstalledAppDetail(id: $id) {
      id
      organizationId
      name
    }
  }
`

const getInstalledAppById = async (id: string): Promise<InstalledAppView | undefined> => {
  const response: Response = await graphClient.request<Response, Variables>(getInstalledAppByIdQuery, { id })
  return response.getInstalledAppDetail
}

export const useGetInstalledAppById = (id: string) => {
  return useQuery(['getInstalledAppById', id], () => getInstalledAppById(id), {
    refetchOnWindowFocus: false
  })
}