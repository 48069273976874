import PageBody from '../../../components/PageBody/PageBody.component'
import { FC, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useOrganizationSelection } from '../../../globals/useOrganizationSelection'
import { PatchesDashboardPage } from './PatchesDashboardPage'
import { Tab, TabPanel } from '../../../components/tab-panel/TabPanel'
import { PatchesListFeature } from './PatchesList.feature'
import { withFeatureRender } from '../../../permissions/withFeatureRender'
import { FEATURE_NAMES } from '../../../permissions/FeatureName.enum'

const Patches: FC = () => {
  const baseUrl = location.pathname.split('/')[1]
  const { t } = useTranslation('patch_management')
  const { organizationId } = useParams()
  const { selectedOrganization } = useOrganizationSelection()

  const navigate = useNavigate()

  useEffect(() => {
    if (!selectedOrganization) {
      return
    }
    if (selectedOrganization._id !== organizationId) {
      navigate(`/${baseUrl}/${selectedOrganization._id}/patch-management/patches/`)
    }
  }, [selectedOrganization])

  const buildTabs = (): Tab[] => {
    return [
      {
        title: t('DASHBOARD.TABS.PATCHES_TAB.TITLE_DASHBOARD'),
        component: <PatchesDashboardPage organizationId={selectedOrganization?._id} />,
        key: 'dashboard',
      },
      {
        title: t('DASHBOARD.TABS.PATCHES_TAB.TITLE_LIST'),
        component: <PatchesListFeature />,
        key: 'patches-list',
      },
    ]
  }

  return (
    <PageBody title={t('PATCHES.TITLE')} isLoading={false}>
      <TabPanel tabs={buildTabs()} />
    </PageBody>
  )
}

export const PatchesPage = withFeatureRender(Patches, FEATURE_NAMES.PATCH_MANAGEMENT_READ)
