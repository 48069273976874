import {
  ControlledTextField,
  DeleteActionButton,
  Dialog,
  FormActionsContainer,
  LoadingSpinner,
  RowColLayout,
} from '@flexxibleit/flexxible-ui'
import { zodResolver } from '@hookform/resolvers/zod'
import { FormProvider, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import * as z from 'zod'
import FormActions from '../../../../components/forms/FormActions'
import { useNavigate, useParams } from 'react-router-dom'
import { useEffect, useState } from 'react'
import SuccessStatus from '../../../../components/status/success-status'
import ErrorStatus from '../../../../components/status/error-status'
import { LinuxPatchPolicy } from 'app/query-client/types'
import { useBoolean } from '@fluentui/react-hooks'
import { useFormSidebarContext } from 'app/components/forms/FormSidebar/state/FormSidebarState'
import { useCreateLinuxPatchPolicy } from 'app/hooks/patchPolicies/linux-patch-policy/useCreateLinuxPatchPolicy'
import { useUpdateLinuxPatchPolicy } from 'app/hooks/patchPolicies/linux-patch-policy/useUpdateLinuxPatchPolicy'
import { useDeleteLinuxPatchPolicy } from 'app/hooks/patchPolicies/linux-patch-policy/useDeleteLinuxPatchPolicy'

export interface CreateLinuxPatchPolicyFormType {
  name: string
}

export interface CreateOrUpdatePatchPolicyFormProps {
  linuxPatchPolicy?: LinuxPatchPolicy
}

const schema: z.ZodType<Partial<CreateLinuxPatchPolicyFormType>> = z.object({
  name: z
    .string({
      required_error: 'required',
    })
    .min(1, { message: 'required' }),
})

export const CreateLinuxPatchPolicyForm = ({ linuxPatchPolicy }: CreateOrUpdatePatchPolicyFormProps) => {
  const { t } = useTranslation('patch_management')
  const { organizationId } = useParams()
  const createLinuxPatchPolicy = useCreateLinuxPatchPolicy(organizationId || '')
  const updateLinuxPatchPolicy = useUpdateLinuxPatchPolicy()
  const deleteLinuxPatchPolicy = useDeleteLinuxPatchPolicy()
  const [hideDeleteDialog, { toggle: toggleHideDeleteDialog }] = useBoolean(true)
  const { closeSidebar } = useFormSidebarContext()
  const navigate = useNavigate()

  const [isSuccess, setIsSuccess] = useState(false)
  const [isError, setIsError] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    setIsSuccess(
      createLinuxPatchPolicy.isSuccess || updateLinuxPatchPolicy.isSuccess || deleteLinuxPatchPolicy.isSuccess
    )
    setIsError(createLinuxPatchPolicy.isError || updateLinuxPatchPolicy.isError || deleteLinuxPatchPolicy.isError)
    setIsLoading(
      createLinuxPatchPolicy.isLoading || updateLinuxPatchPolicy.isLoading || deleteLinuxPatchPolicy.isLoading
    )
  }, [createLinuxPatchPolicy, updateLinuxPatchPolicy, deleteLinuxPatchPolicy])

  const getDefaultValues = () => {
    if (linuxPatchPolicy) {
      return {
        name: linuxPatchPolicy.name,
      }
    }

    return {
      name: '',
    }
  }

  const methods = useForm<CreateLinuxPatchPolicyFormType>({
    resolver: zodResolver(schema),
    defaultValues: getDefaultValues(),
  })

  const handleSubmit = (data: CreateLinuxPatchPolicyFormType) => {
    if (!linuxPatchPolicy?._id) {
      createLinuxPatchPolicy.mutate({
        name: data.name,
      })
    } else {
      updateLinuxPatchPolicy.mutate({
        id: linuxPatchPolicy._id,
        input: { name: data.name, excludes: linuxPatchPolicy.excludes },
      })
    }
  }

  const onDelete = () => {
    toggleHideDeleteDialog()
  }
  const renderDeleteConfirmation = (): JSX.Element => {
    return (
      <Dialog
        title={t('DELETE_CONFIRMATION.TITLE')}
        description={t('DELETE_CONFIRMATION.MESSAGE')}
        actionButton={t('DELETE_CONFIRMATION.BUTTON_ACCEPT')}
        dismissButton={t('DELETE_CONFIRMATION.BUTTON_CANCEL')}
        hidden={hideDeleteDialog}
        onDismiss={toggleHideDeleteDialog}
        callback={() => {
          toggleHideDeleteDialog()
          handleDeletePolicy()
        }}
      />
    )
  }
  const handleDeletePolicy = () => {
    if (linuxPatchPolicy?._id) {
      deleteLinuxPatchPolicy.mutateAsync(linuxPatchPolicy._id).then(() => {
        navigate(`/organization/${organizationId}/patch-management/linux-patch-policies`)
        closeSidebar()
      })
    }
  }

  if (isLoading) {
    return <LoadingSpinner />
  }

  if (isSuccess || isError) {
    return (
      <>
        {createLinuxPatchPolicy.isSuccess && <SuccessStatus message={t('LINUX_PATCH_POLICIES.FORM.ADD_SUCCESS')} />}
        {updateLinuxPatchPolicy.isSuccess && <SuccessStatus message={t('LINUX_PATCH_POLICIES.FORM.UPDATE_SUCCESS')} />}

        {createLinuxPatchPolicy.isError && (
          <>
            <ErrorStatus message={t('LINUX_PATCH_POLICIES.FORM.ADD_ERROR')} />
          </>
        )}
        {updateLinuxPatchPolicy.isError && (
          <>
            <ErrorStatus message={t('LINUX_PATCH_POLICIES.FORM.UPDATE_ERROR')} />
          </>
        )}

        {deleteLinuxPatchPolicy.isError && <ErrorStatus message={t('LINUX_PATCH_POLICIES.FORM.DELETE_ERROR')} />}
      </>
    )
  }

  return (
    <>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(handleSubmit)}>
          <RowColLayout rowProps={{ classNames: 'mt-2' }}>
            <ControlledTextField
              name="name"
              required={true}
              translator={t}
              textFieldProps={{
                label: t('LINUX_PATCH_POLICIES.FORM.NAME'),
                placeholder: t('LINUX_PATCH_POLICIES.FORM.NAME_PLACEHOLDER'),
              }}
            />
          </RowColLayout>

          <ActionButtons isEdit={!!linuxPatchPolicy?._id} onDelete={onDelete} />
        </form>
        {renderDeleteConfirmation()}
      </FormProvider>
    </>
  )
}

const ActionButtons = ({ isEdit, onDelete }: { isEdit: boolean; onDelete: () => void }) => {
  const { t, i18n } = useTranslation()

  if (isEdit) {
    return (
      <FormActionsContainer isEdit>
        <DeleteActionButton locale={i18n.language} onClick={onDelete} />
        <FormActions
          submitProps={{
            text: t('general:BUTTON.SAVE'),
            iconProps: { iconName: 'Save' },
          }}
        />
      </FormActionsContainer>
    )
  }

  return (
    <FormActionsContainer>
      <FormActions
        submitProps={{
          text: t('general:BUTTON.CREATE'),
          iconProps: { iconName: 'Add' },
        }}
      />
    </FormActionsContainer>
  )
}
