import { IButtonProps } from '@fluentui/react'
import { useTranslation } from 'react-i18next'
import { FlexxibleSecondaryButton } from '@flexxibleit/flexxible-ui'

const EditButton = (props: IButtonProps) => {
  const { t } = useTranslation('general')

  return <FlexxibleSecondaryButton {...props} text={t('BUTTON.EDIT')} />
}

export default EditButton
