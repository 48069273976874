import { pageInfoBlock, paginatedVarsBlock } from 'app/config/pagination'
import { gql } from 'graphql-request'

export const usePaginatedFlowsQuery = () => {
  const queryName = 'getFlowsByOrganizationPaginated'

  const query = gql`
		query Request(
			$organizationId: ID!
			$language: String!
			$page: Int!
			$perPage: Int!
			$searchTerm: String!
			$filters: PaginatedFlowFiltersGraphql!
			$sorting: PaginatedFlowSortingGraphql!
		) {
			getFlowsByOrganizationPaginated(
				organizationId: $organizationId
				language: $language
				${paginatedVarsBlock}
			) {
				data {
					id
					name {
						code
						text
						translatedByIA
					}
					description {
						code
						text
						translatedByIA
					}
					type
					detectionOnly
					cooldown
					enabled
				}
				${pageInfoBlock}
			}
		}
	`

  return {
    query,
    queryName,
  }
}
