import { createContext, useContext } from 'react'

export interface MicroserviceTabsState {
  script?: {
    version: number
    text: string
  }
  overview?: {
    text: string
  }
  license?: {
    text: string
  }
}

interface MicroserviceTabsContextType {
  state: MicroserviceTabsState
  setState: (tabs: MicroserviceTabsState) => void
}

export const MicroserviceTabsContext = createContext<MicroserviceTabsContextType | undefined>(undefined)

export function useMicroserviceTabs() {
  const context = useContext(MicroserviceTabsContext)
  if (context === undefined) {
    throw new Error('useMicroserviceTabs must be used within a MicroserviceTabsContext.Provider')
  }
  return context
}
