import { LoadingSpinner } from '@flexxibleit/flexxible-ui'
import { Status, Wrapper } from '@googlemaps/react-wrapper'
import EditButton from 'app/components/Buttons/EditButton.component'
import { DisplayField } from 'app/components/DisplayField'
import FlexxibleCard from 'app/components/FlexxibleCard/FlexxibleCard.component'
import { GoogleMap } from 'app/components/GoogleMap/GoogleMap'
import { GoogleMapMarker } from 'app/components/GoogleMap/GoogleMapMarker'
import Col from 'app/components/Layouts/Col'
import Row from 'app/components/Layouts/Row'
import { MOBILE_QUERY, TABLET_QUERY } from 'app/config/media-query'
import { useGlobalConfig } from 'app/globals/context/GlobalConfigContext'
import useCreateForm from 'app/hooks/useCreateForm'
import useMediaQuery from 'app/hooks/utils/useMediaQuery'
import { WorkspaceGroupType } from 'app/models/workspaces-groups/WorkspaceGroupType'
import { WorkspaceGroupLocationForm } from 'app/pages/organization-details/workspace-groups/location/WorspaceGroupLocationForm'
import { WorkspaceGroup } from 'app/query-client/types'
import { useTranslation } from 'react-i18next'
import { FeatureRender } from '../../../../permissions/FeatureRender'
import { FEATURE_NAMES } from '../../../../permissions/FeatureName.enum'

interface Props {
  workspaceGroup?: WorkspaceGroup
}

export const WorkspaceGroupLocation = ({ workspaceGroup }: Props) => {
  const { t } = useTranslation('organization_details')
  const globalConfig = useGlobalConfig()
  const [isTablet] = useMediaQuery(TABLET_QUERY)
  const [isMobile] = useMediaQuery(MOBILE_QUERY)

  const _onEditWorkspaceGroupDetailsClicked = useCreateForm(
    {
      title: t('WORKSPACE_GROUPS.LOCATION_FORM.EDIT_TITLE'),
      isFooterAtBottom: true,
    },
    <WorkspaceGroupLocationForm location={workspaceGroup?.location} id={workspaceGroup!._id} />
  )

  const render = (status: Status) => {
    if (status === Status.LOADING) return <LoadingSpinner />

    return <></>
  }

  return (
    <FlexxibleCard cardProps={{ style: { marginTop: 30 } }} styleNames="col d-flex d-flexCol d-flexJustifyBetween">
      <Row>
        <Col className="col-xs-12 col-lg-6">
          <Row>
            <Col className="col-xs-12 col-lg-6">
              <DisplayField label={t('WORKSPACE_GROUPS.LOCATION_FORM.ADDRESS')}>
                {workspaceGroup?.location?.address ?? '-'}
              </DisplayField>
            </Col>
          </Row>
          <Row>
            <Col className="col-xs-12 col-lg-6">
              <DisplayField label={t('WORKSPACE_GROUPS.LOCATION_FORM.DESCRIPTION')}>
                {workspaceGroup?.location?.description ?? '-'}
              </DisplayField>
            </Col>
          </Row>
          {workspaceGroup?.type !== WorkspaceGroupType.DYNAMIC && (
            <FeatureRender feature={FEATURE_NAMES.WORKSPACE_GROUPS_UPDATE}>
              <div className="d-flex d-flexRowReverse">
                <EditButton onClick={_onEditWorkspaceGroupDetailsClicked} />
              </div>
            </FeatureRender>
          )}
        </Col>
        <Col className="col-xs-12 col-lg-6">
          <div style={{ height: isTablet || isMobile ? 350 : 600, marginTop: isTablet || isMobile ? 10 : 5 }}>
            <Wrapper apiKey={globalConfig?.gmApiKey ?? ''} render={render} libraries={['places']}>
              <GoogleMap
                center={{ lat: workspaceGroup?.location?.latitude ?? 0, lng: workspaceGroup?.location?.longitude ?? 0 }}
                initialZoom={workspaceGroup?.location ? 14 : 2}
                zoom={workspaceGroup?.location ? 17 : 2}
                disableDefaultUI={true}
                animationDuration={2000}
              >
                {workspaceGroup?.location && (
                  <GoogleMapMarker
                    position={{
                      lat: workspaceGroup?.location?.latitude ?? 0,
                      lng: workspaceGroup?.location?.longitude ?? 0,
                    }}
                  />
                )}
              </GoogleMap>
            </Wrapper>
          </div>
        </Col>
      </Row>
    </FlexxibleCard>
  )
}
