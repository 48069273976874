import { useMutation, useQueryClient } from '@tanstack/react-query'
import { MultiLanguageField } from 'app/models/microservices'
import graphClient from 'app/query-client/graphClient'
import { gql } from 'graphql-request'

interface CreateFlowGraphqlRequest {
  organizationId: string
  name: MultiLanguageField[]
  description: MultiLanguageField[]
  type: string
  detectionOnly: boolean
  cooldown: number
  language: string
}

const mutation = gql`
  mutation Request($input: CreateFlowGraphqlRequest!) {
    createFlow(input: $input) {
      _id
    }
  }
`
async function createFlow(input: CreateFlowGraphqlRequest) {
  const { createFlow }: any = await graphClient.request(mutation, {
    input: input,
  })

  return createFlow
}

export default function () {
  const queryClient = useQueryClient()

  return useMutation(['createFlow'], (input: CreateFlowGraphqlRequest) => createFlow(input), {
    onSuccess: () => {
      queryClient.invalidateQueries(['flows'])
    },
  })
}
