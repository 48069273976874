import * as Excel from 'exceljs'
import { t } from 'i18next'

type useTableExportProps = {
  query: any
  columns: any[]
  fileName: string
  exportMapper?: (item: any) => any
}

export const useTableExport = ({ query, columns, fileName, exportMapper }: useTableExportProps) => {
  const onExportClicked = async () => {
    let { data: items } = await query()
    if (exportMapper) {
      items = items.map(exportMapper)
    }
    let workbook = new Excel.Workbook()
    let worksheet = workbook.addWorksheet(fileName)

    worksheet.columns = columns.map((column) => {
      return {
        header: column.header,
        key: column.key,
        width: column.width,
      }
    })

    const headerRow = worksheet.getRow(1)
    headerRow.font = { bold: true }
    headerRow.alignment = { horizontal: 'center' }

    const updateColumnWidths = (row: any) => {
      row.eachCell({ includeEmpty: true }, (cell: any, colNumber: any) => {
        const column: any = worksheet.columns[colNumber - 1]
        const value = cell?.value?.toString()

        if (value) {
          let columnWidth = value.length > column.width - 3 ? value.length + 3 : column.width
          if (colNumber === 1 || colNumber === 6 || colNumber === 7 || colNumber === 8) {
            columnWidth = value.length > column.width - 6 ? value.length + 6 : column.width
          }
          column.width = columnWidth
        }
      })
    }

    items.map((item: any) => {
      columns.forEach((column) => {
        if (column.isDate && item[column.key] && typeof item[column.key] === 'string') {
          const date = new Date(parseInt(item[column.key]))
          item[column.key] = date.toLocaleDateString()
        }
      })

      const row = worksheet.addRow({
        action: 'Add/Update',
        ...item,
      })

      updateColumnWidths(row)
    })

    const validationPermissions = columns
      .filter((item) => Boolean(item.formulae))
      .map((item) => {
        return {
          key: item.key,
          config: {
            type: 'list' as const,
            allowBlank: false,
            formulae: item.formulae,
            showDropDown: true,
            showErrorMessage: true,
            errorTitle: t('TABLE_EXPORT.INVALID_INPUT'),
            error: t('TABLE_EXPORT.INVALID_INPUT_DESCRIPTION'),
            errorStyle: 'stop' as const,
          },
        }
      })

    const validationColumns = validationPermissions.map((item) => {
      return worksheet.getColumn(worksheet.getColumn(item.key).number)
    })

    validationColumns.forEach((column) => {
      const fieldValidation = validationPermissions.find((item) => item.key === column.key)
      if (!fieldValidation) return

      for (let rowNumber = 2; rowNumber <= items.length + 100; rowNumber++) {
        const cell = worksheet.getCell(`${column.letter}${rowNumber}`)
        cell.dataValidation = fieldValidation.config
      }
    })

    worksheet.autoFilter = `A1:${worksheet.getColumn(columns.length).letter}${items.length + 1}`

    const buffer = await workbook.xlsx.writeBuffer()
    const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
    const link = document.createElement('a')
    link.href = window.URL.createObjectURL(blob)
    const date = new Date()
    const formattedDate = date.toISOString().slice(0, 10)

    const hyphenTitle = fileName.replace(/ /g, '-')
    const filename = `${hyphenTitle}-${formattedDate}.xlsx`

    link.download = filename
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  }

  return { onExportClicked }
}
