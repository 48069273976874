import { MultiLanguageField, getTranslation } from "app/models/microservices";
import { MultilanguageFormFields } from "app/pages/microservices/MultilanguageFormFields";
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useFormSidebarContext } from "../components/forms/FormSidebar/state/FormSidebarState";
import { LateralPanelProps } from "../components/lateral-panel/LateralPanel.component";

function useMultilanguageForm(
  formSidebarProps: Partial<LateralPanelProps>,
  fieldName: string,
  languages: MultiLanguageField[],
  updateField: (code: string, text: string) => void,
  methods: any,
  labelKey?: string,
  placeholderKey?: string
) {
  const { i18n } = useTranslation()
  const { openSidebar, setContent, setFormSidebarProps } = useFormSidebarContext()

  return () => {
    const preferred = getTranslation(languages, i18n.language)
    const missingTranslation = languages.some((language) => !language.text || language.translatedByIA)
    const missingPreferredText = !preferred.text
    const shouldFetchTranslations = missingTranslation && !missingPreferredText

    const sidebarContent = React.createElement(MultilanguageFormFields, {
      fields: languages,
      updateField,
      fieldName,
      shouldFetchTranslations,
      textForTranslation: preferred?.text ?? '',
      methods,
      labelKey,
      placeholderKey,
    })
    setFormSidebarProps(formSidebarProps)
    setContent(sidebarContent)
    openSidebar()
  }
}

export default useMultilanguageForm;
