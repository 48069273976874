import Highcharts from 'highcharts'
import { useTranslation } from 'react-i18next'
import { DashboardChartCard } from '../../../../components/Dashboard/DashboardChartCard/DashboardChartCard'
import { HeatmapChart } from '../../../../components/Charts/HeatmapChart/HeatmapChart'
import { useNavigate, useLocation } from 'react-router-dom'
import useGetCountAvailableWindowsPatches from '../../../../hooks/patches/useGetCountAvailableWindowsPatches'
import { PatchesCount } from '../../../../query-client/types'

export const SecurityMissingPatches = () => {
  const { t } = useTranslation('patch_management');
  const navigate = useNavigate();
  const location = useLocation();

  const {
    data,
    isLoading
  } = useGetCountAvailableWindowsPatches({ categories: ['Security Updates'] } )

  const severities = ['Critical', 'Important', 'Moderate', 'Low', 'Unspecified'];
  const dateRange = ['>60 days', '60-30 days', '30-7 days', '>7 days']

  const xAxisCategories = [
    t('DASHBOARD.SECURITY.CRITICAL'),
    t('DASHBOARD.SECURITY.IMPORTANT'),
    t('DASHBOARD.SECURITY.MODERATE'),
    t('DASHBOARD.SECURITY.LOW'),
    t('DASHBOARD.SECURITY.UNSPECIFIED'),
  ];
  const yAxisCategories = [
    `>60 ${t('DASHBOARD.SECURITY.DAYS').toLowerCase()}`,
    `60-30 ${t('DASHBOARD.SECURITY.DAYS').toLowerCase()}`,
    `30-7 ${t('DASHBOARD.SECURITY.DAYS').toLowerCase()}`,
    `>7 ${t('DASHBOARD.SECURITY.DAYS').toLowerCase()}`
  ]

  const getChartValue = (data: PatchesCount[], x: number, y: number) => {
    const chartValue = data.find((item) => item.severity === severities[x] && item.dateRange === dateRange[y])
    return chartValue ? chartValue.value : 0;
  }

  const getChartMatrix = (data: PatchesCount[]) => {
    return (
      [
        [0, 0, getChartValue(data, 0, 0)],
        [0, 1, getChartValue(data, 0, 1)],
        [0, 2, getChartValue(data, 0, 2)],
        [0, 3, getChartValue(data, 0, 3)],
        [1, 0, getChartValue(data, 1, 0)],
        [1, 1, getChartValue(data, 1, 1)],
        [1, 2, getChartValue(data, 1, 2)],
        [1, 3, getChartValue(data, 1, 3)],
        [2, 0, getChartValue(data, 2, 0)],
        [2, 1, getChartValue(data, 2, 1)],
        [2, 2, getChartValue(data, 2, 2)],
        [2, 3, getChartValue(data, 2, 3)],
        [3, 0, getChartValue(data, 3, 0)],
        [3, 1, getChartValue(data, 3, 1)],
        [3, 2, getChartValue(data, 3, 2)],
        [3, 3, getChartValue(data, 3, 3)],
        [4, 0, getChartValue(data, 4, 0)],
        [4, 1, getChartValue(data, 4, 1)],
        [4, 2, getChartValue(data, 4, 2)],
        [4, 3, getChartValue(data, 4, 3)],
      ]
    )
  }

  const columnColors = ['#880808', '#FF5733', '#FFC300', '#EEDC82', '#71797E']
  const getColumnColor = (column: number, row: number) => {
    const baseColor = Highcharts.color(columnColors[column % columnColors.length])
    return baseColor.setOpacity(1 - row * 0.3).get('rgba')
  }

  const chartSeriesData: Highcharts.SeriesHeatmapOptions[] = [
    {
      name: 'Security Heatmap',
      type: 'heatmap',
      borderColor: '#000000',
      borderWidth: 0.2,
      data: isLoading ? [] : getChartMatrix(data!).map((point) => ({
        x: point[0],
        y: point[1],
        value: point[2],
        color: getColumnColor(point[0], point[1]),
      })),
      dataLabels: {
        enabled: true,
      },
    },
  ];

  const getSeverity = (point: number) => {
    switch (point) {
      case 0:
        return 'severity=Critical'
      case 1:
        return 'severity=Important'
      case 2:
        return 'severity=Moderate'
      case 3:
        return 'severity=Low'
      default:
        return 'severity=Unspecified'
    }
  }

  const getDateRange = (point: number) => {
    switch (point) {
      case 0:
        return 'dateRange=60days'
      case 1:
        return 'dateRange=60-30days'
      case 2:
        return 'dateRange=30-7days'
      default:
        return 'dateRange=7days'
    }
  }

  const handlePointClick = (event: any) => {
    const point = event.point;
    const currentPath = location.pathname;
    const newPath = currentPath
      .replace('security', `patches/?tab=patches-list&category=0fa1201d-4330-4fa8-8ae9-b877473b6441&${getSeverity(point.x)}&&${getDateRange(point.y)}`);
    navigate(newPath);
  }

  const highchartsConfig: Partial<Highcharts.Options> = {
    plotOptions: {
      series: {
        point: {
          events: {
            click: handlePointClick,
            mouseOver() {
              (this as any).graphic.element.style.cursor = 'pointer';
            },
            mouseOut() {
              (this as any).graphic.element.style.cursor = 'default';
            },
          },
        },
      },
    },
  }

  return (
    <DashboardChartCard
      title={t('DASHBOARD.SECURITY.MISSING_PATCHES')}
      tooltip={t('DASHBOARD.SECURITY.SECURITY_PATCHES_TOOLTIP')}
    >
      <HeatmapChart
        series={chartSeriesData}
        categoriesXAxis={xAxisCategories}
        categoriesYAxis={yAxisCategories}
        tooltip={t('DASHBOARD.SECURITY.NUMBER_OF_PATCHES')}
        highchartsConfig={highchartsConfig}
      />
    </DashboardChartCard>
  )
}
