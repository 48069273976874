import Highcharts from 'highcharts'
import { DashboardChartCard } from '../../../../components/Dashboard/DashboardChartCard/DashboardChartCard';
import { ColumnChart } from '../../../../components/Charts/ColumnChart/ColumnChart'
import { ReportingGroupsDashboard } from '../../../../query-client/types'
import { useTranslation } from 'react-i18next'

interface BarChartProps {
  items: ReportingGroupsDashboard['notApprovedPatchesInReportingGroupBySeverity']
}

export const NotApprovedPatchesBySeverityAndReportingGroupChart = (props: BarChartProps) => {
  const { t } = useTranslation('patch_management');

  const categories = Array.from(new Set(props.items.map((item) => item.reportingGroup)))
  const colors = ['#DD396A', '#FF6500', '#F4AE62', '#FFECCE', '#014140',]

  const buildSeries = () => {
    const series: Highcharts.SeriesColumnOptions[] = []
    const severities = ['Critical', 'Important', 'Moderate', 'Low', 'Unspecified']
    severities.forEach((severity, index) => {
      const data = props.items.filter((item) => item.severity === severity).map((item) => item.value)
      series.push({
        type: 'column',
        name: t(`DASHBOARD.SECURITY.${severity.toUpperCase()}`),
        color: colors[index],
        data,
      })
    })
    return series
  }

  return (
    <DashboardChartCard
      tooltip={(t("DASHBOARD.REPORTING_GROUPS.NOT_APPROVED_PATCHES_BY_SEVERITY"))}
      title={t("DASHBOARD.REPORTING_GROUPS.NOT_APPROVED_PATCHES_BY_SEVERITY")}
    >
      <ColumnChart series={buildSeries()} categories={categories}/>
    </DashboardChartCard>
  )
}
