import {
  CreateButton,
  EmptyStatus,
  MOBILE_QUERY,
  ViewDetailsActionButton,
  useMediaQuery, SearchBox,
} from '@flexxibleit/flexxible-ui'
import {
  ColumnActionsMode,
  DetailsListLayoutMode,
  IColumn,
  Icon,
  Link,
  SelectionMode,
  Stack,
} from '@fluentui/react'
import Table from 'app/components/Table/Table.component'
import useCreateForm from 'app/hooks/useCreateForm'
import { OrganizationPolicy } from 'app/query-client/types'
import { dateFormatter } from 'app/services/date-formatter'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { PolicyInfoForm } from './tabs/details/PolicyInfoForm'
import useGetProductTypes from 'app/hooks/product/useGetProductTypes'
import { useFeatureRender } from 'app/permissions/useFeatureRender'
import { FEATURE_NAMES } from 'app/permissions/FeatureName.enum'
import { withFeatureRender } from 'app/permissions/withFeatureRender'
import { CellWithFeatureLink } from 'app/components/Table/CellWithFeatureLink'
import { FeatureRender } from '../../../permissions/FeatureRender'

interface Props {
  organizationId?: string
  data: OrganizationPolicy[]
}

export const PoliciesFeature = ({ organizationId, data }: Props) => {
  const { t, i18n } = useTranslation('organization_details')
  const [isMobile] = useMediaQuery(MOBILE_QUERY)
  const navigate = useNavigate()
  const [textFilter, setTextFilter] = useState<string>()
  const [items, setItems] = useState(data)
  const { data: productData } = useGetProductTypes()
  const { checkFeature } = useFeatureRender()

  useEffect(() => {
    let filteredItems = data

    if (textFilter) {
      filteredItems = filteredItems.filter((item: OrganizationPolicy) => {
        return (
          item.name.toLowerCase().includes(textFilter.toLowerCase()) ||
          item.description?.toLowerCase().includes(textFilter.toLowerCase())
        )
      })
    }

    setItems(filteredItems)
  }, [data, textFilter])

  const _onFilter = (ev: React.ChangeEvent<HTMLInputElement> | undefined, text?: string): void => {
    setTextFilter(text)
  }

  const onCreate = useCreateForm(
    {
      title: t('POLICIES.FORM.CREATE_TITLE'),
      isFooterAtBottom: true,
    },
    <PolicyInfoForm organizationId={organizationId || ''} />
  )
  const onViewDetails = (policy: OrganizationPolicy) => {
    navigate(`/organization/${organizationId}/policy/${policy._id}`)
  }

  const tableActionsFeatures = [FEATURE_NAMES.POLICY_UPDATE]
  const canSeeActions = tableActionsFeatures.some((feature) => checkFeature(feature))

  const columns: IColumn[] = [
    {
      key: 'name',
      name: t('POLICIES.TABLE.NAME'),
      fieldName: 'name',
      minWidth: 100,
      maxWidth: 400,
      isRowHeader: true,
      isPadded: true,
      columnActionsMode: ColumnActionsMode.disabled,
      onRender: (item: OrganizationPolicy) => {
        return (
          <CellWithFeatureLink feature={FEATURE_NAMES.POLICY_UPDATE} callback={() => onViewDetails(item)}>
            {item.name}
          </CellWithFeatureLink>
        )
      },
    },
    {
      key: 'description',
      name: t('POLICIES.TABLE.DESCRIPTION'),
      fieldName: 'description',
      minWidth: 600,
      isRowHeader: true,
      isCollapsible: true,
      isPadded: true,
      columnActionsMode: ColumnActionsMode.disabled,
    },
    {
      key: 'product',
      name: t('POLICIES.TABLE.PRODUCT'),
      fieldName: 'product',
      minWidth: 200,
      isRowHeader: true,
      isCollapsible: true,
      isPadded: true,
      columnActionsMode: ColumnActionsMode.disabled,
      onRender: (item: OrganizationPolicy) => {
        if (item.product) {
          const product = productData?.find((p) => p._id === item.product)
          return <span>{product?.name}</span>
        }
      },
    },
    {
      key: 'created_at',
      name: t('POLICIES.TABLE.CREATION_DATE'),
      fieldName: 'created_at',
      minWidth: 100,
      isRowHeader: true,
      isCollapsible: true,
      onRender: (item: OrganizationPolicy) => {
        return <span>{dateFormatter(t, item.created_at)}</span>
      },
      isPadded: true,
      columnActionsMode: ColumnActionsMode.disabled,
    },
    ...(canSeeActions
      ? [
          {
            key: 'actions',
            name: t('POLICIES.TABLE.ACTION'),
            minWidth: isMobile ? 50 : 100,
            onRender: (item: OrganizationPolicy) => {
              return (
                <span className="d-flex d-flexRow">
                  <span style={isMobile ? { marginRight: 10 } : { marginRight: 20 }}>
                    {withFeatureRender(
                      ViewDetailsActionButton,
                      FEATURE_NAMES.POLICY_UPDATE
                    )({
                      locale: i18n.language,
                      isMobile: isMobile,
                      onClick: () => onViewDetails(item),
                    })}
                  </span>
                </span>
              )
            },
            isPadded: true,
            columnActionsMode: ColumnActionsMode.disabled,
          },
        ]
      : []),
  ]

  if (!data.length) {
    return (
      <div style={{ marginTop: 100 }}>
        <EmptyStatus
          message={t('POLICIES.EMPTY_MESSAGE')}
          img={<Icon iconName="EntitlementPolicy" style={{ fontSize: 68 }} />}
          {...(checkFeature(FEATURE_NAMES.POLICY_CREATE) && {
            buttonProps: {
              text: t('general:BUTTON.CREATE'),
              iconProps: { iconName: 'Add' },
              onClick: onCreate,
            },
          })}
        />
      </div>
    )
  }

  return (
    <div>
      <Stack
        horizontal={!isMobile}
        horizontalAlign="space-between"
        tokens={{ padding: '20px 0 0 0' }}
        className="mb-sm-2"
      >
        <Stack.Item grow={1} style={{ maxWidth: 400, minWidth: 196 }}>
          <SearchBox placeholder={t('POLICIES.SEARCH_PLACEHOLDER')} id="searchProduct" onChange={_onFilter} />
        </Stack.Item>

        <Stack.Item>
          <div
            className={`d-flex d-flexRow d-flexAlignItemsCenter ${isMobile ? 'd-flexJustifyBetween' : ''} `}
            style={isMobile ? { marginTop: 15 } : {}}
          >
            <FeatureRender feature={FEATURE_NAMES.POLICY_CREATE}>
              <CreateButton locale={i18n.language} onClick={onCreate} />
            </FeatureRender>
          </div>
        </Stack.Item>
      </Stack>

      <Table
        tableId="policies"
        isEmpty={items.length === 0}
        emptyMessage={t('general:EMPTY_FILTER')}
        tableProps={{
          columns,
          items,
          compact: false,
          selectionMode: SelectionMode.none,
          layoutMode: DetailsListLayoutMode.justified,
          isHeaderVisible: true,
        }}
      />
    </div>
  )
}
