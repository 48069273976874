import { DashboardMetricCard } from '../../../../../../components/Dashboard/DashboardMetricCard/DashboardMetricCard'
import { usePatchManagementSummaryData } from "../../usePatchManagementSummaryData";
import { Shimmer } from "@fluentui/react";

interface Props {
  isInfo?: boolean
}

export const MustBeSomeWorkspacesWithInstalledPatchesCard = (props: Props) => {
  const { data, isLoading } = usePatchManagementSummaryData()

  const totalWorkspacesWithPendingPatches = data?.workspacesCount.totalWorkspacesWithPendingPatches || 0
  const totalWorkspaces = data?.workspacesCount.total || 0

  if (isLoading) {
    return <Shimmer />
  }

  return (
    <DashboardMetricCard
      title={'Workspaces without pending patches'}
      subtitle={`${totalWorkspaces} total workspaces`}
      icon={{
        name: props.isInfo ? 'CheckMark' : 'Warning',
        color: props.isInfo ? 'green' : 'orange',
      }}
      value={totalWorkspacesWithPendingPatches}
    />
  )
}
