import { useQuery } from '@tanstack/react-query'
import graphClient from 'app/query-client/graphClient'
import { gql } from 'graphql-request'

const query = gql`
  query Request($businessId: ID!) {
    getBusinessProductConfigs(businessId: $businessId) {
      _id
      active
      environment
      region
      product_name
      created_at_date
    }
  }
`

function getBusinessProductConfigs(businessId: string) {
  return graphClient.request(query, { businessId }).then((response) => {
    return response.getBusinessProductConfigs || []
  })
}

export default function (businessId: string) {
  return useQuery(['getBusinessProductConfigs', businessId], () => getBusinessProductConfigs(businessId), {
    refetchOnWindowFocus: false,
    enabled: false,
  })
}
