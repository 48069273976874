import { ControlledToggleButton, RowColLayout } from '@flexxibleit/flexxible-ui'
import { TooltipInfo } from 'app/components/Tooltip/TooltipInfo'
import { WrapOverwriteAgentSettingsForm } from './WrapOverwriteAgentSettingsForm'

interface Props {
  overridable?: boolean
  name: string
  label: string
  tooltip: string
  isOverwrite?: boolean
  disabled?: boolean
  callbackOverwrite?: () => void
}

const AgentSettingsToggleButton = ({
  overridable = false,
  name,
  label,
  tooltip,
  isOverwrite = true,
  disabled = false,
  callbackOverwrite,
}: Props) => {
  const child = (
    <RowColLayout rowProps={{ classNames: 'mt-2' }}>
      <section style={{ display: 'flex', alignItems: 'center' }}>
        <ControlledToggleButton
          name={name}
          toggleProps={{
            inlineLabel: true,
            label: label,
            disabled: (overridable && !isOverwrite) || disabled,
          }}
        />
        {tooltip && <TooltipInfo text={tooltip} />}
      </section>
    </RowColLayout>
  )
  return overridable ? (
    <WrapOverwriteAgentSettingsForm
      onClick={() => callbackOverwrite && callbackOverwrite()}
      overWritable={!isOverwrite}
      disabled={disabled}
    >
      {child}
    </WrapOverwriteAgentSettingsForm>
  ) : (
    child
  )
}

export default AgentSettingsToggleButton
