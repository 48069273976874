import { FieldTypes, Filter, TableFilter as TableFilterFlexxUI } from "@flexxibleit/flexxible-ui";
import { useTranslation } from "react-i18next";

interface FilterField {
  field: string;
  text: string;
  type: FieldTypes;
}

interface TableFilterProps {
  filterFields: FilterField[];
  onFilter: (filteredItems: any[]) => void;
}

export const TableFilter = ({ onFilter, filterFields }: TableFilterProps) => {

  const { i18n } = useTranslation('microservices')
  return (
    <TableFilterFlexxUI locale={i18n.language} onFilter={(filters: Filter[]) => onFilter(filters)} {...{ filterFields }} />
  )
}
