import { gql } from 'graphql-request'
import graphClient from '../../query-client/graphClient'
import { useQuery } from '@tanstack/react-query'
import { Business } from '../../query-client/types'

const getOrganizationsForSelectionQuery = gql`
  query getBusiness($onlyActive: Boolean) {
    getBusiness(onlyActive: $onlyActive) {
      _id
      name
      type
      isTrial
      active
      uniqueCode
      modules {
        type
        url
      }
    }
  }
`

function getOrganizationsForSelection(onlyActive = false): Promise<Business[]> {
  return graphClient
    .request(getOrganizationsForSelectionQuery, { onlyActive })
    .then((response) => response.getBusiness)
    .then((organizations) =>
      organizations.sort((orgA: Business, orgB: Business) => {
        const nameA = orgA.name.toLowerCase()
        const nameB = orgB.name.toLowerCase()
        if (nameA > nameB) return 1
        if (nameB > nameA) return -1
        return 0
      })
    )
}

export function useGetOrganizationsForSelection(onlyActive = false) {
  return useQuery(['business', { forSelection: true, onlyActive }], () => getOrganizationsForSelection(onlyActive), {
    refetchOnWindowFocus: false,
  })
}
