import { TFunction } from 'react-i18next'

export enum DateFormat {
  /** Ex: 12/04/2023 */
  Short = 'SHORT',
  /** Ex: Nov 10, 2023 */
  Medium = 'MEDIUM',
  /** Ex: Nov 15 */
  MonthYear = 'MONTHYEAR',
  /** Ex: 1/1/23, 10:54:23 PM */
  DATETIME = 'DATETIME',
}

export const dateFormatter = (t: TFunction, date: Date | string, format: DateFormat = DateFormat.Short) => {
  if (!(date instanceof Date)) {
    date = new Date(+date)
  }

  return t('general:DATEFORMAT.' + format, { val: date })
}
