import { IDropdownOption } from '@fluentui/react'

const languages = [
  {
    key: 'es',
    text: 'Spanish',
  },
  {
    key: 'pt',
    text: 'Portuguese',
  },
  {
    key: 'en',
    text: 'English',
  },
  {
    key: 'ca',
    text: 'Catalan',
  },
  {
    key: 'eu',
    text: 'Basque',
  },
] as IDropdownOption[]

const languageCodes = [
  {
    key: 'es',
    value: 'es-ES',
  },
  {
    key: 'pt',
    value: 'pt-BR',
  },
  {
    key: 'en',
    value: 'en',
  },
  {
    key: 'ca',
    value: 'ca',
  },
  {
    key: 'eu',
    value: 'eu',
  },
]

export type LanguageKey = (typeof languages)[number]['key']

export function getLanguageText(key: LanguageKey | undefined): string {
  return languages.find((l) => l.key === key)?.text || 'Spanish'
}

export function getLanguageCode(key: LanguageKey | undefined): string {
  return languageCodes.find((l) => l.key === key)?.value || 'es-ES'
}

export default languages
