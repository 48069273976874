export const useUpdateWorkspaceGroupAddress = (apiKey: string) => {
  const fetchAddress = async (lat: number, lng: number) => {
    try {
      const response = await fetch(
        `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=${apiKey}`
      );
      const data = await response.json();
      const firstResult = data.results[0];
      return firstResult.formatted_address;
    } catch (error) {
      console.error('Error fetching data:', error);
      return 'Error fetching address';
    }
  };

  return { fetchAddress };
};


