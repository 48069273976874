import { TFunction } from 'i18next'

interface Props {
  t: TFunction
}

export const useActivationBuildExport = ({ t }: Props) => {
  const exportColumns = [
    { key: 'name', header: t('TENANTS_ACTIVATIONS.TABLE.NAME'), width: 20 },
    { key: 'product', header: t('TENANTS_ACTIVATIONS.TABLE.PRODUCT'), width: 20 },
    { key: 'type', header: t('TENANTS_ACTIVATIONS.TABLE.TYPE'), width: 20 },
    {
      key: 'productStatus',
      header: t('TENANTS_ACTIVATIONS.TABLE.STATUS'),
      width: 20,
    },
    {
      key: 'last90Days',
      header: t('TENANTS_ACTIVATIONS.TABLE.90_DAYS_AGO'),
      width: 20,
    },
    {
      key: 'last60Days',
      header: t('TENANTS_ACTIVATIONS.TABLE.60_DAYS_AGO'),
      width: 20,
    },
    {
      key: 'last30Days',
      header: t('TENANTS_ACTIVATIONS.TABLE.30_DAYS_AGO'),
      width: 20,
    },
    {
      key: 'last7Days',
      header: t('TENANTS_ACTIVATIONS.TABLE.7_DAYS_AGO'),
      width: 20,
    },
    {
      key: 'lastDay',
      header: t('TENANTS_ACTIVATIONS.TABLE.YESTERDAY'),
      width: 20,
    },
    {
      key: 'createdAt',
      header: t('TENANTS_ACTIVATIONS.TABLE.CREATED_AT'),
      width: 20,
    },
    {
      key: 'deleteAt',
      header: t('TENANTS_ACTIVATIONS.TABLE.DELETED_AT'),
      width: 20,
    },
  ]

  return { exportColumns }
}
