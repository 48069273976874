import { LoadingSpinner } from '@flexxibleit/flexxible-ui'
import { IPivotItemProps, MessageBar } from '@fluentui/react'
import { useBoolean } from '@fluentui/react-hooks'
import Dialog from 'app/components/Dialog/Dialog'
import IconLanguage from 'app/components/IconLanguage/IconLanguage'
import { UnsavedIcon } from 'app/components/Icons'
import { PRODUCT_ENV } from 'app/config/Consts'
import { ProductEnvTypes } from 'app/config/productEnv.enum'
import { MicroserviceTabsContext, MicroserviceTabsState } from 'app/context/MicroserviceTabsContext'
import { MicroserviceLicense } from 'app/pages/microservices/details/license/MicroserviceLicense'
import { MicroserviceDefaultTargetsTab } from 'app/pages/microservices/details/target-groups/MicroserviceDefaultTargetsTab'
import i18next from 'i18next'
import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import { StringParam, useQueryParam } from 'use-query-params'
import { FeedbackMessageBar } from '../../../components/FeedbackMessageBar/FeedbackMessageBar'
import PageBody from '../../../components/PageBody/PageBody.component'
import { TabPanel } from '../../../components/tab-panel/TabPanel'
import { MOBILE_QUERY } from '../../../config/media-query'
import { useOrganizationSelection } from '../../../globals/useOrganizationSelection'
import {
  MicroserviceView,
  useGetDesignerMicroserviceById,
} from '../../../hooks/microservices/useGetDesignerMicroserviceById'
import useMediaQuery from '../../../hooks/utils/useMediaQuery'
import { MicroserviceAction, getTranslation } from '../../../models/microservices'
import history, { Transition } from '../../../routing/history'
import { MicroserviceDetailsCondition } from './condition/MicroserviceDetailsCondition'
import { MicroserviceOverview } from './overview/MicroserviceOverview'
import { MicroserviceDetailsPrivacy } from './privacy-settings/MicroserviceDetailsPrivacy'
import { MicroserviceDetailsScript } from './script/MicroserviceDetailsScript'

export const MicroserviceDetailsPage = () => {
  const [isMobile] = useMediaQuery(MOBILE_QUERY)
  const { microserviceId } = useParams()
  const { t } = useTranslation('microservices')
  const navigate = useNavigate()
  const { selectedOrganizationId } = useOrganizationSelection()
  const {
    data: microservice,
    isLoading,
    error,
  } = useGetDesignerMicroserviceById(microserviceId!, selectedOrganizationId ?? '')
  const [tabsState, setTabsState] = useState<MicroserviceTabsState>({})
  const globalContextValue = useMemo(() => ({ state: tabsState, setState: setTabsState }), [tabsState, setTabsState])
  const [tabQueryParameter] = useQueryParam('tab', StringParam)
  const [hideDialog, { toggle: toggleHideDialog }] = useBoolean(true)
  const [modalCallback, setModalCallback] = useState<() => void>(() => () => {
    toggleHideDialog()
  })

  useEffect(() => {
    const isNotFound = (error as any)?.response?.errors?.some((e: any) => e.message === 'Microservice not found')

    if (isNotFound) {
      navigate('/microservices-designer')
    }
  }, [error])

  useEffect(() => {
    if (!tabsState || !Object.keys(tabsState).length) return

    const unblock = history.block((tx: Transition) => {
      const hasChanges = Object.keys(tabsState).length
      const isSamePage = tx.location.pathname === location.pathname
      if (!hasChanges || isSamePage) {
        unblock()
        tx.retry()
        return
      }

      setModalCallback(() => {
        return () => {
          unblock()
          tx.retry()
        }
      })

      toggleHideDialog()
    })

    return () => unblock()
  }, [history, tabsState, tabQueryParameter])

  if (!microservice) {
    return <LoadingSpinner />
  }

  const titleComponent = (microservice: MicroserviceView) => {
    const name = getTranslation(microservice.name, i18next.language)
    const itemName = name.text
    const showNameIcon = name.translatedByIA

    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'start',
        }}
      >
        {isMobile ? <h3>{itemName?.toString()}</h3> : <h1>{itemName?.toString()}</h1>}{' '}
        {showNameIcon && <IconLanguage tam="16" />}
        {PRODUCT_ENV !== ProductEnvTypes.FXXONE && (
          <div id="titleTagPage" className={`content-header__title-tag`}>
            PREVIEW
          </div>
        )}
      </div>
    )
  }

  const onRenderItemLink = (props?: IPivotItemProps) => {
    const hasUnsavedChanges = Object.keys(tabsState ?? []).some((key) => key === props?.itemKey)

    return (
      <span>
        <span>{props?.headerText}</span>
        {hasUnsavedChanges && <UnsavedIcon />}
      </span>
    )
  }

  const renderHasUnsavedChangesDialog = (): JSX.Element => {
    return (
      <Dialog
        title={t('DETAILS.UNSAVED_CHANGES_DIALOG.TITLE')}
        description={t('DETAILS.UNSAVED_CHANGES_DIALOG.DESCRIPTION')}
        actionButton={t('DETAILS.UNSAVED_CHANGES_DIALOG.BUTTON_ACCEPT')}
        dismissButton={t('DETAILS.UNSAVED_CHANGES_DIALOG.BUTTON_CANCEL')}
        hidden={hideDialog}
        onDismiss={toggleHideDialog}
        callback={modalCallback}
      >
        <MessageBar
          messageBarType={3}
          isMultiline={true}
          dismissButtonAriaLabel={t('general:CLOSE_LABEL')}
          className="mb-2"
          style={{ width: '100%' }}
        >
          {t('DETAILS.UNSAVED_CHANGES_DIALOG.WARNING')}
        </MessageBar>
      </Dialog>
    )
  }

  const name = getTranslation(microservice.name, i18next.language)
  const itemName = name.text
  return (
    <MicroserviceTabsContext.Provider value={globalContextValue}>
      <PageBody
        isLoading={isLoading}
        titleComponent={titleComponent(microservice)}
        title={itemName ? itemName.toString() : ''}
        breadcrumbItems={[
          {
            text: t('microservices:TITLE'),
            key: 'microservices',
            onClick: () => {
              navigate('/microservices-designer')
            },
          },
          { text: itemName ? itemName.toString() : '', key: 'current', isCurrentItem: true },
        ]}
      >
        <FeedbackMessageBar />
        <TabPanel
          pivotProps={{
            styles: {
              root: {
                marginTop: '10px',
              },
            },
          }}
          tabs={[
            {
              title: t('DETAILS.OVERVIEW_TAB'),
              key: 'overview',
              component: <MicroserviceOverview microservice={microservice} />,
            },
            ...(microservice.permissions.includes(MicroserviceAction.VIEW_SCRIPT)
              ? [
                  {
                    title: t('DETAILS.SCRIPT_TAB'),
                    key: 'script',
                    component: (
                      <MicroserviceDetailsScript
                        microservice={microservice}
                        canEdit={microservice.permissions.includes(MicroserviceAction.EDIT_SCRIPT)}
                      />
                    ),
                  },
                ]
              : []),
            ...(microservice.permissions.includes(MicroserviceAction.VIEW_PRIVACY)
              ? [
                  {
                    title: t('DETAILS.PRIVACY_TAB'),
                    key: 'privacy',
                    component: (
                      <MicroserviceDetailsPrivacy
                        microservice={microservice}
                        canEdit={microservice.permissions.includes(MicroserviceAction.SET_PRIVACY)}
                      />
                    ),
                  },
                ]
              : []),
            {
              title: t('DETAILS.TARGET_GROUP_TAB'),
              key: 'target-group',
              component: (
                <MicroserviceDefaultTargetsTab
                  microservice={microservice}
                  canEdit={microservice.permissions.includes(MicroserviceAction.EDIT_DEFAULT_TARGETS)}
                />
              ),
            },
            {
              title: t('DETAILS.LICENSE_TAB'),
              key: 'license',
              component: (
                <MicroserviceLicense
                  microserviceId={microservice._id}
                  license={microservice.license}
                  canEdit={microservice.permissions.includes(MicroserviceAction.EDIT_LICENSE)}
                />
              ),
            },
            ...(microservice.permissions.includes(MicroserviceAction.EDIT_CONDITION_SETTINGS)
              ? [
                  {
                    title: t('DETAILS.CONDITION_TAB'),
                    key: 'condition',
                    component: (
                      <MicroserviceDetailsCondition
                        microservice={microservice}
                        canEdit={microservice.permissions.includes(MicroserviceAction.EDIT_CONDITION_SETTINGS)}
                      />
                    ),
                  },
                ]
              : []),
          ]}
          onRenderItemLink={onRenderItemLink}
        />
      </PageBody>
      {renderHasUnsavedChangesDialog()}
    </MicroserviceTabsContext.Provider>
  )
}
