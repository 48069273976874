
import { FieldTypes, FilterField } from '@flexxibleit/flexxible-ui';
import { useLocalStorageSearchParams } from 'app/hooks/utils/useLocalStorageSearchParams';
import { TFunction } from 'i18next';

export const useEnabledMicroservicesFilters = ({ t }: { t: TFunction }) => {

  useLocalStorageSearchParams();

  const filterFields: FilterField[] = [
    {
      field: 'microserviceName',
      text: t('MARKETPLACE.TABLE.NAME'),
      type: FieldTypes.STRING
    },
    {
      field: 'category',
      text: t('MARKETPLACE.TABLE.CATEGORY'),
      type: FieldTypes.STRING
    },
    {
      field: 'organizationName',
      text: t('MARKETPLACE.TABLE.LIBRARY'),
      type: FieldTypes.STRING,
    },
  ];

  return {
    filterFields
  }
}