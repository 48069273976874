import { useQuery, UseQueryResult } from '@tanstack/react-query'
import { gql } from 'graphql-request'
import graphClient from '../../query-client/graphClient'
import { Business } from '../../query-client/types'

const businessByIdQuery = gql`
  query ($id: ID!) {
    getBusinessById(id: $id) {
      _id
      name
      country
      email
      type
      description
      language
      industry
      lastReport
      created_at
      active
      serviceNowCompanyId
      partner {
        _id
        name
      }
      productConfigs {
        _id
        created_at
        environment
        lastReportDate
        license
        active
        baseline
        serviceNowRelatedEnvironment
        product {
          _id
          name
          active
          fixedBaseline
        }
      }
      modules {
        _id
        type
        name
        tag
        url
        visibility
        order
      }
      reportingGroups {
        _id
      }
    }
  }
`

function getBusinessById(id: string): Promise<Business> {
  return graphClient.request(businessByIdQuery, { id }).then((r) => r.getBusinessById)
}

export function useOrganizationByIdForAllRoles(businessId: string): UseQueryResult<Business> {
  if (!businessId) {
    return useQuery(['getBusinessById', { forAllRoles: true }, businessId], () => undefined, {
      refetchOnWindowFocus: false,
      enabled: false,
    })
  }

  return useQuery(['getBusinessById', { forAllRoles: true }, businessId], () => getBusinessById(businessId), {
    refetchOnWindowFocus: false,
  })
}
