import { useQuery } from "@tanstack/react-query";
import { gql } from "graphql-request";
import graphClient from "../../query-client/graphClient";
import {WorkspaceGroupWithWorkspaceCount} from '../../query-client/types'

const workspaceGroupQuery = gql`
  query ($id: ID!) {
    getWorkspaceGroupById(id: $id) {
      workspaceGroup {
        _id
        name
        description
        business {
          _id
          name
          serviceNowCompanyId
          productConfigs {
            _id
            serviceNowRelatedEnvironment
          }
        }
        location {
          latitude
          longitude
          address
          description
        }
        created_by {
          name
          surname
        }
        manuallyAddedWorkspaces
        created_at
        type
        filter
        lastUpdated
      }
      workspaceCount
    }
  }
`

function useGetWorkspaceGroupById(id: string): Promise<WorkspaceGroupWithWorkspaceCount> {
  return graphClient
    .request(workspaceGroupQuery, { id })
    .then(response => response.getWorkspaceGroupById)
}

export default function(id: string) {
  return useQuery(['getWorkspaceGroupById', id], () => useGetWorkspaceGroupById(id), {
    refetchOnWindowFocus: false,
  })
}
