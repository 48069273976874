import { LoadingSpinner } from '@flexxibleit/flexxible-ui'
import PageBody from '../../components/PageBody/PageBody.component'
import { useTranslation } from 'react-i18next'
import { useMe } from '../../hooks/me/useMe'
import useMediaQuery from '../../hooks/utils/useMediaQuery'
import { MOBILE_QUERY } from '../../config/media-query'
import { UserInfoCard } from './components/UserInfoCard'
import { UserSettingsPanel } from './components/UserSettingsPanel'
import { useOrganizationSelection } from 'app/globals/useOrganizationSelection'

export function UserSettingsPage() {
  const { t } = useTranslation('user_settings')
  const [isMobile] = useMediaQuery(MOBILE_QUERY)
  const { data: user, isLoading } = useMe()
  const { rolesOnBusiness } = useOrganizationSelection()

  if (!user) {
    return <LoadingSpinner />
  }

  return (
    <PageBody isLoading={isLoading} title={t('TITLE')}>
      <div
        style={{
          display: 'flex',
          flexDirection: isMobile ? 'column' : 'row',
          alignItems: 'start',
          justifyContent: 'center',
          gap: '20px',
          margin: '40px 0',
          height: '80%',
        }}
      >
        <UserInfoCard user={user} portalRole={rolesOnBusiness?.portal} />
        <UserSettingsPanel user={user} />
      </div>
    </PageBody>
  )
}
