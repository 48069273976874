import PageBody from '../../../components/PageBody/PageBody.component'
import { FC, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useOrganizationSelection } from '../../../globals/useOrganizationSelection'
import { SecurityPoliciesDashboardFeature } from './SecurityPoliciesDashboard.feature'
import { withFeatureRender } from '../../../permissions/withFeatureRender'
import { FEATURE_NAMES } from '../../../permissions/FeatureName.enum'

const Security: FC = () => {
  const baseUrl = location.pathname.split('/')[1]
  const { t } = useTranslation('patch_management')
  const { organizationId } = useParams()
  const { selectedOrganization } = useOrganizationSelection()
  const navigate = useNavigate()

  useEffect(() => {
    if (!selectedOrganization) {
      return
    }
    if (selectedOrganization._id !== organizationId) {
      navigate(`/${baseUrl}/${selectedOrganization._id}/patch-management/security/`)
    }
  }, [selectedOrganization])

  return (
    <PageBody title={t('DASHBOARD.SECURITY.TITLE')} isLoading={false}>
      <SecurityPoliciesDashboardFeature organizationId={selectedOrganization?._id} />
    </PageBody>
  )
}

export const SecurityPage = withFeatureRender(Security, FEATURE_NAMES.PATCH_MANAGEMENT_READ)
