import { DatePicker as FluentuiDatePicker, IDatePickerProps, IDatePickerStrings, IconButton } from '@fluentui/react'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

interface DatePickerProps extends IDatePickerProps {
  onClear?: () => void
}

const DatePicker = (props: DatePickerProps) => {
  const { t } = useTranslation('general')

  const [showClear, setShowClear] = useState(false)

  const datePickerStrings: IDatePickerStrings = {
    months: [
      t('DATEPICKER.MONTHS.JANUARY', { defaultValue: 'January' }),
      t('DATEPICKER.MONTHS.FEBRUARY', { defaultValue: 'February' }),
      t('DATEPICKER.MONTHS.MARCH', { defaultValue: 'March' }),
      t('DATEPICKER.MONTHS.APRIL', { defaultValue: 'April' }),
      t('DATEPICKER.MONTHS.MAY', { defaultValue: 'May' }),
      t('DATEPICKER.MONTHS.JUNE', { defaultValue: 'June' }),
      t('DATEPICKER.MONTHS.JULY', { defaultValue: 'July' }),
      t('DATEPICKER.MONTHS.AUGUST', { defaultValue: 'August' }),
      t('DATEPICKER.MONTHS.SEPTEMBER', { defaultValue: 'September' }),
      t('DATEPICKER.MONTHS.OCTOBER', { defaultValue: 'October' }),
      t('DATEPICKER.MONTHS.NOVEMBER', { defaultValue: 'November' }),
      t('DATEPICKER.MONTHS.DECEMBER', { defaultValue: 'December' }),
    ],

    shortMonths: [
      t('DATEPICKER.SHORTMONTHS.JANUARY', { defaultValue: 'Jan' }),
      t('DATEPICKER.SHORTMONTHS.FEBRUARY', { defaultValue: 'Feb' }),
      t('DATEPICKER.SHORTMONTHS.MARCH', { defaultValue: 'Mar' }),
      t('DATEPICKER.SHORTMONTHS.APRIL', { defaultValue: 'Apr' }),
      t('DATEPICKER.SHORTMONTHS.MAY', { defaultValue: 'May' }),
      t('DATEPICKER.SHORTMONTHS.JUNE', { defaultValue: 'Jun' }),
      t('DATEPICKER.SHORTMONTHS.JULY', { defaultValue: 'Jul' }),
      t('DATEPICKER.SHORTMONTHS.AUGUST', { defaultValue: 'Aug' }),
      t('DATEPICKER.SHORTMONTHS.SEPTEMBER', { defaultValue: 'Sep' }),
      t('DATEPICKER.SHORTMONTHS.OCTOBER', { defaultValue: 'Oct' }),
      t('DATEPICKER.SHORTMONTHS.NOVEMBER', { defaultValue: 'Nov' }),
      t('DATEPICKER.SHORTMONTHS.DECEMBER', { defaultValue: 'Dec' }),
    ],

    days: [
      t('DATEPICKER.DAYS.SUNDAY', { defaultValue: 'Sunday' }),
      t('DATEPICKER.DAYS.MONDAY', { defaultValue: 'Monday' }),
      t('DATEPICKER.DAYS.TUESDAY', { defaultValue: 'Tuesday' }),
      t('DATEPICKER.DAYS.WEDNESDAY', { defaultValue: 'Wednesday' }),
      t('DATEPICKER.DAYS.THURSDAY', { defaultValue: 'Thursday' }),
      t('DATEPICKER.DAYS.FRIDAY', { defaultValue: 'Friday' }),
      t('DATEPICKER.DAYS.SATURDAY', { defaultValue: 'Saturday' }),
    ],

    shortDays: [
      t('DATEPICKER.SHORTDAYS.SUNDAY', { defaultValue: 'Sun' }),
      t('DATEPICKER.SHORTDAYS.MONDAY', { defaultValue: 'Mon' }),
      t('DATEPICKER.SHORTDAYS.TUESDAY', { defaultValue: 'Tue' }),
      t('DATEPICKER.SHORTDAYS.WEDNESDAY', { defaultValue: 'Wed' }),
      t('DATEPICKER.SHORTDAYS.THURSDAY', { defaultValue: 'Thu' }),
      t('DATEPICKER.SHORTDAYS.FRIDAY', { defaultValue: 'Fri' }),
      t('DATEPICKER.SHORTDAYS.SATURDAY', { defaultValue: 'Sat' }),
    ],

    goToToday: t('DATEPICKER.GO_TO_TODAY', { defaultValue: 'Go to today' }),
    prevMonthAriaLabel: t('DATEPICKER.PREV_MONTH_ARIA_LABEL', {
      defaultValue: 'Go to previous month',
    }),
    nextMonthAriaLabel: t('DATEPICKER.NEXT_MONTH_ARIA_LABEL', { defaultValue: 'Go to next month' }),
    prevYearAriaLabel: t('DATEPICKER.PREV_YEAR_ARIA_LABEL', { defaultValue: 'Go to previous year' }),
    nextYearAriaLabel: t('DATEPICKER.NEXT_YEAR_ARIA_LABEL', { defaultValue: 'Go to next year' }),
  }

  useEffect(() => {
    setShowClear(!!props.value)
  }, [props.value])

  const _onClear = () => {
    if (props.onClear) {
      props.onClear()
      setShowClear(false)
    }
  }

  return (
    <FluentuiDatePicker
      strings={datePickerStrings}
      {...props}
      textField={{
        onRenderSuffix: () =>
          showClear && props.onClear ? (
            <IconButton
              iconProps={{
                iconName: 'Cancel',
                styles: {
                  root: {
                    color: '#24292f',
                    fontSize: 12,
                    backgroundColor: 'white',
                  },
                },
              }}
              styles={{
                rootHovered: {
                  backgroundColor: 'white',
                  borderTop: '1px solid #8c9095',
                  borderBottom: '1px solid #8c9095',
                },
              }}
              onClick={_onClear}
            />
          ) : null,
        styles: {
          suffix: {
            padding: '0 4px',
            backgroundColor: 'white',
          },
        },
        style: { paddingLeft: '40px', paddingRight: '8px' },
      }}
      styles={{
        icon: { left: '8px', right: 'unset' },
      }}
    />
  )
}

export default DatePicker
