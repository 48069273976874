import { useNavigate } from 'react-router'
import { PaginatedEnabledMicroservicePageViewData } from './PaginatedEnabledMicroservicePageViewData'
import { ICommandItem } from '@flexxibleit/flexxible-ui'
import { TFunction } from 'i18next'

type useEnabledMicroservicesActionsProps = {
  t: TFunction
  onExportClicked: () => void
}

export const useEnabledMicroservicesActions = ({ t, onExportClicked }: useEnabledMicroservicesActionsProps) => {
  const navigate = useNavigate()

  const handleOnClickViewDetails = (item: PaginatedEnabledMicroservicePageViewData) => {
    navigate(`/microservices/${item._id}`, {
      state: { fromEnabledList: true },
    })
  }

  const commandBarActions: ICommandItem[] = [
    {
      key: 'export',
      text: t('general:BUTTON.EXPORT'),
      iconProps: { iconName: 'ExcelDocument' },
      onClick: onExportClicked,
    },
  ]

  return {
    handleOnClickViewDetails,
    commandBarActions,
  }
}
