import { gql } from 'graphql-request'
import graphClient from '../../query-client/graphClient'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { CreatePatchPolicyFormTargetType } from 'app/pages/patch-management/targets/components/CreatePatchPolicyTargetForm'

const createPatchPolicyTargetRequest = gql`
  mutation Request($organizationId: String!, $input: CreatePatchPolicyTargetGraphqlRequest!) {
    createPatchPolicyTarget(organizationId: $organizationId, input: $input) {
      _id
    }
  }
`

const createPatchPolicyTarget = async (patchPolicyTarget: CreatePatchPolicyFormTargetType, organizationId: string) => {
  const response = await graphClient.request(createPatchPolicyTargetRequest, {
    organizationId: organizationId,
    input: patchPolicyTarget,
  })

  return response.createPatchPolicyTarget
}

export const useCreatePatchPolicyTarget = (organizationId: string) => {
  const queryClient = useQueryClient()

  return useMutation(
    ['useCreatePatchPolicyTarget'],
    (patchPolicyTarget: CreatePatchPolicyFormTargetType) => createPatchPolicyTarget(patchPolicyTarget, organizationId),
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries(['getPatchPolicyTargetByOrganization'])
      },
    }
  )
}
