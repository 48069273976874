import { useMutation, useQueryClient } from '@tanstack/react-query'
import graphClient from 'app/query-client/graphClient'
import { gql } from 'graphql-request'

interface Request {
  microserviceId: string
  trigger: string
  conditions: {
    conditionTypeId: string
    metric: string
    operator: string
    compareTo: string
    checkEvery: number
    period: number | null
  }[]
}

const mutation = gql`
  mutation ($input: UpdateFlowFlowGraphqlRequest!, $id: ID!) {
    updateFlowFlow(id: $id, input: $input)
  }
`

const updateFlowFlow = async (id: string, input: Request): Promise<string> => {
  const response = await graphClient.request(mutation, { id, input })

  return id
}

export const useUpdateFlowFlow = () => {
  const queryClient = useQueryClient()

  return useMutation(
    ['updateFlowFlow'],
    ({ id, input }: { id: string; input: Request }) => updateFlowFlow(id, input),
    {
      onSuccess: (id) => {
        queryClient.invalidateQueries(['flow', id])
      },
    }
  )
}
