import { Module } from "app/query-client/types"
import { getModuleIcon } from "app/services/module-icon.service"
import { ModuleTypes } from "../../modules/module-form/types"


const NameCell = ({ module }: { module: Module }) => {

  const getModuleType = (): string => {
    const moduleType = ModuleTypes.find((type: any) => (type.key as string) === module.type)
    return moduleType?.text || ''
  }

  return (
    <span className="d-flex d-flexAlignItemsCenter">
      <span className="d-flex d-flexAlignItemsCenter" style={{ marginRight: 5}}>
        { getModuleIcon(module.type, 18, 18) }
      </span>
      <div>
        { module.type === 'custom' ? module.name : getModuleType() } { module.tag && ' - ' + module.tag }
      </div>
    </span>
  )
}

export default NameCell