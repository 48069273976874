import { PatchPolicy, ReportingGroup } from '../../../../query-client/types'
import { ColumnActionsMode, DetailsListLayoutMode, IColumn, SelectionMode } from '@fluentui/react'
import { Table } from '@flexxibleit/flexxible-ui'
import { useTranslation } from 'react-i18next'

interface PatchPolicyReportingGroupsTableProps {
  patchPolicy: PatchPolicy
}

export const PatchPolicyReportingGroupsTable = (props: PatchPolicyReportingGroupsTableProps) => {
  const { t } = useTranslation('patch_management')

  const reportingGroups: ReportingGroup[] = props.patchPolicy.reportingGroups

  const reportingGroupsColumns: IColumn[] = [
    {
      key: 'name',
      name: t('PATCH_POLICIES.SUMMARY.REPORTING_GROUPS_TABLE.NAME_COLUMN'),
      minWidth: 100,
      isRowHeader: true,
      isPadded: true,
      columnActionsMode: ColumnActionsMode.disabled,
      maxWidth: 200,
    },
  ]

  return (
    <Table
      tableProps={{
        items: reportingGroups,
        columns: reportingGroupsColumns,
        compact: false,
        selectionMode: SelectionMode.none,
        layoutMode: DetailsListLayoutMode.justified,
        isHeaderVisible: true,
      }}
    />
  )
}
