import { FC } from 'react'
import MDEditor from '@uiw/react-md-editor/nohighlight'
import './MarkdownEditor.scss'

interface MarkdownEditorProps {
  value: string | undefined
  onChange?: (value: string) => void
  readonly?: boolean
}

export const MarkdownEditor: FC<MarkdownEditorProps> = ({ value, onChange, readonly }) => {
  return (
    <>
      <div style={{ paddingBottom: '20px' }} data-color-mode="light">
        {!readonly ? (
          <MDEditor height="60vh" value={value} onChange={(value) => onChange?.(value ?? '')} />
        ) : (
          <MDEditor.Markdown style={{ paddingLeft: '10px' }} source={value ?? ''} />
        )}
      </div>
    </>
  )
}
