import { PrimaryButton } from '@fluentui/react'
import { useFormContext, useFormState } from 'react-hook-form'
import { TFunction } from 'react-i18next'
import Controlled from '../Controlled'
import { useRef, useState } from 'react'
import * as Excel from 'exceljs'

export interface CustomFileInputProps {
  name: string
  translator: TFunction
  required?: boolean
  onChange?: (event: React.ChangeEvent<HTMLInputElement> | null) => void
  styles?: React.CSSProperties
  allowedExtensions?: string[]
  setErrors?: string[]
}

const FileInput = ({
  name,
  translator,
  required = false,
  onChange,
  allowedExtensions,
  setErrors,
}: CustomFileInputProps) => {
  const { control } = useFormContext()
  const { errors } = useFormState({ control })
  const errorMessageKey: any = errors[name]?.message
  const [fileName, setFileName] = useState('')
  const [fileError, setFileError] = useState('')

  const fileInputRef = useRef<HTMLInputElement>(null)

  const handleFileInput = () => {
    fileInputRef.current?.click()
  }

  const handleFileChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files ? event.target.files[0] : null
    if (file) {
      const extension = file.name.split('.').pop()?.toLowerCase()
      if (!allowedExtensions || allowedExtensions.includes(extension || '')) {
        if (allowedExtensions && extension === 'xlsx') {
          try {
            const workbook = new Excel.Workbook()
            await workbook.xlsx.load(await file.arrayBuffer())
            setFileName(file.name)
            setFileError('')
            onChange && onChange(event)
          } catch (error) {
            setFileName('')
            setFileError(translator('general:FILE_INPUT.INVALID_EXTENSION'))
            onChange && onChange(null)
          }
          return
        }
        setFileName(file.name)
        setFileError('')
        onChange && onChange(event)
      } else {
        setFileName('')
        setFileError(translator('general:FILE_INPUT.INVALID_EXTENSION'))
        onChange && onChange(null)
      }
    } else {
      setFileName('')
      setFileError('')
      onChange && onChange(null)
    }
  }

  return (
    <>
      <Controlled
        name={name}
        render={() => (
          <>
            <div style={{ display: 'flex', alignItems: 'center', border: '1px solid black' }}>
              <div>
                <input
                  required={required}
                  type="file"
                  ref={fileInputRef}
                  style={{ display: 'none' }}
                  onChange={handleFileChange}
                />
                <PrimaryButton onClick={handleFileInput}>{translator('general:BUTTON.SELECT_FILE')}</PrimaryButton>
              </div>
              <div style={{ marginLeft: '10px' }}>
                {translator('general:FILE_INPUT.SELECTED_FILE')}:
                <span style={{ marginLeft: 10, marginRight: 10 }}>
                  {fileName ? <span>{fileName}</span> : translator('general:FILE_INPUT.NONE')}
                </span>
              </div>
            </div>
            {fileError && <div style={{ color: 'red' }}>{fileError}</div>}
            {errorMessageKey && <span style={{ color: 'red' }}>{translator(errorMessageKey)}</span>}
            {setErrors &&
              setErrors?.length > 0 &&
              setErrors.map((errorKey) => (
                <div key={errorKey} style={{ color: 'red' }}>
                  {translator(errorKey)}
                </div>
              ))}
          </>
        )}
      />
    </>
  )
}

export default FileInput
