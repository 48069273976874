import { ProductConfig } from '../../query-client/types'
import { HomeFeature } from './features/home.feature'
import { useTranslation } from 'react-i18next'
import PageBody from 'app/components/PageBody/PageBody.component'
import { useOrganizationSelection } from '../../globals/useOrganizationSelection'
import { useOrganizationByIdForAllRoles } from '../../hooks/organization/useOrganizationByIdForAllRoles'
import { useEffect, useState } from 'react'
import { useMe } from 'app/hooks/me/useMe'
import { PRODUCT_ENV } from 'app/config/Consts'
import { ProductEnvTypes } from 'app/config/productEnv.enum'

export const HomePage = () => {
  const { selectedOrganizationId } = useOrganizationSelection()
  const { data, isLoading } = useOrganizationByIdForAllRoles(selectedOrganizationId || '')
  const { t } = useTranslation('home')
  const { data: user } = useMe()
  const [canSeeDownload, setCanSeeDownload] = useState(false)

  useEffect(() => {
    if (!user || !data?.reportingGroups?.length || PRODUCT_ENV !== ProductEnvTypes.FXXONE) {
      setCanSeeDownload(false)
    } else {
      setCanSeeDownload(true)
    }
  }, [data, user])

  const filteredProducts: ProductConfig[] =
    data?.productConfigs.filter((product: ProductConfig) => product.active) || []

  return (
    <PageBody title={t('TITLE')} titleTag={data?.name} isLoading={isLoading}>
      <HomeFeature
        products={filteredProducts}
        modules={data?.modules}
        selectedOrganizationId={selectedOrganizationId}
        canSeeDownload={canSeeDownload}
      />
    </PageBody>
  )
}
