import { useMutation, useQueryClient } from '@tanstack/react-query'
import { MultiLanguageField } from 'app/models/microservices'
import graphClient from 'app/query-client/graphClient'
import { gql } from 'graphql-request'

interface UpdateFlowGraphqlRequest {
  name: MultiLanguageField[]
  description: MultiLanguageField[]
  detectionOnly: boolean
  cooldown: number
  language: string
}

const mutation = gql`
  mutation Request($input: UpdateFlowGraphqlRequest!, $id: ID!) {
    updateFlow(id: $id, input: $input)
  }
`

async function updateFlow(id: string, input: UpdateFlowGraphqlRequest) {
  await graphClient.request(mutation, { id, input })

  return id
}

export const useUpdateFlow = () => {
  const queryClient = useQueryClient()

  return useMutation(
    ['updateFlow'],
    ({ id, input }: { id: string; input: UpdateFlowGraphqlRequest }) => updateFlow(id, input),
    {
      onSuccess: (id) => {
        queryClient.invalidateQueries(['flows'])
        queryClient.invalidateQueries(['flow', id])
      },
    }
  )
}
