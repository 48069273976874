import { FormActionsContainer, LoadingSpinner } from '@flexxibleit/flexxible-ui'
import { zodResolver } from '@hookform/resolvers/zod'
import RowColLayout from 'app/components/Layouts/RowColLayout'
import FormActions from 'app/components/forms/FormActions'
import ControlledToggleButton from 'app/components/forms/ToggleButton/ToggleButton.component'
import Multiselect from 'app/components/forms/multiselect/multiselect'
import ErrorStatus from 'app/components/status/error-status'
import SuccessStatus from 'app/components/status/success-status'
import { useFeedbackMessage } from 'app/context/feedback-message/FeedbackMessageContext'
import { useOrganizationSelection } from 'app/globals/useOrganizationSelection'
import { useUpdateEnabledMicroserviceExecutionSettings } from 'app/hooks/microservices/useUpdateEnabledMicroserviceExecutionSettings'
import useWorkspaceGroupsByOrganization from 'app/hooks/workspaceGroup/useWorkspaceGroupsByOrganization'
import { FormProvider, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import * as z from 'zod'

interface FormData {
  enabled: boolean
  endUserExecutionWorkspaceGroups: string[]
}

interface Props {
  microserviceId: string
  initialData: FormData
}

const schema: z.ZodType<FormData> = z.object({
  enabled: z.boolean(),
  endUserExecutionWorkspaceGroups: z.array(z.string()),
})
  .superRefine((values, ctx) => {
    if (values.enabled && values.endUserExecutionWorkspaceGroups.length === 0) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: 'required',
        path: ['endUserExecutionWorkspaceGroups'],
      })
    }
  })

export const MicroserviceEndUserForm = ({ microserviceId, initialData }: Props) => {
  const { t } = useTranslation('microservices')
  const { selectedOrganizationId } = useOrganizationSelection()
  const { data } = useWorkspaceGroupsByOrganization(selectedOrganizationId ?? '')
  const { mutateAsync, isError, isSuccess, isLoading } = useUpdateEnabledMicroserviceExecutionSettings()
  const { setSuccessMessage, setErrorMessage } = useFeedbackMessage()

  const methods = useForm<FormData>({
    resolver: zodResolver(schema),
    defaultValues: initialData
  })
  const watchEnable = methods.watch('enabled')

  const onSubmit = (data: FormData) => {
    return mutateAsync({
      microserviceId: microserviceId,
      organizationId: selectedOrganizationId ?? '',
      input: {
        endUserExecutionWorkspaceGroups: data.enabled ? data.endUserExecutionWorkspaceGroups : [],
      },
    })
    .then(() => {
      setSuccessMessage(t('DESIGNER.MICROSERVICE_FORM.UPDATE_SUCCESS'))
    })
    .catch(() => setErrorMessage(t('DESIGNER.MICROSERVICE_FORM.UPDATE_ERROR')))
  }

  const itemsWithKey = (data ?? []).map((item) => ({ key: item.workspaceGroup._id, text: item.workspaceGroup.name }))

  if (isLoading) return <LoadingSpinner />

  if (isError) {
    return (
      <div className="d-flex h-100">
        <ErrorStatus message={t('DESIGNER.MICROSERVICE_FORM.UPDATE_ERROR')} />
      </div>
    )
  }
  if (isSuccess) {
    return (
      <div className="d-flex h-100">
        <SuccessStatus message={t('DESIGNER.MICROSERVICE_FORM.UPDATE_SUCCESS')} />
      </div>
    )
  }

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <RowColLayout rowProps={{ classNames: 'mt-2' }}>
          <ControlledToggleButton
            name="enabled"
            toggleProps={{
              inlineLabel: true,
              label: t('DETAILS.EXECUTION.END_USER_INPUT_LABEL'),
              onText: t('MICROSERVICE_STATUS.ACTIVE'),
              offText: t('MICROSERVICE_STATUS.INACTIVE'),
            }}
          />
        </RowColLayout>
        {watchEnable && (
          <RowColLayout rowProps={{ classNames: 'mt-2' }}>
            <Multiselect
              name="endUserExecutionWorkspaceGroups"
              options={itemsWithKey}
              translator={t}
              multiselectProps={{
                label: t('DETAILS.EXECUTION.ENDUSER_EXECUTION_WORKSPACE_GROUPS_INPUT_LABEL'),
                placeholder: t('DETAILS.EXECUTION.ENDUSER_EXECUTION_WORKSPACE_GROUPS_INPUT_PLACEHOLDER'),
              }}
              selectedList={initialData.endUserExecutionWorkspaceGroups}
              styles={{
                dropdownItemSelected: { height: 50 },
                dropdownItem: { height: 50 },
              }}
            />
          </RowColLayout>
        )}
        <FormActionsContainer>
          <FormActions
            submitProps={{
              text: t('general:BUTTON.SAVE'),
              iconProps: { iconName: 'Save' },
            }}
          />
        </FormActionsContainer>
      </form>
    </FormProvider>
  )
}
