import { FieldTypes, FilterField } from '@flexxibleit/flexxible-ui'
import { useLocalStorageSearchParams } from 'app/hooks/utils/useLocalStorageSearchParams'
import { FlowType, typeLabel } from 'app/models/flows/FlowType'
import { TFunction } from 'react-i18next'

type Props = {
  t: TFunction
}

export const useFlowsFilters = ({ t }: Props) => {
  useLocalStorageSearchParams()

  const filterFields: FilterField[] = [
    {
      field: 'nameText',
      text: t('TABLE.NAME'),
      type: FieldTypes.STRING,
    },
    {
      field: 'descriptionText',
      text: t('TABLE.DESCRIPTION'),
      type: FieldTypes.STRING,
    },
    {
      field: 'type',
      text: t('TABLE.TYPE'),
      type: FieldTypes.ENUM,
      options: Object.values(FlowType).map(type => {
        return {
          key: type,
          text: typeLabel(t)[type]
        }
      })
    },
    {
      field: 'detectionOnly',
      text: t('TABLE.DETECTIONONLY'),
      type: FieldTypes.BOOLEAN,
      options: [
        { key: 'true', text: t('general:YES') },
        { key: 'false', text: t('general:NO') },
      ],
    },
  ]

  return {
    filterFields,
  }
}
