import { useMutation, useQueryClient } from '@tanstack/react-query'
import graphClient from '../../query-client/graphClient'
import { gql } from 'graphql-request'
import { MicroserviceFormType } from '../../pages/microservices/components/MicroserviceForm'

interface UpdateMicroservicePayload {
  _id: string
}

interface Response {
  updateMicroservice: UpdateMicroservicePayload
}

const updateMicroserviceQuery = gql`
  mutation ($input: UpdateMicroserviceGraphqlRequest!, $microserviceId: ID!, $defaultLang: String!) {
    updateMicroservice(id: $microserviceId, input: $input, defaultLang: $defaultLang) {
      _id
    }
  }
`

const updateMicroservice = async (
  microserviceId: string,
  input: MicroserviceFormType,
  defaultLang: string
): Promise<UpdateMicroservicePayload> => {
  const response: Response = await graphClient.request(updateMicroserviceQuery, {
    microserviceId,
    input,
    defaultLang
  })
  return response.updateMicroservice
}

export const useUpdateMicroservice = () => {
  const queryClient = useQueryClient()

  return useMutation(
    ['updateMicroservice'],
    ({ microserviceId, input, defaultLang }: { microserviceId: string; input: MicroserviceFormType, defaultLang: string }) =>
      updateMicroservice(microserviceId, input, defaultLang),
    {
      onSuccess: (microserviceId) => {
        queryClient.invalidateQueries(['microservices'])
        queryClient.invalidateQueries(['microservices', microserviceId])
      },
    }
  )
}
