import { HighchartsReact } from 'highcharts-react-official'
import Highcharts from 'highcharts/highstock'


interface Props {
  series: Highcharts.SeriesBarOptions[];
  categories?: string[];
  highchartsConfig?: Partial<Highcharts.Options>;
}

export const BarChart = (props: Props) => {
  const chartOptions: Highcharts.Options = {
    chart: {
      type: 'bar',
      numberFormatter: (value: number) => value.toLocaleString(),
    },
    title: {
      text: ''
    },
    credits: {
      enabled: false,
    },
    legend: {
      enabled: true,
    },
    xAxis: {
      categories: props.categories || [],
    },
    yAxis: {
      title: {
        text: '',
      },
    },
    series: props.series,
    ...props.highchartsConfig
  }

  return (
    <HighchartsReact
      highcharts={Highcharts}
      options={chartOptions}
    />
  )
}
