import { t, TFunction } from 'i18next'
import { ICommandItem } from '@flexxibleit/flexxible-ui'
import { useNavigate } from 'react-router-dom'
import { InstalledApp } from './InstalledApp'

interface UseInstalledAppsActionProps {
  t: TFunction;
  onExportClicked: () => Promise<void>
}

export const useInstalledAppsActions = ({ onExportClicked }: UseInstalledAppsActionProps) => {
  const navigate = useNavigate()
  
  const openInstalledAppDetails = (installedApp: InstalledApp) => {
    navigate(`/analyzer/installed-apps/${installedApp.id}`)
  }
  
  const commandBarActions: ICommandItem[] = [
    {
      key: 'export',
      text: t('general:BUTTON.EXPORT'),
      iconProps: { iconName: 'ExcelDocument' },
      onClick: onExportClicked,
    },
  ]

  return { commandBarActions, openInstalledAppDetails }
}