import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import { useEffect, useState } from 'react'
import { ExplorerContent } from './explorer/explorer-content.feature'
import { Business, ProductConfig } from '../../query-client/types'
import { withFeatureRender } from '../../permissions/withFeatureRender'
import { FEATURE_NAMES } from '../../permissions/FeatureName.enum'
import PageBody from 'app/components/PageBody/PageBody.component'
import { IBreadcrumbItem } from '@fluentui/react'
import { useOrganizationSelection } from '../../globals/useOrganizationSelection'
import { useOrganizationByIdForAllRoles } from '../../hooks/organization/useOrganizationByIdForAllRoles'

const ReportsExplorer = () => {
  const { productId } = useParams()
  const { selectedOrganizationId } = useOrganizationSelection()

  const { isLoading, data, isFetching } = useOrganizationByIdForAllRoles(selectedOrganizationId || '')

  const navigate = useNavigate()
  const { t } = useTranslation('reports')

  const [productConfig, setProductConfig] = useState<any>()
  const [isConfigured, setConfigured] = useState(false)

  useEffect(() => {
    if (!isLoading && !isFetching) {
      const product: ProductConfig | undefined = findProductFromOrganization(data as Business, productId || '')
      if (product) {
        setProductConfig(findProductFromOrganization(data as Business, productId || ''))
        setConfigured(true)
      } else {
        navigate('/products')
      }
    }
  }, [isLoading, isFetching, productId])

  const onBreadcrumbItemClicked = (event?: React.MouseEvent, item?: IBreadcrumbItem) => {
    navigate(item?.key ?? '/home')
  }

  const findProductFromOrganization = (organization: Business, productId: string): ProductConfig | undefined => {
    return organization.productConfigs.find((product) => product._id === productId)
  }

  const itemsUser: IBreadcrumbItem[] = [
    { text: t('BC_PRODUCTS'), key: '/products', onClick: onBreadcrumbItemClicked },
    { text: productConfig?.product?.name || '', key: 'current', isCurrentItem: true },
  ]

  return (
    <>
      {isConfigured && selectedOrganizationId && (
        <PageBody title={t('TITLE')} breadcrumbItems={itemsUser} isLoading={isLoading || isFetching}>
          <ExplorerContent
            key={'explorer'}
            isAdmin={false}
            organizationId={selectedOrganizationId}
            organization={data || ({} as Business)}
            product={productConfig}
          />
        </PageBody>
      )}
    </>
  )
}

export const ReportsExplorerPage = withFeatureRender(ReportsExplorer, FEATURE_NAMES.REPORTS_READ)
