import { useQuery } from '@tanstack/react-query'
import graphClient from '../../query-client/graphClient'
import { gql } from 'graphql-request'
import { MicroserviceOperatingSystem, MultiLanguageField } from '../../models/microservices'
import { MicroserviceScope } from '../../models/microservices'
import { MicroserviceLanguage } from '../../models/microservices'
import { MicroserviceContext } from 'app/models/microservices/MicroserviceContext'
import { MicroserviceTargetGroup } from 'app/models/microservices/MicroserviceTargetGroup'
import { MicroserviceAction } from '../../models/microservices'
import { ValueType } from 'app/models/flows/ValueType'
import { ConditionEnvironment } from 'app/models/condition/ConditionEnvironment'

export interface MicroserviceView {
  _id: string
  created_at: string
  name: MultiLanguageField[]
  description: MultiLanguageField[]
  iconName: string
  iconBackground: string
  readme: string
  script: string
  author: {
    name: string
    surname: string
  }
  business: {
    _id: string
  }
  operatingSystem: MicroserviceOperatingSystem[]
  language: MicroserviceLanguage
  version: number
  scope: MicroserviceScope[]
  context?: MicroserviceContext
  category: MultiLanguageField[] | null
  efficiency: number
  active: boolean
  permissions: MicroserviceAction[]
  privacySettings: {
    isPrivate: boolean
    visibleTo: string[]
  }
  license: {
    type: string
    year: number
    holder: string
  }
  workspaceGroupExecution: MicroserviceTargetGroup
  individualExecution: MicroserviceTargetGroup
  condition?: {
    _id: string
    valueType: ValueType
    environment: ConditionEnvironment
  }
}

interface Response {
  getDesignerMicroserviceById: MicroserviceView
}

const getDesignerMicroservicesQuery = gql`
  query ($id: ID!, $businessId: ID!) {
    getDesignerMicroserviceById(id: $id, businessId: $businessId) {
      _id
      created_at
      name {
        code
        text
        translatedByIA
      }
      description {
        code
        text
        translatedByIA
      }
      iconName
      iconBackground
      readme
      script(id: $id, businessId: $businessId)
      author {
        name
        surname
      }
      business {
        _id
      }
      operatingSystem
      language
      version
      scope
      context
      category {
        code
        text
        translatedByIA
      }
      efficiency
      active
      permissions
      license {
        type
        year
        holder
      }
      privacySettings(id: $id, businessId: $businessId) {
        isPrivate
        visibleTo
      }
      workspaceGroupExecution
      individualExecution
      type
      condition {
        _id
        valueType
        environment
      }
    }
  }
`

export async function getDesignerMicroservicesById(id: string, businessId: string): Promise<MicroserviceView> {
  const response: Response = await graphClient.request(getDesignerMicroservicesQuery, { id, businessId })
  return response.getDesignerMicroserviceById
}

export const useGetDesignerMicroserviceById = (id: string, businessId: string) => {
  return useQuery(['microservices', id, businessId], () => getDesignerMicroservicesById(id, businessId))
}
