import { useEffect, useRef, useState } from 'react'
import {
  Col,
  ErrorStatus,
  FlexxiblePrimaryButton,
  FlexxibleSecondaryButton,
  LoadingSpinner,
  Row,
} from '@flexxibleit/flexxible-ui'
import { IDropdownOption, SelectableOptionMenuItemType } from '@fluentui/react'
import { theme } from '../../../../config/theme'
import { PatchPolicyTarget, Schedule, timezoneOptions, WeekDay } from '../../../../query-client/types'
import { useTranslation } from 'react-i18next'
import { z } from 'zod'
import { zodResolver } from '@hookform/resolvers/zod'
import { FormProvider, useForm } from 'react-hook-form'
import { SearchableDropdown } from 'app/components/forms/SearchableDropdown/SearchableDropdown'
import Multiselect from 'app/components/forms/multiselect/multiselect'
import { useUpdatePatchPolicyTarget } from 'app/hooks/patchPolicyTarget/useUpdatePatchPolicyTarget'
import SelectionArea, { SelectionEvent } from '@viselect/react'

interface PatchPolicyTargetSchedulerEditModeProps {
  patchPolicyTarget: PatchPolicyTarget
  onClickCancel: () => void
  onClickSave: (patchPolicyTarget: PatchPolicyTarget) => void
}

interface ScheduleFormData {
  weeks: string[]
  timezone: string
}

const schema = z.object({
  weeks: z.array(z.string()).nonempty({ message: 'required' }),
  timezone: z.string({ required_error: 'required' }).min(1, { message: 'required' }),
})

export const PatchPolicyTargetSchedulerEditMode = (props: PatchPolicyTargetSchedulerEditModeProps) => {
  const { t } = useTranslation('patch_management')
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [isError, setIsError] = useState<boolean>(false)
  const updatePatchPolicyTarget = useUpdatePatchPolicyTarget()

  const isInicialitedSchedule = useRef<boolean>(false)

  const setScheduleToList = (setSchedule: Set<number>) => {
    const listSelectedSchedule = Array.from(setSchedule)
    let newSchedule: Schedule = {
      [WeekDay.MONDAY]: {},
      [WeekDay.TUESDAY]: {},
      [WeekDay.WEDNESDAY]: {},
      [WeekDay.THURSDAY]: {},
      [WeekDay.FRIDAY]: {},
      [WeekDay.SATURDAY]: {},
      [WeekDay.SUNDAY]: {},
    }
    for (const key of listSelectedSchedule) {
      const day = Math.floor(key / (24 * 4))
      const hour = Math.floor((key % (24 * 4)) / 4)
      const minute = (key % (24 * 4)) % 4
      const dayName = Object.values(WeekDay)[day]
      if (!newSchedule[dayName]) {
        newSchedule[dayName] = {}
      }
      if (!newSchedule[dayName][hour]) {
        newSchedule[dayName][hour] = []
      }
      newSchedule[dayName][hour].push(minute * 15)
    }
    return newSchedule
  }

  const listScheduleToSet = (schedule: Schedule) => {
    let newSetSchedule = new Set<number>()
    for (const day in schedule) {
      const dayIndex = Object.values(WeekDay).indexOf(day as WeekDay)
      for (const hour in schedule[day as WeekDay]) {
        const hourIndex = parseInt(hour)
        if (!schedule[day as WeekDay][hour]) {
          continue
        }
        for (const minute of schedule[day as WeekDay][hour]) {
          const minuteIndex = minute / 15
          newSetSchedule.add(dayIndex * 24 * 4 + hourIndex * 4 + minuteIndex)
        }
      }
    }
    return newSetSchedule
  }

  const [selectedSchedule, setSelectedSchedule] = useState<Set<number>>(
    listScheduleToSet(props.patchPolicyTarget.schedule)
  )

  const extractIds = (els: Element[]): number[] =>
    els
      .map((v) => v.getAttribute('data-key'))
      .filter(Boolean)
      .map(Number)

  const onStart = ({ event, selection }: SelectionEvent) => {
    if (!event?.ctrlKey && !event?.metaKey && !isInicialitedSchedule.current) {
      const listElements = Array.from(selectedSchedule)
        .map((key) => document.querySelector(`[data-key="${key}"]`))
        .filter(Boolean) as Element[]
      selection.select(listElements)
      isInicialitedSchedule.current = true
    }
  }

  const onMove = ({
    store: {
      changed: { added, removed },
    },
    selection,
  }: SelectionEvent) => {
    setSelectedSchedule((prev) => {
      const next = new Set(prev)

      extractIds(added).forEach((id) => next.add(id))
      extractIds(removed).forEach((id) => next.delete(id))

      selection.resolveSelectables()
      return next
    })
  }

  const getDefaultValues = (): ScheduleFormData => {
    return {
      weeks: props.patchPolicyTarget.weeks.map((w) => w.toString()),
      timezone: props.patchPolicyTarget.timezone,
    }
  }

  const methods = useForm<ScheduleFormData>({
    resolver: zodResolver(schema),
    defaultValues: { ...getDefaultValues() },
  })

  const timeGapSizeInMinutes = 15

  const dailyHours = Array.from({ length: 24 }, (_, i) => i)

  const weeksOptions: IDropdownOption[] = [
    { key: 'selectAll', text: t('SCHEDULE.WEEKS.ALL'), itemType: SelectableOptionMenuItemType.SelectAll },
    { key: '1', text: `${t('SCHEDULE.WEEKS.WEEK')} 1` },
    { key: '2', text: `${t('SCHEDULE.WEEKS.WEEK')} 2` },
    { key: '3', text: `${t('SCHEDULE.WEEKS.WEEK')} 3` },
    { key: '4', text: `${t('SCHEDULE.WEEKS.WEEK')} 4` },
    { key: '5', text: `${t('SCHEDULE.WEEKS.WEEK')} 5` },
  ]

  const onSubmit = (data: ScheduleFormData) => {
    const schedule = setScheduleToList(selectedSchedule)
    const editedPatchPolicyTarget: PatchPolicyTarget = {
      ...props.patchPolicyTarget,
      schedule,
      weeks: data.weeks.map((w) => parseInt(w)),
      timezone: data.timezone,
    }

    updatePatchPolicyTarget
      .mutateAsync({
        id: props.patchPolicyTarget._id,
        input: {
          name: editedPatchPolicyTarget.name,
          reportingGroups: editedPatchPolicyTarget.reportingGroups.map((rg) => rg._id),
          shouldRestart: editedPatchPolicyTarget.shouldRestart,
          schedule: JSON.stringify(editedPatchPolicyTarget.schedule),
          weeks: editedPatchPolicyTarget.weeks,
          timezone: editedPatchPolicyTarget.timezone,
          maxSimultaneousWorkspaces: editedPatchPolicyTarget.maxSimultaneousWorkspaces,
          isInMaintenance: editedPatchPolicyTarget.isInMaintenance,
          windowsPatchPolicyId: editedPatchPolicyTarget.windowsPatchPolicyId,
          forcePatchingConfiguration: editedPatchPolicyTarget.forcePatchingConfiguration,
          wakeOnLAN: editedPatchPolicyTarget.wakeOnLAN,
        },
      })
      .then(() => {
        props.onClickSave(editedPatchPolicyTarget)
      })
  }

  useEffect(() => {
    setIsError(updatePatchPolicyTarget.isError)
    setIsLoading(updatePatchPolicyTarget.isLoading)
  }, [updatePatchPolicyTarget])

  if (isLoading) {
    return <LoadingSpinner />
  }

  if (isError) {
    return (
      <div
        style={{
          marginTop: '25px',
        }}
      >
        <ErrorStatus message={t('ERRORS.UPDATE_SCHEDULER')} />
        <FlexxiblePrimaryButton
          onClick={() => {
            window.location.reload()
          }}
        >
          {t('PATCH_POLICIES_TARGET.TRY_AGAIN')}
        </FlexxiblePrimaryButton>
      </div>
    )
  }

  return (
    <>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <div
            style={{
              display: 'flex',
              marginBottom: '16px',
              justifyContent: 'end',
              gap: '10px',
              marginTop: '16px',
            }}
          >
            <FlexxibleSecondaryButton onClick={props.onClickCancel}>
              {t('general:BUTTON.CANCEL')}
            </FlexxibleSecondaryButton>
            <FlexxiblePrimaryButton type="submit">{t('general:BUTTON.SAVE')}</FlexxiblePrimaryButton>
          </div>
          <Row>
            <Col className="col-xs-12 col-lg-6">
              <Multiselect
                name="weeks"
                translator={t}
                options={weeksOptions}
                selectedList={props.patchPolicyTarget.weeks.map((w) => w.toString())}
                multiselectProps={{
                  label: t('SCHEDULE.FORM.WEEKS'),
                  placeholder: t('SCHEDULE.FORM.WEEKS_PLACEHOLDER'),
                  required: true,
                }}
              />
            </Col>
            <Col className="col-xs-12 col-lg-6">
              <SearchableDropdown
                label={t('SCHEDULE.FORM.TIMEZONE')}
                name="timezone"
                required={true}
                defaultSelectedKey={props.patchPolicyTarget && props.patchPolicyTarget.timezone}
                translator={t}
                placeholder={t('SCHEDULE.FORM.TIMEZONE_PLACEHOLDER')}
                searchBarPlaceholder={t('SCHEDULE.FORM.SEARCH_TIMEZONE_PLACEHOLDER')}
                options={timezoneOptions}
                calloutProps={{ calloutMaxHeight: 400 }}
              />
            </Col>
          </Row>
          <SelectionArea onStart={onStart} onMove={onMove} selectables=".selectable">
            <div
              style={{
                overflowX: 'auto',
                marginTop: '25px',
                scrollbarWidth: 'thin',
                scrollbarColor: `#ccc ${theme.palette?.neutralLighterAlt}`,
              }}
            >
              <table
                style={{
                  borderCollapse: 'collapse',
                  border: '1px solid #ccc',
                  borderRadius: '15px',
                }}
              >
                <thead
                  style={{
                    backgroundColor: '#f0f0f0',
                  }}
                >
                  <tr>
                    <th
                      style={{
                        position: 'sticky',
                        left: 0,
                        backgroundColor: '#f0f0f0',
                      }}
                    ></th>
                    {dailyHours.map((hour, index) => (
                      <th
                        key={index}
                        colSpan={60 / timeGapSizeInMinutes}
                        style={{
                          border: '1px solid #ccc',
                          padding: '0.5rem',
                          margin: '0.5rem 0',
                          userSelect: 'none',
                        }}
                      >
                        {hour}h
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {Object.values(WeekDay).map((day, index) => (
                    <tr
                      key={index}
                      style={{
                        backgroundColor: '#f9f9f9',
                      }}
                    >
                      <td
                        style={{
                          padding: '0.5rem',
                          margin: '0.5rem 0',
                          fontWeight: 'bold',
                          position: 'sticky',
                          left: 0,
                          backgroundColor: '#f0f0f0',
                          userSelect: 'none',
                        }}
                      >
                        {day.charAt(0) + day.slice(1).toLowerCase()}
                      </td>
                      {dailyHours.map((hour) => {
                        const cellCount = 60 / timeGapSizeInMinutes
                        const partialKey = index * dailyHours.length * cellCount + hour * cellCount
                        return Array.from({ length: cellCount }, (_, i) => (
                          <td
                            key={`schedule-${partialKey + i}`}
                            style={{
                              border: '1px solid #ccc',
                              padding: '0.5rem',
                              margin: '0.5rem 0',
                              backgroundColor: selectedSchedule.has(partialKey + i) ? 'var(--theme-primary)' : 'white',
                              cursor: 'pointer',
                            }}
                            data-key={partialKey + i}
                            className={selectedSchedule.has(partialKey + i) ? 'selected selectable' : 'selectable'}
                          ></td>
                        ))
                      })}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </SelectionArea>
        </form>
      </FormProvider>
    </>
  )
}
