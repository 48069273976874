import './content-header.scss'
import useMediaQuery from 'app/hooks/utils/useMediaQuery'
import { MOBILE_QUERY } from 'app/config/media-query'

interface Props {
  title?: string
  titleTag?: string
}

export const ContentHeader = ({title, titleTag}: Props) => {
  const [ isMobile ] = useMediaQuery(MOBILE_QUERY)
  return (
    <header className="content-header">
      <section className="content-header__content d-flex d-flexAlignItemsCenter">
        { title && <div id='titlePage' className={`content-header__title${isMobile ? ' mobile' : ''}`}>{title}</div> }
        { titleTag && <div id='titleTagPage' className={`content-header__title-tag`}>{titleTag}</div> }
      </section>
    </header>
  )
}
