import { useState, useMemo, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Dropdown, Link } from '@fluentui/react'
import { Col, Row, RowColLayout } from '@flexxibleit/flexxible-ui'
import Table from '../../../components/Table/Table.component'
import { DateFormat, dateFormatter } from '../../../services/date-formatter'
import useGetAvailableWindowsPatches from '../../../hooks/patches/useGetAvailableWindowsPatches'
import useGetWindowsProducts from '../../../hooks/patchPolicies/useGetWindowsProducts'
import useGetWindowsPatchCategories from '../../../hooks/patchPolicies/useGetWindowsPatchCategories'
import { PaginationArgs, WindowsPatches, WindowsPatchFilter } from '../../../query-client/types'
import { SearchableDropdown } from '../../../components/SearchableDropdown/SearchableDropdown.component'
import { useLocation } from 'react-router-dom'

export const PatchesListFeature = () => {
  const { t } = useTranslation('patch_management')
  const location = useLocation();

  const [paginationState, setPaginationState] = useState<PaginationArgs>(() => ({
    page: 1,
    perPage: 20,
  }))

  const [filter, setFilter] = useState<WindowsPatchFilter>({
    categories: [],
    severity: [],
    products: [],
    creationDate: {
      start: null,
      end: null
    }
  })

  const [selectedCategory, setSelectedCategory] = useState<any>([])
  const [selectedProduct, setSelectedProduct] = useState<any>([])
  const [selectedSeverity, setSelectedSeverity] = useState<any>([])
  const [selectedDateRange, setSelectedDateRange] = useState<any>([])

  const getQueryParams = () => {
    const query = new URLSearchParams(location.search);

    const queryParams: any = {};
    query.forEach((value, key) => {
      queryParams[key] = value;
    });

    return queryParams;
  }

  const {
    data: patchesList,
    isLoading,
    isFetching,
  } = useGetAvailableWindowsPatches(filter, paginationState)

  const { data: products } = useGetWindowsProducts()
  const { data: categories } = useGetWindowsPatchCategories()
  const severitiesOptions = [
    { key: 'critical', text: t('DASHBOARD.SECURITY.CRITICAL'), selected: false },
    { key: 'important', text: t('DASHBOARD.SECURITY.IMPORTANT'), selected: false },
    { key: 'moderate', text: t('DASHBOARD.SECURITY.MODERATE'), selected: false },
    { key: 'low', text: t('DASHBOARD.SECURITY.LOW'), selected: false },
    { key: 'unspecified', text: t('DASHBOARD.SECURITY.UNSPECIFIED'), selected: false }
  ];
  const dateOptions = [
    { key: '60days', text: t('DASHBOARD.PATCHES.MORE_60'), selected: false },
    { key: '60-30days', text: t('DASHBOARD.PATCHES.60_TO_30'), selected: false },
    { key: '30-7days', text: t('DASHBOARD.PATCHES.30_TO_7'), selected: false },
    { key: '7days', text: t('DASHBOARD.PATCHES.LESS_7'), selected: false },
  ];

  const productOptions = [...(products ? products.map((product) => ({ key: product.id, text: product.title, selected: false })) : [])]
  const categoryOptions = [
    ...(categories ? categories.map((category) => ({ key: category.id, text: category.title, selected: false })) : []),
  ]

  const patchesPage: WindowsPatches[] = useMemo(() => patchesList?.results || [], [patchesList])

  const columns: any[] = [
    {
      key: 'kb',
      name: 'KB',
      fieldName: 'kb',
      minWidth: 60,
      maxWidth: 80,
      isRowHeader: true,
    },
    {
      key: 'title',
      name: t('PATCH_POLICIES.TABLE.TITLE'),
      fieldName: 'title',
      minWidth: 100,
      isRowHeader: true,
      onRender: (item: any) => {
        if (!item?.infoUrl) {
          return item.title
        } else {
          return (
            <Link
              target="_blank"
              onClick={() => {
                window.open(item?.infoUrl, '_blank')
              }}
            >
              {item.title}
            </Link>
          )
        }
      },
    },
    {
      key: 'category',
      name: t('PATCH_POLICIES.TABLE.CATEGORY'),
      minWidth: 100,
      maxWidth: 150,
      isRowHeader: true,
      fieldName: 'category',
    },
    {
      key: 'os',
      name: 'OS',
      minWidth: 80,
      maxWidth: 100,
      isRowHeader: true,
      fieldName: 'os'
    },
    {
      key: 'product',
      name: t('PATCH_POLICIES.TABLE.PRODUCT'),
      minWidth: 200,
      maxWidth: 200,
      isRowHeader: true,
      fieldName: 'product',
    },

    {
      key: 'severity',
      name: t('PATCH_POLICIES.TABLE.SEVERITY'),
      minWidth: 70,
      maxWidth: 150,
      isRowHeader: true,
      fieldName: 'severity',
      onRender: (item: any) => {
        return (
          <div style={{ textAlign: 'center' }}>
            {item && item.severity && item.severity.toLowerCase() !== 'unspecified' ? item.severity : '-'}
          </div>
        )
      },
    },
    {
      key: 'creationDate',
      name: t('PATCH_POLICIES.TABLE.DATE'),
      minWidth: 100,
      maxWidth: 200,
      isRowHeader: true,
      fieldName: 'creationDate',
      onRender: (item: any) => {
        return (
          <div>{item && item.creationDate ? dateFormatter(t, new Date(item.creationDate), DateFormat.Short) : ''}</div>
        )
      },
    }
  ]

  const generateDates = (filter: string) => {
    const startDate = new Date();
    const endDate = new Date();
    let start = null;
    let end = null;

    switch (filter) {
      case '60days':
        start = null;
        end = new Date(endDate.setDate(endDate.getDate() - 60));
        break;
      case '60-30days':
        start = new Date(startDate.setDate(startDate.getDate() - 60));
        end = new Date(endDate.setDate(endDate.getDate() - 30));
        break;
      case '30-7days':
        start = new Date(startDate.setDate(startDate.getDate() - 30))
        end = new Date(endDate.setDate(endDate.getDate() - 7));
        break;
      case '7days':
        start = new Date(startDate.setDate(startDate.getDate() - 7));
        end = null;
        break;
      default:
        start = null;
        end = null;
        break;
    }

    return { start, end };
  };

  const handleOnPaginationChange = (newPaginationState: PaginationArgs) => {
    setPaginationState(newPaginationState)
  }

  const onChangeDropdownProducts = (event: any, option: any) => {
    let newSelectedProducts

    if (option.selected) {
      newSelectedProducts = [...selectedProduct, option]
    } else {
      newSelectedProducts = selectedProduct.filter((category: any) => category.key !== option.key)
    }

    setSelectedProduct(newSelectedProducts)

    setFilter({
      ...filter,
      products: newSelectedProducts.map((product: any) => product.text),
    })
  }

  const onChangeDropdownSeverities = (event: any, option: any) => {
    let newSelectedSeverities

    if (option.selected) {
      newSelectedSeverities = [...selectedSeverity, option]
    } else {
      newSelectedSeverities = selectedSeverity.filter((category: any) => category.key !== option.key)
    }

    setSelectedSeverity(newSelectedSeverities)

    setFilter({
      ...filter,
      severity: newSelectedSeverities.map((severity: any) => severity.text),
    })
  }

  const onChangeDropdownCategories = (event: any, option: any) => {
    let newSelectedCategories

    if (option.selected) {
      newSelectedCategories = [...selectedCategory, option]
    } else {
      newSelectedCategories = selectedCategory.filter((category: any) => category.key !== option.key)
    }

    setSelectedCategory(newSelectedCategories)

    setFilter({
      ...filter,
      categories: newSelectedCategories.map((category: any) => category.text),
    })
  }

  const onChangeDropdownReleaseDate = (event: any, option: any) => {
    let newSelectedReleaseDate;
    let startDate = null;
    let endDate = null;

    if (option.selected) {
      const { start, end } = generateDates(option.key)
      startDate = start;
      endDate = end;
      newSelectedReleaseDate = [option]
    } else {
      newSelectedReleaseDate = selectedDateRange.filter((date: any) => date.key !== date.key)
    }

    setSelectedDateRange(newSelectedReleaseDate);

    setFilter({
      ...filter,
      creationDate: {
        start: startDate,
        end: endDate
      },
    })
  }

  useEffect(() => {
    const {severity, dateRange, category} = getQueryParams()
    let filters: WindowsPatchFilter = filter;

    if(severity) {
      setSelectedSeverity(severitiesOptions.filter((severityOption) => severityOption.text === severity))
      filters = {...filters, severity: [severity]}
    }

    if(dateRange) {
      setSelectedDateRange(dateOptions.filter((dateOption) => dateOption.key === dateRange))
      const { start, end } = generateDates(dateRange);
      filters = {
        ...filters,
        creationDate: {
          start: start,
          end: end
        }
      }
    }

    if(category && categories) {
      const categoryOption = categoryOptions.filter((option) => option.key === category)
      setSelectedCategory(categoryOption)
      filters = {...filters, categories: [categoryOption[0].text]}
    }

    setFilter({ ...filters })

  }, [categories]);

  return (
    <>
      <section style={{ marginBottom: 20 }}>
        <Row>
          <Col className="col-xs-12 col-lg-4">
            <RowColLayout rowProps={{ classNames: 'mt-2' }}>
              <Dropdown
                label={t('PATCH_POLICIES.FORM.CATEGORIES')}
                placeholder={t('PATCH_POLICIES.FORM.CATEGORIES_PLACEHOLDER')}
                options={categoryOptions}
                onChange={onChangeDropdownCategories}
                selectedKeys={selectedCategory.map((category: any) => category.key)}
                multiSelect
              />
            </RowColLayout>
          </Col>

          <Col className="col-xs-12 col-lg-4">
            <RowColLayout rowProps={{ classNames: 'mt-2' }}>
              <SearchableDropdown
                dropdownProps={{
                  label: t('PATCH_POLICIES.FORM.PRODUCTS'),
                  placeholder: t('PATCH_POLICIES.FORM.PRODUCTS_PLACEHOLDER'),
                  options: productOptions,
                  multiSelect: true,
                  selectedKeys: selectedProduct.map((product: any) => product.key),
                  onChange: onChangeDropdownProducts,
                  styles: {
                    dropdownItemsWrapper: {
                      maxHeight: '400px',
                    },
                  },
                }}
                searchBoxProps={{
                  placeholder: t('PATCH_POLICIES.FORM.SEARCH_PRODUCT_PLACEHOLDER'),
                }}
              />
            </RowColLayout>
          </Col>

          <Col className="col-xs-12 col-lg-4">
            <RowColLayout rowProps={{ classNames: 'mt-2' }}>
              <Dropdown
                label={t('DASHBOARD.PATCHES.SEVERITY')}
                placeholder={t('DASHBOARD.PATCHES.SEVERITY')}
                options={severitiesOptions}
                onChange={onChangeDropdownSeverities}
                selectedKeys={selectedSeverity.map((severity: any) => severity.key)}
                multiSelect
              />
            </RowColLayout>
          </Col>

          <Col className="col-xs-12 col-lg-4">
            <RowColLayout rowProps={{ classNames: 'mt-2' }}>
              <Dropdown
                label={t('DASHBOARD.PATCHES.RELEASE_DATE')}
                placeholder={t('DASHBOARD.PATCHES.RELEASE_DATE')}
                options={dateOptions}
                onChange={onChangeDropdownReleaseDate}
                selectedKeys={selectedDateRange.map((dateRange: any) => dateRange.key)}
                multiSelect
              />
            </RowColLayout>
          </Col>
        </Row>
      </section>

      <Table
        tableId="vulnerable-workspaces"
        isLoading={isLoading}
        isEmpty={patchesPage?.length === 0}
        emptyMessage={t('general:EMPTY_FILTER')}
        tableProps={{
          columns: columns,
          items: patchesPage,
          onShouldVirtualize: () => false,
        }}
        paginationProps={
          patchesList
            ? {
              pagination: paginationState,
              onPaginationChange: handleOnPaginationChange,
              paginatedData: patchesList,
              isFetching: isFetching,
            }
            : undefined
        }
      />
    </>
  )
}