import { useEffect, useState } from 'react'
import { usePatchManagementSummaryData } from './usePatchManagementSummaryData'
import { PatchSummary } from '../entities/PatchSummary'
import { PatchSummaryRuleSentences, SummarySectionType, SummaryType } from './types'
import { AllReportingGroupsMustHaveTargetRule } from './rules/all-reporting-groups-must-have-target-rule/AllReportingGroupsMustHaveTargetRule'
import { AllTargetMustHaveSchedule } from './rules/all-target-must-have-schedule/AllTargetMustHaveScheduleRule'
import { AllTargetsMustHavePatchPolicyRule } from './rules/all-patch-policies-must-have-target/AllTargetsMustHavePatchPolicyRule'
import { MustHaveApprovedPatchesRule } from './rules/must-have-approved-patches/MustHaveApprovedPatchesRule'
import { AllWorkspacesMustHaveReportingGroupRule } from './rules/all-workspaces-must-have-reporting-group/AllWorkspacesMustHaveReportingGroupRule'
import { MustNotHaveSecurityPatchesNotApprovedRule } from './rules/must-not-have-security-patches-not-approved/MustNotHaveSecurityPatchesNotApprovedRule'
import { MustBeSomeWorkspacesWithInstalledPatchesRule } from './rules/must-be-some-workspaces-with-installed-patches/MustBeSomeWorkspacesWithInstalledPatchesRule'
import { useTranslation } from 'react-i18next'

export interface SummaryItem {
  title?: string
  message?: string
  type: SummaryType
  section: SummarySectionType
  card?: JSX.Element
}

interface UsePatchManagementSummaryResult {
  summaryItems: SummaryItem[]
  isLoading: boolean
}

export interface SummaryRule {
  rule: PatchSummaryRuleSentences
  alertSummaryItem: SummaryItem
  infoSummaryItem: SummaryItem
  passRule: (patchSummary: PatchSummary) => boolean
}

export const usePatchManagementRuleEngine = (): UsePatchManagementSummaryResult => {
  const { data, isLoading } = usePatchManagementSummaryData()
  const [summaryItems, setSummaryItems] = useState<SummaryItem[]>([])
  const { t } = useTranslation('patch_management')

  const getSummaryRuleItemFromRule = (rule: PatchSummaryRuleSentences): SummaryRule | undefined => {
    switch (rule) {
      case PatchSummaryRuleSentences.ALL_REPORTING_GROUPS_MUST_HAVE_TARGET:
        return AllReportingGroupsMustHaveTargetRule
      case PatchSummaryRuleSentences.ALL_TARGET_MUST_HAVE_SCHEDULE:
        return AllTargetMustHaveSchedule
      case PatchSummaryRuleSentences.ALL_TARGETS_MUST_HAVE_PATCH_POLICY:
        return AllTargetsMustHavePatchPolicyRule
      case PatchSummaryRuleSentences.MUST_HAVE_APPROVED_PATCHES:
        return MustHaveApprovedPatchesRule
      case PatchSummaryRuleSentences.MUST_NOT_HAVE_STALE_SECURITY_PATCHES_NOT_APPROVED:
        return MustNotHaveSecurityPatchesNotApprovedRule
      case PatchSummaryRuleSentences.ALL_WORKSPACES_MUST_HAVE_REPORTING_GROUP:
        return AllWorkspacesMustHaveReportingGroupRule
      case PatchSummaryRuleSentences.MUST_BE_SOME_WORKSPACES_WITH_INSTALLED_PATCHES:
        return MustBeSomeWorkspacesWithInstalledPatchesRule
    }
  }

  const buildSummaryItemsFromPatchSummary = (patchSummary: PatchSummary): SummaryItem[] => {
    const summaryItems: SummaryItem[] = []

    Object.values(PatchSummaryRuleSentences).forEach((rule) => {
      const summaryRuleItem: SummaryRule | undefined = getSummaryRuleItemFromRule(rule)

      if (!summaryRuleItem) {
        return
      }

      const localizedSummaryRuleItem = {
        ...summaryRuleItem,
        alertSummaryItem: {
          ...summaryRuleItem.alertSummaryItem,
          title: t(`SUMMARY.RULES.${rule}.ALERT_SUMMARY_ITEM.TITLE`),
          message: t(`SUMMARY.RULES.${rule}.ALERT_SUMMARY_ITEM.MESSAGE`),
        },
        infoSummaryItem: {
          ...summaryRuleItem.infoSummaryItem,
          title: t(`SUMMARY.RULES.${rule}.INFO_SUMMARY_ITEM.TITLE`),
          message: t(`SUMMARY.RULES.${rule}.INFO_SUMMARY_ITEM.MESSAGE`),
        },
      }

      if (localizedSummaryRuleItem.passRule(patchSummary)) {
        summaryItems.push(localizedSummaryRuleItem.infoSummaryItem)
      } else {
        summaryItems.push(localizedSummaryRuleItem.alertSummaryItem)
      }
    })

    return summaryItems
  }

  useEffect(() => {
    if (data) {
      const summaryItems: SummaryItem[] = buildSummaryItemsFromPatchSummary(data)

      setSummaryItems(summaryItems)
    }
  }, [data])

  return {
    summaryItems,
    isLoading,
  }
}
