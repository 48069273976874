import { Navigate, useLocation } from 'react-router-dom'
import { useOrganizationSelection } from 'app/globals/useOrganizationSelection'

export const NavigateToMainPage = () => {
  const { rolesOnBusiness } = useOrganizationSelection()
  const location = useLocation()

  switch (rolesOnBusiness?.portal) {
    case 'USER':
    case 'ORGANIZATION_ADMIN':
    case 'ADMIN':
    default:
      return <Navigate to="/home" state={{ from: location }} replace />
  }
}