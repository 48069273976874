import { pageInfoBlock, paginatedVarsBlock } from 'app/config/pagination'
import { gql } from 'graphql-request'

export const usePaginatedOrgEligibleProductsQuery = () => {
  const queryName = 'getBusinessPaginatedProducts'

  const query = gql`
		query Request(
			$businessId: ID!
			$page: Int!
			$perPage: Int!
			$searchTerm: String!
			$filters: ProductFilter!
			$sorting: ProductSorting!
		) {
			getBusinessPaginatedProducts(
				businessId: $businessId
				${paginatedVarsBlock}
			) {
				data {
					_id
					name
					regions
					}
				${pageInfoBlock}
			}
		}
	`

  return {
    query,
    queryName,
  }
}
