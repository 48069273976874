import { Module } from '../../../query-client/types'

interface ModuleOrder {
  moduleId: string
}

export interface ModulesOrderInput {
  modulesOrder: ModuleOrder[];
}

export function getModulesOrderInputFromModuleArray(modules: Module[]): ModulesOrderInput {
  const modulesOrder: ModuleOrder[] = modules.map((module) => {
    return {
      moduleId: module._id,
    }
  })

  return {
    modulesOrder: [...modulesOrder]
  }
}
