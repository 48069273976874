import { gql } from 'graphql-request'

export const useViewUserConnectionsListPaginatedQuery = (): string => {
  const query = gql`
    query ViewUserConnectionsListPaginated($perPage: Int!, $page: Int!) {
      viewUserConnectionsListPaginated(perPage: $perPage, page: $page) {
        data {
          id
          date
          userAgent
          application
          ip
        }
        pageInfo {
          hasNextPage
          hasPreviousPage
          totalPages
          currentPage
          total
        }
      }
    }
  `

  return query
}
