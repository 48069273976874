import { SummaryRule } from '../../usePatchManagementRuleEngine'
import { PatchSummaryRuleSentences, SummarySectionType, SummaryType } from '../../types'
import { PatchSummary } from '../../../entities/PatchSummary'
import { MustBeSomeWorkspacesWithInstalledPatchesCard } from './MustBeSomeWorkspacesWithInstalledPatchesCard'

export const MustBeSomeWorkspacesWithInstalledPatchesRule: SummaryRule = {
  rule: PatchSummaryRuleSentences.MUST_BE_SOME_WORKSPACES_WITH_INSTALLED_PATCHES,
  alertSummaryItem: {
    card: <MustBeSomeWorkspacesWithInstalledPatchesCard />,
    type: SummaryType.Alert,
    section: SummarySectionType.Workspaces,
  },
  infoSummaryItem: {
    type: SummaryType.Information,
    section: SummarySectionType.Workspaces,
    card: <MustBeSomeWorkspacesWithInstalledPatchesCard isInfo />,
  },
  passRule: (patchSummary: PatchSummary) => {
    return true
  },
}
