import {
  ControlledTextField,
  DeleteActionButton,
  Dialog,
  FormActionsContainer,
  LoadingSpinner,
  RowColLayout,
} from '@flexxibleit/flexxible-ui'
import { useBoolean } from '@fluentui/react-hooks'
import { zodResolver } from '@hookform/resolvers/zod'
import { useFormSidebarContext } from 'app/components/forms/FormSidebar/state/FormSidebarState'
import { useUpdateLinuxPatchPolicy } from 'app/hooks/patchPolicies/linux-patch-policy/useUpdateLinuxPatchPolicy'
import { useCreateWindowsPatchPolicy } from 'app/hooks/patchPolicies/useCreateWindowsPatchPolicy'
import { useDeleteWindowsPatchPolicy } from 'app/hooks/patchPolicies/useDeleteWindowsPatchPolicy'
import { LinuxPatchPolicy, PatchPolicyExclude } from 'app/query-client/types'
import { useEffect, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import * as z from 'zod'
import FormActions from '../../../../components/forms/FormActions'
import ErrorStatus from '../../../../components/status/error-status'
import SuccessStatus from '../../../../components/status/success-status'

export interface CreateExcludeRuleLinuxPatchPolicyFormType {
  name: string
  rule: string
}

export interface CreateOrUpdatePatchPolicyFormProps {
  exclude?: PatchPolicyExclude
  index?: number
  linuxPatchPolicy: LinuxPatchPolicy
}

const schema: z.ZodType<Partial<CreateExcludeRuleLinuxPatchPolicyFormType>> = z.object({
  name: z
    .string({
      required_error: 'required',
    })
    .min(1, { message: 'required' }),
  rule: z
    .string({
      required_error: 'required',
    })
    .min(1, { message: 'required' }),
})

export const CreateExcludeRuleLinuxPatchPolicyForm = ({
  exclude,
  index,
  linuxPatchPolicy,
}: CreateOrUpdatePatchPolicyFormProps) => {
  const { t } = useTranslation('patch_management')
  const { organizationId } = useParams()
  const createWindowsPatchPolicy = useCreateWindowsPatchPolicy(organizationId || '')
  const updateLinuxPatchPolicy = useUpdateLinuxPatchPolicy()
  const deleteWindowsPatchPolicy = useDeleteWindowsPatchPolicy()
  const [hideDeleteDialog, { toggle: toggleHideDeleteDialog }] = useBoolean(true)
  const { closeSidebar } = useFormSidebarContext()
  const navigate = useNavigate()

  const [isSuccess, setIsSuccess] = useState(false)
  const [isError, setIsError] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    setIsSuccess(
      createWindowsPatchPolicy.isSuccess || updateLinuxPatchPolicy.isSuccess || deleteWindowsPatchPolicy.isSuccess
    )
    setIsError(createWindowsPatchPolicy.isError || updateLinuxPatchPolicy.isError || deleteWindowsPatchPolicy.isError)
    setIsLoading(
      createWindowsPatchPolicy.isLoading || updateLinuxPatchPolicy.isLoading || deleteWindowsPatchPolicy.isLoading
    )
  }, [createWindowsPatchPolicy, updateLinuxPatchPolicy, deleteWindowsPatchPolicy])

  const getDefaultValues = () => {
    return {
      name: exclude?.name || '',
      rule: exclude?.rule || '',
    }
  }
  const methods = useForm<CreateExcludeRuleLinuxPatchPolicyFormType>({
    resolver: zodResolver(schema),
    defaultValues: getDefaultValues(),
  })

  const handleSubmit = (data: CreateExcludeRuleLinuxPatchPolicyFormType) => {
    if (!exclude) {
      const newLinuxPatchPolicy: LinuxPatchPolicy = {
        ...linuxPatchPolicy,
        excludes: [
          ...linuxPatchPolicy.excludes,
          {
            name: data.name,
            rule: data.rule,
          },
        ],
      }
      updateLinuxPatchPolicy.mutate({
        id: linuxPatchPolicy._id,
        input: {
          name: newLinuxPatchPolicy.name,
          excludes: newLinuxPatchPolicy.excludes,
        },
      })
    } else if (exclude && index !== undefined) {
      const newLinuxPatchPolicy: LinuxPatchPolicy = {
        ...linuxPatchPolicy,
        excludes: [
          ...linuxPatchPolicy.excludes.slice(0, index),
          {
            name: data.name,
            rule: data.rule,
          },
          ...linuxPatchPolicy.excludes.slice(index + 1),
        ],
      }
      updateLinuxPatchPolicy.mutate({
        id: linuxPatchPolicy._id,
        input: {
          name: newLinuxPatchPolicy.name,
          excludes: newLinuxPatchPolicy.excludes,
        },
      })
    }
  }

  const onDelete = () => {
    toggleHideDeleteDialog()
  }
  const renderDeleteConfirmation = (): JSX.Element => {
    return (
      <Dialog
        title={t('DELETE_CONFIRMATION.TITLE_EXCLUDE')}
        description={t('DELETE_CONFIRMATION.MESSAGE')}
        actionButton={t('DELETE_CONFIRMATION.BUTTON_ACCEPT')}
        dismissButton={t('DELETE_CONFIRMATION.BUTTON_CANCEL')}
        hidden={hideDeleteDialog}
        onDismiss={toggleHideDeleteDialog}
        callback={() => {
          toggleHideDeleteDialog()
          handleDeleteExclude()
        }}
      />
    )
  }
  const handleDeleteExclude = () => {
    if (index === undefined) {
      return
    }

    const newLinuxPatchPolicy: LinuxPatchPolicy = {
      ...linuxPatchPolicy,
      excludes: [...linuxPatchPolicy.excludes.slice(0, index), ...linuxPatchPolicy.excludes.slice(index + 1)],
    }
    updateLinuxPatchPolicy.mutate(
      {
        id: linuxPatchPolicy._id,
        input: {
          name: newLinuxPatchPolicy.name,
          excludes: newLinuxPatchPolicy.excludes,
        },
      },
      {
        onSuccess: () => {
          closeSidebar()
        },
      }
    )
  }

  if (isLoading) {
    return <LoadingSpinner />
  }

  if (isSuccess || isError) {
    return (
      <>
        {createWindowsPatchPolicy.isSuccess && <SuccessStatus message={t('LINUX_PATCH_POLICIES.FORM.ADD_SUCCESS')} />}
        {updateLinuxPatchPolicy.isSuccess && <SuccessStatus message={t('LINUX_PATCH_POLICIES.FORM.UPDATE_SUCCESS')} />}

        {createWindowsPatchPolicy.isError && (
          <>
            <ErrorStatus message={t('LINUX_PATCH_POLICIES.FORM.ADD_ERROR')} />
          </>
        )}
        {updateLinuxPatchPolicy.isError && (
          <>
            <ErrorStatus message={t('LINUX_PATCH_POLICIES.FORM.UPDATE_ERROR')} />
          </>
        )}

        {deleteWindowsPatchPolicy.isError && <ErrorStatus message={t('LINUX_PATCH_POLICIES.FORM.DELETE_ERROR')} />}
      </>
    )
  }

  return (
    <>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(handleSubmit)}>
          <RowColLayout rowProps={{ classNames: 'mt-2' }}>
            <ControlledTextField
              name="name"
              required={true}
              translator={t}
              textFieldProps={{
                label: t('LINUX_PATCH_POLICIES.FORM.NAME'),
                placeholder: t('LINUX_PATCH_POLICIES.FORM.NAME_PLACEHOLDER'),
              }}
            />
          </RowColLayout>
          <RowColLayout rowProps={{ classNames: 'mt-2' }}>
            <ControlledTextField
              name="rule"
              required={true}
              translator={t}
              textFieldProps={{
                label: t('LINUX_PATCH_POLICIES.FORM.RULE'),
                placeholder: t('LINUX_PATCH_POLICIES.FORM.RULE_PLACEHOLDER'),
              }}
            />
          </RowColLayout>

          <ActionButtons isEdit={!!exclude} onDelete={onDelete} />
        </form>
        {renderDeleteConfirmation()}
      </FormProvider>
    </>
  )
}

const ActionButtons = ({ isEdit, onDelete }: { isEdit: boolean; onDelete: () => void }) => {
  const { t, i18n } = useTranslation()

  if (isEdit) {
    return (
      <FormActionsContainer isEdit>
        <DeleteActionButton locale={i18n.language} onClick={onDelete} />
        <FormActions
          submitProps={{
            text: t('general:BUTTON.SAVE'),
            iconProps: { iconName: 'Save' },
          }}
        />
      </FormActionsContainer>
    )
  }

  return (
    <FormActionsContainer>
      <FormActions
        submitProps={{
          text: t('general:BUTTON.CREATE'),
          iconProps: { iconName: 'Add' },
        }}
      />
    </FormActionsContainer>
  )
}
