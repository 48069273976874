import {
  ControlledTextField,
  DeleteActionButton,
  Dialog,
  FormActionsContainer,
  LoadingSpinner,
  RowColLayout,
} from '@flexxibleit/flexxible-ui'
import { zodResolver } from '@hookform/resolvers/zod'
import { CompactPeoplePicker } from 'app/components/forms/PeoplePicker/CompactPeoplePicker'
import { FormProvider, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import * as z from 'zod'
import FormActions from '../../../../components/forms/FormActions'
import useOrganizationById from '../../../../hooks/organization/useOrganizationById'
import { useNavigate, useParams } from 'react-router-dom'
import { IPersonaProps } from '@fluentui/react/lib/Persona'
import { useEffect, useState } from 'react'
import SuccessStatus from '../../../../components/status/success-status'
import ErrorStatus from '../../../../components/status/error-status'
import ControlledToggleButton from 'app/components/forms/ToggleButton/ToggleButton.component'
import { PatchPolicyTarget, ReportingGroup } from 'app/query-client/types'
import { useBoolean } from '@fluentui/react-hooks'
import { useFormSidebarContext } from 'app/components/forms/FormSidebar/state/FormSidebarState'
import Dropdown from 'app/components/forms/Dropdown'
import { Icon, IDropdownOption, TooltipHost } from '@fluentui/react'
import { useCreatePatchPolicyTarget } from 'app/hooks/patchPolicyTarget/useCreatePatchPolicyTarget'
import { useUpdatePatchPolicyTarget } from 'app/hooks/patchPolicyTarget/useUpdatePatchPolicyTarget'
import { useDeletePatchPolicyTarget } from 'app/hooks/patchPolicyTarget/useDeletePatchPolicyTarget'
import useGetWindowsPatchPoliciesByOrganization from 'app/hooks/patchPolicies/useGetWindowsPatchPoliciesByOrganization'
import useGetLinuxPatchPoliciesByOrganization from 'app/hooks/patchPolicies/linux-patch-policy/useGetLinuxPatchPoliciesByOrganization'
import { useExtendedPatchManagementFeature } from '../../../../permissions/ExtendedPatchManagement.featureFlag'

export interface CreatePatchPolicyFormTargetType {
  name: string
  reportingGroups: string[]
  shouldRestart?: boolean
  maxSimultaneousWorkspaces?: number | null
  windowsPatchPolicyId?: string
  linuxPatchPolicyId?: string
  forcePatchingConfiguration?: boolean
  wakeOnLAN?: boolean
}

export interface CreateOrUpdatePatchPolicyFormProps {
  patchPolicyTarget?: PatchPolicyTarget
}

const schema: z.ZodType<Partial<CreatePatchPolicyFormTargetType>> = z.object({
  name: z
    .string({
      required_error: 'required',
    })
    .min(1, { message: 'required' }),
  reportingGroups: z.array(z.string()).nonempty(),
  shouldRestart: z.boolean().optional(),
  maxSimultaneousWorkspaces: z
    .custom<number>()
    .transform((value) => Number(value))
    .refine((val) => val > 0, { message: 'greater_than_zero' })
    .optional(),
  windowsPatchPolicyId: z.string().optional(),
  linuxPatchPolicyId: z.string().optional(),
  forcePatchingConfiguration: z.boolean().optional(),
  wakeOnLAN: z.boolean().optional(),
})

export const CreatePatchPolicyTargetForm = ({ patchPolicyTarget }: CreateOrUpdatePatchPolicyFormProps) => {
  const { t } = useTranslation('patch_management')
  const { organizationId } = useParams()
  const { data: organization } = useOrganizationById(organizationId || '')
  const createPatchPolicyTarget = useCreatePatchPolicyTarget(organizationId || '')
  const updatePatchPolicyTarget = useUpdatePatchPolicyTarget()
  const deletePatchPolicyTarget = useDeletePatchPolicyTarget()
  const [hideDeleteDialog, { toggle: toggleHideDeleteDialog }] = useBoolean(true)
  const { closeSidebar } = useFormSidebarContext()
  const navigate = useNavigate()

  const tooltipStyles = {
    root: {
      cursor: 'pointer',
    },
  }

  const [selectedReportingGroups, setSelectedReportingGroups] = useState<string[]>(
    patchPolicyTarget?.reportingGroups?.map((rg) => rg._id) ?? []
  )
  const { isLoading: isLoadingWindowsPacthes, data: windowsPatchPolicies } = useGetWindowsPatchPoliciesByOrganization(
    organizationId || ''
  )
  const { isLoading: isLoadingLinuxPacthes, data: linuxPatchPolicies } = useGetLinuxPatchPoliciesByOrganization(
    organizationId || ''
  )
  const [isSuccess, setIsSuccess] = useState(false)
  const [isError, setIsError] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    setIsSuccess(
      createPatchPolicyTarget.isSuccess || updatePatchPolicyTarget.isSuccess || deletePatchPolicyTarget.isSuccess
    )
    setIsError(createPatchPolicyTarget.isError || updatePatchPolicyTarget.isError || deletePatchPolicyTarget.isError)
    setIsLoading(
      createPatchPolicyTarget.isLoading || updatePatchPolicyTarget.isLoading || deletePatchPolicyTarget.isLoading
    )
  }, [createPatchPolicyTarget, updatePatchPolicyTarget, deletePatchPolicyTarget])

  const getDefaultValues = () => {
    if (patchPolicyTarget) {
      return {
        name: patchPolicyTarget.name,
        reportingGroups: patchPolicyTarget.reportingGroups.map((rg) => rg._id),
        shouldRestart: patchPolicyTarget.shouldRestart,
        maxSimultaneousWorkspaces: patchPolicyTarget.maxSimultaneousWorkspaces,
        windowsPatchPolicyId: patchPolicyTarget.windowsPatchPolicyId ?? undefined,
        linuxPatchPolicyId: patchPolicyTarget.linuxPatchPolicyId ?? undefined,
        forcePatchingConfiguration: patchPolicyTarget.forcePatchingConfiguration,
        wakeOnLAN: patchPolicyTarget.wakeOnLAN,
      }
    }
    return {
      reportingGroups: [],
    }
  }

  const methods = useForm<CreatePatchPolicyFormTargetType>({
    resolver: zodResolver(schema),
    defaultValues: getDefaultValues(),
  })

  const optionsWindowsPatchPolicy: IDropdownOption[] = windowsPatchPolicies
    ? windowsPatchPolicies.map((item: any) => ({ key: item._id, text: item.name }))
    : []

  const optionsLinuxPatchPolicy: IDropdownOption[] = linuxPatchPolicies
    ? linuxPatchPolicies.map((item: any) => ({ key: item._id, text: item.name }))
    : []

  const reportingGroupsOptions: IPersonaProps[] =
    organization?.reportingGroups
      .filter(
        (rg: ReportingGroup) =>
          rg.patchPolicyTargetId === null ||
          rg.patchPolicyTargetId === undefined ||
          rg.patchPolicyTargetId === patchPolicyTarget?._id
      )
      .map((rg) => ({
        key: rg._id,
        text: rg.name,
      })) || []

  const handleSubmit = (data: CreatePatchPolicyFormTargetType) => {
    setSelectedReportingGroups(data.reportingGroups)
    if (patchPolicyTarget?._id) {
      updatePatchPolicyTarget.mutate({
        id: patchPolicyTarget._id!,
        input: {
          name: data.name,
          reportingGroups: data.reportingGroups || [],
          schedule: JSON.stringify(patchPolicyTarget.schedule),
          weeks: patchPolicyTarget.weeks,
          timezone: patchPolicyTarget.timezone,
          maxSimultaneousWorkspaces: data.maxSimultaneousWorkspaces ?? patchPolicyTarget.maxSimultaneousWorkspaces,
          windowsPatchPolicyId: data.windowsPatchPolicyId,
          linuxPatchPolicyId: data.linuxPatchPolicyId,
          isInMaintenance: patchPolicyTarget.isInMaintenance,
          shouldRestart: data.shouldRestart ?? patchPolicyTarget.shouldRestart,
          forcePatchingConfiguration: data.forcePatchingConfiguration ?? patchPolicyTarget.forcePatchingConfiguration,
          wakeOnLAN: data.wakeOnLAN ?? patchPolicyTarget.wakeOnLAN,
        },
      })
    } else {
      try {
        createPatchPolicyTarget.mutate(
          {
            name: data.name,
            reportingGroups: data.reportingGroups || [],
            windowsPatchPolicyId: data.windowsPatchPolicyId,
            linuxPatchPolicyId: data.linuxPatchPolicyId,
          },
          {
            onSuccess: (data) => {
              if (data?._id) {
                navigate(`/organization/${organizationId}/patch-management/patch-policy-targets/${data._id}`)
                closeSidebar()
              }
            },
          }
        )
      } catch (e) {
        console.error(e)
      }
    }
  }

  const onDelete = () => {
    toggleHideDeleteDialog()
  }
  const renderDeleteConfirmation = (): JSX.Element => {
    return (
      <Dialog
        title={t('DELETE_CONFIRMATION.TITLE')}
        description={t('DELETE_CONFIRMATION.MESSAGE')}
        actionButton={t('DELETE_CONFIRMATION.BUTTON_ACCEPT')}
        dismissButton={t('DELETE_CONFIRMATION.BUTTON_CANCEL')}
        hidden={hideDeleteDialog}
        onDismiss={toggleHideDeleteDialog}
        callback={() => {
          toggleHideDeleteDialog()
          handleDeletePolicy()
        }}
      />
    )
  }
  const handleDeletePolicy = () => {
    //TODO - handleDeletePolicyTarget
    if (patchPolicyTarget?._id) {
      deletePatchPolicyTarget.mutateAsync(patchPolicyTarget?._id).then(() => {
        closeSidebar()
        navigate(-1)
      })
    }
  }

  if (isLoading && isLoadingWindowsPacthes) {
    return <LoadingSpinner />
  }

  if (isSuccess || isError) {
    return (
      <>
        {createPatchPolicyTarget.isSuccess && <SuccessStatus message={t('PATCH_POLICIES_TARGET.FORM.ADD_SUCCESS')} />}
        {updatePatchPolicyTarget.isSuccess && (
          <SuccessStatus message={t('PATCH_POLICIES_TARGET.FORM.UPDATE_SUCCESS')} />
        )}

        {createPatchPolicyTarget.isError && (
          <>
            <ErrorStatus message={t('PATCH_POLICIES_TARGET.FORM.ADD_ERROR')} />
          </>
        )}
        {updatePatchPolicyTarget.isError && (
          <>
            <ErrorStatus message={t('PATCH_POLICIES_TARGET.FORM.UPDATE_ERROR')} />
          </>
        )}

        {deletePatchPolicyTarget.isError && <ErrorStatus message={t('PATCH_POLICIES_TARGET.FORM.DELETE_ERROR')} />}
      </>
    )
  }

  return (
    <>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(handleSubmit)}>
          <RowColLayout rowProps={{ classNames: 'mt-2' }}>
            <ControlledTextField
              name="name"
              required={true}
              translator={t}
              textFieldProps={{
                label: t('PATCH_POLICIES_TARGET.FORM.NAME'),
                placeholder: t('PATCH_POLICIES_TARGET.FORM.NAME_PLACEHOLDER'),
                disabled: isLoading,
              }}
            />
          </RowColLayout>
          <RowColLayout rowProps={{ classNames: 'mt-2' }}>
            <CompactPeoplePicker
              name="reportingGroups"
              label={t('PATCH_POLICIES_TARGET.FORM.REPORTING_GROUPS')}
              placeholder={t('PATCH_POLICIES_TARGET.FORM.REPORTING_GROUPS_PLACEHOLDER')}
              options={reportingGroupsOptions}
              translator={t}
              required={true}
              peoplePickerProps={{
                disabled: isLoading,
              }}
              selectedList={selectedReportingGroups}
            />
          </RowColLayout>
          {patchPolicyTarget?._id && useExtendedPatchManagementFeature() && (
            <RowColLayout rowProps={{ classNames: 'mt-2' }}>
              <ControlledTextField
                name="maxSimultaneousWorkspaces"
                translator={t}
                textFieldProps={{
                  type: 'number',
                  label: t('PATCH_POLICIES_TARGET.FORM.MAX_SIMULTANEOUS_WORKSPACES'),
                  placeholder: t('PATCH_POLICIES_TARGET.FORM.MAX_SIMULTANEOUS_WORKSPACES_PLACEHOLDER'),
                  disabled: isLoading,
                }}
              />
            </RowColLayout>
          )}
          {useExtendedPatchManagementFeature() && <div className="mt-2">
            <Dropdown
              name="windowsPatchPolicyId"
              //required={true}
              dropdownProps={{
                label: t('PATCH_POLICIES_TARGET.FORM.WINDOWS_PATCH_POLICY'),
                options: optionsWindowsPatchPolicy,
                placeholder: t('PATCH_POLICIES_TARGET.FORM.WINDOWS_PATCH_POLICY_PLACEHOLDER'),
                disabled: isLoading,
                defaultSelectedKey: patchPolicyTarget?.windowsPatchPolicyId,
              }}
              translator={t}
            />
          </div>}
          {useExtendedPatchManagementFeature() && <div className="mt-2">
            <Dropdown
              name="linuxPatchPolicyId"
              //required={true}
              dropdownProps={{
                label: t('PATCH_POLICIES_TARGET.FORM.LINUX_PATCH_POLICY'),
                options: optionsLinuxPatchPolicy,
                placeholder: t('PATCH_POLICIES_TARGET.FORM.LINUX_PATCH_POLICY_PLACEHOLDER'),
                disabled: isLoading,
                defaultSelectedKey: patchPolicyTarget?.linuxPatchPolicyId,
              }}
              translator={t}
            />
          </div>}
          {patchPolicyTarget?._id && (
            <>
              <RowColLayout rowProps={{ classNames: 'mt-2' }}>
                <section style={{ display: 'flex', alignItems: 'center' }}>
                  <ControlledToggleButton
                    name="shouldRestart"
                    toggleProps={{
                      inlineLabel: true,
                      label: t('PATCH_POLICIES_TARGET.FORM.RESTART_AFTER_PATCHING'),
                      disabled: isLoading,
                    }}
                  />
                  <TooltipHost
                    content={t('PATCH_POLICIES_TARGET.FORM.RESTART_AFTER_PATCHING_TOOLTIP')}
                    styles={tooltipStyles}
                  >
                    <Icon
                      iconName={'info'}
                      style={{
                        height: '100%',
                        marginBottom: 6,
                        color: '#464A4F',
                        fontSize: '1em',
                        marginLeft: 8,
                      }}
                    />
                  </TooltipHost>
                </section>
              </RowColLayout>
              <RowColLayout rowProps={{ classNames: 'mt-2' }}>
                <section style={{ display: 'flex', alignItems: 'center' }}>
                  <ControlledToggleButton
                    name="wakeOnLAN"
                    toggleProps={{
                      inlineLabel: true,
                      label: t('PATCH_POLICIES_TARGET.FORM.WAKE_ON_LAN'),
                      disabled: isLoading,
                    }}
                  />
                  <TooltipHost content={t('PATCH_POLICIES_TARGET.FORM.WAKE_ON_LAN_TOOLTIP')} styles={tooltipStyles}>
                    <Icon
                      iconName={'info'}
                      style={{
                        height: '100%',
                        marginBottom: 6,
                        color: '#464A4F',
                        fontSize: '1em',
                        marginLeft: 8,
                      }}
                    />
                  </TooltipHost>
                </section>
              </RowColLayout>
              {useExtendedPatchManagementFeature() && <RowColLayout rowProps={{ classNames: 'mt-2' }}>
                <section style={{ display: 'flex', alignItems: 'center' }}>
                  <ControlledToggleButton
                    name="forcePatchingConfiguration"
                    toggleProps={{
                      inlineLabel: true,
                      label: t('PATCH_POLICIES_TARGET.FORM.FORCE_PATCHING_CONFIGURATION'),
                      disabled: isLoading,
                    }}
                  />
                  <TooltipHost
                    content={t('PATCH_POLICIES_TARGET.FORM.FORCE_PATCHING_CONFIGURATION_TOOLTIP')}
                    styles={tooltipStyles}
                  >
                    <Icon
                      iconName={'info'}
                      style={{
                        height: '100%',
                        marginBottom: 6,
                        color: '#464A4F',
                        fontSize: '1em',
                        marginLeft: 8,
                      }}
                    />
                  </TooltipHost>
                </section>
              </RowColLayout>}
            </>
          )}
          {isLoading && (
            <div className="my-auto">
              <LoadingSpinner overlay={false} />
            </div>
          )}
          <ActionButtons isEdit={!!patchPolicyTarget?._id} onDelete={onDelete} />
        </form>
        {renderDeleteConfirmation()}
      </FormProvider>
    </>
  )
}

const ActionButtons = ({ isEdit, onDelete }: { isEdit: boolean; onDelete: () => void }) => {
  const { t, i18n } = useTranslation()

  if (isEdit) {
    return (
      <FormActionsContainer isEdit>
        <DeleteActionButton locale={i18n.language} onClick={onDelete} />
        <FormActions
          submitProps={{
            text: t('general:BUTTON.SAVE'),
            iconProps: { iconName: 'Save' },
          }}
        />
      </FormActionsContainer>
    )
  }

  return (
    <FormActionsContainer>
      <FormActions
        submitProps={{
          text: t('general:BUTTON.CREATE'),
          iconProps: { iconName: 'Add' },
        }}
      />
    </FormActionsContainer>
  )
}
