import FD_icons_LT from '../assets/img/FD_icons_LT.svg'
import FD_icons_CLT from '../assets/img/FD_icons_CLT.svg'
import FD_icons_ADV from '../assets/img/FD_icons_ADV.svg'
import FD_icons_ENT from '../assets/img/FD_icons_ENT.svg'
import FD_icons_EDG from '../assets/img/FD_icons_EDG.svg'
import FD_icons_CLO from '../assets/img/FD_icons_CLO.svg'
import FD_icons_STD from '../assets/img/FD_icons_STD.svg'

export const getIcon = (product: string) => {
  switch (product) {
    case 'FlexxDesktop Lite':
      return FD_icons_LT
    case 'FlexxDesktop Client':
      return FD_icons_CLT
    case 'FlexxClient':
      return FD_icons_CLT
    case 'FlexxDesktop Advanced':
      return FD_icons_ADV
    case 'FlexxDesktop Enterprise':
      return FD_icons_ENT
    case 'FlexxDesktop Edge':
      return FD_icons_EDG
    case 'FlexxDesktop Cloud':
      return FD_icons_CLO
    case 'FlexxCloud':
      return FD_icons_CLO
    case 'FlexxDesktop Standard':
      return FD_icons_STD
    default: 
      return FD_icons_LT;
  }
}

export const getProductIcon = (product: string, width: number = 25, height: number = 25) => {
  return <img src={getIcon(product)} alt={product} width={width} height={height} />
}
