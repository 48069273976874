import { ControlledTextField, FormActionsContainer, LoadingSpinner, RowColLayout } from '@flexxibleit/flexxible-ui'
import { IDropdownOption, MessageBar, ResponsiveMode } from '@fluentui/react'
import { useBoolean } from '@fluentui/react-hooks'
import { zodResolver } from '@hookform/resolvers/zod'
import Dialog from 'app/components/Dialog/Dialog'
import ComboBox from 'app/components/forms/ComboBox'
import Dropdown from 'app/components/forms/Dropdown'
import FormActions from 'app/components/forms/FormActions'
import useDropdownOptions from 'app/components/hooks/useDropdownOptions'
import ErrorStatus from 'app/components/status/error-status'
import SuccessStatus from 'app/components/status/success-status'
import countries from 'app/config/countries'
import industries from 'app/config/industries'
import useCreateTenant from 'app/hooks/tenant/useCreateTenant'
import languages from 'app/i18n/languages'
import { ElegibleProduct, OrganizationPolicy, Product, ProductConfig, Region } from 'app/query-client/types'
import { useEffect, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import * as z from 'zod'
import { GettingStartedChecklistTasks } from '../../../user-journey/getting-started-checklist/GettingStartedChecklistTasksEnum'
import { useGettingStartedChecklist } from '../../../user-journey/getting-started-checklist/useGettingStartedChecklist'
import useGetProductTypes from 'app/hooks/product/useGetProductTypes'
import { BusinessAnalyzer, BusinessType } from 'app/models/business/BusinessType'

interface Props {
  elegibleProducts: ElegibleProduct[]
  policies: OrganizationPolicy[]
  organizationId: string
  productConfigs?: ProductConfig[]
  organizationType?: BusinessType
}

interface FormValues {
  name: string
  product?: string
  region?: string
  email: string
  language: string
  country: string
  industry: string
  policyId?: string
  environment?: string
  analyzer?: string
}

const getSchema = (organizationType: BusinessType | undefined) => {
  const baseSchema = {
    name: z.string({ required_error: 'required' }).min(1, { message: 'required' }),
    email: z.string({ required_error: 'required' }).trim().email({ message: 'format' }),
    language: z.string({ required_error: 'required' }).min(1, { message: 'required' }),
    country: z.string({ required_error: 'required' }).min(1, { message: 'required' }),
    industry: z.string({ required_error: 'required' }).min(1, { message: 'required' }),
    policyId: z.string({ required_error: 'required' }).optional(),
  }

  if (organizationType === BusinessType.END_CUSTOMER) {
    return z.object({
      ...baseSchema,
      environment: z.string({ required_error: 'required' }),
      analyzer: z.string({ required_error: 'required' }),
      product: z.string().optional(),
      region: z.string().optional(),
    })
  } else {
    return z.object({
      ...baseSchema,
      product: z.string({ required_error: 'required' }),
      region: z.string({ required_error: 'required' }),
      environment: z.string().optional(),
      analyzer: z.string().optional(),
    })
  }
}

export const TenantForm = ({ elegibleProducts, policies, organizationId, productConfigs, organizationType }: Props) => {
  const { t } = useTranslation('organization_details')
  const { isError, isLoading, isSuccess, mutate } = useCreateTenant()
  const [regions, setRegions] = useState<IDropdownOption[]>([])
  const [policiesOpts, setPoliciesOpts] = useState<IDropdownOption[]>([])
  const [hideDialog, { toggle: toggleHideDialog }] = useBoolean(true)
  const { completeGettingStartedTask } = useGettingStartedChecklist()
  const formatedElegibleProducts = elegibleProducts.map((product) => ({
    _id: product.elegibleProduct._id,
    name: product.elegibleProduct.name,
  }))
  const dropdownOptions: IDropdownOption[] = useDropdownOptions(formatedElegibleProducts, '_id', 'name')
  const { data: products, isFetching: productsLoading } = useGetProductTypes(true)

  const dropdownOptionsEndCustomers: IDropdownOption[] = []
  if (organizationType === BusinessType.END_CUSTOMER) {
    productConfigs?.map((productConfig) => {
      const formatProductText = (productConfig: ProductConfig) => {
        const regionText = productConfig.region ? ` (${productConfig.region})` : ''
        return `${productConfig.environment} - ${productConfig.product.name}${regionText}`
      }
      dropdownOptionsEndCustomers.push({
        key: productConfig._id,
        text: formatProductText(productConfig),
      })
    })
  }

  const dropdownOptionsAnalyzers: IDropdownOption[] = [
    {
      key: BusinessAnalyzer.NEW,
      text: t('TENANTS.FORM.NEW_ANALYZER'),
    },
    {
      key: BusinessAnalyzer.EXISTING,
      text: t('TENANTS.FORM.EXISTING_ANALYZER'),
    },
  ]

  const schema = getSchema(organizationType)

  const methods = useForm<FormValues>({
    resolver: zodResolver(schema),
  })
  const productWatch = methods.watch('product')
  const nameWatch = methods.watch('name')
  const regionWatch = methods.watch('region')

  useEffect(() => {
    const product = products.filter((i) => i._id === productWatch)[0]

    if (!product) {
      setPoliciesOpts([])
      setRegions([])
    } else {
      const productId = product._id
      const availableDeploymentCodes =
        elegibleProducts?.filter((product) => product.elegibleProduct._id === productId)[0].deploymentRegions || []
      const regionOptions: IDropdownOption[] = []
      product.regions.map((region: Region) => {
        if (availableDeploymentCodes.includes(region.deploymentCode)) {
          regionOptions.push({ key: region.deploymentCode, text: `${region.name} (${region.deploymentCode})` })
        }
      })
      setRegions(regionOptions)

      const filterePolicies = policies.filter((policy) => policy.product === product._id)
      let policiesOptions: IDropdownOption[] = useDropdownOptions(filterePolicies, '_id', 'name')
      setPoliciesOpts(policiesOptions)
    }
  }, [productWatch])

  const onSubmit = (values: FormValues) => {
    completeGettingStartedTask(GettingStartedChecklistTasks.CREATE_TENANT)

    if (organizationType === BusinessType.END_CUSTOMER) {
      return mutate({
        name: values.name,
        productConfigId: values.environment,
        analyzer: values.analyzer,
        policyId: values.policyId,
        organizationCountry: values.country,
        organizationIndustry: values.industry,
        organizationLanguage: values.language,
        organizationEmail: values.email.trim(),
        organizationId: organizationId,
      })
    } else {
      return mutate({
        name: values.name,
        productId: values.product,
        region: values.region,
        policyId: values.policyId,
        organizationCountry: values.country,
        organizationIndustry: values.industry,
        organizationLanguage: values.language,
        organizationEmail: values.email.trim(),
        organizationId: organizationId,
      })
    }
  }

  if (isLoading) {
    return <LoadingSpinner />
  }

  if (isError) {
    return <ErrorStatus message={t('TENANTS.FORM.ADD_ERROR_MESSAGE')} />
  }

  if (isSuccess) {
    return <SuccessStatus message={t('TENANTS.FORM.ADD_SUCCESS_MESSAGE')} />
  }

  const renderConfirmDialog = (): JSX.Element => {
    return (
      <Dialog
        title={t('TENANTS.ADD_DIALOG.TITLE')}
        description={t('TENANTS.ADD_DIALOG.DESCRIPTION')}
        actionButton={t('TENANTS.ADD_DIALOG.BUTTON_CREATE')}
        dismissButton={t('general:BUTTON.CANCEL')}
        hidden={hideDialog}
        onDismiss={toggleHideDialog}
        callback={() => {
          onSubmit(methods.getValues())
          toggleHideDialog()
        }}
      >
        <MessageBar
          messageBarType={0}
          isMultiline={true}
          dismissButtonAriaLabel={t('general:CLOSE_LABEL')}
          className="mb-2"
          style={{ width: '100%' }}
        >
          {organizationType !== BusinessType.END_CUSTOMER &&
            t('TENANTS.ADD_DIALOG.INFO', { name: nameWatch, region: regionWatch })}

          {organizationType === BusinessType.END_CUSTOMER &&
            t('TENANTS.ADD_DIALOG.INFO_END_CUSTOMER', { name: nameWatch })}
        </MessageBar>
      </Dialog>
    )
  }

  return (
    <>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(toggleHideDialog)}>
          <RowColLayout rowProps={{ classNames: 'mt-2' }}>
            <ControlledTextField
              name="name"
              required={true}
              translator={t}
              textFieldProps={{
                label: t('TENANTS.FORM.NAME'),
                placeholder: t('USERS.FORM.ENTER_TEXT'),
              }}
            />
          </RowColLayout>
          <RowColLayout rowProps={{ classNames: 'mt-2' }}>
            <ControlledTextField
              name="email"
              required={true}
              translator={t}
              textFieldProps={{
                label: t('organizations:FORM.EMAIL'),
                placeholder: t('USERS.FORM.ENTER_TEXT'),
              }}
            />
          </RowColLayout>
          <RowColLayout rowProps={{ classNames: 'mt-2' }}>
            <ComboBox
              name="language"
              required={true}
              translator={t}
              comboBoxProps={{
                options: languages,
                label: t('organizations:FORM.LANGUAGE'),
                placeholder: t('organizations:FORM.LANGUAGE_PLACEHOLDER'),
              }}
            />
          </RowColLayout>
          <RowColLayout rowProps={{ classNames: 'mt-2' }}>
            <ComboBox
              name="country"
              required={true}
              translator={t}
              comboBoxProps={{
                options: countries,
                label: t('organizations:FORM.COUNTRY'),
                placeholder: t('organizations:FORM.COUNTRY_PLACEHOLDER'),
              }}
            />
          </RowColLayout>
          <RowColLayout rowProps={{ classNames: 'mt-2' }}>
            <ComboBox
              name="industry"
              required={true}
              translator={t}
              comboBoxProps={{
                options: industries,
                label: t('organizations:FORM.INDUSTRY'),
                placeholder: t('organizations:FORM.INDUSTRY_PLACEHOLDER'),
              }}
            />
          </RowColLayout>
          {organizationType !== BusinessType.END_CUSTOMER && (
            <>
              <RowColLayout rowProps={{ classNames: 'mt-2' }}>
                <Dropdown
                  name="product"
                  required={true}
                  translator={t}
                  dropdownProps={{
                    responsiveMode: ResponsiveMode.large,
                    options: dropdownOptions,
                    label: t('TENANTS.TABLE.PRODUCT'),
                    placeholder: t('TENANTS.FORM.PRODUCT_PLACEHOLDER'),
                  }}
                />
              </RowColLayout>
              <RowColLayout rowProps={{ classNames: 'mt-2' }}>
                <Dropdown
                  name="region"
                  required={true}
                  translator={t}
                  dropdownProps={{
                    responsiveMode: ResponsiveMode.large,
                    label: t('TENANTS.FORM.REGION'),
                    placeholder: t('TENANTS.FORM.REGION_PLACEHOLDER'),
                    options: regions,
                  }}
                />
              </RowColLayout>
            </>
          )}
          {organizationType === BusinessType.END_CUSTOMER && (
            <>
              <RowColLayout rowProps={{ classNames: 'mt-2' }}>
                <Dropdown
                  name="environment"
                  required={true}
                  translator={t}
                  dropdownProps={{
                    responsiveMode: ResponsiveMode.large,
                    options: dropdownOptionsEndCustomers,
                    label: t('TENANTS.FORM.ENVIRONMENT'),
                    placeholder: t('TENANTS.FORM.ENVIRONMENT_PLACEHOLDER'),
                  }}
                />
              </RowColLayout>
              <RowColLayout rowProps={{ classNames: 'mt-2' }}>
                <Dropdown
                  name="analyzer"
                  required={true}
                  translator={t}
                  dropdownProps={{
                    responsiveMode: ResponsiveMode.large,
                    options: dropdownOptionsAnalyzers,
                    label: t('TENANTS.FORM.ANALYZER'),
                    placeholder: t('TENANTS.FORM.ANALYZER_PLACEHOLDER'),
                  }}
                />
              </RowColLayout>
            </>
          )}

          <RowColLayout rowProps={{ classNames: 'mt-2' }}>
            <Dropdown
              name="policyId"
              required={false}
              translator={t}
              dropdownProps={{
                responsiveMode: ResponsiveMode.large,
                label: t('TENANTS.FORM.POLICY'),
                placeholder: t('TENANTS.FORM.POLICY_PLACEHOLDER'),
                options: policiesOpts,
              }}
            />
          </RowColLayout>

          <FormActionsContainer>
            <FormActions
              submitProps={{
                text: t('general:BUTTON.CREATE'),
                iconProps: { iconName: 'Add' },
              }}
            />
          </FormActionsContainer>
        </form>
      </FormProvider>
      {renderConfirmDialog()}
    </>
  )
}
