import { ILabelStyles, Label } from '@fluentui/react'
import { PropsWithChildren } from 'react'

export const labelStyle: ILabelStyles = {
  root: {
    marginLeft: 4,
    fontWeight: 400,
    fontSize: 13,
    color: '#8e8e93',
  },
}

export const valueStyle: ILabelStyles = {
  root: {
    marginLeft: 4,
    marginBottom: 20,
    fontWeight: 'normal',
  },
}

interface Props {
  label: string
}

export const DisplayField = ({ label, children }: PropsWithChildren<Props>) => {
  return (
    <>
      <Label styles={labelStyle}>{label}</Label>
      <Label styles={valueStyle}>{children}</Label>
    </>
  )
}
