import { useMutation, useQueryClient } from '@tanstack/react-query'
import { gql } from 'graphql-request'
import graphClient from '../../query-client/graphClient'
import { Microservice } from '../../query-client/types'

const activateMicroserviceQuery = gql`
  mutation activateMicroservice($id: ID!, $businessId: ID!) {
    activateMicroservice(id: $id, businessId: $businessId) {
      _id
    }
  }
`

const activateMicroservice = async (id: string, businessId: string): Promise<Microservice> => {
  return graphClient.request(activateMicroserviceQuery, { id: id, businessId }).then((res) => res.activateMicroservice)
}

export const useActivateMicroservice = (businessId: string) => {
  const queryClient = useQueryClient()

  return useMutation(['activateMicroservice', businessId], (id: string) => activateMicroservice(id, businessId), {
    onSuccess: (id) => {
      queryClient.invalidateQueries(['microservices'])
      queryClient.invalidateQueries(['microservices', id])
      queryClient.invalidateQueries(['microservices', businessId])
      queryClient.invalidateQueries(['enabled-microservices'])
      queryClient.invalidateQueries(['enabled-microservice', id._id, businessId])
    },
  })
}
