import { useMutation, useQueryClient } from "@tanstack/react-query";
import { gql } from "graphql-request";
import graphClient from "../../query-client/graphClient";
import { CreateUserFormType } from "../../pages/organization-details/users/UserForm";

const createUserQuery = gql`
  mutation Request($businessId: ID!, $input: UserInput!) {
    createUser(businessId: $businessId, input: $input) {
      _id
      name
      userRole {
        _id
        name
      }
      language
      email
    }
  }
`

function createUser(user: CreateUserFormType, businessId: string) {
  return graphClient
    .request(createUserQuery, {
      businessId: businessId,
      input: user,
    })
    .then(response => response.createUser)
}

export default function (businessId: string) {
  const queryClient = useQueryClient()

  return useMutation(['createUser'], (user: CreateUserFormType) => createUser(user, businessId), {
    onSuccess: () => {
      queryClient.invalidateQueries(['getBusinessById'])
      queryClient.invalidateQueries(['getBusinessById', 'users'])
    }},
  )
}
