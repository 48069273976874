import { TFunction } from 'react-i18next'

export enum FlowType {
  SESSION = 'SESSION',
  WORKSPACE = 'WORKSPACE',
}

export const flowTypeOptions = (t: TFunction) =>
  Object.values(FlowType).map((type) => ({
    key: type,
    text: typeLabel(t)[type],
  }))

export const typeLabel = (t: TFunction): { [key in FlowType]: string } => ({
  [FlowType.SESSION]: t('flows:FLOW_TYPE.SESSION'),
  [FlowType.WORKSPACE]: t('flows:FLOW_TYPE.WORKSPACE'),
})
