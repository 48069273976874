import { Breadcrumb, LoadingSpinner } from '@flexxibleit/flexxible-ui'
import { IBreadcrumbItem } from '@fluentui/react'
import React, { ReactNode } from 'react'
import { ContentHeader } from '../content-header/content-header'

interface Props {
  children: ReactNode
  title: string
  titleComponent?: ReactNode
  isLoading: boolean
  breadcrumbItems?: IBreadcrumbItem[] | undefined
  titleTag?: string
}

const PageBody = ({ children, title, titleTag, titleComponent, breadcrumbItems, isLoading }: Props) => {
  return (
    <div style={{ display: 'flex', flexDirection: 'column', height: '100vh' }}>
      <div
        style={{
          padding: '85px 15px 30px 15px',
          height: isLoading ? '100vh' : '',
        }}
      >
        {isLoading ? (
          <LoadingSpinner></LoadingSpinner>
        ) : (
          <>
            {breadcrumbItems && <Breadcrumb items={breadcrumbItems} />}
            {titleComponent ? titleComponent : <ContentHeader title={title} titleTag={titleTag} />}
            {children}
          </>
        )}
      </div>
    </div>
  )
}

export default PageBody
