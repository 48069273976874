import { LoadingSpinner } from '@flexxibleit/flexxible-ui'
import { useState } from 'react'
import { GroupedWorkspacesReadMode } from './GroupedWorkspacesReadMode'
import { GroupedWorkspacesEditMode } from './GroupedWorkspacesEditMode'
import { Workspace, WorkspaceGroup } from '../../../query-client/types'
import { ColumnActionsMode, IColumn } from '@fluentui/react'
import { useTranslation } from 'react-i18next'

interface Props {
  workspaceGroup?: WorkspaceGroup
}

export const GroupedWorkspaces = (props: Props) => {
  const { t } = useTranslation('organization_details')

  const getColumns = (): IColumn[] => {
    function getUTypeDisplayValue(uType?: string): string {
      if (!uType) {
        return ''
      }
      if (uType.toLowerCase() == 'physical') {
        return 'Physical'
      }
      if (uType.toLowerCase() == 'virtual') {
        return 'Virtual'
      }
      return uType
    }

    return [
      {
        key: 'name',
        name: t('WORKSPACE_GROUPS.TABLE.NAME'),
        fieldName: 'name',
        minWidth: 100,
        isRowHeader: true,
        onRender: ({ name }: Workspace) => {
          return <span>{name}</span>
        },
        isPadded: true,
        columnActionsMode: ColumnActionsMode.disabled,
      },
      {
        key: 'fqdn',
        name: t('WORKSPACE_GROUPS.TABLE.FQDN'),
        fieldName: 'fqdn',
        minWidth: 260,
        isRowHeader: true,
        onRender: ({ fqdn }: Workspace) => {
          return <span>{fqdn}</span>
        },
        isPadded: true,
        columnActionsMode: ColumnActionsMode.disabled,
      },
      {
        key: 'ipAddress',
        name: t('WORKSPACE_GROUPS.TABLE.IP_ADDRESS'),
        fieldName: 'ipAddress',
        minWidth: 100,
        isRowHeader: true,
        onRender: ({ ipAddress }: Workspace) => {
          return <span>{ipAddress}</span>
        },
        isPadded: true,
        columnActionsMode: ColumnActionsMode.disabled,
      },
      {
        key: 'uOperativeSystem',
        name: t('WORKSPACE_GROUPS.TABLE.U_OPERATIVE_SYSTEM'),
        fieldName: 'uOperativeSystem',
        minWidth: 200,
        isRowHeader: true,
        onRender: ({ uOperativeSystem }: Workspace) => {
          return <span>{uOperativeSystem}</span>
        },
        isPadded: true,
        columnActionsMode: ColumnActionsMode.disabled,
      },
      {
        key: 'uCoresCpu',
        name: t('WORKSPACE_GROUPS.TABLE.U_CORES_CPU'),
        fieldName: 'uCoresCpu',
        minWidth: 50,
        isRowHeader: true,
        onRender: ({ uCoresCpu }: Workspace) => {
          return <span>{uCoresCpu}</span>
        },
        isPadded: true,
        columnActionsMode: ColumnActionsMode.disabled,
      },
      {
        key: 'uRam',
        name: t('WORKSPACE_GROUPS.TABLE.U_RAM'),
        fieldName: 'uRam',
        minWidth: 50,
        isRowHeader: true,
        onRender: ({ uRam }: Workspace) => {
          return <span>{uRam}</span>
        },
        isPadded: true,
        columnActionsMode: ColumnActionsMode.disabled,
      },
      {
        key: 'uType',
        name: t('WORKSPACE_GROUPS.TABLE.U_TYPE'),
        fieldName: 'uType',
        minWidth: 50,
        isRowHeader: true,
        onRender: ({ uType }: Workspace) => {
          return <span>{getUTypeDisplayValue(uType)}</span>
        },
        isPadded: true,
        columnActionsMode: ColumnActionsMode.disabled,
      },
      {
        key: 'uLastUser',
        name: t('WORKSPACE_GROUPS.TABLE.U_LAST_USER'),
        fieldName: 'uLastUser',
        minWidth: 180,
        isRowHeader: true,
        onRender: ({ uLastUser }: Workspace) => {
          return <span>{uLastUser}</span>
        },
        isPadded: true,
        columnActionsMode: ColumnActionsMode.disabled,
      },
    ]
  }

  const [editModeState, setEditModeState] = useState<boolean>(false)

  if (!props.workspaceGroup || !props.workspaceGroup.business) {
    return <LoadingSpinner />
  }

  if (editModeState) {
    return (
      <GroupedWorkspacesEditMode
        workspaceGroup={props.workspaceGroup!}
        organization={props.workspaceGroup.business!}
        onExitEditMode={() => setEditModeState(false)}
        getColumns={getColumns}
      />
    )
  }

  return (
    <GroupedWorkspacesReadMode
      workspaceGroupId={props.workspaceGroup._id!}
      type={props.workspaceGroup.type!}
      organization={props.workspaceGroup.business!}
      onEnterEditMode={() => setEditModeState(true)}
      getColumns={getColumns}
    />
  )
}
