import { Route, Routes } from 'react-router-dom'
import { NavigateToMainPage } from './NavigateToMainPage'
import { QueryParamProvider } from 'use-query-params'
import { ReactRouter6Adapter } from 'use-query-params/adapters/react-router-6'
import { ProductsPage } from 'app/pages/products/product.page'
import { GlobalProvider } from 'app/globals/context/GlobalContext'
import { LoginPage } from 'app/pages/login/Login.page'
import { RequireAuth } from 'app/authentication/RequireAuth'
import App from 'App'
import { HomePage } from 'app/pages/home/home.page'
import { OrganizationPage } from 'app/pages/organizations/organizations.page'
import { BusinessDetailPage } from 'app/pages/organization-details/organization-detail.page'
import NotFound from 'app/pages/not-found/not-found.page'
import { WorkspaceGroupDetailPage } from '../pages/organization-details/workspace-groups/workspace-group-detail.page'
import { WorkspaceGroupsPage } from '../pages/workspace-groups/workspace-groups.page'
import { ReportsExplorerPage } from '../pages/report-explorer/report-explorer.page'
import { ReportsExplorerOrganizationPage } from '../pages/report-explorer/report-explorer-organization.page'
import { UserSettingsPage } from '../pages/user-settings/UserSettings.page'
import { MicroserviceDetailsPage } from '../pages/microservices/details/MicroserviceDetailsPage'
import { MicroserviceCreationPage } from '../pages/microservices/create/MicroserviceCreationPage'
import { MicroserviceEditOverviewPage } from '../pages/microservices/details/overview/MicroserviceEditOverviewPage'
import { MicroservicesAuditLogDetailsPage } from 'app/pages/microservices/audit-log/MicroservicesAuditLogDetailsPage'
import { FeedbackMessageProvider } from '../context/feedback-message/FeedbackMessageContext'
import EnabledMicroservicePage from 'app/pages/microservices/details/EnabledMicroservicePage'
import { MicroservicesMarketplacePage } from 'app/pages/microservices/marketplace/MicroservicesMarketplacePage'
import { EnabledMicroservicesFeature } from 'app/pages/microservices/enabled/EnabledMicroservicesFeature'
import { MicroservicesDesignerPage } from 'app/pages/microservices/designer/MicroservicesDesignerPage'
import { MicroservicesAuditLogPage } from 'app/pages/microservices/audit-log/MicroservicesAuditLogPage'
import PolicyDetailPage from 'app/pages/organization-details/policies/PolicyDetailPage'
import { OrgWorkspaceGroupsPage } from '../pages/organization-details/workspace-groups/OrgWorkspaceGroups.page'
import { OrgUsersPage } from '../pages/organization-details/users/OrgUsers.page'
import { OrgProductsPage } from '../pages/organization-details/products/OrgProducts.page'
import { OrgModulesPage } from '../pages/organization-details/modules/OrgModules.page'
import { OrgIntegrationsPage } from '../pages/organization-details/integrations/OrgIntegrations.page'
import { OrgReportingGroupsPage } from '../pages/organization-details/reporting-groups/OrgReportingGroups.page'
import { OrgTenantsPage } from '../pages/organization-details/tenants/OrgTenants.page'
import { OrgElegibleProductsPage } from '../pages/organization-details/elegible-products/OrgElegibleProducts.page'
import { OrgPoliciesPage } from '../pages/organization-details/policies/OrgPolicies.page'
import { FlexxBuildOperationsLogPage } from 'app/pages/flexxbuild/operations-log/FlexxBuildOperationsLogPage'
import { ErrorBoundary } from '@sentry/react'
import { ErrorPage } from 'app/components/error-boundary/ErrorPage'
import { FlowsPage } from 'app/pages/flow/flows.page'
import { FlowCreationPage } from 'app/pages/flow/create/FlowCreationPage'
import { FlowDetailsPage } from 'app/pages/flow/details/FlowDetail'
import { FlowEditOverviewPage } from 'app/pages/flow/details/overview/FlowEditOverviewPage'
import { OperationsPage } from 'app/pages/efficiency_operations/efficiency-operations.page'
import { PatchesPage } from '../pages/patch-management/patches/PatchesPage'
import { PMReportingGroupsPage } from '../pages/patch-management/reporting-groups/PMReportingGroupsPage'
import { SecurityPage } from '../pages/patch-management/security/SecurityPage'
import { PMWorkspacesPage } from '../pages/patch-management/workspaces/PMWorkspacesPage'
import { PatchPolicyTargetDetails } from 'app/pages/patch-management/targets/PatchPolicyTargetDetailsPage'
import { WindowsPatchPoliciesPage } from 'app/pages/patch-management/windows-patch-policies/WindowsPatchPoliciesPage'
import { PatchPoliciesTargetsPage } from 'app/pages/patch-management/targets/PatchPolicyTargets'
import { WindowsPatchPolicyDetailsPage } from 'app/pages/patch-management/windows-patch-policies/WindowsPatchPolicyDetailsPage'
import { PatchManagementSummaryPage } from '../pages/patch-management/./summary/PatchManagementSummaryPage'
import { PatchPolicyTargetsPage } from '../pages/patch-management/patch-policy-targets/PatchPolicyTargetsPage'
import { LinuxPatchPoliciesPage } from 'app/pages/patch-management/linux-path-policies/LinuxPatchPoliciesPage'
import { LinuxPatchPolicyDetails } from 'app/pages/patch-management/linux-path-policies/LinuxPatchPolicyDetails'
import { ActivationsTenantsFeature } from 'app/pages/organization-details/tenants/activations/ActivationsTenants'
import { WorkspacesPage } from 'app/pages/workspaces/workspaces.page'
import { MicroserviceType } from 'app/models/microservices/MicroserviceType'
import RolDetailPage from 'app/pages/organization-details/roles/RolDetailPage'
import { OrgRolesPage } from 'app/pages/organization-details/roles/OrgRoles.page'
import { UserLoginsPage } from '../pages/user-logins/UserLogins.page'
import ReportingGroupsDetailPage from 'app/pages/organization-details/reporting-groups/ReportingGroupsDetailPage'
import { KeepOrganizationSelected } from './KeepOrganizationSelected'
import { analyzerRoutes } from '../analyzer/routes'
import * as Sentry from '@sentry/react'
import { KeepQueryParams } from './KeepQueryParams'

export const Router = () => {
  const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes)

  return (
    <GlobalProvider>
      <QueryParamProvider adapter={ReactRouter6Adapter}>
        <SentryRoutes>
          <Route path="/login" element={<LoginPage />} />
          <Route
            path="/"
            element={
              <ErrorBoundary
                fallback={({ error, componentStack, resetError }) => {
                  return <ErrorPage error={error} resetError={resetError} />
                }}
              >
                <RequireAuth>
                  <FeedbackMessageProvider>
                    <App />
                    <KeepQueryParams />
                  </FeedbackMessageProvider>
                </RequireAuth>
              </ErrorBoundary>
            }
          >
            <Route
              index
              element={
                <RequireAuth>
                  <NavigateToMainPage />
                </RequireAuth>
              }
            />
            <Route path="/home" element={<HomePage />} />
            <Route path="/flows" element={<FlowsPage />} />
            <Route path="/flows/create" element={<FlowCreationPage />} />
            <Route path="/flows/:flowId" element={<FlowDetailsPage />} />
            <Route path="/flows/:flowId/edit" element={<FlowEditOverviewPage />} />
            <Route path="/operations" element={<OperationsPage />} />
            <Route path="/products" element={<ProductsPage />} />
            <Route path="/products/:productId" element={<ReportsExplorerPage />} />
            <Route path="/organizations" element={<OrganizationPage />} />
            <Route path="/organizations/:organizationId" element={<KeepOrganizationSelected />}>
              <Route index element={<BusinessDetailPage />} />
              <Route path="information" element={<BusinessDetailPage />} />
              <Route
                path="workspace-groups/:workspaceGroupId"
                element={<WorkspaceGroupDetailPage origin="organization_details" />}
              />
              <Route path=":productId" element={<ReportsExplorerOrganizationPage />} />
            </Route>
            <Route path="/organization/:organizationId" element={<KeepOrganizationSelected />}>
              <Route index element={<BusinessDetailPage />} />
              <Route path="elegible-products" element={<OrgElegibleProductsPage />} />
              <Route path="information" element={<BusinessDetailPage />} />
              <Route path="integrations" element={<OrgIntegrationsPage />} />
              <Route path="modules" element={<OrgModulesPage />} />
              <Route path="patch-management">
                <Route path="dashboard" element={<PatchManagementSummaryPage />} />
                <Route path="linux-patch-policies" element={<LinuxPatchPoliciesPage />} />
                <Route path="linux-patch-policies/:linuxPatchPolicyId" element={<LinuxPatchPolicyDetails />} />
                <Route path="patch-policy-targets" element={<PatchPoliciesTargetsPage />} />
                <Route path="patch-policy-targets/:patchPolicyTargetId" element={<PatchPolicyTargetDetails />} />
                <Route path="patches" element={<PatchesPage />} />
                <Route path="reporting-groups" element={<PMReportingGroupsPage />} />
                <Route path="reporting-groups" element={<PMReportingGroupsPage />} />
                <Route path="security" element={<SecurityPage />} />
                <Route path="summary" element={<PatchManagementSummaryPage />} />
                <Route path="targets" element={<PatchPolicyTargetsPage />} />
                <Route path="windows-patch-policies" element={<WindowsPatchPoliciesPage />} />
                <Route
                  path="windows-patch-policies/:windowsPatchPolicyId"
                  element={<WindowsPatchPolicyDetailsPage />}
                />
                <Route path="workspaces" element={<PMWorkspacesPage />} />
              </Route>
              <Route path="policies" element={<OrgPoliciesPage />} />
              <Route path="policy" element={<PolicyDetailPage />} />
              <Route path="policy/:policyId" element={<PolicyDetailPage />} />
              <Route path="products" element={<OrgProductsPage />} />
              <Route path="reporting-groups" element={<OrgReportingGroupsPage />} />
              <Route path="reporting-groups/:reportingGroupId" element={<ReportingGroupsDetailPage />} />
              <Route path="roles" element={<OrgRolesPage />} />
              <Route path="roles/:userRoleId" element={<RolDetailPage />} />
              <Route path="tenants" element={<OrgTenantsPage />} />
              <Route path="tenants/activations" element={<ActivationsTenantsFeature />} />
              <Route path="users" element={<OrgUsersPage />} />
              <Route path="workspace-groups" element={<OrgWorkspaceGroupsPage />} />
            </Route>
            <Route path="/workspace-groups" element={<WorkspaceGroupsPage />} />
            <Route path="/workspaces" element={<WorkspacesPage />} />
            <Route
              path="/workspace-groups/:workspaceGroupId"
              element={<WorkspaceGroupDetailPage origin="workspace_groups" />}
            />
            <Route path="/logins" element={<UserLoginsPage />} />
            <Route path="/settings" element={<UserSettingsPage />} />
            <Route path="/microservices-enabled" element={<EnabledMicroservicesFeature />} />
            <Route path="/microservices-marketplace" element={<MicroservicesMarketplacePage />} />
            <Route path="/microservices-designer">
              <Route index element={<MicroservicesDesignerPage />} />
              <Route path="create" element={<MicroserviceCreationPage type={MicroserviceType.STANDARD} />} />
              <Route path="create/condition" element={<MicroserviceCreationPage type={MicroserviceType.CONDITION} />} />
              <Route
                path="create/custom-fields"
                element={<MicroserviceCreationPage type={MicroserviceType.CUSTOM_FIELDS} />}
              />
              <Route path=":microserviceId" element={<MicroserviceDetailsPage />} />
              <Route path=":microserviceId/edit" element={<MicroserviceEditOverviewPage />} />
            </Route>

            <Route path="/microservices/:microserviceId" element={<EnabledMicroservicePage />} />
            <Route path="/microservices-events" element={<MicroservicesAuditLogPage />} />
            <Route path="/microservices-events/:microserviceEventId" element={<MicroservicesAuditLogDetailsPage />} />
            <Route path="/flexxbuild-operations-logs" element={<FlexxBuildOperationsLogPage />} />
            {analyzerRoutes.map((route) => (
              <Route key={route.path} path={route.path} element={<route.component />} />
            ))}
          </Route>
          <Route path="*" element={<NotFound />} />
        </SentryRoutes>
      </QueryParamProvider>
    </GlobalProvider>
  )
}
