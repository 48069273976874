import { PaginatedTable, LoadingSpinner } from '@flexxibleit/flexxible-ui'
import { RolePermission, UserRole } from 'app/query-client/types'
import { useTranslation } from 'react-i18next'
import { useRolePermissionsFilters } from './useRolePermissionsFilters'
import { usePaginatedRolePermissionsQuery } from './usePaginatedRolePermissionsQuery'
import usePaginatedData from 'app/hooks/usePaginatedData'
import { useRolePermissionsBuildTable } from './useRolesPermissionsBuildTable'
import { useRolePermissionsActions } from './useRolePermissionsActions'
import { EmptyRolePermissions } from './EmptyRolePermissions'

interface Props {
  userRole: UserRole
}

export const RolPermissions = ({ userRole }: Props) => {
  const { t, i18n } = useTranslation('organization_details')
  const { filterFields } = useRolePermissionsFilters({ t })
  const { query } = usePaginatedRolePermissionsQuery()
  const {
    data: paginatedRolePermissions,
    isFetching,
    isLoading,
  } = usePaginatedData<RolePermission>(query, 'getPaginatedRolePermissions', filterFields, {
    userRoleId: userRole?._id,
    organizationId: userRole?.organizationId,
  })

  // Actions and columns
  const { commandBarActions, openCreatePermissionForm } = useRolePermissionsActions({
    t,
    userRole,
  })

  const { columns } = useRolePermissionsBuildTable({ t, i18n, userRole })

  return (
    <div style={{ display: 'flex', flexDirection: 'column', height: '76vh' }}>
      <div
        style={{
          padding: '16px',
          display: 'flex',
          flexDirection: 'column',
          overflow: 'hidden',
          height: '100%',
        }}
      >
        {isLoading ? (
          <LoadingSpinner></LoadingSpinner>
        ) : (
          <>
            <PaginatedTable
              tableId="role-permissions"
              locale={i18n.language}
              paginatedData={paginatedRolePermissions}
              columns={columns}
              filters={{
                filterFields,
                smartSearch: true,
                advancedSearch: true,
              }}
              commands={commandBarActions}
              isFetching={isFetching}
              emptyInitialLoadContent={<EmptyRolePermissions {...{ t, onAddClicked: openCreatePermissionForm }} />}
            />
          </>
        )}
      </div>
    </div>
  )
}
