import { Card, Col, MOBILE_QUERY, Row, RowColLayout, useMediaQuery } from '@flexxibleit/flexxible-ui'
import {
  Dropdown,
  IColumn,
  IDropdownOption,
  Label,
  Link,
  Pivot,
  PivotItem,
  SearchBox,
  Selection,
  SelectionMode,
  Spinner,
  Stack,
} from '@fluentui/react'
import SubmitFormButton from 'app/components/forms/FormActions/SubmitFormButton'
import { useApproveWindowsPatches } from 'app/hooks/patchPolicies/useApproveWindowsPatches'
import { useDeclineWindowsPatches } from 'app/hooks/patchPolicies/useDeclineWindowsPatches'
import useGetWindowsPatchCategories from 'app/hooks/patchPolicies/useGetWindowsPatchCategories'
import useGetWindowsPatches from 'app/hooks/patchPolicies/useGetWindowsPatches'
import useGetWindowsProducts from 'app/hooks/patchPolicies/useGetWindowsProducts'
import { PaginationArgs, WindowsPatchFilter, WindowsPatchPolicy, WindowsPatches } from 'app/query-client/types'
import { CSSProperties, FormEvent, useMemo, useRef, useState } from 'react'
import { TFunction, useTranslation } from 'react-i18next'
import { DateFormat, dateFormatter } from '../../../../services/date-formatter'
import { useFeedbackMessage } from 'app/context/feedback-message/FeedbackMessageContext'
import Table from 'app/components/Table/Table.component'
import { usePaginatedSelection } from 'app/hooks/utils/usePaginatedSelection'
import { SearchableDropdown } from 'app/components/SearchableDropdown/SearchableDropdown.component'
import { debounce } from 'lodash'
import { DateTime } from 'luxon'
import DatePicker from 'app/components/DatePicker/DatePicker'
import { FeatureRender } from '../../../../permissions/FeatureRender'
import { FEATURE_NAMES } from '../../../../permissions/FeatureName.enum'

interface WindowsPatchPolicyPatchesTabProps {
  windowsPatchPolicy: WindowsPatchPolicy
}

interface Selector {
  key: string
  text?: string
  selected?: boolean
}

const LoadingButton = ({
  text,
  handleClick,
  isLoading,
  disabled,
  iconName,
}: {
  text: string
  handleClick: () => void
  isLoading: boolean
  disabled: boolean
  iconName: string
}) => {
  return isLoading ? (
    <SubmitFormButton className="mr-2" text={text} onRenderIcon={() => <Spinner />} disabled={true} />
  ) : (
    <SubmitFormButton
      className="mr-2"
      text={text}
      iconProps={{
        iconName: iconName,
      }}
      onClick={handleClick}
      disabled={disabled}
    />
  )
}

export const WindowsPatchPolicyPatchesTab = ({ windowsPatchPolicy }: WindowsPatchPolicyPatchesTabProps) => {
  const { t } = useTranslation('patch_management')
  const [pivotSelectedKeyState, setPivotSelectedKeyState] = useState<string>('all')
  const [selectedItems, setSelectedItems] = useState<WindowsPatches[]>([])
  const [paginationState, setPaginationState] = useState<PaginationArgs>(() => ({
    page: 1,
    perPage: 100,
  }))
  const [filter, setFilter] = useState<WindowsPatchFilter>({
    categories: [],
    products: [],
    status: null,
    search: '',
  })
  const [selectedCategory, setSelectedCategory] = useState<Selector[]>([])
  const [selectedProduct, setSelectedProduct] = useState<Selector[]>([])
  const [selectedSuperseded, setSelectedSuperseded] = useState<string | null>(null)
  const [selectedReleaseDate, setSelectedReleaseDate] = useState<Date | undefined>(undefined)

  const {
    data: patchesPagination,
    isLoading,
    isFetching,
  } = useGetWindowsPatches(windowsPatchPolicy._id, filter, paginationState)

  const { data: products } = useGetWindowsProducts()
  const { data: categories } = useGetWindowsPatchCategories()
  const approvePatches = useApproveWindowsPatches()
  const declinePatches = useDeclineWindowsPatches()
  const [isMobile] = useMediaQuery(MOBILE_QUERY)
  const { setSuccessMessage, setErrorMessage } = useFeedbackMessage()

  const patchesPage: WindowsPatches[] = useMemo(() => patchesPagination?.results || [], [patchesPagination])

  const productOptions = [...(products ? products.map((product) => ({ key: product.id, text: product.title })) : [])]
  const categoryOptions = [
    ...(categories ? categories.map((category) => ({ key: category.id, text: category.title })) : []),
  ]
  const supersededOptions: IDropdownOption<boolean>[] = [
    { key: 'CLEAR', text: '-', data: undefined },
    { key: 'yes', text: t('general:YES'), data: true },
    { key: 'no', text: t('general:NO'), data: false },
  ]

  const formatFilterDate = (date?: Date) => {
    if (!date) return ''

    return dateFormatter(t, date, DateFormat.Medium)
  }

  const allColumns: IColumn[] = [
    {
      key: 'kb',
      name: 'KB',
      fieldName: 'kb',
      minWidth: 60,
      maxWidth: 80,
      isRowHeader: true,
    },
    {
      key: 'title',
      name: t('PATCH_POLICIES.TABLE.TITLE'),
      fieldName: 'title',
      minWidth: 100,
      isRowHeader: true,
      onRender: (item: WindowsPatches) => {
        if (!item?.infoUrl) {
          return item.title
        } else {
          return (
            <Link
              target="_blank"
              onClick={() => {
                window.open(item?.infoUrl, '_blank')
              }}
            >
              {item.title}
            </Link>
          )
        }
      },
    },
    {
      key: 'status',
      name: t('PATCH_POLICIES.TABLE.STATUS'),
      minWidth: 80,
      maxWidth: 100,
      isRowHeader: true,
      fieldName: 'status',
      onRender: (item: WindowsPatches) => {
        return <StatusBadge type={item.status} t={t} />
      },
    },

    {
      key: 'product',
      name: t('PATCH_POLICIES.TABLE.PRODUCT'),
      minWidth: 200,
      maxWidth: 200,
      isRowHeader: true,
      fieldName: 'product',
    },

    {
      key: 'severity',
      name: t('PATCH_POLICIES.TABLE.SEVERITY'),
      minWidth: 70,
      maxWidth: 150,
      isRowHeader: true,
      fieldName: 'severity',
      onRender: (item: WindowsPatches) => {
        return (
          <div style={{ textAlign: 'center' }}>
            {item && item.severity && item.severity.toLowerCase() !== 'unspecified' ? item.severity : '-'}
          </div>
        )
      },
    },
    {
      key: 'creationDate',
      name: t('PATCH_POLICIES.TABLE.DATE'),
      minWidth: 100,
      maxWidth: 200,
      isRowHeader: true,
      fieldName: 'creationDate',
      onRender: (item: WindowsPatches) => {
        return (
          <div>{item && item.creationDate ? dateFormatter(t, new Date(item.creationDate), DateFormat.Short) : ''}</div>
        )
      },
    },
    {
      key: 'category',
      name: t('PATCH_POLICIES.TABLE.CATEGORY'),
      minWidth: 100,
      maxWidth: 150,
      isRowHeader: true,
      fieldName: 'category',
    },
  ]

  const approvedColumns: IColumn[] = [
    {
      key: 'approvedAt',
      name: t('PATCH_POLICIES.TABLE.APPROVAL_DATE'),
      minWidth: 100,
      maxWidth: 200,
      isRowHeader: true,
      fieldName: 'approvedAt',
      onRender: (item: WindowsPatches) => {
        return (
          <div>{item && item?.approvedAt ? dateFormatter(t, new Date(item.approvedAt), DateFormat.Short) : ''}</div>
        )
      },
    },
    {
      key: 'approvedBy',
      name: t('PATCH_POLICIES.TABLE.APPROVAL_USER'),
      minWidth: 100,
      maxWidth: 200,
      isRowHeader: true,
      fieldName: 'approvedBy',
      onRender: (item: WindowsPatches) => {
        if (item.automatic) {
          return t('PATCH_POLICIES.TABLE.AUTOMATIC_APPROVED')
        }
        return (
          <div>
            {item?.approvedBy?.name} {item?.approvedBy?.surname}
          </div>
        )
      },
    },
  ]

  const declinedColumns: IColumn[] = [
    {
      key: 'declinedAt',
      name: t('PATCH_POLICIES.TABLE.DECLINE_DATE'),
      minWidth: 100,
      maxWidth: 200,
      isRowHeader: true,
      fieldName: 'declinedAt',
      onRender: (item: WindowsPatches) => {
        return (
          <div>{item && item?.declinedAt ? dateFormatter(t, new Date(item.declinedAt), DateFormat.Short) : ''}</div>
        )
      },
    },
    {
      key: 'declinedBy',
      name: t('PATCH_POLICIES.TABLE.DECLINE_USER'),
      minWidth: 100,
      maxWidth: 200,
      isRowHeader: true,
      fieldName: 'declinedBy',
      onRender: (item: WindowsPatches) => {
        if (item?.automatic) {
          return t('PATCH_POLICIES.TABLE.AUTOMATIC_DECLINED')
        }
        return (
          <div>
            {item?.declinedBy?.name} {item?.declinedBy?.surname}
          </div>
        )
      },
    },
  ]

  const [columns, setColumns] = useState<IColumn[]>(allColumns)

  const selection = useRef(usePaginatedSelection((item: WindowsPatches) => item.id, setSelectedItems))

  const hasSelectedPatches = (filter: '' | 'NOT_APPROVED' | 'APPROVED' | 'DECLINED' = '') => {
    if (selectedItems.length === 0) {
      return false
    }
    if (filter === '') {
      return selectedItems.length > 0
    }
    const filtered = selectedItems.filter((item: WindowsPatches) => item.status === filter)
    return filtered.length > 0
  }

  const handleApproveClick = () => {
    const windowsPatchesToApprove = selectedItems.filter(
      (item: WindowsPatches) => item.status === 'NOT_APPROVED' || item.status === 'DECLINED'
    )
    const validArray = windowsPatchesToApprove.map((item: WindowsPatches) => item.id)

    if (windowsPatchesToApprove.length > 0) {
      try {
        approvePatches.mutate(
          {
            windowsPatchPolicyId: windowsPatchPolicy._id,
            patches: validArray,
          },
          {
            onSuccess: () => {
              selection.current.setAllSelected(false)
              setSelectedItems([])
              setSuccessMessage(t('PATCH_POLICIES.FEEDBACK.PATCH_APPROVED'))
            },
            onError: () => {
              setErrorMessage(t('PATCH_POLICIES.FEEDBACK.PATCH_APPROVE_ERROR'))
            },
          }
        )
      } catch (error) {
        console.log(error)
      }
    }
  }

  const handleDeclineClick = () => {
    const windowsPatchesToDecline = selectedItems.filter(
      (item: any) => item.status === 'APPROVED' || item.status === 'NOT_APPROVED'
    )
    const validArray = windowsPatchesToDecline.map((item: any) => item.id)
    try {
      declinePatches.mutate(
        {
          windowsPatchPolicyId: windowsPatchPolicy._id,
          patches: validArray,
        },
        {
          onSuccess: () => {
            selection.current.setAllSelected(false)
            setSelectedItems([])
            setSuccessMessage(t('PATCH_POLICIES.FEEDBACK.PATCH_DECLINED'))
          },
          onError: () => {
            setErrorMessage(t('PATCH_POLICIES.FEEDBACK.PATCH_DECLINE_ERROR'))
          },
        }
      )
    } catch (error) {
      console.log(error)
    }
  }

  const handlePivotOnLinkClick = (item?: PivotItem) => {
    if (!item?.props?.itemKey) {
      return
    }
    switch (item.props.itemKey) {
      case 'all':
        setColumns(allColumns)
        break
      case 'APPROVED':
        const titleIndex = allColumns.findIndex((column) => column.key === 'title')
        const beforeTitle = allColumns.slice(0, titleIndex + 1)
        const afterTitle = allColumns.slice(titleIndex + 1)
        const combinedColumns = [...beforeTitle, ...approvedColumns, ...afterTitle]
        setColumns(combinedColumns)
        break
      case 'DECLINED':
        const titleIndexD = allColumns.findIndex((column) => column.key === 'title')
        const beforeTitleD = allColumns.slice(0, titleIndexD + 1)
        const afterTitleD = allColumns.slice(titleIndexD + 1)
        const combinedColumnsD = [...beforeTitleD, ...declinedColumns, ...afterTitleD]
        setColumns(combinedColumnsD)
        break
      case 'NOT_APPROVED':
        setColumns(allColumns)
    }

    const status = item.props.itemKey === 'all' ? null : item.props.itemKey
    setFilter({
      ...filter,
      status: status,
    })

    setPivotSelectedKeyState(item.props.itemKey)
  }

  const onChangeDropdownProducts = (event: FormEvent, option: any) => {
    let newSelectedProducts

    if (option.selected) {
      newSelectedProducts = [...selectedProduct, option]
    } else {
      newSelectedProducts = selectedProduct.filter((category) => category.key !== option.key)
    }

    setSelectedProduct(newSelectedProducts)

    setFilter({
      ...filter,
      products: newSelectedProducts.map((product) => product.text),
    })
  }

  const onChangeDropdownCategories = (event: FormEvent, option: any) => {
    let newSelectedCategories

    if (option.selected) {
      newSelectedCategories = [...selectedCategory, option]
    } else {
      newSelectedCategories = selectedCategory.filter((category) => category.key !== option.key)
    }

    setSelectedCategory(newSelectedCategories)

    setFilter({
      ...filter,
      categories: newSelectedCategories.map((category) => category.text),
    })
  }
  const onChangeDropdownSuperseded = (event: FormEvent, option: any) => {
    if (option.key === 'CLEAR') {
      setSelectedSuperseded(null)
    } else {
      setSelectedSuperseded(option.key)
    }

    setFilter({
      ...filter,
      superseded: option.data,
    })
  }

  const handleOnPaginationChange = (newPaginationState: PaginationArgs) => {
    setPaginationState(newPaginationState)
  }

  const handleOnSearchBoxChange = debounce((e?: unknown, value?: string) => {
    setFilter({
      ...filter,
      search: value,
    })
  }, 500)

  const onClearDatePicker = () => {
    setSelectedReleaseDate(undefined)
    setFilter({
      ...filter,
      releaseDate: undefined,
    })
  }

  const formatDatetime = (datetime: string) =>
    datetime ? dateFormatter(t, new Date(datetime), DateFormat.DATETIME) : null

  return (
    <>
      <Row>
        <Col className="col-xs-12 col-lg-3">
          <RowColLayout rowProps={{ classNames: 'mt-2' }}>
            <Dropdown
              label={t('PATCH_POLICIES.FORM.CATEGORIES')}
              placeholder={t('PATCH_POLICIES.FORM.CATEGORIES_PLACEHOLDER')}
              options={categoryOptions}
              onChange={onChangeDropdownCategories}
              selectedKeys={selectedCategory.map((category) => category.key)}
              multiSelect
            />
          </RowColLayout>
        </Col>
        <Col className="col-xs-12 col-lg-3">
          <RowColLayout rowProps={{ classNames: 'mt-2' }}>
            <SearchableDropdown
              dropdownProps={{
                label: t('PATCH_POLICIES.FORM.PRODUCTS'),
                placeholder: t('PATCH_POLICIES.FORM.PRODUCTS_PLACEHOLDER'),
                options: productOptions,
                multiSelect: true,
                selectedKeys: selectedProduct.map((product) => product.key),
                onChange: onChangeDropdownProducts,
                styles: {
                  dropdownItemsWrapper: {
                    maxHeight: '400px',
                  },
                },
              }}
              searchBoxProps={{
                placeholder: t('PATCH_POLICIES.FORM.SEARCH_PRODUCT_PLACEHOLDER'),
              }}
            />
          </RowColLayout>
        </Col>
        <Col className="col-xs-12 col-lg-3">
          <RowColLayout rowProps={{ classNames: 'mt-2' }}>
            <Dropdown
              label={t('PATCH_POLICIES.FORM.SUPERSEDED')}
              placeholder={t('PATCH_POLICIES.FORM.SUPERSEDED_PLACEHOLDER')}
              options={supersededOptions}
              onChange={onChangeDropdownSuperseded}
              selectedKey={selectedSuperseded}
            />
          </RowColLayout>
        </Col>
        <Col className="col-xs-12 col-lg-3">
          <RowColLayout rowProps={{ classNames: 'mt-2' }}>
            <Label>{t('PATCH_POLICIES.FORM.RELEASE_DATE')}</Label>
            <div>
              <DatePicker
                onClear={onClearDatePicker}
                showCloseButton={false}
                showGoToToday={false}
                value={selectedReleaseDate}
                style={{ width: '100%' }}
                formatDate={formatFilterDate}
                maxDate={DateTime.now().endOf('day').toJSDate()}
                placeholder={t('general:FROM')}
                onSelectDate={(date: Date | null | undefined) => {
                  if (date) {
                    setSelectedReleaseDate(date)
                    setFilter({
                      ...filter,
                      releaseDate: date,
                    })
                  }
                }}
              />
            </div>
          </RowColLayout>
        </Col>
      </Row>

      <Row>
        <Col className="col-xs-12 col-lg-12">
          <Row>
            <Col className="col-xs-12 col-lg-12">
              <>
                <div>
                  <Stack
                    horizontal={true}
                    horizontalAlign="space-between"
                    verticalAlign="center"
                    tokens={{ padding: '20px 0 0 0' }}
                    className="mb-sm-2"
                    style={{ minHeight: '70px', flexFlow: isMobile ? 'column' : 'row' }}
                  >
                    <Stack.Item grow={1} style={{ maxWidth: 400, minWidth: 196 }}>
                      <SearchBox
                        styles={{ root: { border: '1px solid #8c9095' } }}
                        placeholder={t('PATCH_POLICIES.FORM.SEARCH_PLACEHOLDER')}
                        onChange={handleOnSearchBoxChange}
                      />
                    </Stack.Item>
                    <Stack.Item>
                      <div
                        className={`d-flex d-flexRow  ${isMobile ? 'd-flexJustifyBetween' : 'd-flexAlignItemsCenter'} `}
                        style={{ flexDirection: isMobile ? 'column' : 'row' }}
                      >
                        <Pivot
                          headersOnly={true}
                          selectedKey={pivotSelectedKeyState}
                          onLinkClick={handlePivotOnLinkClick}
                        >
                          <PivotItem itemKey="all" headerText={t('PATCH_POLICIES.TABLE.ALL')} />
                          <PivotItem itemKey="NOT_APPROVED" headerText={t('PATCH_POLICIES.TABLE.PENDING_APPROVAL')} />
                          <PivotItem itemKey="APPROVED" headerText={t('PATCH_POLICIES.TABLE.APPROVED')} />
                          <PivotItem itemKey="DECLINED" headerText={t('PATCH_POLICIES.TABLE.DECLINED')} />
                        </Pivot>
                        <div style={{ marginTop: isMobile ? 15 : 0 }}>
                          <FeatureRender feature={FEATURE_NAMES.PATCH_MANAGEMENT_UPDATE}>
                            {(pivotSelectedKeyState === 'all' || pivotSelectedKeyState === 'NOT_APPROVED') && (
                              <>
                                <LoadingButton
                                  text={t('PATCH_POLICIES.TABLE.APPROVE')}
                                  handleClick={handleApproveClick}
                                  isLoading={approvePatches.isLoading}
                                  disabled={!hasSelectedPatches('NOT_APPROVED') && !hasSelectedPatches('DECLINED')}
                                  iconName="CheckMark"
                                />
                                <LoadingButton
                                  text={t('PATCH_POLICIES.TABLE.DECLINE')}
                                  handleClick={handleDeclineClick}
                                  isLoading={declinePatches.isLoading}
                                  disabled={!hasSelectedPatches('NOT_APPROVED') && !hasSelectedPatches('APPROVED')}
                                  iconName="Cancel"
                                />
                              </>
                            )}
                            {pivotSelectedKeyState === 'DECLINED' && (
                              <LoadingButton
                                text={t('PATCH_POLICIES.TABLE.APPROVE')}
                                handleClick={handleApproveClick}
                                isLoading={approvePatches.isLoading}
                                disabled={!hasSelectedPatches('NOT_APPROVED') && !hasSelectedPatches('DECLINED')}
                                iconName="CheckMark"
                              />
                            )}
                            {pivotSelectedKeyState === 'APPROVED' && (
                              <LoadingButton
                                text={t('PATCH_POLICIES.TABLE.DECLINE')}
                                handleClick={handleDeclineClick}
                                isLoading={declinePatches.isLoading}
                                disabled={!hasSelectedPatches('NOT_APPROVED') && !hasSelectedPatches('APPROVED')}
                                iconName="Cancel"
                              />
                            )}
                          </FeatureRender>
                        </div>
                      </div>
                    </Stack.Item>
                  </Stack>
                </div>
                <Card>
                  {patchesPagination && patchesPagination.lastUpdatedWindowsPatches && (
                    <Label styles={{ root: { textAlign: 'right', paddingRight: '20px' } }}>
                      {t('PATCH_POLICIES.LABEL.LAST_UPDATED')}{' '}
                      {formatDatetime(patchesPagination.lastUpdatedWindowsPatches)}
                    </Label>
                  )}
                  <Table
                    tableId="windows-patches"
                    isLoading={isLoading}
                    isEmpty={patchesPage?.length === 0}
                    emptyMessage={t('general:EMPTY_FILTER')}
                    tableProps={{
                      columns: columns,
                      items: patchesPage,
                      selection: selection.current as Selection,
                      selectionMode: SelectionMode.multiple,
                      selectionPreservedOnEmptyClick: true,
                      isSelectedOnFocus: false,
                      onShouldVirtualize: () => false,
                    }}
                    paginationProps={
                      patchesPagination
                        ? {
                            pagination: paginationState,
                            onPaginationChange: handleOnPaginationChange,
                            paginatedData: patchesPagination,
                            isFetching: isFetching,
                          }
                        : undefined
                    }
                  />
                </Card>
              </>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  )
}

export const StatusBadge = ({ type, t }: { type: string; t: TFunction }) => {
  const circleStyles: CSSProperties = {
    height: 12,
    width: 12,
    borderRadius: '50%',
    marginRight: 5,
    display: 'inline-block',
  }
  switch (type) {
    case 'APPROVED':
      return (
        <>
          <div className="d-flex d-flexRow d-flexAlignItemsCenter">
            <span style={{ ...circleStyles, height: 8, width: 8, backgroundColor: '#34c759' }}></span>
            <span>{t('PATCH_POLICIES.TABLE.APPROVED')}</span>
          </div>
        </>
      )
      break
    case 'DECLINED':
      return (
        <>
          <div className="d-flex d-flexRow d-flexAlignItemsCenter">
            <span style={{ ...circleStyles, height: 8, width: 8, backgroundColor: '#ff0000' }}></span>
            <span>{t('PATCH_POLICIES.TABLE.DECLINED')}</span>
          </div>
        </>
      )
      break
    case 'NOT_APPROVED':
      return (
        <>
          <div className="d-flex d-flexRow d-flexAlignItemsCenter">
            <span style={{ ...circleStyles, height: 8, width: 8, backgroundColor: '#cccccc' }}></span>
            <span>{t('PATCH_POLICIES.TABLE.PENDING')}</span>
          </div>
        </>
      )
      break
    default:
      return <></>
  }
}
