import { useQuery } from '@tanstack/react-query'
import { MultiLanguageField } from 'app/models/microservices'
import { ValueType } from 'app/models/flows/ValueType'
import graphClient from 'app/query-client/graphClient'
import { gql } from 'graphql-request'

export interface ConditionType {
  id: string
  name: MultiLanguageField[]
  description: MultiLanguageField[]
  iconName: string
  color: string
  type: string
  frequencyValues: number[]
  valueType: ValueType
  valuePlaceholder?: string
  period: boolean
}

interface Response {
  getConditionsByOrganization: ConditionType[]
}

const query = gql`
  query ($organizationId: ID!) {
    getConditionsByOrganization(organizationId: $organizationId) {
      id
      name {
        code
        text
        translatedByIA
      }
      description {
        code
        text
        translatedByIA
      }
      iconName
      color
      type
      frequencyValues
      valueType
      valuePlaceholder
      period
    }
  }
`

async function getConditionByOrganization(organizationId: string): Promise<ConditionType[]> {
  const response = await graphClient.request<Response>(query, { organizationId })

  return response.getConditionsByOrganization
}

export const useGetConditions = (organizationId?: string) => {
  if (!organizationId) {
    return useQuery(['conditions'], () => [], {
      refetchOnWindowFocus: false,
      enabled: false,
    })
  }

  return useQuery(['conditions', organizationId], () => getConditionByOrganization(organizationId))
}
