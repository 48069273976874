import { FieldTypes } from '@flexxibleit/flexxible-ui'
import { useLocalStorageSearchParams } from 'app/hooks/utils/useLocalStorageSearchParams'
import { BusinessType, label } from 'app/models/business/BusinessType'
import { TFunction } from 'i18next'

type ActivationFiltersProps = {
  t: TFunction
}

export const useActivationFilters = ({ t }: ActivationFiltersProps) => {
  useLocalStorageSearchParams()

  const filterFields = [
    {
      field: 'name',
      text: t('TENANTS_ACTIVATIONS.TABLE.NAME'),
      type: FieldTypes.STRING,
    },
    {
      field: 'product',
      text: t('TENANTS_ACTIVATIONS.TABLE.PRODUCT'),
      type: FieldTypes.STRING,
    },
    {
      field: 'type',
      text: t('TENANTS_ACTIVATIONS.TABLE.TYPE'),
      type: FieldTypes.ENUM,
      options: Object.values(BusinessType).map((item) => {
        return {
          key: item,
          text: label(t)[item],
        }
      }),
    },
    {
      field: 'last90Days',
      text: t('TENANTS_ACTIVATIONS.TABLE.90_DAYS_AGO'),
      type: FieldTypes.NUMBER,
    },
    {
      field: 'last60Days',
      text: t('TENANTS_ACTIVATIONS.TABLE.60_DAYS_AGO'),
      type: FieldTypes.NUMBER,
    },
    {
      field: 'last30Days',
      text: t('TENANTS_ACTIVATIONS.TABLE.30_DAYS_AGO'),
      type: FieldTypes.NUMBER,
    },
    {
      field: 'last7Days',
      text: t('TENANTS_ACTIVATIONS.TABLE.7_DAYS_AGO'),
      type: FieldTypes.NUMBER,
    },
    {
      field: 'lastDay',
      text: t('TENANTS_ACTIVATIONS.TABLE.YESTERDAY'),
      type: FieldTypes.NUMBER,
    },
    {
      field: 'productStatus',
      text: t('TENANTS_ACTIVATIONS.TABLE.STATUS'),
      type: FieldTypes.BOOLEAN,
      options: [
        { key: 'true', text: t('general:STATUS.ACTIVE') },
        { key: 'false', text: t('general:STATUS.INACTIVE') },
      ],
    },
    // SE DEJA COMENTADO PARA RETOMARLO MAS ADELANTE
    // CUANDO LA TABLA MANEJE FILTROS DE FECHA
    // {
    //   field: 'createdAt',
    //   text: t('TENANTS_ACTIVATIONS.TABLE.CREATED_AT'),
    //   type: FieldTypes.STRING,
    // },
    // {
    //   field: 'deleteAt',
    //   text: t('TENANTS_ACTIVATIONS.TABLE.DELETED_AT'),
    //   type: FieldTypes.STRING,
    // },
  ]

  return { filterFields }
}
