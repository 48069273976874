import { chartColors } from '../ChartColors.constants'

export const MockChartConfiguration = {
  chart: {
    type: 'column'
  },
  title: {
    text: undefined
  },
  plotOptions: {
    column: {
      stacking: 'normal',
      groupPadding: 0,
    },
  },
  colors: chartColors,
  credits: {
    enabled: false,
  },
  useHTML: true,
}
