import { DeleteActionButton, FormActionsContainer, RowColLayout } from '@flexxibleit/flexxible-ui'
import { zodResolver } from '@hookform/resolvers/zod'
import Dropdown from 'app/components/forms/Dropdown'
import FormActions from 'app/components/forms/FormActions'
import ControlledTextField from 'app/components/forms/TextField'
import { ConditionType } from 'app/hooks/flows/useGetConditions'
import { ConditionData } from 'app/hooks/flows/useGetFlow'
import { secondsToLabel } from 'app/models/flows/FlowCooldown'
import { OperatorType, operatorTypeOptions } from 'app/models/flows/OperatorType'
import { ValueType } from 'app/models/flows/ValueType'
import { FormProvider, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import * as z from 'zod'

interface Props {
  condition: ConditionType
  data: Partial<FormValues>
  onSubmit: (data: ConditionData) => void
  onRemove: () => void
}

interface FormValues {
  conditionTypeId: string
  metric: string
  checkEvery: number
  operator: OperatorType
  compareTo: string
  period: number | null
}

export const ConditionForm = (props: Props) => {
  const { t, i18n } = useTranslation('flows')

  const schema: z.ZodType<FormValues> = z.object({
    conditionTypeId: z.string(),
    metric: z.string({ required_error: 'required' }),
    checkEvery: z.number({ required_error: 'required' }),
    operator: z.nativeEnum(OperatorType),
    compareTo: z.string({ required_error: 'required' }),
    period: props.condition.period ? z.number({ required_error: 'required' }) : z.number().nullable(),
  })

  const methods = useForm<FormValues>({
    resolver: zodResolver(schema),
    defaultValues: {
      conditionTypeId: props.condition.id,
      ...props.data,
      period: props.data.period ?? null,
    },
  })
  const onSubmit = (data: FormValues) => {
    props.onSubmit({
      ...data,
      conditionType: props.condition,
      conditionTypeId: props.condition.id,
      period: data.period,
    })
  }

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <RowColLayout rowProps={{ classNames: 'mt-2' }}>
          <Dropdown
            name="checkEvery"
            translator={t}
            dropdownProps={{
              label: t('EDITOR.FORM.CONDITION.CHECKEVERY'),
              placeholder: t('EDITOR.FORM.CONDITION.CHECKEVERY_PLACEHOLDER'),
              options: props.condition.frequencyValues.map((f) => {
                return {
                  key: f,
                  text: secondsToLabel(t, f),
                }
              }),
              defaultSelectedKey: props.data.checkEvery,
            }}
            required
          />
        </RowColLayout>
        <RowColLayout rowProps={{ classNames: 'mt-2' }}>
          <Dropdown
            name="operator"
            translator={t}
            dropdownProps={{
              label: t('EDITOR.FORM.CONDITION.OPERATOR'),
              placeholder: t('EDITOR.FORM.CONDITION.OPERATOR_PLACEHOLDER'),
              options: operatorTypeOptions(t, props.condition.valueType),
              defaultSelectedKey: props.data.operator,
            }}
            required
          />
        </RowColLayout>
        <RowColLayout rowProps={{ classNames: 'mt-2' }}>
          {props.condition.valueType === ValueType.bool ? (
            <Dropdown
              name="compareTo"
              translator={t}
              dropdownProps={{
                label: t('EDITOR.FORM.CONDITION.COMPARETO'),
                placeholder: t('EDITOR.FORM.CONDITION.COMPARETO_PLACEHOLDER'),
                options: [
                  { key: 'true', text: t('BOOLEAN_VALUES.TRUE') },
                  { key: 'false', text: t('BOOLEAN_VALUES.FALSE') },
                ],
                defaultSelectedKey: props.data.compareTo,
              }}
              required
            />
          ) : (
            <ControlledTextField
              name="compareTo"
              translator={t}
              textFieldProps={{
                label: t('EDITOR.FORM.CONDITION.COMPARETO'),
                placeholder: props.condition.valuePlaceholder ?? t('EDITOR.FORM.CONDITION.COMPARETO_PLACEHOLDER'),
                type: [ValueType.int, ValueType.double].includes(props.condition.valueType) ? 'number' : 'text',
                step: props.condition.valueType === ValueType.double ? '0.01' : '1',
              }}
              required
            />
          )}
        </RowColLayout>
        {props.condition.period && (
          <RowColLayout rowProps={{ classNames: 'mt-2' }}>
            <Dropdown
              name="period"
              translator={t}
              dropdownProps={{
                label: t('EDITOR.FORM.CONDITION.PERIOD'),
                placeholder: t('EDITOR.FORM.CONDITION.PERIOD_PLACEHOLDER'),
                options: [300, 600, 900, 1200, 1500, 1800].map((f) => {
                  return {
                    key: f,
                    text: secondsToLabel(t, f),
                  }
                }),
                defaultSelectedKey: props.data.period,
              }}
              required
            />
          </RowColLayout>
        )}

        <FormActionsContainer isEdit>
          <DeleteActionButton locale={i18n.language} onClick={props.onRemove} />
          <FormActions
            submitProps={{
              text: t('general:BUTTON.SAVE'),
              iconProps: { iconName: 'Save' },
            }}
          />
        </FormActionsContainer>
      </form>
    </FormProvider>
  )
}
