import { ColumnActionsMode, IColumn } from '@fluentui/react'
import { Workspace } from 'app/query-client/types'
import { TFunction } from 'react-i18next'

export const getColumns = (t: TFunction): IColumn[] => {
  function getUTypeDisplayValue(uType?: string): string {
    if (!uType) {
      return ''
    }
    if (uType.toLowerCase() == 'physical') {
      return 'Physical'
    }
    if (uType.toLowerCase() == 'virtual') {
      return 'Virtual'
    }
    return uType
  }

  return [
    {
      key: 'name',
      name: t('organization_details:WORKSPACE_GROUPS.TABLE.NAME'),
      fieldName: 'name',
      minWidth: 100,
      isRowHeader: true,
      onRender: ({ name }: Workspace) => {
        return <span>{name}</span>
      },
      isPadded: true,
      columnActionsMode: ColumnActionsMode.disabled,
    },
    {
      key: 'fqdn',
      name: t('organization_details:WORKSPACE_GROUPS.TABLE.FQDN'),
      fieldName: 'fqdn',
      minWidth: 260,
      isRowHeader: true,
      onRender: ({ fqdn }: Workspace) => {
        return <span>{fqdn}</span>
      },
      isPadded: true,
      columnActionsMode: ColumnActionsMode.disabled,
    },
    {
      key: 'ipAddress',
      name: t('organization_details:WORKSPACE_GROUPS.TABLE.IP_ADDRESS'),
      fieldName: 'ipAddress',
      minWidth: 100,
      isRowHeader: true,
      onRender: ({ ipAddress }: Workspace) => {
        return <span>{ipAddress}</span>
      },
      isPadded: true,
      columnActionsMode: ColumnActionsMode.disabled,
    },
    {
      key: 'uOperativeSystem',
      name: t('organization_details:WORKSPACE_GROUPS.TABLE.U_OPERATIVE_SYSTEM'),
      fieldName: 'uOperativeSystem',
      minWidth: 200,
      isRowHeader: true,
      onRender: ({ uOperativeSystem }: Workspace) => {
        return <span>{uOperativeSystem}</span>
      },
      isPadded: true,
      columnActionsMode: ColumnActionsMode.disabled,
    },
    {
      key: 'uCoresCpu',
      name: t('organization_details:WORKSPACE_GROUPS.TABLE.U_CORES_CPU'),
      fieldName: 'uCoresCpu',
      minWidth: 50,
      isRowHeader: true,
      onRender: ({ uCoresCpu }: Workspace) => {
        return <span>{uCoresCpu}</span>
      },
      isPadded: true,
      columnActionsMode: ColumnActionsMode.disabled,
    },
    {
      key: 'uRam',
      name: t('organization_details:WORKSPACE_GROUPS.TABLE.U_RAM'),
      fieldName: 'uRam',
      minWidth: 50,
      isRowHeader: true,
      onRender: ({ uRam }: Workspace) => {
        return <span>{uRam}</span>
      },
      isPadded: true,
      columnActionsMode: ColumnActionsMode.disabled,
    },
    {
      key: 'uType',
      name: t('organization_details:WORKSPACE_GROUPS.TABLE.U_TYPE'),
      fieldName: 'uType',
      minWidth: 50,
      isRowHeader: true,
      onRender: ({ uType }: Workspace) => {
        return <span>{getUTypeDisplayValue(uType)}</span>
      },
      isPadded: true,
      columnActionsMode: ColumnActionsMode.disabled,
    },
    {
      key: 'uLastUser',
      name: t('organization_details:WORKSPACE_GROUPS.TABLE.U_LAST_USER'),
      fieldName: 'uLastUser',
      minWidth: 180,
      isRowHeader: true,
      onRender: ({ uLastUser }: Workspace) => {
        return <span>{uLastUser}</span>
      },
      isPadded: true,
      columnActionsMode: ColumnActionsMode.disabled,
    },
  ]
}
