import { UserRole } from 'app/hooks/roles/useGetRolesByOrganization'

export const useUsersBuildExport = (rolesByOrganization?: UserRole[]) => {
  const rolesNames = rolesByOrganization?.map((role) => role.name)

  const userTableExportColumns = [
    {
      key: 'action',
      header: 'Action',
      width: 14,
      formulae: [`"Add/Update,Delete"`],
    },
    {
      key: 'name',
      header: 'Name',
      width: 10,
    },
    {
      key: 'surname',
      header: 'Surname',
      width: 10,
    },
    {
      key: 'email',
      header: 'Email',
      width: 10,
    },
    {
      key: 'department',
      header: 'Department',
      width: 14,
    },
    {
      key: 'userRole',
      header: 'Role',
      width: 14,
      formulae: [`"${rolesNames?.join(',')}"`],
    },
    {
      key: 'language',
      header: 'Language',
      width: 12,
      formulae: [`"es,en,pt,ca,eu"`],
    },
  ]
  return { userTableExportColumns }
}
