import { TFunction } from 'i18next'

export const useMinutesToLabel = (t: TFunction, minutes: number) => {
  if (minutes < 60) {
    return t('general:TIMEPICKER.MINUTES', { count: minutes })
  } else {
    const hours = Math.floor(minutes / 60)
    const remainingMinutes = minutes % 60
    let timeLabel = t('general:TIMEPICKER.HOURS_ABBREVIATED', { count: hours })
    if (remainingMinutes > 0) {
      timeLabel += ' ' + t('general:TIMEPICKER.MINUTES_ABBREVIATED', { count: remainingMinutes })
    }
    return timeLabel
  }
}
