import { AutocompleteSearchBox, ISuggestionItem } from "app/components/GoogleMap/AutocompleteSearchBox";
import { useState } from "react";

interface Props {
  onPlaceSelected: (place: google.maps.places.QueryAutocompletePrediction) => void
  label: string
  required?: boolean
  placeholder?: string
  value?: string
  placesService?: google.maps.places.PlacesService
}

export const GoogleMapAddressPicker = ({ onPlaceSelected, label, required, placeholder, placesService, value }: Props) => {
  const [suggestions, setSuggestions] = useState<google.maps.places.QueryAutocompletePrediction[]>([])

  const handleInputChange = async (filterText: string): Promise<google.maps.places.QueryAutocompletePrediction[]> => {
    if (!filterText) {
      return []
    }
  
    const service = new google.maps.places.AutocompleteService();

    const getDetails = async (placeId: string): Promise<string> => {
      return new Promise((resolve, reject) => {
        placesService?.getDetails({placeId}, (result, status) => {
          resolve(result?.formatted_address ?? "")
        })
      })
    }
  
    const predictions = await new Promise<google.maps.places.QueryAutocompletePrediction[]>((resolve, reject) => {
      service.getPlacePredictions({ input: filterText, types: ['address'] },async (predictions, status) => {
        if (status !== google.maps.places.PlacesServiceStatus.OK) {
          return reject(status)
        }

        const suggestions = await Promise.all(predictions?.map(async (prediction) => {
          const address = await getDetails(prediction.place_id!)
          return {
            ...prediction,
            description: address
          }
        }) ?? [])
  
        return resolve(suggestions)
      });
    })
  
    return predictions
  }

  const onSuggestionClicked = (suggestion: string | ISuggestionItem): void => {
    const place = suggestions.find((s) => s.description === suggestion)

    place && onPlaceSelected(place)
  }

  return (
    <AutocompleteSearchBox
      suggestions={suggestions.map((suggestion) => suggestion.description)}
      onSuggestionClicked={onSuggestionClicked}
      value={value ?? ""}
      debounceTime={500}
      label={label}
      placeholder={placeholder}
      required={required}
      onChange={(_, newValue) => {
        handleInputChange(newValue ?? "").then((suggestions) => {
          setSuggestions(suggestions)
        }).catch((error) => {
          console.log("Error getting suggestions", error)
        })
      }}
    />
  )
}
