import { useMutation } from '@tanstack/react-query'
import graphClient from 'app/query-client/graphClient'
import queryClient from 'app/query-client/queryClient'
import { gql } from 'graphql-request'

const mutation = gql`
  mutation DeleteTenant($id: ID!) {
    deleteTenant(id: $id)
  }
`

async function deleteTenant(id: string) {
  await graphClient.request(mutation, { id })
}

export default function () {
  return useMutation(['deleteTenant'], ({ id }: { id: string }) => deleteTenant(id), {
    onSuccess: () => {
      queryClient.invalidateQueries(['tenant'])
      queryClient.invalidateQueries(['business'])
      queryClient.invalidateQueries(['getBusinessById'])
    },
  })
}
