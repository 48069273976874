import { DropdownMenuItemType, IDropdownOption, IDropdownStyles, ISelectableOption } from '@fluentui/react'
import { useMemo, useState } from 'react'
import { TFunction } from 'react-i18next'
import Dropdown from '../forms/Dropdown'
import { colors } from './ColorsOptions'

const dropdownStyles: Partial<IDropdownStyles> = {
  dropdownItemSelected: {
    '&:before': {
      content: '""',
      position: 'absolute',
      left: 0,
      top: 0,
      bottom: 0,
      width: '4px',
      background: 'rgb(255, 101, 0)',
    },
    display: 'inline-flex',
    width: 'auto',
    padding: '0px',
    minHeight: '24px',
  },
  dropdownItem: {
    display: 'inline-flex',
    width: 'auto',
    padding: '0px',
    minHeight: '24px',
    '&:hover': {
      background: 'rgb(191 191 191)'
    }
  },
  dropdownDividerHidden: {
    display: 'block',
  },
}

export interface IconSelectorProps {
  name: string
  translator: TFunction
  label: string
  placeholder: string
  defaultSelectedKey?: string | number
  searchBarPlaceholder: string
  optionIcon?: string
  allowEmpty?: {
    emptyOptionText: string
  }
}

const ColorSelector = (props: IconSelectorProps) => {
  const buildInitialOptions = useMemo(() => {
    return Object.keys(colors)
      .map((category, index) => {
        const categoryItems = []
        categoryItems.push({
          key: `category_${index}`,
          text: category,
          itemType: DropdownMenuItemType.Divider,
          hidden: true,
        })

        return [
          ...categoryItems,
          ...colors[category].map((iconName: string) => {
            return {
              key: iconName,
              text: iconName,
              itemType: DropdownMenuItemType.Normal,
              data: {
                color: iconName,
              },
            }
          }),
        ]
      })
      .flat()
  }, [])

  const [dropdownOptions, setDropdownOptions] = useState<ISelectableOption[]>(buildInitialOptions)

  const renderOption = (option?: ISelectableOption) => {
    const color = option?.data?.color ?? props.optionIcon
    if (option?.itemType === DropdownMenuItemType.Header) {
      return <div>{option?.text}</div>
    }

    return (
      <div className="d-flex d-center">
        <span style={{ background: color, height: '100%', width: '24px' }}>&nbsp;</span>
      </div>
    )
  }

  const renderTitle = (selected?: IDropdownOption<any>[]) => {
    const color = selected?.[0].data.color

    if (!color) return <span className="ms-Dropdown-title"></span>

    return (
      <span className="ms-Dropdown-title">
        <span style={{ background: color, padding: '0 11px' }}>&nbsp;</span>

        <span className="mr-2"></span>
        {color}
      </span>
    )
  }

  return (
    <>
      <Dropdown
        name={props.name}
        translator={props.translator}
        dropdownProps={{
          styles: dropdownStyles,
          options: dropdownOptions,
          label: props.label,
          placeholder: props.placeholder,
          defaultSelectedKey: props.defaultSelectedKey,
          dropdownWidth: 260,
          onClick: () => {
            setDropdownOptions(buildInitialOptions)
          },
          onRenderOption: renderOption,
          onRenderTitle: renderTitle,
        }}
      />
    </>
  )
}

export default ColorSelector
