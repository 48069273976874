import { useQuery } from '@tanstack/react-query'
import graphClient from '../../query-client/graphClient'
import { gql } from 'graphql-request'
import { MicroserviceEvent } from '../../models/microservices/MicroserviceEvent'

const getMicroserviceEventsQuery = gql`
  query getMicroserviceEvents($businessId: ID!) {
    getMicroserviceEvents(businessId: $businessId) {
      _id
      type
      microservice {
        _id
        name {
          code
          text
          translatedByIA
        }
      }
      initiatedBy
      status
      duration
      data
      executionStartTime
      executionEndTime
      loggedAt
      reportStartTime
      reportEndTime
      business {
        _id
        name
      }
    }
  }
`

export async function getMicroserviceEvents(businessId: string): Promise<MicroserviceEvent[]> {
  let r = await graphClient.request(getMicroserviceEventsQuery, { businessId })
  return await r.getMicroserviceEvents
}

export const useGetMicroserviceEvents = (businessId?: string) => {
  if (!businessId) {
    return useQuery(['microserviceEvents'], () => [], {
      refetchOnWindowFocus: false,
      enabled: false,
    })
  }

  return useQuery(['microserviceEvents', businessId], () => getMicroserviceEvents(businessId), {
    refetchOnWindowFocus: false,
  })
}
