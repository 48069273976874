import { BusinessType } from '../../../models/business/BusinessType'
import { MOBILE_QUERY, TablePageBody, useMediaQuery } from '@flexxibleit/flexxible-ui'
import { getOrganizationTypeIcon } from '../../../services/organization-icon-service'
import { useTranslation } from 'react-i18next'
import { useOrganizationSelection } from '../../../globals/useOrganizationSelection'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import useOrganizationById from '../../../hooks/organization/useOrganizationById'
import { useEffect, useState } from 'react'
import { FeedbackMessageBar } from '../../../components/FeedbackMessageBar/FeedbackMessageBar'
import { withFeatureRender } from '../../../permissions/withFeatureRender'
import { FEATURE_NAMES } from '../../../permissions/FeatureName.enum'
import { TenantsFeature } from './Tenants.feature'
import { useLocalStorageSearchParams } from 'app/hooks/utils/useLocalStorageSearchParams'
import useOrganizationExistsOnBuild from 'app/hooks/organization/useOrganizationExistsOnBuild'

const TitleComponent = ({ title, type }: { title: string; type?: BusinessType }) => {
  const [isMobile] = useMediaQuery(MOBILE_QUERY)

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'start',
      }}
    >
      {type && <div className="mr-1 vertical-center">{getOrganizationTypeIcon(type)}</div>}
      {isMobile ? <h3>{title}</h3> : <h1>{title}</h1>}
    </div>
  )
}

const OrgTenants = () => {
  const { t } = useTranslation('organization_details')
  const navigate = useNavigate()
  const { selectedOrganizationId } = useOrganizationSelection()
  const { organizationId } = useParams()
  const { isLoading, data, isFetching, refetch } = useOrganizationById(organizationId || '')
  const { getPersistedSearchParams } = useLocalStorageSearchParams(false)
  const location = useLocation()
  const { data: organizationExistOnBuild, isLoading: organizationExistOnBuildLoading } = useOrganizationExistsOnBuild(
    organizationId || ''
  )
  const [canCreate, setCanCreate] = useState<boolean>(false)
  const baseUrl = location.pathname.split('/')[1]

  useEffect(() => {
    if (!selectedOrganizationId) {
      return
    }

    const finalSearchParams = location.search !== '' ? location.search : getPersistedSearchParams(location.pathname)
    navigate(`/${baseUrl}/${selectedOrganizationId}/tenants${finalSearchParams}`)
  }, [selectedOrganizationId])

  useEffect(() => {
    refetch()
  }, [organizationId])

  useEffect(() => {
    setCanCreate(false)
    if (!organizationExistOnBuild) {
      return
    }
    if (data?.type === BusinessType.PARTNER || data?.type === BusinessType.WHOLESALER) {
      if (Boolean(data?.elegibleProducts?.length)) {
        setCanCreate(true)
      }
    } else {
      if (data?.partner?.type === BusinessType.PARTNER || data?.partner?.type === BusinessType.WHOLESALER) {
        setCanCreate(true)
      }
    }
  }, [data, organizationExistOnBuild])

  return (
    <TablePageBody
      title={isLoading || isFetching ? '' : data?.name || ''}
      titleComponent={<TitleComponent title={`${data?.name} - ${t('TENANTS_PIVOT')}`} type={data?.type} />}
      isLoading={isLoading || isFetching || organizationExistOnBuildLoading}
    >
      <FeedbackMessageBar />

      <TenantsFeature
        organizationId={data?._id || ''}
        elegibleProducts={data?.elegibleProducts ?? []}
        policies={data?.policies ?? []}
        allowAdd={canCreate}
        productConfigs={data?.productConfigs}
        organizationType={data?.type}
      />
    </TablePageBody>
  )
}

export const OrgTenantsPage = withFeatureRender(OrgTenants, FEATURE_NAMES.TENANT_READ)
