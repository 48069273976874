import { useBoolean } from '@fluentui/react-hooks'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import Dialog from '../../../../components/Dialog/Dialog'
import PageBody from '../../../../components/PageBody/PageBody.component'
import { useOrganizationSelection } from '../../../../globals/useOrganizationSelection'
import { useGetDesignerMicroserviceById } from '../../../../hooks/microservices/useGetDesignerMicroserviceById'
import { MicroserviceForm } from '../../components/MicroserviceForm'
import { PRODUCT_ENV } from 'app/config/Consts'
import { ProductEnvTypes } from 'app/config/productEnv.enum'

export const MicroserviceEditOverviewPage = () => {
  const navigate = useNavigate()
  const { t } = useTranslation('microservices')
  const [hideDialog, { toggle: toggleHideDialog }] = useBoolean(true)
  const { microserviceId } = useParams()
  const { selectedOrganizationId } = useOrganizationSelection()
  const { data: microservice, isLoading } = useGetDesignerMicroserviceById(
    microserviceId || '',
    selectedOrganizationId || ''
  )

  const renderExitFormConfirmation = (): JSX.Element => {
    return (
      <Dialog
        title={t('DESIGNER.MICROSERVICES_EDIT_PAGE.CONFIRMATION_CANCEL_EDIT')}
        description={t('DESIGNER.MICROSERVICES_EDIT_PAGE.CONFIRMATION_CANCEL_EDIT_MESSAGE')}
        actionButton={t('DESIGNER.BUTTON_ACCEPT')}
        dismissButton={t('DESIGNER.BUTTON_CANCEL')}
        hidden={hideDialog}
        onDismiss={toggleHideDialog}
        callback={() => {
          toggleHideDialog()
          navigate(-1)
        }}
      />
    )
  }

  const handleOnCancel = (isDirty: boolean) => {
    if (isDirty) {
      toggleHideDialog()
    } else {
      navigate(-1)
    }
  }

  return (
    <PageBody
      title={`${t('DESIGNER.MICROSERVICES_EDIT_PAGE.TITLE')}`}
      titleTag={PRODUCT_ENV !== ProductEnvTypes.FXXONE ? 'PREVIEW' : undefined}
      isLoading={isLoading}
    >
      {renderExitFormConfirmation()}
      <MicroserviceForm microservice={microservice} onCancel={handleOnCancel} />
    </PageBody>
  )
}
