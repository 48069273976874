import { useMutation } from '@tanstack/react-query'
import graphClient from 'app/query-client/graphClient'
import queryClient from 'app/query-client/queryClient'
import { gql } from 'graphql-request'

const query = gql`
  mutation ($input: SetMicroservicePrivacyGraphqlRequest!, $microserviceId: ID!) {
    setMicroservicePrivacy(id: $microserviceId, input: $input) {
      _id
    }
  }
`

interface Response {
  setMicroservicePrivacy: { _id: string }
}

interface Request {
  isPrivate: boolean
  visibleTo: string[]
}

interface Params {
  microserviceId: string
  input: Request
}

const setMicroservicePrivacy = async (microserviceId: string, input: Request) => {
  const response: Response = await graphClient.request(query, {
    microserviceId,
    input,
  })
  return response.setMicroservicePrivacy
}

export const useSetMicroservicePrivacy = () => {
  return useMutation(
    ['setMicroservicePrivacy'],
    ({ microserviceId, input }: Params) => setMicroservicePrivacy(microserviceId, input),
    {
      onSuccess: (microserviceId) => {
        queryClient.invalidateQueries(['microservices'])
        queryClient.invalidateQueries(['microservices', microserviceId])
      },
    }
  )
}
