import React from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import PageBody from '../../../../components/PageBody/PageBody.component'
import { FeedbackMessageBar } from '../../../../components/FeedbackMessageBar/FeedbackMessageBar'
import { TabPanel } from '../../../../components/tab-panel/TabPanel'
import { useGetInstalledAppById } from './hooks/useGetInstalledAppById'
import { useParams } from 'react-router'
import { LoadingSpinner } from '@flexxibleit/flexxible-ui'
import NotFoundPage from '../../../../pages/not-found/not-found.page'
import { InstalledAppVersionsList } from '../versions/InstalledAppVersionsList'

export const InstalledAppDetailsPage = () => {
  const { t } = useTranslation('analyzer')
  const { installedAppId } = useParams()
  const navigate = useNavigate()
  const { data: installedApp, isLoading } = useGetInstalledAppById(installedAppId!)
  
  if (isLoading) {
    return <LoadingSpinner />
  }
  
  if (!installedApp) {
    return <NotFoundPage />
  }

  return (
    <PageBody
      isLoading={isLoading}
      title={installedApp.name}
      breadcrumbItems={[
        {
          text: t('analyzer:INSTALLED_APPS.TITLE'),
          key: 'installed-apps',
          onClick: () => {
            navigate('/analyzer/installed-apps')
          },
        },
        { text: installedApp.name, key: 'current', isCurrentItem: true },
      ]}
    >
      <FeedbackMessageBar />
      <TabPanel
        pivotProps={{
          styles: {
            root: {
              marginTop: '10px',
            },
          },
        }}
        tabs={[
          {
            title: t('INSTALLED_APPS.DETAILS.VERSIONS.TITLE'),
            key: 'versions',
            component: <InstalledAppVersionsList installedApp={installedApp} />
          },
        ]}
      />
    </PageBody>
  )
}
