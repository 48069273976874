import { Icon, INavLink, IRenderFunction, IRenderGroupHeaderProps, Nav } from '@fluentui/react'
import React from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { navLinkGroups } from './Sidebar.elements'
import { useFeatureRender } from '../../permissions/useFeatureRender'
import './Sidebar.component.scss'
import useMediaQuery from 'app/hooks/utils/useMediaQuery'
import { MOBILE_QUERY, TABLET_QUERY } from 'app/config/media-query'
import { useLocalStorageSearchParams } from 'app/hooks/utils/useLocalStorageSearchParams'

interface Props {
  isOpen: boolean
}

export const Sidebar = ({ isOpen }: Props) => {
  const { checkFeature } = useFeatureRender()
  const [isMobile] = useMediaQuery(MOBILE_QUERY)
  const [isTablet] = useMediaQuery(TABLET_QUERY)
  const navigate = useNavigate()
  const location = useLocation()
  const { getPersistedSearchParams } = useLocalStorageSearchParams(false)

  const _onLinkClick = (ev?: React.MouseEvent<HTMLElement>, item?: INavLink) => {
    if (item?.target) {
      return
    }
    ev?.preventDefault()
    ev?.stopPropagation()
    navigate(item!.url + getPersistedSearchParams(item!.url))
  }

  const customRenderGroupHeader: IRenderFunction<IRenderGroupHeaderProps> = (props, defaultRender) => {
    const hasAccessibleLinks = props?.links?.some((link) => checkFeature(link.feature))

    if (!hasAccessibleLinks) {
      return null
    }

    if (!props?.groupData?.icon) {
      return defaultRender!(props)
    }

    return (
      <div
        className="content-header__custom-group-header"
        style={{ display: 'flex', alignItems: 'center', position: 'relative' }}
      >
        <Icon className="custom-icon" iconName={props.groupData.icon} />
        {defaultRender!(props)}
        {props.groupData.isPreview && (
          <div id="titleTagPage" className={`content-header__title-tag-navLink`}>
            PREVIEW
          </div>
        )}
      </div>
    )
  }

  const sidebarElements = navLinkGroups()
  const path = '/' + location.pathname.split('/')[1]
  const selectedGroup = sidebarElements.find((group) => {
    return group.links.find((link) => [path, location.pathname].includes(link.url))
  })
  if (selectedGroup?.collapseByDefault) {
    selectedGroup.collapseByDefault = false
  }

  const selected = selectedGroup?.links.find((link) => [path, location.pathname].includes(link.url)) ?? {
    key: undefined,
  }

  return (
    <div className={`sidebar${isOpen ? ' open' : ''}${isMobile || isTablet ? ' mobile' : ''}`}>
      <Nav
        selectedKey={selected.key ?? ''}
        onLinkClick={_onLinkClick}
        groups={sidebarElements}
        onRenderGroupHeader={customRenderGroupHeader}
        linkAs={(props) => {
          const feature = props.link!.feature
          if (feature && !checkFeature(props.link!.feature)) {
            return null
          }
          return (
            <div
              className="content-header__custom-group-header"
              style={{ display: 'flex', alignItems: 'center', position: 'relative' }}
            >
              <props.defaultRender {...props} />
              {props.link?.isPreview && <div className={`content-header__title-tag-navLink`}>PREVIEW</div>}
            </div>
          )
        }}
      />
    </div>
  )
}
