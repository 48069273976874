import PageBody from 'app/components/PageBody/PageBody.component'
import { useTranslation } from 'react-i18next'
import { PatchPolicyDetailsTabV2 } from './tabs/PatchPolicyDetailsTabV2'
import { Tab, TabPanel } from 'app/components/tab-panel/TabPanel'
import { PatchPolicySchedulesTabV2 } from './tabs/PatchPolicySchedulesTabV2'
import { useNavigate, useParams } from 'react-router-dom'
import { IBreadcrumbItem } from '@fluentui/react'
import useGetPatchPolicyTarget from 'app/hooks/patchPolicyTarget/useGetPatchPolicyTarget'
import { useEffect } from 'react'
import { useOrganizationSelection } from 'app/globals/useOrganizationSelection'

export const PatchPolicyTargetDetails = () => {
  const { t, i18n } = useTranslation('patch_management')
  const navigate = useNavigate()

  const { organizationId, patchPolicyTargetId } = useParams()

  const { data, isLoading } = useGetPatchPolicyTarget({ id: patchPolicyTargetId ?? '' }, true)
  const { selectedOrganization } = useOrganizationSelection()

  const baseUrl = location.pathname.split('/')[1]

  useEffect(() => {
    if (!selectedOrganization) {
      return
    }
    if (selectedOrganization._id !== organizationId) {
      navigate(`/${baseUrl}/${selectedOrganization._id}/patch-management/patch-policy-targets/`)
    }
  }, [selectedOrganization])

  const buildTabs = (): Tab[] => {
    if (!data) {
      return []
    }

    return [
      {
        title: t('PATCH_POLICIES_TARGET.TABS.DETAILS'),
        component: <PatchPolicyDetailsTabV2 patchPolicyTarget={data} />,
        key: 'details',
      },

      {
        title: t('PATCH_POLICIES_TARGET.TABS.SCHEDULES'),
        //TODO - PatchPolicySchedulesTabV2 to use PatchPolicyTarget
        component: <PatchPolicySchedulesTabV2 patchPolicyTarget={data} />,
        key: 'schedules',
      },
    ]
  }

  const onBreadcrumbItemClicked = (event?: React.MouseEvent, item?: IBreadcrumbItem) => {
    navigate(item?.key ?? '/home')
  }

  const breadcrumbItems = [
    {
      text: t('PATCH_POLICIES_TARGET.TITLE'),
      key: `/organization/${organizationId}/patch-management/patch-policy-targets`,
      onClick: onBreadcrumbItemClicked,
    },
    { text: data?.name || '', key: 'current', isCurrentItem: true },
  ]

  return (
    <PageBody title={data?.name ?? ''} isLoading={isLoading} breadcrumbItems={breadcrumbItems}>
      <TabPanel tabs={buildTabs()} />
    </PageBody>
  )
}
