import { Role, WorkspaceGroup } from '../../../query-client/types'
import { useTranslation } from 'react-i18next'
import { IBreadcrumbItem } from '@fluentui/react'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import { WorkspaceGroupsDetailPageOrigin } from './WorkspaceGroupsDetailPageOrigin'

export const useWorkspaceGroupDetailPageConfigurationForOrigin = (
  origin: WorkspaceGroupsDetailPageOrigin,
) => {
  const { t } = useTranslation('organization_details');
  const navigate = useNavigate();
  const _onBreadcrumbItemClicked = (event?: React.MouseEvent, item?: IBreadcrumbItem) => {
    navigate(item?.key ?? '/home')
  }

  if (origin === 'organization_details') {
    const getBreadcrumbItems = (workspaceGroup?: WorkspaceGroup): IBreadcrumbItem[] => {
      if (!workspaceGroup) {
        return [];
      }

      return [
        {
          text: workspaceGroup.business.name,
          key: `/organization/${workspaceGroup.business._id}`,
          onClick: _onBreadcrumbItemClicked,
        },
        {
          text: t('WORKSPACE_GROUPS.BREADCRUMB'),
          key: `/organization/${workspaceGroup.business._id}/workspace-groups`,
          onClick: _onBreadcrumbItemClicked,
        },
        {
          text: workspaceGroup.name,
          key: 'current',
          isCurrentItem: true,
        },
      ];
    }

    return {
      getBreadcrumbItems,
    };

  } else {
    const getBreadcrumbItems = (workspaceGroup?: WorkspaceGroup): IBreadcrumbItem[] => {
      if (!workspaceGroup) {
        return [];
      }

      return [
        {
          text: t('WORKSPACE_GROUPS.BREADCRUMB'),
          key: '/workspace-groups',
          onClick: _onBreadcrumbItemClicked,
        },
        {
          text: workspaceGroup.name,
          key: 'current',
          isCurrentItem: true,
        },
      ];
    }

    return {
      getBreadcrumbItems,
    };
  }

}
