import { FC, useState } from 'react'
import { Icon } from '@fluentui/react'
import { SummaryItem } from '../hooks/usePatchManagementRuleEngine'
import { SummaryType } from "../hooks/types";

interface DetailsSectionProps {
  type: SummaryType
  items: SummaryItem[]
}

export const SummaryDetails: FC<DetailsSectionProps> = (props) => {
  const [detailsOpen, setDetailsOpen] = useState<boolean>(true)

  let summaryStyles: {
    title: string
    backgroundColor: string
    color: string
    icon: string
  } = {
    title: '',
    backgroundColor: '',
    color: '',
    icon: '',
  }

  switch (props.type) {
    case SummaryType.Alert:
      summaryStyles.title = 'Alerts'
      summaryStyles.backgroundColor = 'rgb(248,228,218)'
      summaryStyles.color = 'orangered'
      summaryStyles.icon = 'Warning'
      break
    case SummaryType.Information:
      summaryStyles.title = 'Information'
      summaryStyles.backgroundColor = 'rgb(218,241,248)'
      summaryStyles.color = '#005db2'
      summaryStyles.icon = 'Info'
      break
  }

  const renderSummaryItem = (item: SummaryItem, index: number) => (
    <div key={index} style={{ margin: '10px 0' }}>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          gap: '10px',
          height: '40px',
        }}
      >
        <div
          style={{
            borderLeft: `4px solid ${summaryStyles.color}`,
            height: '100%',
          }}
        ></div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
            width: '100%',
            gap: '10px',
          }}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <b
              style={{
                color: summaryStyles.color,
                margin: '0',
              }}
            >
              {item.title}
            </b>
            <span>{item.message}</span>
          </div>
        </div>
      </div>
    </div>
  )

  return (
    <details
      open={detailsOpen}
      onToggle={(e) => setDetailsOpen(e.currentTarget.open)}
      style={{
        margin: '10px 0',
        backgroundColor: summaryStyles.backgroundColor,
        padding: '10px',
        borderRadius: '5px',
        flexGrow: 1,
      }}
    >
      <summary
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-between',
          gap: '5px',
          cursor: 'pointer',
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            gap: '5px',
          }}
        >
          <Icon
            iconName={summaryStyles.icon}
            style={{
              color: summaryStyles.color,
              fontSize: '1.2em',
            }}
          />
          <b
            style={{
              fontSize: '1.2em',
              color: summaryStyles.color,
            }}
          >
            {summaryStyles.title} ({props.items.length})
          </b>
        </div>
        <Icon
          iconName={'ChevronRight'}
          style={{
            cursor: 'pointer',
            color: summaryStyles.color,
            transform: detailsOpen ? 'rotate(90deg)' : 'rotate(0deg)',
            transition: 'transform 0.2s ease',
          }}
        />
      </summary>
      {props.items.map(renderSummaryItem)}
    </details>
  )
}
