import { MOBILE_QUERY, useMediaQuery, ViewDetailsActionButton } from '@flexxibleit/flexxible-ui'
import { IPaginatedTableColumn } from '@flexxibleit/flexxible-ui/dist/lib/components/paginated-table/paginatedTable.types'
import { ColumnActionsMode, TooltipDelay, TooltipHost, TooltipOverflowMode } from '@fluentui/react'
import { CellWithFeatureGreenLink } from 'app/components/table-page-body/CellWithFeatureGreenLink'
import { FEATURE_NAMES } from 'app/permissions/FeatureName.enum'
import { useFeatureRender } from 'app/permissions/useFeatureRender'
import { withFeatureRender } from 'app/permissions/withFeatureRender'
import { PaginatedEligibleProduct, Product } from 'app/query-client/types'
import { i18n } from 'i18next'
import { TFunction } from 'react-i18next'

type UseOrgEligibleProductsBuildTableProps = {
  t: TFunction
  i18n: i18n
  products: Product[]
  openUpdateElegibleProductForm: (item: PaginatedEligibleProduct) => () => void
}

export const useOrgEligibleProductsBuildTable = ({
  t,
  i18n,
  products,
  openUpdateElegibleProductForm,
}: UseOrgEligibleProductsBuildTableProps) => {
  const [isMobile] = useMediaQuery(MOBILE_QUERY)

  const { checkFeature } = useFeatureRender()
  const tableActionsFeatures = [FEATURE_NAMES.ELIGIBLE_PRODUCT_UPDATE]
  const canSeeActions = tableActionsFeatures.some((feature) => checkFeature(feature))

  const columns: IPaginatedTableColumn[] = [
    {
      key: 'name',
      name: t('ELEGIBLE_PRODUCTS.TABLE.NAME'),
      fieldName: 'name',
      minWidth: 25,
      flexGrow: 1,
      isRowHeader: true,
      onRender: (item: PaginatedEligibleProduct) => {
        return (
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'flex-start',
            }}
          >
            <div
              style={{
                overflow: 'hidden',
                textOverflow: 'ellipsis',
              }}
            >
              <TooltipHost content={item?.name} delay={TooltipDelay.zero} overflowMode={TooltipOverflowMode.Parent}>
                <CellWithFeatureGreenLink
                  feature={FEATURE_NAMES.ELIGIBLE_PRODUCT_UPDATE}
                  callback={openUpdateElegibleProductForm(item)}
                >
                  {item?.name}
                </CellWithFeatureGreenLink>
              </TooltipHost>
            </div>
          </div>
        )
      },
    },
    {
      key: 'regions',
      name: t('ELEGIBLE_PRODUCTS.TABLE.REGIONS'),
      fieldName: 'regions',
      minWidth: 25,
      flexGrow: 1,
      isResizable: true,
      isRowHeader: true,
      onRender: (item: PaginatedEligibleProduct) => {
        const productId = item?._id
        let namesString = ''
        if (productId) {
          const product = products?.find((p) => p._id === productId)
          if (product) {
            const regions = item?.regions || []
            const names: string[] = []
            product.regions.map((region) => {
              if (regions.includes(region.deploymentCode)) {
                names.push(region.name)
              }
            })
            namesString = names.join(', ')
          }
        }

        return (
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'flex-start',
            }}
          >
            <div
              style={{
                overflow: 'hidden',
                textOverflow: 'ellipsis',
              }}
            >
              <TooltipHost content={namesString} delay={TooltipDelay.zero} overflowMode={TooltipOverflowMode.Parent}>
                {namesString}
              </TooltipHost>
            </div>
          </div>
        )
      },
    },
    ...(canSeeActions
      ? [
        {
          key: 'actions',
          name: t('ELEGIBLE_PRODUCTS.TABLE.ACTION'),
          minWidth: 25,
          flexGrow: 1,
          onRender: (item: PaginatedEligibleProduct) =>
            withFeatureRender(
              ViewDetailsActionButton,
              FEATURE_NAMES.ELIGIBLE_PRODUCT_UPDATE
            )({
              locale: i18n.language,
              isMobile: isMobile,
              onClick: openUpdateElegibleProductForm(item),
            }),
          isPadded: true,
        },
      ]
      : []),
  ]

  return { columns }
}
