import { ITheme, Icon, Spinner, getFocusStyle, mergeStyleSets, useTheme } from '@fluentui/react'
import { Suspense, lazy, useMemo } from 'react'
const BootstrapIcon = lazy(() => import('app/components/BootstrapIcon/BootstrapIcon'))

interface Props {
  name: string
  description: string
  iconName: string
  color?: string
  onClick: () => void
}

const generateStyles = (theme: ITheme) => {
  const { palette, semanticColors, fonts } = theme
  return mergeStyleSets({
    itemCell: [
      getFocusStyle(theme, { inset: -1 }),
      {
        minHeight: 54,
        padding: 10,
        boxSizing: 'border-box',
        borderBottom: `1px solid ${semanticColors.bodyDivider}`,
        display: 'flex',
        selectors: {
          '&:hover': { background: palette.neutralLight },
        },
      },
    ],
    itemImage: {
      flexShrink: 0,
    },
    itemContent: {
      marginLeft: 10,
      overflow: 'hidden',
      flexGrow: 1,
    },
    itemName: [fonts.xLarge],
    itemIndex: {
      fontSize: fonts.small.fontSize,
      color: palette.neutralTertiary,
      marginBottom: 10,
    },
    chevron: {
      alignSelf: 'center',
      marginLeft: 10,
      color: palette.neutralTertiary,
      fontSize: fonts.large.fontSize,
      flexShrink: 0,
    },
  })
}

export const SelectorItem = ({ iconName, name, description, color, onClick }: Props) => {
  const theme = useTheme()
  const classNames = useMemo(() => generateStyles(theme), [theme])

  return (
    <div className={classNames.itemCell} data-is-focusable={true} onClick={onClick}>
      {iconName ? (
        <Suspense fallback={<Spinner />}>
          <BootstrapIcon
            className="mb-2"
            iconName={iconName}
            size={32}
            style={color ? { color: color, minWidth: 48 } : { minWidth: 48 }}
          />
        </Suspense>
      ) : (
        <div className="mb-2" style={{ minWidth: 48, minHeight: 32 }}></div>
      )}
      <div className={classNames.itemContent}>
        <div className={classNames.itemName}>{name}</div>
        <div className={classNames.itemIndex}></div>
        <div>{description}</div>
      </div>
      <Icon className={classNames.chevron} iconName={'ChevronRight'} />
    </div>
  )
}
