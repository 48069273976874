import { User } from 'app/query-client/types'
import { i18n } from 'i18next'
import { useEffect, useState } from 'react'
import { TFunction } from 'react-i18next'
import { useSearchParams } from 'react-router-dom'
import { ColumnActionsMode } from '@fluentui/react'
import { IPaginatedTableColumn } from '@flexxibleit/flexxible-ui/dist/lib/components/paginated-table/paginatedTable.types'

type UseReportingGroupRolesBuildTableProps = {
  t: TFunction
  i18n: i18n
}

export const useReportingGroupUsersBuildTable = ({ t, i18n }: UseReportingGroupRolesBuildTableProps) => {
  const [columns, setColumns] = useState<IPaginatedTableColumn[]>([])
  const [searchParams] = useSearchParams()

  useEffect(() => {
    setColumns([
      {
        key: 'name',
        name: t('USERS.TABLE.NAME'),
        fieldName: 'name',
        minWidth: 100,
        isRowHeader: true,
        onRender: (item: User) => {
          return `${item.name} ${item.surname}`
        },
        isPadded: true,
        columnActionsMode: ColumnActionsMode.disabled,
      },
      {
        key: 'email',
        name: t('USERS.TABLE.EMAIL'),
        fieldName: 'email',
        minWidth: 200,
        isRowHeader: true,
        isPadded: true,
        columnActionsMode: ColumnActionsMode.disabled,
      },
      {
        key: 'department',
        name: t('USERS.TABLE.DEPARTMENT'),
        fieldName: 'department',
        minWidth: 120,
        isCollapsible: true,
        isRowHeader: true,
        isPadded: true,
        columnActionsMode: ColumnActionsMode.disabled,
      },
    ])
  }, [searchParams])

  return {
    columns,
  }
}
