import * as Excel from 'exceljs'

interface InstalledAppTableExportColumn {
  key: string,
  header: string,
  width: number,
  isDate?: boolean
}

interface UseInstalledAppsTableExportProps {
  query: any,
  columns: InstalledAppTableExportColumn[],
  fileName: string
}

export const useInstalledAppsTableExport = ({ query, columns, fileName }: UseInstalledAppsTableExportProps) => {
  const onExportClicked = async () => {
    const { data: items } = await query()
    let workbook = new Excel.Workbook()
    let worksheet = workbook.addWorksheet(fileName)

    worksheet.columns = columns.map((column) => {
      return {
        header: column.header,
        key: column.key,
        width: column.width,
      }
    })

    const headerRow = worksheet.getRow(1)
    headerRow.font = { bold: true }
    headerRow.alignment = { horizontal: 'center' }
    
    const formattedItems = items.map((item: any) => {
      const formattedItem: any = {};
      columns.forEach((column) => {
        formattedItem[column.key] = item[column.key]

        if (column.isDate && item[column.key]) {
          const date = new Date(item[column.key])
          formattedItem[column.key] = date.toLocaleDateString()
        }
      })
      
      return formattedItem
    })

    formattedItems.forEach((item: any) => {
      worksheet.addRow({
        action: 'Add/Update',
        ...item,
      })
    })

    worksheet.autoFilter = `A1:I${items.length + 1}`

    const buffer = await workbook.xlsx.writeBuffer()
    const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
    const link = document.createElement('a')
    link.href = window.URL.createObjectURL(blob)
    const date = new Date()
    const formattedDate = date.toISOString().slice(0, 10)

    const hyphenTitle = fileName.replace(/ /g, '-')
    
    link.download = `${hyphenTitle}-${formattedDate}.xlsx`
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  }

  return { onExportClicked }
}