import { Toggle } from '@fluentui/react'
import { useTranslation } from 'react-i18next'

export const AdvancedMenuToggle = () => {
  const { t } = useTranslation()
  const advancedMenuEnabled = localStorage.getItem('advancedMenuEnabled') === 'true'
  const handleOnChangeAdvancedMenu = (event: any, checked?: boolean) => {
    localStorage.setItem('advancedMenuEnabled', checked ? 'true' : 'false')
    window.location.reload()
  }

  return (
    <Toggle
      label={t('user_settings:ENABLE_ADVANCED_MENU_LABEL')}
      defaultChecked={advancedMenuEnabled}
      onChange={handleOnChangeAdvancedMenu}
    />
  )
}
