import { FormActionsContainer, LoadingSpinner } from '@flexxibleit/flexxible-ui'
import { z } from 'zod'
import { Role } from '../../../query-client/types'
import {
  authorizedRolesForRole,
  roleLocalizationKeyDictionary,
  RoleName,
  RoleNames,
  Roles,
} from '../../../permissions/RoleFeature.enum'
import { useTranslation } from 'react-i18next'
import { useGenerateApiKey } from '../../../hooks/apiKey/useGenerateApiKey'
import { FormProvider, useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import RowColLayout from '../../../components/Layouts/RowColLayout'
import Dropdown from 'app/components/forms/Dropdown'
import { IDropdownOption, MessageBar, MessageBarType, ResponsiveMode, TextField } from '@fluentui/react'
import FormActions from '../../../components/forms/FormActions'
import SuccessStatus from '../../../components/status/success-status'
import Dialog from '../../../components/Dialog/Dialog'
import { useEffect, useState } from 'react'
import { useFormSidebarContext } from '../../../components/forms/FormSidebar/state/FormSidebarState'
import Copy from '../../../components/Copy/Copy.component'
import ErrorStatus from '../../../components/status/error-status'
import { useAuth } from '../../../globals/useAuth'
import { useOrganizationSelection } from 'app/globals/useOrganizationSelection'

interface Props {
  organizationId: string
}

interface ApiKeyFormType {
  role: Role
}

const RoleEnum = z.enum(RoleNames)

const schema: z.ZodType<Partial<ApiKeyFormType>> = z.object({
  role: RoleEnum.default('USER'),
})

export const ApiKeyForm = (props: Props) => {
  const { t } = useTranslation('organization_details')
  const { rolesOnBusiness } = useOrganizationSelection()
  const {
    mutate: generateApiKey,
    data: generateApiKeyResponse,
    isSuccess,
    isError,
    isLoading,
  } = useGenerateApiKey(props.organizationId)
  const { closeSidebar } = useFormSidebarContext()

  const [responseDialogHiddenState, setResponseDialogHiddenState] = useState<boolean>(true)

  useEffect(() => {
    if (!isSuccess || !generateApiKeyResponse) {
      return
    }
    setResponseDialogHiddenState(false)
  }, [isSuccess, generateApiKeyResponse])

  const methods = useForm<ApiKeyFormType>({
    resolver: zodResolver(schema),
  })

  const handleSubmit = (data: ApiKeyFormType) => {
    generateApiKey(data)
  }

  const buildDropdownOptions = (): IDropdownOption[] => {
    if (!rolesOnBusiness) {
      return []
    }
    const authorizedRoles: Array<RoleName> = authorizedRolesForRole[rolesOnBusiness.portal]
    return authorizedRoles
      .filter((role) => role !== Roles.ADMIN)
      .map((role) => {
        return { key: role, text: t(roleLocalizationKeyDictionary[role]) }
      })
  }

  const renderForm = () => (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(handleSubmit)}>
        <RowColLayout rowProps={{ classNames: 'mt-2' }}>
          <Dropdown
            name="role"
            required={true}
            translator={t}
            dropdownProps={{
              responsiveMode: ResponsiveMode.large,
              defaultSelectedKey: 'USER',
              label: t('API_KEYS.FORM.ROLE'),
              options: buildDropdownOptions(),
            }}
          />
        </RowColLayout>
        <FormActionsContainer>
          <FormActions
            submitProps={{
              text: t('general:BUTTON.CREATE'),
              iconProps: { iconName: 'Add' },
            }}
          />
        </FormActionsContainer>
      </form>
    </FormProvider>
  )

  const renderPanelContent = () => {
    if (!isLoading && !isSuccess && !isError) {
      return renderForm()
    }

    if (isLoading) {
      return <LoadingSpinner></LoadingSpinner>
    }
    if (isSuccess) {
      return <SuccessStatus message={t('API_KEYS.FORM.SUCCESS')} />
    }
    if (isError) {
      return <ErrorStatus message={t('API_KEYS.FORM.ERROR')} />
    }
  }

  const renderApiKeyResponseDialogContent = () => {
    return (
      <>
        <MessageBar messageBarType={MessageBarType.severeWarning}>
          {t('API_KEYS.FORM.RESPONSE_DIALOG_WARNING')}
        </MessageBar>
        <Copy value={generateApiKeyResponse?.token || ''} controlPosition="bottom">
          <div className="mt-2" style={{ width: '100%' }}>
            <TextField
              style={{ height: '300px' }}
              multiline
              resizable={false}
              readOnly={true}
              value={generateApiKeyResponse?.token || ''}
            />
          </div>
        </Copy>
      </>
    )
  }

  return (
    <>
      {renderPanelContent()}

      <Dialog
        title={t('API_KEYS.FORM.RESPONSE_DIALOG_TITLE')}
        description={t('API_KEYS.FORM.RESPONSE_DIALOG_DESCRIPTION')}
        children={renderApiKeyResponseDialogContent()}
        actionButton={t('general:BUTTON.OK')}
        callback={() => {
          setResponseDialogHiddenState(true)
          closeSidebar()
        }}
        hidden={responseDialogHiddenState}
      />
    </>
  )
}
