import { useMutation } from "@tanstack/react-query";
import { gql } from "graphql-request";
import graphClient from "../../query-client/graphClient";
import queryClient from "../../query-client/queryClient";

const deleteUserQuery = gql`mutation Request($id: ID!) {
  deleteUser(id: $id)
}`

function deleteUser(id: string) {
  return graphClient
    .request(deleteUserQuery, { id: id })
    .then(r => r)
}

export default function () {
  return useMutation(['deleteUser'], (userId: any) => deleteUser(userId), {
    onSuccess: () => {
      queryClient.invalidateQueries(['getBusinessById'])
    },
  })
}
