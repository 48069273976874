import { useQuery } from '@tanstack/react-query'
import graphClient from 'app/query-client/graphClient'
import { gql } from 'graphql-request'

interface WindowsPatchCategory {
  id: string
  title: string
  description: string
}

interface Response {
  getWindowsPatchCategories: WindowsPatchCategory[]
}

const query = gql`
  query {
    getWindowsPatchCategories {
      id
      title
      description
    }
  }
`

async function getWindowsPatchCategories(): Promise<WindowsPatchCategory[]> {
  return graphClient.request<Response>(query).then((r) => r.getWindowsPatchCategories)
}

export default function useGetWindowsPatchCategories() {
  return useQuery(['getPatchCategories'], getWindowsPatchCategories, {
    refetchOnWindowFocus: false,
  })
}
