import { ContentHeader, Table } from '@flexxibleit/flexxible-ui'
import { PaginationArgs } from '@flexxibleit/flexxible-ui/dist/lib/components/table/Paginator'
import { ColumnActionsMode, SelectionMode } from '@fluentui/react'
import { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useOrganizationSelection } from '../../../globals/useOrganizationSelection'
import { useViewOrganizationOperationsListHistory } from '../../../hooks/efficiencyOperations/useViewListOrganizationOperationsHistory'
import { useOperationsFilter } from '../OperationsContext'

interface Props {
  startDate: Date
  endDate: Date
}

export const ListOrganizationOperationsHistoryFeature = ({ startDate, endDate }: Props) => {
  const { selectedOrganizationId } = useOrganizationSelection()
  const { t } = useTranslation('efficiency_operations')
  const filtersState = useOperationsFilter()
  const [paginationState, setPaginationState] = useState<PaginationArgs>(() => ({
    page: 1,
    perPage: 20,
  }))
  const { data, isLoading: isLoadingView } = useViewOrganizationOperationsListHistory(
    selectedOrganizationId || '',
    startDate,
    endDate,
    paginationState.page,
    paginationState.perPage
  )

  const organizationOperations: any[] = useMemo(() => data?.results || [], [data])

  const columns = [
    {
      key: 'operationName',
      name: t('TABLE_HEADER_OPERATION_NAME'),
      fieldName: 'name',
      minWidth: 100,
      isRowHeader: true,
      isCollapsible: true,
      isPadded: true,
      columnActionsMode: ColumnActionsMode.disabled,
    },
    {
      key: 'operationType',
      name: t('TABLE_HEADER_OPERATION_TYPE'),
      fieldName: 'type',
      minWidth: 100,
      isRowHeader: true,
      isCollapsible: true,
      isPadded: true,
      columnActionsMode: ColumnActionsMode.disabled,
    },
    {
      key: 'status',
      name: t('TABLE_HEADER_STATUS'),
      fieldName: 'status',
      minWidth: 100,
      isRowHeader: true,
      isCollapsible: true,
      isPadded: true,
      columnActionsMode: ColumnActionsMode.disabled,
    },
    {
      key: 'module',
      name: t('TABLE_HEADER_MODULE'),
      fieldName: 'module',
      minWidth: 150,
      isRowHeader: true,
      isCollapsible: true,
      isPadded: true,
      columnActionsMode: ColumnActionsMode.disabled,
    },
    {
      key: 'startedAt',
      name: t('TABLE_HEADER_STARTED_AT'),
      fieldName: 'startedAt',
      minWidth: 200,
      isRowHeader: true,
      isCollapsible: true,
      isPadded: true,
      columnActionsMode: ColumnActionsMode.disabled,
    },
    {
      key: 'endedAt',
      name: t('TABLE_HEADER_ENDED_AT'),
      fieldName: 'endedAt',
      minWidth: 200,
      isRowHeader: true,
      isCollapsible: true,
      isPadded: true,
      columnActionsMode: ColumnActionsMode.disabled,
    },
    {
      key: 'userFQDN',
      name: t('TABLE_HEADER_USER_FQDN'),
      fieldName: 'userFQDN',
      minWidth: 200,
      isRowHeader: true,
      isCollapsible: true,
      isPadded: true,
      columnActionsMode: ColumnActionsMode.disabled,
    },
  ]

  const handleOnPaginationChange = (newPaginationState: PaginationArgs) => {
    setPaginationState(newPaginationState)
  }

  return (
    <>
      <div style={{ borderRadius: '15px', backgroundColor: 'white', padding: '20px', marginTop: '16px' }}>
        <ContentHeader title={t('TABLE_TITLE')} />
        <Table
          isEmpty={!data || data.pageInfo.total === 0}
          emptyMessage={t('general:EMPTY_FILTER')}
          tableProps={{
            columns: columns,
            items: organizationOperations,
            compact: false,
            listProps: {
              getItemCountForPage: () => {
                return data ? data.results.length : 0
              },
            },
            selectionMode: SelectionMode.none,
            // layoutMode: DetailsListLayoutMode.justified,
            isHeaderVisible: true,
          }}
          paginationProps={
            data
              ? {
                  pagination: paginationState,
                  onPaginationChange: handleOnPaginationChange,
                  paginatedData: data,
                  isFetching: isLoadingView,
                }
              : undefined
          }
        />
      </div>
    </>
  )
}
