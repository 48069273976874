import { useQuery } from '@tanstack/react-query'
import graphClient from 'app/query-client/graphClient'
import { gql } from 'graphql-request'

const query = gql`
  query Request($organizationId: ID!) {
    getUserRoles(organizationId: $organizationId) {
      name
      created_at
      updated_at
      created_by
      updated_by
      assignedUsers
      assignedOrganizations
      isAdminRole
      _id
    }
  }
`

export interface UserRole {
  name: string
  created_at: string
  updated_at: string
  created_by: string
  updated_by: string
  assignedUsers: string[]
  assignedOrganizations: string[]
  isAdminRole: boolean
  _id: string
}

function getRolesByOrganization(organizationId: string): Promise<UserRole[]> {
  return graphClient.request(query, { organizationId }).then((response) => {
    return response.getUserRoles || []
  })
}

export default function (organizationId: string) {
  return useQuery(['getUserRoles', organizationId], () => getRolesByOrganization(organizationId), {
    refetchOnWindowFocus: false,
    enabled: !!organizationId,
  })
}
