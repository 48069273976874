import { IBreadcrumbItem } from '@fluentui/react'
import { FeedbackMessageBar } from 'app/components/FeedbackMessageBar/FeedbackMessageBar'
import PageBody from 'app/components/PageBody/PageBody.component'
import { Tab, TabPanel } from 'app/components/tab-panel/TabPanel'
import useOrganizationById from 'app/hooks/organization/useOrganizationById'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import { useOrganizationSelection } from 'app/globals/useOrganizationSelection'
import { useEffect } from 'react'
import { ReportingGroupDetails } from './tabs/details/ReportingGroupDetails'
import useGetReportingGroupById from 'app/hooks/reportingGroups/useGetReportingGroupById'
import { ReportingGroupRoles } from './tabs/roles/ReportingGroupRoles'
import { ReportingGroupUsers } from './tabs/users/ReportingGroupUsers'

const ReportingGroupsDetailPage = () => {
  const { t } = useTranslation('organization_details')
  const { organizationId, reportingGroupId } = useParams()
  const { selectedOrganizationId } = useOrganizationSelection()
  const organizationRequest = useOrganizationById(organizationId || '')
  const { isLoading, data } = useGetReportingGroupById(
    { id: reportingGroupId || '', organizationId: organizationId || '' },
    true
  )
  const navigate = useNavigate()

  useEffect(() => {
    if (selectedOrganizationId && selectedOrganizationId !== organizationId) {
      navigate(`/organization/${selectedOrganizationId}/reporting-groups`)
    }
  }, [selectedOrganizationId])

  const buildTabs = (): Tab[] => {
    return [
      {
        title: t('REPORTING_GROUPS.REPORTING_GROUP_DETAILS_PIVOT'),
        component: <ReportingGroupDetails organizationId={organizationId || ''} reportingGroup={data!} />,
        key: 'overview',
      },
      {
        title: t('REPORTING_GROUPS.ROLES_PIVOT'),
        component: <ReportingGroupRoles organizationId={organizationId || ''} reportingGroup={data!} />,
        key: 'roles',
      },
      {
        title: t('REPORTING_GROUPS.USERS_PIVOT'),
        component: <ReportingGroupUsers organizationId={organizationId || ''} reportingGroup={data!} />,
        key: 'users',
      },
    ]
  }

  const onBreadcrumbItemClicked = (event?: React.MouseEvent, item?: IBreadcrumbItem) => {
    navigate(item?.key ?? '/home')
  }
  const breadcrumbItems = [
    {
      text: organizationRequest.data?.name ?? '',
      key: `/organization/${organizationId}`,
      onClick: onBreadcrumbItemClicked,
    },
    {
      text: t('REPORTING_GROUPS.REPORTING_GROUPS'),
      key: `/organization/${organizationId}/reporting-groups`,
      onClick: onBreadcrumbItemClicked,
    },
    { text: data?.name || '', key: 'current', isCurrentItem: true },
  ]

  return (
    <PageBody title={data?.name ?? ''} isLoading={isLoading} breadcrumbItems={breadcrumbItems}>
      <FeedbackMessageBar />
      <TabPanel tabs={buildTabs()} />
    </PageBody>
  )
}

export default ReportingGroupsDetailPage
