import { DetailsRow, IDetailsRowProps, IRenderFunction, SelectionMode } from '@fluentui/react'
import { DateTime } from 'luxon'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'
import graphClient from '../../../query-client/graphClient'
import { getReportsQuery } from '../../../query-client/queries/reportQuery'
import { ElegibleProduct, ProductConfig } from '../../../query-client/types'
import { PathChecker } from '../../../services/path-checker/path-checker'
import { GettingStartedChecklistTasks } from '../../../user-journey/getting-started-checklist/GettingStartedChecklistTasksEnum'
import { useGettingStartedChecklist } from '../../../user-journey/getting-started-checklist/useGettingStartedChecklist'
import useGetProductTypes from 'app/hooks/product/useGetProductTypes'
import { useProductsBuildTable } from './useOrgProductsBuildTable'
import { ReportsChart } from 'app/components/reports-chart/reports-chart.component'
import { PaginatedTable } from '@flexxibleit/flexxible-ui'
import { EmptyOrgProducts } from './EmptyOrgProducts'
import { useOrgProductsActions } from './useOrgProductsActions'
import { useOrgProductsFilters } from './useOrgProductsFilters'
import { usePaginatedProductsConfigQuery } from './usePaginatedProductsConfigQuery'
import usePaginatedData from 'app/hooks/usePaginatedData'
import { useOrgProductsBuildExport } from './useOrgProductsBuildExport'
import { useTableExport } from 'app/hooks/utils/useTableExport'
import useGetBusinessProductConfigs from 'app/hooks/product/useGetBusinessProductConfigs'

interface ProductsProps {
  data: ProductConfig[]
  organizationId?: string
  elegibleProducts?: ElegibleProduct[]
}

export const ProductsFeature = ({ data, organizationId = '', elegibleProducts }: ProductsProps) => {
  const navigate = useNavigate()
  const { t, i18n } = useTranslation('organization_details')
  const startDate = DateTime.now().minus({ days: 30 }).startOf('day')
  const endDate = DateTime.now().endOf('day')
  const { pathname } = useLocation()
  const { completeGettingStartedTask } = useGettingStartedChecklist()
  const { data: products, isFetching: productsLoading } = useGetProductTypes(true)

  // Request data
  const { filterFields } = useOrgProductsFilters({ t })
  const { query, queryName } = usePaginatedProductsConfigQuery()
  const { data: paginatedProducts, isFetching } = usePaginatedData<ProductConfig>(query, queryName, filterFields, {
    businessId: organizationId,
  })

  // Export
  const { productTableExportColumns } = useOrgProductsBuildExport()
  const { refetch: refetchExportProducts } = useGetBusinessProductConfigs(organizationId)
  const { onExportClicked } = useTableExport({
    query: refetchExportProducts,
    columns: productTableExportColumns,
    fileName: 'Product List',
  })

  // Actions and columns
  const { openAddProduct, openEditProduct, commandBarActions, openAgentSettingsProduct } = useOrgProductsActions({
    t,
    organizationId,
    onExportClicked,
    elegibleProducts,
  })
  const { columns } = useProductsBuildTable({
    t,
    i18n,
    products,
    openEditProduct,
    openAgentSettingsProduct,
  })

  const ProductRow = (props: any) => {
    const [isExpanded, setIsExpanded] = useState(false)
    const [reportList, setReportList] = useState(undefined)
    const [isLoading, setIsLoading] = useState(false)
    const [item] = useState(props.detailsProps.item)

    const onNavButtonClicked = () => {
      const comesFromOrganizationDetails = PathChecker.isAllowedPath(pathname, [
        '/organization/:organizationId/products',
      ])
      const comesFromProductsPage = PathChecker.isAllowedPath(pathname, ['/products'])
      let navigationDestination = ''
      if (comesFromOrganizationDetails) {
        navigationDestination = `/organizations/${organizationId}/${item._id}`
      } else if (comesFromProductsPage) {
        navigationDestination = `/products/${item._id}`
      }
      completeGettingStartedTask(GettingStartedChecklistTasks.CONSUMPTION)
      navigate(navigationDestination)
    }

    const fetchReports = async () => {
      setIsLoading(true)
      graphClient
        .request(getReportsQuery, {
          filter: {
            minDate: `${startDate.toMillis()}`,
            maxDate: `${endDate.toMillis()}`,
            productConfig: item._id,
          },
        })
        .then(
          (data: any) => {
            setReportList(data.getReports)
            setIsLoading(false)
          },
          (error) => console.log(error)
        )
    }

    const _onRowClicked = () => {
      setIsExpanded((prev) => !prev)
      item.isExpanded = isExpanded
      fetchReports()
    }

    return (
      <>
        <DetailsRow onClick={_onRowClicked} {...props.detailsProps} />
        {isExpanded && (
          <div style={{ margin: '16px 16px' }}>
            <ReportsChart
              baseline={item.baseline}
              reports={reportList || []}
              showAccumulatedData={true}
              showNavigationButton={true}
              startDate={startDate}
              endDate={endDate}
              isLoading={isLoading || productsLoading}
              onNavButtonClicked={onNavButtonClicked}
            />
          </div>
        )}
      </>
    )
  }

  const _onRenderRow: IRenderFunction<IDetailsRowProps> = (props: any) => {
    if (props) {
      return <ProductRow detailsProps={props} />
    }
    return null
  }

  return (
    <PaginatedTable
      tableId="organization-products"
      locale={i18n.language}
      paginatedData={paginatedProducts}
      columns={columns}
      filters={{
        filterFields,
        smartSearch: true,
        advancedSearch: true,
      }}
      emptyInitialLoadContent={<EmptyOrgProducts t={t} onAddProductClicked={openAddProduct} />}
      commands={commandBarActions}
      isFetching={isFetching}
      selectionMode={SelectionMode.none}
      tableProps={{
        onRenderRow: _onRenderRow,
      }}
    />
  )
}
