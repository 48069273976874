import { FieldTypes } from '@flexxibleit/flexxible-ui'
import { useLocalStorageSearchParams } from 'app/hooks/utils/useLocalStorageSearchParams'
import { TFunction } from 'i18next'

type UseUserFilters = {
  t: TFunction
}

export const useUsersFilters = ({ t }: UseUserFilters) => {
  useLocalStorageSearchParams()

  const filterFields = [
    {
      field: 'name',
      text: t('USERS.TABLE.NAME'),
      type: FieldTypes.STRING,
    },
    {
      field: 'email',
      text: t('USERS.TABLE.EMAIL'),
      type: FieldTypes.STRING,
    },
    {
      field: 'department',
      text: t('USERS.TABLE.DEPARTMENT'),
      type: FieldTypes.STRING,
    },
    {
      field: 'userRole',
      text: t('USERS.TABLE.USER_ROLE'),
      type: FieldTypes.STRING,
    },
  ]

  return {
    filterFields,
  }
}
