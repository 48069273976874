import { useMutation, useQueryClient } from '@tanstack/react-query'
import graphClient from 'app/query-client/graphClient'
import { gql } from 'graphql-request'

interface CreateReportingGroupRequest {
  name: string
  organizationId: string
  productConfigId?: string
  fishingPattern?: string
}

const mutation = gql`
  mutation CreateReportingGroup($input: CreateReportingGroupGraphqlRequest!) {
    createReportingGroup(input: $input) {
      _id
    }
  }
`

interface CreateReportingGroupResponse {
  createReportingGroup: {
    _id: string
  }
}

async function createReportingGroup(input: CreateReportingGroupRequest) {
  const { createReportingGroup }: CreateReportingGroupResponse = await graphClient.request(mutation, {
    input,
  })

  return createReportingGroup
}

export default function () {
  const queryClient = useQueryClient()

  return useMutation(['createReportingGroup'], (input: CreateReportingGroupRequest) => createReportingGroup(input), {
    onSuccess: () => {
      queryClient.invalidateQueries(['getBusinessById'])
    },
  })
}
