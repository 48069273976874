import { gql } from 'graphql-request'
import {
  TargetsDashboard,
} from '../../query-client/types'
import graphClient from '../../query-client/graphClient'
import { useQuery } from '@tanstack/react-query'

const getTargetsDashboard = gql`
  query ($organizationId: String!) {
    getPatchManagementDashboard(organizationId: $organizationId) {
      reportingGroupsCountByPatchPolicy {
        patchPolicy
        value
      }
      patchPolicySummaries(organizationId: $organizationId) {
        targetGroup
        unknownCount
        notApplicableCount
        notInstalledCount
        downloadedCount
        noStatus
        installedCount
        installedPendingRebootCount
        failedCount
      }
    }
  }
`

function useGetTargetsDashboard(organizationId: string | undefined
): Promise<TargetsDashboard> {
  return graphClient
    .request(getTargetsDashboard, {organizationId})
    .then((response) => {
      return response.getPatchManagementDashboard
    })
}

export default function (organizationId: string | undefined) {
  return useQuery(
    ['getTargetsDashboard', organizationId],
    () => useGetTargetsDashboard(organizationId),
    {
      refetchOnWindowFocus: false,
      keepPreviousData: true,
    }
  )
}
