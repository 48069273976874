import { IDropdownOption } from '@fluentui/react'
import { Badge } from 'app/components/Badge/Badge'
import { DisplayField } from 'app/components/DisplayField'
import Col from 'app/components/Layouts/Col'
import Row from 'app/components/Layouts/Row'
import { PRODUCT_ENV } from 'app/config/Consts'
import countries from 'app/config/countries'
import industries from 'app/config/industries'
import { ProductEnvTypes } from 'app/config/productEnv.enum'
import { useMyOrganization } from 'app/hooks/organization/useMyOrganization'
import languages from 'app/i18n/languages'
import { FlexxibleUniqueCode } from 'app/permissions/OrganizationCodes'
import { Business } from 'app/query-client/types'
import { useTranslation } from 'react-i18next'

interface Props {
  business: Business
}

const InformationPanel = ({ business }: Props) => {
  const { t } = useTranslation('organizations')
  const { data: myOrganization } = useMyOrganization()

  const showCode = myOrganization?.uniqueCode === FlexxibleUniqueCode

  return (
    <>
      <Row>
        <Col className="col-xs-12 col-lg-6">
          <DisplayField label={t('FORM.NAME')}>
            {business?.isTrial && <Badge>{t('organizations:TRIAL')}</Badge>}
            <span>{business?.name}</span>
          </DisplayField>
        </Col>
        <Col className="col-xs-12 col-lg-6">
          <DisplayField label={t('FORM.EMAIL')}>{business.email}</DisplayField>
        </Col>
      </Row>

      <Row>
        <Col className="col-xs-12 col-lg-6">
          <Row>
            <Col className="col-xs-12 col-lg-6">
              <DisplayField label={t('FORM.LANGUAGE')}>
                {languages.find((option: IDropdownOption) => option.key == business.language)?.text}
              </DisplayField>
              <DisplayField label={t('FORM.COUNTRY')}>
                {countries.find((option: IDropdownOption) => option.key == business.country)?.text}
              </DisplayField>
            </Col>
          </Row>
        </Col>

        <Col className="col-xs-12 col-lg-6">
          <Row>
            <Col className="col-xs-12 col-lg-6">
              <DisplayField label={t('FORM.INDUSTRY')}>
                {industries.find((option: IDropdownOption) => option.key == business.industry)?.text}
              </DisplayField>
              {PRODUCT_ENV !== ProductEnvTypes.FXXONE && (
                <>
                  <DisplayField label={t('FORM.SERVICENOW_COMPANY_ID')}>{business.serviceNowCompanyId}</DisplayField>
                </>
              )}
            </Col>
          </Row>
        </Col>
      </Row>

      <Row>
        <Col className="col-xs-12 col-lg-6">
          <DisplayField label={t('FORM.DESCRIPTION')}>{business.description}</DisplayField>
        </Col>
        {business.policy?.name && (
          <Col className="col-xs-12 col-lg-6">
            <DisplayField label={t('FORM.POLICY')}>{business.policy?.name}</DisplayField>
          </Col>
        )}
      </Row>
      <Row>
        {business.elegibleProducts && business.elegibleProducts.length > 0 && (
          <Col className="col-xs-12 col-lg-6">
            <DisplayField label={t('FORM.DEFAULT_POLICY')}>
              {business?.defaultPolicy?.name || t('FORM.DEFAULT_POLICY_EMPTY')}
            </DisplayField>
          </Col>
        )}
        {business.uniqueCode && showCode && (
          <Col className="col-xs-12 col-lg-6">
            <DisplayField label={t('FORM.UNIQUE_CODE')}>{business.uniqueCode}</DisplayField>
          </Col>
        )}
      </Row>
    </>
  )
}

export default InformationPanel
