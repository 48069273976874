export const useUserLoginsBuildExport = () => {
  const exportColumns = [
    {
      key: 'date',
      header: 'Date',
      width: 20,
    },
    {
      key: 'ip',
      header: 'IP',
      width: 20,
    },
    {
      key: 'application',
      header: 'Application',
      width: 40,
    },
    {
      key: 'userAgent',
      header: 'User Agent',
      width: 40,
    },
  ]

  return { exportColumns }
}
