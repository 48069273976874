import React, { FC, useEffect, useState } from 'react'
import PageBody from '../../../components/PageBody/PageBody.component'
import {
  BadgeMode,
  CreateButton,
  LoadingSpinner,
  MOBILE_QUERY,
  SearchBox,
  StatusBadge,
  useMediaQuery,
  ViewDetailsActionButton,
} from '@flexxibleit/flexxible-ui'
import { ColumnActionsMode, DetailsListLayoutMode, IColumn, Icon, SelectionMode, Stack } from '@fluentui/react'
import { useNavigate, useParams } from 'react-router-dom'
import { useOrganizationSelection } from '../../../globals/useOrganizationSelection'
import Table from 'app/components/Table/Table.component'
import { useTranslation } from 'react-i18next'
import { CellWithFeatureLink } from 'app/components/Table/CellWithFeatureLink'
import { FEATURE_NAMES } from 'app/permissions/FeatureName.enum'
import useCreateForm from 'app/hooks/useCreateForm'
import { PatchPolicy, WindowsPatchPolicy } from 'app/query-client/types'
import EmptyStatus from 'app/components/status/empty-status'
import { CreateWindowsPatchPolicyForm } from './components/CreateWindowsPatchPolicyForm'
import useGetWindowsPatchPoliciesByOrganization from 'app/hooks/patchPolicies/useGetWindowsPatchPoliciesByOrganization'
import { useFeatureRender } from '../../../permissions/useFeatureRender'
import { FeatureRender } from '../../../permissions/FeatureRender'
import { withFeatureRender } from '../../../permissions/withFeatureRender'

const WindowsPatchPolicies: FC = () => {
  const [isMobile] = useMediaQuery(MOBILE_QUERY)
  const { selectedOrganization } = useOrganizationSelection()
  const navigate = useNavigate()
  const { t, i18n } = useTranslation('patch_management')
  const { organizationId } = useParams()
  const { isLoading, data } = useGetWindowsPatchPoliciesByOrganization(organizationId || '')
  const [items, setItems] = useState<WindowsPatchPolicy[]>([])
  const [textFilter, setTextFilter] = useState<string>()
  const baseUrl = location.pathname.split('/')[1]
  const { checkFeature } = useFeatureRender()

  useEffect(() => {
    if (!selectedOrganization) {
      return
    }
    if (selectedOrganization._id !== organizationId) {
      navigate(`/${baseUrl}/${selectedOrganization._id}/patch-management/windows-patch-policies/`)
    }
  }, [selectedOrganization])

  const _onFilter = (ev: React.ChangeEvent<HTMLInputElement> | undefined, text?: string): void => {
    setTextFilter(text)
  }

  useEffect(() => {
    if (data && data.length > 0) {
      let filteredItems = data

      if (textFilter) {
        filteredItems = filteredItems.filter((item: WindowsPatchPolicy) => {
          return item.name.toLowerCase().includes(textFilter.toLowerCase())
        })
      }

      setItems(filteredItems)
    } else {
      setItems([])
    }
  }, [data, textFilter])

  const onClickViewDetails = (windowsPatchPolicyId: string) => {
    navigate(
      `/organization/${selectedOrganization?._id}/patch-management/windows-patch-policies/${windowsPatchPolicyId}`
    )
  }

  //TODO use this for targets
  const onClickViewDetailsTarget = (patchPolicyTargetId: string) => {
    navigate(`/organization/${selectedOrganization?._id}/patch-management/patch-policy-targets/${patchPolicyTargetId}`)
  }

  const patchPoliciesColumns: IColumn[] = [
    {
      key: 'name',
      name: t('WINDOWS_PATCH_POLICIES.TABLE.NAME'),
      fieldName: 'name',
      minWidth: 100,
      flexGrow: 1,
      maxWidth: 600,
      isRowHeader: true,
      columnActionsMode: ColumnActionsMode.disabled,
      onRender: (item: PatchPolicy) => {
        return (
          <CellWithFeatureLink
            feature={FEATURE_NAMES.PATCH_MANAGEMENT_READ}
            callback={() => onClickViewDetails(item._id)}
          >
            {item.name}
          </CellWithFeatureLink>
        )
      },
    },
    {
      key: 'patchPolicyTargets',
      name: t('WINDOWS_PATCH_POLICIES.TABLE.PATCH_POLICY_TARGETS'),
      fieldName: 'patchPolicyTargets',
      minWidth: 100,
      maxWidth: 500,
      isRowHeader: true,
      columnActionsMode: ColumnActionsMode.disabled,
      onRender: (item: WindowsPatchPolicy) => {
        const patchPolicyTargets = item.patchPolicyTargets ?? []

        return (
          <span>
            {patchPolicyTargets.map((i, index) => (
              <React.Fragment key={i._id}>
                <CellWithFeatureLink
                  feature={FEATURE_NAMES.PATCH_MANAGEMENT_READ}
                  callback={() => onClickViewDetailsTarget(i._id)}
                >
                  {i.name}
                </CellWithFeatureLink>
                {index < patchPolicyTargets.length - 1 && ', '}
              </React.Fragment>
            ))}
          </span>
        )
      },
    },
    {
      key: 'hasAutomaticApprovals',
      name: t('WINDOWS_PATCH_POLICIES.TABLE.HAS_AUTOMATIC_APPROVALS'),
      fieldName: 'active',
      minWidth: 0,
      isPadded: true,
      isRowHeader: true,
      columnActionsMode: ColumnActionsMode.disabled,
      onRender: (item: PatchPolicy) => {
        return (
          <StatusBadge
            active={item.hasAutomaticApprovals ?? false}
            mode={BadgeMode.circle}
            size={8}
            labels={{ active: t('general:STATUS.ENABLED'), inactive: t('general:STATUS.DISABLED') }}
          />
        )
      },
    },
    {
      key: 'actions',
      name: t('WINDOWS_PATCH_POLICIES.TABLE.ACTIONS'),
      minWidth: 100,
      isRowHeader: true,
      columnActionsMode: ColumnActionsMode.disabled,
      onRender: (item: WindowsPatchPolicy) => {
        return <ViewDetailsActionButton isMobile={isMobile} onClick={() => onClickViewDetails(item._id)} />
      },
    },
  ]

  const onCreate = useCreateForm(
    {
      title: t('WINDOWS_PATCH_POLICIES.CREATE_FORM'),
      isFooterAtBottom: true,
    },
    <CreateWindowsPatchPolicyForm />
  )

  return (
    <PageBody title={t('WINDOWS_PATCH_POLICIES.TITLE')} isLoading={false}>
      {isLoading ? (
        <LoadingSpinner />
      ) : !data?.length ? (
        <div style={{ marginTop: 100 }}>
          <EmptyStatus
            message={t('WINDOWS_PATCH_POLICIES.EMPTY_MESSAGE')}
            img={<Icon iconName="EntitlementPolicy" style={{ fontSize: 68 }} />}
            {...(checkFeature(FEATURE_NAMES.PATCH_MANAGEMENT_CREATE) && {
              buttonProps: {
                text: t('general:BUTTON.CREATE'),
                iconProps: { iconName: 'Add' },
                onClick: onCreate,
              },
            })}
          />
        </div>
      ) : (
        <>
          <Stack
            horizontal={!isMobile}
            horizontalAlign="space-between"
            tokens={{ padding: '20px 0 0 0' }}
            className="mb-sm-2"
          >
            <SearchBox
              placeholder={t('WINDOWS_PATCH_POLICIES.SEARCH_PLACEHOLDER')}
              onChange={_onFilter}
              style={{ width: isMobile ? '100%' : '300px' }}
            />
            <FeatureRender feature={FEATURE_NAMES.PATCH_MANAGEMENT_CREATE}>
              <CreateButton locale={i18n.language} onClick={onCreate} />
            </FeatureRender>
          </Stack>
          <Table
            tableId="windows-patch-policies"
            isEmpty={items.length === 0}
            emptyMessage={t('general:EMPTY_FILTER')}
            tableProps={{
              items: items,
              columns: patchPoliciesColumns,
              compact: false,
              selectionMode: SelectionMode.none,
              layoutMode: DetailsListLayoutMode.justified,
              isHeaderVisible: true,
            }}
          />
        </>
      )}
    </PageBody>
  )
}

export const WindowsPatchPoliciesPage = withFeatureRender(WindowsPatchPolicies, FEATURE_NAMES.PATCH_MANAGEMENT_READ)
