import { FEATURE_NAME, FEATURE_NAMES } from '../FeatureName.enum'
import { RolesOnBusiness } from '../../models/user/RolesOnBusiness'

const checkOnlyUserHasL1ReadHigher = (rolesOnBusiness: RolesOnBusiness) => {
  if (rolesOnBusiness.isPortalUser()) {
    return rolesOnBusiness.hasL1ReadHigher()
  }

  return true
}

const checkOnlyUserHasL2OrHigher = (rolesOnBusiness: RolesOnBusiness) => {
  if (rolesOnBusiness.isPortalUser()) {
    return rolesOnBusiness.hasL2OrHigher()
  }

  return true
}

export const authorizationExceptions: Partial<Record<FEATURE_NAME, (rolesOnBusiness: RolesOnBusiness) => boolean>> = {
  [FEATURE_NAMES.ENABLED_MICROSERVICE_READ]: checkOnlyUserHasL1ReadHigher,
  [FEATURE_NAMES.FLOWS_CREATE]: checkOnlyUserHasL2OrHigher,
  [FEATURE_NAMES.FLOWS_READ]: checkOnlyUserHasL1ReadHigher,
  [FEATURE_NAMES.FLOWS_UPDATE]: checkOnlyUserHasL2OrHigher,
  [FEATURE_NAMES.MICROSERVICES_READ]: checkOnlyUserHasL1ReadHigher,
  [FEATURE_NAMES.OPERATIONS_READ]: checkOnlyUserHasL1ReadHigher,
  [FEATURE_NAMES.PATCH_MANAGEMENT_CREATE]: checkOnlyUserHasL2OrHigher,
  [FEATURE_NAMES.PATCH_MANAGEMENT_DELETE]: checkOnlyUserHasL2OrHigher,
  [FEATURE_NAMES.PATCH_MANAGEMENT_READ]: checkOnlyUserHasL1ReadHigher,
  [FEATURE_NAMES.PATCH_MANAGEMENT_UPDATE]: checkOnlyUserHasL2OrHigher,
  [FEATURE_NAMES.WORKSPACES_READ]: checkOnlyUserHasL1ReadHigher,
  [FEATURE_NAMES.WORKSPACE_GROUPS_READ]: checkOnlyUserHasL1ReadHigher,
}
