import { DisplayField } from 'app/components/DisplayField'
import { useTranslation } from 'react-i18next'
import EditButton from '../../../../components/Buttons/EditButton.component'
import FlexxibleCard from '../../../../components/FlexxibleCard/FlexxibleCard.component'
import Col from '../../../../components/Layouts/Col'
import Row from '../../../../components/Layouts/Row'
import { MicroserviceView } from '../../../../hooks/microservices/useGetDesignerMicroserviceById'
import { useGetOrganizationsForSelection } from '../../../../hooks/organization/useGetOrganizationsForSelection'
import useCreateForm from '../../../../hooks/useCreateForm'
import MicroserviceDetailsPrivacyForm from './MicroserviceDetailsPrivacyForm'

interface Props {
  microservice: MicroserviceView
  canEdit: boolean
}

const cardProps = { style: { marginTop: 30 } }

export const MicroserviceDetailsPrivacy = ({ microservice, canEdit }: Props) => {
  const { t } = useTranslation('microservices')
  const { data: organizationList } = useGetOrganizationsForSelection(true)

  const microServicePrivicy = microservice.privacySettings

  const buildPrivacy = () => {
    return microServicePrivicy.isPrivate ? t('DESIGNER.PRIVACY.PRIVATE') : t('DESIGNER.PRIVACY.PUBLIC')
  }
  const buildOrganizations = () => {
    if (!microServicePrivicy.isPrivate) return 'All'
    if (!organizationList) return ''
    return microServicePrivicy.visibleTo
      ?.map((id) => {
        const organization = organizationList?.find((org) => org._id === id)
        return organization?.name
      })
      .join(', ')
  }

  const openCreateOrganizationForm = useCreateForm(
    {
      title: t('DESIGNER.PRIVACY.PRIVACY_EDIT_TITLE'),
      isFooterAtBottom: true,
    },
    <MicroserviceDetailsPrivacyForm microservice={microservice} />
  )

  return (
    <FlexxibleCard cardProps={cardProps} styleNames="col-md-12 d-flex d-flexCol d-flexJustifyBetween">
      <Row>
        <Col>
          <DisplayField label={t('DETAILS.PRIVACY.LABEL')}>{buildPrivacy()}</DisplayField>
        </Col>
        <Col>
          <DisplayField label={t('DETAILS.PRIVACY.ORGANIZATIONS')}>{buildOrganizations()}</DisplayField>
        </Col>
      </Row>
      {canEdit && (
        <div className="d-flex d-flexRowReverse">
          <EditButton onClick={openCreateOrganizationForm} />
        </div>
      )}
    </FlexxibleCard>
  )
}
