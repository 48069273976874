import { useTranslation } from 'react-i18next'
import { Dispatch, SetStateAction, useMemo, useState } from 'react'
import { PaginationArgs, Workspace } from '../../../query-client/types'
import useGetNotAddedWorkspacesList from '../../../hooks/workspace/useGetNotAddedWorkspacesList'
import { ColumnActionsMode, IColumn, IDetailsListStyles, Selection, SelectionMode } from '@fluentui/react'
import Table from '../../../components/Table/Table.component'
import { usePaginatedSelection } from '../../../hooks/utils/usePaginatedSelection'

interface Props {
  organizationId: string,
  selectedWorkspaces: Workspace[],
  setSelectedWorkspacesState: Dispatch<SetStateAction<Workspace[]>>,
  searchTerm: string,
  getColumns: () => IColumn[],
}

export const GroupedWorkspacesEditNotAddedWorkspaces = (props: Props) => {
  const { t } = useTranslation('organization_details');

  const [paginationState, setPaginationState] = useState<PaginationArgs>(() => ({
    page: 1,
    perPage: 10,
  }));

  const {
    data: notAddedWorkspacesPagination,
    isLoading,
    isFetching,
  } = useGetNotAddedWorkspacesList(
    props.organizationId,
    props.selectedWorkspaces.map((workspace) => workspace.sysId),
    props.searchTerm,
    paginationState
  );

  const notAddedWorkspacesPage: Workspace[] = useMemo(
    () => notAddedWorkspacesPagination?.results || [],
    [notAddedWorkspacesPagination]
  );

  const selection = usePaginatedSelection(
    (item: Workspace) => item.sysId,
    props.setSelectedWorkspacesState
  );

  const handleOnPaginationChange = (newPaginationState: PaginationArgs) => {
    setPaginationState(newPaginationState);
  }

  const hideSelectAllControlStyle: Partial<IDetailsListStyles> = {
    headerWrapper: {
      '.ms-DetailsHeader-cellIsCheck':{
        visibility: 'hidden'
      }
    }
  };

  const renderTable = () => {
    return (
      <Table
        tableId="not-added-workspaces"
        isLoading={isLoading}
        isEmpty={notAddedWorkspacesPage?.length === 0}
        emptyMessage={t('general:EMPTY_FILTER')}
        tableProps={{
          columns: props.getColumns(),
          items: notAddedWorkspacesPage,
          selection: selection as Selection,
          selectionMode: SelectionMode.multiple,
          selectionPreservedOnEmptyClick: true,
          isSelectedOnFocus: false,
          styles: hideSelectAllControlStyle,
          onShouldVirtualize: () => false,
        }}
        paginationProps={
          notAddedWorkspacesPagination
            ? {
                pagination: paginationState,
                onPaginationChange: handleOnPaginationChange,
                paginatedData: notAddedWorkspacesPagination,
                isFetching: isFetching,
              }
            : undefined
        }
      />
    )
  };

  return renderTable();
}
