import { TFunction } from 'i18next'
import { FieldTypes, FilterField } from '@flexxibleit/flexxible-ui'

export const useInstalledAppVersionsFilter = ({ t }: { t: TFunction }): { filterFields: FilterField[] } => {
  const filterFields: FilterField[] = [
    {
      field: 'version',
      text: t('INSTALLED_APPS.DETAILS.VERSIONS.TABLE.VERSION'),
      type: FieldTypes.STRING,
    },
    {
      field: 'workspacesCount',
      text: t('INSTALLED_APPS.DETAILS.VERSIONS.TABLE.WORKSPACES_COUNT'),
      type: FieldTypes.NUMBER,
    },
  ]

  return {
    filterFields,
  }
}