import { withFeatureRender } from '../../permissions/withFeatureRender'
import { FEATURE_NAMES } from '../../permissions/FeatureName.enum'
import PageBody from '../../components/PageBody/PageBody.component'
import { WorkspaceGroupsFeature } from '../organization-details/workspace-groups/WorkspaceGroupsFeature'
import { useTranslation } from 'react-i18next'
import { useOrganizationSelection } from '../../globals/useOrganizationSelection'
import { useEffect } from 'react'
import { useOrganizationByIdForAllRoles } from '../../hooks/organization/useOrganizationByIdForAllRoles'
import { TablePageBody } from '@flexxibleit/flexxible-ui'

const WorkspaceGroups = () => {
  const { selectedOrganizationId } = useOrganizationSelection()
  const { data, isLoading, isFetching } = useOrganizationByIdForAllRoles(selectedOrganizationId || '')
  const { t } = useTranslation('workspace_groups')

  return (
    <TablePageBody title={t('TITLE')} titleTag={data?.name} isLoading={isLoading || isFetching}>
      <WorkspaceGroupsFeature
        organizationId={data?._id ?? ''}
        detailPageUrlBase={`/workspace-groups/`}
      />
    </TablePageBody>
  );
}

export const WorkspaceGroupsPage = withFeatureRender(WorkspaceGroups, FEATURE_NAMES.WORKSPACE_GROUPS_READ)
