import { useQuery } from '@tanstack/react-query'
import { MicroserviceEvent } from '../../models/microservices/MicroserviceEvent'
import graphClient from '../../query-client/graphClient'
import { gql } from 'graphql-request'

const getMicroserviceEventsQuery = gql`
  query ($id: ID!) {
    getMicroserviceEventById(id: $id) {
      _id
      type
      microservice {
        _id
        name {
          code
          text
          translatedByIA
        }
      }
      initiatedBy
      status
      duration
      data
      executionStartTime
      executionEndTime
      loggedAt
      reportStartTime
      reportEndTime
      business {
        _id
        name
      }
    }
  }
`

export async function getMicroserviceEventById(id: string): Promise<MicroserviceEvent> {
  let r = await graphClient.request(getMicroserviceEventsQuery, { id: id })
  return await r.getMicroserviceEventById
}

export const useGetMicroserviceEventById = (id: string) => {
  return useQuery(['microserviceEvents', id], () => getMicroserviceEventById(id))
}
