import { Business, Workspace, WorkspaceGroup } from '../../../query-client/types'
import { ChangeEvent, useEffect, useState } from 'react'
import { IColumn, Pivot, PivotItem, Stack } from '@fluentui/react'
import { useTranslation } from 'react-i18next'
import useSetWorkspacesInWorkspaceGroup from '../../../hooks/workspaceGroup/useSetWorkspacesInWorkspaceGroup'
import FormActions from '../../../components/forms/FormActions'
import useMediaQuery from '../../../hooks/utils/useMediaQuery'
import { MOBILE_QUERY } from '../../../config/media-query'
import { GroupedWorkspacesEditAllWorkspaces } from './GroupedWorkspacesEditAllWorkspaces'
import { GroupedWorkspacesEditAddedWorkspaces } from './GroupedWorkspacesEditAddedWorkspaces'
import { GroupedWorkspacesEditNotAddedWorkspaces } from './GroupedWorkspacesEditNotAddedWorkspaces'
import _ from 'lodash'
import { SearchBox } from "@flexxibleit/flexxible-ui";

interface Props {
  workspaceGroup: WorkspaceGroup
  organization: Business
  onExitEditMode: () => void
  getColumns: () => IColumn[]
}

export const GroupedWorkspacesEditMode = (props: Props) => {
  const { t } = useTranslation('organization_details')
  const [isMobile] = useMediaQuery(MOBILE_QUERY)
  const setWorkspacesInWorkspaceGroup = useSetWorkspacesInWorkspaceGroup()

  const [selectedWorkspacesState, setSelectedWorkspacesState] = useState<Workspace[]>(() => {
    return (
      props.workspaceGroup.manuallyAddedWorkspaces?.map((workspace) => {
        return {
          sysId: workspace,
        } as Workspace
      }) || []
    )
  })

  const [searchBoxValueState, setSearchBoxValueState] = useState<string>('')

  const handleOnSearchBoxChange = _.debounce((e?: ChangeEvent, value?: string) => {
    setSearchBoxValueState(value || '')
  }, 500)

  const handleOnSaveButtonClick = () => {
    const selectedWorkspacesSysIds = selectedWorkspacesState.map((workspace) => workspace.sysId)

    setWorkspacesInWorkspaceGroup.mutate({
      workspaceGroupId: props.workspaceGroup._id,
      workspaceSysIds: selectedWorkspacesSysIds,
    })
  }

  useEffect(() => {
    if (setWorkspacesInWorkspaceGroup.isSuccess) {
      props.onExitEditMode()
    }
  }, [setWorkspacesInWorkspaceGroup])

  const handleOnCancelButtonClick = () => {
    props.onExitEditMode()
  }

  const [pivotSelectedKeyState, setPivotSelectedKeyState] = useState<string>('all')

  const handlePivotOnLinkClick = (item?: PivotItem) => {
    if (!item || !item.props || !item.props.itemKey) {
      return
    }
    setPivotSelectedKeyState(item.props.itemKey)
  }

  const renderTable = () => {
    switch (pivotSelectedKeyState) {
      case 'all':
        return (
          <GroupedWorkspacesEditAllWorkspaces
            organizationId={props.organization._id}
            setSelectedWorkspacesState={setSelectedWorkspacesState}
            searchTerm={searchBoxValueState}
            getColumns={props.getColumns}
          />
        )
      case 'added':
        return (
          <GroupedWorkspacesEditAddedWorkspaces
            organizationId={props.organization._id}
            selectedWorkspaces={selectedWorkspacesState}
            setSelectedWorkspacesState={setSelectedWorkspacesState}
            searchTerm={searchBoxValueState}
            getColumns={props.getColumns}
          />
        )
      case 'notAdded':
        return (
          <GroupedWorkspacesEditNotAddedWorkspaces
            organizationId={props.organization._id}
            selectedWorkspaces={selectedWorkspacesState}
            setSelectedWorkspacesState={setSelectedWorkspacesState}
            searchTerm={searchBoxValueState}
            getColumns={props.getColumns}
          />
        )
    }
  }

  return (
    <div>
      <Stack
        horizontal={!isMobile}
        horizontalAlign="space-between"
        verticalAlign="center"
        tokens={{ padding: '20px 0 0 0' }}
        className="mb-sm-2"
        style={{ minHeight: '70px' }}
      >
        <Stack.Item grow={1} style={{ maxWidth: 400, minWidth: 196 }}>
          <SearchBox placeholder={t('WORKSPACE_GROUPS.SEARCH_PLACEHOLDER')} onChange={handleOnSearchBoxChange} />
        </Stack.Item>

        <Stack.Item>
          <div
            className={`d-flex d-flexRow d-flexAlignItemsCenter ${isMobile ? 'd-flexJustifyBetween' : ''} `}
            style={isMobile ? { marginTop: 15 } : {}}
          >
            <Pivot headersOnly={true} selectedKey={pivotSelectedKeyState} onLinkClick={handlePivotOnLinkClick}>
              <PivotItem itemKey="all" headerText={t('WORKSPACE_GROUPS.FILTERS.ALL')} />
              <PivotItem itemKey="added" headerText={t('WORKSPACE_GROUPS.FILTERS.ADDED')} />
              <PivotItem itemKey="notAdded" headerText={t('WORKSPACE_GROUPS.FILTERS.NOT_ADDED')} />
            </Pivot>

            <FormActions
              cancelProps={{
                onClick: handleOnCancelButtonClick,
              }}
              submitProps={{
                text: t('general:BUTTON.SAVE'),
                onClick: handleOnSaveButtonClick,
                disabled: setWorkspacesInWorkspaceGroup.isLoading,
                iconProps: {
                  iconName: 'Save',
                },
              }}
            />
          </div>
        </Stack.Item>
      </Stack>

      {renderTable()}
    </div>
  )
}
