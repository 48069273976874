import {
  DashboardCardAction,
  DashboardMetricCard,
} from '../../../../../../components/Dashboard/DashboardMetricCard/DashboardMetricCard'
import { useTranslation } from 'react-i18next'
import { usePatchManagementSummaryData } from '../../usePatchManagementSummaryData'
import { useNavigate, useParams } from 'react-router-dom'
import useOrganizationById from '../../../../../../hooks/organization/useOrganizationById'
import { ReportingGroup } from '../../../../../../query-client/types'
import { useFeatureRender } from 'app/permissions/useFeatureRender'
import { FEATURE_NAMES } from 'app/permissions/FeatureName.enum'

interface Props {
  isInfo?: boolean
}

export const AllReportingGroupsMustHaveTargetCard = (props: Props) => {
  const { t } = useTranslation('patch_management')
  const { data: patchSummary } = usePatchManagementSummaryData()
  const { organizationId } = useParams()
  const { data: organization } = useOrganizationById(organizationId || '')
  const totalReportingGroupsWithoutPatchPolicyTarget = patchSummary?.reportingGroupsWithoutPatchPolicyTarget || 0
  const reportingGroups: ReportingGroup[] = organization?.reportingGroups || []
  const navigate = useNavigate()
  const { checkFeature } = useFeatureRender()

  const action = (): DashboardCardAction | undefined => {
    return {
      onClick: () => {
        navigate(`/organization/${organizationId}/reporting-groups`)
      },
      label: 'Fix',
    }
  }

  const calculateReportingGroupsWithoutPatchPolicyPercentage = (count: number, reportingGroupsCount: number) => {
    return (count / reportingGroupsCount) * 100
  }

  return (
    <DashboardMetricCard
      title={t('DASHBOARD.TABS.PATCH_POLICIES_TAB.METRIC_CARDS.REPORTING_GROUPS_WITHOUT_PATCH_POLICY_TITLE')}
      subtitle={`${totalReportingGroupsWithoutPatchPolicyTarget} ${t(
        'DASHBOARD.TABS.PATCH_POLICIES_TAB.METRIC_CARDS.REPORTING_GROUPS_WITHOUT_PATCH_POLICY_SUBTITLE'
      )}`}
      icon={{
        name: props.isInfo ? 'CheckMark' : 'Warning',
        color: props.isInfo ? 'green' : 'orange',
      }}
      unitLabel={'%'}
      value={calculateReportingGroupsWithoutPatchPolicyPercentage(
        totalReportingGroupsWithoutPatchPolicyTarget,
        reportingGroups.length
      ).toFixed()}
      action={props.isInfo || !checkFeature(FEATURE_NAMES.REPORTING_GROUP_READ) ? undefined : action()}
    />
  )
}
