import { EmptyStatus } from '@flexxibleit/flexxible-ui'
import EmptyUser from '../../../assets/img/user-icon.svg'
import { TFunction } from 'i18next'
import { useFeatureRender } from '../../../permissions/useFeatureRender'
import { FEATURE_NAMES } from '../../../permissions/FeatureName.enum'

type EmptyUserRolesProps = {
  t: TFunction
  onCreate: () => void
}

export const EmptyUserRoles = ({ t, onCreate }: EmptyUserRolesProps) => {
  const { checkFeature } = useFeatureRender()

  return (
    <div style={{ marginTop: 100 }}>
      <EmptyStatus
        img={EmptyUser}
        message={t('ROLES.TABLE.EMPTY_MESSAGE')}
        {...(checkFeature(FEATURE_NAMES.USER_CREATE) && {
          buttonProps: {
            text: t('general:BUTTON.CREATE'),
            iconProps: { iconName: 'Add' },
            onClick: onCreate,
          },
        })}
      />
    </div>
  )
}
