import { useMutation, useQueryClient } from '@tanstack/react-query'
import graphClient from 'app/query-client/graphClient'
import { gql } from 'graphql-request'

interface UpdatePatchPolicyDetailsTargetGraphqlRequest {
  name: string
  reportingGroups: string[]
  shouldRestart: boolean
  schedule: string
  weeks: number[]
  timezone: string
  maxSimultaneousWorkspaces: number
  isInMaintenance: boolean
  windowsPatchPolicyId?: string
  linuxPatchPolicyId?: string
  forcePatchingConfiguration?: boolean
  wakeOnLAN?: boolean
}

interface Response {
  updatePatchPolicyTargetDetails: {
    _id: string
  }
}

const mutation = gql`
  mutation Request($id: String!, $input: UpdatePatchPolicyTargetDetailsGraphqlRequest!) {
    updatePatchPolicyTargetDetails(id: $id, input: $input) {
      _id
    }
  }
`

const updatePatchPolicy = async (id: string, input: UpdatePatchPolicyDetailsTargetGraphqlRequest): Promise<string> => {
  const response: Response = await graphClient.request(mutation, {
    id,
    input,
  })
  return response.updatePatchPolicyTargetDetails._id
}

export const useUpdatePatchPolicyTarget = () => {
  const queryClient = useQueryClient()

  return useMutation(
    ['updatePatchPolicyDetailsTarget'],
    ({ id, input }: { id: string; input: UpdatePatchPolicyDetailsTargetGraphqlRequest }) =>
      updatePatchPolicy(id, input),
    {
      onSuccess: async (id) => {
        await queryClient.invalidateQueries(['getPatchPolicyTarget', id])
      },
    }
  )
}
