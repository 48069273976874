import { useMutation, useQueryClient } from '@tanstack/react-query'
import { gql } from 'graphql-request'
import graphClient from '../../query-client/graphClient'
import { CreateUserFormType } from '../../pages/organization-details/users/UserForm'
import { useAuth } from '../../globals/useAuth'
import { AuthUser } from '../../globals/interfaces'
import { useMe } from '../me/useMe'

const updateUserQuery = gql`
  mutation Request($id: ID!, $input: UserEditInput!) {
    updateUser(id: $id, input: $input) {
      _id
      name
      userRole {
        _id
        name
      }
      language
      email
    }
  }
`

function updateUser(id: string, user: any) {
  return graphClient
    .request(updateUserQuery, {
      id,
      input: user,
    })
    .then((response) => response.getUser)
}

interface UserEditInput {
  name: string
  surname: string
  email: string
  userRole: string
  department: string
}

export default function () {
  const queryClient = useQueryClient()
  const { user: authUser, setUser } = useAuth()
  const { data: userMe } = useMe()

  return useMutation(
    ['updateUser'],
    ({ user, userId }: { user: UserEditInput; userId: string }) => updateUser(userId, user),
    {
      onSuccess: (_, { user, userId }) => {
        queryClient.invalidateQueries(['getBusinessById'])
        queryClient.invalidateQueries(['getMe'])

        if (userId === userMe?._id) {
          setUser({
            ...authUser,
            // workspacePermission: user.workspacePermission,
          } as AuthUser)
        }
      },
    }
  )
}
