import { ControlledTextField, FormActionsContainer, LoadingSpinner, SuccessStatus } from '@flexxibleit/flexxible-ui'
import * as z from 'zod'
import { FormProvider, useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import RowColLayout from '../../../components/Layouts/RowColLayout'
import { useTranslation } from 'react-i18next'
import FormActions from '../../../components/forms/FormActions'
import ErrorStatus from '../../../components/status/error-status'
import { ReactNode, useEffect, useState } from 'react'
import useImportWorkspaces from '../../../hooks/workspaceGroup/useImportWorkspaces'
import { DirectionalHint, Icon, Pivot, PivotItem, TooltipDelay, TooltipHost } from '@fluentui/react'
import { StringParam, useQueryParam } from 'use-query-params'

export interface ImportWorkspacesFormType {
  workspacesList: string
}

export interface ImportWorkspacesInputType {
  workspaces: string[]
}

export interface ImportWorkspacesFormProps {
  workspaceGroupId: string
}

export interface WorkspaceGroupId {
  _id: string
}

export interface ImportWorkspacesResult {
  errorMatch: string[]
  successMatch: string[]
  existsMatch: string[]
  workspaceGroup: WorkspaceGroupId
}

const schema: z.ZodType<Partial<ImportWorkspacesFormType>> = z.object({
  workspacesList: z
    .string({
      required_error: 'required',
    })
    .min(1, { message: 'required' }),
})

const ImportWorkspacesForm = ({ workspaceGroupId }: ImportWorkspacesFormProps) => {
  const { t, i18n } = useTranslation('organization_details')
  const MAX_WORKSPACES = 20000

  const methods = useForm<ImportWorkspacesFormType>({
    resolver: zodResolver(schema),
    defaultValues: { workspacesList: '' },
  })

  const listWatch = methods.watch('workspacesList')

  const importWorkspaces = useImportWorkspaces(workspaceGroupId)

  const [isSuccess, setIsSuccess] = useState(false)
  const [isError, setIsError] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [resultImport, setResultImport] = useState<ImportWorkspacesResult | null>(null)
  const [countWorkspaces, setCountWorkspaces] = useState(0)
  const [copied, setCopied] = useState(false)
  const [tabQueryParameter, setTabQueryParameter] = useQueryParam('filter', StringParam)

  useEffect(() => {
    setIsSuccess(importWorkspaces.isSuccess)
    setIsError(importWorkspaces.isError)
    setIsLoading(importWorkspaces.isLoading)
    setResultImport(importWorkspaces.data)
  }, [importWorkspaces])

  useEffect(() => {
    const count = listWatch.split('\n').length

    setCountWorkspaces(count)
  }, [listWatch])

  const handleSubmit = (data: ImportWorkspacesFormType) => {
    if (countWorkspaces >= MAX_WORKSPACES) {
      return
    }
    if (data?.workspacesList?.length > 0) {
      const workspaces = data.workspacesList.split('\n')
      const uniqueWorkspaces = Array.from(new Set(workspaces)).filter((workspace) => workspace !== '')

      importWorkspaces.mutate({ workspaces: uniqueWorkspaces })
    }
  }

  const renderForm = (): ReactNode => {
    return (
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(handleSubmit)}>
          <RowColLayout rowProps={{ classNames: 'mt-2' }}>
            <ControlledTextField
              name="workspacesList"
              translator={t}
              required={true}
              textFieldProps={{
                label: t('WORKSPACE_GROUPS.FORM.WORKSPACES_LIST'),
                placeholder: `workspace_1\nworkspace_2\nworkspace_3`,
                autoComplete: 'off',
                multiline: true,
                resizable: false,
                rows: 15,
              }}
            />
            <div>
              <span
                style={{
                  color: countWorkspaces > MAX_WORKSPACES ? 'red' : 'inherit',
                }}
              >{`${countWorkspaces}/${MAX_WORKSPACES}`}</span>
              {countWorkspaces > MAX_WORKSPACES && (
                <span style={{ color: 'red', marginLeft: '10px' }}>{t('WORKSPACE_GROUPS.FORM.MAX_WORKSPACES')}</span>
              )}
            </div>
          </RowColLayout>

          {renderFormActionButtons()}
        </form>
      </FormProvider>
    )
  }

  const renderFormActionButtons = (): ReactNode => {
    return (
      <FormActionsContainer>
        <FormActions
          submitProps={{
            text: t('general:BUTTON.IMPORT'),
            iconProps: { iconName: 'Add' },
          }}
        />
      </FormActionsContainer>
    )
  }

  const copyToClipboard = (event: MouseEvent, panel: string) => {
    event.stopPropagation()
    setCopied(true)
    let value = ''
    switch (panel) {
      case 'error':
        if (resultImport && resultImport.errorMatch) {
          value = resultImport?.errorMatch.join('\n')
        }
        break
      case 'success':
        if (resultImport && resultImport.successMatch) {
          value = resultImport?.successMatch.join('\n')
        }
        break
      case 'exists':
        if (resultImport && resultImport.existsMatch) {
          value = resultImport?.existsMatch.join('\n')
        }
        break
    }
    navigator.clipboard.writeText(value)
    setTimeout(() => {
      setCopied(false)
    }, 3000)
  }

  const _handleFilterChange = (item?: PivotItem) => {
    setTabQueryParameter(item?.props.itemKey)
  }

  const renderResultPanel = (): ReactNode => {
    return (
      <>
        {importWorkspaces.isSuccess && resultImport && (
          <>
            <div className="d-flex h-100" style={{ flexDirection: 'column', justifyContent: 'top' }}>
              <div style={{ marginTop: '30px', marginBottom: '30px' }}>
                <SuccessStatus message={t('WORKSPACE_GROUPS.FORM.IMPORT_SUCCESS')} />
              </div>
              <div>
                <>
                  <Pivot selectedKey={tabQueryParameter} onLinkClick={_handleFilterChange} headersOnly={true}>
                    <PivotItem
                      itemKey="added"
                      headerText={`${t('WORKSPACE_GROUPS.FORM.SUCCESS_MATCH')} (${resultImport.successMatch.length})`}
                    />
                    <PivotItem
                      itemKey="error"
                      headerText={`${t('WORKSPACE_GROUPS.FORM.ERROR_MATCH')} (${resultImport.errorMatch.length})`}
                    />
                    <PivotItem
                      itemKey="exist"
                      headerText={`${t('WORKSPACE_GROUPS.FORM.EXISTS_MATCH')} (${resultImport.existsMatch.length})`}
                    />
                  </Pivot>
                </>
                <div style={{ display: 'flex', flexDirection: 'column', marginTop: '20px' }}>
                  {tabQueryParameter === 'added' && (
                    <div style={{ flex: 1, marginRight: '10px', position: 'relative' }}>
                      <div
                        style={{
                          padding: '10px',
                          borderRadius: '5px',
                          maxHeight: '350px',
                          overflow: 'auto',
                          border: '1px solid',
                        }}
                      >
                        {resultImport?.successMatch && resultImport?.successMatch?.length > 0 && (
                          <>
                            <div className="d-flex" style={{ position: 'absolute', top: 10, right: 25 }}>
                              <TooltipHost
                                content={copied ? t('general:COPIED') : ''}
                                delay={TooltipDelay.zero}
                                closeDelay={500}
                                directionalHint={DirectionalHint.topCenter}
                              >
                                <Icon
                                  style={{ marginLeft: 10, fontSize: 18 }}
                                  className="cursor-pointer"
                                  iconName="Copy"
                                  onClick={(e: any) => copyToClipboard(e, 'success')}
                                />
                              </TooltipHost>
                            </div>
                            {resultImport?.successMatch.map((success, index) => (
                              <div key={index} style={{ marginBottom: '5px' }}>
                                {success}
                              </div>
                            ))}
                          </>
                        )}

                        {resultImport?.successMatch && resultImport?.successMatch?.length === 0 && (
                          <>
                            <div>{t('WORKSPACE_GROUPS.FORM.NONE')}</div>
                          </>
                        )}
                      </div>
                    </div>
                  )}

                  {tabQueryParameter === 'error' && (
                    <div style={{ flex: 1, marginRight: '10px', position: 'relative' }}>
                      <div
                        style={{
                          padding: '10px',
                          borderRadius: '5px',
                          maxHeight: '350px',
                          overflow: 'auto',
                          border: '1px solid',
                        }}
                      >
                        {resultImport?.errorMatch && resultImport?.errorMatch?.length > 0 && (
                          <>
                            <div className="d-flex" style={{ position: 'absolute', top: 10, right: 25 }}>
                              <TooltipHost
                                content={copied ? t('general:COPIED') : ''}
                                delay={TooltipDelay.zero}
                                closeDelay={500}
                                directionalHint={DirectionalHint.topCenter}
                              >
                                <Icon
                                  style={{ marginLeft: 10, fontSize: 18 }}
                                  className="cursor-pointer"
                                  iconName="Copy"
                                  onClick={(e: any) => copyToClipboard(e, 'error')}
                                />
                              </TooltipHost>
                            </div>
                            {resultImport?.errorMatch.map((error, index) => (
                              <div key={index} style={{ marginBottom: '5px' }}>
                                {error}
                              </div>
                            ))}
                          </>
                        )}

                        {resultImport?.errorMatch && resultImport?.errorMatch?.length === 0 && (
                          <>
                            <div>{t('WORKSPACE_GROUPS.FORM.NONE')}</div>
                          </>
                        )}
                      </div>
                    </div>
                  )}

                  {tabQueryParameter === 'exist' && (
                    <div style={{ flex: 1, marginRight: '10px', position: 'relative' }}>
                      <div
                        style={{
                          padding: '10px',
                          borderRadius: '5px',
                          maxHeight: '350px',
                          overflow: 'auto',
                          border: '1px solid',
                        }}
                      >
                        {resultImport?.existsMatch && resultImport?.existsMatch?.length > 0 && (
                          <>
                            <div className="d-flex" style={{ position: 'absolute', top: 10, right: 25 }}>
                              <TooltipHost
                                content={copied ? t('general:COPIED') : ''}
                                delay={TooltipDelay.zero}
                                closeDelay={500}
                                directionalHint={DirectionalHint.topCenter}
                              >
                                <Icon
                                  style={{ marginLeft: 10, fontSize: 18 }}
                                  className="cursor-pointer"
                                  iconName="Copy"
                                  onClick={(e: any) => copyToClipboard(e, 'error')}
                                />
                              </TooltipHost>
                            </div>
                            {resultImport?.existsMatch.map((exist, index) => (
                              <div key={index} style={{ marginBottom: '5px' }}>
                                {exist}
                              </div>
                            ))}
                          </>
                        )}

                        {resultImport?.existsMatch && resultImport?.existsMatch?.length === 0 && (
                          <>
                            <div>{t('WORKSPACE_GROUPS.FORM.NONE')}</div>
                          </>
                        )}
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </>
        )}

        {importWorkspaces.isError && <ErrorStatus message={t('WORKSPACE_GROUPS.FORM.IMPORT_ERROR')} />}
      </>
    )
  }

  return (
    <>
      {!isLoading && !isSuccess && !isError && renderForm()}

      {isLoading && <LoadingSpinner></LoadingSpinner>}

      {(isSuccess || isError) && renderResultPanel()}
    </>
  )
}

export default ImportWorkspacesForm
