import PageBody from '../../../components/PageBody/PageBody.component'
import { FC, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { MOBILE_QUERY, useMediaQuery } from '@flexxibleit/flexxible-ui'
import { SummaryItem, usePatchManagementRuleEngine } from './hooks/usePatchManagementRuleEngine'
import { SummarySection } from './components/SummarySection'
import { Shimmer } from '@fluentui/react'
import { SummarySectionType } from './hooks/types'
import { useOrganizationSelection } from 'app/globals/useOrganizationSelection'
import { useExtendedPatchManagementFeature } from '../../../permissions/ExtendedPatchManagement.featureFlag'
import { withFeatureRender } from '../../../permissions/withFeatureRender'
import { FEATURE_NAMES } from '../../../permissions/FeatureName.enum'

const PatchManagementSummary: FC = () => {
  const { t } = useTranslation('patch_management')
  const [isMobile] = useMediaQuery(MOBILE_QUERY)
  const { summaryItems, isLoading } = usePatchManagementRuleEngine()

  const { organizationId } = useParams()
  const navigate = useNavigate()
  const { selectedOrganization } = useOrganizationSelection()
  const baseUrl = location.pathname.split('/')[1]

  useEffect(() => {
    if (!selectedOrganization) {
      return
    }
    if (selectedOrganization._id !== organizationId) {
      navigate(`/${baseUrl}/${selectedOrganization._id}/patch-management/summary/`)
    }
  }, [selectedOrganization])

  const renderSummaries = (): JSX.Element[] => {
    const renderElements: JSX.Element[] = Object.keys(SummarySectionType).map((section: string) => {
      return (
        <SummarySection
          key={section}
          title={section}
          summaryHeaderItems={summaryItems.filter((item: SummaryItem) => item.section === section)}
          metricCards={summaryItems
            .filter((item: SummaryItem) => item.section === section)
            .map((item: SummaryItem) => item.card)}
        />
      )
    })

    if (useExtendedPatchManagementFeature()) {
      return renderElements
    } else {
      const extendedSummarySectionTypes = [
        SummarySectionType.Security.toString(),
        SummarySectionType.Patches.toString(),
      ]
      return renderElements.filter(
        (renderElement) => !extendedSummarySectionTypes.includes(renderElement.key as string)
      )
    }
  }

  const renderShimmer = (): JSX.Element[] => {
    return Array.from({ length: 4 }).map((_, index) => (
      <div
        key={index}
        style={{
          display: 'flex',
          flexDirection: 'column',
          gap: 20,
        }}
      >
        <Shimmer width={'140px'} />
        <Shimmer
          styles={{
            root: {
              height: '50px',
            },
            shimmerWrapper: {
              height: '100%',
            },
          }}
        />
        <Shimmer
          styles={{
            root: {
              height: '50px',
            },
            shimmerWrapper: {
              height: '100%',
            },
          }}
        />
        <div
          style={{
            display: 'flex',
            gap: 20,
          }}
        >
          <Shimmer
            styles={{
              root: {
                width: '200px',
                height: '150px',
              },
              shimmerWrapper: {
                width: '100%',
                height: '100%',
              },
            }}
          />
          <Shimmer
            styles={{
              root: {
                width: '200px',
                height: '150px',
              },
              shimmerWrapper: {
                width: '100%',
                height: '100%',
              },
            }}
          />
        </div>
      </div>
    ))
  }

  return (
    <PageBody title={t('DASHBOARD.TITLE')} isLoading={false}>
      <div
        style={{
          display: 'grid',
          gridTemplateColumns: isMobile ? '1fr' : '1fr 1fr',
          gap: '20px',
          fontSize: '12px',
        }}
      >
        {isLoading ? renderShimmer() : renderSummaries()}
      </div>
    </PageBody>
  )
}

export const PatchManagementSummaryPage = withFeatureRender(PatchManagementSummary, FEATURE_NAMES.PATCH_MANAGEMENT_READ)
