import { Stack } from '@fluentui/react'
import { MicroservicesCard } from './MicroservicesCard'
import { useTranslation } from 'react-i18next'
import EmptyStatus from '../../../components/status/empty-status'
import { MarketplaceMicroserviceView } from '../../../hooks/microservices/useGetMarketplaceMicroservices'
import { useNavigate } from 'react-router-dom'
import i18next from 'i18next'
import { getTranslation } from 'app/models/microservices'

export interface MicroservicesBlockModeProps {
  microservices: MarketplaceMicroserviceView[]
}

export const MicroservicesBlocks = (props: MicroservicesBlockModeProps) => {
  const { t } = useTranslation('microservices')
  const navigate = useNavigate()

  const renderMicroservicesBlocks = () => {
    if (props.microservices.length === 0) {
      return (
        <div
          style={{
            margin: '100px',
          }}
        >
          <EmptyStatus message={t('MARKETPLACE.EMPTY_STATUS_MESSAGE')} />
        </div>
      )
    }

    const getNameInPreferredLanguage = (microservice: MarketplaceMicroserviceView) => {
      const name = getTranslation(microservice.name, i18next.language)

      return name.text
    }

    return (
      <Stack horizontal wrap horizontalAlign="center" tokens={{ childrenGap: 40 }}>
        {props.microservices.map((microservice) => (
          <Stack.Item key={getNameInPreferredLanguage(microservice)}>
            <MicroservicesCard
              id={microservice.id}
              name={microservice.name}
              description={microservice.description}
              category={microservice.category}
              iconName={microservice.iconName}
              iconBackground={microservice.iconBackground}
              active={microservice.active}
              onClick={(id: string) => {
                navigate(`/microservices/${id}`, {
                  state: { fromMarketplace: true },
                })
              }}
            />
          </Stack.Item>
        ))}
      </Stack>
    )
  }

  return renderMicroservicesBlocks()
}
