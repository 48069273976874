import { TFunction } from "i18next";

export enum MicroserviceTargetGroup {
  L3 = 'L3',
  L2 = 'L2',
  L1 = 'L1',
}

export const microserviceTargetGroupOptions = (t: TFunction) => {
  return Object.values(MicroserviceTargetGroup).map((targetGroup) => ({
    key: targetGroup,
    text: targetGroupLabel(t)[targetGroup],
  }))
}

export const targetGroupLabel = (t: TFunction): { [key in MicroserviceTargetGroup]: string } => ({
  [MicroserviceTargetGroup.L3]: 'L3',
  [MicroserviceTargetGroup.L2]: 'L2',
  [MicroserviceTargetGroup.L1]: 'L1',
})