import { EmptyStatus } from '@flexxibleit/flexxible-ui'
import { TFunction } from 'react-i18next'
import { useFeatureRender } from '../../../permissions/useFeatureRender'
import { FEATURE_NAMES } from '../../../permissions/FeatureName.enum'

type EmptyOrgProductsProps = {
  t: TFunction
  onAddProductClicked: () => void
}

export const EmptyOrgProducts = ({ t, onAddProductClicked }: EmptyOrgProductsProps) => {
  const { checkFeature } = useFeatureRender()

  return (
    <div style={{ marginTop: 100 }}>
      <EmptyStatus
        message={t('PRODUCTS.EMPTY_MESSAGE')}
        {...(checkFeature(FEATURE_NAMES.ENVIRONMENT_CREATE) && {
          buttonProps: {
            text: t('general:BUTTON.CREATE'),
            iconProps: { iconName: 'Add' },
            onClick: onAddProductClicked,
          },
        })}
      />
    </div>
  )
}
