import { isLatLngLiteral } from "@googlemaps/typescript-guards";
import { Easing, Tween, update } from "@tweenjs/tween.js";
import { createCustomEqual } from "fast-equals";
import { Children, cloneElement, isValidElement, useEffect, useRef, useState } from "react";

interface MapProps extends google.maps.MapOptions {
  children?: React.ReactNode;
  /** Initial zoom for animation */
  initialZoom?: number;
  /** Animation duration in ms */
  animationDuration?: number;
  /** Map ref */
  onGoogleApiLoaded?: (map: google.maps.Map) => void;
  onClick?: (e: google.maps.MapMouseEvent) => void;
}

export const GoogleMap = ({ animationDuration, initialZoom, onGoogleApiLoaded, onClick, children, ...options }: MapProps) => {
  const ref = useRef<HTMLDivElement>(null);
  const [map, setMap] = useState<google.maps.Map>();

  useEffect(() => {
    if (ref.current && !map) {
      const newMap = new window.google.maps.Map(ref.current, {})
      setMap(newMap);
      onGoogleApiLoaded?.(newMap);
    }
  }, [ref, map]);

  useDeepCompareEffectForMaps(() => {
    if (map) {
      map.setOptions(options);
    }
  }, [map, options]);

  useEffect(() => {
    if (!animationDuration) {
      return;
    }

    const initialCameraOptions: google.maps.CameraOptions = {
      tilt: 0,
      heading: 0,
      zoom: initialZoom ?? 12.5,
      center: options.center ?? { lat: 0, lng: 0 },
    };
    const cameraOptions: google.maps.CameraOptions = {
      zoom: options.zoom ?? 13
    };

    new Tween(initialCameraOptions)
      .to(cameraOptions, animationDuration)
      .easing(Easing.Quadratic.In)
      .onUpdate(() => {
        map?.moveCamera(initialCameraOptions);
      })
      .start();

    function animate(time: number) {
      requestAnimationFrame(animate);
      update(time);
    }

    requestAnimationFrame(animate);
  }, [map])

  useEffect(() => {
    if (map) {
      google.maps.event.clearListeners(map, "click")

      if (onClick) {
        map.addListener("click", onClick);
      }
    }
  }, [map, onClick]);

  return (
    <div style={{ flexGrow: "1", height: "100%" }} ref={ref} >
      {Children.map(children, (child) => {
        if (isValidElement(child)) {
          // set the map prop on the child component
          // @ts-ignore
          return cloneElement(child, { map });
        }
      })}
    </div>
  )
}



function useDeepCompareEffectForMaps(
  callback: React.EffectCallback,
  dependencies: any[]
) {
  useEffect(callback, dependencies.map(useDeepCompareMemoize));
}

function useDeepCompareMemoize(value: any) {
  const ref = useRef();

  if (!deepCompareEqualsForMaps(value, ref.current)) {
    ref.current = value;
  }

  return ref.current;
}

const deepCompareEqualsForMaps = createCustomEqual(
  (deepEqual) => (a: any, b: any) => {
    if (
      isLatLngLiteral(a) ||
      a instanceof google.maps.LatLng ||
      isLatLngLiteral(b) ||
      b instanceof google.maps.LatLng
    ) {
      return new google.maps.LatLng(a).equals(new google.maps.LatLng(b));
    }

    // TODO extend to other types

    // use fast-equals for other objects
    return deepEqual(a, b);
  }
);