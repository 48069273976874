import { DateTime, Interval } from 'luxon'
import { MockChartConfiguration } from './MockChartConfiguration';
import { chartColors } from '../ChartColors.constants';
import { Report, Usage } from '../../../query-client/types'
import thousandParser from '../../../services/thousand-parser';
import { DateFormat, dateFormatter } from 'app/services/date-formatter';
import { TFunction } from 'react-i18next';

type ConsumptionChartSeriesDataElement = [usage: Number, day?: DateTime, group?: string]
type ConsumptionChartSeriesData = Array<ConsumptionChartSeriesDataElement>

interface Props {
  reports: Report[]
  startDate: DateTime
  endDate: DateTime
  tooltipText: string
  language: string
  baseline?: number
  t: TFunction
}

export const useConsumptionChart = ({reports, startDate, endDate, tooltipText, language, baseline, t}: Props) => {
  const interval = Interval.fromDateTimes(startDate, endDate)
  const seriesNames: Set<string> = new Set()

  reports.forEach((report: Report) => {
    report.usage.forEach((usage: Usage) => {
      seriesNames.add(usage.group)
    })
  })

  function getSeriesData(seriesName: string): ConsumptionChartSeriesData {
    const reportMap: { [date: string]: Report } = {};
    reports.forEach((report: Report) => {
      const reportDate = dateFormatter(t, report.date, DateFormat.MonthYear);
      reportMap[reportDate] = report;
    });
  
    return Array.from(days(interval))
      .map((day: DateTime): ConsumptionChartSeriesDataElement => {
        const formattedDate = dateFormatter(t, day.toJSDate(), DateFormat.MonthYear);
        const report: Report | undefined = reportMap[formattedDate];
  
        return [report ? report.usage.find((usage: Usage) => usage.group === seriesName)?.quantity || 0 : 0, day, seriesName];
      });
  }

  const series = Array.from(seriesNames).map((seriesName: string, i: number) => ({
    type: 'column',
    name: seriesName,
    color: chartColors[i],
    data: getSeriesData(seriesName),
  }))

  const baselineSerie = {
    type: 'spline',
    name: 'Baseline',
    color: 'red',
    data: Array.from(days(interval)).map((date: DateTime) => {
      return [baseline || 0]
    }),
    marker: {
      enabled: false
    }
  }

  return {
    ...MockChartConfiguration,
    xAxis: {
      categories: Array.from(days(interval)).map(date => dateFormatter(t, date.toJSDate(), DateFormat.MonthYear)),
    },
    yAxis: {
      title: undefined,
      allowDecimals: false,
      min: 0,
      labels: {
        formatter: function(): any {
          const yAxis: any = this
          return thousandParser(yAxis.value, language)
        }
      },
    },
    series: [...series, baselineSerie],
    plotOptions: {
      series: {
        keys: ['y', 'custom.date', 'custom.name']
      },
      column: {
        stacking: 'normal',
        groupPadding: 0,
      },
    },
    tooltip: {
      useHTML: true,
      backgroundColor: '#032D43',
      borderWidth: 0,
      shadow: false,
      formatter: function(): any {
        const currentPoint: any = this
        if(currentPoint.point.options.custom) {
          const date = new Date(Number(currentPoint.point.options.custom.date))
          return (
            '<div style="display: flex, flex-direction: column">' +
              '<div style="color: white">' + dateFormatter(t, date, DateFormat.Medium) + '</div>' +
              '<div style="color: white">' + currentPoint.point.options.custom.name + '</div>' +
              '<div style="color: white">' + tooltipText + '<b> ' + thousandParser(currentPoint.y, language) + '</b> </div>' +
            '</div>'
          )
        } else {
          return (
            '<div style="display: flex, flex-direction: column">' +
              '<div style="color: white"> Baseline <b> ' + thousandParser(currentPoint.y, language) + '</b> </div>' +
            '</div>'
          )
        }          
      }
    },
    legend: {
      enabled: false
    },
  }
}


function* days(interval: Interval) {
  let cursor = interval.start?.startOf('day')
  while (cursor < interval.end) {
    yield cursor
    cursor = cursor.plus({ days: 1 })
  }
}
