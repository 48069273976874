import { DateTime, Interval } from 'luxon'
import { Report } from '../../../query-client/types';
import { MockChartConfiguration } from './MockChartConfiguration';
import thousandParser from '../../../services/thousand-parser';
import { DateFormat, dateFormatter } from 'app/services/date-formatter';
import { TFunction } from 'react-i18next';

interface Props {
  reports: Report[]
  startDate: DateTime
  endDate: DateTime
  tooltipText: string
  language: string
  baseline?: number
  t: TFunction
}

export const useAccumulatedChart = ({reports, startDate, endDate, tooltipText, language, baseline, t}: Props) => {
  const interval = Interval.fromDateTimes(startDate, endDate)

  const series = [{
    type: 'column',
    data: Array.from(days(interval)).map((date: DateTime) => {
      const formattedDate = dateFormatter(t, date.toJSDate(), DateFormat.MonthYear)

      const usage = reports.find((report: any) => {
        const reportDate = dateFormatter(t, report.date, DateFormat.MonthYear)
        return reportDate === formattedDate
      })
      return [usage ? usage.usage.reduce((accu, current) => accu + current.quantity, 0) : 0, date]
    }),
  }]

  const baselineSerie = {
    type: 'spline',
    name: 'Baseline',
    color: 'red',
    data: Array.from(days(interval)).map((date: DateTime) => {
      return [baseline || 0]
    }),
    marker: {
      enabled: false
    }
  }

  return {
    ...MockChartConfiguration,
    xAxis: {
      categories: Array.from(days(interval)).map(date => dateFormatter(t, date.toJSDate(), DateFormat.MonthYear)),
    },
    yAxis: {
      title: undefined,
      allowDecimals: false,
      min: 0,
      labels: {
        formatter: function(): any {
          const yAxis: any = this
          return thousandParser(yAxis.value, language)
        }
      },
    },
    legend: {
      enabled: false
    },
    series: [...series, baselineSerie],
    plotOptions: {
      series: {
        keys: ['y', 'custom.date']
      },
      column: {
        stacking: 'normal',
        groupPadding: 0,
      },
    },
    tooltip: {
      useHTML: true,
      backgroundColor: '#032D43',
      borderWidth: 0,
      shadow: false,
      formatter: function(): any {
        const currentPoint: any = this
        if(currentPoint.point.options.custom) {
          const date = new Date(Number(currentPoint.point.options.custom.date))

          return (
            '<div style="display: flex, flex-direction: column">' +
              '<div style="color: white">' + dateFormatter(t, date, DateFormat.Medium) + '</div>' +
              '<div style="color: white">' + tooltipText + '<b> ' + thousandParser(currentPoint.y, language) + '</b> </div>' +
            '</div>'
          )
        } else {
          return (
            '<div style="display: flex, flex-direction: column">' +
              '<div style="color: white"> Baseline <b> ' + thousandParser(currentPoint.y, language) + '</b> </div>' +
            '</div>'
          )
        }
      }
    },
  }
  
}
function* days(interval: Interval) {
  let cursor = interval.start?.startOf('day')
  while (cursor < interval.end) {
    yield cursor
    cursor = cursor.plus({ days: 1 })
  }
}
