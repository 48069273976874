import { CreateButton, SearchBox, ViewDetailsActionButton } from '@flexxibleit/flexxible-ui'
import {
  ColumnActionsMode,
  DetailsListLayoutMode,
  IColumn,
  Link,
  Pivot,
  PivotItem,
  SelectionMode,
  Stack,
} from '@fluentui/react'
import { Badge } from 'app/components/Badge/Badge'
import Table from 'app/components/Table/Table.component'
import StatusBadge, { BadgeMode } from 'app/components/status-badge/status-badge.component'
import { MOBILE_QUERY } from 'app/config/media-query'
import useOrganizationsPaginated, { OrganizationView } from 'app/hooks/organization/useOrganizationsPaginated'
import useMediaQuery from 'app/hooks/utils/useMediaQuery'
import { debounce } from 'lodash'
import { ChangeEvent, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { StringParam, useQueryParam } from 'use-query-params'
import { useOrganizationSelection } from '../../../globals/useOrganizationSelection'
import useCreateForm from '../../../hooks/useCreateForm'
import { FEATURE_NAMES } from '../../../permissions/FeatureName.enum'
import { withFeatureRender } from '../../../permissions/withFeatureRender'
import { PaginationArgs } from '../../../query-client/types'
import OrganizationForm from './OrganizationForm'
import { FeatureRender } from '../../../permissions/FeatureRender'

const OrganizationList = () => {
  const navigate = useNavigate()
  const { setSelectedOrganizationId } = useOrganizationSelection()
  const [isMobile] = useMediaQuery(MOBILE_QUERY)
  const { t, i18n } = useTranslation('organizations')
  const [tabQueryParameter, setTabQueryParameter] = useQueryParam('filter', StringParam)
  const [selectedTab, setSelectedTab] = useState('active')
  const [textFilter, setTextFilter] = useState<string>()
  const [activeFilter, setActiveFilter] = useState<boolean | null>(true)
  const [paginationState, setPaginationState] = useState<PaginationArgs>(() => ({
    page: 1,
    perPage: 10,
  }))
  const {
    data: organizationsPagination,
    isLoading,
    isFetching,
  } = useOrganizationsPaginated(textFilter || '', activeFilter, paginationState)

  const availableOrganizationsPage: OrganizationView[] = useMemo(
    () => organizationsPagination?.results || [],
    [organizationsPagination]
  )

  const [opacity, setOpacity] = useState(1)

  useEffect(() => {
    if (tabQueryParameter === undefined || tabQueryParameter === null) {
      setTabQueryParameter('active')
    } else {
      setSelectedTab(tabQueryParameter)
    }
  }, [tabQueryParameter])

  useEffect(() => {
    // TODO: workaround for webkit (Safari).
    if (availableOrganizationsPage.length == 0) {
      const title = document.getElementById('titlePage')

      if (title) {
        title.style.opacity = '0.9999'
      }

      setOpacity(0.9999)
    }
  }, [availableOrganizationsPage])

  const onViewDetailClicked = (organizationId: string) => {
    setSelectedOrganizationId(organizationId)
    navigate(`/organizations/${organizationId}`, { replace: true })
  }

  const columns: IColumn[] = [
    {
      key: 'name',
      name: t('TABLE.NAME'),
      fieldName: 'name',
      minWidth: 100,
      flexGrow: 1,
      isRowHeader: true,
      isPadded: true,
      columnActionsMode: ColumnActionsMode.disabled,
      onRender(item: OrganizationView) {
        return (
          <Link onClick={() => onViewDetailClicked(item._id)}>
            {item.isTrial && <Badge>{t('organizations:TRIAL')}</Badge>}
            <span>{item.name}</span>
          </Link>
        )
      },
    },
    {
      key: 'products',
      name: t('TABLE.PRODUCTS'),
      fieldName: 'productConfigs',
      minWidth: 100,
      isRowHeader: true,
      isCollapsible: true,
      onRender: (item: OrganizationView) => {
        return (
          <>
            <span>{item.productConfigs?.length ?? 0} </span>
            <span>{t('TABLE.PRODUCTS')}</span>
          </>
        )
      },
      isPadded: true,
      columnActionsMode: ColumnActionsMode.disabled,
    },
    {
      key: 'members',
      name: t('TABLE.MEMBERS'),
      fieldName: 'users',
      minWidth: 100,
      isRowHeader: true,
      isCollapsible: true,
      onRender: (item: OrganizationView) => {
        return (
          <>
            <span>{item.users?.length ?? 0} </span>
            <span>{t('TABLE.USERS')}</span>
          </>
        )
      },
      isPadded: true,
      columnActionsMode: ColumnActionsMode.disabled,
    },
    {
      key: 'status',
      name: t('TABLE.STATUS'),
      fieldName: 'active',
      minWidth: 100,
      isRowHeader: true,
      isCollapsible: true,
      onRender: (item: OrganizationView) => {
        return <StatusBadge active={item.active} mode={BadgeMode.circle} size={8} />
      },
      isPadded: true,
      columnActionsMode: ColumnActionsMode.disabled,
    },
    {
      key: 'actions',
      name: t('TABLE.ACTION'),
      minWidth: isMobile ? 50 : 100,
      onRender: (item: OrganizationView) => {
        return (
          <ViewDetailsActionButton
            locale={i18n.language}
            isMobile={isMobile}
            onClick={() => onViewDetailClicked(item._id)}
          />
        )
      },
      isPadded: true,
      columnActionsMode: ColumnActionsMode.disabled,
    },
  ]

  const openCreateBusinessForm = useCreateForm(
    {
      title: t('FORM.ADD_TITLE'),
      isFooterAtBottom: true,
    },
    <OrganizationForm />
  )

  const _onFilter = debounce((ev: ChangeEvent<HTMLInputElement> | undefined, text?: string): void => {
    setTextFilter(text)
  }, 300)

  const _handleFilterChange = (item?: PivotItem) => {
    setTabQueryParameter(item?.props.itemKey)
    if (item?.props.itemKey === 'all') {
      setActiveFilter(null)
    } else if (item?.props.itemKey === 'active') {
      setActiveFilter(true)
    } else if (item?.props.itemKey === 'inactive') {
      setActiveFilter(false)
    }
  }

  const handleOnPaginationChange = (newPaginationState: PaginationArgs) => {
    setPaginationState(newPaginationState)
  }

  return (
    <div style={{ opacity: opacity }}>
      <Stack horizontal={!isMobile} horizontalAlign="space-between" className="mb-sm-2">
        <Stack.Item grow={1} style={{ maxWidth: 400, minWidth: 196 }}>
          <SearchBox placeholder={t('SEARCH_PLACEHOLDER')} id="searchProduct" onChange={_onFilter} />
        </Stack.Item>

        <Stack.Item>
          <div
            className={`d-flex d-flexRow d-flexAlignItemsCenter ${isMobile ? 'd-flexJustifyBetween' : ''} `}
            style={isMobile ? { marginTop: 15 } : {}}
          >
            <Pivot selectedKey={selectedTab} onLinkClick={_handleFilterChange} headersOnly={true}>
              <PivotItem itemKey="all" headerText={t('general:ALL')} />
              <PivotItem itemKey="active" headerText={t('general:STATUS.ACTIVE')} />
              <PivotItem itemKey="inactive" headerText={t('general:STATUS.INACTIVE')} />
            </Pivot>
            <FeatureRender feature={FEATURE_NAMES.ORGANIZATION_CREATE}>
              <CreateButton locale={i18n.language} onClick={openCreateBusinessForm} />
            </FeatureRender>
          </div>
        </Stack.Item>
      </Stack>

      <Table
        tableId="organizations-list"
        isLoading={isLoading}
        isEmpty={availableOrganizationsPage?.length === 0}
        emptyMessage={t('general:EMPTY_FILTER')}
        tableProps={{
          columns,
          items: availableOrganizationsPage,
          compact: false,
          selectionMode: SelectionMode.none,
          layoutMode: DetailsListLayoutMode.justified,
          isHeaderVisible: true,
        }}
        paginationProps={
          organizationsPagination
            ? {
                pagination: paginationState,
                onPaginationChange: handleOnPaginationChange,
                paginatedData: organizationsPagination,
                isFetching: isFetching,
              }
            : undefined
        }
      />
    </div>
  )
}

export const OrganizationListFeature = withFeatureRender(OrganizationList, FEATURE_NAMES.ALL_ORGANIZATION_READ)
