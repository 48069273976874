import { useMutation, useQueryClient } from '@tanstack/react-query'
import graphClient from 'app/query-client/graphClient'
import { gql } from 'graphql-request'

interface UpdateReportingGroupRequest {
  name: string
  productConfigId?: string
  patchPolicyTargetId?: string | null
  fishingPattern?: string | null
}

interface Response {
  updateReportingGroup: {
    _id: string
  }
}

const mutation = gql`
  mutation Request($id: ID!, $input: UpdateReportingGroupGraphqlRequest!) {
    updateReportingGroup(id: $id, input: $input) {
      _id
    }
  }
`

const updateReportingGroup = async (id: string, input: UpdateReportingGroupRequest) => {
  const response: Response = await graphClient.request(mutation, {
    id,
    input,
  })
  return response.updateReportingGroup._id
}

export const useUpdateReportingGroup = () => {
  const queryClient = useQueryClient()

  return useMutation(
    ['updateReportingGroup'],
    ({ id, input }: { id: string; input: UpdateReportingGroupRequest }) => updateReportingGroup(id, input),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['getReportingGroupById'])
      },
    }
  )
}
