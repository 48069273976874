import { useMutation, useQueryClient } from '@tanstack/react-query'
import graphClient from 'app/query-client/graphClient'
import { gql } from 'graphql-request'

interface UpdatePolicyReportingGroupRequest {
  name: string
}

interface Response {
  updatePolicyReportingGroup: {
    _id: string
  }
}

const mutation = gql`
  mutation Request($id: ID!, $input: UpdatePolicyReportingGroupGraphqlRequest!) {
    updatePolicyReportingGroup(id: $id, input: $input) {
      _id
    }
  }
`

const updatePolicyReportingGroup = async (id: string, policyId: string, input: UpdatePolicyReportingGroupRequest) => {
  const response: Response = await graphClient.request(mutation, {
    id,
    input,
  })
  return {
    reportingGroupId: response.updatePolicyReportingGroup._id,
    policyId: policyId,
  }
}

export const useUpdatePolicyReportingGroup = () => {
  const queryClient = useQueryClient()

  return useMutation(
    ['updatePolicyReportingGroup'],
    ({ id, input, policyId }: { id: string; policyId: string; input: UpdatePolicyReportingGroupRequest }) =>
      updatePolicyReportingGroup(id, policyId, input),
    {
      onSuccess: ({ policyId }) => {
        queryClient.invalidateQueries(['getTenantPolicy', policyId])
      },
    }
  )
}
