import { CreateButton, SearchBox, ViewDetailsActionButton } from '@flexxibleit/flexxible-ui'
import { useTranslation } from 'react-i18next'
import Layers from '../../../../app/assets/img/layers.svg'
import useCreateForm from '../../../hooks/useCreateForm'
import { ModuleForm } from './module-form/module.form'
import { Module } from '../../../query-client/types'
import { useEffect, useState } from 'react'
import {
  ColumnActionsMode,
  DetailsListLayoutMode,
  DetailsRow,
  IColumn,
  Icon,
  Pivot,
  PivotItem,
  SelectionMode,
  Stack,
} from '@fluentui/react'
import EmptyStatus from '../../../components/status/empty-status'
import Table from 'app/components/Table/Table.component'
import useMediaQuery from 'app/hooks/utils/useMediaQuery'
import { MOBILE_QUERY } from 'app/config/media-query'
import NameCell from '../components/NameCell/NameCell.component'
import { StringParam, useQueryParam } from 'use-query-params'
import { useReorderModules } from '../../../hooks/modules/useReorderModules'
import { getModulesOrderInputFromModuleArray, ModulesOrderInput } from './ModulesOrderInput'
import useGetOrganizationModules from '../../../hooks/modules/useGetOrganizationModules'
import { useFeatureRender } from 'app/permissions/useFeatureRender'
import { FEATURE_NAMES } from 'app/permissions/FeatureName.enum'
import { withFeatureRender } from 'app/permissions/withFeatureRender'
import { CellWithFeatureLink } from 'app/components/Table/CellWithFeatureLink'
import { FeatureRender } from '../../../permissions/FeatureRender'

interface Props {
  organizationId: string
}

export const ModulesFeature = ({ organizationId }: Props) => {
  const { t, i18n } = useTranslation('organization_details')
  const { data } = useGetOrganizationModules(organizationId)
  const [listItemsState, setListItemsState] = useState(data)
  const { mutate: reorderModules } = useReorderModules()
  const [isMobile] = useMediaQuery(MOBILE_QUERY)
  const [tabQueryParameter, setTabQueryParameter] = useQueryParam('filter', StringParam)
  const [textFilter, setTextFilter] = useState<string>()
  const [isLoading, setLoading] = useState(true)
  const { checkFeature } = useFeatureRender()

  const applyTableFiltering = () => {
    setLoading(true)

    let filteredItems: Module[] = data

    switch (tabQueryParameter) {
      case 'visibility1':
        filteredItems = data.filter((module: Module) => module.visibility === 1)
        break
      case 'visibility2':
        filteredItems = data.filter((module: Module) => module.visibility === 2)
        break
    }

    if (textFilter) {
      filteredItems = filteredItems.filter((item: Module) => {
        return (
          item.type.toLowerCase().indexOf(textFilter) >= 0 ||
          item.name?.toLowerCase().indexOf(textFilter) >= 0 ||
          item.tag?.toLowerCase().indexOf(textFilter) >= 0
        )
      })
    }

    filteredItems.sort((a, b) => a.order! - b.order!)

    setListItemsState([...filteredItems])

    setLoading(false)
  }

  const tableActionsFeatures = [FEATURE_NAMES.MODULE_UPDATE]
  const canSeeActions = tableActionsFeatures.some((feature) => checkFeature(feature))

  const columns: IColumn[] = [
    {
      key: 'dragIcon',
      name: '',
      minWidth: 30,
      maxWidth: 30,
      onRenderHeader: () => {
        return (
          <Icon
            iconName="SwitcherStartEnd"
            style={{
              marginLeft: '3px',
            }}
          />
        )
      },
      onRender: (_: Module) => {
        return <Icon iconName="DragObject" style={{ cursor: 'grab', fontSize: '20px' }} />
      },
    },
    {
      key: 'name',
      name: t('MODULES.TABLE.NAME'),
      fieldName: 'name',
      minWidth: 100,
      flexGrow: 1,
      isRowHeader: true,
      onRender: (item: Module) => {
        return (
          <CellWithFeatureLink feature={FEATURE_NAMES.MODULE_UPDATE} callback={onEditClicked(item)}>
            <NameCell module={item} />
          </CellWithFeatureLink>
        )
      },
      isPadded: true,
      columnActionsMode: ColumnActionsMode.disabled,
    },
    {
      key: 'url',
      name: t('MODULES.TABLE.URL'),
      fieldName: 'url',
      minWidth: 250,
      isRowHeader: true,
      isCollapsible: true,
      isPadded: true,
      columnActionsMode: ColumnActionsMode.disabled,
    },
    {
      key: 'visibility',
      name: t('MODULES.TABLE.VISIBILITY'),
      fieldName: 'visibility',
      minWidth: 80,
      isRowHeader: true,
      isCollapsible: true,
      onRender: (item: Module) => {
        return <div>{item.visibility === 1 ? t('MODULES.VISIBILITY.1') : t('MODULES.VISIBILITY.2')}</div>
      },
      isPadded: true,
      columnActionsMode: ColumnActionsMode.disabled,
    },
    ...(canSeeActions
      ? [
          {
            key: 'actions',
            name: t('MODULES.TABLE.ACTION'),
            minWidth: isMobile ? 20 : 100,
            onRender: (item: Module) => {
              return withFeatureRender(
                ViewDetailsActionButton,
                FEATURE_NAMES.MODULE_UPDATE
              )({
                locale: i18n.language,
                isMobile: isMobile,
                onClick: onEditClicked(item),
              })
            },
            isPadded: true,
            columnActionsMode: ColumnActionsMode.disabled,
          },
        ]
      : []),
  ]
  const onReorder = (sourceIndex: string, destinationIndex: string) => {
    let processedModules = [...data]
    const movedElement = processedModules.splice(Number(sourceIndex), 1)
    const arrayTail = processedModules.splice(Number(destinationIndex))
    processedModules.push(...movedElement)
    processedModules.push(...arrayTail)

    const modulesOrderInput: ModulesOrderInput = getModulesOrderInputFromModuleArray(processedModules)
    reorderModules(modulesOrderInput)
  }
  const onEditClicked = (module: Module) =>
    useCreateForm(
      {
        title: t('MODULES.FORM.EDIT_TITLE'),
        isFooterAtBottom: true,
      },
      <ModuleForm module={module} organizationId={organizationId} />
    )
  const openAddModuleForm = useCreateForm(
    {
      title: t('MODULES.FORM.ADD_TITLE'),
      isFooterAtBottom: true,
    },
    <ModuleForm organizationId={organizationId} />
  )
  const _onFilter = (ev: React.ChangeEvent<HTMLInputElement> | undefined, text?: string): void => {
    setTextFilter(text)
  }
  const _handleFilterChange = (item?: PivotItem) => {
    setTabQueryParameter(item?.props.itemKey)
  }

  useEffect(() => {
    applyTableFiltering()
  }, [data, tabQueryParameter, textFilter])

  return (
    <>
      {data.length > 0 && (
        <>
          <Stack
            horizontal={!isMobile}
            horizontalAlign="space-between"
            tokens={{ padding: '20px 0 0 0' }}
            className="mb-sm-2"
          >
            <Stack.Item grow={1} style={{ maxWidth: 400, minWidth: 196 }}>
              <SearchBox placeholder={t('MODULES.SEARCH_PLACEHOLDER')} id="searchModule" onChange={_onFilter} />
            </Stack.Item>

            <Stack.Item>
              <div
                className={`d-flex d-flexRow d-flexAlignItemsCenter ${isMobile ? 'd-flexJustifyBetween' : ''} `}
                style={isMobile ? { marginTop: 15 } : {}}
              >
                {!isLoading && (
                  <>
                    <Pivot selectedKey={tabQueryParameter} onLinkClick={_handleFilterChange} headersOnly={true}>
                      <PivotItem itemKey="all" headerText={t('general:ALL')} />
                      <PivotItem itemKey="visibility1" headerText={t('MODULES.VISIBILITY.1')} />
                      <PivotItem itemKey="visibility2" headerText={t('MODULES.VISIBILITY.2')} />
                    </Pivot>
                    <FeatureRender feature={FEATURE_NAMES.MODULE_CREATE}>
                      <CreateButton locale={i18n.language} onClick={openAddModuleForm} />
                    </FeatureRender>
                  </>
                )}
              </div>
            </Stack.Item>
          </Stack>

          <Table
            tableId="modules"
            isEmpty={listItemsState.length === 0}
            emptyMessage={t('general:EMPTY_FILTER')}
            tableProps={{
              columns,
              items: listItemsState,
              compact: false,
              selectionMode: SelectionMode.none,
              layoutMode: DetailsListLayoutMode.justified,
              isHeaderVisible: true,
              onRenderRow: (props: any) => {
                return (
                  <DetailsRow
                    {...props}
                    focusZoneProps={{
                      draggable: true,
                      onDragStart: (event) => {
                        event.dataTransfer.setData('text/plain', props.item.order!.toString())
                      },
                      onDragEnter: (event) => {
                        event.dataTransfer.dropEffect = 'move'
                      },
                      onDragOver: (event) => {
                        event.preventDefault()
                        event.dataTransfer.dropEffect = 'move'
                      },
                      onDrop: (event) => {
                        onReorder(event.dataTransfer.getData('text/plain'), props.item.order!.toString())
                      },
                    }}
                  />
                )
              },
            }}
          />
        </>
      )}

      {data.length === 0 && (
        <div style={{ marginTop: 100 }}>
          <EmptyStatus
            message={t('MODULES.EMPTY_MESSAGE')}
            img={Layers}
            {...(checkFeature(FEATURE_NAMES.MODULE_CREATE) && {
              buttonProps: {
                text: t('general:BUTTON.CREATE'),
                iconProps: { iconName: 'Add' },
                onClick: openAddModuleForm,
              },
            })}
          />
        </div>
      )}
    </>
  )
}
