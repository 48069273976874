import { FormActionsContainer, LoadingSpinner } from '@flexxibleit/flexxible-ui'
import { ResponsiveMode } from "@fluentui/react"
import { zodResolver } from "@hookform/resolvers/zod"
import RowColLayout from "app/components/Layouts/RowColLayout"
import Dropdown from "app/components/forms/Dropdown"
import FormActions from "app/components/forms/FormActions"
import { MicroserviceTargetGroup, microserviceTargetGroupOptions } from "app/models/microservices/MicroserviceTargetGroup"
import { useState } from "react"
import { FormProvider, useForm } from "react-hook-form"
import { useTranslation } from "react-i18next"
import * as z from 'zod'

interface Props {
  microserviceId: string
  initialData: FormData
  onSubmit: (data: FormData) => Promise<void>
}

interface FormData {
  workspaceGroupExecution: MicroserviceTargetGroup
  individualExecution: MicroserviceTargetGroup
}

const schema: z.ZodType<FormData> = z.object({
  workspaceGroupExecution: z.enum([
    MicroserviceTargetGroup.L1,
    MicroserviceTargetGroup.L2,
    MicroserviceTargetGroup.L3
  ]),
  individualExecution: z.enum([
    MicroserviceTargetGroup.L1,
    MicroserviceTargetGroup.L2,
    MicroserviceTargetGroup.L3
  ]),
})

const MicroserviceTargetGroupForm = ({ microserviceId, initialData, onSubmit }: Props) => {
  const { t } = useTranslation('microservices')
  const [isLoading, setIsLoading] = useState(false)

  const methods = useForm<FormData>({
    resolver: zodResolver(schema),
    defaultValues: initialData
  })

  const handleSubmit = async (data: FormData) => {
    setIsLoading(true)
    try {
      await onSubmit(data)
    } finally {
      setIsLoading(false)
    }
  }

  if (isLoading) {
    return <LoadingSpinner />
  }

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(handleSubmit)}>
        <RowColLayout rowProps={{ classNames: 'mt-2' }}>
          <Dropdown
            name="workspaceGroupExecution"
            required={true}
            translator={t}
            dropdownProps={{
              responsiveMode: ResponsiveMode.large,
              options: microserviceTargetGroupOptions(t),
              label: t('DETAILS.EXECUTION.WORKSPACE_GROUP_EXECUTION_LABEL'),
              selectedKey: methods.watch("workspaceGroupExecution")
            }}
          />
        </RowColLayout>
        <RowColLayout rowProps={{ classNames: 'mt-2' }}>
          <Dropdown
            name="individualExecution"
            required={true}
            translator={t}
            dropdownProps={{
              responsiveMode: ResponsiveMode.large,
              options: microserviceTargetGroupOptions(t),
              label: t('DETAILS.EXECUTION.INDIVIDUAL_EXECUTION_LABEL'),
              selectedKey: methods.watch("individualExecution"),
            }}
          />
        </RowColLayout>
        <FormActionsContainer>
          <FormActions
            submitProps={{
              text: t('general:BUTTON.SAVE'),
              iconProps: { iconName: 'Save' },
              disabled: !methods.formState.isDirty,
            }}
          />
        </FormActionsContainer>
      </form>
    </FormProvider>
  )
}

export default MicroserviceTargetGroupForm
