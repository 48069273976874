import { SummaryDetails } from './SummaryDetails'
import { SummaryItem } from '../hooks/usePatchManagementRuleEngine'
import { SummaryType } from '../hooks/types'

interface Props {
  items: SummaryItem[]
}

export const SummaryHeader = (props: Props) => {
  const alertItems = props.items.filter((item) => item.type === SummaryType.Alert)
  const infoItems = props.items.filter((item) => item.type === SummaryType.Information)

  return (
    <div
      style={{
        borderRadius: '5px',
        gap: '20px',
      }}
    >
      {alertItems.length > 0 && <SummaryDetails type={SummaryType.Alert} items={alertItems} />}
      {infoItems.length > 0 && <SummaryDetails type={SummaryType.Information} items={infoItems} />}
    </div>
  )
}
