import PageBody from '../../../components/PageBody/PageBody.component'
import { FC, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { PatchPoliciesByReportingGroupsChart } from './components/PatchPoliciesByReportingGroupsChart'
import { PatchPolicySummary, TargetsDashboard } from '../../../query-client/types'
import { PatchPoliciesSummaryPieChart } from './components/PatchPoliciesSummaryPieChart'
import { MOBILE_QUERY, useMediaQuery } from '@flexxibleit/flexxible-ui'
import { useOrganizationSelection } from '../../../globals/useOrganizationSelection'
import useGetTargetsDashboard from '../../../hooks/patches/useGetTargetsDashboard'
import { MessageBar } from '@fluentui/react'
import { withFeatureRender } from '../../../permissions/withFeatureRender'
import { FEATURE_NAMES } from '../../../permissions/FeatureName.enum'

const PatchPolicyTargets: FC = () => {
  const baseUrl = location.pathname.split('/')[1]
  const navigate = useNavigate()

  const [isMobile] = useMediaQuery(MOBILE_QUERY)
  const { t } = useTranslation('patch_management')
  const { organizationId } = useParams()
  const { selectedOrganization } = useOrganizationSelection()

  const [targetsDashboard, setTargetsDashboard] = useState<TargetsDashboard>({
    reportingGroupsCountByPatchPolicy: [],
    patchPolicySummaries: [],
  })

  const { data: targetsDashboardData, isLoading } = useGetTargetsDashboard(selectedOrganization?._id)

  useEffect(() => {
    if (!selectedOrganization) {
      return
    }
    if (selectedOrganization._id !== organizationId) {
      navigate(`/${baseUrl}/${selectedOrganization._id}/patch-management/targets/`)
    }
  }, [selectedOrganization])

  useEffect(() => {
    if (!isLoading && targetsDashboardData) {
      setTargetsDashboard(targetsDashboardData)
    }
  }, [targetsDashboardData])

  const filterSummaries = (patchPolicies: PatchPolicySummary[]) => {
    return patchPolicies.filter((summary) => {
      return (
        summary.unknownCount !== 0 ||
        summary.notApplicableCount !== 0 ||
        summary.notInstalledCount !== 0 ||
        summary.downloadedCount !== 0 ||
        summary.noStatus !== 0 ||
        summary.installedCount !== 0 ||
        summary.installedPendingRebootCount !== 0 ||
        summary.failedCount !== 0
      )
    })
  }

  return (
    <PageBody title={t('TARGETS.TITLE')} isLoading={isLoading}>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'stretch',
          justifyContent: 'space-around',
          gap: 20,
        }}
      >
        <MessageBar messageBarType={0} isMultiline={true} className="mb-2" style={{ width: '100%' }}>
          {t('DASHBOARD.TABS.PATCH_POLICIES_TAB.INFORMATION_BAR')}
        </MessageBar>
        <div>
          <PatchPoliciesByReportingGroupsChart
            title={t('DASHBOARD.TABS.PATCH_POLICIES_TAB.TOP_TARGETS_BY_REPORTING_GROUPS.TITLE')}
            tooltip={t('DASHBOARD.TABS.PATCH_POLICIES_TAB.TOP_TARGETS_BY_REPORTING_GROUPS.TOOLTIP')}
            items={targetsDashboard.reportingGroupsCountByPatchPolicy.map((item) => ({
              name: item.patchPolicy,
              count: item.value,
            }))}
            units={t('DASHBOARD.TABS.PATCH_POLICIES_TAB.TOP_TARGETS_BY_REPORTING_GROUPS.REPORTING_GROUPS_LABEL')}
            maxHeight={'300px'}
          />
        </div>
        <div
          style={{
            display: 'flex',
            alignItems: 'stretch',
            justifyContent: 'space-around',
            gap: 15,
            flexWrap: 'wrap',
          }}
        >
          {filterSummaries(targetsDashboard.patchPolicySummaries).map((patchPolicySummary: PatchPolicySummary) => (
            <div
              key={patchPolicySummary.targetGroup}
              style={{
                width: isMobile ? '100%' : '450px',
              }}
            >
              <PatchPoliciesSummaryPieChart patchPolicySummary={patchPolicySummary} />
            </div>
          ))}
        </div>
      </div>
    </PageBody>
  )
}

export const PatchPolicyTargetsPage = withFeatureRender(PatchPolicyTargets, FEATURE_NAMES.PATCH_MANAGEMENT_READ)
