import { getPaginatedQueryParamsBlock, pageInfoBlock, paginatedVarsBlock } from 'app/config/pagination'
import { gql } from 'graphql-request'

export const usePaginatedUsersByRoleQuery = () => {
  const queryName = 'getPaginatedUsersByRole'
  const query = gql`
query Request(
  $organizationId: ID!
  $userRoleId: ID!
  ${getPaginatedQueryParamsBlock('UserFilter', 'UserSorting')}
) {
  getPaginatedUsersByRole(
    organizationId: $organizationId
    userRoleId: $userRoleId
    ${paginatedVarsBlock}
  ) {
    data {
      _id
      surname
      name
      email
      department
    }
    ${pageInfoBlock}
  }
}
`
  return {
    query,
    queryName,
  }
}
