import {
  Col,
  DataTableIcon,
  EditButton,
  FlexxiblePrimaryButton,
  MOBILE_QUERY,
  Row,
  TABLET_QUERY,
} from '@flexxibleit/flexxible-ui'
import useCreateForm, { useCreateFormV2 } from 'app/hooks/useCreateForm'
import { useTranslation } from 'react-i18next'
import useMediaQuery from 'app/hooks/utils/useMediaQuery'
import FlexxibleCard from 'app/components/FlexxibleCard/FlexxibleCard.component'
import { DisplayField } from 'app/components/DisplayField'
import SummaryCard from 'app/pages/organization-details/components/SummaryCard/SummaryCard'
import { dateFormatter } from 'app/services/date-formatter'
import AuthorIcon from '../../../../assets/img/AuthorIcon.svg'
import { User, WindowsPatchPolicy } from 'app/query-client/types'
import { CreateWindowsPatchPolicyForm } from '../components/CreateWindowsPatchPolicyForm'
import { WindowsPatchPolicyTargetsTable } from '../components/WindowsPatchPolicyTargetsTable'
import { CloneWindowsPatchPolicyForm } from '../components/CloneWindowsPatchPolicyForm'
import { FeatureRender } from '../../../../permissions/FeatureRender'
import { FEATURE_NAMES } from '../../../../permissions/FeatureName.enum'

interface WindowsPatchPolicyDetailsTabProps {
  windowsPatchPolicy: WindowsPatchPolicy
}

interface WindowsPatchPolicyWithUser extends WindowsPatchPolicy {
  createdBy?: User
}

export const WindowsPatchPolicyDetailsTab = ({ windowsPatchPolicy }: WindowsPatchPolicyDetailsTabProps) => {
  const { t } = useTranslation('patch_management')
  const [isMobile] = useMediaQuery(MOBILE_QUERY)
  const [isTablet] = useMediaQuery(TABLET_QUERY)
  const isDesktop = !isMobile && !isTablet
  const cardProps = {
    style: { marginTop: 30 },
  }

  const { open: openEditPatchPolicyForm } = useCreateFormV2(
    {
      title: t('PATCH_POLICIES.EDIT_FORM'),
      isFooterAtBottom: true,
    },
    <CreateWindowsPatchPolicyForm windowsPatchPolicy={windowsPatchPolicy} />
  )

  const onClone = useCreateForm(
    {
      title: t('WINDOWS_PATCH_POLICIES.CLONE_FORM'),
      isFooterAtBottom: true,
    },
    <CloneWindowsPatchPolicyForm />
  )

  return (
    <>
      <div className="d-flex d-flexJustifyEnd" style={{ marginTop: '-37px' }}>
        <FlexxiblePrimaryButton text={t('general:BUTTON.CLONE')} onClick={onClone} />
      </div>
      <div
        className={!isDesktop ? 'd-flex d-flexCol d-flexColReverse' : 'd-flex d-flexRow col-xs-12'}
        style={!isDesktop ? { gap: 0 } : { gap: 30, paddingRight: 20 }}
      >
        <FlexxibleCard cardProps={cardProps} styleNames="col-md-9 d-flex d-flexCol d-flexJustifyBetween">
          <Row>
            <Col className="col-xs-12 col-lg-6">
              <DisplayField label={t('PATCH_POLICIES.SUMMARY.NAME')}>{windowsPatchPolicy?.name}</DisplayField>
            </Col>
          </Row>

          <div className="d-flex d-flexRowReverse">
            <FeatureRender feature={FEATURE_NAMES.PATCH_MANAGEMENT_UPDATE}>
              <EditButton translator={t} onClick={openEditPatchPolicyForm} />
            </FeatureRender>
          </div>
        </FlexxibleCard>

        <div className="col-md-3 summary-container d-flexJustifyCenter d-flexAlignItemsCenter">
          <>
            <div
              className={isMobile || isTablet ? 'd-flex d-flexRow d-flexWrap' : 'd-flex d-flexCol '}
              style={{ gap: 20 }}
            >
              <SummaryCard
                Icon={DataTableIcon}
                title={t('PATCH_POLICIES.SUMMARY.CREATION_DATE')}
                text={windowsPatchPolicy?.createdAt ? dateFormatter(t, new Date(windowsPatchPolicy.createdAt)) : ''}
              />
              <SummaryCard
                Icon={AuthorIcon}
                title={t('PATCH_POLICIES.SUMMARY.CREATED_BY')}
                text={
                  (windowsPatchPolicy as WindowsPatchPolicyWithUser).createdBy
                    ? (windowsPatchPolicy as WindowsPatchPolicyWithUser).createdBy?.name +
                      ' ' +
                      (windowsPatchPolicy as WindowsPatchPolicyWithUser).createdBy?.surname
                    : ''
                }
              />
            </div>
          </>
        </div>
      </div>
      <div
        style={{
          margin: '30px 10px',
        }}
      >
        <h3>{t('PATCH_POLICIES.SUMMARY.TARGETS_TABLE.TABLE_TITLE')}</h3>
        <WindowsPatchPolicyTargetsTable windowsPatchPolicy={windowsPatchPolicy} />
      </div>
    </>
  )
}
