import { FilterField, FieldTypes } from '@flexxibleit/flexxible-ui'
import { InstalledAppOperatingSystem } from './InstalledAppOperatingSystem'
import { TFunction } from 'i18next'

interface Props {
  t: TFunction
}

export const useInstalledAppsFilter = ({ t }: Props): { filterFields: FilterField[] } => {
  const filterFields: FilterField[] = [
    {
      field: 'name',
      text: t('analyzer:INSTALLED_APPS.TABLE.PRODUCT_NAME'),
      type: FieldTypes.STRING,
    },
    {
      field: 'operatingSystem',
      text: t('analyzer:INSTALLED_APPS.TABLE.OPERATING_SYSTEM'),
      type: FieldTypes.ENUM,
      options: Object.values(InstalledAppOperatingSystem).map((operatingSystem) => ({
        key: operatingSystem,
        text: operatingSystem
      }))
    },
    {
      field: 'publisher',
      text: t('analyzer:INSTALLED_APPS.TABLE.PUBLISHER'),
      type: FieldTypes.STRING,
    },
  ]
  
  return {
    filterFields
  }
}