export const chartColors = [
  '#F4AE62',
  '#65CDAA',
  '#FFECCE',
  '#DD396A',
  '#20B1AB',
  '#FF6500',
  '#014140',
  '#FFC0CA',
  '#008080',
  '#FFE5E2',
  '#E38E9B',
  '#F5DFB4',
  '#65CDAA',
  '#FDDAB8',
  '#FAF0E6',
]
