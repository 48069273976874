import { IBreadcrumbItem } from '@fluentui/react'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import useOrganizationById from '../../hooks/organization/useOrganizationById'
import BusinessInformation from './information/Information'
import { withFeatureRender } from '../../permissions/withFeatureRender'
import { FEATURE_NAMES } from '../../permissions/FeatureName.enum'
import PageBody from 'app/components/PageBody/PageBody.component'
import useMediaQuery from 'app/hooks/utils/useMediaQuery'
import { MOBILE_QUERY } from 'app/config/media-query'
import { useOrganizationSelection } from '../../globals/useOrganizationSelection'
import { BusinessType } from 'app/models/business/BusinessType'
import { getOrganizationTypeIcon } from 'app/services/organization-icon-service'
import { FeedbackMessageBar } from 'app/components/FeedbackMessageBar/FeedbackMessageBar'

const BusinessDetail = () => {
  const { t } = useTranslation('organization_details')
  const navigate = useNavigate()
  const { selectedOrganizationId } = useOrganizationSelection()
  const { organizationId } = useParams()
  const { isLoading, data, isFetching, refetch } = useOrganizationById(organizationId || '')
  const location = useLocation()
  const baseUrl = location.pathname.split('/')[1]

  useEffect(() => {
    if (!selectedOrganizationId) {
      return
    }

    navigate(`/${baseUrl}/${selectedOrganizationId}/information`)
  }, [selectedOrganizationId])

  useEffect(() => {
    refetch()
  }, [organizationId])

  const onBreadcrumbItemClicked = (event?: React.MouseEvent, item?: IBreadcrumbItem) => {
    navigate(item?.key ?? '/home')
  }

  const getBreadcrumbItems = (): IBreadcrumbItem[] | undefined => {
    if (baseUrl === 'organizations') {
      return [
        {
          text: t('BREADCRUMB'),
          key: '/organizations',
          onClick: onBreadcrumbItemClicked,
        },
        { text: data?.name ?? '', key: 'current', isCurrentItem: true },
      ]
    }

    return undefined
  }

  return (
    <PageBody
      title={isLoading || isFetching ? '' : data?.name || ''}
      titleComponent={<TitleComponent title={`${data?.name}`} type={data?.type} />}
      breadcrumbItems={getBreadcrumbItems()}
      isLoading={isLoading || isFetching}
    >
      <FeedbackMessageBar />

      <div className="business-details pb-2">{data && <BusinessInformation organization={data} />}</div>
    </PageBody>
  )
}

const TitleComponent = ({ title, type }: { title: string; type?: BusinessType }) => {
  const [isMobile] = useMediaQuery(MOBILE_QUERY)

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'start',
      }}
    >
      {type && <div className="mr-1 vertical-center">{getOrganizationTypeIcon(type)}</div>}
      {isMobile ? <h3>{title}</h3> : <h1>{title}</h1>}
    </div>
  )
}

export const BusinessDetailPage = withFeatureRender(BusinessDetail, FEATURE_NAMES.ORGANIZATION_READ)
