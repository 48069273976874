import { IButtonProps } from "@fluentui/react";
import WhiteBox from '../../assets/img/empty_white_box.svg';
import StatusLayout from "./status-layout/status-layout";

export interface EmptyPageProps {
  message: string;
  buttonProps?: IButtonProps;
  img?: any;
}

const EmptyStatus = ({ message, buttonProps, img }: EmptyPageProps) => {

  return (
    <StatusLayout 
      img={img || WhiteBox}
      message={message}
      buttonProps={buttonProps}
      imgWithContainer={true}
    ></StatusLayout>
  )
}

export default EmptyStatus;