// https://learn.microsoft.com/en-us/powershell/module/microsoft.powershell.core/about/about_language_keywords?view=powershell-7.3
export const powershellKeywords = [
  //Microsoft.PowerShell.Utility
  'Add-Member',
  'Add-Type',
  'Clear-Variable',
  'Compare-Object',
  'ConvertFrom-Csv',
  'ConvertFrom-Json',
  'ConvertFrom-Markdown',
  'ConvertFrom-SddlString',
  'ConvertFrom-StringData',
  'ConvertTo-Csv',
  'ConvertTo-Html',
  'ConvertTo-Json',
  'ConvertTo-Xml',
  'Debug-Runspace',
  'Disable-PSBreakpoint',
  'Disable-RunspaceDebug',
  'Enable-PSBreakpoint',
  'Enable-RunspaceDebug',
  'Export-Alias',
  'Export-Clixml',
  'Export-Csv',
  'Export-FormatData',
  'Export-PSSession',
  'Format-Custom',
  'Format-Hex',
  'Format-List',
  'Format-Table',
  'Format-Wide',
  'Get-Alias',
  'Get-Culture',
  'Get-Date',
  'Get-Error',
  'Get-Event',
  'Get-EventSubscriber',
  'Get-FileHash',
  'Get-FormatData',
  'Get-Host',
  'Get-MarkdownOption',
  'Get-Member',
  'Get-PSBreakpoint',
  'Get-PSCallStack',
  'Get-Random',
  'Get-Runspace',
  'Get-RunspaceDebug',
  'Get-TraceSource',
  'Get-TypeData',
  'Get-UICulture',
  'Get-Unique',
  'Get-Uptime',
  'Get-Variable',
  'Get-Verb',
  'Group-Object',
  'Import-Alias',
  'Import-Clixml',
  'Import-Csv',
  'Import-LocalizedData',
  'Import-PowerShellDataFile',
  'Import-PSSession',
  'Invoke-Expression',
  'Invoke-RestMethod',
  'Invoke-WebRequest',
  'Join-String',
  'Measure-Command',
  'Measure-Object',
  'New-Alias',
  'New-Event',
  'New-Guid',
  'New-Object',
  'New-TemporaryFile',
  'New-TimeSpan',
  'New-Variable',
  'Out-File',
  'Out-GridView',
  'Out-Printer',
  'Out-String',
  'Read-Host',
  'Register-EngineEvent',
  'Register-ObjectEvent',
  'Remove-Alias',
  'Remove-Event',
  'Remove-PSBreakpoint',
  'Remove-TypeData',
  'Remove-Variable',
  'Select-Object',
  'Select-String',
  'Select-Xml',
  'Send-MailMessage',
  'Set-Alias',
  'Set-Date',
  'Set-MarkdownOption',
  'Set-PSBreakpoint',
  'Set-TraceSource',
  'Set-Variable',
  'Show-Command',
  'Show-Markdown',
  'Sort-Object',
  'Start-Sleep',
  'Tee-Object',
  'Test-Json',
  'Trace-Command',
  'Unblock-File',
  'Unregister-Event',
  'Update-FormatData',
  'Update-List',
  'Update-TypeData',
  'Wait-Debugger',
  'Wait-Event',
  'Write-Debug',
  'Write-Error',
  'Write-Host',
  'Write-Information',
  'Write-Output',
  'Write-Progress',
  'Write-Verbose',
  'Write-Warning',
  // Microsoft.PowerShell.Security
  'ConvertFrom-SecureString',
  'ConvertTo-SecureString',
  'Get-Acl',
  'Get-AuthenticodeSignature',
  'Get-CmsMessage',
  'Get-Credential',
  'Get-ExecutionPolicy',
  'Get-PfxCertificate',
  'New-FileCatalog',
  'Protect-CmsMessage',
  'Set-Acl',
  'Set-AuthenticodeSignature',
  'Set-ExecutionPolicy',
  'Test-FileCatalog',
  'Unprotect-CmsMessage',
  // Microsoft.PowerShell.Management
  'Add-Content',
  'Clear-Content',
  'Clear-Item',
  'Clear-ItemProperty',
  'Clear-RecycleBin',
  'Convert-Path',
  'Copy-Item',
  'Copy-ItemProperty',
  'Debug-Process',
  'Get-ChildItem',
  'Get-Clipboard',
  'Get-ComputerInfo',
  'Get-Content',
  'Get-HotFix',
  'Get-Item',
  'Get-ItemProperty',
  'Get-ItemPropertyValue',
  'Get-Location',
  'Get-Process',
  'Get-PSDrive',
  'Get-PSProvider',
  'Get-Service',
  'Get-TimeZone',
  'Invoke-Item',
  'Join-Path',
  'Move-Item',
  'Move-ItemProperty',
  'New-Item',
  'New-ItemProperty',
  'New-PSDrive',
  'New-Service',
  'Pop-Location',
  'Push-Location',
  'Remove-Item',
  'Remove-ItemProperty',
  'Remove-PSDrive',
  'Remove-Service',
  'Rename-Computer',
  'Rename-Item',
  'Rename-ItemProperty',
  'Resolve-Path',
  'Restart-Computer',
  'Restart-Service',
  'Resume-Service',
  'Set-Clipboard',
  'Set-Content',
  'Set-Item',
  'Set-ItemProperty',
  'Set-Location',
  'Set-Service',
  'Set-TimeZone',
  'Split-Path',
  'Start-Process',
  'Start-Service',
  'Stop-Computer',
  'Stop-Process',
  'Stop-Service',
  'Suspend-Service',
  'Test-Connection',
  'Test-Path',
  'Wait-Process',
  // Microsoft.PowerShell.Host
  'Start-Transcript',
  'Stop-Transcript',
  // Microsoft.PowerShell.Diagnostics
  'Get-Counter',
  'Get-WinEvent',
  'New-WinEvent',
  // Microsoft.PowerShell.Core
  'Add-History',
  'Clear-History',
  'Clear-Host',
  'Connect-PSSession',
  'Debug-Job',
  'Disable-ExperimentalFeature',
  'Disable-PSRemoting',
  'Disable-PSSessionConfiguration',
  'Disconnect-PSSession',
  'Enable-ExperimentalFeature',
  'Enable-PSRemoting',
  'Enable-PSSessionConfiguration',
  'Enter-PSHostProcess',
  'Enter-PSSession',
  'Exit-PSHostProcess',
  'Exit-PSSession',
  'Export-ModuleMember',
  'ForEach-Object',
  'Get-Command',
  'Get-ExperimentalFeature',
  'Get-Help',
  'Get-History',
  'Get-Job',
  'Get-Module',
  'Get-PSHostProcessInfo',
  'Get-PSSession',
  'Get-PSSessionCapability',
  'Get-PSSessionConfiguration',
  'Get-PSSubsystem',
  'Import-Module',
  'Invoke-Command',
  'Invoke-History',
  'New-Module',
  'New-ModuleManifest',
  'New-PSRoleCapabilityFile',
  'New-PSSession',
  'New-PSSessionConfigurationFile',
  'New-PSSessionOption',
  'New-PSTransportOption',
  'Out-Default',
  'Out-Host',
  'Out-Null',
  'Receive-Job',
  'Receive-PSSession',
  'Register-ArgumentCompleter',
  'Register-PSSessionConfiguration',
  'Remove-Job',
  'Remove-Module',
  'Remove-PSSession',
  'Save-Help',
  'Set-PSDebug',
  'Set-PSSessionConfiguration',
  'Set-StrictMode',
  'Start-Job',
  'Stop-Job',
  'Switch-Process',
  'Test-ModuleManifest',
  'Test-PSSessionConfigurationFile',
  'Unregister-PSSessionConfiguration',
  'Update-Help',
  'Wait-Job',
  'Where-Object',
  // Microsoft.PowerShell.Archive
  'Compress-Archive',
  'Expand-Archive',
  // CimCmdlets
  'Get-CimAssociatedInstance',
  'Get-CimClass',
  'Get-CimInstance',
  'Get-CimSession',
  'Invoke-CimMethod',
  'New-CimInstance',
  'New-CimSession',
  'New-CimSessionOption',
  'Register-CimIndicationEvent',
  'Remove-CimInstance',
  'Remove-CimSession',
  'Set-CimInstance',
  // Microsoft.WSMan.Management
  'Connect-WSMan',
  'Disable-WSManCredSSP',
  'Disconnect-WSMan',
  'Enable-WSManCredSSP',
  'Get-WSManCredSSP',
  'Get-WSManInstance',
  'Invoke-WSManAction',
  'New-WSManInstance',
  'New-WSManSessionOption',
  'Remove-WSManInstance',
  'Set-WSManInstance',
  'Set-WSManQuickConfig',
  'Test-WSMan',
  // PSDiagnostics
  'Disable-PSTrace',
  'Disable-PSWSManCombinedTrace',
  'Disable-WSManTrace',
  'Enable-PSTrace',
  'Enable-PSWSManCombinedTrace',
  'Enable-WSManTrace',
  'Get-LogProperties',
  'Set-LogProperties',
  'Start-Trace',
  'Stop-Trace',
  // PSReadLine
  'Get-PSReadLineKeyHandler',
  'Get-PSReadLineOption',
  'PSConsoleHostReadLine',
  'Remove-PSReadLineKeyHandler',
  'Set-PSReadLineKeyHandler',
  'Set-PSReadLineOption',
  // keywords
  'begin',
  'break',
  'catch',
  'class',
  'continue',
  'data',
  'define',
  'do',
  'dynamicparam',
  'else',
  'elseif',
  'end',
  'enum',
  'exit',
  'filter',
  'finally',
  'for',
  'foreach',
  'from',
  'function',
  'hidden',
  'if',
  'in',
  'param',
  'process',
  'return',
  'static',
  'switch',
  'throw',
  'trap',
  'try',
  'until',
  'using',
  'var',
  'while',
]
