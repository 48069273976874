export const PortalPermissionName = ['USER', 'ORGANIZATION_ADMIN', 'ADMIN'] as const

export type PortalPermissionName = (typeof PortalPermissionName)[number]

export const PortalPermission = {
  USER: 'USER',
  ORGANIZATION_ADMIN: 'ORGANIZATION_ADMIN',
  ADMIN: 'ADMIN',
}

export const portalPermissionLocalizationKeyDictionary = {
  USER: 'USERS.PORTAL_PERMISSION.USER',
  ORGANIZATION_ADMIN: 'USERS.PORTAL_PERMISSION.ORGANIZATION_ADMIN',
  ADMIN: 'USERS.PORTAL_PERMISSION.ADMIN',
}

export const hasEnoughPortalPermissions = (authPortal: PortalPermissionName, requestPortal: PortalPermissionName) => {
  const orderedPortal = [PortalPermission.ADMIN, PortalPermission.ORGANIZATION_ADMIN, PortalPermission.USER]
  const authIndex = orderedPortal.indexOf(authPortal)

  const requestIndex = orderedPortal.indexOf(requestPortal)

  return requestIndex >= authIndex;
  
}

