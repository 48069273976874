import {
  ControlledDropdown,
  DeleteActionButton,
  ErrorStatus,
  FormActionsContainer,
  LoadingSpinner,
  RowColLayout,
  SuccessStatus,
} from '@flexxibleit/flexxible-ui'
import {
  IDropdownOption,
  IPersonaProps,
  MessageBar,
  MessageBarButton,
  ResponsiveMode, Stack,
} from '@fluentui/react'
import { useBoolean } from '@fluentui/react-hooks'
import { zodResolver } from '@hookform/resolvers/zod'
import Dialog from 'app/components/Dialog/Dialog'
import Dropdown from 'app/components/forms/Dropdown'
import FormActions from 'app/components/forms/FormActions'
import { useFormSidebarContext } from 'app/components/forms/FormSidebar/state/FormSidebarState'
import { useFeedbackMessage } from 'app/context/feedback-message/FeedbackMessageContext'
import useUnlinkPolicy from 'app/hooks/organization/useUnlinkPolicy'
import useCreateReportingGroup from 'app/hooks/reportingGroups/useCreateReportingGroup'
import { useDeleteReportingGroup } from 'app/hooks/reportingGroups/useDeleteReportingGroup'
import { useUpdateReportingGroup } from 'app/hooks/reportingGroups/useUpdateReportingGroup'
import useGetBusinessAndPartnerUsers, { BasicUser } from 'app/hooks/users/useGetBusinessAndPartnerUsers'
import { ProductConfig, ReportingGroup } from 'app/query-client/types'
import { useEffect, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import * as z from 'zod'
import { GettingStartedChecklistTasks } from '../../../user-journey/getting-started-checklist/GettingStartedChecklistTasksEnum'
import { useGettingStartedChecklist } from '../../../user-journey/getting-started-checklist/useGettingStartedChecklist'
import useGetPatchPolicyTargetsByOrganization from '../../../hooks/patchPolicyTarget/useGetPatchPolicyTargetsByOrganization'
import ControlledTextField from '../../../components/forms/TextField'

interface Props {
  organizationId: string
  id?: string
  data?: FormValues
  policyReportingGroup?: string
  reportingGroups: ReportingGroup[]
  productConfigs?: ProductConfig[]
}

interface FormValues {
  name: string
  productConfig?: string
  patchPolicyTargetId?: string | null
  fishingPattern?: string
}

const schema: z.ZodType<FormValues> = z.object({
  name: z.string({ required_error: 'required' }).min(1, { message: 'required' }),
  productConfig: z.string().min(1, { message: 'required' }),
  patchPolicyTargetId: z.string().nullish().optional(),
  fishingPattern: z.string().optional().refine((data) => {
    try {
      if (!data) {
        return true
      }
      new RegExp(data);
      return true;
    } catch (e) {
      return false;
    }
  }, {
    message: "invalid_regular_expression",
  })
})

const getUserOptions = (users: BasicUser[]): IPersonaProps[] => {
  return users
    .map((user: BasicUser) => ({ key: user._id, text: [user.name, user.surname].join(' '), secondaryText: user.email }))
    .sort((a, b) => a.text.localeCompare(b.text))
}

const getProductOptions = (productConfigs: ProductConfig[]): IDropdownOption[] => {
  return productConfigs.map((config) => ({
    key: config._id,
    text: `${config.environment} (${config.product.name})`,
  }))
}

export const ReportingGroupForm = ({
  id,
  organizationId,
  data,
  policyReportingGroup,
  reportingGroups,
  productConfigs,
}: Props) => {
  const { t, i18n } = useTranslation('organization_details')
  const createReportingGroup = useCreateReportingGroup()
  const updateReportingGroup = useUpdateReportingGroup()
  const deleteReportingGroup = useDeleteReportingGroup()
  const { data: users } = useGetBusinessAndPartnerUsers(organizationId)
  const unlinkPolicy = useUnlinkPolicy()
  const [hideDeleteDialog, { toggle: toggleHideDeleteDialog }] = useBoolean(true)
  const [hideDialog, { toggle: toggleHideDialog }] = useBoolean(true)
  const { setSuccessMessage } = useFeedbackMessage()
  const { closeSidebar } = useFormSidebarContext()
  const isEdit = Boolean(id)
  const isEditable = !policyReportingGroup
  const { completeGettingStartedTask } = useGettingStartedChecklist()
  const { data: patchPolicyTargets } = useGetPatchPolicyTargetsByOrganization(organizationId || '')
  const [isLoading, setIsLoading] = useState(true)
  const dropdownOptions = getUserOptions((users ?? []).filter((user) => user.business !== organizationId))
  const dropdownOptionsLocal = getUserOptions((users ?? []).filter((user) => user.business === organizationId))
  const productDropdownOptions = getProductOptions(productConfigs ?? [])
  const dropdownOptionsPatchPolicyTargets =
    patchPolicyTargets?.map((target) => ({
      key: target._id,
      text: target.name,
    })) ?? []
  const getDefaultValues = () => {
    if (data) {
      return {
        ...data,
        patchPolicyTargetId: data.patchPolicyTargetId ?? undefined,
      }
    }
    return {
      name: '',
      productConfig: '',
    }
  }

  useEffect(() => {
    setIsLoading(createReportingGroup.isLoading || updateReportingGroup.isLoading || deleteReportingGroup.isLoading)
  }, [createReportingGroup, updateReportingGroup, deleteReportingGroup])

  const methods = useForm<FormValues>({
    resolver: zodResolver(schema),
    defaultValues: getDefaultValues(),
  })

  const onSubmit = (values: FormValues) => {
    if (isEdit) {
      updateReportingGroup.mutate({
        id: id!,
        input: {
          name: values.name,
          productConfigId: values.productConfig,
          patchPolicyTargetId: values.patchPolicyTargetId ?? null,
          fishingPattern: values.fishingPattern
        },
      })
    } else {
      createReportingGroup.mutate({
        name: values.name,
        organizationId,
        productConfigId: values.productConfig,
        fishingPattern: values.fishingPattern
      })
    }
  }

  const onDelete = (replaceWith: string) => {
    if (id) {
      deleteReportingGroup.mutateAsync({ id, replaceWith }).then(() => {
        closeSidebar()
      })
    }
  }

  const onUnlinkPolicy = () => {
    if (id) {
      unlinkPolicy.mutateAsync({ id: organizationId }).then(() => {
        closeSidebar()
        setSuccessMessage(t('REPORTING_GROUPS.FORM.UNLINK_SUCCESS_MESSAGE'))
      })
    }
  }

  const renderUnlinkConfirmation = (): JSX.Element => {
    return (
      <Dialog
        title={t('REPORTING_GROUPS.UNLINK_CONFIRMATION.TITLE')}
        description={t('REPORTING_GROUPS.UNLINK_CONFIRMATION.MESSAGE')}
        actionButton={t('REPORTING_GROUPS.UNLINK_CONFIRMATION.BUTTON_ACCEPT')}
        dismissButton={t('REPORTING_GROUPS.UNLINK_CONFIRMATION.BUTTON_CANCEL')}
        hidden={hideDialog}
        onDismiss={toggleHideDialog}
        callback={() => {
          toggleHideDialog()
          onUnlinkPolicy()
        }}
      />
    )
  }

  if (createReportingGroup.isSuccess) {
    completeGettingStartedTask(GettingStartedChecklistTasks.CREATE_REPORTING_GROUP)
    return (
      <div className="d-flex h-100">
        <SuccessStatus message={t('REPORTING_GROUPS.FORM.ADD_SUCCESS_MESSAGE')} />
      </div>
    )
  }
  if (createReportingGroup.isError) {
    return (
      <div className="d-flex h-100">
        <ErrorStatus message={t('REPORTING_GROUPS.FORM.ADD_ERROR_MESSAGE')} />
      </div>
    )
  }

  if (updateReportingGroup.isSuccess) {
    return (
      <div className="d-flex h-100">
        <SuccessStatus message={t('REPORTING_GROUPS.FORM.EDIT_SUCCESS_MESSAGE')} />
      </div>
    )
  }
  if (updateReportingGroup.isError) {
    return (
      <div className="d-flex h-100">
        <ErrorStatus message={t('REPORTING_GROUPS.FORM.EDIT_ERROR_MESSAGE')} />
      </div>
    )
  }

  if (deleteReportingGroup.isError) {
    return (
      <div className="d-flex h-100">
        <ErrorStatus message={t('REPORTING_GROUPS.FORM.DELETE_ERROR_MESSAGE')} />
      </div>
    )
  }

  if (unlinkPolicy.isError) {
    return (
      <div className="d-flex h-100">
        <ErrorStatus message={t('REPORTING_GROUPS.FORM.UNLINK_ERROR_MESSAGE')} />
      </div>
    )
  }

  return (
    <>
      {!isLoading && (
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            {isEdit && !isEditable && (
              <MessageBar
                messageBarType={3}
                isMultiline={true}
                dismissButtonAriaLabel={t('general:CLOSE_LABEL')}
                className="mb-2"
                style={{ width: '100%' }}
                actions={
                  <MessageBarButton
                    text={t('REPORTING_GROUPS.MANAGED_BY_POLICY.UNLINK')}
                    iconProps={{
                      iconName: 'RemoveLink',
                    }}
                    onClick={toggleHideDialog}
                  />
                }
              >
                {t('REPORTING_GROUPS.MANAGED_BY_POLICY.WARNING')}
              </MessageBar>
            )}
            <RowColLayout rowProps={{ classNames: 'mt-2' }}>
              <ControlledTextField
                name="name"
                required={true}
                translator={t}
                textFieldProps={{
                  disabled: isEdit && !isEditable,
                  label: t('REPORTING_GROUPS.FORM.NAME'),
                  placeholder: t('USERS.FORM.ENTER_TEXT'),
                }}
              />
            </RowColLayout>
            <RowColLayout rowProps={{ classNames: 'mt-2' }}>
              <Dropdown
                name="productConfig"
                required={true}
                translator={t}
                dropdownProps={{
                  disabled: isEdit && !isEditable,
                  responsiveMode: ResponsiveMode.large,
                  options: productDropdownOptions,
                  label: t('REPORTING_GROUPS.FORM.ENVIRONMENT'),
                  placeholder: t('REPORTING_GROUPS.FORM.ENVIRONMENT_PLACEHOLDER'),
                  defaultSelectedKey: data?.productConfig,
                }}
              />
            </RowColLayout>
            <RowColLayout rowProps={{ classNames: 'mt-2' }}>
              <ControlledTextField
                name="fishingPattern"
                tooltip={t('REPORTING_GROUPS.FORM.FISHING_PATTERN_INFO')}
                translator={t}
                textFieldProps={{
                  label: t('REPORTING_GROUPS.FORM.FISHING_PATTERN'),
                  placeholder: t('REPORTING_GROUPS.FORM.FISHING_PATTERN_PLACEHOLDER'),
                }}
              />
            </RowColLayout>

            {isEdit && (
              <RowColLayout rowProps={{ classNames: 'mt-2' }}>
                <ControlledDropdown
                  name="patchPolicyTargetId"
                  translator={t}
                  dropdownProps={{
                    responsiveMode: ResponsiveMode.large,
                    label: t('REPORTING_GROUPS.FORM.PATCH_POLICY_TARGET'),
                    placeholder: t('REPORTING_GROUPS.FORM.PATCH_POLICY_TARGET_PLACEHOLDER'),
                    options: dropdownOptionsPatchPolicyTargets,
                    defaultSelectedKey: patchPolicyTargets?.find((target) => target._id === data?.patchPolicyTargetId)
                      ?._id,
                  }}
                />
              </RowColLayout>
            )}
            <FormActionsContainer isEdit={isEdit && isEditable}>
              {false && isEdit && isEditable && (
                <DeleteActionButton locale={i18n.language} onClick={toggleHideDeleteDialog} />
              )}
              <span></span>
              <FormActions
                submitProps={
                  isEdit
                    ? {
                        text: t('general:BUTTON.SAVE'),
                        iconProps: { iconName: 'Save' },
                      }
                    : {
                        text: t('general:BUTTON.CREATE'),
                        iconProps: { iconName: 'Add' },
                      }
                }
              />
            </FormActionsContainer>
          </form>
          {renderUnlinkConfirmation()}
          <ReplacementDialog
            hidden={hideDeleteDialog}
            onDismiss={toggleHideDeleteDialog}
            callback={(replaceWith: string) => {
              onDelete(replaceWith)
            }}
            options={reportingGroups.filter((ele) => ele._id !== id).map((ele) => ({ key: ele._id, text: ele.name }))}
          />
        </FormProvider>
      )}
      {isLoading && <LoadingSpinner />}
    </>
  )
}

interface DeleteConfirmationProps {
  options: IDropdownOption[]
  callback: (replaceWith: string) => void
  onDismiss?: () => void
  hidden: boolean
}
interface DeleteFormData {
  replaceWith: string
}
const deleteSchema: z.ZodType<DeleteFormData> = z.object({
  replaceWith: z.string({ required_error: 'required' }),
})
const ReplacementDialog = (props: DeleteConfirmationProps) => {
  const { t } = useTranslation('organization_details')

  const methods = useForm<DeleteFormData>({
    resolver: zodResolver(deleteSchema),
  })

  useEffect(() => {
    methods.reset()
  }, [props.hidden])

  return (
    <Dialog
      title={t('REPORTING_GROUPS.DELETE_CONFIRMATION.TITLE')}
      description={t('REPORTING_GROUPS.DELETE_CONFIRMATION.MESSAGE')}
      actionButton={t('REPORTING_GROUPS.DELETE_CONFIRMATION.BUTTON_ACCEPT')}
      dismissButton={t('REPORTING_GROUPS.DELETE_CONFIRMATION.BUTTON_CANCEL')}
      actionButtonDisabled={!methods.formState.isValid}
      hidden={props.hidden}
      onDismiss={props.onDismiss}
      callback={() => {
        props.callback(methods.getValues().replaceWith)
      }}
    >
      <FormProvider {...methods}>
        <RowColLayout rowProps={{ classNames: 'mt-2' }}>
          <Dropdown
            name="replaceWith"
            required={true}
            translator={t}
            dropdownProps={{
              responsiveMode: ResponsiveMode.large,
              label: t('REPORTING_GROUPS.DELETE_CONFIRMATION.REPORTING_GROUP'),
              placeholder: t('REPORTING_GROUPS.DELETE_CONFIRMATION.REPORTING_GROUP_PLACEHOLDER'),
              options: props.options,
            }}
          />
        </RowColLayout>
      </FormProvider>
    </Dialog>
  )
}
