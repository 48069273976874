import { TFunction } from 'i18next'

export enum MicroserviceType {
  CONDITION = 'CONDITION',
  CUSTOM_FIELDS = 'CUSTOM_FIELDS',
  STANDARD = 'STANDARD',
}

export const microserviceTypeLabel = (t: TFunction): { [key in MicroserviceType]: string } => ({
  [MicroserviceType.CONDITION]: t('microservices:MICROSERVICE_TYPE.CONDITION'),
  [MicroserviceType.STANDARD]: t('microservices:MICROSERVICE_TYPE.STANDARD'),
  [MicroserviceType.CUSTOM_FIELDS]: t('microservices:MICROSERVICE_TYPE.CUSTOM_FIELDS'),
})

export const microserviceTypeOptions = (t: TFunction) =>
  Object.values(MicroserviceType).map((type) => ({
    key: type,
    text: microserviceTypeLabel(t)[type],
  }))
