export interface RowProps {
  children?: any;
  classNames?: string;
}

function Row({ children, classNames }: any) {
  return (<>
    <div className={`row ${classNames || ''}`}>
      {children}
    </div>
  </>)
}
export default Row