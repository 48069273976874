import { useQuery } from '@tanstack/react-query'
import graphClient from 'app/query-client/graphClient'
import { LinuxPatchPolicy } from 'app/query-client/types'
import { gql } from 'graphql-request'

const getLinuxPatchPoliciesByOrganizationQuery = gql`
  query GetLinuxPatchPoliciesByOrganization($organizationId: ID!) {
    getLinuxPatchPoliciesByOrganization(organizationId: $organizationId) {
      _id
      name
      organizationId
      createdBy
      patchPolicyTargets {
        _id
        name
      }
      targetGroupId
      createdAt
      updatedAt
    }
  }
`

async function getLinuxPatchPoliciesByOrganization(organizationId: string): Promise<LinuxPatchPolicy[]> {
  const response = await graphClient.request(getLinuxPatchPoliciesByOrganizationQuery, { organizationId })

  return response.getLinuxPatchPoliciesByOrganization
}

export default function (organizationId: string) {
  return useQuery(
    ['getLinuxPatchPoliciesByOrganization', organizationId],
    () => getLinuxPatchPoliciesByOrganization(organizationId),
    {
      refetchOnWindowFocus: false,
    }
  )
}
