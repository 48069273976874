import { useMutation, useQueryClient } from '@tanstack/react-query'
import { gql } from 'graphql-request'
import graphClient from '../../query-client/graphClient'

interface Response {
  deleteUserRole: boolean
}

const deleteUserRoleQuery = gql`
  mutation Request($userRoleId: ID!, $organizationId: ID!) {
    deleteUserRole(userRoleId: $userRoleId, organizationId: $organizationId)
  }
`

const deleteUserRole = async (userRoleId: string, organizationId: string): Promise<boolean> => {
  const response: Response = await graphClient.request(deleteUserRoleQuery, { userRoleId, organizationId })

  return response.deleteUserRole
}

export default function () {
  const queryClient = useQueryClient()

  return useMutation(
    ['deleteUserRole'],
    ({ userRoleId, organizationId }: { userRoleId: string; organizationId: string }) =>
      deleteUserRole(userRoleId, organizationId),
    {
      onSuccess: (id) => {
        queryClient.invalidateQueries(['getRoleById', id])
      },
    }
  )
}
