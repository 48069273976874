import { getPaginatedQueryParamsBlock, pageInfoBlock, paginatedVarsBlock } from 'app/config/pagination'
import { gql } from 'graphql-request'

export const usePaginatedEnabledMicroservicesQuery = () => {
  const queryName = 'getPaginatedEnabledMicroservices'
  const query = gql`
query Request(
  $organizationId: ID!
  $language: String!
  ${getPaginatedQueryParamsBlock('EnabledMicroservicesFiltersGraphql', 'EnabledMicroservicesSortingGraphql')}
) {
  getPaginatedEnabledMicroservices(
    organizationId: $organizationId
    language: $language
    ${paginatedVarsBlock}
  ) {
    data {
      _id
      microserviceName {
        code
        text
        translatedByIA
      }
      description {
        code
        text
        translatedByIA
      }
      category {
        code
        text
        translatedByIA
      }
      iconBackground
      iconName
      organization {
        isTrial
        name
      }
    }
    ${pageInfoBlock}
  }
}
`
  return {
    query,
    queryName,
  }
}
