import { TFunction } from 'react-i18next'
import { DateFormat, dateFormatter } from '../../services/date-formatter'
import { MOBILE_QUERY, useMediaQuery } from '@flexxibleit/flexxible-ui'
import { useEffect, useState } from 'react'
import { IPaginatedTableColumn } from '@flexxibleit/flexxible-ui/dist/lib/components/paginated-table/paginatedTable.types'

type UseUserLoginsBuildTableProps = {
  t: TFunction
}

export const useUserLoginsBuildTable = ({ t }: UseUserLoginsBuildTableProps) => {

  const [columns, setColumns] = useState<IPaginatedTableColumn[]>([])

  useEffect(() => {
    setColumns([
      {
        key: 'ip',
        name: t('TABLE.IP'),
        fieldName: 'ip',
        minWidth: 25,
        flexGrow: 1,
      },
      {
        key: 'application',
        name: t('TABLE.APPLICATION'),
        fieldName: 'application',
        minWidth: 25,
        flexGrow: 1,
      },
      {
        key: 'userAgent',
        name: t('TABLE.USER_AGENT'),
        fieldName: 'userAgent',
        minWidth: 25,
        flexGrow: 1,
      },
      {
        key: 'date',
        name: t('TABLE.DATE'),
        fieldName: 'date',
        minWidth: 25,
        flexGrow: 1,
        onRender: (item?: any) => {
          return <span>{dateFormatter(t, new Date(item.date), DateFormat.DATETIME)}</span>
        },
      },
    ])
  }, [t])

  return {
    columns,
  }
}
