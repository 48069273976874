import { getPaginatedQueryParamsBlock, pageInfoBlock, paginatedVarsBlock } from 'app/config/pagination'
import { gql } from 'graphql-request'

export const usePaginatedBusinessUsersQuery = () => {
  const queryName = 'getPaginatedBusinessUsers'
  const query = gql`
query Request(
  $businessId: ID!
  ${getPaginatedQueryParamsBlock('UserFilter', 'UserSorting')}
) {
  getPaginatedBusinessUsers(
    businessId: $businessId
    ${paginatedVarsBlock}
  ) {
    data {
      _id
      surname
      name
      email
      userRole {
        _id
        name
      }
      department
      language
      grantedBusinesses
    }
    ${pageInfoBlock}
  }
}
`
  return {
    query,
    queryName,
  }
}
