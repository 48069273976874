import { useMutation, useQueryClient } from '@tanstack/react-query'
import { gql } from 'graphql-request'
import graphClient from '../../query-client/graphClient'
import { Microservice } from '../../query-client/types'

const unarchiveMicroserviceQuery = gql`
  mutation unarchiveMicroservice($id: ID!) {
    unarchiveMicroservice(id: $id) {
      _id
    }
  }
`

const unarchiveMicroservice = async (data: string): Promise<Microservice> => {
  return graphClient.request(unarchiveMicroserviceQuery, { id: data }).then((res) => res.unarchiveMicroservice)
}

export const useUnarchiveMicroservice = () => {
  const queryClient = useQueryClient()

  return useMutation(['unarchiveMicroservice'], (id: string) => unarchiveMicroservice(id), {
    onSuccess: (id) => {
      queryClient.invalidateQueries(['microservices'])
      queryClient.invalidateQueries(['microservices', id])
    },
  })
}
