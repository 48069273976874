import { FC } from 'react'

import Highcharts from 'highcharts'
import { BarChart } from '../../../../components/Charts/BarChart/BarChart'
import { DashboardChartCard } from '../../../../components/Dashboard/DashboardChartCard/DashboardChartCard'

type ItemCount = {
  name: string
  count: number
  group?: string
  icon?: string
}

interface Props {
  title: string
  tooltip?: string,
  units: string
  items: ItemCount[]
  maxWidth?: string
  maxHeight?: string
}

export const PatchPoliciesByReportingGroupsChart: FC<Props> = (props: Props) => {
  const chartOptions: Highcharts.Options = {
    chart: {
      animation: false,
      height: props.maxHeight,
      width: props.maxWidth,
    },
    yAxis: {
      opposite: true,
      tickPixelInterval: 150,
      title: {
        text: null,
      },
    },
    plotOptions: {
      series: {
        animation: false,
        borderWidth: 0,
        dataSorting: {
          enabled: true,
          matchByName: true,
        },
        dataLabels: {
          enabled: true,
        },
      },
    },
    responsive: {
      rules: [
        {
          condition: {
            maxWidth: 550,
          },
          chartOptions: {
            xAxis: {
              visible: false,
            },
            subtitle: {
              x: 0,
            },
            plotOptions: {
              series: {
                dataLabels: [
                  {
                    enabled: true,
                    y: 8,
                  },
                  {
                    enabled: true,
                    format: '{point.name}',
                    y: -8,
                    style: {
                      fontWeight: 'normal',
                      opacity: 0.7,
                    },
                  },
                ],
              },
            },
          },
        },
      ],
    },
  }

  const series: Highcharts.SeriesBarOptions[] = [
    {
      type: 'bar',
      name: props.units,
      colorByPoint: true,
      data: props.items.map((item) => ({
        name: item.name,
        y: item.count,
      })),
    },
  ]

  const categories: string[] = props.items.map((item) => item.name)

  return (
    <DashboardChartCard title={props.title} tooltip={props.tooltip}>
      <BarChart series={series} categories={categories} highchartsConfig={chartOptions} />
    </DashboardChartCard>
  )
}
