import { useQuery, useQueryClient } from "@tanstack/react-query";
import { gql } from "graphql-request";
import graphClient from "../../query-client/graphClient";
import { Business } from "../../query-client/types";

const allBusiness = gql`query {
  getBusiness {
    _id
    name
    country
    email
    type
    description
    language
    industry
    active
    lastReport
    users {
      _id
      surname
      name
      email
      role
      language
    }
    isTrial
    productConfigs {
      _id
      created_at
      environment
      lastReportDate
      license
      active
      product {
        _id
        name
        active
      }
    }
  }
}`

function getBusiness(): Promise<Business[]> {
    return graphClient
        .request(allBusiness)
        .then(r => r.getBusiness)
}

export default function () {
  const queryClient = useQueryClient()
  return useQuery(['business'], () => getBusiness(), {
    refetchOnWindowFocus: false,
    onSuccess: (businesses: Business[]) => {
      businesses.forEach((business: Business) => {
        queryClient.setQueryData(['business', business._id, 'users'], business.users)
      })
    }
  })
}