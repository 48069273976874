import { IColumn } from '@fluentui/react'

export const getDefaultValuesColumns = (columns: IColumn[], nItems: number): IColumn[] => {
  return columns.map((column) => ({
    className: 'default-column',
    isResizable: nItems ? true : false,
    ...column,
  }))
}

export const saveColumnsConfig = (tableId: string, key: string, value: number): void => {
  const name = `table-config-${tableId}`

  const savedColumnsConfig = localStorage.getItem(name)

  let columnsToSave

  if (!savedColumnsConfig) {
    columnsToSave = [{ key, maxWidth: value }]
  } else {
    const savedColumnsArray = JSON.parse(savedColumnsConfig)

    const existingColumnIndex = savedColumnsArray.findIndex((col: IColumn) => col.key === key)

    if (existingColumnIndex !== -1) {
      savedColumnsArray[existingColumnIndex].maxWidth = value
    } else {
      savedColumnsArray.push({ key, maxWidth: value })
    }

    columnsToSave = savedColumnsArray
  }

  localStorage.setItem(name, JSON.stringify(columnsToSave))
}

export const loadColumnsConfig = (tableId: string, defaultColumns: IColumn[]): IColumn[] => {
  const name = `table-config-${tableId}`

  const savedColumnsConfig = localStorage.getItem(name)
  if (savedColumnsConfig) {
    const savedColumns = JSON.parse(savedColumnsConfig) as {
      key: string
      maxWidth: number
      calculatedWidth: number
      currentWidth: number
    }[]
    return defaultColumns.map((column) => {
      const savedColumn = savedColumns.find((c) => c.key === column.key)
      if (savedColumn) {
        return {
          ...column,
          maxWidth: savedColumn.maxWidth,
        }
      }
      return column
    })
  }

  return defaultColumns
}
