import { LoadingSpinner } from '@flexxibleit/flexxible-ui'
import { DirectionalHint, Icon, Label, TooltipDelay, TooltipHost } from '@fluentui/react'
import { labelStyle, valueStyle } from 'app/components/DisplayField'
import { useOrganizationSelection } from 'app/globals/useOrganizationSelection'
import { useCreateFormV2 } from 'app/hooks/useCreateForm'
import { useTranslation } from 'react-i18next'
import EditButton from '../../../../components/Buttons/EditButton.component'
import FlexxibleCard from '../../../../components/FlexxibleCard/FlexxibleCard.component'
import Col from '../../../../components/Layouts/Col'
import Row from '../../../../components/Layouts/Row'
import { EnabledMicroserviceView } from '../../../../hooks/microservices/useGetEnabledMicroserviceById'
import { useUpdateEnabledMicroserviceSettings } from '../../../../hooks/microservices/useUpdateEnabledMicroserviceSettings'
import { MicroserviceAction } from '../../../../models/microservices'
import { EnabledMicroserviceSettingsForm } from './EnabledMicroserviceSettingsForm'

interface Props {
  microservice: EnabledMicroserviceView
}

export const EnabledMicroserviceSettingsTab = ({ microservice }: Props) => {
  const { t } = useTranslation('microservices')
  const { selectedOrganizationId } = useOrganizationSelection()
  const { mutateAsync } = useUpdateEnabledMicroserviceSettings()

  if (!selectedOrganizationId) {
    return <LoadingSpinner />
  }

  const onSubmitEdit = async (data: { efficiency: number }) => {
    try {
      await mutateAsync({
        microserviceId: microservice._id,
        organizationId: selectedOrganizationId ?? '',
        input: {
          efficiency: data.efficiency,
        },
      })
      setSuccess({
        message: t('DESIGNER.MICROSERVICE_FORM.UPDATE_SUCCESS'),
      })
    } catch (error) {
      setError({
        message: t('DESIGNER.MICROSERVICE_FORM.UPDATE_ERROR'),
      })
    }
  }

  const {
    open: openEditForm,
    setError,
    setSuccess,
  } = useCreateFormV2(
    {
      title: t('DETAILS.SETTINGS.EDIT_SETTINGS_TITLE'),
      isFooterAtBottom: true,
    },
    <EnabledMicroserviceSettingsForm
      initialData={{
        efficiency: microservice.efficiency,
      }}
      microserviceId={microservice._id}
      onSubmit={onSubmitEdit}
    />
  )

  return (
    <div style={{ marginTop: 20, marginLeft: 10 }}>
      <FlexxibleCard cardProps={{ style: { marginTop: 36 } }} styleNames="d-flex d-flexCol d-flexJustifyBetween">
        <Row>
          <Col className="col-xs-12 col-lg-6">
            <Label styles={labelStyle}>
              {t('DETAILS.SETTINGS.EFFICIENCY')}
              <TooltipHost
                delay={TooltipDelay.zero}
                directionalHint={DirectionalHint.topCenter}
                content={t('DETAILS.SETTINGS.EFFICIENCY_TOOLTIP')}
                styles={{ root: { verticalAlign: 'middle' } }}
              >
                <Icon
                  className="cursor-default"
                  iconName="Info"
                  style={{ display: 'inline-block', fontSize: '15px', paddingLeft: '5px' }}
                />
              </TooltipHost>
            </Label>
            <Label styles={valueStyle}>
              {t('DETAILS.SETTINGS.EFFICIENCY_VALUE', { value: microservice.efficiency })}
            </Label>
          </Col>
        </Row>
        {microservice.permissions.includes(MicroserviceAction.EDIT_ORGANIZATION_SETTINGS) && (
          <div className="d-flex d-flexRowReverse">
            <EditButton onClick={openEditForm} style={{ marginLeft: '20px' }} />
          </div>
        )}
      </FlexxibleCard>
    </div>
  )
}
