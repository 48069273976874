import { MOBILE_QUERY, useMediaQuery, ViewDetailsActionButton } from '@flexxibleit/flexxible-ui'
import { IPaginatedTableColumn } from '@flexxibleit/flexxible-ui/dist/lib/components/paginated-table/paginatedTable.types'
import { ColumnActionsMode } from '@fluentui/react'
import { CellWithFeatureGreenLink } from 'app/components/table-page-body/CellWithFeatureGreenLink'
import { FEATURE_NAMES } from 'app/permissions/FeatureName.enum'
import { useFeatureRender } from 'app/permissions/useFeatureRender'
import { withFeatureRender } from 'app/permissions/withFeatureRender'
import { Tenant } from 'app/query-client/types'
import { dateFormatter } from 'app/services/date-formatter'
import { getOrganizationTypeIcon } from 'app/services/organization-icon-service'
import { getProductIcon } from 'app/services/product-icon.service'
import { i18n } from 'i18next'
import { useEffect, useState } from 'react'
import { TFunction } from 'react-i18next'

type UseOrgTenantsBuildTableProps = {
  t: TFunction
  i18n: i18n
  organizationId: string
  openEditTenant: (tenant: Tenant) => () => void
}

export const useOrgTenantsBuildTable = ({ t, i18n, organizationId, openEditTenant }: UseOrgTenantsBuildTableProps) => {
  const [isMobile] = useMediaQuery(MOBILE_QUERY)
  const [columns, setColumns] = useState<IPaginatedTableColumn[]>([])

  const { checkFeature } = useFeatureRender()
  const tableActionsFeatures = [FEATURE_NAMES.TENANT_READ]
  const canSeeActions = tableActionsFeatures.some((feature) => checkFeature(feature))

  useEffect(() => {
    setColumns([
      {
        key: 'name',
        name: t('TENANTS.TABLE.NAME'),
        fieldName: 'name',
        minWidth: 25,
        flexGrow: 1,
        isRowHeader: true,
        onRender: (item: Tenant) => {
          const isDefault = item.organizationRelated._id === organizationId

          return (
            <CellWithFeatureGreenLink
              className="d-flex d-flexAlignItemsCenter"
              feature={FEATURE_NAMES.TENANT_READ}
              callback={openEditTenant(item)}
            >
              <span className="d-flex d-flexAlignItemsCenter">
                <span className="d-flex d-flexAlignItemsCenter" style={{ marginRight: 5 }}>
                  {getOrganizationTypeIcon(item?.organizationRelated?.type, 18, 18)}
                </span>
                <span>{isDefault ? t('TENANTS.TABLE.DEFAULT_TENANT_NAME', { name: item?.name }) : item?.name}</span>
              </span>
            </CellWithFeatureGreenLink>
          )
        },
      },
      {
        key: 'product',
        name: t('TENANTS.TABLE.PRODUCT'),
        fieldName: 'product',
        minWidth: 25,
        flexGrow: 1,
        isRowHeader: true,
        onRender: (item: Tenant) => {
          if (!item.product) return
          return (
            <span className="d-flex d-flexAlignItemsCenter">
              <span className="d-flex d-flexAlignItemsCenter" style={{ marginRight: 5 }}>
                {getProductIcon(item.product.name, 18, 18)}
              </span>
              <span>{item.product.name}</span>
            </span>
          )
        },
      },
      {
        key: 'policy',
        name: t('TENANTS.TABLE.POLICY'),
        fieldName: 'policy',
        minWidth: 25,
        flexGrow: 1,
        isRowHeader: true,
        onRender: (item: Tenant) => {
          return <span>{item.policy?.name ?? ''}</span>
        },
      },
      {
        key: 'created_at',
        name: t('TENANTS.TABLE.CREATION_DATE'),
        fieldName: 'created_at',
        minWidth: 25,
        flexGrow: 1,
        isRowHeader: true,
        onRender: (item: Tenant) => {
          return <span>{dateFormatter(t, item.created_at)}</span>
        },
      },
      ...(canSeeActions
        ? [
            {
              key: 'actions',
              name: t('TENANTS.TABLE.ACTION'),
              minWidth: 25,
              flexGrow: 1,
              isPadded: true,
              onRender: (item: Tenant) => {
                return (
                  <span className="d-flex d-flexRow">
                    <span style={isMobile ? { marginRight: 10 } : { marginRight: 20 }}>
                      <>
                        {withFeatureRender(
                          ViewDetailsActionButton,
                          FEATURE_NAMES.TENANT_READ
                        )({
                          locale: i18n.language,
                          isMobile: isMobile,
                          onClick: openEditTenant(item),
                        })}
                      </>
                    </span>
                  </span>
                )
              },
            },
          ]
        : []),
    ])
  }, [isMobile])

  return { columns }
}
