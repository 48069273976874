import { useQuery } from '@tanstack/react-query'
import graphClient from 'app/query-client/graphClient'
import { gql } from 'graphql-request'
import {
  AutomaticApproval,
  OperativeSystem,
  PatchPolicy,
  Products,
  ReportingGroup,
  WindowsPatchPolicy,
} from '../../query-client/types'

interface GetWindowsPatchPolicyRequest {
  id: string
}

const query = gql`
  query Request($id: String!) {
    getWindowsPatchPolicy(id: $id) {
      _id
      name
      organizationId
      createdBy {
        _id
        name
        surname
      }
      targetGroupId
      patchPolicyTargets {
        _id
        name
      }
      automaticApprovals {
        classification
        products
        deadlineAfterRelease
      }
      createdAt
      updatedAt
    }
  }
`

interface UserResponse {
  _id: string
  name: string
  surname: string
}

interface WindowsPatchPolicyTarget {
  _id: string
  name: string
}

interface WindowsPatchPolicyResponse {
  _id: string
  name: string
  organizationId: string
  createdBy: UserResponse
  targetGroupId: string
  patchPolicyTargets: WindowsPatchPolicyTarget[]
  automaticApprovals: AutomaticApproval[]
  createdAt: string
  updatedAt: string
}

interface Response {
  getWindowsPatchPolicy: WindowsPatchPolicyResponse
}

async function getWindowsPatchPolicy(request: GetWindowsPatchPolicyRequest): Promise<WindowsPatchPolicy> {
  const { getWindowsPatchPolicy }: Response = await graphClient.request(query, {
    id: request.id,
  })
  return {
    ...(getWindowsPatchPolicy as any),
    //schedule: JSON.parse(getPatchPolicy.schedule),
  }
}

export default function (request: GetWindowsPatchPolicyRequest, shouldFetch: boolean) {
  return useQuery(['getWindowsPatchPolicy', request.id], () => getWindowsPatchPolicy(request), {
    refetchOnWindowFocus: false,
    enabled: shouldFetch,
  })
}
