import { useMutation, useQueryClient } from '@tanstack/react-query'
import graphClient from 'app/query-client/graphClient'
import { gql } from 'graphql-request'

const mutation = gql`
  mutation Request($id: ID!) {
    applyTenantPolicy(id: $id)
  }
`

const applyTenantPolicy = async (id: string): Promise<string> => {
  await graphClient.request(mutation, { id })

  return id
}

export default function () {
  const queryClient = useQueryClient()

  return useMutation(['applyTenantPolicy'], ({ id }: { id: string }) => applyTenantPolicy(id), {
    onSuccess: (id) => {
      queryClient.invalidateQueries(['getTenantPolicy', id])
    },
  })
}
