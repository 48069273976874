import useCreateForm from 'app/hooks/useCreateForm'
import { UserRole } from 'app/query-client/types'
import { TFunction } from 'i18next'

import { ICommandItem } from '@flexxibleit/flexxible-ui'
import { RolPermissionsForm } from './RolPermissionsForm'
import { useFeatureRender } from '../../../../../permissions/useFeatureRender'
import { FEATURE_NAMES } from '../../../../../permissions/FeatureName.enum'

type UseRolePermissionsActionsProps = {
  t: TFunction
  userRole: UserRole
}

export const useRolePermissionsActions = ({ t, userRole }: UseRolePermissionsActionsProps) => {
  const { checkFeature } = useFeatureRender()

  const openCreatePermissionForm = useCreateForm(
    {
      title: t('ROLES.PERMISSIONS.FORM.CREATE_TITLE'),
      isFooterAtBottom: true,
    },
    <RolPermissionsForm userRole={userRole} />
  )

  const commandBarActions: ICommandItem[] = checkFeature(FEATURE_NAMES.ROLE_CREATE)
    ? [
        {
          key: 'create',
          text: t('general:BUTTON.CREATE'),
          iconProps: { iconName: 'Add' },
          onClick: () => openCreatePermissionForm(),
        },
      ]
    : []

  return {
    openCreatePermissionForm,
    commandBarActions,
  }
}
