import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import TableActivation from './TableActivation'
import { useOrganizationSelection } from 'app/globals/useOrganizationSelection'
import { useEffect } from 'react'
import { TablePageBody } from '@flexxibleit/flexxible-ui'
import { useLocalStorageSearchParams } from 'app/hooks/utils/useLocalStorageSearchParams'
import { withFeatureRender } from '../../../../permissions/withFeatureRender'
import { FEATURE_NAMES } from '../../../../permissions/FeatureName.enum'

const ActivationsTenants = () => {
  const { t } = useTranslation('organization_details')
  const navigate = useNavigate()

  const [searchParams, setSearchParams] = useSearchParams()

  const { organizationId } = useParams()
  const { getPersistedSearchParams } = useLocalStorageSearchParams(false)

  const { selectedOrganizationId, selectedOrganization } = useOrganizationSelection()
  const baseUrl = location.pathname.split('/')[1]

  useEffect(() => {
    const persistedSearchParams = getPersistedSearchParams(location.pathname)
    if (!persistedSearchParams) {
      searchParams.set('sortBy', 'name')
      searchParams.set('sortOrder', 'ASC')
      setSearchParams(searchParams)
    }
  }, [])

  useEffect(() => {
    if (!selectedOrganizationId) {
      return
    }

    const finalSearchParams = location.search !== '' ? location.search : getPersistedSearchParams(location.pathname)

    navigate(`/${baseUrl}/${selectedOrganizationId}/tenants/activations${finalSearchParams}`)
  }, [selectedOrganizationId])

  return (
    <TablePageBody title={t('TENANTS_ACTIVATION_PIVOT')} titleTag={selectedOrganization?.name}>
      <TableActivation organizationId={organizationId ?? ''} />
    </TablePageBody>
  )
}

export const ActivationsTenantsFeature = withFeatureRender(ActivationsTenants, FEATURE_NAMES.ACTIVATION_READ)
