import { CreateButton, TableActionButton } from '@flexxibleit/flexxible-ui'
import {
  ColumnActionsMode,
  DetailsListLayoutMode,
  IColumn,
  Icon,
  Pivot,
  PivotItem,
  SelectionMode,
  Stack,
} from '@fluentui/react'
import { useTranslation } from 'react-i18next'
import { useGetOrganizationApiKeys } from '../../../hooks/apiKey/useGetOrganizationApiKeys'
import { ApiKey } from '../../../query-client/types'
import StatusBadge, { BadgeMode } from '../../../components/status-badge/status-badge.component'
import Table from '../../../components/Table/Table.component'
import { useMemo } from 'react'
import { roleLocalizationKeyDictionary } from '../../../permissions/RoleFeature.enum'
import useMediaQuery from '../../../hooks/utils/useMediaQuery'
import { MOBILE_QUERY } from '../../../config/media-query'
import useCreateForm from '../../../hooks/useCreateForm'
import { ApiKeyForm } from './ApiKeyForm'
import { useApiKeyRevocationDialog } from './useApiKeyRevocationDialog'
import { StringParam, useQueryParam } from 'use-query-params'
import EmptyStatus from '../../../components/status/empty-status'
import { dateFormatter } from 'app/services/date-formatter'
import { FEATURE_NAMES } from '../../../permissions/FeatureName.enum'
import { useFeatureRender } from '../../../permissions/useFeatureRender'

const PIVOT_ITEM_KEY_ALL = 'all'
const PIVOT_ITEM_KEY_ACTIVE = 'active'
const PIVOT_ITEM_KEY_INACTIVE = 'inactive'

interface Props {
  organizationId?: string
}

export const ApiKeysFeature = (props: Props) => {
  if (!props.organizationId) {
    return <></>
  }

  const [isMobile] = useMediaQuery(MOBILE_QUERY)
  const { t, i18n } = useTranslation('organization_details')
  const [tabQueryParameter, setTabQueryParameter] = useQueryParam('filter', StringParam)
  const { data } = useGetOrganizationApiKeys(props.organizationId)
  const { checkFeature } = useFeatureRender()

  const sortFunction = (a: ApiKey, b: ApiKey) => {
    return Number(b.created_at) - Number(a.created_at)
  }

  const isKeyActive = (key: ApiKey): boolean => {
    const now = new Date()
    const expiration = new Date(Number(key.expiresAt))
    return !key.revokedAt && expiration.getTime() > now.getTime()
  }

  const listItemsFactory = () => {
    if (!data) {
      return
    }
    return data
      .filter((apiKey: ApiKey) => {
        switch (tabQueryParameter) {
          case PIVOT_ITEM_KEY_ACTIVE:
            return isKeyActive(apiKey)
          case PIVOT_ITEM_KEY_INACTIVE:
            return !isKeyActive(apiKey)
          case PIVOT_ITEM_KEY_ALL:
          default:
            return true
        }
      })
      .sort(sortFunction)
  }

  const listItems = useMemo(listItemsFactory, [data, tabQueryParameter])

  const openGenerateApiKey = useCreateForm(
    {
      title: t('API_KEYS.FORM.GENERATE_API_KEY'),
      isFooterAtBottom: true,
    },
    <ApiKeyForm organizationId={props.organizationId} />
  )

  const { openApiKeyRevocationDialog, renderApiKeyRevocationDialog } = useApiKeyRevocationDialog(props.organizationId)

  const tableActionsFeatures = [FEATURE_NAMES.INTEGRATIONS_UPDATE]
  const canSeeActions = tableActionsFeatures.some((feature) => checkFeature(feature))
  const columns: IColumn[] = [
    {
      key: 'status',
      name: t('API_KEYS.TABLE.STATUS'),
      minWidth: 70,
      isRowHeader: true,
      onRender: (item: ApiKey) => {
        return <StatusBadge active={isKeyActive(item)} mode={BadgeMode.circle} size={8} />
      },
      isPadded: true,
      columnActionsMode: ColumnActionsMode.disabled,
    },
    {
      key: 'key_id',
      name: t('API_KEYS.TABLE.KEY_ID'),
      minWidth: 160,
      flexGrow: 1,
      isRowHeader: true,
      onRender: (item: ApiKey) => {
        return <span>{item._id}</span>
      },
      isPadded: true,
      columnActionsMode: ColumnActionsMode.disabled,
    },
    {
      key: 'role',
      name: t('API_KEYS.TABLE.ROLE'),
      minWidth: 170,
      isRowHeader: true,
      onRender: (item: ApiKey) => {
        return <span>{t(roleLocalizationKeyDictionary[item.programmaticUser.role])}</span>
      },
      isPadded: true,
      columnActionsMode: ColumnActionsMode.disabled,
    },
    {
      key: 'createdBy',
      name: t('API_KEYS.TABLE.CREATED_BY'),
      minWidth: 130,
      isRowHeader: true,
      onRender: (item: ApiKey) => {
        return <span>{`${item.createdBy.name} ${item.createdBy.surname}`}</span>
      },
      isPadded: true,
      columnActionsMode: ColumnActionsMode.disabled,
    },
    {
      key: 'created_at',
      name: t('API_KEYS.TABLE.CREATED_AT'),
      minWidth: 100,
      isRowHeader: true,
      onRender: (item: ApiKey) => {
        return <span>{dateFormatter(t, item.created_at)}</span>
      },
      isPadded: true,
      columnActionsMode: ColumnActionsMode.disabled,
    },
    {
      key: 'lastUsedAt',
      name: t('API_KEYS.TABLE.LAST_USED_AT'),
      minWidth: 100,
      isRowHeader: true,
      onRender: (item: ApiKey) => {
        return <span>{item.lastUsedAt && dateFormatter(t, item.lastUsedAt)}</span>
      },
      isPadded: true,
      columnActionsMode: ColumnActionsMode.disabled,
    },
    {
      key: 'expiresAt',
      name: t('API_KEYS.TABLE.EXPIRES_AT'),
      minWidth: 100,
      isRowHeader: true,
      onRender: (item: ApiKey) => {
        return <span>{dateFormatter(t, item.expiresAt)}</span>
      },
      isPadded: true,
      columnActionsMode: ColumnActionsMode.disabled,
    },
    {
      key: 'revokedAt',
      name: t('API_KEYS.TABLE.REVOKED_AT'),
      minWidth: 100,
      isRowHeader: true,
      onRender: (item: ApiKey) => {
        return <span>{item.revokedAt && dateFormatter(t, item.revokedAt)}</span>
      },
      isPadded: true,
      columnActionsMode: ColumnActionsMode.disabled,
    },
    ...(canSeeActions
      ? [
          {
            key: 'actions',
            name: t('API_KEYS.TABLE.ACTION'),
            minWidth: isMobile ? 20 : 80,
            onRender: (item: ApiKey) => {
              if (!isKeyActive(item)) {
                return <></>
              }
              return (
                <TableActionButton
                  isMobile={isMobile}
                  onClick={() => openApiKeyRevocationDialog(item)}
                  text={t('API_KEYS.TABLE.REVOKE')}
                  iconName="Blocked"
                />
              )
            },
            isPadded: true,
            columnActionsMode: ColumnActionsMode.disabled,
          },
        ]
      : []),
  ]

  const changeFilter = (item?: PivotItem) => {
    setTabQueryParameter(item?.props.itemKey)
  }

  const renderFeature = () => {
    return (
      <>
        <Stack horizontal={!isMobile} horizontalAlign={'end'} tokens={{ padding: '20px 0 0 0' }} className="mb-sm-2">
          <Stack.Item>
            <div
              className={`d-flex d-flexRow d-flexAlignItemsCenter ${isMobile ? 'd-flexJustifyBetween' : ''} `}
              style={isMobile ? { marginTop: 15 } : {}}
            >
              <>
                <Pivot selectedKey={tabQueryParameter} onLinkClick={changeFilter} headersOnly={true}>
                  <PivotItem itemKey={PIVOT_ITEM_KEY_ALL} headerText={t('API_KEYS.PIVOT.ALL')} />
                  <PivotItem itemKey={PIVOT_ITEM_KEY_ACTIVE} headerText={t('API_KEYS.PIVOT.ACTIVE')} />
                  <PivotItem itemKey={PIVOT_ITEM_KEY_INACTIVE} headerText={t('API_KEYS.PIVOT.INACTIVE')} />
                </Pivot>
                <CreateButton locale={i18n.language} onClick={openGenerateApiKey} />
              </>
            </div>
          </Stack.Item>
        </Stack>
        <Table
          tableId="api-keys"
          isEmpty={!listItems || listItems.length === 0}
          emptyMessage={t('general:EMPTY_FILTER')}
          tableProps={{
            columns,
            items: listItems || [],
            compact: false,
            selectionMode: SelectionMode.none,
            layoutMode: DetailsListLayoutMode.justified,
            isHeaderVisible: true,
          }}
        />
        {renderApiKeyRevocationDialog()}
      </>
    )
  }

  const renderEmptyStatus = () => {
    return (
      <div style={{ marginTop: 100 }}>
        <EmptyStatus
          message={t('API_KEYS.EMPTY_MESSAGE')}
          img={<Icon iconName="AzureKeyVault" style={{ fontSize: 68 }} />}
          {...(checkFeature(FEATURE_NAMES.INTEGRATIONS_CREATE) && {
            buttonProps: {
              text: t('general:BUTTON.CREATE'),
              iconProps: { iconName: 'Add' },
              onClick: openGenerateApiKey,
            },
          })}
        />
      </div>
    )
  }

  if (data && data.length > 0) {
    return renderFeature()
  }
  if (data && data.length === 0) {
    return renderEmptyStatus()
  }

  return <></>
}
