import { TFunction } from 'react-i18next'

export enum ValueType {
  string = 'string',
  int = 'int',
  double = 'double',
  id = 'id',
  bool = 'bool',
  cron = 'cron',
}

export const valueTypeLabel = (t: TFunction): { [key in ValueType]: string } => ({
  [ValueType.string]: t('flows:VALUE_TYPE.STRING'),
  [ValueType.int]: t('flows:VALUE_TYPE.INT'),
  [ValueType.double]: t('flows:VALUE_TYPE.DOUBLE'),
  [ValueType.id]: t('flows:VALUE_TYPE.ID'),
  [ValueType.bool]: t('flows:VALUE_TYPE.BOOL'),
  [ValueType.cron]: t('flows:VALUE_TYPE.CRON')
})

export const valueTypeOptions = (t: TFunction) =>
  Object.keys(ValueType).map((key) => ({
    key,
    text: valueTypeLabel(t)[key as ValueType],
  }))
