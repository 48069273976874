import { useQuery } from '@tanstack/react-query'
import graphClient from 'app/query-client/graphClient'
import { PaginationArgs, PaginationResponse, Workspace } from 'app/query-client/types'
import { gql } from 'graphql-request'

const query = gql`
  query ($flowId: ID!, $perPage: Int!, $page: Int!) {
    getWorkspacesByFlowId(flowId: $flowId, perPage: $perPage, page: $page) {
      workspaces {
        _id
        name
        fqdn
        company
        sysDomain
        sysId
        uLastUser
        uOperativeSystem
        uRelatedEnvironment
        uHostname
        ipAddress
        uCoresCpu
        uRam
        uType
      }
      pageInfo {
        hasNextPage
        hasPreviousPage
        totalPages
        currentPage
        total
      }
    }
  }
`

function getWorkspacesByFlowId(
  flowId: string,
  pagination: PaginationArgs
): Promise<PaginationResponse<Workspace>> {
  return graphClient
    .request(query, {
      flowId,
      page: pagination.page,
      perPage: pagination.perPage,
    })
    .then((response) => {
      return {
        results: response.getWorkspacesByFlowId.workspaces,
        pageInfo: response.getWorkspacesByFlowId.pageInfo,
      }
    })
}

export default function (flowId: string, pagination: PaginationArgs, enabled: boolean = true) {
  return useQuery(
    ['getWorkspacesByFlowId', flowId, pagination],
    () => getWorkspacesByFlowId(flowId, pagination),
    {
      refetchOnWindowFocus: false,
      keepPreviousData: true,
      enabled,
    }
  )
}
