import { gql } from 'graphql-request'
import { getPaginatedQueryParamsBlock, pageInfoBlock, paginatedVarsBlock } from '../../../config/pagination'

export const usePaginatedInstalledAppsQuery = () => {
  const queryName = 'getInstalledApps'
  const query = gql`
    query Request(
      $businessId: ID!
      ${getPaginatedQueryParamsBlock('InstalledAppFiltersGraphql', 'InstalledAppSortingGraphql')}
    ) {
      getInstalledApps(
        organizationId: $businessId
        ${paginatedVarsBlock}
      ) {
        data {
          id
          organizationId
          name
          discoveredAt
          reportedAt
          operatingSystem
          publisher
        }
        ${pageInfoBlock}
      }
    }
  `

  return {
    query,
    queryName,
  }
}
