import { TooltipDelay, TooltipHost, TooltipOverflowMode } from "@fluentui/react"
import IconLanguage from "app/components/IconLanguage/IconLanguage"
import { WarnIcon } from "app/components/Icons"
import { getTranslation } from "app/models/microservices"
import { DateFormat, dateFormatter } from "app/services/date-formatter"
import i18next, { TFunction } from "i18next"
import { PaginatedEnabledMicroservicePageViewData } from "../PaginatedEnabledMicroservicePageViewData"
import { CellWithGreenLink } from "app/components/table-page-body/CellWithGreenLink"

export const renderMicroserviceName = (t: TFunction, item: PaginatedEnabledMicroservicePageViewData, handleOnClickViewDetails: (item: PaginatedEnabledMicroservicePageViewData) => void) => {

  const name = getTranslation(item.microserviceName, i18next.language)

  const itemName = name.text
  const showIcon = name.translatedByIA

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'flex-start',
      }}
    >
      {item.gracePeriod && (
        <TooltipHost
          content={t('DETAILS.GRACE_PERIOD', { date: dateFormatter(t, item.gracePeriod, DateFormat.DATETIME) })}
          delay={TooltipDelay.zero}
        >
          <WarnIcon />
        </TooltipHost>
      )}
      <div
        style={{
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        }}
      >
        <TooltipHost
          content={itemName?.toString()}
          delay={TooltipDelay.zero}
          overflowMode={TooltipOverflowMode.Parent}
        >
          <CellWithGreenLink callback={() => handleOnClickViewDetails(item)}>
            {itemName?.toString()} {showIcon && <IconLanguage tam="16" />}
          </CellWithGreenLink>
        </TooltipHost>
      </div>
    </div>
  )
}