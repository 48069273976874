import { ComboBox as CustomComboBox, IComboBoxProps, IComboBoxStyles, IOnRenderComboBoxLabelProps, IRenderFunction } from '@fluentui/react'
import { useFormContext, useFormState } from 'react-hook-form'
import { TFunction } from 'react-i18next'
import Controlled from '../Controlled'
  
export interface CustomComboBoxProps {
    name: string;
    comboBoxProps: IComboBoxProps;
    translator: TFunction;
    required?: boolean;
    onChange?: any;
    styles?: Partial<IComboBoxStyles>;
  }
  
  export const defaultComboBoxStyles: Partial<IComboBoxStyles> = {

  };

  const ComboBox = ({
    name,
    comboBoxProps,
    translator,
    required = false,
    onChange,
  }: CustomComboBoxProps) => {
    const { control } = useFormContext()
    const { errors } = useFormState({ control })
    const errorMessageKey: any = errors[name]?.message
  
    const onWrapDefaultLabelRenderer = (
        props?: IOnRenderComboBoxLabelProps,
        defaultRender?: IRenderFunction<IOnRenderComboBoxLabelProps>
      ) => {
        return defaultRender ? (
          <span className={required ? 'required' : ''}>{defaultRender(props)}</span>
        ) : null;
      }
  
    return (
      <>
         <Controlled
            name={name}
            render={({ field }) => (
            <CustomComboBox
                {...field}
                {...comboBoxProps}
                styles={comboBoxProps.styles ?? defaultComboBoxStyles}
                autoComplete="on"
                allowFreeform
                onRenderLabel={onWrapDefaultLabelRenderer}
                onChange={(e: any, o: any) => {
                    if (o) {
                        onChange ? onChange(o) : null;
                        field.onChange(o.key);
                    }
                }}
                errorMessage={
                !!errors[name]
                    ? translator(`ERRORS.${name}_${errorMessageKey.toLowerCase()}`)
                    : ''
                }
            />
            )}
        />
      </>
    )
  }
  
  export default ComboBox
  