import {
  IBasePicker,
  IBasePickerSuggestionsProps,
  IPeoplePickerProps,
  IPersonaProps,
  Label,
  NormalPeoplePicker as PeoplePicker,
  ValidationState,
  mergeStyles
} from '@fluentui/react'
import { useEffect, useRef, useState } from 'react'
import { TFunction } from 'react-i18next'
import Controlled from '../Controlled'

function doesTextStartWith(text: string, filterText: string): boolean {
  return text.toLowerCase().indexOf(filterText.toLowerCase()) === 0
}
function removeDuplicates(personas: IPersonaProps[], possibleDupes: IPersonaProps[]) {
  return personas.filter((persona) => !listContainsPersona(persona, possibleDupes))
}
function listContainsPersona(persona: IPersonaProps, personas: IPersonaProps[]) {
  if (!personas || !personas.length || personas.length === 0) {
    return false
  }
  return personas.filter((item) => item.key === persona.key).length > 0
}
function getTextFromItem(persona: IPersonaProps): string {
  return persona.text as string
}

function validateInput(input: string): ValidationState {
  if (input.indexOf('@') !== -1) {
    return ValidationState.valid
  } else if (input.length > 1) {
    return ValidationState.warning
  } else {
    return ValidationState.invalid
  }
}

interface Props {
  name: string
  options: IPersonaProps[]
  required?: boolean
  translator: TFunction
  label: string
  placeholder: string
  peoplePickerProps: Partial<IPeoplePickerProps>
  selectedList?: string[]
}

export const CompactPeoplePicker = ({
  name,
  options,
  required,
  translator: t,
  peoplePickerProps,
  label,
  placeholder,
  selectedList,
  ...props
}: Props) => {
  const [peopleList, setPeopleList] = useState<IPersonaProps[]>(options)
  const picker = useRef<IBasePicker<IPersonaProps>>(null)
  const [selectedKeys, setSelectedKeys] = useState<string[]>(selectedList || [])

  useEffect(() => {
    setPeopleList(options)
  }, [options])

  useEffect(() => {
    setSelectedKeys(selectedList ?? [])
  }, [selectedList])

  const returnMostRecentlyUsed = (currentPersonas?: IPersonaProps[]): IPersonaProps[] => {
    return removeDuplicates(peopleList, currentPersonas ?? [])
  }

  const filterPersonasByText = (filterText: string): IPersonaProps[] => {
    return peopleList.filter(
      (item) =>
        doesTextStartWith(item.text as string, filterText) || doesTextStartWith(item.secondaryText ?? '', filterText)
    )
  }

  const onFilterChanged = (
    filterText: string,
    currentPersonas: IPersonaProps[] | undefined
  ): IPersonaProps[] | Promise<IPersonaProps[]> => {
    if (filterText) {
      let filteredPersonas: IPersonaProps[] = filterPersonasByText(filterText)

      filteredPersonas = removeDuplicates(filteredPersonas, currentPersonas ?? [])
      return filteredPersonas
    } else {
      return []
    }
  }

  const customSuggestionsItemStyles = mergeStyles({
    '.ms-Persona': {
      width: '98%',
    }
  });

  const suggestionProps: IBasePickerSuggestionsProps = {
    noResultsFoundText: t('general:NO_RESULTS'),
    loadingText: t('general:LOADING'),
    showRemoveButtons: false,
    suggestionsItemClassName: customSuggestionsItemStyles,
  }

  const _onChange = (selection: IPersonaProps[], field: any): void => {
    field.onChange(selection.map((e) => e.key))
    setSelectedKeys(selection.map((e) => e.key as string))
  }
  return (
    <Controlled
      name={name}
      render={({ field }) => (
        <>
          <Label required={required}>{label}</Label>
          <PeoplePicker
            {...field}
            {...peoplePickerProps}
            onResolveSuggestions={onFilterChanged}
            onEmptyResolveSuggestions={returnMostRecentlyUsed}
            getTextFromItem={getTextFromItem}
            pickerSuggestionsProps={suggestionProps}
            className={'ms-PeoplePicker'}
            selectedItems={peopleList.filter((item) => selectedKeys.includes(item.key as string))}
            // eslint-disable-next-line react/jsx-no-bind
            //        onRemoveSuggestion={onRemoveSuggestion}
            onValidateInput={validateInput}
            inputProps={{
              'aria-label': label,
              placeholder: field.value?.length > 0 ? '' : placeholder,
              autoComplete: 'off',
            }}
            componentRef={picker}
            styles={{ root: { backgroundColor: 'white' }}}
            onChange={(items?: IPersonaProps[]) => {
              _onChange(items ?? [], field)
            }}
            resolveDelay={100}
          />
        </>
      )}
    />
  )
}
