import {
  DashboardCardAction,
  DashboardMetricCard,
} from '../../../../../../components/Dashboard/DashboardMetricCard/DashboardMetricCard'
import { useTranslation } from 'react-i18next'
import { usePatchManagementSummaryData } from '../../usePatchManagementSummaryData'
import { useNavigate, useParams } from 'react-router-dom'
import { PatchPolicyTarget, Time } from '../../../../../../query-client/types'

interface Props {
  isInfo?: boolean
}

export const AllTargetsMustHavePatchPolicyRuleCard = (props: Props) => {
  const { t } = useTranslation('patch_management')
  const { data: patchSummary } = usePatchManagementSummaryData()
  const { organizationId } = useParams()
  const totalTargets = patchSummary?.targets.length || 0
  const totalTargetsWithoutPatchPolicy: Partial<PatchPolicyTarget>[] =
    patchSummary?.targets.filter((target) => target.windowsPatchPolicyId === null) || []

  const navigate = useNavigate()

  const action = (): DashboardCardAction | undefined => {
    return {
      onClick: () => {
        navigate(`/organization/${organizationId}/patch-management/patch-policy-targets`)
      },
      label: 'Fix',
    }
  }

  const calculatePercentage = (count: number, reportingGroupsCount: number) => {
    return (count / reportingGroupsCount) * 100
  }

  return (
    <DashboardMetricCard
      title={'Targets without Patch Policy'}
      subtitle={`${totalTargetsWithoutPatchPolicy.length} targets`}
      icon={{
        name: props.isInfo ? 'CheckMark' : 'Warning',
        color: props.isInfo ? 'green' : 'orange',
      }}
      unitLabel={'%'}
      value={calculatePercentage(totalTargetsWithoutPatchPolicy.length, totalTargets).toFixed()}
      action={props.isInfo ? undefined : action()}
    />
  )
}
