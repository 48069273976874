import { MessageBar, Spinner } from '@fluentui/react'
import { useBoolean } from '@fluentui/react-hooks'
import { PrimaryButton } from '@fluentui/react/lib/Button'
import { ConfirmationDialog } from 'app/components/ConfirmationDialog/ConfirmationDialog'
import Dialog from 'app/components/Dialog/Dialog'
import { DisplayField } from 'app/components/DisplayField'
import IconLanguage from 'app/components/IconLanguage/IconLanguage'
import { LicenseType, licenses } from 'app/config/licenses'
import {
  MicroserviceAction,
  contextLabel,
  createMultiLanguageFields,
  getTranslation,
  languageLabel,
  operatingSystemLabel,
  scopeLabel,
} from 'app/models/microservices'
import { DateFormat, dateFormatter } from 'app/services/date-formatter'
import i18next from 'i18next'
import { Suspense, lazy } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import AuthorIcon from '../../../../assets/img/AuthorIcon.svg'
import EfficiencyIcon from '../../../../assets/img/Efficiency.svg'
import LawIcon from '../../../../assets/img/Law.svg'
import BoxIcon from '../../../../assets/img/box.svg'
import DataTableIcon from '../../../../assets/img/data-table-svgrepo-com.svg'
import FlexxibleCard from '../../../../components/FlexxibleCard/FlexxibleCard.component'
import Col from '../../../../components/Layouts/Col'
import Row from '../../../../components/Layouts/Row'
import { MOBILE_QUERY, TABLET_QUERY } from '../../../../config/media-query'
import { useFeedbackMessage } from '../../../../context/feedback-message/FeedbackMessageContext'
import { useOrganizationSelection } from '../../../../globals/useOrganizationSelection'
import { useActivateMicroservice } from '../../../../hooks/microservices/useActivateMicroservice'
import { useDeactivateMicroservice } from '../../../../hooks/microservices/useDeactivateMicroservice'
import { EnabledMicroserviceView } from '../../../../hooks/microservices/useGetEnabledMicroserviceById'
import useMediaQuery from '../../../../hooks/utils/useMediaQuery'
import { GettingStartedChecklistTasks } from '../../../../user-journey/getting-started-checklist/GettingStartedChecklistTasksEnum'
import { useGettingStartedChecklist } from '../../../../user-journey/getting-started-checklist/useGettingStartedChecklist'
import SummaryCard from '../../../organization-details/components/SummaryCard/SummaryCard'
import { MicroserviceDetailsReadme } from './MicroserviceDetailsReadme'

const BootstrapIcon = lazy(() => import('app/components/BootstrapIcon/BootstrapIcon'))

interface MicroserviceReadmeProps {
  microservice: EnabledMicroserviceView
}

export const EnabledMicroserviceOverview = ({ microservice }: MicroserviceReadmeProps) => {
  const [isMobile] = useMediaQuery(MOBILE_QUERY)
  const [isTablet] = useMediaQuery(TABLET_QUERY)
  const { t } = useTranslation('microservices')
  const navigate = useNavigate()
  const { selectedOrganizationId } = useOrganizationSelection()
  const activateMicroservice = useActivateMicroservice(selectedOrganizationId || '')
  const deactivateMicroservice = useDeactivateMicroservice(selectedOrganizationId || '')
  const [hideActivateDialog, { toggle: toggleHideActivateDialog }] = useBoolean(true)
  const [hideDeactivateDialog, { toggle: toggleHideDeactivateDialog }] = useBoolean(true)
  const { setSuccessMessage, setErrorMessage } = useFeedbackMessage()
  const { completeGettingStartedTask } = useGettingStartedChecklist()

  const buildAuthorName = (author: { name: string; surname: string } | undefined) => {
    if (!author) {
      return t('DETAILS.OVERVIEW.NO_AUTHOR')
    }
    return `${author.name} ${author.surname}`
  }

  const handleActivate = () => {
    completeGettingStartedTask(GettingStartedChecklistTasks.ENABLE_MICROSERVICE)
    activateMicroservice
      .mutateAsync(microservice._id)
      .then(() => setSuccessMessage(t('TOAST.MICROSERVICE_ACTIVATED')))
      .catch(() => setErrorMessage(t('TOAST.MICROSERVICE_ACTIVATED_ERROR')))
  }
  const renderActivateConfirmation = (): JSX.Element => {
    return (
      <Dialog
        title={t('DETAILS.OVERVIEW.ACTIVATE_DIALOG.TITLE')}
        description={t('DETAILS.OVERVIEW.ACTIVATE_DIALOG.DESCRIPTION')}
        actionButton={t('DETAILS.OVERVIEW.ACTIVATE_DIALOG.BUTTON_ACCEPT')}
        dismissButton={t('DETAILS.OVERVIEW.ACTIVATE_DIALOG.BUTTON_CANCEL')}
        hidden={hideActivateDialog}
        onDismiss={toggleHideActivateDialog}
        callback={() => {
          toggleHideActivateDialog()
          handleActivate()
        }}
      >
        <MessageBar
          messageBarType={0}
          isMultiline={true}
          dismissButtonAriaLabel={t('general:CLOSE_LABEL')}
          className="mb-2"
          style={{ width: '100%' }}
        >
          <Trans i18nKey="DETAILS.OVERVIEW.ACTIVATE_DIALOG.MESSAGE" t={t}>
            By enabling this microservice, you agree to the
            <a href={`/microservices/${microservice._id}?tab=license`} target="_blank" rel="noopener noreferrer">
              License
            </a>
            .
          </Trans>
        </MessageBar>
      </Dialog>
    )
  }

  const handleDeactivate = () => {
    deactivateMicroservice
      .mutateAsync(microservice._id)
      .then(() => setSuccessMessage(t('TOAST.MICROSERVICE_DEACTIVATED')))
      .then(() => navigate(`/microservices/${microservice._id}`))
      .catch(() => setErrorMessage(t('TOAST.MICROSERVICE_DEACTIVATED_ERROR')))
  }

  const renderDeactivateConfirmation = (): JSX.Element => {
    const name = getTranslation(microservice.name, i18next.language)
    const itemName = name.text

    return (
      <ConfirmationDialog
        title={t('DETAILS.OVERVIEW.DEACTIVATE_DIALOG.TITLE')}
        description={t('DETAILS.OVERVIEW.DEACTIVATE_DIALOG.DESCRIPTION')}
        textRequired={itemName}
        actionButton={t('DETAILS.OVERVIEW.DEACTIVATE_DIALOG.BUTTON_ACCEPT')}
        dismissButton={t('DETAILS.OVERVIEW.DEACTIVATE_DIALOG.BUTTON_CANCEL')}
        hidden={hideDeactivateDialog}
        onDismiss={toggleHideDeactivateDialog}
        callback={() => {
          toggleHideDeactivateDialog()
          handleDeactivate()
        }}
      />
    )
  }

  const renderGoToDesigner = () => {
    const designerPermissions = [
      MicroserviceAction.EDIT_SCRIPT,
      MicroserviceAction.SET_PRIVACY,
      MicroserviceAction.EDIT_DEFAULT_TARGETS,
      MicroserviceAction.EDIT,
    ]

    if (!designerPermissions.some((permission) => microservice.permissions.includes(permission))) {
      return null
    }

    return (
      <PrimaryButton onClick={() => navigate(`/microservices-designer/${microservice._id}`)}>
        {t('MARKETPLACE.ACTION.GO_TO_DESIGNER')}
      </PrimaryButton>
    )
  }

  const renderEnableButton = () =>
    microservice.permissions.includes(MicroserviceAction.ENABLE) ? (
      <PrimaryButton onClick={toggleHideActivateDialog}>{t('MARKETPLACE.ACTION.ACTIVATE')}</PrimaryButton>
    ) : null

  const renderDisableButton = () =>
    microservice.permissions.includes(MicroserviceAction.DISABLE) ? (
      <PrimaryButton onClick={toggleHideDeactivateDialog}>{t('MARKETPLACE.ACTION.DEACTIVATE')}</PrimaryButton>
    ) : null

  const renderCloneButton = () =>
    microservice.permissions.includes(MicroserviceAction.CLONE) ? (
      <PrimaryButton onClick={onClone}>{t('MARKETPLACE.ACTION.CLONE')}</PrimaryButton>
    ) : null

  const onClone = () => {
    const name = createMultiLanguageFields()
    navigate(`/microservices-designer/create`, {
      state: {
        microservice: {
          name: name,
          description: microservice.description,
          iconName: microservice.iconName,
          iconBackground: microservice.iconBackground,
          readme: microservice.readme,
          script: microservice.script,
          language: microservice.language,
          operatingSystem: microservice.operatingSystem,
          scope: microservice.scope,
          licenseType: microservice.license.type,
          context: microservice.context,
          category: microservice.category,
          efficiency: microservice.efficiency,
        },
      },
    })
  }

  const description = getTranslation(microservice.description, i18next.language)
  const itemDescription = description.text
  const showDescriptionIcon = description.translatedByIA

  const category = getTranslation(microservice.category ?? [], i18next.language)
  const itemCategory = category.text
  const showCategoryIcon = category.translatedByIA

  return (
    <div className="d-flex d-flexCol col-xs-12" style={{ marginTop: '-38px' }}>
      {renderActivateConfirmation()}
      {renderDeactivateConfirmation()}
      <span className="d-flex d-flexJustifyEnd" style={{ height: 32, gap: 10 }}>
        {renderGoToDesigner()}

        {renderCloneButton()}

        {microservice.active ? renderDisableButton() : renderEnableButton()}
      </span>
      <div
        className={isMobile || isTablet ? 'd-flex d-flexCol d-flexColReverse' : ''}
        style={isMobile || isTablet ? {} : { display: 'grid', gridTemplateColumns: '2fr 1fr', gap: '1em' }}
      >
        <div>
          <FlexxibleCard
            cardProps={{
              style: { marginTop: 36 },
            }}
            styleNames="d-flex d-flexCol d-flexJustifyBetween"
          >
            <Row>
              <Col className="col-xs-12 col-lg-6">
                <DisplayField label={t('DETAILS.OVERVIEW.DESCRIPTION')}>
                  {itemDescription} {showDescriptionIcon && <IconLanguage tam="16" />}
                </DisplayField>
              </Col>
              <Col className="col-xs-12 col-lg-6">
                <DisplayField label={t('DETAILS.OVERVIEW.ICON')}>
                  {microservice.iconName && (
                    <Suspense fallback={<Spinner />}>
                      <BootstrapIcon
                        iconName={microservice.iconName}
                        size={24}
                        style={microservice.iconBackground ? { color: microservice.iconBackground } : {}}
                      />
                    </Suspense>
                  )}
                </DisplayField>
              </Col>
            </Row>
            <Row>
              <Col className="col-xs-12 col-lg-6">
                <DisplayField label={t('DETAILS.OVERVIEW.LANGUAGE')}>
                  {microservice.language ? languageLabel(t)[microservice.language] : ''}
                </DisplayField>
              </Col>
              <Col className="col-xs-12 col-lg-6">
                <DisplayField label={t('DETAILS.OVERVIEW.VERSION')}>{microservice.version}</DisplayField>
              </Col>
            </Row>
            <Row>
              <Col className="col-xs-12 col-lg-6">
                <DisplayField label={t('DETAILS.OVERVIEW.SCOPE')}>
                  {microservice.scope?.map((scope) => scopeLabel(t)[scope]).join(', ') || ''}
                </DisplayField>
              </Col>
              {microservice.context && (
                <Col className="col-xs-12 col-lg-6">
                  <DisplayField label={t('DETAILS.OVERVIEW.CONTEXT')}>
                    {contextLabel(t)[microservice.context]}
                  </DisplayField>
                </Col>
              )}
              <Col className="col-xs-12 col-lg-6">
                <DisplayField label={t('DETAILS.OVERVIEW.OPERATING_SYSTEM')}>
                  {microservice.operatingSystem?.map((scope) => operatingSystemLabel(t)[scope]).join(', ') || ''}
                </DisplayField>
              </Col>
              {microservice.category ? (
                <Col className="col-xs-12 col-lg-6">
                  <DisplayField label={t('DETAILS.OVERVIEW.CATEGORY')}>
                    {itemCategory} {showCategoryIcon && <IconLanguage tam="16" />}
                  </DisplayField>
                </Col>
              ) : null}
            </Row>
          </FlexxibleCard>
          {microservice.readme && microservice.readme.trim() !== '' && (
            <MicroserviceDetailsReadme microservice={microservice} canEdit={false} />
          )}
        </div>
        <div className="summary-container d-flexJustifyCenter d-flexAlignItemsCenter" style={{ marginTop: 6 }}>
          <div
            className={isMobile || isTablet ? 'd-flex d-flexRow d-flexWrap' : 'd-flex d-flexCol '}
            style={{ gap: 20 }}
          >
            <SummaryCard
              Icon={AuthorIcon}
              title={t('DETAILS.OVERVIEW.AUTHOR')}
              text={buildAuthorName(microservice.author)}
            />
            <SummaryCard
              Icon={DataTableIcon}
              title={t('DETAILS.OVERVIEW.PUBLISHED')}
              text={dateFormatter(t, microservice.created_at, DateFormat.Short)}
            />
            <SummaryCard
              Icon={LawIcon}
              title={t('DETAILS.OVERVIEW.LICENSE')}
              text={licenses[microservice.license.type as LicenseType]?.title}
            />
            <SummaryCard
              Icon={BoxIcon}
              title={t('DETAILS.OVERVIEW.CATEGORY')}
              text={itemCategory || t('DETAILS.OVERVIEW.NO_CATEGORY')}
            />
            <SummaryCard
              Icon={EfficiencyIcon}
              title={t('DETAILS.OVERVIEW.EFFICIENCY')}
              text={t('DETAILS.OVERVIEW.EFFICIENCY_VALUE', { value: microservice.efficiency })}
            />
          </div>
        </div>
      </div>
    </div>
  )
}
