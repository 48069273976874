import { SummaryRule } from '../../usePatchManagementRuleEngine'
import { PatchSummaryRuleSentences, SummarySectionType, SummaryType } from '../../types'
import { PatchSummary } from '../../../entities/PatchSummary'
import { AllWorkspacesMustHaveReportingGroupCard } from './AllWorkspacesMustHaveReportingGroupCard'

export const AllWorkspacesMustHaveReportingGroupRule: SummaryRule = {
  rule: PatchSummaryRuleSentences.ALL_WORKSPACES_MUST_HAVE_REPORTING_GROUP,
  alertSummaryItem: {
    card: <AllWorkspacesMustHaveReportingGroupCard />,
    type: SummaryType.Alert,
    section: SummarySectionType.Workspaces,
  },
  infoSummaryItem: {
    type: SummaryType.Information,
    section: SummarySectionType.Workspaces,
    card: <AllWorkspacesMustHaveReportingGroupCard isInfo />,
  },
  passRule: (patchSummary: PatchSummary) => {
    return patchSummary.workspacesCount.totalWorkspacesWithoutReportingGroup === 0
  },
}
