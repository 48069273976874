import { RoleNames, Roles } from 'app/permissions/RoleFeature.enum'
import { AnalyzerPermissionNames } from 'app/permissions/UserAnalyzerPermission.enum'
import { WorkspacePermissionNames, WorkspacePermissions } from 'app/permissions/UserWorkspacePermission.enum'
import { AnalyzerPermission, Role, WorkspacePermission } from 'app/query-client/types'

export interface PermissionsOnOrganization {
  portal: Role
  workspaces: WorkspacePermission
  analyzer: AnalyzerPermission
}

export class RolesOnBusiness {
  private constructor(
    private _portal: Role,
    private _workspaces: WorkspacePermission,
    private _analyzer: AnalyzerPermission
  ) {}

  public static LowestPermission(): RolesOnBusiness {
    return new RolesOnBusiness(RoleNames[2], WorkspacePermissionNames[0], AnalyzerPermissionNames[1])
  }

  public static fromPrimitives({ portal, workspaces, analyzer }: PermissionsOnOrganization): RolesOnBusiness {
    return new RolesOnBusiness(portal, workspaces, analyzer)
  }

  get portal(): Role {
    return this._portal
  }

  get workspaces(): WorkspacePermission {
    return this._workspaces
  }

  get analyzer(): AnalyzerPermission {
    return this._analyzer
  }

  public isPortalAdmin(): boolean {
    return this._portal === Roles.ADMIN
  }

  public isOrganizationAdmin(): boolean {
    return this._portal === Roles.ORGANIZATION_ADMIN
  }

  public isPortalUser(): boolean {
    return this._portal === Roles.USER
  }

  public isOrganizationAdminOrHigher(): boolean {
    return this._portal === Roles.ADMIN || this._portal === Roles.ORGANIZATION_ADMIN
  }

  public hasL2OrHigher(): boolean {
    return [
      WorkspacePermissions.ADMIN,
      WorkspacePermissions.L3_ENGINEERING_TEAM,
      WorkspacePermissions.L2_SUPPORT_TEAM,
    ].includes(this._workspaces)
  }

  public hasL2OReadHigher(): boolean {
    return [
      WorkspacePermissions.ADMIN,
      WorkspacePermissions.ADMIN_READ_ONLY,
      WorkspacePermissions.L3_ENGINEERING_TEAM,
      WorkspacePermissions.L3_ENGINEERING_TEAM_READ_ONLY,
      WorkspacePermissions.L2_SUPPORT_TEAM,
      WorkspacePermissions.L2_SUPPORT_TEAM_READ_ONLY,
    ].includes(this._workspaces)
  }

  public hasL1ReadHigher(): boolean {
    return [
      WorkspacePermissions.ADMIN,
      WorkspacePermissions.ADMIN_READ_ONLY,
      WorkspacePermissions.L3_ENGINEERING_TEAM,
      WorkspacePermissions.L3_ENGINEERING_TEAM_READ_ONLY,
      WorkspacePermissions.L2_SUPPORT_TEAM,
      WorkspacePermissions.L2_SUPPORT_TEAM_READ_ONLY,
      WorkspacePermissions.L1_SUPPORT_TEAM,
      WorkspacePermissions.L1_SUPPORT_TEAM_READ_ONLY,
    ].includes(this._workspaces)
  }

  public toPrimitives(): PermissionsOnOrganization {
    return {
      portal: this._portal,
      workspaces: this._workspaces,
      analyzer: this._analyzer,
    }
  }
}
