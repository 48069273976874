import useCreateForm from 'app/hooks/useCreateForm';
import { TFunction } from 'i18next';

import { ICommandItem } from '@flexxibleit/flexxible-ui';
import WorkspaceGroupForm from './WorkspaceGroupForm';

type UseWorkspaceGroupsActions = {
  t: TFunction,
  organizationId: string,
  onExportClicked: () => void,
}

export const useWorkspaceGroupsActions = ({ t, organizationId, onExportClicked }: UseWorkspaceGroupsActions) => {

  const onAddWorkspaceGroupClicked = useCreateForm(
    {
      title: t('WORKSPACE_GROUPS.FORM.ADD_TITLE'),
      isFooterAtBottom: true,
    },
    <WorkspaceGroupForm {...{ organizationId }} />
  )

  const commandBarActions: ICommandItem[] = [
    {
      key: 'create',
      text: t('general:BUTTON.CREATE'),
      iconProps: { iconName: 'Add' },
      onClick: onAddWorkspaceGroupClicked,
    },
    {
      key: 'export',
      text: t('general:BUTTON.EXPORT'),
      iconProps: { iconName: 'ExcelDocument' },
      onClick: onExportClicked,
    },
  ]

  return {
    onAddWorkspaceGroupClicked,
    commandBarActions
  }
}