import {
  Link,
  MessageBar,
  MessageBarButton,
  MessageBarType,
} from '@fluentui/react'
import { useTranslation } from 'react-i18next'
import { Business } from '../../../query-client/types'
import { useFeatureRender } from '../../../permissions/useFeatureRender'
import { FEATURE_NAMES } from '../../../permissions/FeatureName.enum'
import { PRODUCT_ENV } from 'app/config/Consts'
import { ProductEnvTypes } from 'app/config/productEnv.enum'

export const useConfigurationRequiredPanel = (organization: Business) => {
  const { checkFeature } = useFeatureRender()
  const { t } = useTranslation('organization_details')
  const isCompanyIdSet = !!organization?.serviceNowCompanyId
  const isRelatedEnvironmentIdSetOnSomeProduct =
    !!organization?.productConfigs?.some((product) => {
      return product.serviceNowRelatedEnvironment?.length > 0
    })
  const isCompanyAndRelatedEnvironmentIdSet = (): boolean => {
    if (PRODUCT_ENV === ProductEnvTypes.FXXONE) {
      return true
    }

    return isCompanyIdSet && isRelatedEnvironmentIdSetOnSomeProduct
  }

  const goToSettingsButton = () => {
    if (checkFeature(FEATURE_NAMES.ORGANIZATION_READ)) return

    return (
      <div>
        <Link
          href={`/organizations/${organization._id}/`}
          target="_blank"
          rel="noopener noreferrer"
        >
          <MessageBarButton iconProps={{ iconName: 'Settings' }}>
            <b>Go to settings</b>
          </MessageBarButton>
        </Link>
      </div>
    )
  }

  const getNoDataWarningMessage = () => {
    switch (true) {
      case !isCompanyIdSet && isRelatedEnvironmentIdSetOnSomeProduct:
        return t('WORKSPACE_GROUPS.WORKSPACE_MISSING_COMPANY')
      case isCompanyIdSet && !isRelatedEnvironmentIdSetOnSomeProduct:
        return t('WORKSPACE_GROUPS.WORKSPACE_MISSING_RELATED_ENV')
      case !isCompanyIdSet && !isRelatedEnvironmentIdSetOnSomeProduct:
        return t('WORKSPACE_GROUPS.WORKSPACE_MISSING_COMPANY_AND_RELATED_ENV')
      default:
        return 'No data available.'
    }
  }

  const renderConfigurationRequiredPanel = () => {
    return (
      <>
        {!isCompanyAndRelatedEnvironmentIdSet() && (
          <div style={{ margin: '20px 0' }}>
            <MessageBar
              messageBarType={MessageBarType.severeWarning}
              actions={goToSettingsButton()}
            >
              <>{getNoDataWarningMessage()}</>
            </MessageBar>
          </div>
        )}
      </>
    )
  }

  return {
    renderConfigurationRequiredPanel,
    isCompanyAndRelatedEnvironmentIdSet,
  }
}
