import { TFunction } from 'react-i18next'

export enum MicroserviceEventType {
  CREATED = 'CREATED',
  UPDATED = 'UPDATED',
  ARCHIVED = 'ARCHIVED',
  UNARCHIVED = 'UNARCHIVED',
  ACTIVATED = 'ACTIVATED',
  DEACTIVATED = 'DEACTIVATED',
  EXECUTED = 'EXECUTED',
}

export const label = (t: TFunction): { [key in MicroserviceEventType]: string } => ({
  [MicroserviceEventType.CREATED]: t('EVENT.CREATED'),
  [MicroserviceEventType.UPDATED]: t('EVENT.UPDATED'),
  [MicroserviceEventType.ACTIVATED]: t('EVENT.ACTIVATED'),
  [MicroserviceEventType.DEACTIVATED]: t('EVENT.DEACTIVATED'),
  [MicroserviceEventType.ARCHIVED]: t('EVENT.ARCHIVED'),
  [MicroserviceEventType.UNARCHIVED]: t('EVENT.UNARCHIVED'),
  [MicroserviceEventType.EXECUTED]: t('EVENT.EXECUTED'),
})
