import { useEffect } from 'react'
import { useSearchParams } from 'react-router-dom'

const ORGANIZATION_PATH_REGEX = /\/organization\/[^/]+/
const ORGANIZATION_URL_SEGMENT = '/organization'

export const useLocalStorageSearchParams = (persist: boolean = true) => {
  const [searchParams] = useSearchParams()

  useEffect(() => {
    persist && persistSearchParams()
  }, [searchParams])

  const persistSearchParams = () => {
    const url = new URL(window.location.href)
    let params = url.search.replace('?', '').split('&')
    params = params.filter((param) => !param.startsWith('page='))
    localStorage.setItem(
      url.pathname.replace(ORGANIZATION_PATH_REGEX, ORGANIZATION_URL_SEGMENT),
      `?${params.join('&')}`
    )
  }

  const getPersistedSearchParams = (url: string) => {
    return localStorage.getItem(url.replace(ORGANIZATION_PATH_REGEX, ORGANIZATION_URL_SEGMENT)) || ''
  }

  return {
    persistSearchParams,
    getPersistedSearchParams,
  }
}
