import { ControlledToggleButton, Dialog, RowColLayout } from '@flexxibleit/flexxible-ui'
import { MessageBar } from '@fluentui/react'
import { useBoolean } from '@fluentui/react-hooks'
import { zodResolver } from '@hookform/resolvers/zod'
import FormActions from 'app/components/forms/FormActions'
import MultiLangTextField, { createDefaultMultiLanguageFields } from 'app/components/forms/MultiLangTextField'
import { FlowDetail } from 'app/hooks/flows/useGetFlow'
import { Languages, MultiLanguageField } from 'app/models/microservices'
import { useEffect } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import * as z from 'zod'
import { useFlowTabStore } from '../store'

export interface FormNotificationData {
  initText: MultiLanguageField[]
  okText: MultiLanguageField[]
  koText: MultiLanguageField[]
  enabled: boolean
}

const multiLanguageFieldSchema = z.object({
  code: z.nativeEnum(Languages),
  text: z.string(),
  translatedByIA: z.boolean(),
})
const schema: z.ZodType<Partial<FormNotificationData>> = z
  .object({
    initText: z.array(multiLanguageFieldSchema),
    okText: z.array(multiLanguageFieldSchema),
    koText: z.array(multiLanguageFieldSchema),
    enabled: z.boolean(),
  })
  .superRefine((values, ctx) => {
    if (!values.enabled) {
      return
    }

    if (!values.initText.some((field) => field.text.trim().length > 0)) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: 'at-least-one-text-field-must-be-non-empty',
        path: ['initText'],
      })
    }

    if (!values.okText.some((field) => field.text.trim().length > 0)) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: 'at-least-one-text-field-must-be-non-empty',
        path: ['okText'],
      })
    }

    if (!values.koText.some((field) => field.text.trim().length > 0)) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: 'at-least-one-text-field-must-be-non-empty',
        path: ['koText'],
      })
    }
  })

interface Props {
  flow: FlowDetail
  onSubmit: (data: FormNotificationData) => void
  onCancel: () => void
}
export const FlowNotificationForm = ({ flow, onSubmit, onCancel }: Props) => {
  const { t } = useTranslation('flows')
  const setState = useFlowTabStore((state) => state.setNotification)
  const state = useFlowTabStore((state) => state.notification)
  const [hideDialog, { toggle: toggleHideDialog }] = useBoolean(true)

  const methods = useForm<FormNotificationData>({
    resolver: zodResolver(schema),
    defaultValues: {
      initText: flow.initText ?? createDefaultMultiLanguageFields(),
      okText: flow.okText ?? createDefaultMultiLanguageFields(),
      koText: flow.koText ?? createDefaultMultiLanguageFields(),
      enabled: Boolean(flow.initText) || Boolean(flow.okText) || Boolean(flow.koText),
    },
  })
  const watchInitText = methods.watch('initText')
  const watchOkText = methods.watch('okText')
  const watchKoText = methods.watch('koText')
  const watchEnabled = methods.watch('enabled')

  useEffect(() => {
    setState(methods.getValues())
  }, [watchInitText, watchOkText, watchKoText, watchEnabled])

  useEffect(() => {
    if (!state) {
      return
    }

    methods.reset(state)
  }, [])

  const renderCancelEditDialog = (): JSX.Element => {
    return (
      <Dialog
        title={t('general:UNSAVED_CHANGES_DIALOG.TITLE')}
        description={t('general:UNSAVED_CHANGES_DIALOG.CANCEL_EDIT')}
        actionButton={t('general:UNSAVED_CHANGES_DIALOG.BUTTON_ACCEPT')}
        dismissButton={t('general:UNSAVED_CHANGES_DIALOG.BUTTON_CANCEL')}
        hidden={hideDialog}
        onDismiss={toggleHideDialog}
        callback={() => {
          methods.reset()
          onCancel()
          toggleHideDialog()
        }}
      >
        <MessageBar
          messageBarType={3}
          isMultiline={true}
          dismissButtonAriaLabel={t('general:CLOSE_LABEL')}
          className="mb-2"
          style={{ width: '100%' }}
        >
          {t('general:UNSAVED_CHANGES_DIALOG.WARNING')}
        </MessageBar>
      </Dialog>
    )
  }

  const isDirty = methods.formState.isDirty
  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <div className="d-flex d-flexAlignItemsEnd d-flexRowReverse">
          <FormActions
            cancelProps={{
              onClick: () => {
                if (isDirty) {
                  return toggleHideDialog()
                }

                methods.reset()
                onCancel()
              },
            }}
            submitProps={{
              text: t('general:BUTTON.SAVE'),
              iconProps: { iconName: 'Save' },
            }}
          />
        </div>
        <div className="mt-2">
          <ControlledToggleButton
            name="enabled"
            toggleProps={{
              inlineLabel: true,
              label: t('NOTIFICATION.LABEL'),
              onText: t('general:STATUS.ACTIVE'),
              offText: t('general:STATUS.INACTIVE'),
            }}
          />
        </div>
        {methods.watch('enabled') && (
          <>
            <RowColLayout rowProps={{ classNames: 'mt-2' }}>
              <MultiLangTextField
                name="initText"
                required
                labelKey="flows:FORM.INIT_TEXT"
                placeholderKey="flows:FORM.INIT_TEXT_PLACEHOLDER"
                translator={t}
              />
            </RowColLayout>

            <RowColLayout rowProps={{ classNames: 'mt-2' }}>
              <MultiLangTextField
                name="okText"
                required
                labelKey="flows:FORM.OK_TEXT"
                placeholderKey="flows:FORM.OK_TEXT_PLACEHOLDER"
                translator={t}
              />
            </RowColLayout>

            <RowColLayout rowProps={{ classNames: 'mt-2' }}>
              <MultiLangTextField
                name="koText"
                required
                labelKey="flows:FORM.KO_TEXT"
                placeholderKey="flows:FORM.KO_TEXT_PLACEHOLDER"
                translator={t}
              />
            </RowColLayout>
          </>
        )}
      </form>
      {renderCancelEditDialog()}
    </FormProvider>
  )
}
