import { FormActionsContainer, LoadingSpinner } from '@flexxibleit/flexxible-ui'
import { zodResolver } from '@hookform/resolvers/zod'
import { GoogleMap } from 'app/components/GoogleMap/GoogleMap'
import { GoogleMapAddressPicker } from 'app/components/GoogleMap/GoogleMapAddressPicker'
import { GoogleMapMarker } from 'app/components/GoogleMap/GoogleMapMarker'
import RowColLayout from 'app/components/Layouts/RowColLayout'
import FormActions from 'app/components/forms/FormActions'
import ControlledTextField from 'app/components/forms/TextField'
import ErrorStatus from 'app/components/status/error-status'
import SuccessStatus from 'app/components/status/success-status'
import { useUpdateWorkspaceGroupLocation } from 'app/hooks/workspaceGroup/useUpdateWorkspaceGroupLocation'
import { WorkspaceLocation } from 'app/query-client/types'
import { useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import * as z from 'zod'
import { useUpdateWorkspaceGroupAddress } from '../../../../hooks/workspaceGroup/useUpdateWorkspaceGroupAddress'
import { useGlobalConfig } from 'app/globals/context/GlobalConfigContext'

export interface WorkspaceGroupLocationFormType {
  address: string
  description?: string | null | undefined
  latitude: number
  longitude: number
}

interface Props {
  id: string
  location?: WorkspaceLocation
}

const schema: z.ZodType<Partial<WorkspaceGroupLocationFormType>> = z.object({
  address: z.string({ required_error: 'required' }).min(1, { message: 'required' }),
  description: z.string().max(280, { message: 'max' }).optional().nullable(),
})

export const WorkspaceGroupLocationForm = ({ id, location }: Props) => {
  const { t } = useTranslation('organization_details')
  const { isSuccess, mutateAsync, isError, isLoading } = useUpdateWorkspaceGroupLocation()
  const [service, setService] = useState<google.maps.places.PlacesService>()
  const globalConfig = useGlobalConfig()
  const { fetchAddress } = useUpdateWorkspaceGroupAddress(globalConfig?.gmApiKey ?? '')

  const [selectedMapPoint, setSelectedMapPoint] = useState<google.maps.LatLngLiteral | null>(
    location?.latitude
      ? {
          lat: location?.latitude ?? 0,
          lng: location?.longitude ?? 0,
        }
      : null
  )
  const [address, setAddress] = useState<string>(location?.address ?? '')

  const methods = useForm<WorkspaceGroupLocationFormType>({
    resolver: zodResolver(schema),
    defaultValues: {
      ...location,
      latitude: location?.latitude ?? 0,
      longitude: location?.longitude ?? 0,
    },
  })

  const handleOnClick = async (e: google.maps.MapMouseEvent) => {
    const selectedPointEvent = JSON.stringify(e.latLng!.toJSON(), null, 2)
    const newSelectedPoint = JSON.parse(selectedPointEvent)
    setSelectedMapPoint(newSelectedPoint)
    methods.setValue('latitude', newSelectedPoint.lat)
    methods.setValue('longitude', newSelectedPoint.lng)

    const fetchedAddress = await fetchAddress(newSelectedPoint.lat, newSelectedPoint.lng)
    methods.setValue('address', fetchedAddress)
    setAddress(fetchedAddress)
  }

  const handleSubmit = async (data: WorkspaceGroupLocationFormType) => {
    await mutateAsync({
      workspaceGroupId: id,
      input: methods.getValues(),
    })
  }

  const onPlaceSelected = (place: google.maps.places.QueryAutocompletePrediction) => {
    service?.getDetails({ placeId: place.place_id! }, (result, status) => {
      methods.setValue('address', result?.formatted_address ?? place.description)
      methods.setValue('latitude', result?.geometry?.location?.lat()!)
      methods.setValue('longitude', result?.geometry?.location?.lng()!)
      setSelectedMapPoint({
        lat: result?.geometry?.location?.lat()!,
        lng: result?.geometry?.location?.lng()!,
      })
    })
  }

  if (isLoading) {
    return <LoadingSpinner />
  }

  if (isError) {
    return (
      <div className="d-flex h-100">
        <ErrorStatus message={t('WORKSPACE_GROUPS.LOCATION_FORM.UPDATE_ERROR')} />
      </div>
    )
  }
  if (isSuccess) {
    return (
      <div className="d-flex h-100">
        <SuccessStatus message={t('WORKSPACE_GROUPS.LOCATION_FORM.UPDATE_SUCCESS')} />
      </div>
    )
  }

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(handleSubmit)}>
        <RowColLayout rowProps={{ classNames: 'mt-2' }}>
          <GoogleMapAddressPicker
            onPlaceSelected={onPlaceSelected}
            required={true}
            value={address}
            label={t('WORKSPACE_GROUPS.LOCATION_FORM.ADDRESS')}
            placeholder={t('WORKSPACE_GROUPS.FORM.ENTER_TEXT')}
            placesService={service}
          />
        </RowColLayout>

        <RowColLayout rowProps={{ classNames: 'mt-2' }}>
          <ControlledTextField
            name="description"
            translator={t}
            textFieldProps={{
              label: t('WORKSPACE_GROUPS.LOCATION_FORM.DESCRIPTION'),
              placeholder: t('WORKSPACE_GROUPS.FORM.ENTER_TEXT'),
            }}
          />
        </RowColLayout>

        <RowColLayout rowProps={{ classNames: 'mt-2' }}>
          <div style={{ height: 350 }}>
            <GoogleMap
              onGoogleApiLoaded={(map) => {
                setService(new google.maps.places.PlacesService(map))
              }}
              center={selectedMapPoint ?? { lat: 0, lng: 0 }}
              zoom={selectedMapPoint ? 17 : 2}
              disableDefaultUI={true}
              onClick={handleOnClick}
            >
              {selectedMapPoint && <GoogleMapMarker position={selectedMapPoint} />}
            </GoogleMap>
          </div>
        </RowColLayout>

        <FormActionsContainer>
          <FormActions
            submitProps={{
              text: t('general:BUTTON.SAVE'),
              iconProps: { iconName: 'Save' },
            }}
          />
        </FormActionsContainer>
      </form>
    </FormProvider>
  )
}
