import { useTranslation } from 'react-i18next'
import { EnabledMicroservices } from './EnabledMicroservices'
import { PRODUCT_ENV } from 'app/config/Consts'
import { ProductEnvTypes } from 'app/config/productEnv.enum'
import { useOrganizationSelection } from 'app/globals/useOrganizationSelection'
import { TablePageBody } from '@flexxibleit/flexxible-ui'
import { withFeatureRender } from '../../../permissions/withFeatureRender'
import { FEATURE_NAMES } from '../../../permissions/FeatureName.enum'

const EnabledMicroservicesPage = () => {
  const { t } = useTranslation('microservices')
  const { selectedOrganizationId } = useOrganizationSelection()
  return (
    <TablePageBody
      isLoading={false}
      title={`${t('ENABLED_MICROSERVICES.TITLE')}`}
      titleTag={PRODUCT_ENV !== ProductEnvTypes.FXXONE ? 'PREVIEW' : undefined}
    >
      {selectedOrganizationId && <EnabledMicroservices organizationId={selectedOrganizationId} />}
    </TablePageBody>
  )
}

export const EnabledMicroservicesFeature = withFeatureRender(
  EnabledMicroservicesPage,
  FEATURE_NAMES.ENABLED_MICROSERVICE_READ
)
