import { useMutation, useQueryClient } from "@tanstack/react-query";
import { gql } from "graphql-request";
import graphClient from "../../query-client/graphClient";
import { ProductFormType } from "app/pages/organization-details/products/ProductForm";

const editProductConfigQuery = gql`
mutation Request($id: ID!, $input: ProductConfigUpdateInput!) {
  updateProductConfig(id: $id, input: $input) {
    active
  }
}
`

function editProductConfig(id: string, request: ProductFormType ) {
  return graphClient
    .request(editProductConfigQuery, {
      id,
      input: request
    })
    .then(response => response)
}

export default function (productId: string) {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: ( request: ProductFormType) => editProductConfig(productId, request),
    onSuccess: () => {
      queryClient.invalidateQueries(['getBusinessById'])
    }
  })
}
