import { RowColLayout, SearchBox } from '@flexxibleit/flexxible-ui'
import { List } from '@fluentui/react'
import { useOrganizationSelection } from 'app/globals/useOrganizationSelection'
import { EnabledMicroserviceView, useGetEnabledMicroservices } from 'app/hooks/microservices/useGetEnabledMicroservices'
import { getTranslation } from 'app/models/microservices'
import { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { SelectorItem } from './SelectorItem'

interface Props {
  onSelect: (item: EnabledMicroserviceView) => void
}

export const ActionSelector = (props: Props) => {
  const { t, i18n } = useTranslation('flows')
  const { selectedOrganizationId } = useOrganizationSelection()
  const { data } = useGetEnabledMicroservices(selectedOrganizationId)
  const [textFilter, setTextFilter] = useState<string>()
  const [items, setItems] = useState(data ?? [])

  const onSearchChange = (ev: unknown, text?: string): void => {
    setTextFilter(text)
  }

  useEffect(() => {
    if (!textFilter) {
      setItems(data ?? [])
      return
    }

    setItems(
      data?.filter((item: EnabledMicroserviceView) => {
        const name = getTranslation(item.name, i18n.language)
        const description = getTranslation(item.description, i18n.language)

        return (
          name.text.toLowerCase().includes(textFilter.toLowerCase()) ||
          description.text.toLowerCase().includes(textFilter.toLowerCase())
        )
      }) ?? []
    )
  }, [data, textFilter])

  const onRenderCell = useCallback((item?: EnabledMicroserviceView): JSX.Element => {
    if (!item) return <></>

    const name = getTranslation(item.name, i18n.language)
    const description = getTranslation(item.description, i18n.language)

    return (
      <SelectorItem
        name={name.text}
        description={description.text}
        iconName={item.iconName}
        color={item.iconBackground}
        onClick={() => props.onSelect(item)}
      />
    )
  }, [])

  return (
    <>
      <RowColLayout rowProps={{ classNames: 'mt-2 mb-2' }}>
        <SearchBox placeholder={t('general:SEARCH.PLACEHOLDER')} onChange={onSearchChange} />
      </RowColLayout>
      <List items={items} onRenderCell={onRenderCell} />
    </>
  )
}
