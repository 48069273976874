import { FormActionsContainer, LoadingSpinner } from '@flexxibleit/flexxible-ui'
import { FormProvider, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { zodResolver } from '@hookform/resolvers/zod'
import * as z from 'zod'
import useEditOrganization from 'app/hooks/organization/useEditOrganization'
import { useEffect, useState } from 'react'
import useCreateOrganization from 'app/hooks/organization/useCreateOrganization'
import { IDropdownOption, PrimaryButton } from '@fluentui/react'
import RowColLayout from 'app/components/Layouts/RowColLayout'
import ControlledTextField from 'app/components/forms/TextField'
import ComboBox from 'app/components/forms/ComboBox'
import languages from 'app/i18n/languages'
import countries from 'app/config/countries'
import industries from 'app/config/industries'
import MultilineTextField from 'app/components/forms/MultilineTextField'
import FormActions from 'app/components/forms/FormActions'
import SuccessStatus from 'app/components/status/success-status'
import ErrorStatus from 'app/components/status/error-status'
import { activeDirectoryCredentials, Business } from 'app/query-client/types'
import ControlledToggleButton from 'app/components/forms/ToggleButton/ToggleButton.component'
import Dialog from 'app/components/Dialog/Dialog'
import { useBoolean } from '@fluentui/react-hooks'
import useOrganization from '../../../hooks/organization/useOrganization'
import { SearchableDropdown } from '../../../components/forms/SearchableDropdown/SearchableDropdown'
import Row from 'app/components/Layouts/Row'
import Col from 'app/components/Layouts/Col'
import { BusinessType } from 'app/models/business/BusinessType'
import useDropdownOptions from 'app/components/hooks/useDropdownOptions'
import { PRODUCT_ENV } from 'app/config/Consts'
import { ProductEnvTypes } from 'app/config/productEnv.enum'
import { useOrganizationSelection } from 'app/globals/useOrganizationSelection'
import { useFeatureRender } from '../../../permissions/useFeatureRender'
import { FEATURE_NAMES } from '../../../permissions/FeatureName.enum'

export interface BusinessFormProps {
  organization?: Business
}

export interface BusinessFormData {
  name: string | undefined
  email: string | undefined
  type: string | undefined
  language: string | undefined
  country: string | undefined
  industry: string | undefined
  active?: boolean | undefined
  description?: string | undefined
  serviceNowCompanyId?: string | null | undefined
  partner?: string | null | undefined
  activeDirectoryCredentials?: activeDirectoryCredentials | undefined
  isTrial?: boolean | undefined
  defaultPolicy?: string | null | undefined
}

interface ApiError {
  response: {
    errors: Array<{ message: string }>
  }
}

const schema: z.ZodType<BusinessFormData> = z.object({
  name: z
    .string({
      required_error: 'required',
    })
    .min(1, 'required'),
  email: z
    .string({
      required_error: 'required',
    })
    .trim()
    .email({ message: 'format' }),
  type: z.string({
    required_error: 'required',
  }),
  language: z.string({
    required_error: 'required',
  }),
  country: z.string({
    required_error: 'required',
  }),
  industry: z.string({
    required_error: 'required',
  }),
  active: z.boolean().default(true),
  description: z.string().optional(),
  serviceNowCompanyId: z.string().nullable().optional(),
  partner: z.string().nullable().optional(),
  isTrial: z.boolean().default(false),
  defaultPolicy: z.string().nullable().optional(),
})

const OrganizationForm = ({ organization }: BusinessFormProps) => {
  const { t } = useTranslation('organizations')
  const [hideDialog, { toggle: toggleHideDialog }] = useBoolean(true)

  const createOrganization = useCreateOrganization()
  const editOrganization = useEditOrganization(organization?._id)

  const { rolesOnBusiness } = useOrganizationSelection()
  const { checkFeature } = useFeatureRender()

  const { isLoading: organizationsLoading, data: organizationList } = useOrganization()

  const hasPolicies = organization?._id && organization.policies.length > 0 ? true : false

  let policiesOptions: IDropdownOption[] = []
  if (hasPolicies && organization) {
    policiesOptions = useDropdownOptions(organization.policies, '_id', 'name')
  }
  const buildPartnerOptions = () => {
    return (
      organizationList
        ?.filter((org: Business) => {
          return organization?._id !== org._id
        })
        .map((org) => {
          return {
            key: org._id,
            text: org.name,
          }
        }) || []
    )
  }

  const typeOptions = [
    { key: BusinessType.END_CUSTOMER, text: t('TYPE.END_CUSTOMER') },
    { key: BusinessType.PARTNER, text: t('TYPE.PARTNER') },
    { key: BusinessType.WHOLESALER, text: t('TYPE.WHOLESALER') },
  ]

  const [isSuccess, setIsSuccess] = useState(false)
  const [isError, setIsError] = useState(false)
  const [isLoading, setIsLoading] = useState(organizationsLoading)
  const [dataToSubmit, setDataToSubmit] = useState<BusinessFormData>()

  useEffect(() => {
    setIsSuccess(createOrganization.isSuccess || editOrganization.isSuccess)
    setIsError(createOrganization.isError || editOrganization.isError)
    setIsLoading(createOrganization.isLoading || editOrganization.isLoading)
  }, [createOrganization, editOrganization])

  useEffect(() => {
    if (createOrganization.isError || editOrganization.isError) {
      handleSaveError()
    }
  }, [createOrganization.isError, editOrganization.isError])

  const getDefaultValues = (): BusinessFormData => {
    return {
      name: organization?.name,
      email: organization?.email,
      type: organization?.type,
      language: organization?.language,
      country: organization?.country,
      industry: organization?.industry,
      active: organization?.active,
      description: organization?.description || '',
      serviceNowCompanyId: organization?.serviceNowCompanyId || null,
      partner: organization?.partner?._id || null,
      isTrial: organization?.isTrial || false,
      defaultPolicy: organization?.defaultPolicy?._id || null,
    }
  }

  const methods = useForm<BusinessFormData>({
    resolver: zodResolver(schema),
    defaultValues: { ...getDefaultValues() },
  })

  const policyWatch = methods.watch('defaultPolicy')

  const onSubmit = (data: BusinessFormData) => {
    if (!organization) {
      delete data.defaultPolicy
      createOrganization.mutate(data)
    } else if (organization && data.active) {
      data.partner === '' ? (data.partner = null) : data.partner
      if (!checkFeature(FEATURE_NAMES.ORGANIZATION_PARTNER_UPDATE)) {
        data.partner = undefined
      }
      if (!checkFeature(FEATURE_NAMES.ORGANIZATION_TYPE_UPDATE)) {
        data.type = undefined
      }
      if (data.serviceNowCompanyId === organization?.serviceNowCompanyId) {
        delete data.serviceNowCompanyId
      }
      editOrganization.mutate(data)
    } else {
      setDataToSubmit(data)
      toggleHideDialog()
    }
  }

  const handleSaveError = () => {
    if (
      (editOrganization.error as ApiError)?.response?.errors?.[0]?.message ||
      (createOrganization.error as ApiError)?.response?.errors?.[0]?.message
    ) {
      const message =
        (editOrganization.error as ApiError)?.response?.errors?.[0]?.message ||
        (createOrganization.error as ApiError)?.response?.errors?.[0]?.message
      if (message === 'Invalid ServiceNow Company ID.') {
        methods.setError('serviceNowCompanyId', { message: t('invalid_id') })
      }
    }
  }

  function isTypeSelectorEnabled(organization: Business | undefined): boolean | undefined {
    const isCreatingOrganization = (organization?: Business) => organization === undefined
    const isEditingOrganization = (organization?: Business) => organization !== undefined

    let enabled: boolean | undefined = false

    if (isCreatingOrganization(organization)) {
      enabled = checkFeature(FEATURE_NAMES.ORGANIZATION_CREATE)
    } else if (isEditingOrganization(organization)) {
      enabled = checkFeature(FEATURE_NAMES.ORGANIZATION_TYPE_UPDATE)
    }

    return enabled
  }

  function isPartnerSelectorEnabled(organization: Business | undefined): boolean | undefined {
    const isCreatingOrganization = (organization?: Business) => organization === undefined
    const isEditingOrganization = (organization?: Business) => organization !== undefined

    let enabled: boolean | undefined = false

    if (isCreatingOrganization(organization)) {
      enabled = checkFeature(FEATURE_NAMES.ORGANIZATION_CREATE)
    } else if (isEditingOrganization(organization)) {
      enabled = checkFeature(FEATURE_NAMES.ORGANIZATION_PARTNER_UPDATE)
    }

    return enabled
  }

  return (
    <>
      {!isLoading && !isSuccess && !isError && (
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            <RowColLayout rowProps={{ classNames: 'mt-2' }}>
              <ControlledTextField
                name="name"
                required={true}
                translator={t}
                textFieldProps={{
                  label: t('FORM.NAME'),
                  placeholder: t('FORM.ENTER_TEXT'),
                }}
              />
            </RowColLayout>

            <RowColLayout rowProps={{ classNames: 'mt-2' }}>
              <ControlledTextField
                name="email"
                required={true}
                translator={t}
                textFieldProps={{
                  label: t('FORM.EMAIL'),
                  placeholder: t('FORM.ENTER_TEXT'),
                }}
              />
            </RowColLayout>

            {isTypeSelectorEnabled(organization) ? (
              <RowColLayout rowProps={{ classNames: 'mt-2' }}>
                <ComboBox
                  name="type"
                  required={true}
                  translator={t}
                  comboBoxProps={{
                    options: typeOptions,
                    label: t('FORM.TYPE'),
                    placeholder: t('FORM.TYPE_PLACEHOLDER'),
                    defaultSelectedKey: organization && organization.type,
                  }}
                />
              </RowColLayout>
            ) : null}

            <RowColLayout rowProps={{ classNames: 'mt-2' }}>
              <ComboBox
                name="language"
                required={true}
                translator={t}
                comboBoxProps={{
                  options: languages,
                  label: t('FORM.LANGUAGE'),
                  placeholder: t('FORM.LANGUAGE_PLACEHOLDER'),
                  defaultSelectedKey: organization && organization.language,
                }}
              />
            </RowColLayout>

            <RowColLayout rowProps={{ classNames: 'mt-2' }}>
              <ComboBox
                name="country"
                required={true}
                translator={t}
                comboBoxProps={{
                  options: countries,
                  label: t('FORM.COUNTRY'),
                  placeholder: t('FORM.COUNTRY_PLACEHOLDER'),
                  defaultSelectedKey: organization && organization.country,
                }}
              />
            </RowColLayout>

            <RowColLayout rowProps={{ classNames: 'mt-2' }}>
              <ComboBox
                name="industry"
                required={true}
                translator={t}
                comboBoxProps={{
                  options: industries,
                  label: t('FORM.INDUSTRY'),
                  placeholder: t('FORM.INDUSTRY_PLACEHOLDER'),
                  defaultSelectedKey: organization && organization.industry,
                }}
              />
            </RowColLayout>

            {PRODUCT_ENV !== ProductEnvTypes.FXXONE && (
              <RowColLayout rowProps={{ classNames: 'mt-2' }}>
                <ControlledTextField
                  name="serviceNowCompanyId"
                  translator={t}
                  textFieldProps={{
                    label: t('FORM.SERVICENOW_COMPANY_ID'),
                    placeholder: t('FORM.ENTER_TEXT'),
                  }}
                />
              </RowColLayout>
            )}

            {isPartnerSelectorEnabled(organization) ? (
              <RowColLayout rowProps={{ classNames: 'mt-2' }}>
                <SearchableDropdown
                  name="partner"
                  label={t('FORM.PARTNER')}
                  placeholder={t('FORM.PARTNER_PLACEHOLDER')}
                  translator={t}
                  defaultSelectedKey={organization?.partner && organization?.partner._id}
                  options={buildPartnerOptions()}
                  searchBarPlaceholder={t('FORM.SEARCH_PARTNER')}
                  allowEmpty={{
                    emptyOptionText: t('FORM.NO_PARTNER'),
                  }}
                  optionIcon={'EMI'}
                  key={'partner'}
                />
              </RowColLayout>
            ) : null}

            <RowColLayout rowProps={{ classNames: 'mt-2' }}>
              <MultilineTextField
                name="description"
                translator={t}
                textFieldProps={{
                  label: t('FORM.DESCRIPTION'),
                  placeholder: t('FORM.ENTER_TEXT'),
                }}
              />
            </RowColLayout>

            {hasPolicies && (
              <RowColLayout rowProps={{ classNames: 'mt-2' }}>
                <ComboBox
                  name="defaultPolicy"
                  translator={t}
                  comboBoxProps={{
                    options: policiesOptions,
                    label: t('FORM.DEFAULT_POLICY'),
                    placeholder: t('FORM.DEFAULT_POLICY_PLACEHOLDER'),
                    defaultSelectedKey: policyWatch,
                  }}
                />
              </RowColLayout>
            )}

            <Row classNames="mt-2">
              {organization && (
                <Col className="col-12 col-xs-6">
                  <ControlledToggleButton
                    name="active"
                    toggleProps={{
                      inlineLabel: true,
                      label: t('FORM.STATUS_LABEL'),
                      onText: t('general:STATUS.ACTIVE'),
                      offText: t('general:STATUS.INACTIVE'),
                    }}
                  />
                </Col>
              )}
              <Col className="col-12 col-xs-6">
                <ControlledToggleButton
                  name="isTrial"
                  toggleProps={{
                    inlineLabel: true,
                    label: t('FORM.TRIAL_LABEL'),
                    onText: t('FORM.TRIAL_LABEL'),
                    offText: '',
                  }}
                />
              </Col>
            </Row>

            <FormActionsContainer>
              {organization ? (
                <FormActions
                  submitProps={{
                    text: t('general:BUTTON.SAVE'),
                    iconProps: { iconName: 'Save' },
                  }}
                />
              ) : (
                <FormActions
                  submitProps={{
                    text: t('general:BUTTON.CREATE'),
                    iconProps: { iconName: 'Add' },
                  }}
                />
              )}
            </FormActionsContainer>
          </form>
        </FormProvider>
      )}

      {isLoading && <LoadingSpinner></LoadingSpinner>}

      {(isSuccess || isError) && (
        <>
          {createOrganization.isSuccess && <SuccessStatus message={t('FORM.ADD_SUCCESS')} />}

          {createOrganization.isError && (
            <>
              <ErrorStatus
                message={
                  (createOrganization.error as ApiError)?.response?.errors?.[0]?.message &&
                  (createOrganization.error as ApiError)?.response?.errors?.[0]?.message ===
                    'Invalid ServiceNow Company ID.'
                    ? t('ERRORS.serviceNowCompanyId_invalid_id')
                    : t('FORM.ADD_ERROR')
                }
              />
              <PrimaryButton
                text={t('general:BUTTON.KEEP_EDITING')}
                onClick={() => {
                  createOrganization.reset()
                }}
                iconProps={{ iconName: 'NavigateBack' }}
              />
            </>
          )}

          {editOrganization.isSuccess && <SuccessStatus message={t('FORM.EDIT_SUCCESS')} />}

          {editOrganization.isError && (
            <>
              <ErrorStatus
                message={
                  (editOrganization.error as ApiError)?.response?.errors?.[0]?.message &&
                  (editOrganization.error as ApiError)?.response?.errors?.[0]?.message ===
                    'Invalid ServiceNow Company ID.'
                    ? t('ERRORS.serviceNowCompanyId_invalid_id')
                    : t('FORM.EDIT_ERROR')
                }
              />
              <PrimaryButton
                text={t('general:BUTTON.KEEP_EDITING')}
                onClick={() => {
                  editOrganization.reset()
                }}
                iconProps={{ iconName: 'NavigateBack' }}
              />
            </>
          )}
        </>
      )}

      <Dialog
        title={t('FORM.CONFIRMATION_TITLE')}
        description={t('FORM.CONFIRMATION_MESSAGE')}
        actionButton={t('general:BUTTON.SAVE')}
        dismissButton={t('general:BUTTON.CANCEL')}
        callback={() => {
          if (dataToSubmit) {
            toggleHideDialog()
            editOrganization.mutate(dataToSubmit)
          }
        }}
        hidden={hideDialog}
        onDismiss={toggleHideDialog}
      />
    </>
  )
}

export default OrganizationForm
