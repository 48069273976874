import { RowColLayout } from '@flexxibleit/flexxible-ui'
import { WrapOverwriteAgentSettingsForm } from './WrapOverwriteAgentSettingsForm'
import { ControlledSlider } from 'app/components/forms/Slider'

interface Props {
  overridable?: boolean
  name: string
  label: string
  tooltip: string
  isOverwrite?: boolean
  sliderProps: {
    min: number
    max: number
    step: number
    valueFormat: (value: number) => string
  }
  disabled?: boolean
  callbackOverwrite?: () => void
}

export const calculateStepRealValueFromSliderValue = (value: number) => {
  if (value < 60) {
    return value
  }
  const anchorValue = value - 60
  return 60 + anchorValue * 60
}

export const calculateSliderValueFromStepRealValue = (value: number) => {
  if (value < 60) {
    return value
  }
  const anchorValue = value - 60
  return 60 + anchorValue / 60
}

const AgentSettingsSlider = ({
  overridable = false,
  name,
  label,
  tooltip,
  isOverwrite = true,
  sliderProps,
  disabled = false,
  callbackOverwrite,
}: Props) => {
  const child = (
    <RowColLayout rowProps={{ classNames: 'mt-2' }}>
      <ControlledSlider
        label={label}
        name={name}
        sliderProps={{
          showValue: true,
          styles: {
            valueLabel: { minWidth: 80 },
          },
          ...sliderProps,
          max: calculateSliderValueFromStepRealValue(sliderProps.max),
          valueFormat: (value: number) => sliderProps.valueFormat(calculateStepRealValueFromSliderValue(value)),
          disabled: (overridable && !isOverwrite) || disabled,
        }}
        tooltip={tooltip}
      />
    </RowColLayout>
  )
  return overridable ? (
    <WrapOverwriteAgentSettingsForm
      onClick={() => callbackOverwrite && callbackOverwrite()}
      overWritable={!isOverwrite}
      disabled={disabled}
    >
      {child}
    </WrapOverwriteAgentSettingsForm>
  ) : (
    child
  )
}

export default AgentSettingsSlider
