import { ISpinButtonProps, SpinButton } from "@fluentui/react";
import Controlled from "../Controlled"

export interface ControlledSpinButtonProps {
  name: string;
  spinButtonProps?: ISpinButtonProps;
}

function ControlledSpinButton({ name, spinButtonProps }: ControlledSpinButtonProps) {

  return (
    <Controlled
      name={name}
      render={({ field: { value, onChange, ...others } }) =>
        <SpinButton
          {...spinButtonProps}
          {...others}
          className="ms-SpinButton-wrapper"
          value={String(value)}
          onChange={(e: any, newValue?: string) => { 
            onChange(Number(newValue))  
          }}/>
      }/>
  )
}

export default ControlledSpinButton