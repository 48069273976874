import { TFunction } from 'react-i18next'
import { MicroserviceLanguage } from './MicroserviceLanguage'

export enum MicroserviceOperatingSystem {
  'WINDOWS' = 'WINDOWS',
  'LINUX' = 'LINUX',
  'MAC_OS' = 'MAC_OS',
}

export const microserviceOperatingSystemOptions = (t: TFunction, lang: MicroserviceLanguage | null = null) =>
  Object.values(MicroserviceOperatingSystem).map((operatingSystem) => ({
    key: operatingSystem,
    text: operatingSystemLabel(t)[operatingSystem],
    disabled: lang === MicroserviceLanguage.BASH ? false : !isAvailable[operatingSystem],
    data: {
      preview: operatingSystem !== MicroserviceOperatingSystem.WINDOWS,
    },
  }))

export const operatingSystemLabel = (t: TFunction): { [key in MicroserviceOperatingSystem]: string } => ({
  [MicroserviceOperatingSystem.WINDOWS]: t('MICROSERVICE_OPERATING_SYSTEM.WINDOWS'),
  [MicroserviceOperatingSystem.LINUX]: t('MICROSERVICE_OPERATING_SYSTEM.LINUX'),
  [MicroserviceOperatingSystem.MAC_OS]: t('MICROSERVICE_OPERATING_SYSTEM.MAC_OS'),
})

const isAvailable: { [key in MicroserviceOperatingSystem]: boolean } = {
  [MicroserviceOperatingSystem.WINDOWS]: true,
  [MicroserviceOperatingSystem.LINUX]: false,
  [MicroserviceOperatingSystem.MAC_OS]: false,
}
