import { Col, Row } from '@flexxibleit/flexxible-ui'
import { ResponsiveMode } from '@fluentui/react'
import { zodResolver } from '@hookform/resolvers/zod'
import Dropdown from 'app/components/forms/Dropdown'
import FormActions from 'app/components/forms/FormActions'
import { ConditionEnvironment, conditionEnvironmentOptions } from 'app/models/condition/ConditionEnvironment'
import { ValueType, valueTypeOptions } from 'app/models/flows/ValueType'
import { FormProvider, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import * as z from 'zod'

interface Props {
  onSubmit: (data: ConditionForm) => void
  onCancel: () => void
}

interface ConditionForm {
  valueType: ValueType
  environment: ConditionEnvironment
}

const schema: z.ZodType<Partial<ConditionForm>> = z.object({
  valueType: z.nativeEnum(ValueType),
  environment: z.nativeEnum(ConditionEnvironment),
})

export const MicroserviceConditionForm = ({ onCancel, onSubmit }: Props) => {
  const { t } = useTranslation('microservices')

  const methods = useForm<ConditionForm>({
    resolver: zodResolver(schema),
  })

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)} className="mb-2">
        <Row classNames="mt-2">
          <Col className="col-xs-12 col-lg-6">
            <Dropdown
              name="valueType"
              required={true}
              translator={t}
              dropdownProps={{
                responsiveMode: ResponsiveMode.large,
                label: t('DESIGNER.MICROSERVICE_FORM.VALUE_TYPE'),
                options: valueTypeOptions(t),
                placeholder: t('DESIGNER.MICROSERVICE_FORM.VALUE_TYPE_PLACEHOLDER'),
              }}
            />
          </Col>
          <Col className="col-xs-12 col-lg-6">
            <Dropdown
              name="environment"
              required={true}
              translator={t}
              dropdownProps={{
                responsiveMode: ResponsiveMode.large,
                label: t('DESIGNER.MICROSERVICE_FORM.ENVIROMENT'),
                options: conditionEnvironmentOptions(t),
                placeholder: t('DESIGNER.MICROSERVICE_FORM.ENVIROMENT_PLACEHOLDER'),
              }}
            />
          </Col>
        </Row>
        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
            marginTop: '100px',
          }}
        >
          <FormActions
            cancelProps={{ onClick: onCancel }}
            submitProps={{
              text: t('general:BUTTON.SAVE'),
              iconProps: { iconName: 'Save' },
            }}
          />
        </div>
      </form>
    </FormProvider>
  )
}
