import { LoadingSpinner } from '@flexxibleit/flexxible-ui'
import { useEffect, useState } from 'react'
import { MicroservicesMarketplaceTable } from './MicroservicesMarketplaceTable'
import { MicroservicesBlocks } from '../components/MicroservicesBlocks'
import {
  MarketplaceMicroserviceView,
  useGetMarketplaceMicroservices,
} from '../../../hooks/microservices/useGetMarketplaceMicroservices'
import { useOrganizationSelection } from '../../../globals/useOrganizationSelection'
import { useLocalStorage } from '../../../hooks/utils/useLocalStorage'
import { MicroservicesMarketplaceFilters } from './MicroservicesMarketplaceFilters'

export const MicroservicesMarketplace = () => {
  const [showAsTable, setShowAsTable] = useLocalStorage('microservices-marketplace-showAsTable', 'true')
  const { selectedOrganizationId } = useOrganizationSelection()
  const { data: microservices, isLoading } = useGetMarketplaceMicroservices(selectedOrganizationId)
  const [microservicesList, setMicroservicesList] = useState<MarketplaceMicroserviceView[]>()

  const asTable = showAsTable === 'true';

  useEffect(() => {
    if (microservices) {
      setMicroservicesList(microservices)
    }
  }, [microservices])

  const handleOnFilter = (filteredItems: MarketplaceMicroserviceView[]) => {
    setMicroservicesList(filteredItems)
  };

  const MicroServicesData = () => {
    return (isLoading || !microservicesList || !selectedOrganizationId) ?
      <LoadingSpinner /> :
      asTable ?
        <MicroservicesMarketplaceTable microservices={microservicesList} /> :
        <MicroservicesBlocks microservices={microservicesList} />
  };

  return (
    <>
      <MicroservicesMarketplaceFilters
        onFilter={handleOnFilter}
        itemsToFilter={microservices}
        {...{ showAsTable, setShowAsTable }}
      />
      <MicroServicesData />
    </>
  )
}
