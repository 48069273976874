import {
  ControlledTextField,
  DeleteActionButton,
  Dialog,
  FormActionsContainer,
  LoadingSpinner,
  RowColLayout,
} from '@flexxibleit/flexxible-ui'
import { zodResolver } from '@hookform/resolvers/zod'
import { CompactPeoplePicker } from 'app/components/forms/PeoplePicker/CompactPeoplePicker'
import { FormProvider, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import * as z from 'zod'
import FormActions from '../../../../components/forms/FormActions'
import useOrganizationById from '../../../../hooks/organization/useOrganizationById'
import { useNavigate, useParams } from 'react-router-dom'
import { IPersonaProps } from '@fluentui/react/lib/Persona'
import { useCreatePatchPolicy } from '../../../../hooks/patchPolicies/useCreatePatchPolicy'
import { useUpdatePatchPolicy } from '../../../../hooks/patchPolicies/useUpdatePatchPolicy'
import { useEffect, useState } from 'react'
import SuccessStatus from '../../../../components/status/success-status'
import ErrorStatus from '../../../../components/status/error-status'
import ControlledToggleButton from 'app/components/forms/ToggleButton/ToggleButton.component'
import { PatchPolicy, WindowsPatchPolicy } from 'app/query-client/types'
import { useBoolean } from '@fluentui/react-hooks'
import { useFormSidebarContext } from 'app/components/forms/FormSidebar/state/FormSidebarState'
import { useDeletePatchPolicy } from 'app/hooks/patchPolicies/useDeletePatchPolicy'
import { useCreateWindowsPatchPolicy } from 'app/hooks/patchPolicies/useCreateWindowsPatchPolicy'
import { useDeleteWindowsPatchPolicy } from 'app/hooks/patchPolicies/useDeleteWindowsPatchPolicy'
import { useUpdateWindowsPatchPolicy } from 'app/hooks/patchPolicies/useUpdateWindowsPatchPolicy'

export interface CloneWindowsPatchPolicyFormType {
  name: string
}

export interface ClonePatchPolicyFormProps {
  windowsPatchPolicy?: WindowsPatchPolicy
}

const schema: z.ZodType<Partial<CloneWindowsPatchPolicyFormType>> = z.object({
  name: z
    .string({
      required_error: 'required',
    })
    .min(1, { message: 'required' }),
})

export const CloneWindowsPatchPolicyForm = ({ windowsPatchPolicy }: ClonePatchPolicyFormProps) => {
  const { t } = useTranslation('patch_management')

  const methods = useForm<CloneWindowsPatchPolicyFormType>({
    resolver: zodResolver(schema),
    defaultValues: windowsPatchPolicy ? ({ ...windowsPatchPolicy } as WindowsPatchPolicy) : {},
  })

  const handleSubmit = (data: CloneWindowsPatchPolicyFormType) => {}

  return (
    <>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(handleSubmit)}>
          <RowColLayout rowProps={{ classNames: 'mt-2' }}>
            <ControlledTextField
              name="name"
              required={true}
              translator={t}
              textFieldProps={{
                label: t('WINDOWS_PATCH_POLICIES.FORM.NEW_NAME'),
                placeholder: t('WINDOWS_PATCH_POLICIES.FORM.NEW_NAME_PLACEHOLDER'),
              }}
            />
          </RowColLayout>

          <ActionButtons isEdit={!!windowsPatchPolicy?._id} />
        </form>
      </FormProvider>
    </>
  )
}

const ActionButtons = ({ isEdit }: { isEdit: boolean }) => {
  const { t, i18n } = useTranslation()

  return (
    <FormActionsContainer>
      <FormActions
        submitProps={{
          text: t('general:BUTTON.CLONE'),
          //iconProps: { iconName: 'Add' },
          disabled: true,
        }}
      />
    </FormActionsContainer>
  )
}
