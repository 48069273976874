import { useMutation, useQueryClient } from "@tanstack/react-query";
import { gql } from "graphql-request";
import graphClient from "../../query-client/graphClient";

const createModuleQuery = gql`mutation Request($input: ModuleInput!) {
  createModule(input: $input) {
    _id
  }
}`

function createModule(module: any) {
  return graphClient
    .request(createModuleQuery, { input: module })
    .then(response => response)
}

export default function () {
  const queryClient = useQueryClient()

  return useMutation(['createModule'],(module: any ) => createModule(module), {
    onSuccess: () => {
      queryClient.invalidateQueries(['getBusinessById'])
      queryClient.invalidateQueries(['getOrganizationModules'])
    }},
  )
}
