import { useMutation, useQueryClient } from "@tanstack/react-query";
import { gql } from "graphql-request";
import graphClient from "../../query-client/graphClient";

const updateModuleQuery = gql`mutation Request($id: ID!, $input: ModuleInput!) {
  updateModule(id: $id, input: $input) {
    _id
  }
}`

function updateModule(id: string, module: any) {
  return graphClient
    .request(updateModuleQuery, { id, input: module })
    .then(response => response)
}

export default function () {
  const queryClient = useQueryClient()

  return useMutation(
    ['updateModule'],
    ({ moduleId, module }: { moduleId: string, module: any }) => updateModule(moduleId, module),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['getBusinessById'])
        queryClient.invalidateQueries(['getOrganizationModules'])
      },
    },
  )
}
