import {
  FeedbackMessage,
  FeedbackMessageType,
  useFeedbackMessage,
} from '../../context/feedback-message/FeedbackMessageContext'
import {
  IMessageBarStyleProps,
  IMessageBarStyles,
  IStyleFunctionOrObject,
  MessageBar,
  MessageBarType,
} from '@fluentui/react'
import { FC } from 'react'

interface FeedbackMessageBarProps {
  styles?: IStyleFunctionOrObject<IMessageBarStyleProps, IMessageBarStyles>
}

export const FeedbackMessageBar: FC<FeedbackMessageBarProps> = ({ styles }) => {
  const { feedbackMessage, clearFeedbackMessage } = useFeedbackMessage()

  const messageBarType = (message: FeedbackMessage) =>
    message.type === FeedbackMessageType.SUCCESS ? MessageBarType.success : MessageBarType.error

  if (!feedbackMessage) {
    return null
  }

  return (
    <MessageBar
      key={feedbackMessage.content}
      styles={styles}
      messageBarType={messageBarType(feedbackMessage)}
      isMultiline={false}
      dismissButtonAriaLabel="Close"
      style={{ width: '100%' }}
      onDismiss={() => clearFeedbackMessage()}
    >
      {feedbackMessage.content}
    </MessageBar>
  )
}
