import { StatusLayout } from '@flexxibleit/flexxible-ui'
import EmptyUser from '../../../assets/img/user-icon.svg'
import { TFunction } from 'i18next'
import { FEATURE_NAMES } from '../../../permissions/FeatureName.enum'
import { PrimaryButton, Stack } from '@fluentui/react'
import { FeatureRender } from 'app/permissions/FeatureRender'
import useCreateForm from 'app/hooks/useCreateForm'
import ImportUsersForm from './ImportUsersForm'

type EmptyUsersProps = {
  t: TFunction
  onAddUserClicked: () => void
}

export const EmptyUsers = ({ t, onAddUserClicked }: EmptyUsersProps) => {
  const onImportUsersClicked = useCreateForm(
    {
      title: t('USERS.FORM.IMPORT_USERS'),
      isFooterAtBottom: true,
    },
    <ImportUsersForm />
  )

  return (
    <div style={{ marginTop: 100 }}>
      <StatusLayout
        img={EmptyUser}
        message={t('USERS.EMPTY_MESSAGE')}
        imgWithContainer={true}
        renderButton={
          <FeatureRender feature={FEATURE_NAMES.USER_CREATE}>
            <Stack horizontal tokens={{ childrenGap: 40 }}>
              <PrimaryButton
                text={t('general:BUTTON.CREATE')}
                iconProps={{ iconName: 'Add' }}
                onClick={onAddUserClicked}
                styles={{ label: { fontWeight: 400 } }}
              />
              <PrimaryButton
                text={t('general:BUTTON.IMPORT')}
                iconProps={{ iconName: 'Upload' }}
                onClick={onImportUsersClicked}
                styles={{ label: { fontWeight: 400 } }}
              />
            </Stack>
          </FeatureRender>
        }
      ></StatusLayout>
    </div>
  )
}
