import { useQuery } from '@tanstack/react-query'
import { gql } from 'graphql-request'
import graphClient from '../../query-client/graphClient'
import { PatchPolicyTarget, ReportingGroup } from 'app/query-client/types'

const getPatchPolicyTargetsByOrganizationQuery = gql`
  query ($organizationId: ID!) {
    getPatchPolicyTargetsByOrganization(organizationId: $organizationId) {
      _id
      name
      organizationId
      schedule
      reportingGroups {
        _id
        name
      }
      createdAt
      updatedAt
      createdBy
    }
  }
`

async function getPatchPolicyTargetsByOrganization(organizationId: string): Promise<PatchPolicyTarget[]> {
  //return mockData
  return graphClient.request(getPatchPolicyTargetsByOrganizationQuery, { organizationId }).then((r) =>
    r.getPatchPolicyTargetsByOrganization.map((target: any) => ({
      ...target,
      schedule: JSON.parse(target.schedule),
    }))
  )
}

export default function (organizationId: string) {
  return useQuery(
    ['getPatchPolicyTargetByOrganization', organizationId],
    () => getPatchPolicyTargetsByOrganization(organizationId),
    {
      refetchOnWindowFocus: false,
    }
  )
}
