import { useNavigate } from 'react-router-dom'
import { MicroservicesCard } from '../../components/MicroservicesCard'
import { PaginatedEnabledMicroservicePageViewData } from '../PaginatedEnabledMicroservicePageViewData'

export const renderMicroserviceBlock = (microservice: PaginatedEnabledMicroservicePageViewData) => {
  const navigate = useNavigate()

  return (
    <MicroservicesCard
      id={microservice._id}
      name={microservice.microserviceName}
      description={microservice.description}
      category={microservice.category ?? []}
      iconName={microservice.iconName}
      iconBackground={microservice.iconBackground}
      onClick={(id: string) => {
        navigate(`/microservices/${id}`, {
          state: { fromEnabledList: true },
        })
      }}
    />
  )
}
