import { MOBILE_QUERY, useMediaQuery } from '@flexxibleit/flexxible-ui'
import { Pivot, PivotItem, SearchBox, Selection, Stack } from '@fluentui/react'
import { FlowDetail } from 'app/hooks/flows/useGetFlow'
import { usePaginatedSelection } from 'app/hooks/utils/usePaginatedSelection'
import useGetAddedWorkspacesList from 'app/hooks/workspace/useGetAddedWorkspacesList'
import useGetNotAddedWorkspacesList from 'app/hooks/workspace/useGetNotAddedWorkspacesList'
import useGetWorkspacesByOrganization from 'app/hooks/workspace/useGetWorkspacesByOrganization'
import { PaginationArgs, Workspace } from 'app/query-client/types'
import { debounce } from 'lodash'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useFlowTabStore } from '../../store'
import { TablaSelector } from './TableSelector'
import { getColumns } from './WorkspaceColumns'

interface Props {
  flow: FlowDetail
  onWorkspaceSelected: (workspaces: string[]) => void
}
export const WorkspaceSelector = ({ flow, onWorkspaceSelected }: Props) => {
  const [isMobile] = useMediaQuery(MOBILE_QUERY)
  const { t } = useTranslation('flows')
  const [searchBoxValueState, setSearchBoxValueState] = useState<string>('')
  const state = useFlowTabStore((state) => state.target)
  const [selectedWorkspacesState, setSelectedWorkspacesState] = useState<Workspace[]>(() => {
    return (
      (state?.workspaces ?? flow.workspaces)?.map((workspace) => {
        return {
          sysId: workspace,
        } as Workspace
      }) || []
    )
  })
  const selection = usePaginatedSelection((item: Workspace) => item.sysId, setSelectedWorkspacesState)
  const [pivotSelectedKeyState, setPivotSelectedKeyState] = useState<string>('all')
  const [paginationState, setPaginationState] = useState<PaginationArgs>(() => ({
    page: 1,
    perPage: 10,
  }))

  const all = useGetWorkspacesByOrganization(flow.business, searchBoxValueState, paginationState, false)
  const added = useGetAddedWorkspacesList(
    flow.business,
    selectedWorkspacesState.map((workspace) => workspace.sysId),
    searchBoxValueState,
    paginationState,
    false
  )
  const notAdded = useGetNotAddedWorkspacesList(
    flow.business,
    selectedWorkspacesState.map((workspace) => workspace.sysId),
    searchBoxValueState,
    paginationState,
    false
  )

  useEffect(() => {
    if (pivotSelectedKeyState === 'all') {
      all.refetch()
    } else if (pivotSelectedKeyState === 'added') {
      added.refetch()
    } else {
      notAdded.refetch()
    }
  }, [pivotSelectedKeyState, paginationState, searchBoxValueState])

  const handleOnSearchBoxChange = debounce((e?: unknown, value?: string) => {
    setSearchBoxValueState(value || '')
  }, 500)

  useEffect(() => {
    onWorkspaceSelected(selectedWorkspacesState.map((workspace) => workspace.sysId))
  }, [selectedWorkspacesState])

  const handlePivotOnLinkClick = (item?: PivotItem) => {
    if (!item || !item.props || !item.props.itemKey) {
      return
    }
    if (pivotSelectedKeyState !== item.props.itemKey) {
      setPaginationState({ page: 1, perPage: 10 })
    }
    setPivotSelectedKeyState(item.props.itemKey)
  }
  const getTableProps = () => {
    if (pivotSelectedKeyState === 'added') {
      return {
        items: added.data?.results ?? [],
        paginatedData: added.data,
        isLoading: added.isLoading,
        isFetching: added.isFetching,
      }
    }
    if (pivotSelectedKeyState === 'notAdded') {
      return {
        items: notAdded.data?.results ?? [],
        paginatedData: notAdded.data,
        isLoading: notAdded.isLoading,
        isFetching: notAdded.isFetching,
      }
    }

    return {
      items: all.data?.results ?? [],
      paginatedData: all.data,
      isLoading: all.isLoading,
      isFetching: all.isFetching,
    }
  }

  return (
    <>
      <Stack
        horizontal={!isMobile}
        horizontalAlign="space-between"
        verticalAlign="center"
        tokens={{ padding: '20px 0 0 0' }}
        className="mb-sm-2"
        style={{ minHeight: '70px' }}
      >
        <Stack.Item grow={1} style={{ maxWidth: 400, minWidth: 196 }}>
          <SearchBox
            styles={{ root: { border: '1px solid #8c9095' } }}
            placeholder={t('organization_details:WORKSPACE_GROUPS.SEARCH_PLACEHOLDER')}
            onChange={handleOnSearchBoxChange}
          />
        </Stack.Item>

        <Stack.Item>
          <div
            className={`d-flex d-flexRow d-flexAlignItemsCenter ${isMobile ? 'd-flexJustifyBetween' : ''} `}
            style={isMobile ? { marginTop: 15 } : {}}
          >
            <Pivot headersOnly={true} selectedKey={pivotSelectedKeyState} onLinkClick={handlePivotOnLinkClick}>
              <PivotItem itemKey="all" headerText={t('organization_details:WORKSPACE_GROUPS.FILTERS.ALL')} />
              <PivotItem itemKey="added" headerText={t('organization_details:WORKSPACE_GROUPS.FILTERS.ADDED')} />
              <PivotItem itemKey="notAdded" headerText={t('organization_details:WORKSPACE_GROUPS.FILTERS.NOT_ADDED')} />
            </Pivot>
          </div>
        </Stack.Item>
      </Stack>

      <TablaSelector
        columns={getColumns(t)}
        pagination={paginationState}
        onPaginationChange={setPaginationState}
        selection={selection as Selection}
        editable={true}
        {...getTableProps()}
      />
    </>
  )
}
