import { Edge } from 'reactflow'
import { CustomNode } from '../nodes'

export const initialNodes: CustomNode[] = [
  {
    id: '1',
    type: 'starter',
    data: {},
    position: { x: 0, y: 0 },
    width: 250,
    height: 85,
    style: {
      width: 250,
      height: 85,
    },
  },
]
export const initialEdges: Edge[] = []
