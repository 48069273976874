import { gql } from 'graphql-request'
import {
  ReportingGroupsDashboard,
} from '../../query-client/types'
import graphClient from '../../query-client/graphClient'
import { useQuery } from '@tanstack/react-query'

const getReportingGroupsDashboard = gql`
  query($organizationId: String!) {
  getPatchManagementDashboard(organizationId: $organizationId) {
    notApprovedPatchesInReportingGroupBySeverity {
      reportingGroup
      severity
      value
    }
    reportingGroupsInRisk {
      reportingGroup
      value
    }
    workspacesCount {
        total
        windows
        linux
    }
    totalWorkspacesByReportingGroup {
        reportingGroup
        value
    }
  }
}
`

function useGetReportingGroupsDashboard(organizationId: string | undefined
): Promise<ReportingGroupsDashboard> {
  return graphClient
    .request(getReportingGroupsDashboard, {organizationId})
    .then((response) => {
      return response.getPatchManagementDashboard
    })
}

export default function (organizationId: string | undefined) {
  return useQuery(
    ['getReportingGroupsDashboard', organizationId],
    () => useGetReportingGroupsDashboard(organizationId),
    {
      refetchOnWindowFocus: false,
      keepPreviousData: true,
    }
  )
}
