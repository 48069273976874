import useCreateForm from 'app/hooks/useCreateForm'
import { User } from 'app/query-client/types'
import { TFunction } from 'i18next'

import ImportUsersForm from './ImportUsersForm'
import UserForm from './UserForm'
import { ICommandItem } from '@flexxibleit/flexxible-ui'
import { useFeatureRender } from '../../../permissions/useFeatureRender'
import { FEATURE_NAMES } from '../../../permissions/FeatureName.enum'

type UseUsersActionsProps = {
  t: TFunction
  organizationId: string
  onExportClicked: () => void
}

export const useUsersActions = ({ t, organizationId, onExportClicked }: UseUsersActionsProps) => {
  const { checkFeature } = useFeatureRender()

  const openUpdateUserForm = (item: User) =>
    useCreateForm(
      {
        title: t('USERS.FORM.DETAILS_TITLE'),
        isFooterAtBottom: true,
      },
      <UserForm user={item} organizationId={organizationId} />
    )

  const onAddUserClicked = useCreateForm(
    {
      title: t('USERS.FORM.ADD_TITLE'),
      isFooterAtBottom: true,
    },
    <UserForm organizationId={organizationId} />
  )

  const onImportUsersClicked = useCreateForm(
    {
      title: t('USERS.FORM.IMPORT_USERS'),
      isFooterAtBottom: true,
    },
    <ImportUsersForm />
  )

  const commandBarActions: ICommandItem[] = checkFeature(FEATURE_NAMES.USER_CREATE)
    ? [
        {
          key: 'create',
          text: t('general:BUTTON.CREATE'),
          iconProps: { iconName: 'Add' },
          onClick: () => onAddUserClicked(),
        },
        {
          key: 'import',
          text: t('USERS.FORM.IMPORT_USERS'),
          iconProps: { iconName: 'Upload' },
          onClick: onImportUsersClicked,
        },
        {
          key: 'export',
          text: t('USERS.FORM.EXPORT_USERS'),
          iconProps: { iconName: 'ExcelDocument' },
          onClick: onExportClicked,
        },
      ]
    : [
        {
          key: 'export',
          text: t('USERS.FORM.EXPORT_USERS'),
          iconProps: { iconName: 'ExcelDocument' },
          onClick: onExportClicked,
        },
      ]

  return {
    openUpdateUserForm,
    onAddUserClicked,
    commandBarActions,
  }
}
