import { useNavigate, useParams } from 'react-router-dom'
import {
  DashboardCardAction,
  DashboardMetricCard,
} from '../../../../../../components/Dashboard/DashboardMetricCard/DashboardMetricCard'
import { usePatchManagementSummaryData } from '../../usePatchManagementSummaryData'
import { useTranslation } from 'react-i18next'

interface Props {
  isInfo?: boolean
}

export const MustNotHaveSecurityPatchesNotApprovedCard = (props: Props) => {
  const { organizationId } = useParams()
  const { data: patchSummary } = usePatchManagementSummaryData()
  const navigate = useNavigate()
  const { t } = useTranslation('patch_management')

  const totalSecurityPatches =
    patchSummary?.patchesBySeverityAndStatus.reduce((acc, patch) => {
      if (patch.severity === 'Critical') {
        acc += patch.value
      }

      return acc
    }, 0) || 0
  const totalSecurityPatchesNotApproved =
    patchSummary?.patchesBySeverityAndStatus.reduce((acc, patch) => {
      if (patch.severity === 'Critical' && patch.status === 'NOT_APPROVED') {
        acc += patch.value
      }

      return acc
    }, 0) || 0

  const action = (): DashboardCardAction | undefined => {
    return {
      onClick: () => {
        navigate(`/organization/${organizationId}/patch-management/windows-patch-policies/`)
      },
      label: 'Fix',
    }
  }

  return (
    <DashboardMetricCard
      title={t('SUMMARY.RULES.MUST_NOT_HAVE_STALE_SECURITY_PATCHES_NOT_APPROVED.SUMMARY_ITEM_CARD.TITLE')}
      subtitle={`${totalSecurityPatches.toLocaleString()} ${t(
        'SUMMARY.RULES.MUST_NOT_HAVE_STALE_SECURITY_PATCHES_NOT_APPROVED.SUMMARY_ITEM_CARD.SUBTITLE'
      )}`}
      icon={{
        name: props.isInfo ? 'CheckMark' : 'Warning',
        color: props.isInfo ? 'green' : 'orange',
      }}
      value={totalSecurityPatchesNotApproved}
      action={props.isInfo ? undefined : action()}
    />
  )
}
