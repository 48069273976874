import { useMutation, useQueryClient } from '@tanstack/react-query'
import { WorkspaceGroupFormType } from '../../pages/organization-details/workspace-groups/WorkspaceGroupForm'
import graphClient from '../../query-client/graphClient'
import { gql } from 'graphql-request'

const createWorkspaceGroupQuery = gql`
mutation($businessId: String!, $input: WorkspaceGroupBaseInput!) {
  createWorkspaceGroup(businessId: $businessId, input: $input) {
    _id
  }
}
`

function createWorkspaceGroup(workspaceGroup: WorkspaceGroupFormType, businessId: string) {
  return graphClient
    .request(createWorkspaceGroupQuery, {
      businessId,
      input: workspaceGroup,
    })
    .then((response) => response.createWorkspaceGroup)
}

export default function (businessId: string) {
  const queryClient = useQueryClient()

  return useMutation(
    ['createWorkspaceGroup'],
    (workspaceGroup: WorkspaceGroupFormType) => createWorkspaceGroup(workspaceGroup, businessId),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['getWorkspaceGroupsByBusinessPaginated'])
      },
    }
  )
}
