import { Link } from '@fluentui/react'
import { theme } from 'app/config/theme'
import { FEATURE_NAME } from 'app/permissions/FeatureName.enum'
import { useFeatureRender } from 'app/permissions/useFeatureRender'
import { FC, PropsWithChildren } from 'react'

interface Props {
  callback?: () => void
}

const styles = {
  root: {
    textDecoration: 'none', color: theme.palette?.green,
    '&:hover, &:active, &:focus': {
      textDecoration: 'none',
      color: theme.palette?.greenDark,
    },
    '&:hover:active:focus': {
      textDecoration: 'none',
      color: theme.palette?.greenDark,
    }
  }
}

export const CellWithGreenLink: FC<PropsWithChildren<Props>> = ({ children, callback }) => {
  return (
    <Link onClick={callback} isUnderlined={false} {...{ styles }}>
      {children}
    </Link >
  )
}
