import { PropsWithChildren } from 'react'
import { Card, FlexxibleSecondaryButton, MOBILE_QUERY, useMediaQuery } from '@flexxibleit/flexxible-ui'
import { Icon, TooltipHost } from '@fluentui/react'

export interface DashboardCardAction {
  onClick: () => void
  label: string
}

interface DashboardMetricCardProps {
  title: string
  subtitle?: string
  value: number | string
  icon?: {
    name: string
    color?: string
  }
  unitLabel?: string
  action?: DashboardCardAction
  tooltip?: string
}

export const DashboardMetricCard = (props: PropsWithChildren<DashboardMetricCardProps>) => {
  const [isMobile] = useMediaQuery(MOBILE_QUERY)

  const cardProps = {
    key: props.title,
    style: {
      width: isMobile ? '85%' : '200px',
      height: '130px',
    },
  }

  const tooltipStyles = {
    root: {
      cursor: props.tooltip ? 'pointer' : 'cursor',
    },
  }

  return (
    <Card
      cardProps={
        props.tooltip ? { ...cardProps, style: { ...cardProps.style, width: isMobile ? '95%' : '200px' } } : cardProps
      }
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          height: '100%',
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'start',
            width: '100%',
          }}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
            }}
          >
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <h1
                style={{
                  margin: 0,
                }}
              >
                {props.value.toLocaleString() || 0} {props.unitLabel}
              </h1>
              {props.tooltip && (
                <TooltipHost content={props.tooltip} styles={tooltipStyles}>
                  <Icon
                    iconName={'info'}
                    style={{
                      height: '100%',
                      color: '#464A4F',
                      fontSize: '1.2em',
                      marginLeft: 8,
                    }}
                  />
                </TooltipHost>
              )}
            </div>
            {props.subtitle && (
              <span
                style={{
                  fontSize: 12,
                  color: 'gray',
                  margin: 0,
                }}
              >
                {props.subtitle}
              </span>
            )}
          </div>
          {props.icon && <Icon iconName={props.icon.name} style={{ fontSize: 25, color: props.icon.color }} />}
        </div>
        <h3>{props.title}</h3>
        {props.action && (
          <FlexxibleSecondaryButton
            onClick={props.action.onClick}
            style={{
              padding: '5px',
            }}
          >
            {props.action.label}
          </FlexxibleSecondaryButton>
        )}
      </div>
    </Card>
  )
}
