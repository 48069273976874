import { ColumnActionsMode, DetailsList, FontIcon, IColumn, SelectionMode } from "@fluentui/react"
import EditButton from "app/components/Buttons/EditButton.component"
import FlexxibleCard from "app/components/FlexxibleCard/FlexxibleCard.component"
import { MOBILE_QUERY } from "app/config/media-query"
import useMediaQuery from "app/hooks/utils/useMediaQuery"
import { MicroserviceTargetGroup } from "app/models/microservices/MicroserviceTargetGroup"
import { useTranslation } from "react-i18next"

interface Props {
  microservice: {
    _id: string
    workspaceGroupExecution: MicroserviceTargetGroup
    individualExecution: MicroserviceTargetGroup
  }
  canEdit: boolean
  onEdit: () => void
}

interface TargetGroup {
  targetGroupId: string
  group: boolean
  individual: boolean
}

const calculateItems = (workspaceGroupExecution: MicroserviceTargetGroup, individualExecution: MicroserviceTargetGroup): TargetGroup[] => {
  return [
    {
      targetGroupId: "L3",
      group: [MicroserviceTargetGroup.L3, MicroserviceTargetGroup.L2, MicroserviceTargetGroup.L1].includes(workspaceGroupExecution),
      individual: [MicroserviceTargetGroup.L3, MicroserviceTargetGroup.L2, MicroserviceTargetGroup.L1].includes(individualExecution),
    },
    {
      targetGroupId: "L2",
      group: [MicroserviceTargetGroup.L2, MicroserviceTargetGroup.L1].includes(workspaceGroupExecution),
      individual: [MicroserviceTargetGroup.L2, MicroserviceTargetGroup.L1].includes(individualExecution),
    },
    {
      targetGroupId: "L1",
      group: [MicroserviceTargetGroup.L1].includes(workspaceGroupExecution),
      individual: [MicroserviceTargetGroup.L1].includes(individualExecution),
    }
  ]
}

export const MicroserviceTargetGroups = ({ microservice, canEdit, onEdit }: Props) => {
  const { t } = useTranslation('microservices')
  const [isMobile] = useMediaQuery(MOBILE_QUERY);

  const columns: IColumn[] = [
    {
      key: 'targetGroupId',
      name: '',
      fieldName: 'targetGroupId',
      isRowHeader: true,
      minWidth: 50,
      maxWidth: 50,
      columnActionsMode: ColumnActionsMode.disabled,
    },
    {
      key: 'group',
      name: t('DETAILS.EXECUTION.WORKSPACE_GROUP_EXECUTION'),
      fieldName: 'group',
      isRowHeader: true,
      styles: {
        cellTitle: { justifyContent: 'center' }
      },
      minWidth: 100,
      maxWidth: 200,
      columnActionsMode: ColumnActionsMode.disabled,
    },
    {
      key: 'individual',
      name: t('DETAILS.EXECUTION.INDIVIDUAL_EXECUTION'),
      fieldName: 'individual',
      isRowHeader: true,
      styles: {
        cellTitle: { justifyContent: 'center' }
      },
      minWidth: 100,
      maxWidth: 200,
      columnActionsMode: ColumnActionsMode.disabled,
    },
  ]

  return (
    <FlexxibleCard cardProps={{ style: { padding: '0 16px 16px 16px', marginTop: isMobile ? 0 : 30 } }}>
      <div style={{ maxWidth: 600 }}>
        <DetailsList
          columns={columns}
          items={calculateItems(microservice.workspaceGroupExecution, microservice.individualExecution)}
          selectionMode={SelectionMode.none}
          onRenderItemColumn={_onRenderItemColumn}
        />
      </div>
      {canEdit && (
        <div className="d-flex d-flexRowReverse">
          <EditButton
            onClick={onEdit}
            style={{ marginLeft: '20px' }}
          />
        </div>
      )}
    </FlexxibleCard>
  )
}

const _onRenderItemColumn = (item?: any, _index?: number, column?: IColumn) => {
  let content = item[column!.key]

  if (['group', 'individual'].includes(column!.key)) {

    const icon = item[column!.key] ?
      <FontIcon iconName="CheckMark" style={{ color: "#3fb950" }} /> :
      <FontIcon iconName="Cancel" style={{ color: "#f85149" }} />

    content = icon
  }

  return (
    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', height: '100%' }}>
      {content}
    </div>
  )
}