import { FormActionsContainer, LoadingSpinner } from '@flexxibleit/flexxible-ui'
import { ISelectableOption, MessageBar, MessageBarType } from '@fluentui/react'
import { useBoolean } from '@fluentui/react-hooks'
import { zodResolver } from '@hookform/resolvers/zod'
import Dialog from 'app/components/Dialog/Dialog'
import { useSetMicroservicePrivacy } from 'app/hooks/microservices/useSetMicroservicePrivacy'
import { FormProvider, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import * as z from 'zod'
import RowColLayout from '../../../../components/Layouts/RowColLayout'
import FormActions from '../../../../components/forms/FormActions'
import ControlledToggleButton from '../../../../components/forms/ToggleButton/ToggleButton.component'
import Multiselect from '../../../../components/forms/multiselect/multiselect'
import { useGetOrganizationsForSelection } from '../../../../hooks/organization/useGetOrganizationsForSelection'
import { renderOrganizationSelectorDropdownItem } from 'app/pages/common/OrganizationSelectorDropdownItemRenderer/OrganizationSelectorDropdownItemRenderer'
import { useMyOrganization } from 'app/hooks/organization/useMyOrganization'
import { ConfirmationDialog } from 'app/components/ConfirmationDialog/ConfirmationDialog'
import { useEffect, useState } from 'react'
import { MicroserviceView } from '../../../../hooks/microservices/useGetDesignerMicroserviceById'
import ErrorStatus from '../../../../components/status/error-status'
import SuccessStatus from '../../../../components/status/success-status'
import { getTranslation } from 'app/models/microservices'

interface Props {
  microservice: MicroserviceView
}

interface FormData {
  isPrivate: boolean
  visibleTo: string[]
}

const schema: z.ZodType<FormData> = z.object({
  isPrivate: z.boolean({
    required_error: 'required',
  }),
  visibleTo: z.array(z.string()),
})

const MicroserviceDetailsPrivacyForm = ({ microservice }: Props) => {
  const { t, i18n } = useTranslation('microservices')
  const navigate = useNavigate()
  const { data: organizationList } = useGetOrganizationsForSelection(true)
  const { data: userOrganization } = useMyOrganization()
  const { mutateAsync, isError, isSuccess, isLoading } = useSetMicroservicePrivacy()
  const [hideDialog, { toggle: toggleHideDialog }] = useBoolean(true)
  const [isPrivatizing, setIsPrivatizing] = useState(false)

  const methods = useForm<FormData>({
    resolver: zodResolver(schema),
    defaultValues: {
      isPrivate: Boolean(microservice.privacySettings?.isPrivate),
      visibleTo: microservice.privacySettings?.visibleTo ?? [],
    },
  })

  const watchPrivacy = methods.watch('isPrivate')

  const onSubmitClick = () => {
    const { isPrivate, visibleTo } = methods.getValues()
    let newIsPrivatizing = methods.formState.defaultValues?.isPrivate !== isPrivate

    if (!newIsPrivatizing) {
      const keepAllOrganizations = methods.formState.defaultValues?.visibleTo?.every((id) => {
        return visibleTo.includes(id as string)
      })
      newIsPrivatizing = !keepAllOrganizations
    }

    setIsPrivatizing(newIsPrivatizing)

    toggleHideDialog()
  }

  const onSubmit = (data: FormData) => {
    return mutateAsync({
      microserviceId: microservice._id,
      input: {
        isPrivate: data.isPrivate,
        visibleTo: data.visibleTo,
      },
    }).then(() => {
      navigate(`/microservices-designer/${microservice._id}`)
    })
  }

  const handleOnDropdownRenderOption = (
    props?: ISelectableOption,
    _?: (props?: ISelectableOption) => JSX.Element | null
  ): JSX.Element | null => {
    return renderOrganizationSelectorDropdownItem(t, props, userOrganization, false)
  }

  if (isLoading) return <LoadingSpinner />

  if (isError) {
    return (
      <div className="d-flex h-100">
        <ErrorStatus message={t('DESIGNER.MICROSERVICE_FORM.UPDATE_ERROR')} />
      </div>
    )
  }
  if (isSuccess) {
    return (
      <div className="d-flex h-100">
        <SuccessStatus message={t('DESIGNER.MICROSERVICE_FORM.UPDATE_SUCCESS')} />
      </div>
    )
  }

  const renderConfirmation = () => {
    const name = getTranslation(microservice.name, i18n.language)
    const itemName = name.text

    if (isPrivatizing) {
      return (
        <ConfirmationDialog
          title={t('DESIGNER.PRIVACY.CONFIRMATION_EDIT')}
          description={t('DESIGNER.PRIVACY.CONFIRMATION_EDIT_MESSAGE')}
          actionButton={t('general:BUTTON.OK')}
          dismissButton={t('general:BUTTON.CANCEL')}
          hidden={hideDialog}
          onDismiss={toggleHideDialog}
          callback={() => {
            toggleHideDialog()
            onSubmit(methods.getValues())
          }}
          textRequired={itemName}
        >
          <MessageBar
            messageBarType={3}
            isMultiline={true}
            dismissButtonAriaLabel={t('general:CLOSE_LABEL')}
            className="mb-2"
            style={{ width: '100%' }}
          >
            {t('DESIGNER.PRIVACY.WARNING')}
          </MessageBar>
        </ConfirmationDialog>
      )
    }

    return (
      <Dialog
        title={t('DESIGNER.PRIVACY.CONFIRMATION_EDIT')}
        description={t('DESIGNER.PRIVACY.CONFIRMATION_EDIT_MESSAGE')}
        actionButton={t('general:BUTTON.OK')}
        dismissButton={t('general:BUTTON.CANCEL')}
        hidden={hideDialog}
        onDismiss={toggleHideDialog}
        callback={() => {
          toggleHideDialog()
          onSubmit(methods.getValues())
        }}
      />
    )
  }

  return (
    <>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmitClick)}>
          <RowColLayout rowProps={{ classNames: 'mt-2' }}>
            <ControlledToggleButton
              name="isPrivate"
              toggleProps={{
                inlineLabel: true,
                label: t('DESIGNER.PRIVACY.PRIVACY_INPUT_LABEL'),
                onText: t('DESIGNER.PRIVACY.PRIVATE'),
                offText: t('DESIGNER.PRIVACY.PUBLIC'),
              }}
            />
          </RowColLayout>
          {watchPrivacy ? (
            <RowColLayout rowProps={{ classNames: 'mt-2' }}>
              <Multiselect
                name="visibleTo"
                options={
                  organizationList?.map((organization) => ({
                    key: organization._id,
                    text: organization.name,
                  })) ?? []
                }
                translator={t}
                multiselectProps={{
                  label: t('DESIGNER.PRIVACY.VISIBLE_TO_INPUT_LABEL'),
                  placeholder: t('DESIGNER.PRIVACY.VISIBLE_TO_INPUT_PLACEHOLDER'),
                }}
                selectedList={microservice.privacySettings?.visibleTo ?? [microservice.business._id]}
                onRenderOption={handleOnDropdownRenderOption}
                styles={{
                  dropdownItemSelected: { height: 50 },
                  dropdownItem: { height: 50 },
                }}
              />
            </RowColLayout>
          ) : (
            <MessageBar
              messageBarType={MessageBarType.warning}
              isMultiline={true}
              dismissButtonAriaLabel={t('general:CLOSE_LABEL')}
              className="mb-2 mt-2"
            >
              {t('DESIGNER.PRIVACY.PUBLIC_WARNING')}
            </MessageBar>
          )}

          <FormActionsContainer>
            <FormActions
              submitProps={{
                text: t('general:BUTTON.SAVE'),
                iconProps: { iconName: 'Save' },
                disabled: !methods.formState.isDirty,
              }}
            />
          </FormActionsContainer>
        </form>
      </FormProvider>
      {renderConfirmation()}
    </>
  )
}

export default MicroserviceDetailsPrivacyForm
