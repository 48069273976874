import { Link } from '@fluentui/react'
import { FEATURE_NAME } from 'app/permissions/FeatureName.enum'
import { useFeatureRender } from 'app/permissions/useFeatureRender'
import { FC, PropsWithChildren } from 'react'

interface Props {
  feature: FEATURE_NAME
  callback?: () => void
  className?: string
}

export const CellWithFeatureLink: FC<PropsWithChildren<Props>> = ({ feature, children, callback, className }) => {
  const { checkFeature } = useFeatureRender()

  if (!checkFeature(feature)) {
    return <>{children}</>
  }

  return (
    <Link className={className} onClick={callback}>
      {children}
    </Link>
  )
}
