import Col, { ColProps } from "./Col";
import Row, { RowProps } from "./Row";

interface ColLayoutProps {
  rowProps?: RowProps;
  colProps?: ColProps;
  children: any;
}

function RowColLayout({ rowProps, colProps, children }: ColLayoutProps) {

  return (
    <>
      <Row {...rowProps}>
        <Col {...colProps}>
          {children}
        </Col>
      </Row>
    </>
  )
}

export default RowColLayout;