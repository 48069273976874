import { useQuery } from '@tanstack/react-query'
import { gql } from 'graphql-request'
import graphClient from '../../query-client/graphClient'
import { Product } from '../../query-client/types'

const getProductTypesQuery = gql`
  query getProducts($elegible: Boolean!) {
    getProducts(elegible: $elegible) {
      _id
      active
      name
      elegible
      regions {
        name
        deploymentCode
      }
      fixedBaseline
      uniqueCode
    }
  }
`

function getProductTypes(elegible = false): Promise<Product[]> {
  return graphClient.request(getProductTypesQuery, { elegible }).then((r) => r.getProducts)
}

export default function useGetProductTypes(elegible = false) {
  return useQuery(['getProductsTypes', elegible], () => getProductTypes(elegible), {
    initialData: [],
  })
}
