import * as icons from 'react-bootstrap-icons'

interface IconProps extends icons.IconProps {
  iconName: string
}

const BootstrapIcon = ({ iconName, ...props }: IconProps) => {
  const BootstrapIcon = icons[iconName as keyof typeof icons]

  if (!BootstrapIcon) {
    console.error(`Icon ${iconName} not found`)
    return null
  }

  return <BootstrapIcon {...props} />
}

export default BootstrapIcon