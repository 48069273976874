import { TFunction } from 'react-i18next'

export enum WorkspaceGroupType {
  STATIC = 'STATIC',
  DYNAMIC = 'DYNAMIC'
}

export const label = (t: TFunction): { [key in WorkspaceGroupType]: string } => ({
  [WorkspaceGroupType.STATIC]: t('TYPE.STATIC'),
  [WorkspaceGroupType.DYNAMIC]: t('TYPE.DYNAMIC')
})
