import { ApiKey } from '../../query-client/types'
import { gql } from 'graphql-request'
import graphClient from '../../query-client/graphClient'
import { useQuery } from '@tanstack/react-query'

const getOrganizationApiKeysQuery = gql`
query($businessId: String!) {
  getApiKeysByBusiness(businessId: $businessId) {
    _id
    programmaticUser {
      _id
      role
    }
    createdBy {
      _id
      name
      surname
      email
    }
    created_at
    lastUsedAt
    expiresAt
    revokedAt
  }
}
`

const getOrganizationApiKeys = (organizationId: string): Promise<ApiKey[]> => {
  return graphClient
    .request(getOrganizationApiKeysQuery, { businessId: organizationId })
    .then((response) => response.getApiKeysByBusiness)
}

export const useGetOrganizationApiKeys = (organizationId: string) => {
  return useQuery(
    ['getApiKeysByBusiness', organizationId],
    () => getOrganizationApiKeys(organizationId),
    {
      refetchOnWindowFocus: false,
    }
  )
}
