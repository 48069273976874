import { IRenderFunction, ITextFieldProps, TextField } from '@fluentui/react'
import IconLanguage from 'app/components/IconLanguage/IconLanguage'
import useMultilanguageForm from 'app/hooks/useMultilanguageForm'
import { Languages, getPreferredLanguage } from 'app/models/microservices'
import { useFormContext, useFormState } from 'react-hook-form'
import { TFunction, useTranslation } from 'react-i18next'
import Controlled from '../Controlled'
import '../forms.scss'

interface Props {
  name: string
  labelKey: string
  placeholderKey: string
  translator: TFunction
  required?: boolean
  textFieldProps?: ITextFieldProps
  multiLangIcon?: boolean
  multiLang?: boolean
  index?: number
}

export default function MultiLangTextField({
  name,
  labelKey,
  placeholderKey,
  translator: t,
  required = false,
  textFieldProps,
}: Props) {
  const { i18n } = useTranslation()
  const methods = useFormContext()
  const { control, getValues, setValue, watch } = useFormContext()
  const { errors } = useFormState({ control })
  const errorMessageKey: any = errors[name]?.message
  const preferredLanguage = getPreferredLanguage(i18n.language)

  const getCurrentTranslation = () => {
    const values = getValues()
    const index = values[name].findIndex((field: any) => field.code === preferredLanguage)

    return {
      name: `${name}[${index}].text`,
      index,
      ...values[name][index],
    }
  }
  const current = getCurrentTranslation()

  const onWrapDefaultLabelRenderer = (props?: ITextFieldProps, defaultRender?: IRenderFunction<ITextFieldProps>) => {
    if (!defaultRender) return null

    return (
      <span className={required ? 'required' : ''} style={{ display: 'flex', alignItems: 'center' }}>
        {defaultRender(props)}
        {current.translatedByIA && <IconLanguage tam="16" />}
      </span>
    )
  }

  const watchName = watch(name)
  const editForm = useMultilanguageForm(
    {
      title: t('general:MULTILANGUAGE.TITLE'),
      isFooterAtBottom: true,
    },
    name,
    watchName,
    (code: string, text: string) => {
      const values = getValues()
      const index = values[name].findIndex((field: any) => field.code === code)

      setValue(`${name}[${index}].text`, text)
      setValue(`${name}[${index}].translatedByIA`, false)
    },
    methods,
    labelKey,
    placeholderKey
  )

  return (
    <>
      <Controlled
        name={current.name}
        render={({ field: { value, onChange, ...others } }) => (
          <TextField
            {...textFieldProps}
            {...others}
            label={t(labelKey, {
              language: t(`general:LANGUAGE.${preferredLanguage.toUpperCase()}`).toLowerCase(),
            })}
            placeholder={t(placeholderKey, {
              language: t(`general:LANGUAGE.${preferredLanguage.toUpperCase()}`).toLowerCase(),
            })}
            onRenderLabel={onWrapDefaultLabelRenderer}
            value={value}
            onChange={(event, newValue) => {
              onChange(event, newValue)
              setValue(`${name}[${current.index}].translatedByIA`, false)
            }}
            invalid={!!errors[name]}
            errorMessage={!!errors[name] ? t(`ERRORS.${name}_${errorMessageKey.toLowerCase()}`) : ''}
            onRenderSuffix={() => {
              return (
                <span className="cursor-pointer" onClick={() => editForm()}>
                  {t('general:MULTILANGUAGE.BUTTON')}
                </span>
              )
            }}
          />
        )}
      />
    </>
  )
}

export const createDefaultMultiLanguageFields = () => {
  return Object.values(Languages).map((code) => ({
    code,
    text: '',
    translatedByIA: false,
  }))
}
