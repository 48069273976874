import { PatchesCount, PatchPolicyTarget } from '../../../../query-client/types'

interface WorkspacesCount {
  total: number
  totalWorkspacesWithoutReportingGroup: number
  totalWorkspacesWithPendingPatches: number
}

interface IPatchSummary {
  reportingGroupsWithoutPatchPolicyTarget: number
  targets: Partial<PatchPolicyTarget>[]
  patchesBySeverityAndStatus: PatchesCount[]
  workspacesCount: WorkspacesCount
}

export class PatchSummary {
  reportingGroupsWithoutPatchPolicyTarget: number
  targets: Partial<PatchPolicyTarget>[]
  patchesBySeverityAndStatus: PatchesCount[]
  workspacesCount: WorkspacesCount

  constructor(patchSummary: IPatchSummary) {
    this.reportingGroupsWithoutPatchPolicyTarget = patchSummary.reportingGroupsWithoutPatchPolicyTarget
    this.targets = patchSummary.targets
    this.patchesBySeverityAndStatus = patchSummary.patchesBySeverityAndStatus
    this.workspacesCount = patchSummary.workspacesCount
  }
}
