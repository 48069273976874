import { useQuery } from '@tanstack/react-query'
import graphClient from 'app/query-client/graphClient'
import { Business } from 'app/query-client/types'
import { gql } from 'graphql-request'

interface GetOrganizationTreeRequest {
  organizationId: string
}

const query = gql`
  query Request($organizationId: ID!) {
    getOrganizationTree(organizationId: $organizationId) {
      _id
      name
      reportingGroups {
        _id
        name
      }
    }
  }
`

interface GetOrganizationTreeResponse {
  getOrganizationTree: Business[]
}

async function getOrganizationTree(request: GetOrganizationTreeRequest) {
  const { getOrganizationTree }: GetOrganizationTreeResponse = await graphClient.request(query, {
    organizationId: request.organizationId,
  })

  return getOrganizationTree
}

export default function (request: GetOrganizationTreeRequest) {
  return useQuery(['getOrganizationTree', request.organizationId], () => getOrganizationTree(request), {
    refetchOnWindowFocus: false,
    enabled: request.organizationId !== '',
  })
}
