import {
  ControlledTextField,
  DeleteActionButton,
  Dialog,
  FormActionsContainer,
  LoadingSpinner,
  RowColLayout,
} from '@flexxibleit/flexxible-ui'
import { zodResolver } from '@hookform/resolvers/zod'
import { CompactPeoplePicker } from 'app/components/forms/PeoplePicker/CompactPeoplePicker'
import { FormProvider, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import * as z from 'zod'
import FormActions from '../../../../components/forms/FormActions'
import useOrganizationById from '../../../../hooks/organization/useOrganizationById'
import { useNavigate, useParams } from 'react-router-dom'
import { IPersonaProps } from '@fluentui/react/lib/Persona'
import { useCreatePatchPolicy } from '../../../../hooks/patchPolicies/useCreatePatchPolicy'
import { useUpdatePatchPolicy } from '../../../../hooks/patchPolicies/useUpdatePatchPolicy'
import { useEffect, useState } from 'react'
import SuccessStatus from '../../../../components/status/success-status'
import ErrorStatus from '../../../../components/status/error-status'
import ControlledToggleButton from 'app/components/forms/ToggleButton/ToggleButton.component'
import { PatchPolicy, WindowsPatchPolicy } from 'app/query-client/types'
import { useBoolean } from '@fluentui/react-hooks'
import { useFormSidebarContext } from 'app/components/forms/FormSidebar/state/FormSidebarState'
import { useDeletePatchPolicy } from 'app/hooks/patchPolicies/useDeletePatchPolicy'
import { useCreateWindowsPatchPolicy } from 'app/hooks/patchPolicies/useCreateWindowsPatchPolicy'
import { useDeleteWindowsPatchPolicy } from 'app/hooks/patchPolicies/useDeleteWindowsPatchPolicy'
import { useUpdateWindowsPatchPolicy } from 'app/hooks/patchPolicies/useUpdateWindowsPatchPolicy'

export interface CreateWindowsPatchPolicyFormType {
  name: string
}

export interface CreateOrUpdatePatchPolicyFormProps {
  windowsPatchPolicy?: WindowsPatchPolicy
}

const schema: z.ZodType<Partial<CreateWindowsPatchPolicyFormType>> = z.object({
  name: z
    .string({
      required_error: 'required',
    })
    .min(1, { message: 'required' }),
})

export const CreateWindowsPatchPolicyForm = ({ windowsPatchPolicy }: CreateOrUpdatePatchPolicyFormProps) => {
  const { t } = useTranslation('patch_management')
  const { organizationId } = useParams()
  const createWindowsPatchPolicy = useCreateWindowsPatchPolicy(organizationId || '')
  const updateWindowsPatchPolicy = useUpdateWindowsPatchPolicy()
  const deleteWindowsPatchPolicy = useDeleteWindowsPatchPolicy()
  const [hideDeleteDialog, { toggle: toggleHideDeleteDialog }] = useBoolean(true)
  const { closeSidebar } = useFormSidebarContext()
  const navigate = useNavigate()

  const [isSuccess, setIsSuccess] = useState(false)
  const [isError, setIsError] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    setIsSuccess(
      createWindowsPatchPolicy.isSuccess || updateWindowsPatchPolicy.isSuccess || deleteWindowsPatchPolicy.isSuccess
    )
    setIsError(createWindowsPatchPolicy.isError || updateWindowsPatchPolicy.isError || deleteWindowsPatchPolicy.isError)
    setIsLoading(
      createWindowsPatchPolicy.isLoading || updateWindowsPatchPolicy.isLoading || deleteWindowsPatchPolicy.isLoading
    )
  }, [createWindowsPatchPolicy, updateWindowsPatchPolicy, deleteWindowsPatchPolicy])

  const methods = useForm<CreateWindowsPatchPolicyFormType>({
    resolver: zodResolver(schema),
    defaultValues: windowsPatchPolicy ? ({ ...windowsPatchPolicy } as WindowsPatchPolicy) : {},
  })

  const handleSubmit = (data: CreateWindowsPatchPolicyFormType) => {
    if (windowsPatchPolicy?._id) {
      updateWindowsPatchPolicy.mutate({
        id: windowsPatchPolicy._id!,
        input: {
          name: data.name,
          automaticApprovals: windowsPatchPolicy?.automaticApprovals ?? [],
        },
      })
    } else {
      try {
        createWindowsPatchPolicy.mutate(
          {
            name: data.name,
          },
          {
            onSuccess: (data) => {
              if (data?._id) {
                navigate(`/organization/${organizationId}/patch-management/windows-patch-policies/${data?._id}`)
                closeSidebar()
              }
            },
          }
        )
      } catch (e) {
        console.error(e)
      }
    }
  }

  const onDelete = () => {
    toggleHideDeleteDialog()
  }
  const renderDeleteConfirmation = (): JSX.Element => {
    return (
      <Dialog
        title={t('DELETE_CONFIRMATION.TITLE')}
        description={t('DELETE_CONFIRMATION.MESSAGE')}
        actionButton={t('DELETE_CONFIRMATION.BUTTON_ACCEPT')}
        dismissButton={t('DELETE_CONFIRMATION.BUTTON_CANCEL')}
        hidden={hideDeleteDialog}
        onDismiss={toggleHideDeleteDialog}
        callback={() => {
          toggleHideDeleteDialog()
          handleDeletePolicy()
        }}
      />
    )
  }
  const handleDeletePolicy = () => {
    if (windowsPatchPolicy?._id) {
      deleteWindowsPatchPolicy.mutateAsync(windowsPatchPolicy?._id).then(() => {
        closeSidebar()
        navigate(-1)
      })
    }
  }

  if (isLoading) {
    return <LoadingSpinner />
  }

  if (isSuccess || isError) {
    return (
      <>
        {createWindowsPatchPolicy.isSuccess && <SuccessStatus message={t('PATCH_POLICIES.FORM.ADD_SUCCESS')} />}
        {updateWindowsPatchPolicy.isSuccess && <SuccessStatus message={t('PATCH_POLICIES.FORM.UPDATE_SUCCESS')} />}

        {createWindowsPatchPolicy.isError && (
          <>
            <ErrorStatus message={t('PATCH_POLICIES.FORM.ADD_ERROR')} />
          </>
        )}
        {updateWindowsPatchPolicy.isError && (
          <>
            <ErrorStatus message={t('PATCH_POLICIES.FORM.UPDATE_ERROR')} />
          </>
        )}

        {deleteWindowsPatchPolicy.isError && <ErrorStatus message={t('PATCH_POLICIES.FORM.DELETE_ERROR')} />}
      </>
    )
  }

  return (
    <>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(handleSubmit)}>
          <RowColLayout rowProps={{ classNames: 'mt-2' }}>
            <ControlledTextField
              name="name"
              required={true}
              translator={t}
              textFieldProps={{
                label: t('PATCH_POLICIES.FORM.NAME'),
                placeholder: t('PATCH_POLICIES.FORM.NAME_PLACEHOLDER'),
              }}
            />
          </RowColLayout>

          <ActionButtons isEdit={!!windowsPatchPolicy?._id} onDelete={onDelete} />
        </form>
        {renderDeleteConfirmation()}
      </FormProvider>
    </>
  )
}

const ActionButtons = ({ isEdit, onDelete }: { isEdit: boolean; onDelete: () => void }) => {
  const { t, i18n } = useTranslation()

  if (isEdit) {
    return (
      <FormActionsContainer isEdit>
        <DeleteActionButton locale={i18n.language} onClick={onDelete} />
        <FormActions
          submitProps={{
            text: t('general:BUTTON.SAVE'),
            iconProps: { iconName: 'Save' },
          }}
        />
      </FormActionsContainer>
    )
  }

  return (
    <FormActionsContainer>
      <FormActions
        submitProps={{
          text: t('general:BUTTON.CREATE'),
          iconProps: { iconName: 'Add' },
        }}
      />
    </FormActionsContainer>
  )
}
