import PageBody from 'app/components/PageBody/PageBody.component'
import { useGetFlow } from 'app/hooks/flows/useGetFlow'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { FlowOverviewForm } from './FlowOverviewForm'

export const FlowEditOverviewPage = () => {
  const { t } = useTranslation('flows')
  const { flowId } = useParams()
  const { data: flow, isLoading } = useGetFlow(flowId ?? '')

  return (
    <PageBody title={t('FORM.EDIT_TITLE')} isLoading={isLoading}>
      <FlowOverviewForm flow={flow!} />
    </PageBody>
  )
}
