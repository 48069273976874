import { Table as FlexxibleTable } from '@flexxibleit/flexxible-ui'
import { IColumn, IDetailsListProps } from '@fluentui/react'
import React, { useMemo } from 'react'
import { PaginationProps } from './Paginator'
import { getDefaultValuesColumns, saveColumnsConfig, loadColumnsConfig } from './ColumnsFactory'

interface Props {
  tableProps: IDetailsListProps
  isLoading?: boolean
  isEmpty?: boolean
  emptyMessage?: string
  paginationProps?: PaginationProps
  tableId?: string
}

const Table = ({ tableProps, isEmpty, isLoading, emptyMessage, paginationProps, tableId }: Props) => {
  const initColumns = getDefaultValuesColumns(tableProps.columns!, tableProps.items!.length)

  const defaultColumns = tableId ? loadColumnsConfig(tableId, initColumns) : initColumns

  const handleColumnResize = (column?: IColumn, newWidth?: number, columnIndex?: number) => {
    if (tableId && column && typeof newWidth === 'number' && typeof columnIndex === 'number') {
      const updatedColumns = [...defaultColumns]
      updatedColumns[columnIndex] = { ...column, maxWidth: newWidth }

      saveColumnsConfig(tableId, column.key, newWidth)
    }
  }

  const _getKey = (item: any, _?: number) => {
    return item.key
  }

  const getVerticallyCenteredCellContents = (contents: React.ReactNode): React.ReactNode => {
    return (
      <div
        style={{
          height: '100%',
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <div>{contents}</div>
      </div>
    )
  }

  const processedTableProps: IDetailsListProps = useMemo<IDetailsListProps>(() => {
    const onRenderDecorator = (
      onRenderFunction: (item?: any, index?: number, column?: IColumn) => React.ReactNode
    ): ((item?: any, index?: number, column?: IColumn) => React.ReactNode) => {
      return (item?: any, index?: number, column?: IColumn): React.ReactNode => {
        const cellContents: React.ReactNode = onRenderFunction(item, index, column)
        return getVerticallyCenteredCellContents(cellContents)
      }
    }

    return {
      ...tableProps,
      columns: defaultColumns!.map((column) => {
        if (!column.onRender) {
          return column
        }
        return {
          ...column,
          onRender: onRenderDecorator(column!.onRender),
        }
      }),
    }
  }, [tableProps])

  const defaultItemColumnRenderer = (item?: any, index?: number, column?: IColumn): React.ReactNode => {
    return getVerticallyCenteredCellContents(item[column!.key])
  }

  return (
    <FlexxibleTable
      tableProps={{
        onRenderItemColumn: defaultItemColumnRenderer,
        getKey: _getKey,
        setKey: 'none',
        ...processedTableProps,
        onColumnResize: handleColumnResize, // Asegúrate de que este prop se pasa correctamente
      }}
      isLoading={isLoading}
      isEmpty={isEmpty}
      emptyMessage={emptyMessage}
      paginationProps={paginationProps}
    />
  )
}

export default Table
