import { LoadingSpinner } from '@flexxibleit/flexxible-ui'
import { IBreadcrumbItem } from '@fluentui/react'
import { DisplayField } from 'app/components/DisplayField'
import IconLanguage from 'app/components/IconLanguage/IconLanguage'
import PageBody from 'app/components/PageBody/PageBody.component'
import { PRODUCT_ENV } from 'app/config/Consts'
import { ProductEnvTypes } from 'app/config/productEnv.enum'
import { useOrganizationSelection } from 'app/globals/useOrganizationSelection'
import { getTranslation } from 'app/models/microservices'
import { DateFormat, dateFormatter } from 'app/services/date-formatter'
import i18next from 'i18next'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import DataTableIcon from '../../../assets/img/data-table-svgrepo-com.svg'
import UserMultipleIcon from '../../../assets/img/user-multiple-svgrepo-com.svg'
import FlexxibleCard from '../../../components/FlexxibleCard/FlexxibleCard.component'
import Col from '../../../components/Layouts/Col'
import Row from '../../../components/Layouts/Row'
import { MOBILE_QUERY, TABLET_QUERY } from '../../../config/media-query'
import { useGetMicroserviceEventById } from '../../../hooks/microservices/useGetMicroserviceEventById'
import useMediaQuery from '../../../hooks/utils/useMediaQuery'
import { label } from '../../../models/microservices/MicroserviceEventType'
import SummaryCard from '../../organization-details/components/SummaryCard/SummaryCard'

export const MicroservicesAuditLogDetailsPage = () => {
  const navigate = useNavigate()
  const [isMobile] = useMediaQuery(MOBILE_QUERY)
  const [isTablet] = useMediaQuery(TABLET_QUERY)
  const { t } = useTranslation('microservices')
  const { microserviceEventId } = useParams()
  const { selectedOrganizationId } = useOrganizationSelection()
  const { data: microserviceEvent, isLoading } = useGetMicroserviceEventById(microserviceEventId!)

  useEffect(() => {
    if (microserviceEvent?.business?._id && microserviceEvent?.business?._id !== selectedOrganizationId) {
      navigate(`/microservices-events`)
    }
  }, [microserviceEvent, selectedOrganizationId])

  if (!microserviceEvent) {
    return <LoadingSpinner />
  }

  const cardProps = {
    style: { marginTop: 30 },
  }

  const onBreadcrumbItemClicked = (event?: React.MouseEvent, item?: IBreadcrumbItem) => {
    navigate(item?.key ?? '/home')
  }

  const name = getTranslation(microserviceEvent.microservice.name, i18next.language)
  const itemName = name.text
  const showNameIcon = name.translatedByIA

  const title = () => (microserviceEvent ? `${itemName} ${label(t)[microserviceEvent.type]}` : '')

  const breadcrumbItems: IBreadcrumbItem[] = [
    {
      text: `${t('AUDIT_LOG.DETAILS.BREADCRUMB')}`,
      key: '/microservices-events',
      onClick: onBreadcrumbItemClicked,
    },
    { text: title(), key: 'current', isCurrentItem: true },
  ]

  const formatDatetime = (datetime: string) =>
    datetime ? dateFormatter(t, new Date(datetime), DateFormat.DATETIME) : null

  return (
    <PageBody
      isLoading={isLoading}
      breadcrumbItems={breadcrumbItems}
      title={title()}
      titleTag={PRODUCT_ENV !== ProductEnvTypes.FXXONE ? 'PREVIEW' : undefined}
    >
      <>
        <div
          className={isMobile || isTablet ? 'd-flex d-flexCol d-flexColReverse' : 'd-flex d-flexRow col-xs-12'}
          style={isMobile || isTablet ? { gap: 0 } : { gap: 30, paddingRight: 20 }}
        >
          <FlexxibleCard cardProps={cardProps} styleNames="col-md-9 d-flex d-flexCol d-flexJustifyBetween">
            <>
              <Row>
                <Col className="col-xs-12 col-lg-6">
                  <DisplayField label={t('AUDIT_LOG.DETAILS.MICROSERVICE')}>
                    {itemName} {showNameIcon && <IconLanguage tam="16" />}
                  </DisplayField>
                </Col>
                <Col className="col-xs-12 col-lg-6">
                  <DisplayField label={t('AUDIT_LOG.DETAILS.TYPE')}>{label(t)[microserviceEvent.type]}</DisplayField>
                </Col>
              </Row>
              <Row>
                <Col className="col-xs-12 col-lg-6">
                  <DisplayField label={t('AUDIT_LOG.DETAILS.STATUS')}>{microserviceEvent.status}</DisplayField>
                </Col>
                {microserviceEvent.duration ? (
                  <Col className="col-xs-12 col-lg-6">
                    <DisplayField label={t('AUDIT_LOG.DETAILS.DURATION')}>{microserviceEvent.duration}</DisplayField>
                  </Col>
                ) : null}
              </Row>
              <Row>
                {microserviceEvent.reportStartTime ? (
                  <Col className="col-xs-12 col-lg-6">
                    <DisplayField label={t('AUDIT_LOG.DETAILS.REPORT_START_TIME')}>
                      {formatDatetime(microserviceEvent.reportStartTime)}
                    </DisplayField>
                  </Col>
                ) : null}
                {microserviceEvent.reportEndTime ? (
                  <Col className="col-xs-12 col-lg-6">
                    <DisplayField label={t('AUDIT_LOG.DETAILS.REPORT_END_TIME')}>
                      {formatDatetime(microserviceEvent.reportEndTime)}
                    </DisplayField>
                  </Col>
                ) : null}
              </Row>
              <Row>
                {microserviceEvent.executionStartTime ? (
                  <Col className="col-xs-12 col-lg-6">
                    <DisplayField label={t('AUDIT_LOG.DETAILS.EXECUTION_START_TIME')}>
                      {formatDatetime(microserviceEvent.executionStartTime)}
                    </DisplayField>
                  </Col>
                ) : null}
                {microserviceEvent.executionEndTime ? (
                  <Col className="col-xs-12 col-lg-6">
                    <DisplayField label={t('AUDIT_LOG.DETAILS.EXECUTION_END_TIME')}>
                      {formatDatetime(microserviceEvent.executionEndTime)}
                    </DisplayField>
                  </Col>
                ) : null}
              </Row>
              {microserviceEvent.data
                ? Object.keys(microserviceEvent.data).map((key, index) => (
                    <Row key={index}>
                      <Col className="col-xs-12 col-lg-6">
                        <DisplayField label={key}>{microserviceEvent.data![key]}</DisplayField>
                      </Col>
                    </Row>
                  ))
                : null}
            </>
          </FlexxibleCard>

          <div className="col-md-3 summary-container d-flexJustifyCenter d-flexAlignItemsCenter">
            <>
              <div
                className={isMobile || isTablet ? 'd-flex d-flexRow d-flexWrap' : 'd-flex d-flexCol '}
                style={{ gap: 20 }}
              >
                <SummaryCard
                  Icon={DataTableIcon}
                  title={t('AUDIT_LOG.DETAILS.LOGGED_AT')}
                  text={formatDatetime(microserviceEvent.loggedAt)!}
                />

                {microserviceEvent.initiatedBy ? (
                  <SummaryCard
                    Icon={UserMultipleIcon}
                    title={t('AUDIT_LOG.DETAILS.INITIATED_BY')}
                    text={microserviceEvent.initiatedBy}
                  />
                ) : null}
              </div>
            </>
          </div>
        </div>
      </>
    </PageBody>
  )
}
