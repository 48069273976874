import { AllTargetMustHaveScheduleCard } from './AllTargetMustHaveScheduleCard'
import { SummaryRule } from '../../usePatchManagementRuleEngine'
import { PatchSummary } from '../../../entities/PatchSummary'
import { PatchSummaryRuleSentences, SummarySectionType, SummaryType } from '../../types'
import { Time } from '../../../../../../query-client/types'

export const AllTargetMustHaveSchedule: SummaryRule = {
  rule: PatchSummaryRuleSentences.ALL_TARGET_MUST_HAVE_SCHEDULE,
  alertSummaryItem: {
    card: <AllTargetMustHaveScheduleCard />,
    type: SummaryType.Alert,
    section: SummarySectionType.Targets,
  },
  infoSummaryItem: {
    type: SummaryType.Information,
    section: SummarySectionType.Targets,
    card: <AllTargetMustHaveScheduleCard isInfo />,
  },
  passRule: (patchSummary: PatchSummary) => {
    return (
      patchSummary.targets.filter((target) => {
        if (!target.schedule) {
          return true
        }
        return Object.values(target.schedule).every((time: Time) =>
          Object.values(time).every((hour) => hour.length === 0)
        )
      }).length === 0
    )
  },
}
