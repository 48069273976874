import { useFormSidebarContext } from "../components/forms/FormSidebar/state/FormSidebarState";
import { LateralPanelProps } from "../components/lateral-panel/LateralPanel.component";

function useCreateForm(formSidebarProps: Partial<LateralPanelProps>, form: any) {
  const { openSidebar, setContent, setFormSidebarProps } = useFormSidebarContext();

  return () => {
    openSidebar()
    setFormSidebarProps(formSidebarProps)
    setContent(form);
  }
}

export default useCreateForm;

export function useCreateFormV2(formSidebarProps: Partial<LateralPanelProps>, form: any) {
  const { openSidebar, setContent, setFormSidebarProps, setError, setSuccess } = useFormSidebarContext();

  return {
    open: () => {
      openSidebar()
      setFormSidebarProps(formSidebarProps)
      setContent(form)
    },
    setError,
    setSuccess,
  }
}

export function useSidePanel() {
  const { openSidebar, closeSidebar, setContent, setFormSidebarProps } = useFormSidebarContext()

  return {
    openSidePanelWith: (formSidebarProps: Partial<LateralPanelProps>, form: any) => {
      openSidebar()
      setFormSidebarProps(formSidebarProps)
      setContent(form)
    },
    closeSidebar,
  }
}