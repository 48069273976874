import { Col, DataTableIcon, EditButton, MOBILE_QUERY, Row, TABLET_QUERY } from '@flexxibleit/flexxible-ui'
import { useCreateFormV2 } from 'app/hooks/useCreateForm'
import { useTranslation } from 'react-i18next'
import useMediaQuery from 'app/hooks/utils/useMediaQuery'
import FlexxibleCard from 'app/components/FlexxibleCard/FlexxibleCard.component'
import { DisplayField } from 'app/components/DisplayField'
import SummaryCard from 'app/pages/organization-details/components/SummaryCard/SummaryCard'
import { dateFormatter } from 'app/services/date-formatter'
import { PatchPolicyReportingGroupsTable } from '../../patch-policies/components/PatchPolicyReportingGroupsTable'
import AuthorIcon from '../../../../assets/img/AuthorIcon.svg'
import { LinuxPatchPolicy, PatchPolicy, PatchPolicyTarget, User } from 'app/query-client/types'
import { Link } from '@fluentui/react'
import { useNavigate, useParams } from 'react-router-dom'
import { useOrganizationSelection } from 'app/globals/useOrganizationSelection'
import useGetWindowsPatchPoliciesByOrganization from 'app/hooks/patchPolicies/useGetWindowsPatchPoliciesByOrganization'
import useGetWindowsPatchPolicy from 'app/hooks/patchPolicies/useGetWindowsPatchPolicy'
import { LinuxPatchPolicyTargetsTable } from '../components/LinuxPatchPolicyTargetsTable'
import { CreateLinuxPatchPolicyForm } from '../components/CreateLinuxPatchPolicyForm'
import { FeatureRender } from '../../../../permissions/FeatureRender'
import { FEATURE_NAMES } from '../../../../permissions/FeatureName.enum'

interface LinuxPatchPolicyDetailsTabProps {
  linuxPatchPolicy: LinuxPatchPolicy
}

interface LinuxPatchPolicyWithUser extends LinuxPatchPolicy {
  createdBy?: User
}

export const LinuxPatchPolicyDetailsTab = ({ linuxPatchPolicy }: LinuxPatchPolicyDetailsTabProps) => {
  const { t } = useTranslation('patch_management')
  const [isMobile] = useMediaQuery(MOBILE_QUERY)
  const [isTablet] = useMediaQuery(TABLET_QUERY)
  const isDesktop = !isMobile && !isTablet
  const cardProps = {
    style: { marginTop: 30 },
  }

  const { selectedOrganization } = useOrganizationSelection()
  // const { isLoading: isLoadingWindowsPatches, data: windowsPatchPolicies } = useGetWindowsPatchPolicy(
  //   { id: linuxPatchPolicy.windowsPatchPolicyId || '' },
  //   true
  // )

  const navigate = useNavigate()

  const { open: openEditPatchPolicyTargetForm } = useCreateFormV2(
    {
      title: t('LINUX_PATCH_POLICIES.EDIT_FORM'),
      isFooterAtBottom: true,
    },
    <CreateLinuxPatchPolicyForm linuxPatchPolicy={linuxPatchPolicy} />
  )

  const onClickTarget = (targetId: string) => {
    navigate(`/organization/${selectedOrganization._id}/patch-management/patch-policy-targets/${targetId}`)
  }

  return (
    <>
      <div
        className={!isDesktop ? 'd-flex d-flexCol d-flexColReverse' : 'd-flex d-flexRow col-xs-12'}
        style={!isDesktop ? { gap: 0 } : { gap: 30, paddingRight: 20 }}
      >
        <FlexxibleCard cardProps={cardProps} styleNames="col-md-9 d-flex d-flexCol d-flexJustifyBetween">
          <Row>
            <Col className="col-xs-12 col-lg-6">
              <DisplayField label={t('LINUX_PATCH_POLICIES.SUMMARY.NAME')}>{linuxPatchPolicy?.name}</DisplayField>
            </Col>
          </Row>

          <div className="d-flex d-flexRowReverse">
            <FeatureRender feature={FEATURE_NAMES.PATCH_MANAGEMENT_UPDATE}>
              <EditButton translator={t} onClick={openEditPatchPolicyTargetForm} />
            </FeatureRender>
          </div>
        </FlexxibleCard>

        <div className="col-md-3 summary-container d-flexJustifyCenter d-flexAlignItemsCenter">
          <>
            <div
              className={isMobile || isTablet ? 'd-flex d-flexRow d-flexWrap' : 'd-flex d-flexCol '}
              style={{ gap: 20 }}
            >
              <SummaryCard
                Icon={DataTableIcon}
                title={t('LINUX_PATCH_POLICIES.SUMMARY.CREATION_DATE')}
                text={linuxPatchPolicy?.createdAt ? dateFormatter(t, new Date(linuxPatchPolicy.createdAt)) : ''}
              />
              <SummaryCard
                Icon={AuthorIcon}
                title={t('LINUX_PATCH_POLICIES.SUMMARY.CREATED_BY')}
                text={
                  (linuxPatchPolicy as LinuxPatchPolicyWithUser).createdBy
                    ? (linuxPatchPolicy as LinuxPatchPolicyWithUser).createdBy?.name +
                      ' ' +
                      (linuxPatchPolicy as LinuxPatchPolicyWithUser).createdBy?.surname
                    : ''
                }
              />
            </div>
          </>
        </div>
      </div>
      <div
        style={{
          margin: '30px 10px',
        }}
      >
        <h3>{t('LINUX_PATCH_POLICIES.SUMMARY.TARGETS')}</h3>
        <LinuxPatchPolicyTargetsTable linuxPatchPolicy={linuxPatchPolicy} />
      </div>
    </>
  )
}
