import { EmptyStatus } from '@flexxibleit/flexxible-ui'
import EmptyUser from '../../../../../assets/img/user-icon.svg'
import { TFunction } from 'i18next'

type EmptyUserRolesProps = {
  t: TFunction
  onAddClicked: () => void
}

export const EmptyRolePermissions = ({ t, onAddClicked }: EmptyUserRolesProps) => {
  return (
    <div style={{ marginTop: 100 }}>
      <EmptyStatus
        img={EmptyUser}
        message={t('ROLES.TABLE.PERMISSIONS_EMPTY_MESSAGE')}
        buttonProps={{
          text: t('general:BUTTON.CREATE'),
          iconProps: { iconName: 'Add' },
          onClick: () => onAddClicked(),
        }}
      />
    </div>
  )
}
