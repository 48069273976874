import { DirectionalHint, Icon, ITooltipHostStyles, TooltipDelay, TooltipHost } from "@fluentui/react";
import { useId } from "@fluentui/react-hooks";
import { ReactNode, useState } from "react"
import { t } from "i18next";

interface Props {
  children: ReactNode
  value: string
  controlPosition?: 'right' | 'bottom'
}

const hostStyles: Partial<ITooltipHostStyles> = { root: { display: 'inline-block' } };

const Copy = ({ children, value, controlPosition }: Props) => {
  const tooltipId = useId(value);
  const [copied, setCopied] = useState(false)
  
  const copyToClipboard = (event: MouseEvent ) => {
    event.stopPropagation()
    setCopied(true)
    navigator.clipboard.writeText(value)

    setTimeout(() => {
      setCopied(false)
    }, 3000);
  }

  return (
    <div className={`d-flex ${!controlPosition || controlPosition === 'right' ? 'd-flexRow' : 'd-flexCol'}`}>
      <div className="d-flex d-flexGrow1">
      { children }
      </div>
      <div className="d-flex d-flexAlignSelfEnd">
        <TooltipHost 
              content={copied ? t('general:COPIED'): ''}
              delay={TooltipDelay.zero}
              id={tooltipId}
              closeDelay={500}
              directionalHint={DirectionalHint.topCenter}
              styles={hostStyles}>
              <Icon
                style={{
                  marginLeft: !controlPosition || controlPosition === 'right' ? 10 : 0,
                  marginTop: controlPosition === 'bottom' ? 10 : 0,
                  fontSize: 20,
                }}
                className="cursor-pointer"
                iconName="Copy"
                onClick={(e: any) => copyToClipboard(e)}
              />
        </TooltipHost>
      </div>
    </div>
  )
}

export default Copy