import {
  ErrorStatus,
  FormActionsContainer,
  LoadingSpinner,
  RowColLayout,
  SuccessStatus,
} from '@flexxibleit/flexxible-ui'
import { MessageBar, MessageBarType, ResponsiveMode } from '@fluentui/react'
import { zodResolver } from '@hookform/resolvers/zod'
import Dropdown from 'app/components/forms/Dropdown'
import FormActions from 'app/components/forms/FormActions'
import { MicroserviceView } from 'app/hooks/microservices/useGetDesignerMicroserviceById'
import { useUpdateMicroserviceCondition } from 'app/hooks/microservices/useUpdateMicroserviceCondition'
import { ConditionEnvironment, conditionEnvironmentOptions } from 'app/models/condition/ConditionEnvironment'
import { ValueType, valueTypeOptions } from 'app/models/flows/ValueType'
import { FormProvider, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import * as z from 'zod'

interface Props {
  microservice: MicroserviceView
}

interface FormData {
  valueType: ValueType
  environment: ConditionEnvironment
}

const schema: z.ZodType<Partial<FormData>> = z.object({
  valueType: z.nativeEnum(ValueType),
  environment: z.nativeEnum(ConditionEnvironment),
})

export const MicroserviceDetailsConditionForm = ({ microservice }: Props) => {
  const { t } = useTranslation('microservices')
  const { mutate, isError, isSuccess, isLoading } = useUpdateMicroserviceCondition()

  const methods = useForm<FormData>({
    resolver: zodResolver(schema),
    defaultValues: {
      valueType: microservice.condition?.valueType,
      environment: microservice.condition?.environment,
    },
  })
  const watchEnvironment = methods.watch('environment')

  const onSubmit = (data: FormData) => {
    return mutate({ id: microservice._id, input: data })
  }

  if (isLoading) return <LoadingSpinner />
  if (isError) {
    return (
      <div className="d-flex h-100">
        <ErrorStatus message={t('DESIGNER.MICROSERVICE_FORM.UPDATE_ERROR')} />
      </div>
    )
  }
  if (isSuccess) {
    return (
      <div className="d-flex h-100">
        <SuccessStatus message={t('DESIGNER.MICROSERVICE_FORM.UPDATE_SUCCESS')} />
      </div>
    )
  }

  const showWarning = watchEnvironment === ConditionEnvironment.production

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)} className="mb-2">
        <RowColLayout rowProps={{ classNames: 'mt-2' }}>
          <Dropdown
            name="valueType"
            required={true}
            translator={t}
            dropdownProps={{
              responsiveMode: ResponsiveMode.large,
              options: valueTypeOptions(t),
              label: t('DESIGNER.MICROSERVICE_FORM.VALUE_TYPE'),
              placeholder: t('DESIGNER.MICROSERVICE_FORM.VALUE_TYPE_PLACEHOLDER'),
              defaultSelectedKey: methods.getValues().valueType,
            }}
          />
        </RowColLayout>
        <RowColLayout rowProps={{ classNames: 'mt-2' }}>
          <Dropdown
            name="environment"
            required={true}
            translator={t}
            dropdownProps={{
              responsiveMode: ResponsiveMode.large,
              options: conditionEnvironmentOptions(t),
              label: t('DESIGNER.MICROSERVICE_FORM.ENVIROMENT'),
              placeholder: t('DESIGNER.MICROSERVICE_FORM.ENVIROMENT_PLACEHOLDER'),
              defaultSelectedKey: methods.getValues().environment,
            }}
          />
        </RowColLayout>
        {showWarning && (
          <RowColLayout rowProps={{ classNames: 'mt-' }}>
            <MessageBar
              messageBarType={MessageBarType.warning}
              isMultiline={true}
              dismissButtonAriaLabel={t('general:CLOSE_LABEL')}
              className="mb-2 mt-2"
            >
              {t('DESIGNER.MICROSERVICE_FORM.ENVIROMENT_WARNING')}
            </MessageBar>
          </RowColLayout>
        )}
        <FormActionsContainer>
          <FormActions
            submitProps={{
              text: t('general:BUTTON.SAVE'),
              iconProps: { iconName: 'Save' },
              disabled: !methods.formState.isDirty,
            }}
          />
        </FormActionsContainer>
      </form>
    </FormProvider>
  )
}
