import { PatchManagementDashboardData } from '../.././summary/MockData'
import Highcharts from 'highcharts/highstock'
import { DashboardChartCard } from '../../../../components/Dashboard/DashboardChartCard/DashboardChartCard';
import { PieChart } from '../../../../components/Charts/PieChart/PieChart'
import { useTranslation } from 'react-i18next'

interface Props {
  items: PatchManagementDashboardData['totalWorkspacesByReportingGroup']
}

export const TotalWorkspacesByReportingGroup = (props: Props) => {
  const { t } = useTranslation('patch_management');
  const series: Highcharts.SeriesPieOptions[] = [
    {
      type: 'pie',
      data: props.items.map((rg) => ({
        name: rg.reportingGroup,
        y: rg.value,
      }))
    }
  ]


  return (
    <DashboardChartCard
      title={t("DASHBOARD.REPORTING_GROUPS.TOTAL_WORKSPACES_BY_REPORTING_GROUP")}
      tooltip={t("DASHBOARD.REPORTING_GROUPS.TOTAL_WORKSPACES_BY_REPORTING_GROUP_TOOLTIP")}
      >
      <PieChart series={series} />
    </DashboardChartCard>
    )
}
