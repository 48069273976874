import {
  Controller,
  FieldValues,
  useFieldArray,
  useFormContext,
} from 'react-hook-form'
import { ActionButton, Icon, Label, TextField } from '@fluentui/react'
import { theme } from '../../../config/theme'
import { ControllerRenderProps } from 'react-hook-form/dist/types/controller'
import { TFunction } from 'react-i18next'

export interface ControlledTextArrayFieldProps {
  label: string
  name: string
  translator: TFunction
  disabled?: boolean
}

export const ControlledTextArrayField = (
  props: ControlledTextArrayFieldProps
) => {
  const { control } = useFormContext()

  const { fields, append, remove } = useFieldArray({
    name: props.name,
    control,
  })

  const onClickAdd = () => {
    append('')
  }

  const onClickDelete = (i: number) => {
    remove(i)
  }

  const renderLabel = () => {
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-between',
          width: '100%',
        }}
      >
        <Label
          disabled={props.disabled}
        >
          {props.label}
        </Label>
        {!props.disabled && <ActionButton
          text={props.translator('general:ADD')}
          iconProps={{
            iconName: 'Add',
          }}
          style={{ paddingRight: 0 }}
          onClick={onClickAdd}
          disabled={props.disabled}
        />}
      </div>
    )
  }

  const buildRenderer = (item: any, index: number, array: any[]) => {
    return ({
      field,
    }: {
      field: ControllerRenderProps<FieldValues, `${string}[${number}]`>
    }) => {
      const isLastItem = (index: number, array: any[]) => index === array.length - 1
      return (
        <div
          key={item + index}
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
            marginBottom: isLastItem(index, array) ? 0 : 10,
            width: '100%',
          }}
        >
          <div style={{ width: '100%' }}>
            <TextField
              style={{ width: '100%' }}
              maxLength={100}
              disabled={props.disabled}
              {...field}
            />
          </div>
          {!props.disabled && <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <Icon
              iconName="Delete"
              style={{ marginLeft: 10, fontSize: 20 }}
              styles={{
                root: {
                  cursor: 'pointer',
                  ':hover': {
                    color: theme.semanticColors?.errorIcon,
                  },
                },
              }}
              onClick={() => onClickDelete(index)}
            />
          </div>}
        </div>
      )
    }
  }

  return (
    <div>
      {renderLabel()}
      {fields?.length > 0 &&
        fields.map((item: any, index: number, array: any[]) => {
          return (
            <Controller
              render={buildRenderer(item, index, array)}
              name={`${props.name}[${index}]`}
              control={control}
            />
          )
        })}
    </div>
  )
}
