import { useQuery } from '@tanstack/react-query'
import graphClient from 'app/query-client/graphClient'
import { gql } from 'graphql-request'

interface GetTenantPolicyRequest {
  id: string
}

const query = gql`
  query Request($id: ID!) {
    getTenantPolicy(id: $id) {
      _id
      created_at
      updated_at
      applied_at
      name
      description
      accessAllUsers
      organizationId
      product
      tenants {
        _id
        name
        created_at
      }
      reportingGroups {
        _id
        name
      }
    }
  }
`

export interface TenantPolicyView {
  _id: string
  created_at: string
  updated_at: string
  applied_at: string
  name: string
  description: string
  accessAllUsers?: boolean | null
  organizationId: string
  tenants: TenantPolicyTenantView[]
  reportingGroups: TenantPolicyReportingGroupView[]
  product?: string
}
export interface TenantPolicyReportingGroupView {
  _id: string
  name: string
}

export interface TenantPolicyUserWithAccessView {
  _id: string
  name: string
  surname: string
  email: string
  workspacePermission: string
  analyzerPermission: string
  role: string
  language: string
}
export interface TenantPolicyTenantView {
  _id: string
  name: string
  created_at: string
}

interface Response {
  getTenantPolicy: TenantPolicyView
}

async function getTenantPolicy(request: GetTenantPolicyRequest) {
  const { getTenantPolicy }: Response = await graphClient.request(query, {
    id: request.id,
  })

  return getTenantPolicy
}

export default function (request: GetTenantPolicyRequest, shouldFetch: boolean) {
  return useQuery(['getTenantPolicy', request.id], () => getTenantPolicy(request), {
    refetchOnWindowFocus: false,
    enabled: shouldFetch,
  })
}
