import { LoadingSpinner, OutlinedButton } from '@flexxibleit/flexxible-ui'
import HighchartsReact from 'highcharts-react-official'
import Highcharts from 'highcharts/highstock'
import { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import './reports-chart.component.scss'
import hcMore from 'highcharts/highcharts-more'
import { Dropdown, IDropdownOption } from '@fluentui/react'
import { useConsumptionChart } from '../chart/chart-configutations/useConsumptionChart'
import { DateTime } from 'luxon'
import { useAccumulatedChart } from '../chart/chart-configutations/useAccumulatedChart'
import EmptyReports from '../../../app/assets/img/empty-reports.svg'
import { Report } from '../../query-client/types'
import EmptyStatus from '../status/empty-status'
import thousandParser from '../../services/thousand-parser'
import useMediaQuery from 'app/hooks/utils/useMediaQuery'
import { MOBILE_QUERY } from 'app/config/media-query'
import { DateFormat, dateFormatter } from 'app/services/date-formatter'
import DatePicker from 'app/components/DatePicker/DatePicker'


hcMore(Highcharts)
interface Props {
  reports: Report[],
  showNavigationButton?: boolean
  showAccumulatedData?: boolean
  onNavButtonClicked?: any
  showFilters?: boolean
  onFilterChange?: any
  showTable?: boolean
  startDate: DateTime
  endDate: DateTime
  isLoading?: boolean
  baseline?: number
}

export const ReportsChart = ({
  reports,
  showNavigationButton,
  showAccumulatedData,
  onNavButtonClicked,
  showTable,
  startDate,
  endDate,
  showFilters,
  onFilterChange,
  isLoading,
  baseline,
}: Props) => {
  const { t, i18n } = useTranslation('reports')
  const [ isMobile ] = useMediaQuery(MOBILE_QUERY)
  const tooltipText = t('WORKSPACES')
  const [ selectedRow, setSelectedRow ] = useState<number>()
  const [ filterStartDate, setFilterStartDate ] = useState<Date>(startDate.toJSDate())
  const [ filterEndDate, setFilterEndDate ] = useState<Date>(endDate.toJSDate())
  const [ dropdownSelected, setDropdownSelected ] = useState<string | null>('last_30_days')
  const [ chartConfig, setChartConfig ] = useState<any>()
  const chartComponentRef = useRef<HighchartsReact.RefObject>(null)
  const previousValuesRef = useRef({ filterStartDate, filterEndDate })
  const language = i18n.language

  useEffect(() => {
    const data = showAccumulatedData ?
      useAccumulatedChart({ reports, startDate, endDate, tooltipText, language, baseline, t }) :
      useConsumptionChart({ reports, startDate, endDate, tooltipText, language, baseline, t })
    setChartConfig(data)
  }, [reports])


  useEffect( () => {
    if (
      previousValuesRef.current.filterStartDate !== filterStartDate ||
      previousValuesRef.current.filterEndDate !== filterEndDate
    ) {
      onFilterChange(
        DateTime.fromJSDate(filterStartDate || new Date()).startOf('day'),
        DateTime.fromJSDate(filterEndDate || new Date()).endOf('day')
      )
      previousValuesRef.current = { filterStartDate, filterEndDate };
    }
  })

  const onRowClicked = (index: number) => {
    if (selectedRow !== index) {
      setSelectedRow(index)
      chartComponentRef.current?.chart.series.map((serie, i) => {
        i !== index ? serie.setVisible(false) : serie.setVisible(true)
      })
    } else {
      setSelectedRow(undefined)
      chartComponentRef.current?.chart.series.map((serie) => serie.setVisible(true))
    }

    chartComponentRef.current?.chart.redraw(true)
  }

  const onMouseEnter = (index: number) => {
    chartComponentRef.current?.chart.series[index].data.map(data => data.setState('hover'))
  }

  const onMouseLeave = (index: number) => {
    chartComponentRef.current?.chart.series[index].data.map(data => data.setState('normal'))
  }

  let dropdownOptions: IDropdownOption[] = [
    { key: 'last_30_days', text: t('LAST_30_DAYS')},
    { key: 'current_month', text: t('CURRENT_MONTH')},
    { key: 'last_3_months', text: t('LAST_3_MONTHS')}
  ]

  const onDropdownChange = (event?: React.FormEvent<HTMLDivElement>, item?: IDropdownOption) => {
    setDropdownSelected(String(item?.key))
    let startDate = DateTime.now()
    let endDate = DateTime.now()

    switch(String(item?.key)) {
      case 'current_month':
        startDate = startDate.startOf('month')
        endDate = endDate.endOf('day')
        break
      case 'last_30_days':
        startDate = startDate.minus({ days: 30 }).startOf('day')
        endDate = endDate.endOf('day')
        break
      case 'last_3_months':
        startDate = startDate.minus({ month: 2 }).startOf('month')
        endDate = endDate.endOf('day')
        break
    }
    setFilterStartDate(startDate.toJSDate())
    setFilterEndDate(endDate.toJSDate())
  }

  const MaximumColumnContent = ({ serie }: { serie: any }) => {
    let maximum: number = 0
    serie.data.forEach((data: any) => {
      maximum < data[0] ? maximum = data[0] : maximum
    })

    return (
      <>
        {thousandParser(maximum, i18n.language)}
      </>
    )
  }

  const getAdjustedAverage = (totalUsage: number, numberOfDays: number): number => {
    if (totalUsage === 0) {
      return 0
    }
    const average = totalUsage / numberOfDays
    if (average < 1) {
      return 1
    }
    return Math.round(average)
  }

  const AverageColumnContent = ({ serie }: { serie: any }) => {
    const numberOfDays = serie.data.length
    const totalUsage = serie.data.reduce((accu: number, current:any) => accu + current[0], 0)
    const average = getAdjustedAverage(totalUsage, numberOfDays)
    return (
      <>
        {thousandParser(average, i18n.language)}
      </>
    )
  }

  const formatFilterDate = (date?: Date) => {
    if (!date) return ''

    return dateFormatter(t, date, DateFormat.Medium)
  }

  return (

    <>
      <div className={ isMobile ? 'd-flex d-flexCol' : 'd-flex d-flexRow d-flexAlignItemsCenter d-flexJustifyBetween'}>
        <div style={{margin: '10px 10px', fontSize: 17, fontWeight: 700, color: '#8F9193'}}>
          {t('CONSUMPTION')}
        </div>
        { showNavigationButton &&
          <OutlinedButton style={{ marginRight: 10 }} onClick={onNavButtonClicked}> {t('REPORTS_EXPLORER')}</OutlinedButton>
        }
        { showFilters &&
          <div className={ isMobile ? 'd-flex d-flexCol' : 'd-flex d-flexRow d-flexAlignItemsCenter d-flexJustifyBetween'}>
            <Dropdown
              style={ isMobile ? { width: '100%', marginBottom: 15} : { width: 150, marginBottom: 5, marginRight: 27 }}
              selectedKey={dropdownSelected}
              onChange={onDropdownChange}
              placeholder={t('CUSTOM_DATE')}
              options={dropdownOptions}
            ></Dropdown>
            <div style={ isMobile ? {marginBottom: 30}  : {}} className="d-flex d-flexRow d-flexAlignItemsCenter d-flexJustifyBetween">
              <DatePicker
                value={filterStartDate}
                formatDate={formatFilterDate}
                style={{ width: 160, marginRight: 27}}
                maxDate={filterEndDate || DateTime.now().endOf('day').toJSDate()}
                placeholder={t('FROM')}
                onSelectDate={(date: any) => {
                  setFilterStartDate(date)
                  setDropdownSelected(null)
                }}
              />
              <DatePicker
                value={filterEndDate}
                formatDate={formatFilterDate}
                style={{ width: 160 }}
                minDate={filterStartDate}
                maxDate={DateTime.now().endOf('day').toJSDate()}
                placeholder={t('TO')}
                onSelectDate={(date: any) => {
                  setFilterEndDate(date)
                  setDropdownSelected(null)
                }}
              />
            </div>
          </div>
        }
      </div>

      { isLoading ? (
          <div style={{ height: 300}}>
            <LoadingSpinner />
          </div>
        ): (
        reports.length > 0 ? (
          <>
            <HighchartsReact
              highcharts={Highcharts}
              ref={chartComponentRef}
              allowChartUpdate={true}
              options={chartConfig}
            />

            { showTable && chartComponentRef &&
              <div className="table">
                <div className="d-flex d-flexRow d-flexAlignItemsStart table__header ">
                  <div className="d-flexAlignSelfCenter d-flexGrow2 table__header__column first-column"> {t('CATEGORY')}</div>
                  <div className="d-flexAlignSelfCenter d-flexGrow2 table__header__column" style={{textAlign: 'right'}}>{t('MAXIMUM')}</div>
                  {!isMobile && 
                    <div className="d-flexAlignSelfCenter d-flexGrow1 table__header__column last-column" style={{textAlign: 'right'}}>
                      {t('AVERAGE')}
                    </div>
                  }
                </div>
                { chartConfig?.series.map((serie: any, index: number) => (
                   serie.name !== 'Baseline' && (
                    <div className={'d-flex d-flexRow d-flexAlignItemsStart table__row cursor-pointer' + (selectedRow === index ? ' selected' : '')}
                        key={`${index}`}
                        onClick={ () => onRowClicked(index)}
                        onMouseEnter={() => onMouseEnter(index)}
                        onMouseLeave={() => onMouseLeave(index)}>
                      <div className="d-flexGrow2 d-flexAlignSelfCenter table__row__first-column">
                        <div className="d-flex d-flexRow">
                          <div style={{ backgroundColor: serie.color, width: 20, height: 20 }}></div>
                          <div style={{ marginLeft: 10 }}> {serie.name} </div>
                        </div>
                      </div>
                      <div className="d-flexAlignSelfCenter d-flexGrow2" style={{textAlign: 'right'}}>
                        <MaximumColumnContent serie={serie}/>
                      </div>
                      {
                        !isMobile && (
                          <div className="d-flexAlignSelfCenter d-flexGrow1 table__row__last-column" style={{textAlign: 'right'}}>
                            <AverageColumnContent serie={serie}/>
                          </div>
                        )
                      }
                    </div>
                   )
                ))}
              </div>
            }
          </>
          ): (
            <div style={{ marginTop: 100 }}>
              <EmptyStatus
                message={ filterStartDate && filterEndDate ?
                  t('EMPTY_MESSAGE_FILTER') :
                  t('EMPTY_MESSAGE_DEFAULT')}
                img={EmptyReports}
              />
            </div>
          )
        )
      }
    </>
  )
}