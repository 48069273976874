import { useQuery } from "@tanstack/react-query";
import { gql } from "graphql-request";
import graphClient from "../../query-client/graphClient";
import { Module } from "../../query-client/types";

const organizationModulesQuery = gql`
query($id: ID!) {
  getBusinessById(id: $id) {
    _id
    modules {
      _id
      type
      name
      tag
      url
      visibility
      order
    }
  }
}`

function getOrganizationModules(id: string): Promise<Module[]> {
  return graphClient
    .request(organizationModulesQuery, { id })
    .then(response => response.getBusinessById.modules)
}

export default function (businessId: string) {
  return useQuery(
    ['getOrganizationModules'],
    () => getOrganizationModules(businessId),
    {
      initialData: [],
      refetchOnWindowFocus: false
    },
  )
}
