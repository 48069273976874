import { BusinessType } from 'app/models/business/BusinessType'
import { MOBILE_QUERY, TablePageBody, useMediaQuery } from '@flexxibleit/flexxible-ui'
import { getOrganizationTypeIcon } from '../../../services/organization-icon-service'
import { useTranslation } from 'react-i18next'
import { useOrganizationSelection } from '../../../globals/useOrganizationSelection'
import { useNavigate, useParams } from 'react-router-dom'
import useOrganizationById from '../../../hooks/organization/useOrganizationById'
import React, { useEffect } from 'react'
import { FeedbackMessageBar } from '../../../components/FeedbackMessageBar/FeedbackMessageBar'
import { withFeatureRender } from '../../../permissions/withFeatureRender'
import { FEATURE_NAMES } from '../../../permissions/FeatureName.enum'
import { RolesFeature } from './Roles.feature'

const TitleComponent = ({ title, type }: { title: string; type?: BusinessType }) => {
  const [isMobile] = useMediaQuery(MOBILE_QUERY)

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'start',
      }}
    >
      {type && <div className="mr-1 vertical-center">{getOrganizationTypeIcon(type)}</div>}
      {isMobile ? <h3>{title}</h3> : <h1>{title}</h1>}
    </div>
  )
}

const OrgRoles = () => {
  const { t } = useTranslation('organization_details')
  const navigate = useNavigate()
  const { selectedOrganizationId } = useOrganizationSelection()
  const { organizationId } = useParams()
  const { isLoading, data, isFetching, refetch } = useOrganizationById(organizationId || '')
  const baseUrl = location.pathname.split('/')[1]

  useEffect(() => {
    if (!selectedOrganizationId) {
      return
    }
    navigate(`/${baseUrl}/${selectedOrganizationId}/roles`)
  }, [selectedOrganizationId])

  useEffect(() => {
    refetch()
  }, [organizationId])

  return (
    <TablePageBody
      title={isLoading || isFetching ? '' : data?.name || ''}
      titleComponent={<TitleComponent title={`${data?.name} - ${t('ROLES_PIVOT')}`} type={data?.type} />}
      isLoading={isLoading || isFetching}
    >
      <FeedbackMessageBar />
      <RolesFeature organizationId={data?._id} />
    </TablePageBody>
  )
}

export const OrgRolesPage = withFeatureRender(OrgRoles, FEATURE_NAMES.ROLE_READ)
