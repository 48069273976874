import Dropdown from '../Dropdown'
import { ICalloutProps, Icon, IDropdownOption, IDropdownProps, ResponsiveMode, SearchBox } from '@fluentui/react'
import { TFunction } from 'react-i18next'
import { useMemo, useState } from 'react'
import { ISelectableOption } from '@fluentui/react/src/utilities/selectableOption/SelectableOption.types'

export interface SearchableDropdownProps {
  name: string
  translator: TFunction
  options: IDropdownOption[]
  label: string
  placeholder: string
  defaultSelectedKey?: string | number
  searchBarPlaceholder: string
  optionIcon?: string
  allowEmpty?: {
    emptyOptionText: string
  }
  required?: boolean
  dropdownPropsStyles?: any
  calloutProps?: ICalloutProps
  disabled?: boolean
}

export const SearchableDropdown = (props: SearchableDropdownProps) => {
  const buildInitialOptions = useMemo(() => {
    const options = [...props.options]
    if (!!props.allowEmpty) {
      options.push({
        key: '',
        text: props.allowEmpty.emptyOptionText,
      })
    }
    return options
  }, [props.options])

  const [dropdownOptions, setDropdownOptions] = useState<ISelectableOption[]>(buildInitialOptions)

  const renderOption = (option?: ISelectableOption) => {
    if (option?.key === '') {
      return (
        <div className="d-flex d-center">
          <Icon iconName="Blocked" className="mr-2" />
          <p>{option?.text}</p>
        </div>
      )
    }
    return (
      <div className="d-flex d-center">
        {props.optionIcon && <Icon iconName={props.optionIcon} className="mr-2" />}
        <p>{option?.text}</p>
      </div>
    )
  }
  const renderList = (dropdownProps: any, defaultRender: any) => {
    return (
      <div>
        <div
          style={{
            position: 'relative',
            margin: '8px',
            borderBottom: '1px solid #ccc',
          }}
        >
          <SearchBox
            placeholder={props.searchBarPlaceholder}
            onChange={(e, value) => {
              const filteredOptions = buildInitialOptions.filter(
                (option: IDropdownOption) => option.text?.toLowerCase().indexOf(value!.toLowerCase()) !== -1
              )
              setDropdownOptions(filteredOptions)
            }}
          />
        </div>
        {defaultRender!(dropdownProps)}
      </div>
    )
  }

  return (
    <Dropdown
      name={props.name}
      translator={props.translator}
      required={props.required}
      dropdownProps={{
        options: dropdownOptions,
        label: props.label,
        placeholder: props.placeholder,
        defaultSelectedKey: props.defaultSelectedKey,
        onClick: () => {
          setDropdownOptions(buildInitialOptions)
        },
        onRenderOption: renderOption,
        onRenderList: renderList,
        styles: props.dropdownPropsStyles,
        disabled: props.disabled,
      }}
      calloutProps={props.calloutProps}
    />
  )
}
