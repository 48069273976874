import { useSidePanel } from 'app/hooks/useCreateForm'
import { MultiLanguageField, getTranslation } from 'app/models/microservices'
import { useTranslation } from 'react-i18next'
import { NodeProps } from 'reactflow'
import { useShallow } from 'zustand/react/shallow'
import { ActionSelector } from '../../panels/ActionSelector'
import useStore, { RFState } from '../../store/store'
import { BaseNode } from '../base/BaseNode'

export interface ActionNodeData {
  microservice: {
    id: string
    name: MultiLanguageField[]
    iconName?: string
    iconBackground?: string
  }
}

const selector = (state: RFState) => ({
  editing: state.editing,
  replaceAction: state.replaceAction,
})

export const ActionNode = (props: NodeProps<ActionNodeData>) => {
  const { i18n, t } = useTranslation('flows')
  const { editing, replaceAction } = useStore(useShallow(selector))
  const microservice = props.data.microservice
  const { openSidePanelWith, closeSidebar } = useSidePanel()

  const { text } = getTranslation(microservice.name, i18n.language)

  const onReplaceAction = () => {
    openSidePanelWith(
      {
        title: t('EDITOR.SELECTOR.ACTION.TITLE'),
        isFooterAtBottom: true,
        className: 'p-0',
      },
      <ActionSelector
        onSelect={(item) => {
          replaceAction(props.id, item)
          closeSidebar()
        }}
      />
    )
  }

  return (
    <BaseNode
      title={text}
      icon={microservice.iconName}
      color={microservice.iconBackground}
      onClick={() => {
        if (!editing) return
        onReplaceAction()
      }}
    ></BaseNode>
  )
}
