import { gql } from 'graphql-request'
import {
  PatchesBySeverityAndStatusCount,
} from '../../query-client/types'
import graphClient from '../../query-client/graphClient'
import { useQuery } from '@tanstack/react-query'

const getPatchesBySeverityAndStatus = gql`
  query($organizationId: String!) {
  getPatchManagementDashboard(organizationId: $organizationId) {
    securityPatchesBySeverityAndStatus {
      severity
      status
      value
    }
  }
}
`

function useGetPatchesBySeverityAndStatus(organizationId: string | undefined
): Promise<PatchesBySeverityAndStatusCount[]> {
  return graphClient
    .request(getPatchesBySeverityAndStatus, {organizationId})
    .then((response) => {
      return response.getPatchManagementDashboard.securityPatchesBySeverityAndStatus
    })
}

export default function (organizationId: string | undefined) {
  return useQuery(
    ['getPatchesBySeverityAndStatus', organizationId],
    () => useGetPatchesBySeverityAndStatus(organizationId),
    {
      refetchOnWindowFocus: false,
      keepPreviousData: true,
    }
  )
}
