import PageBody from 'app/components/PageBody/PageBody.component'
import { useTranslation } from 'react-i18next'
import { Tab, TabPanel } from 'app/components/tab-panel/TabPanel'
import { useNavigate, useParams } from 'react-router-dom'
import { IBreadcrumbItem } from '@fluentui/react'
import useGetLinuxPatchPolicy from 'app/hooks/patchPolicies/linux-patch-policy/useGetLinuxPatchPolicy'
import { LinuxPatchPolicyDetailsTab } from './tabs/LinuxPatchPolicyDetailsTab'
import { LinuxPatchPolicyAuditLogTab } from './tabs/LinuxPatchPolicyAuditLogTab'
import { LinuxPatchPoliciesExcludeListTab } from './tabs/LinuxPatchPoliciesExcludeListTab'
import { useEffect } from 'react'
import { useOrganizationSelection } from 'app/globals/useOrganizationSelection'

export const LinuxPatchPolicyDetails = () => {
  const { t, i18n } = useTranslation('patch_management')
  const navigate = useNavigate()

  const { organizationId, linuxPatchPolicyId } = useParams()

  const { data, isLoading } = useGetLinuxPatchPolicy({ id: linuxPatchPolicyId ?? '', organizationId }, true)
  const baseUrl = location.pathname.split('/')[1]
  const { selectedOrganization } = useOrganizationSelection()

  useEffect(() => {
    if (!selectedOrganization) {
      return
    }
    if (selectedOrganization._id !== organizationId) {
      navigate(`/${baseUrl}/${selectedOrganization._id}/patch-management/linux-patch-policies/`)
    }
  }, [selectedOrganization])

  const buildTabs = (): Tab[] => {
    if (!data) {
      return []
    }

    return [
      {
        title: t('LINUX_PATCH_POLICIES.TABS.DETAILS'),
        component: <LinuxPatchPolicyDetailsTab linuxPatchPolicy={data} />,
        key: 'details',
      },
      {
        title: t('LINUX_PATCH_POLICIES.TABS.EXCLUDE_LIST'),
        component: <LinuxPatchPoliciesExcludeListTab linuxPatchPolicy={data} />,
        key: 'exclude-list',
      },
    ]
  }

  const onBreadcrumbItemClicked = (event?: React.MouseEvent, item?: IBreadcrumbItem) => {
    navigate(item?.key ?? '/home')
  }

  const breadcrumbItems = [
    {
      text: t('LINUX_PATCH_POLICIES.TITLE'),
      key: `/organization/${organizationId}/patch-management/linux-patch-policies`,
      onClick: onBreadcrumbItemClicked,
    },
    { text: data?.name || '', key: 'current', isCurrentItem: true },
  ]

  return (
    <PageBody title={data?.name ?? ''} isLoading={isLoading} breadcrumbItems={breadcrumbItems}>
      <TabPanel tabs={buildTabs()} />
    </PageBody>
  )
}
