import { useMutation, useQueryClient } from '@tanstack/react-query'
import graphClient from 'app/query-client/graphClient'
import { AutomaticApproval } from 'app/query-client/types'
import { gql } from 'graphql-request'

interface UpdateWindowsPatchPolicyDetailsGraphqlRequest {
  name?: string
  automaticApprovals?: AutomaticApproval[]
}

interface Response {
  updateWindowsPatchPolicyDetails: {
    _id: string
  }
}

const mutation = gql`
  mutation Request($id: String!, $input: UpdateWindowsPatchPolicyDetailsGraphqlRequest!) {
    updateWindowsPatchPolicyDetails(id: $id, input: $input) {
      _id
    }
  }
`

const updateWindowsPatchPolicyDetails = async (
  id: string,
  input: UpdateWindowsPatchPolicyDetailsGraphqlRequest
): Promise<string> => {
  const response: Response = await graphClient.request(mutation, {
    id,
    input,
  })
  return response.updateWindowsPatchPolicyDetails._id
}

export const useUpdateWindowsPatchPolicy = () => {
  const queryClient = useQueryClient()

  return useMutation(
    ['updateWindowsPatchPolicyDetails'],
    ({ id, input }: { id: string; input: UpdateWindowsPatchPolicyDetailsGraphqlRequest }) =>
      updateWindowsPatchPolicyDetails(id, input),
    {
      onSuccess: async (id) => {
        await queryClient.invalidateQueries(['getWindowsPatchPolicy', id])
      },
    }
  )
}
