import { gql } from 'graphql-request'
import { PaginationArgs, PaginationResponse, Workspace } from '../../query-client/types'
import graphClient from '../../query-client/graphClient'
import { useQuery } from '@tanstack/react-query'

const workspacesByWorkspaceGroupIdQuery = gql`
query ($workspaceGroupId: ID!, $perPage: Int!, $page: Int!, $searchTerm: String!) {
  getWorkspacesByWorkspaceGroupId(workspaceGroupId: $workspaceGroupId, perPage: $perPage, page: $page, searchTerm: $searchTerm) {
    workspaces {
      _id
      name
      fqdn
      company
      sysDomain
      sysId
      uLastUser
      uOperativeSystem
      uRelatedEnvironment
      uHostname
      ipAddress
      uCoresCpu
      uRam
      uType
    }
    pageInfo {
      hasNextPage
      hasPreviousPage
      totalPages
      currentPage
      total
    }
  }
}
`

function useGetWorkspacesByWorkspaceGroupId(
  workspaceGroupId: string,
  searchTerm: string,
  pagination: PaginationArgs
): Promise<PaginationResponse<Workspace>> {
  return graphClient
    .request(workspacesByWorkspaceGroupIdQuery, {
      workspaceGroupId,
      page: pagination.page,
      perPage: pagination.perPage,
      searchTerm,
    })
    .then((response) => {
      return {
        results: response.getWorkspacesByWorkspaceGroupId.workspaces,
        pageInfo: response.getWorkspacesByWorkspaceGroupId.pageInfo,
      };
    });
}

export default function (workspaceGroupId: string, searchTerm:string, pagination: PaginationArgs) {
  return useQuery(
    ['getWorkspacesByWorkspaceGroupId', workspaceGroupId, searchTerm, pagination],
    () => useGetWorkspacesByWorkspaceGroupId(workspaceGroupId, searchTerm, pagination),
    {
      refetchOnWindowFocus: false,
      keepPreviousData: true,
    }
  );
}
