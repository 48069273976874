import Highcharts from 'highcharts'
import { useTranslation } from 'react-i18next'
import { DashboardChartCard } from '../../../../components/Dashboard/DashboardChartCard/DashboardChartCard'
import { BarChart } from '../../../../components/Charts/BarChart/BarChart'
import { PatchesBySeverityAndStatusCount, PatchSeverities, PatchStatus } from '../../../../query-client/types'

interface BarChartProps {
  items: PatchesBySeverityAndStatusCount[] | []
}

export const SecurityPatchesStatus = (props: BarChartProps) => {
  const { t } = useTranslation('patch_management')

  const categories = Object.keys(PatchSeverities).map((severity) => t(`DASHBOARD.SECURITY.${severity}`))
  const statusTranslator = (status: string) => {
    switch (status) {
      case 'APPROVED':
        return t('DASHBOARD.SECURITY.APPROVED')
      case 'NOT_APPROVED':
        return t('DASHBOARD.SECURITY.NOT_APPROVED')
      default:
        return t('DASHBOARD.SECURITY.UNKNOWN')
    }
  }

  const buildSeries = () => {
    const series: Highcharts.SeriesBarOptions[] = []
    const colors = ['#228B22', '#D22B2B']

    const severities = Object.keys(PatchSeverities).map((severity) => t(`DASHBOARD.SECURITY.${severity}`))
    const statuses = Object.values(PatchStatus)

    statuses.forEach((status) => {
      const data = severities.map((severity) => {
        const item = props.items.find((item) => item.status === status && item.severity === severity);
        return item ? item.value : 0;
      });

      series.push({
        type: 'bar',
        name: statusTranslator(status),
        data,
        color: status === 'APPROVED' ? colors[0] : colors[1],
      })
    })

    return series
  }

  return (
    <DashboardChartCard
      title={t('DASHBOARD.SECURITY.STATUS_SECURITY_PATCHES')}
      tooltip={t('DASHBOARD.SECURITY.STATUS_SECURITY_PATCHES_TOOLTIP')}
    >
      <BarChart series={buildSeries()} categories={categories} />
    </DashboardChartCard>
  )
}
