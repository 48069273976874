import { useMutation, useQueryClient } from '@tanstack/react-query'
import { gql } from 'graphql-request'
import graphClient from '../../query-client/graphClient'
import { ProductFormType } from 'app/pages/organization-details/products/ProductAgentSettingsForm'
import { usePaginatedProductsConfigQuery } from 'app/pages/organization-details/products/usePaginatedProductsConfigQuery'

export interface UpdateProductAgentSettingsRequest {
  autoUpdateAgents: boolean
  resourcesReportRecurrence: number
  profileStorageReportRecurrence?: number
  syncBrokerRecurrence?: number
  detectNewCitrixSubscriptions?: boolean
  collectEventLogs: boolean
  eventLogIds?: string
  eventLogsRecurrence?: number
  collectDisks: boolean
  collectServices: boolean
  collectPnPEvents: boolean
  collectPublicIP: boolean
  anyDeskSystemActionsRole: string
}

const editProductSettingsQuery = gql`
  mutation Request($id: ID!, $input: ProductAgentSettingsInput!) {
    updateProductAgentSettings(id: $id, input: $input) {
      active
    }
  }
`

function editProductSettings(id: string, request: UpdateProductAgentSettingsRequest) {
  return graphClient
    .request(editProductSettingsQuery, {
      id,
      input: request,
    })
    .then((response) => response)
}

export default function (productId: string) {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (request: UpdateProductAgentSettingsRequest) => editProductSettings(productId, request),
    onSuccess: () => {
      const key = usePaginatedProductsConfigQuery().queryName
      queryClient.invalidateQueries(['getBusinessById'])
      queryClient.invalidateQueries(['getBusinessProductConfigs'])
      queryClient.invalidateQueries([key])
    },
  })
}
