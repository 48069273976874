import { Slider as FluentSlider, ISliderProps, Label } from '@fluentui/react'
import Controlled from '../Controlled'
import { TooltipInfo } from 'app/components/Tooltip/TooltipInfo'

type Props = {
  name: string
  label: string
  required?: boolean
  sliderProps?: ISliderProps
  className?: string
  tooltip?: string
}

export const ControlledSlider = ({ name, label, required, className, sliderProps = {}, tooltip }: Props) => {
  return (
    <Controlled
      name={name}
      render={({ field }) => (
        <div className="col">
          <div className="row-12">
            <div
              style={{
                display: 'flex',
                justifyContent: 'flex-start',
                alignItems: 'end',
              }}
            >
              <Label className={className} required={required} disabled={sliderProps.disabled}>
                {label}
              </Label>
              {tooltip && <TooltipInfo text={tooltip} />}
            </div>
          </div>
          <div className="row-12">
            <FluentSlider {...sliderProps} {...field} />
          </div>
        </div>
      )}
    />
  )
}
