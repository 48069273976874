import { Separator, Spinner } from '@fluentui/react'
import { PropsWithChildren, Suspense, lazy } from 'react'
import { Handle, Position } from 'reactflow'
import './BaseNode.scss'
const BootstrapIcon = lazy(() => import('app/components/BootstrapIcon/BootstrapIcon'))

interface Props {
  title: string
  icon?: string
  color?: string
  backgroundColor?: string
  onClick?: () => void
}
export const BaseNode = (props: PropsWithChildren<Props>) => {
  const color = props.color ?? '#484f58'

  return (
    <>
      <Handle type="target" position={Position.Top} style={{ visibility: 'hidden', height: 1, minHeight: 1 }} />
      <div className="node-card" onClick={props.onClick}>
        <div className="node-card-container" style={{ borderLeftColor: color }}>
          <div
            className="node-card-main"
            style={props.backgroundColor ? { backgroundColor: props.backgroundColor } : {}}
          >
            <div className="node-card-header" role="button">
              <div className="node-card-content-container">
                <div className="panel-card-content-gripper-section"></div>
                <div className="node-card-content-icon-section">
                  {props.icon ? (
                    <Suspense fallback={<Spinner />}>
                      <BootstrapIcon
                        iconName={props.icon}
                        size={32}
                        style={
                          color ? { color: props.backgroundColor ? 'white' : color, minWidth: 32 } : { minWidth: 32 }
                        }
                      />
                    </Suspense>
                  ) : (
                    <div style={{ minWidth: 32, minHeight: 32 }}></div>
                  )}
                </div>
                <div className="node-card-top-content">
                  <div className="title" style={props.backgroundColor ? { color: 'white' } : {}}>
                    {props.title}
                  </div>
                </div>
              </div>
              <Separator styles={{ root: { height: 1, padding: 0 } }} />
              {props.children}
            </div>
          </div>
        </div>
      </div>
      <Handle type="source" position={Position.Bottom} style={{ visibility: 'hidden', height: 1, minHeight: 1 }} />
    </>
  )
}
