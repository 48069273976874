import { useQuery } from '@tanstack/react-query'
import { gql } from 'graphql-request'
import graphClient from '../../query-client/graphClient'

const organizationExistsOnBuildQuery = gql`
  mutation CheckOrganizationExistsOnBuild($id: ID!) {
    organizationExistsOnBuild(id: $id)
  }
`

async function organizationExistsOnBuild(id: string): Promise<boolean> {
  const data = await graphClient.request(organizationExistsOnBuildQuery, { id })
  return data.organizationExistsOnBuild
}

export default function useOrganizationExistsOnBuild(businessId: string) {
  return useQuery(['organizationExistsOnBuild', businessId], () => organizationExistsOnBuild(businessId), {
    enabled: !!businessId,
    refetchOnWindowFocus: false,
  })
}
