import { useMutation, useQueryClient } from '@tanstack/react-query'
import graphClient from 'app/query-client/graphClient'
import { gql } from 'graphql-request'

interface UpdateMicroserviceScriptRequest {
  microserviceId: string
  input: {
    content: string
  }
}

const query = gql`
  mutation ($input: UpdateMicroserviceScriptGraphqlRequest!, $microserviceId: ID!) {
    updateMicroserviceScript(id: $microserviceId, input: $input) {
      _id
    }
  }
`

const updateMicroserviceScript = async (request: UpdateMicroserviceScriptRequest): Promise<void> => {
  const response = await graphClient.request(query, request)

  return response.updateMicroserviceScript._id
}

export const useUpdateMicroserviceScript = () => {
  const queryClient = useQueryClient()

  return useMutation(
    ['updateMicroserviceScript'],
    ({ microserviceId, input }: UpdateMicroserviceScriptRequest) => updateMicroserviceScript({ microserviceId, input }),
    {
      onSuccess: (microserviceId) => {
        queryClient.invalidateQueries(['microservices'])
        queryClient.invalidateQueries(['microservices', microserviceId])
      },
    }
  )
}
