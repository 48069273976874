import { FormActionsContainer, LoadingSpinner } from '@flexxibleit/flexxible-ui'
import { DeleteActionButton } from '@flexxibleit/flexxible-ui'
import { IChoiceGroupOption } from '@fluentui/react'
import { useBoolean } from '@fluentui/react-hooks'
import { zodResolver } from '@hookform/resolvers/zod'
import { useEffect, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import * as z from 'zod'
import { Module } from '../../../../query-client/types'
import ChoiceGroup from '../../../../components/forms/choice-group/choise-group'
import Dropdown from '../../../../components/forms/Dropdown'
import FormActions from '../../../../components/forms/FormActions'
import ControlledTextField from '../../../../components/forms/TextField'
import RowColLayout from '../../../../components/Layouts/RowColLayout'
import ErrorStatus from '../../../../components/status/error-status'
import SuccessStatus from '../../../../components/status/success-status'
import useCreateModule from '../../../../hooks/modules/useCreateModule'
import useDeleteModule from '../../../../hooks/modules/useDeleteModule'
import useEditModule from '../../../../hooks/modules/useEditModule'
import useGetOrganizationModules from '../../../../hooks/modules/useGetOrganizationModules'
import { ModuleTypes } from './types'
import Dialog from '../../../../components/Dialog/Dialog'
import { FeatureRender } from '../../../../permissions/FeatureRender'
import { FEATURE_NAMES } from '../../../../permissions/FeatureName.enum'

interface Props {
  module?: Module
  organizationId: string
}

const httpsRegex = /^https:\/\/(.*)/

export const ModuleForm = ({ module, organizationId }: Props) => {
  const { t, i18n } = useTranslation('organization_details')

  const [hideDialog, { toggle: toggleHideDialog }] = useBoolean(true);

  const { data: currentModules, isFetching: modulesLoading } = useGetOrganizationModules(organizationId)

  const editModule = useEditModule()
  const createModule = useCreateModule()
  const deleteModule = useDeleteModule()

  const [ moduleType, setModuleType ] = useState(module?.type)
  const [ isVisibilityFull, setIsVisibilityFull ] = useState(false)
  const [ isSuccess, setIsSuccess ] = useState(false)
  const [ isError, setIsError ] = useState(false)
  const [ isLoading, setIsLoading ] = useState(false)
  const [ choiceGroupOptions, setChoiceGroupOptions] = useState<IChoiceGroupOption[]>()

  const choiceGroupOptionsDefault: IChoiceGroupOption[] = [
    { key: "1", text: t('MODULES.VISIBILITY.1'), iconProps: { iconName: 'RectangleShape'}},
    { key: "2", text: t('MODULES.VISIBILITY.2'), iconProps: { iconName: 'ThumbnailView'}},
  ]

  useEffect(() => {
    setIsLoading(modulesLoading || editModule.isLoading || createModule.isLoading || deleteModule.isLoading)
    setIsSuccess(editModule.isSuccess || createModule.isSuccess || deleteModule.isSuccess)
    setIsError(editModule.isError || createModule.isError || deleteModule.isError)
  },[ modulesLoading, editModule, createModule, deleteModule])

  useEffect(() => {
    const visibility1List = currentModules.filter(module => module.visibility === 1)
    if (!module && visibility1List?.length >= 5) {
      setIsVisibilityFull(true)
      choiceGroupOptionsDefault[0].disabled = true
      setChoiceGroupOptions(choiceGroupOptionsDefault)
    } else {
      choiceGroupOptionsDefault[0].disabled = false
      setChoiceGroupOptions(choiceGroupOptionsDefault)
    }

  }, [currentModules])

  const schema = z.object({
    type: z.string(),
    tag: z.string().optional(),
    url: z.string().regex(httpsRegex),
    visibility: z.string(),
    name: z.string().optional(),
  }).partial()
  .refine((schema) => {
    return !currentModules.find(element => element.url === schema.url && module?._id !== element._id)
  }, {
    message: "exist",
    path: ["url"],
  })
  .superRefine(
    (schema, ctx) => {
      if (schema.type === 'custom' && !schema.name) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          path: ["name"],
          message: 'required'
        });
      }
      if (!schema.url) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          path: ["url"],
          message: 'required'
        });
      }
    }
  )

  type FormSchemaType = z.infer<typeof schema>

  const getDefaultValues = (isVisibilityFull: boolean, module?: Module): FormSchemaType  => {

    return module ? {
      type: module.type,
      tag: module.tag || '',
      url: module.url || '',
      visibility: String(module.visibility),
      name: module.name || '',
    } : { visibility: isVisibilityFull ? "2" : "1" }
  }

  const defaultValues = getDefaultValues(isVisibilityFull, module)

  const methods = useForm<FormSchemaType>({ resolver: zodResolver(schema), defaultValues: defaultValues})

  const onTypeChanged = (selected: any) => {
    setModuleType(selected.key)
  }

  const _onSubmit = (data: FormSchemaType) => {
    const request: any = {...data}

    request.visibility = isVisibilityFull ? 2 : Number(data.visibility)
    request.business = organizationId

    if (module) {
      editModule.mutate({ moduleId: module._id, module: request })
    }
    else {
      createModule.mutate(request)
    }
  }

  return (
    <>
      {
        (!isLoading && !isSuccess && !isError) && (
          <FormProvider {...methods}>
            <form onSubmit={methods.handleSubmit(_onSubmit)}>
              <RowColLayout rowProps={{ classNames: "mt-2" }}>
                <Dropdown
                  name="type"
                  onChange={onTypeChanged}
                  translator={t}
                  dropdownProps={{
                    disabled: !!module,
                    defaultSelectedKey: defaultValues.type,
                    options: ModuleTypes,
                    label: t('MODULES.FORM.TYPE_LABEL'),
                    placeholder: t('MODULES.FORM.TYPE_PLACEHOLDER'),
                  }}
                />
              </RowColLayout>
              {moduleType && (
                <>
                  {moduleType === 'custom' && (
                    <RowColLayout rowProps={{ classNames: "mt-2" }}>
                      <ControlledTextField
                        name="name"
                        required={moduleType === 'custom'}
                        translator={t}
                        textFieldProps={{
                          label: t('MODULES.FORM.NAME_LABEL'),
                          placeholder: t('MODULES.FORM.NAME_PLACEHOLDER'),
                        }}
                      />
                    </RowColLayout>
                  )}
                  {moduleType !== 'custom' && (
                    <RowColLayout rowProps={{ classNames: "mt-2" }}>
                      <ControlledTextField
                        name="tag"
                        translator={t}
                        textFieldProps={{
                          label: t('MODULES.FORM.TAG_LABEL'),
                          placeholder: t('MODULES.FORM.TAG_PLACEHOLDER')
                        }}
                      />
                    </RowColLayout>
                  )}
                  <RowColLayout rowProps={{ classNames: "mt-2" }}>
                    <ControlledTextField
                      name="url"
                      required={true}
                      translator={t}
                      textFieldProps={{
                        label: t('MODULES.FORM.URL_LABEL'),
                        placeholder: t('MODULES.FORM.URL_PLACEHOLDER')
                      }}
                    />
                  </RowColLayout>

                  <RowColLayout rowProps={{ classNames: "mt-2" }}>
                    <ChoiceGroup
                      name="visibility"
                      choiceGroupProps = {{
                        defaultSelectedKey: defaultValues.visibility || ( isVisibilityFull ? "2" : "1" ),
                        options: choiceGroupOptions,
                        label: t('MODULES.FORM.VISIBILITY_LABEL')
                      }}
                    />
                  </RowColLayout>

                  <FormActionsContainer isEdit={!!module}>
                  { module ? (
                    <>
                      <FeatureRender feature={FEATURE_NAMES.MODULE_DELETE}>
                        <DeleteActionButton locale={i18n.language} onClick={toggleHideDialog} />
                      </FeatureRender>
                      <FormActions submitProps={{ text: t('general:BUTTON.SAVE'), iconProps: { iconName: 'Save' } }} />
                    </>
                  ) : (
                    <FormActions submitProps={{ text: t('general:BUTTON.CREATE'), iconProps: { iconName: 'Add' } }} />
                  )}
                  </FormActionsContainer>
                </>
              )}
            </form>
          </FormProvider>
        )
      }

      { isLoading && <LoadingSpinner></LoadingSpinner> }

      {
        (isSuccess || isError) && (
          <>
            { editModule.isSuccess &&
              <SuccessStatus message={ t('MODULES.FORM.EDIT_SUCCESS') }/>
            }

            { editModule.isError &&
              <ErrorStatus message={ t('MODULES.FORM.EDIT_ERROR') }/>
            }

            { createModule.isSuccess &&
              <SuccessStatus message={ t('MODULES.FORM.CREATE_SUCCESS') }/>
            }

            { createModule.isError &&
              <ErrorStatus message={ t('MODULES.FORM.CREATE_ERROR') }/>
            }

            { deleteModule.isSuccess &&
              <SuccessStatus message={ t('MODULES.FORM.DELETE_SUCCESS') }/>
            }

            { deleteModule.isError &&
              <ErrorStatus message={ t('MODULES.FORM.DELETE_ERROR') }/>
            }
          </>
        )
      }

      <Dialog
        title={ t('MODULES.FORM.CONFIRMATION_TITLE') }
        description={ t('MODULES.FORM.CONFIRMATION_MESSAGE') }
        actionButton={ t('general:BUTTON.DELETE') }
        dismissButton={ t('general:BUTTON.CANCEL') }
        callback={ () => {
          if (module) {
            toggleHideDialog()
            deleteModule.mutate({ moduleId: module._id })
          }
        } }
        hidden={ hideDialog }
        onDismiss={ toggleHideDialog }
        />
    </>
  )
}
