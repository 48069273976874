import { useMutation } from "@tanstack/react-query";
import graphClient from "app/query-client/graphClient";
import queryClient from "app/query-client/queryClient";
import { gql } from "graphql-request";

const query = gql`
  mutation ($input: UpdateEnabledMicroserviceExecutionSettingsGraphqlRequest!, $microserviceId: ID!, $organizationId: ID!) {
    updateEnabledMicroserviceExecutionSettings(id: $microserviceId, organizationId: $organizationId, input: $input) {
      _id
    }
  }
`

interface Response {
  updateEnabledMicroserviceExecutionSettings: { _id: string }
}

interface Request {
  workspaceGroupExecution: string
  individualExecution: string
  endUserExecutionWorkspaceGroups: string[]
}

interface Params {
  microserviceId: string
  organizationId: string
  input: Partial<Request>
}

const updateEnabledMicroserviceExecutionSettings = async (microserviceId: string, organizationId: string, input: Partial<Request>) => {
  const response: Response = await graphClient.request(query, {
    microserviceId,
    organizationId,
    input,
  })
  return response.updateEnabledMicroserviceExecutionSettings._id
}

export const useUpdateEnabledMicroserviceExecutionSettings = () => {
  return useMutation(
    ['updateEnabledMicroserviceExecutionSettings'],
    ({ microserviceId, organizationId, input }: Params) => updateEnabledMicroserviceExecutionSettings(microserviceId, organizationId, input),
    {
      onSuccess: (microserviceId, params) => {
        queryClient.invalidateQueries(['enabled-microservices'])
        queryClient.invalidateQueries(['enabled-microservice', microserviceId, params.organizationId])
      },
    }
  )
}