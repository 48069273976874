import 'reactflow/dist/style.css'

import { CancelButton, Dialog, EditButton, FlexxiblePrimaryButton } from '@flexxibleit/flexxible-ui'
import { MessageBar } from '@fluentui/react'
import { useBoolean } from '@fluentui/react-hooks'
import { useFeedbackMessage } from 'app/context/feedback-message/FeedbackMessageContext'
import { FlowDetail } from 'app/hooks/flows/useGetFlow'
import { useUpdateFlowFlow } from 'app/hooks/flows/useUpdateFlowFlow'
import { FEATURE_NAMES } from 'app/permissions/FeatureName.enum'
import { FeatureRender } from 'app/permissions/FeatureRender'
import { differenceBy } from 'lodash'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import ReactFlow, { Background, BackgroundVariant, Controls, Panel, ReactFlowProvider, useReactFlow } from 'reactflow'
import { useShallow } from 'zustand/react/shallow'
import { useFlowTabStore } from '../store'
import { ActionNode } from './nodes/action/ActionNode'
import { ConditionNode } from './nodes/condition/ConditionNode'
import { StarterNode } from './nodes/starter/StarterNode'
import useStore, { RFState } from './store/store'

const nodeTypes = {
  starter: StarterNode,
  condition: ConditionNode,
  action: ActionNode,
}

const selector = (state: RFState) => ({
  editing: state.editing,
  setEditing: state.setEditing,
  nodes: state.nodes,
  edges: state.edges,
  onNodesChange: state.onNodesChange,
  onEdgesChange: state.onEdgesChange,
  onConnect: state.onConnect,
  restore: state.restore,
  getFlow: state.getFlow,
  setFlow: state.setFlow,
})

interface Props {
  flow: FlowDetail
}
export const FlowFlow = ({ flow }: Props) => {
  const {
    nodes,
    edges,
    onNodesChange,
    onEdgesChange,
    editing,
    setEditing,
    getFlow: getFlow,
    setFlow: setFlow,
    restore,
  } = useStore(useShallow(selector))
  const { t, i18n } = useTranslation('flows')
  const reactFlowInstance = useReactFlow()
  const { mutateAsync } = useUpdateFlowFlow()
  const { setSuccessMessage, setErrorMessage } = useFeedbackMessage()
  const setState = useFlowTabStore((state) => state.setFlow)
  const state = useFlowTabStore((state) => state.flow)
  const [hideDialog, { toggle: toggleHideDialog }] = useBoolean(true)

  useEffect(() => {
    if (!editing) {
      return setState(undefined)
    }

    setState({
      nodes,
      edges,
    })
  }, [editing, nodes, edges])

  useEffect(() => {
    if (!state) {
      return setFlow(flow)
    }

    restore(state.nodes, state.edges)
  }, [])

  const onSave = async () => {
    const data = getFlow()

    if (!data.conditions || !data.microserviceId || !data.trigger) {
      return
    }

    await mutateAsync({
      id: flow.id,
      input: {
        microserviceId: data.microserviceId,
        trigger: data.trigger,
        conditions: data.conditions?.map((condition) => ({
          conditionTypeId: condition.conditionType.id,
          compareTo: condition.compareTo,
          period: condition.period,
          checkEvery: condition.checkEvery,
          metric: condition.metric,
          operator: condition.operator,
        })),
      },
    })
      .then(() => {
        setEditing(false)
        setSuccessMessage(t('EDITOR.SUCCESS'))
      })
      .catch(() => setErrorMessage(t('EDITOR.ERROR')))
  }

  const handleOnCancel = () => {
    const data = getFlow()
    const conditionsHasChanged = data.conditions?.length !== flow.conditions?.length
    const nDifferences =
      differenceBy(data.conditions, flow.conditions ?? [], 'conditionTypeId').length +
      differenceBy(data.conditions, flow.conditions ?? [], 'checkEvery').length +
      differenceBy(data.conditions, flow.conditions ?? [], 'metric').length +
      differenceBy(data.conditions, flow.conditions ?? [], 'operator').length +
      differenceBy(data.conditions, flow.conditions ?? [], 'compareTo').length +
      differenceBy(data.conditions, flow.conditions ?? [], 'period').length
    const isDirty = data.microserviceId !== flow.microservice?.id || conditionsHasChanged || nDifferences > 0

    if (isDirty) {
      toggleHideDialog()
      return
    }

    setEditing(false)
  }

  const editionButtons = () => {
    if (!editing) {
      return (
        <FeatureRender feature={FEATURE_NAMES.FLOWS_UPDATE}>
          <EditButton onClick={() => setEditing(true)} />
        </FeatureRender>
      )
    }

    return (
      <div className="d-flex d-flexJustifyEnd">
        <CancelButton locale={i18n.language} onClick={handleOnCancel} style={{ marginRight: '20px' }} />
        <FeatureRender feature={FEATURE_NAMES.FLOWS_UPDATE}>
          <FlexxiblePrimaryButton
            text={t('general:BUTTON.SAVE')}
            iconProps={{ iconName: 'Save' }}
            onClick={() => {
              onSave()
            }}
          />
        </FeatureRender>
      </div>
    )
  }
  const renderCancelEditDialog = (): JSX.Element => {
    return (
      <Dialog
        title={t('general:UNSAVED_CHANGES_DIALOG.TITLE')}
        description={t('general:UNSAVED_CHANGES_DIALOG.CANCEL_EDIT')}
        actionButton={t('general:UNSAVED_CHANGES_DIALOG.BUTTON_ACCEPT')}
        dismissButton={t('general:UNSAVED_CHANGES_DIALOG.BUTTON_CANCEL')}
        hidden={hideDialog}
        onDismiss={toggleHideDialog}
        callback={() => {
          setEditing(false)
          setFlow(flow)
          toggleHideDialog()
        }}
      >
        <MessageBar
          messageBarType={3}
          isMultiline={true}
          dismissButtonAriaLabel={t('general:CLOSE_LABEL')}
          className="mb-2"
          style={{ width: '100%' }}
        >
          {t('general:UNSAVED_CHANGES_DIALOG.WARNING')}
        </MessageBar>
      </Dialog>
    )
  }

  useEffect(() => {
    reactFlowInstance.fitView({
      padding: 0.5,
      maxZoom: 1.5,
    })
  }, [nodes.length])

  return (
    <>
      <ReactFlow
        nodeTypes={nodeTypes}
        nodes={nodes}
        edges={edges}
        onNodesChange={onNodesChange}
        onEdgesChange={onEdgesChange}
        nodesDraggable={false}
        proOptions={{ hideAttribution: true }}
      >
        <FeatureRender feature={FEATURE_NAMES.FLOWS_UPDATE}>
          <Panel position="top-right">{editionButtons()}</Panel>
        </FeatureRender>
        {editing && <Controls />}
        <AutoFit />

        <Background variant={BackgroundVariant.Dots} gap={12} size={1} />
      </ReactFlow>
      {renderCancelEditDialog()}
    </>
  )
}

const AutoFit = () => {
  const reactFlowInstance = useReactFlow()

  useEffect(() => {
    window.requestAnimationFrame(() => {
      reactFlowInstance?.fitView({
        padding: 0.5,
        maxZoom: 1.5,
      })
    })
  }, [reactFlowInstance])

  return null
}

export const FlowEditor = ({ flow }: Props) => {
  return (
    <ReactFlowProvider>
      <FlowFlow flow={flow} />
    </ReactFlowProvider>
  )
}
