import { zodResolver } from '@hookform/resolvers/zod'
import Dialog from 'app/components/Dialog/Dialog'
import { ReactNode, useEffect } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import * as z from 'zod'
import RowColLayout from '../Layouts/RowColLayout'
import ControlledTextField from '../forms/TextField'

interface ConfirmationDialogProps {
  title: string
  description: string
  actionButton: string
  dismissButton?: string
  callback: () => void
  onDismiss?: () => void
  hidden: boolean
  children?: ReactNode
  actionButtonDisabled?: boolean
  /** Text to write in order to continue */
  textRequired: string
}

interface FormData {
  text: string
}

export const ConfirmationDialog: React.FC<ConfirmationDialogProps> = ({
  title,
  description,
  textRequired,
  actionButton,
  dismissButton,
  hidden,
  children,
  callback,
  onDismiss,
  actionButtonDisabled,
}) => {
  const { t } = useTranslation('general')

  const schema: z.ZodType<FormData> = z.object({
    text: z.literal(textRequired),
  })

  const methods = useForm<FormData>({
    resolver: zodResolver(schema),
    defaultValues: { text: '' },
  })

  useEffect(() => {
    methods.reset({ text: '' })
  }, [hidden])

  return (
    <Dialog
      title={title}
      description={description}
      hidden={hidden}
      actionButton={actionButton}
      actionButtonDisabled={actionButtonDisabled || !methods.formState.isValid}
      dismissButton={dismissButton}
      onDismiss={onDismiss}
      callback={callback}
      maxWidth={'500px'}
    >
      {children}
      <FormProvider {...methods}>
        <RowColLayout rowProps={{ classNames: 'mt-2' }}>
          <ControlledTextField
            name="text"
            required={true}
            translator={t}
            textFieldProps={{
              label: t('CONFIRMATION_DIALOG.INPUT_LABEL', { value: textRequired }),
              autoComplete: 'off',
            }}
          />
        </RowColLayout>
      </FormProvider>
    </Dialog>
  )
}
