import { TFunction } from 'react-i18next'

export enum MicroserviceContext {
  SYSTEM = 'SYSTEM',
  SESSION = 'SESSION',
}

export const microserviceContextOptions = (t: TFunction) => {
  return Object.values(MicroserviceContext).map((context) => ({
    key: context,
    text: contextLabel(t)[context],
  }))
}

export const contextLabel = (t: TFunction): { [key in MicroserviceContext]: string } => ({
  [MicroserviceContext.SYSTEM]: t('MICROSERVICE_CONTEXT.SYSTEM'),
  [MicroserviceContext.SESSION]: t('MICROSERVICE_CONTEXT.SESSION'),
})
