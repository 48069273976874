import { CancelButton } from '@flexxibleit/flexxible-ui'
import { MessageBar, PrimaryButton, TextField } from '@fluentui/react'
import { useBoolean } from '@fluentui/react-hooks'
import Dialog from 'app/components/Dialog/Dialog'
import { useMicroserviceTabs } from 'app/context/MicroserviceTabsContext'
import { useUpdateMicroserviceScript } from 'app/hooks/microservices/useUpdateMicroserviceScript'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import EditButton from '../../../../components/Buttons/EditButton.component'
import { CodeEditor } from '../../../../components/CodeEditor/CodeEditor'
import FlexxibleCard from '../../../../components/FlexxibleCard/FlexxibleCard.component'
import Row from '../../../../components/Layouts/Row'
import { useFeedbackMessage } from '../../../../context/feedback-message/FeedbackMessageContext'

interface Props {
  microservice: {
    _id: string
    script?: string
    version?: number
    language: string
  }
  canEdit: boolean
}

export const MicroserviceDetailsScript = ({ microservice, canEdit }: Props) => {
  const tabsState = useMicroserviceTabs()
  const state = tabsState.state.script
  const { t, i18n } = useTranslation('microservices')
  const updateMicroservice = useUpdateMicroserviceScript()
  const { setSuccessMessage, setErrorMessage } = useFeedbackMessage()
  const [editing, setEditing] = useState(!!state)
  const [version, setVersion] = useState(state ? state.version : microservice.version ?? '')
  const [editedValue, setEditedValue] = useState(state ? state.text : microservice.script ?? '')
  const [hideDialog, { toggle: toggleHideDialog }] = useBoolean(true)

  const cardProps = {
    style: { marginTop: 30 },
  }

  const submit = (value: string) => {
    updateMicroservice
      .mutateAsync({
        microserviceId: microservice._id,
        input: { content: value },
      })
      .then(() => setSuccessMessage(t('DETAILS.SCRIPT.SUCCESS')))
      .catch(() => setErrorMessage(t('DETAILS.SCRIPT.ERROR')))
  }

  useEffect(() => {
    if (!editing) {
      const { script, ...newState } = { ...tabsState.state }

      return tabsState.setState(newState)
    }

    tabsState.setState({
      ...tabsState.state,
      script: {
        version: Number(version),
        text: editedValue,
      },
    })
  }, [editing, version, editedValue])

  const renderCancelEditDialog = (): JSX.Element => {
    return (
      <Dialog
        title={t('DETAILS.SCRIPT.CANCEL_EDIT_DIALOG.TITLE')}
        description={t('DETAILS.SCRIPT.CANCEL_EDIT_DIALOG.DESCRIPTION')}
        actionButton={t('DETAILS.SCRIPT.CANCEL_EDIT_DIALOG.BUTTON_ACCEPT')}
        dismissButton={t('DETAILS.SCRIPT.CANCEL_EDIT_DIALOG.BUTTON_CANCEL')}
        hidden={hideDialog}
        onDismiss={toggleHideDialog}
        callback={() => {
          setEditing(false)
          setEditedValue(microservice.script ?? '')
          toggleHideDialog()
        }}
      >
        <MessageBar
          messageBarType={3}
          isMultiline={true}
          dismissButtonAriaLabel={t('general:CLOSE_LABEL')}
          className="mb-2"
          style={{ width: '100%' }}
        >
          {t('DETAILS.SCRIPT.CANCEL_EDIT_DIALOG.WARNING')}
        </MessageBar>
      </Dialog>
    )
  }

  const handleOnCancel = () => {
    const initialValue = microservice.script ?? ''
    const isDirty = initialValue !== editedValue

    if (isDirty) {
      toggleHideDialog()
    } else {
      setEditing(false)
      setEditedValue(microservice.script ?? '')
    }
  }

  const editionButtons = () =>
    editing ? (
      <>
        <PrimaryButton
          text={t('general:BUTTON.SAVE')}
          onClick={() => {
            setEditing(false)
            submit(editedValue)
          }}
        />
        <CancelButton locale={i18n.language} onClick={handleOnCancel} style={{ marginRight: '20px' }} />
      </>
    ) : (
      <EditButton onClick={() => setEditing(true)} />
    )

  return (
    <FlexxibleCard cardProps={cardProps} styleNames="col-md-12 d-flex d-flexCol d-flexJustifyBetween">
      <div className="ml-2 mr-2">
        <Row classNames="d-flex d-flexJustifyBetween">
          {editing ? (
            <TextField
              label={t('DETAILS.SCRIPT.VERSION')}
              placeholder={t('DETAILS.SCRIPT.VERSION')}
              value={microservice.version?.toString() ?? ''}
              readOnly={true}
            />
          ) : (
            <span></span>
          )}

          <div className="d-flex d-flexAlignItemsEnd d-flexRowReverse">{canEdit && editionButtons()}</div>
        </Row>
      </div>
      <div style={{ marginTop: '30px' }}>
        <CodeEditor
          language={microservice?.language}
          value={editedValue}
          readOnly={!editing}
          onChange={(value) => setEditedValue(value ?? '')}
        />
        {renderCancelEditDialog()}
      </div>
    </FlexxibleCard>
  )
}
