import FlexxibleCard from '../../../components/FlexxibleCard/FlexxibleCard.component'
import { PersonaComponent } from '../../../components/persona/Persona.component'
import { Label, PersonaSize } from '@fluentui/react'
import { roleLocalizationKeyDictionary } from '../../../permissions/RoleFeature.enum'
import { Role, User } from '../../../query-client/types'
import { HTMLAttributes } from 'react'
import { useTranslation } from 'react-i18next'
import { DateFormat, dateFormatter } from 'app/services/date-formatter'

interface UserInfoCardProps {
  user: User
  portalRole?: Role
}

export const cardProps: HTMLAttributes<HTMLDivElement> = {
  style: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'start',
    padding: '20px',
    width: '85%',
    height: '100%',
  },
}

export const UserInfoCard = (props: UserInfoCardProps) => {
  const { t } = useTranslation('user_settings')
  const fieldStyles = {
    marginTop: '0',
  }

  return (
    <FlexxibleCard cardProps={cardProps}>
      <PersonaComponent size={PersonaSize.size72} />
      <h3
        style={{
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        }}
      >
        {props.user?.name} {props.user?.surname}
      </h3>
      <Label>{t('ROLE_LABEL')}</Label>
      <p style={fieldStyles}>{props.portalRole && t(roleLocalizationKeyDictionary[props.portalRole])}</p>
      <Label>{t('ORGANIZATION_LABEL')}</Label>
      <p style={fieldStyles}>{props.user?.business.name}</p>
      <Label>{t('EMAIL_LABEL')}</Label>
      <p style={fieldStyles}>{props.user?.email}</p>
      <Label>{t('CREATED_AT_LABEL')}</Label>
      <p style={fieldStyles}>{dateFormatter(t, props.user.created_at, DateFormat.Short)}</p>
    </FlexxibleCard>
  )
}
