import { useMutation, useQueryClient } from '@tanstack/react-query'
import graphClient from 'app/query-client/graphClient'
import { gql } from 'graphql-request'

const mutation = gql`
  mutation DeleteLinuxPatchPolicy($deleteLinuxPatchPolicyId: ID!) {
    deleteLinuxPatchPolicy(id: $deleteLinuxPatchPolicyId)
  }
`

const deleteLinuxPatchPolicy = async (id: string): Promise<string> => {
  await graphClient.request(mutation, { deleteLinuxPatchPolicyId: id })

  return id
}

export const useDeleteLinuxPatchPolicy = () => {
  const queryClient = useQueryClient()

  return useMutation(['deleteLinuxPatchPolicy'], (id: string) => deleteLinuxPatchPolicy(id), {
    onSuccess: async (id) => {
      await queryClient.invalidateQueries(['getLinuxPatchPoliciesByOrganization'])
    },
  })
}
