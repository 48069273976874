import { ISelectableOption, MessageBar, MessageBarType } from "@fluentui/react"
import { zodResolver } from "@hookform/resolvers/zod"
import RowColLayout from "app/components/Layouts/RowColLayout"
import FormActions from "app/components/forms/FormActions"
import ControlledToggleButton from "app/components/forms/ToggleButton/ToggleButton.component"
import Multiselect from "app/components/forms/multiselect/multiselect"
import { useOrganizationSelection } from 'app/globals/useOrganizationSelection'
import { useGetOrganizationClients } from 'app/hooks/organization/useGetOrganizationsClients'
import { useGetOrganizationsForSelection } from 'app/hooks/organization/useGetOrganizationsForSelection'
import { useMyOrganization } from 'app/hooks/organization/useMyOrganization'
import { renderOrganizationSelectorDropdownItem } from 'app/pages/common/OrganizationSelectorDropdownItemRenderer/OrganizationSelectorDropdownItemRenderer'
import { FormProvider, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import * as z from 'zod'

interface Props {
  initialData: MicroservicePrivacyFormData
  onSubmit: (data: MicroservicePrivacyFormData) => void
  onCancel: () => void
}

export interface MicroservicePrivacyFormData {
  isPrivate: boolean
  visibleTo: string[]
}

const schema: z.ZodType<MicroservicePrivacyFormData> = z.object({
  isPrivate: z.boolean({
    required_error: 'required',
  }),
  visibleTo: z.array(z.string()),
})

export const MicroserviceCreatePrivacy = ({ initialData, onSubmit, onCancel }: Props) => {
  const { t } = useTranslation('microservices')
  //const { data: organizationList } = useGetOrganizationsForSelection(true)
  const { data: userOrganization } = useMyOrganization()
  const { selectedOrganizationId } = useOrganizationSelection()

  const { data: organizationList } = useGetOrganizationClients(selectedOrganizationId || '')

  const defaultValues = {
    isPrivate: initialData?.isPrivate ?? false,
    visibleTo: initialData?.visibleTo ?? [],
  }

  const methods = useForm<MicroservicePrivacyFormData>({
    resolver: zodResolver(schema),
    defaultValues,
  })
  const watchPrivacy = methods.watch('isPrivate')

  const handleOnDropdownRenderOption = (
    props?: ISelectableOption,
    _?: (props?: ISelectableOption) => JSX.Element | null
  ): JSX.Element | null => {
    return renderOrganizationSelectorDropdownItem(t, props, userOrganization, false)
  }

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <RowColLayout rowProps={{ classNames: 'mt-2' }}>
          <ControlledToggleButton
            name="isPrivate"
            toggleProps={{
              inlineLabel: true,
              label: t('DESIGNER.PRIVACY.PRIVACY_INPUT_LABEL'),
              onText: t('DESIGNER.PRIVACY.PRIVATE'),
              offText: t('DESIGNER.PRIVACY.PUBLIC'),
              onChange: () => {},
            }}
          />
        </RowColLayout>
        {watchPrivacy ? (
          <RowColLayout rowProps={{ classNames: 'mt-2' }}>
            <Multiselect
              name="visibleTo"
              options={
                organizationList?.map((organization) => ({
                  key: organization._id,
                  text: organization.name,
                })) ?? []
              }
              translator={t}
              multiselectProps={{
                label: t('DESIGNER.PRIVACY.VISIBLE_TO_INPUT_LABEL'),
                placeholder: t('DESIGNER.PRIVACY.VISIBLE_TO_INPUT_PLACEHOLDER'),
              }}
              selectedList={defaultValues.visibleTo}
              onRenderOption={handleOnDropdownRenderOption}
              styles={{
                dropdownItemSelected: { height: 50 },
                dropdownItem: { height: 50 },
              }}
            />
          </RowColLayout>
        ) : (
          <MessageBar
            messageBarType={MessageBarType.warning}
            isMultiline={true}
            dismissButtonAriaLabel={t('general:CLOSE_LABEL')}
            className="mb-2 mt-2"
          >
            {t('DESIGNER.PRIVACY.PUBLIC_WARNING')}
          </MessageBar>
        )}

        <div className="d-flex d-center" style={{ marginTop: 40, justifyContent: 'right' }}>
          <FormActions
            cancelProps={{ onClick: () => onCancel() }}
            submitProps={{
              text: t('general:PAGINATION.NEXT'),
              iconProps: { iconName: 'Next' },
            }}
          />
        </div>
      </form>
    </FormProvider>
  )
}
