import { useQuery } from '@tanstack/react-query'
import graphClient from 'app/query-client/graphClient'
import { gql } from 'graphql-request'

export interface BasicUser {
  _id: string
  surname: string
  name: string
  email: string
  business: string
}

const query = gql`
  query Request($businessId: ID!) {
    getBusinessAndPartnerUsers(businessId: $businessId) {
      _id
      surname
      name
      email
      business
    }
  }
`

function getBusinessAndPartnerUsers(organizationId: string): Promise<BasicUser[]> {
  return graphClient.request(query, { businessId: organizationId }).then((data) => data.getBusinessAndPartnerUsers)
}

export default function useGetBusinessAndPartnerUsers(organizationId: string) {
  return useQuery(['businessAndPartnerUsers'], () => getBusinessAndPartnerUsers(organizationId), {
    refetchOnWindowFocus: false,
  })
}
