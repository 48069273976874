import { useMutation, useQueryClient } from '@tanstack/react-query'
import graphClient from 'app/query-client/graphClient'
import { gql } from 'graphql-request'

interface UpdateLinuxPatchPolicyDetailsGraphqlRequest {
  name: string
  excludes: {
    name: string
    rule: string
  }[]
}

interface Response {
  updateLinuxPatchPolicyDetails: {
    _id: string
  }
}

const mutation = gql`
  mutation UpdateLinuxPatchPolicyDetails(
    $input: UpdateLinuxPatchPolicyDetailsGraphqlRequest!
    $updateLinuxPatchPolicyDetailsId: String!
  ) {
    updateLinuxPatchPolicyDetails(input: $input, id: $updateLinuxPatchPolicyDetailsId) {
      _id
    }
  }
`

const updateLinuxPatchPolicyDetails = async (
  id: string,
  input: UpdateLinuxPatchPolicyDetailsGraphqlRequest
): Promise<string> => {
  const response: Response = await graphClient.request(mutation, {
    input,
    updateLinuxPatchPolicyDetailsId: id,
  })
  return response.updateLinuxPatchPolicyDetails._id
}

export const useUpdateLinuxPatchPolicy = () => {
  const queryClient = useQueryClient()

  return useMutation(
    ['updateLinuxPatchPolicyDetails'],
    ({ id, input }: { id: string; input: UpdateLinuxPatchPolicyDetailsGraphqlRequest }) =>
      updateLinuxPatchPolicyDetails(id, input),
    {
      onSuccess: async (id) => {
        await queryClient.invalidateQueries(['getLinuxPatchPolicy', id])
      },
    }
  )
}
