import { Col, Dialog, EditButton, Row } from '@flexxibleit/flexxible-ui'
import { MessageBar } from '@fluentui/react'
import { useBoolean } from '@fluentui/react-hooks'
import { zodResolver } from '@hookform/resolvers/zod'
import { DisplayField } from 'app/components/DisplayField'
import FlexxibleCard from 'app/components/FlexxibleCard/FlexxibleCard.component'
import Dropdown from 'app/components/forms/Dropdown'
import FormActions from 'app/components/forms/FormActions'
import Multiselect from 'app/components/forms/multiselect/multiselect'
import { useFeedbackMessage } from 'app/context/feedback-message/FeedbackMessageContext'
import useOrganizationById from 'app/hooks/organization/useOrganizationById'
import { FlowDetail } from 'app/hooks/flows/useGetFlow'
import useGetFlowWorkspaces from 'app/hooks/flows/useGetFlowWorkspaces'
import { useUpdateFlowTarget } from 'app/hooks/flows/useUpdateFlowTarget'
import useWorkspaceGroupsByOrganization from 'app/hooks/workspaceGroup/useWorkspaceGroupsByOrganization'
import { FlowTargetType, targetTypeLabel, flowTargetTypeOptions } from 'app/models/flows/FlowTargetType'
import { FEATURE_NAMES } from 'app/permissions/FeatureName.enum'
import { FeatureRender } from 'app/permissions/FeatureRender'
import { PaginationArgs } from 'app/query-client/types'
import { useEffect, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import * as z from 'zod'
import { useFlowTabStore } from '../store'
import { TablaSelector } from './components/TableSelector'
import { getColumns } from './components/WorkspaceColumns'
import { WorkspaceSelector } from './components/WorkspaceSelector'

interface Props {
  flow: FlowDetail
}

export const FlowTarget = ({ flow }: Props) => {
  const { t } = useTranslation('flows')
  const { mutateAsync } = useUpdateFlowTarget()
  const { setSuccessMessage, setErrorMessage } = useFeedbackMessage()
  const setState = useFlowTabStore((state) => state.setTarget)
  const state = useFlowTabStore((state) => state.target)
  const [editing, setEditing] = useState(Boolean(state))

  useEffect(() => {
    if (!editing) {
      return setState(undefined)
    }

    setState({
      targetType: flow.targetType,
      workspaces: flow.workspaces ?? [],
      workspaceGroups: flow.workspaceGroups?.map((e) => e._id) ?? [],
      reportingGroups: flow.reportingGroups?.map((e) => e._id) ?? [],
    })
  }, [editing])

  const onSubmit = (data: FlowTargetData) => {
    mutateAsync({
      id: flow.id,
      input: {
        targetType: data.targetType,
        workspaces: data.workspaces,
        workspaceGroups: data.workspaceGroups,
        reportingGroups: data.reportingGroups,
      },
    })
      .then(() => {
        setEditing(false)
        setSuccessMessage(t('EDITOR.SUCCESS'))
      })
      .catch(() => {
        setErrorMessage(t('EDITOR.ERROR'))
      })
  }

  return (
    <div className="d-flex d-flexCol col-xs-12">
      <FlexxibleCard cardProps={{ style: { marginTop: 36 } }} styleNames="d-flex d-flexCol d-flexJustifyBetween">
        <div className="d-flex d-flexAlignItemsEnd d-flexRowReverse">
          <FeatureRender feature={FEATURE_NAMES.FLOWS_UPDATE}>
            {!editing && <EditButton onClick={() => setEditing(!editing)} style={{ marginLeft: '20px' }} />}
          </FeatureRender>
        </div>

        {editing ? (
          <FlowTargetForm onSubmit={onSubmit} flow={flow} onCancel={() => setEditing(false)} />
        ) : (
          <FlowTargetDisplay flow={flow} />
        )}
      </FlexxibleCard>
    </div>
  )
}

interface DisplayProps {
  flow: FlowDetail
}
const FlowTargetDisplay = ({ flow }: DisplayProps) => {
  const { t } = useTranslation('flows')
  const [paginationState, setPaginationState] = useState<PaginationArgs>(() => ({
    page: 1,
    perPage: 10,
  }))
  const {
    data: workspacesPagination,
    isLoading: isLoadingWorkspaces,
    isFetching: isFetchingWorkspaces,
  } = useGetFlowWorkspaces(flow.id, paginationState, flow.targetType === FlowTargetType.WORKSPACES)

  return (
    <>
      <DisplayField label={t('TARGET.TARGET_TYPE')}>
        {flow.targetType ? targetTypeLabel(t)[flow.targetType] : '-'}
      </DisplayField>
      {flow.targetType === FlowTargetType.WORKSPACE_GROUPS && (
        <DisplayField label={t('TARGET.WORKSPACE_GROUPS')}>
          {flow.workspaceGroups?.map((e) => e.name).join(', ') ?? '-'}
        </DisplayField>
      )}
      {flow.targetType === FlowTargetType.REPORTING_GROUPS && (
        <DisplayField label={t('TARGET.REPORTING_GROUPS')}>
          {flow.reportingGroups?.map((e) => e.name).join(', ') ?? '-'}
        </DisplayField>
      )}
      {flow.targetType === FlowTargetType.WORKSPACES && (
        <TablaSelector
          columns={getColumns(t)}
          items={workspacesPagination?.results ?? []}
          paginatedData={workspacesPagination}
          pagination={paginationState}
          onPaginationChange={setPaginationState}
          editable={false}
          isLoading={isLoadingWorkspaces}
          isFetching={isFetchingWorkspaces}
        />
      )}
    </>
  )
}

interface FlowTargetData {
  targetType: FlowTargetType
  workspaces: string[]
  workspaceGroups: string[]
  reportingGroups: string[]
}

const schema: z.ZodType<Partial<FlowTargetData>> = z
  .object({
    targetType: z.enum(
      [
        FlowTargetType.ALL_WORKSPACES,
        FlowTargetType.WORKSPACES,
        FlowTargetType.WORKSPACE_GROUPS,
        FlowTargetType.REPORTING_GROUPS,
      ],
      {
        invalid_type_error: 'required',
        required_error: 'required',
      }
    ),
    workspaces: z.array(z.string()),
    workspaceGroups: z.array(z.string()),
    reportingGroups: z.array(z.string()),
  })
  .superRefine((values, ctx) => {
    if (values.targetType === FlowTargetType.WORKSPACE_GROUPS && values.workspaceGroups?.length === 0) {
      return ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: 'required',
        path: ['workspaceGroups'],
      })
    }
    if (values.targetType === FlowTargetType.REPORTING_GROUPS && values.reportingGroups?.length === 0) {
      return ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: 'required',
        path: ['reportingGroups'],
      })
    }
  })

interface FormProps {
  flow: FlowDetail
  onSubmit: (data: FlowTargetData) => void
  onCancel: () => void
}
const FlowTargetForm = ({ flow, onSubmit, onCancel }: FormProps) => {
  const { t } = useTranslation('flows')
  const { data } = useWorkspaceGroupsByOrganization(flow.business)
  const { data: organization } = useOrganizationById(flow.business)
  const setState = useFlowTabStore((state) => state.setTarget)
  const state = useFlowTabStore((state) => state.target)
  const [hideDialog, { toggle: toggleHideDialog }] = useBoolean(true)

  const methods = useForm<FlowTargetData>({
    resolver: zodResolver(schema),
    defaultValues: {
      targetType: flow.targetType,
      workspaces: flow.workspaces ?? [],
      workspaceGroups: flow.workspaceGroups?.map((e) => e._id) ?? [],
      reportingGroups: flow.reportingGroups?.map((e) => e._id) ?? [],
    },
  })
  const watchTargetType = methods.watch('targetType')
  const watchWorkspaceGroups = methods.watch('workspaceGroups')
  const watchReportingGroups = methods.watch('reportingGroups')
  const watchWorkspaces = methods.watch('workspaces')

  useEffect(() => {
    setState(methods.getValues())
  }, [watchTargetType, watchWorkspaceGroups, watchReportingGroups, watchWorkspaces])

  useEffect(() => {
    if (!state) {
      return
    }

    methods.reset(state)
  }, [])

  const itemsWithKey = (data ?? []).map((item) => ({ key: item.workspaceGroup._id, text: item.workspaceGroup.name }))

  const renderCancelEditDialog = (): JSX.Element => {
    return (
      <Dialog
        title={t('general:UNSAVED_CHANGES_DIALOG.TITLE')}
        description={t('general:UNSAVED_CHANGES_DIALOG.CANCEL_EDIT')}
        actionButton={t('general:UNSAVED_CHANGES_DIALOG.BUTTON_ACCEPT')}
        dismissButton={t('general:UNSAVED_CHANGES_DIALOG.BUTTON_CANCEL')}
        hidden={hideDialog}
        onDismiss={toggleHideDialog}
        callback={() => {
          methods.reset()
          onCancel()
          toggleHideDialog()
        }}
      >
        <MessageBar
          messageBarType={3}
          isMultiline={true}
          dismissButtonAriaLabel={t('general:CLOSE_LABEL')}
          className="mb-2"
          style={{ width: '100%' }}
        >
          {t('general:UNSAVED_CHANGES_DIALOG.WARNING')}
        </MessageBar>
      </Dialog>
    )
  }

  const isDirty = methods.formState.isDirty
  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <div className="d-flex d-flexAlignItemsEnd d-flexRowReverse">
          <FormActions
            cancelProps={{
              onClick: () => {
                if (isDirty) {
                  return toggleHideDialog()
                }

                methods.reset()
                onCancel()
              },
            }}
            submitProps={{
              text: t('general:BUTTON.SAVE'),
              iconProps: { iconName: 'Save' },
            }}
          />
        </div>
        <Row classNames="">
          <Col className="col-xs-12 mb-2">
            <Dropdown
              name="targetType"
              translator={t}
              dropdownProps={{
                style: { minWidth: '200px', maxWidth: '400px' },
                options: flowTargetTypeOptions(t),
                label: t('TARGET.TARGET_TYPE'),
                placeholder: t('TARGET.TARGET_TYPE_PLACEHOLDER'),
                selectedKey: watchTargetType,
              }}
              required
            />
          </Col>
          {watchTargetType === FlowTargetType.WORKSPACE_GROUPS && (
            <Col>
              <Multiselect
                name="workspaceGroups"
                options={itemsWithKey}
                translator={t}
                multiselectProps={{
                  style: { minWidth: '200px', maxWidth: '400px' },
                  label: t('TARGET.WORKSPACE_GROUPS'),
                  placeholder: t('TARGET.WORKSPACE_GROUPS_PLACEHOLDER'),
                  required: true,
                }}
                styles={{
                  dropdownItemSelected: { height: 50 },
                  dropdownItem: { height: 50 },
                }}
                selectedList={watchWorkspaceGroups}
              />
            </Col>
          )}
          {watchTargetType === FlowTargetType.REPORTING_GROUPS && (
            <Col>
              <Multiselect
                name="reportingGroups"
                options={organization?.reportingGroups.map((item) => ({ key: item._id, text: item.name })) ?? []}
                translator={t}
                multiselectProps={{
                  style: { minWidth: '200px', maxWidth: '400px' },
                  label: t('TARGET.REPORTING_GROUPS'),
                  placeholder: t('TARGET.REPORTING_GROUPS_PLACEHOLDER'),
                  required: true,
                }}
                styles={{
                  dropdownItemSelected: { height: 50 },
                  dropdownItem: { height: 50 },
                }}
                selectedList={watchReportingGroups}
              />
            </Col>
          )}
          {watchTargetType === FlowTargetType.WORKSPACES && (
            <Col>
              <WorkspaceSelector
                flow={flow}
                onWorkspaceSelected={(workspaceSysIds) => {
                  methods.setValue('workspaces', workspaceSysIds, { shouldDirty: true })
                }}
              />
            </Col>
          )}
        </Row>
      </form>
      {renderCancelEditDialog()}
    </FormProvider>
  )
}
