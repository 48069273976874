import { useMutation, useQueryClient } from '@tanstack/react-query'
import graphClient from 'app/query-client/graphClient'
import { gql } from 'graphql-request'

interface DeleteRolePermissionRequest {
  userRoleId: string
  organizationId: string
  organization: string
}

const query = gql`
  mutation deleteRolePermission($input: DeleteRolePermissionGraphqlRequest!) {
    deleteRolePermission(input: $input) {
      _id
    }
  }
`

interface DeleteRolePermissionResponse {
  deleteRolePermission: {
    _id: string
  }
}

async function deleteRolePermission(request: DeleteRolePermissionRequest) {
  const { deleteRolePermission }: DeleteRolePermissionResponse = await graphClient.request(query, {
    input: request,
  })

  return deleteRolePermission
}

export default function () {
  const queryClient = useQueryClient()

  return useMutation(
    ['deleteRolePermission'],
    (request: DeleteRolePermissionRequest) => deleteRolePermission(request),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['getPaginatedRolePermissions'])
        queryClient.invalidateQueries(['getUserRoleById'])
      },
    }
  )
}
