import { useMutation, useQueryClient } from '@tanstack/react-query'
import graphClient from 'app/query-client/graphClient'
import { gql } from 'graphql-request'

interface UpdateReportingGroupRequest {
  autoUpdateAgents?: boolean
  collectEventLogs?: boolean
  eventLogIds?: string
  eventLogsRecurrence?: number
  collectDisks?: boolean
  collectServices?: boolean
  collectPnPEvents?: boolean
  collectPublicIP?: boolean
  remoteSupport?: string
  anyDeskSystemActionsRole?: string
  collectDeviceLocation?: boolean
}

interface Response {
  updateAgentSettingsReportingGroup: {
    _id: string
  }
}

const mutation = gql`
  mutation Request($id: ID!, $input: ReporttingGroupAgentSettingsInput!) {
    updateAgentSettingsReportingGroup(id: $id, input: $input) {
      _id
    }
  }
`

const updateAgentSettings = async (id: string, input: UpdateReportingGroupRequest) => {
  const response: Response = await graphClient.request(mutation, {
    id,
    input,
  })
  return response.updateAgentSettingsReportingGroup._id
}

export const updateAgentSettingsReportingGroup = () => {
  const queryClient = useQueryClient()

  return useMutation(
    ['updateAgentSettingsReportingGroup'],
    ({ id, input }: { id: string; input: UpdateReportingGroupRequest }) => updateAgentSettings(id, input),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['getBusinessById'])
      },
    }
  )
}
