import { IBreadcrumbItem, MessageBar, MessageBarType, PrimaryButton } from '@fluentui/react'
import { FeedbackMessageBar } from 'app/components/FeedbackMessageBar/FeedbackMessageBar'
import PageBody from 'app/components/PageBody/PageBody.component'
import { Tab, TabPanel } from 'app/components/tab-panel/TabPanel'
import { useFeedbackMessage } from 'app/context/feedback-message/FeedbackMessageContext'
import useOrganizationById from 'app/hooks/organization/useOrganizationById'
import useApplyPolicy from 'app/hooks/policies/useApplyPolicy'
import useGetPolicy from 'app/hooks/policies/useGetPolicy'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import Organizations from './tabs/Organizations'
import { PolicyDetails } from './tabs/details/PolicyDetails'
import ReportingGroups from './tabs/reporting-groups/ReportingGroups'
import { useOrganizationSelection } from 'app/globals/useOrganizationSelection'
import { useEffect } from 'react'

const PolicyDetailPage = () => {
  const { t } = useTranslation('organization_details')
  const { organizationId, policyId } = useParams()
  const { selectedOrganizationId } = useOrganizationSelection()
  const organizationRequest = useOrganizationById(organizationId || '')
  const applyPolicy = useApplyPolicy()
  const { isLoading, isFetching, data } = useGetPolicy({ id: policyId || '' }, true)
  const navigate = useNavigate()
  const { setSuccessMessage, setErrorMessage } = useFeedbackMessage()

  useEffect(() => {
    if (selectedOrganizationId && selectedOrganizationId !== organizationId) {
      navigate(`/organization/${selectedOrganizationId}/policies`)
    }
  }, [selectedOrganizationId])

  const onUpdatePolicy = () => {
    if (!policyId) {
      return
    }

    applyPolicy
      .mutateAsync({ id: policyId })
      .then(() => {
        setSuccessMessage(t('POLICIES.APPLIED_SUCCESS_MESSAGE'))
      })
      .catch(() => {
        setErrorMessage(t('POLICIES.APPLIED_ERROR_MESSAGE'))
      })
  }

  const buildTabs = (): Tab[] => {
    return [
      {
        title: t('POLICIES.PIVOT.DETAILS'),
        component: <PolicyDetails data={data!} />,
        key: 'overview',
      },
      {
        title: t('POLICIES.PIVOT.REPORTING_GROUPS', { count: data?.reportingGroups.length ?? 0 }),
        component: (
          <ReportingGroups
            policyId={data?._id ?? ''}
            organizationId={data?.organizationId ?? ''}
            reportingGroups={data?.reportingGroups ?? []}
            users={organizationRequest.data?.users ?? []}
          />
        ),
        key: 'reporting_groups',
      },
      {
        title: t('POLICIES.PIVOT.ORGANIZATIONS', { count: data?.tenants.length ?? 0 }),
        component: <Organizations tenants={data?.tenants ?? []} />,
        key: 'organizations',
      },
    ]
  }

  const onBreadcrumbItemClicked = (event?: React.MouseEvent, item?: IBreadcrumbItem) => {
    navigate(item?.key ?? '/home')
  }
  const breadcrumbItems = [
    {
      text: organizationRequest.data?.name ?? '',
      key: `/organization/${organizationId}`,
      onClick: onBreadcrumbItemClicked,
    },
    {
      text: t('POLICIES_PIVOT'),
      key: `/organization/${organizationId}/policies`,
      onClick: onBreadcrumbItemClicked,
    },
    { text: data?.name || '', key: 'current', isCurrentItem: true },
  ]

  const loading = isLoading || isFetching
  const showUpdateButton = data?.updated_at && data?.applied_at && data?.updated_at > data?.applied_at

  return (
    <PageBody title={data?.name ?? ''} isLoading={loading} breadcrumbItems={breadcrumbItems}>
      <FeedbackMessageBar />
      {showUpdateButton && (
        <>
          <span className="d-flex d-flexJustifyEnd" style={{ height: 32, gap: 10, marginTop: -40, marginBottom: 10 }}>
            <PrimaryButton
              text={t('POLICIES.UPDATE_BUTTON')}
              iconProps={{ iconName: 'sync' }}
              styles={{
                label: { fontWeight: 400 },
              }}
              onClick={onUpdatePolicy}
              disabled={applyPolicy.isLoading}
            />
          </span>
          <MessageBar
            messageBarType={MessageBarType.warning}
            isMultiline={false}
            dismissButtonAriaLabel="Close"
            style={{ width: '100%' }}
          >
            {t('POLICIES.WARNING_UPDATE')}
          </MessageBar>
        </>
      )}
      <TabPanel tabs={buildTabs()} />
    </PageBody>
  )
}

export default PolicyDetailPage
