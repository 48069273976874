import { MOBILE_QUERY, ViewDetailsActionButton, useMediaQuery } from '@flexxibleit/flexxible-ui'
import { IPaginatedTableColumn } from '@flexxibleit/flexxible-ui/dist/lib/components/paginated-table/paginatedTable.types'
import { ColumnActionsMode, Link } from '@fluentui/react'
import { CellWithGreenLink } from 'app/components/table-page-body/CellWithGreenLink'
import { WorkspaceGroupType } from 'app/models/workspaces-groups/WorkspaceGroupType'
import { WorkspaceGroup, WorkspaceGroupWithWorkspaceCount } from 'app/query-client/types'
import { TFunction, i18n } from 'i18next'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

type UseWorkspaceGroupsBuildTable = {
  t: TFunction
  i18n: i18n
  detailPageUrlBase: string
}

export const useWorkspaceGroupsBuildTable = ({ t, i18n, detailPageUrlBase }: UseWorkspaceGroupsBuildTable) => {

  const navigate = useNavigate()
  const [isMobile] = useMediaQuery(MOBILE_QUERY)
  const [columns, setColumns] = useState<IPaginatedTableColumn[]>([])

  const _onViewDetailsClicked = (item: WorkspaceGroup) => {
    navigate(`${detailPageUrlBase}${item._id}`)
  }

  useEffect(() => {
    setColumns([
      {
        key: 'name',
        name: t('WORKSPACE_GROUPS.TABLE.NAME'),
        fieldName: 'name',
        minWidth: 25,
        flexGrow: 3,
        onRender: ({ workspaceGroup }: WorkspaceGroupWithWorkspaceCount) => {
          return <CellWithGreenLink callback={() => _onViewDetailsClicked(workspaceGroup)}>{workspaceGroup.name}</CellWithGreenLink>
        },
      },
      {
        key: 'type',
        name: t('WORKSPACE_GROUPS.TABLE.TYPE'),
        fieldName: 'type',
        minWidth: 25,
        flexGrow: 2,
        onRender: ({ workspaceGroup }: WorkspaceGroupWithWorkspaceCount) => {
          return <span>{workspaceGroup?.type ? (workspaceGroup?.type === WorkspaceGroupType.DYNAMIC ? String(t('WORKSPACE_GROUPS.TYPE.DYNAMIC')) : String(t('WORKSPACE_GROUPS.TYPE.STATIC'))) : '-'}</span>
        },
      },
      {
        key: 'workspaceCount',
        name: t('WORKSPACE_GROUPS.TABLE.NUMBER_OF_WORKSPACES'),
        fieldName: 'workspaceCount',
        minWidth: 25,
        flexGrow: 2,
        isNumeric: true,
      },
      {
        key: 'actions',
        name: t('WORKSPACE_GROUPS.TABLE.ACTION'),
        minWidth: 25,
        flexGrow: 1,
        onRender: ({ workspaceGroup }: WorkspaceGroupWithWorkspaceCount) => {
          return (
            <ViewDetailsActionButton
              locale={i18n.language}
              isMobile={isMobile}
              onClick={() => _onViewDetailsClicked(workspaceGroup)}
            />
          )
        }
      },
    ])
  }, [])

  return { columns }
}
