import { gql } from 'graphql-request'
import graphClient from '../../query-client/graphClient'
import { useMutation, useQueryClient } from '@tanstack/react-query'

const setWorkspacesInWorkspaceGroupQuery = gql`
mutation ($workspaceSysIds: [String!]!, $workspaceGroupId: ID!) {
  setWorkspacesInWorkspaceGroup(workspaceSysIds: $workspaceSysIds, workspaceGroupId: $workspaceGroupId) {
    _id
    name
    manuallyAddedWorkspaces
  }
}
`

function setWorkspacesInWorkspaceGroup(workspaceGroupId: string, workspaceSysIds: string[]) {
  return graphClient
    .request(setWorkspacesInWorkspaceGroupQuery, {
      workspaceGroupId,
      workspaceSysIds,
    })
    .then(response => response.setWorkspacesInWorkspaceGroup)
}

export default function () {
  const queryClient = useQueryClient()

  return useMutation(
    ['setWorkspacesInWorkspaceGroup'],
    ({ workspaceGroupId, workspaceSysIds }: { workspaceGroupId: string, workspaceSysIds: string[] }) => setWorkspacesInWorkspaceGroup(workspaceGroupId, workspaceSysIds),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['getWorkspaceGroupById'])
      },
    }
  )
}
