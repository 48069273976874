import { CancelButton } from "@flexxibleit/flexxible-ui";
import { MOBILE_QUERY } from "app/config/media-query";
import useMediaQuery from "app/hooks/utils/useMediaQuery";
import { useFormSidebarContext } from "../FormSidebar/state/FormSidebarState";
import { IButtonProps } from '@fluentui/react'
import { useTranslation } from "react-i18next";

const CancelFormButton = (props: IButtonProps) => {
  const { closeSidebar } = useFormSidebarContext()
  const [ isMobile ] = useMediaQuery(MOBILE_QUERY)
  const { i18n } = useTranslation()

  return (
    <CancelButton
      locale={i18n.language}
      onClick={props.onClick || closeSidebar}
      style={props.style || { marginRight: isMobile ? '10px' : '20px' }}
      {...props}
    />
  )
}

export default CancelFormButton;