import { IconButton, Stack } from '@fluentui/react'
import { ReactNode } from 'react'
import { useTranslation } from 'react-i18next'

interface WrapOverwriteAgentSettingsFormProps {
  children?: ReactNode
  overWritable?: boolean
  disabled?: boolean
  onClick?: () => void
}

export const WrapOverwriteAgentSettingsForm = ({
  children,
  overWritable = true,
  disabled = false,
  onClick,
}: WrapOverwriteAgentSettingsFormProps) => {
  const { t } = useTranslation('general')

  return (
    <Stack
      horizontal
      tokens={{
        childrenGap: 10,
      }}
    >
      <Stack.Item grow>{children}</Stack.Item>
      <Stack.Item align="end">
        <IconButton
          iconProps={{
            iconName: overWritable ? 'Edit' : 'Refresh',
          }}
          title={overWritable ? t('BUTTON.EDIT') : t('BUTTON.RESTORE')}
          onClick={() => onClick && onClick()}
          disabled={disabled}
          styles={{
            rootDisabled: {
              backgroundColor: 'transparent',
            },
          }}
        />
      </Stack.Item>
    </Stack>
  )
}
