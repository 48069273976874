import { Col, DataTableIcon, EditButton, MOBILE_QUERY, Row, TABLET_QUERY } from '@flexxibleit/flexxible-ui'
import { useCreateFormV2 } from 'app/hooks/useCreateForm'
import { useTranslation } from 'react-i18next'
import useMediaQuery from 'app/hooks/utils/useMediaQuery'
import FlexxibleCard from 'app/components/FlexxibleCard/FlexxibleCard.component'
import { DisplayField } from 'app/components/DisplayField'
import SummaryCard from 'app/pages/organization-details/components/SummaryCard/SummaryCard'
import { dateFormatter } from 'app/services/date-formatter'
import { PatchPolicyReportingGroupsTable } from '../../patch-policies/components/PatchPolicyReportingGroupsTable'
import AuthorIcon from '../../../../assets/img/AuthorIcon.svg'
import { PatchPolicy, PatchPolicyTarget, User } from 'app/query-client/types'
import { Link } from '@fluentui/react'
import { useNavigate, useParams } from 'react-router-dom'
import { useOrganizationSelection } from 'app/globals/useOrganizationSelection'
import { CreatePatchPolicyTargetForm } from '../components/CreatePatchPolicyTargetForm'
import useGetWindowsPatchPoliciesByOrganization from 'app/hooks/patchPolicies/useGetWindowsPatchPoliciesByOrganization'
import useGetWindowsPatchPolicy from 'app/hooks/patchPolicies/useGetWindowsPatchPolicy'
import useGetLinuxPatchPolicy from 'app/hooks/patchPolicies/linux-patch-policy/useGetLinuxPatchPolicy'
import { useExtendedPatchManagementFeature } from '../../../../permissions/ExtendedPatchManagement.featureFlag'
import { FeatureRender } from '../../../../permissions/FeatureRender'
import { FEATURE_NAMES } from '../../../../permissions/FeatureName.enum'

interface PatchPolicyDetailsTabProps {
  patchPolicyTarget: PatchPolicyTargetWithUser
}

interface PatchPolicyTargetWithUser extends PatchPolicyTarget {
  createdBy?: User
}

export const PatchPolicyDetailsTabV2 = ({ patchPolicyTarget }: PatchPolicyDetailsTabProps) => {
  const { t } = useTranslation('patch_management')
  const [isMobile] = useMediaQuery(MOBILE_QUERY)
  const [isTablet] = useMediaQuery(TABLET_QUERY)
  const isDesktop = !isMobile && !isTablet
  const cardProps = {
    style: { marginTop: 30 },
  }

  const { selectedOrganization } = useOrganizationSelection()
  const { isLoading: isLoadingWindowsPatches, data: windowsPatchPolicies } = useGetWindowsPatchPolicy(
    { id: patchPolicyTarget.windowsPatchPolicyId || '' },
    Boolean(patchPolicyTarget.windowsPatchPolicyId)
  )

  const { isLoading: isLoadingLinuxPatches, data: linuxPatchPolicies } = useGetLinuxPatchPolicy(
    {
      id: patchPolicyTarget.linuxPatchPolicyId || '',
    },
    Boolean(patchPolicyTarget.linuxPatchPolicyId)
  )

  const navigate = useNavigate()

  const { open: openEditPatchPolicyTargetForm } = useCreateFormV2(
    {
      title: t('PATCH_POLICIES_TARGET.EDIT_FORM'),
      isFooterAtBottom: true,
    },
    <CreatePatchPolicyTargetForm patchPolicyTarget={patchPolicyTarget} />
  )

  const onClickWindowsPolicy = () => {
    navigate(
      `/organization/${selectedOrganization._id}/patch-management/windows-patch-policies/${patchPolicyTarget.windowsPatchPolicyId}`
    )
  }

  const onClickLinuxPolicy = () => {
    navigate(
      `/organization/${selectedOrganization._id}/patch-management/linux-patch-policies/${patchPolicyTarget.linuxPatchPolicyId}`
    )
  }

  return (
    <>
      <div
        className={!isDesktop ? 'd-flex d-flexCol d-flexColReverse' : 'd-flex d-flexRow col-xs-12'}
        style={!isDesktop ? { gap: 0 } : { gap: 30, paddingRight: 20 }}
      >
        <FlexxibleCard cardProps={cardProps} styleNames="col-md-9 d-flex d-flexCol d-flexJustifyBetween">
          <Row>
            <Col className="col-xs-12 col-lg-6">
              <DisplayField label={t('PATCH_POLICIES_TARGET.SUMMARY.NAME')}>{patchPolicyTarget?.name}</DisplayField>
            </Col>
            <Col className="col-xs-12 col-lg-6">
              <DisplayField label={t('PATCH_POLICIES_TARGET.SUMMARY.REPORTING_GROUPS')}>
                {patchPolicyTarget?.reportingGroups.length}
              </DisplayField>
            </Col>
          </Row>
          <Row>
            <Col className="col-xs-12 col-lg-6">
              <DisplayField label={t('PATCH_POLICIES_TARGET.SUMMARY.RESTART_AFTER_PATCHING')}>
                {patchPolicyTarget?.shouldRestart ? t('YES') : t('NO')}
              </DisplayField>
            </Col>
            {useExtendedPatchManagementFeature() && (
              <Col className="col-xs-12 col-lg-6">
                <DisplayField label={t('PATCH_POLICIES_TARGET.SUMMARY.MAX_SIMULTANEOUS_WORKSPACES')}>
                  {patchPolicyTarget?.maxSimultaneousWorkspaces ?? 0}
                </DisplayField>
              </Col>
            )}
          </Row>
          <Row>
            <Col className="col-xs-12 col-lg-6">
              <DisplayField label={t('PATCH_POLICIES_TARGET.SUMMARY.WAKE_ON_LAN')}>
                {patchPolicyTarget?.wakeOnLAN ? t('YES') : t('NO')}
              </DisplayField>
            </Col>
            {useExtendedPatchManagementFeature() && (
              <Col className="col-xs-12 col-lg-6">
                <DisplayField label={t('PATCH_POLICIES_TARGET.SUMMARY.FORCE_PATCHING_CONFIGURATION')}>
                  {patchPolicyTarget?.forcePatchingConfiguration ? t('YES') : t('NO')}
                </DisplayField>
              </Col>
            )}
          </Row>
          {useExtendedPatchManagementFeature() && (
            <Row>
              <Col className="col-xs-12 col-lg-6">
                <DisplayField label={t('PATCH_POLICIES_TARGET.SUMMARY.WINDOWS_PATCH_POLICY')}>
                  {patchPolicyTarget.windowsPatchPolicyId && windowsPatchPolicies ? (
                    <Link
                      disabled={isLoadingWindowsPatches}
                      onClick={() => {
                        onClickWindowsPolicy()
                      }}
                    >
                      {windowsPatchPolicies?.name}
                    </Link>
                  ) : (
                    t('PATCH_POLICIES_TARGET.SUMMARY.NOT_SET')
                  )}
                </DisplayField>
              </Col>
              <Col className="col-xs-12 col-lg-6">
                <DisplayField label={t('PATCH_POLICIES_TARGET.SUMMARY.LINUX_PATCH_POLICY')}>
                  {patchPolicyTarget.linuxPatchPolicyId && linuxPatchPolicies ? (
                    <Link
                      disabled={isLoadingLinuxPatches}
                      onClick={() => {
                        onClickLinuxPolicy()
                      }}
                    >
                      {linuxPatchPolicies?.name}
                    </Link>
                  ) : (
                    t('PATCH_POLICIES_TARGET.SUMMARY.NOT_SET')
                  )}
                </DisplayField>
              </Col>
            </Row>
          )}

          <div className="d-flex d-flexRowReverse">
            <FeatureRender feature={FEATURE_NAMES.PATCH_MANAGEMENT_UPDATE}>
              <EditButton translator={t} onClick={openEditPatchPolicyTargetForm} />
            </FeatureRender>
          </div>
        </FlexxibleCard>

        <div className="col-md-3 summary-container d-flexJustifyCenter d-flexAlignItemsCenter">
          <>
            <div
              className={isMobile || isTablet ? 'd-flex d-flexRow d-flexWrap' : 'd-flex d-flexCol '}
              style={{ gap: 20 }}
            >
              <SummaryCard
                Icon={DataTableIcon}
                title={t('PATCH_POLICIES_TARGET.SUMMARY.CREATION_DATE')}
                text={patchPolicyTarget?.createdAt ? dateFormatter(t, new Date(patchPolicyTarget.createdAt)) : ''}
              />
              <SummaryCard
                Icon={AuthorIcon}
                title={t('PATCH_POLICIES_TARGET.SUMMARY.CREATED_BY')}
                text={
                  (patchPolicyTarget as PatchPolicyTargetWithUser).createdBy
                    ? (patchPolicyTarget as PatchPolicyTargetWithUser).createdBy?.name +
                      ' ' +
                      (patchPolicyTarget as PatchPolicyTargetWithUser).createdBy?.surname
                    : ''
                }
              />
            </div>
          </>
        </div>
      </div>
      <div
        style={{
          margin: '30px 10px',
        }}
      >
        <h3>{t('PATCH_POLICIES_TARGET.SUMMARY.REPORTING_GROUPS_TABLE.TABLE_TITLE')}</h3>
        <PatchPolicyReportingGroupsTable patchPolicy={patchPolicyTarget as unknown as PatchPolicy} />
      </div>
    </>
  )
}
