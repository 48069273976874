import { DeleteActionButton, FormActionsContainer, LoadingSpinner } from '@flexxibleit/flexxible-ui'
import { useTranslation } from 'react-i18next'
import { ResponsiveMode } from '@fluentui/react'
import { useBoolean } from '@fluentui/react-hooks'
import FormActions from '../../../components/forms/FormActions'
import { User } from '../../../query-client/types'
import languages from '../../../i18n/languages'
import { FormProvider, useForm } from 'react-hook-form'
import useDeleteUser from '../../../hooks/users/useDeleteUser'
import useUpdateUser from '../../../hooks/users/useUpdateUser'
import useCreateUser from '../../../hooks/users/useCreateUser'
import { useEffect, useState } from 'react'
import SuccessStatus from '../../../components/status/success-status'
import ErrorStatus from '../../../components/status/error-status'
import Dialog from '../../../components/Dialog/Dialog'
import * as z from 'zod'
import { zodResolver } from '@hookform/resolvers/zod'
import ControlledTextField from 'app/components/forms/TextField'
import Dropdown from 'app/components/forms/Dropdown'
import RowColLayout from 'app/components/Layouts/RowColLayout'
import { useMe } from 'app/hooks/me/useMe'

import { GettingStartedChecklistTasks } from '../../../user-journey/getting-started-checklist/GettingStartedChecklistTasksEnum'
import { useGettingStartedChecklist } from '../../../user-journey/getting-started-checklist/useGettingStartedChecklist'
import useGetRolesByOrganization, { UserRole } from 'app/hooks/roles/useGetRolesByOrganization'
import { FEATURE_NAMES } from 'app/permissions/FeatureName.enum'
import { useFeatureRender } from 'app/permissions/useFeatureRender'

export interface CreateUserFormType {
  name: string
  surname: string
  userRole: string
  language: string | ''
  email: string
  department?: string | null
}

export interface CreateOrUpdateUserFormProps {
  user?: User
  organizationId?: string
}

const schema: z.ZodType<Partial<CreateUserFormType>> = z.object({
  name: z.string({
    required_error: 'required',
  }),
  surname: z.string({
    required_error: 'required',
  }),
  email: z
    .string({
      required_error: 'required',
    })
    .trim()
    .email({ message: 'format' }),
  userRole: z.string({
    required_error: 'required',
  }),
  language: z.string({
    required_error: 'required',
  }),
  department: z.string().optional().nullable(),
})

const UserForm = ({ user, organizationId }: CreateOrUpdateUserFormProps) => {
  const { t, i18n } = useTranslation('organization_details')
  const { data: userMe } = useMe()
  const { completeGettingStartedTask } = useGettingStartedChecklist()
  const { checkFeature } = useFeatureRender()

  const { isLoading: userRoleLoading, data: userRolesList } = useGetRolesByOrganization(organizationId || '')

  const [hideDialog, { toggle: toggleHideDialog }] = useBoolean(true)

  const getDefaultValues = () => {
    return {
      ...user,
      userRole: user?.userRole?._id || '',
      department: user?.department || '',
    }
  }

  const methods = useForm<CreateUserFormType>({
    resolver: zodResolver(schema),
    defaultValues: getDefaultValues(),
  })
  const languagesDropdownOptions = languages

  const updateUser = useUpdateUser()
  const createUser = useCreateUser(organizationId || '')
  const deleteUser = useDeleteUser()

  const [isSuccess, setIsSuccess] = useState(false)
  const [isError, setIsError] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    setIsSuccess(createUser.isSuccess || updateUser.isSuccess || deleteUser.isSuccess)
    setIsError(createUser.isError || updateUser.isError || deleteUser.isError)
    setIsLoading(createUser.isLoading || updateUser.isLoading || deleteUser.isLoading || userRoleLoading)
  }, [createUser, updateUser, deleteUser, userRolesList])

  const handleSubmit = (data: CreateUserFormType) => {
    if (user) {
      updateUser.mutate({
        user: {
          name: data.name,
          surname: data.surname,
          userRole: data.userRole,
          email: data.email,
          department: data.department ?? '',
        },
        userId: user._id,
      })
    } else if (organizationId) {
      completeGettingStartedTask(GettingStartedChecklistTasks.INVITE_PEOPLE)
      createUser.mutate(data)
    }
  }

  const canAssignAdminRole = checkFeature(FEATURE_NAMES.USER_ADMIN_ROLE_UPDATE)
  const buildUserRolesOptions = () => {
    const availableRolesToAssign = canAssignAdminRole
      ? userRolesList
      : userRolesList?.filter((role) => !role.isAdminRole || user?.userRole?._id === role._id)

    return (
      availableRolesToAssign?.map((userRole: UserRole) => {
        return {
          key: userRole._id,
          text: userRole.name,
        }
      }) || []
    )
  }

  const canModifyRole = (user: User) => {
    const hasAdminRole = userRolesList?.find((role) => user.userRole?._id === role._id)?.isAdminRole

    if (!hasAdminRole) {
      return true
    }

    return canAssignAdminRole
  }

  const isNotMe = (user: User) => user?._id !== userMe?._id

  return (
    <>
      {!isLoading && !isSuccess && !isError && (
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(handleSubmit)}>
            <RowColLayout rowProps={{ classNames: 'mt-2' }}>
              <ControlledTextField
                name="name"
                required={true}
                translator={t}
                textFieldProps={{
                  label: t('USERS.FORM.NAME'),
                  placeholder: t('USERS.FORM.ENTER_TEXT'),
                }}
              />
            </RowColLayout>
            <RowColLayout rowProps={{ classNames: 'mt-2' }}>
              <ControlledTextField
                name="surname"
                required={true}
                translator={t}
                textFieldProps={{
                  label: t('USERS.FORM.SURNAME'),
                  placeholder: t('USERS.FORM.ENTER_TEXT'),
                }}
              />
            </RowColLayout>
            <RowColLayout rowProps={{ classNames: 'mt-2' }}>
              <ControlledTextField
                name="email"
                translator={t}
                required={true}
                textFieldProps={{
                  label: t('USERS.FORM.EMAIL'),
                  placeholder: t('USERS.FORM.ENTER_TEXT'),
                }}
              />
            </RowColLayout>
            <RowColLayout rowProps={{ classNames: 'mt-2' }}>
              <Dropdown
                name="userRole"
                required={true}
                translator={t}
                dropdownProps={{
                  disabled: user && !canModifyRole(user),
                  responsiveMode: ResponsiveMode.large,
                  defaultSelectedKey: methods.watch('userRole'),
                  label: t('USERS.FORM.USER_ROLE'),
                  placeholder: t('USERS.FORM.USER_ROLE_PLACEHOLDER'),
                  options: buildUserRolesOptions(),
                }}
              />
            </RowColLayout>

            {!user?._id && (
              <RowColLayout rowProps={{ classNames: 'mt-2' }}>
                <Dropdown
                  name="language"
                  required={true}
                  translator={t}
                  dropdownProps={{
                    responsiveMode: ResponsiveMode.large,
                    defaultSelectedKey: user?.language,
                    label: t('USERS.FORM.LANGUAGE'),
                    placeholder: t('USERS.FORM.LANGUAGE_PLACEHOLDER'),
                    options: languagesDropdownOptions,
                  }}
                />
              </RowColLayout>
            )}
            <RowColLayout rowProps={{ classNames: 'mt-2' }}>
              <ControlledTextField
                name="department"
                translator={t}
                textFieldProps={{
                  label: t('USERS.FORM.DEPARTMENT'),
                  placeholder: t('USERS.FORM.ENTER_TEXT'),
                }}
              />
            </RowColLayout>

            <FormActionsContainer isEdit={!!user}>
              {user ? (
                <>
                  {isNotMe(user) ? <DeleteActionButton locale={i18n.language} onClick={toggleHideDialog} /> : <div />}
                  <FormActions
                    submitProps={{
                      text: t('general:BUTTON.SAVE'),
                      iconProps: { iconName: 'Save' },
                    }}
                  />
                </>
              ) : (
                <FormActions
                  submitProps={{
                    text: t('general:BUTTON.CREATE'),
                    iconProps: { iconName: 'Add' },
                  }}
                />
              )}
            </FormActionsContainer>
          </form>
        </FormProvider>
      )}

      {isLoading && <LoadingSpinner></LoadingSpinner>}

      {(isSuccess || isError) && (
        <>
          {createUser.isSuccess && <SuccessStatus message={t('USERS.FORM.ADD_SUCCESS')} />}

          {createUser.isError && (
            <>
              {(createUser.error as any).response.errors[0].message.includes('Email already exist') ? (
                <ErrorStatus message={t('USERS.FORM.EMAIL_ALREADY_EXIST')} />
              ) : (
                <ErrorStatus message={t('USERS.FORM.ADD_ERROR')} />
              )}
            </>
          )}

          {updateUser.isSuccess && <SuccessStatus message={t('USERS.FORM.EDIT_SUCCESS')} />}

          {updateUser.isError && <ErrorStatus message={t('USERS.FORM.EDIT_ERROR')} />}

          {deleteUser.isSuccess && <SuccessStatus message={t('USERS.FORM.DELETE_SUCCESS')} />}

          {deleteUser.isError && <ErrorStatus message={t('USERS.FORM.DELETE_ERROR')} />}
        </>
      )}

      <Dialog
        title={t('USERS.FORM.CONFIRMATION_TITLE')}
        description={t('USERS.FORM.CONFIRMATION_MESSAGE')}
        actionButton={t('general:BUTTON.DELETE')}
        dismissButton={t('general:BUTTON.CANCEL')}
        callback={() => {
          if (user) {
            toggleHideDialog()
            deleteUser.mutate(user._id)
          }
        }}
        hidden={hideDialog}
        onDismiss={toggleHideDialog}
      />
    </>
  )
}

export default UserForm
