import { RoleName } from '../permissions/RoleFeature.enum'
import { MicroserviceScope } from '../models/microservices/MicroserviceScope'
import { MicroserviceOperatingSystem } from '../models/microservices/MicroserviceOperatingSystem'
import { MicroserviceLanguage } from '../models/microservices/MicroserviceLanguage'
import { MicroserviceContext } from 'app/models/microservices/MicroserviceContext'
import { BusinessType } from 'app/models/business/BusinessType'
import { RemoteSupport } from 'app/models/reporting-groups/RemoteSupport'
import { WorkspacePermissionName } from 'app/permissions/UserWorkspacePermission.enum'
import { AnalyzerPermissionName } from 'app/permissions/UserAnalyzerPermission.enum'
import { WorkspaceGroupType } from 'app/models/workspaces-groups/WorkspaceGroupType'
import { PortalPermissionName } from 'app/permissions/UserPortalPermission.enum'

export type ProductSettings = {
  autoUpdateAgents: boolean
  resourcesReportRecurrence: number
  profileStorageReportRecurrence?: number
  syncBrokerRecurrence?: number
  detectNewCitrixSubscriptions?: boolean
  collectEventLogs: boolean
  eventLogIds?: string
  eventLogsRecurrence?: number
  collectDisks: boolean
  collectServices: boolean
  collectPnPEvents: boolean
  collectPublicIP: boolean
  remoteSupport: string
  anyDeskSystemActionsRole: string
}

export type ProductConfig = {
  _id: string
  product: Product
  business: Business
  reports: [Report]
  environment: string
  lastReportDate: string
  active: boolean
  license: string
  created_at: string
  updated_at: string
  baseline: number
  serviceNowRelatedEnvironment: string[]
  region?: string
  agentSettings?: ProductSettings
}

export type Report = {
  _id: string
  unit: string
  group: string
  date: string
  usage: Usage[]
  created_at: string
  updated_at: string
  productConfig: ProductConfig
}

export type Usage = {
  group: string
  quantity: number
}

export type Region = {
  name: string
  deploymentCode: string
}

export type Product = {
  _id: string
  name: string
  active: boolean
  created_at: string
  updated_at: string
  productConfigs: [ProductConfig]
  reports: [Report]
  regions: Region[]
  elegible?: boolean
  fixedBaseline?: number
  uniqueCode?: string
}

export type ElegibleProduct = {
  elegibleProduct: Product
  deploymentRegions: string[]
}

export type PaginatedEligibleProduct = {
  _id: string
  name: string
  regions: string[]
}

export type Business = {
  _id: string
  name: string
  email: string
  type: BusinessType
  language: string
  country: string
  industry: string
  lastReport: number
  description: string
  created_at: string
  updated_at: string
  active: boolean
  serviceNowCompanyId: string
  partner: Business
  productConfigs: [ProductConfig]
  users: [User]
  modules: [Module]
  tenants: Tenant[]
  policies: OrganizationPolicy[]
  activeDirectoryCredentials?: activeDirectoryCredentials
  isTrial?: boolean
  elegibleProducts?: ElegibleProduct[]
  policy?: OrganizationPolicy
  reportingGroups: ReportingGroup[]
  defaultPolicy?: OrganizationPolicy
  uniqueCode?: string
}

export type UserRole = {
  _id: string
  name: string
  organizationId: string
  permissions: RolePermission[]
  created_by: User
  updated_by: User
  created_at: string
  updated_at: string
  assignedUsers: number
  assignedOrganizations: number
  canBeCloned: boolean
}

export type RolePermission = {
  allOrganizations: boolean
  organization: Business
  portal: PortalPermission
  workspaces: WorkspacePermission
  analyzer: AnalyzerPermission
  allReportingGroups: boolean
  reportingGroups: ReportingGroup[]
}

export type User = {
  _id: string
  name: string
  surname: string
  email: string
  language: string
  created_at: string
  updated_at: string
  business: Business
  department?: string | null
  specialPermissions?: SpecialPermission[]
  userRole?: UserRole
}

export enum SpecialPermission {
  CONDITION_MICROSERVICE = 'CONDITION_MICROSERVICE',
  CUSTOM_FIELDS_MICROSERVICE = 'CUSTOM_FIELDS_MICROSERVICE',
}

export type Module = {
  _id: string
  type: string
  name: string
  tag: string
  url: string
  visibility: number
  order: number
  business: Business
}

export type Tenant = {
  _id: string
  name: string
  region: string
  created_at: string
  product?: Product
  policy?: OrganizationPolicy
  organizationRelated: Business
}

export type Activation = {
  _id: string
  name: string
  type: string
  product: string
  createdAt: string
  deleteAt?: string
  lastDay: number
  last7Days: number
  last30Days: number
  last60Days: number
  last90Days: number
  children: string[]
  level: number
  productStatus: boolean
}
export type ReportingGroupAgentSettings = {
  autoUpdateAgents?: boolean
  collectEventLogs?: boolean
  eventLogIds?: string
  eventLogsRecurrence?: number
  collectDisks?: boolean
  collectServices?: boolean
  collectPnPEvents?: boolean
  collectPublicIP?: boolean
  remoteSupport: RemoteSupport
  anyDeskSystemActionsRole?: string
  collectDeviceLocation?: boolean
}

export type ReportingGroup = {
  _id: string
  name: string
  remoteSupport: RemoteSupport
  policyReportingGroup: string
  productConfig?: ProductConfig
  patchPolicyTargetId?: string | null | undefined
  agentSettings?: ReportingGroupAgentSettings
  created_at: string
  fishingPattern?: string
}

export type OrganizationPolicy = {
  _id: string
  name: string
  description?: string
  product?: string
  created_at: string
}

export type Workspace = {
  _id: string
  name: string
  fqdn: string
  sysDomain: string
  sysId: string
  uLastUser: string
  uOperativeSystem: string
  uRelatedEnvironment: string
  company: string
  uHostname: string
  ipAddress: string
  uCoresCpu: string
  uRam: string
  uType: string
}

export type WorkspaceGroup = {
  _id: string
  name: string
  description?: string
  location?: WorkspaceLocation
  business: Business
  manuallyAddedWorkspaces: string[]
  created_by: User
  created_at: string
  type?: WorkspaceGroupType | null | undefined
  filter?: string | null | undefined
  lastUpdated?: string | null | undefined
}

export type WorkspaceLocation = {
  latitude: number
  longitude: number
  address: string
  description?: string | null | undefined
}

export type WorkspaceGroupWithWorkspaceCount = {
  workspaceGroup: WorkspaceGroup
  workspaceCount: number
}

export type WorkspaceGroupMetric = {
  _id: string
  workspaceGroup: WorkspaceGroup
  workspaceCount: number
  created_at: string
}

export type UserSettings = {
  _id: string
  created_at: string
  defaultOrganizationSelected: string
  preferredLanguage: string
  preferredRegion?: string
  updated_at: string
}

export type ApiKey = {
  _id: string
  token: string
  createdBy: User
  programmaticUser: {
    _id: string
    role: Role
  }
  business: Business
  lastUsedAt?: string
  revokedAt?: string
  expiresAt: string
  created_at: string
  updated_at: string
}

export type Role = RoleName
export type PortalPermission = PortalPermissionName
export type WorkspacePermission = WorkspacePermissionName
export type AnalyzerPermission = AnalyzerPermissionName

export type PaginationArgs = {
  page: number
  perPage: number
}

export type PageInfo = {
  hasNextPage: boolean
  hasPreviousPage: boolean
  totalPages: number
  currentPage: number
  total: number
}

export type PaginationResponse<T> = {
  results: T[]
  pageInfo: PageInfo
}

export type Saving = {
  _id: string
  amount: number
  unit: string
  description?: string
}

export type Microservice = {
  _id: string
  created_at: string
  name: string
  description?: string
  readme?: string
  iconName?: string
  iconBackground?: string
  script?: string
  active?: boolean
  author?: User
  business?: Business
  archivedAt?: string
  language?: MicroserviceLanguage
  operatingSystem?: Array<MicroserviceOperatingSystem>
  version?: string
  scope?: Array<MicroserviceScope>
  context?: MicroserviceContext
  canEdit?: boolean
  privacySettings?: PrivacySettings
  license?: string
}

export type PrivacySettings = {
  isPrivate: boolean
  visibleTo: string[]
}

export type activeDirectoryCredentials = {
  clientId: string
  clientSecret?: string | null | undefined
  tenantId: string
  isClientSecretSet?: boolean | null | undefined
}

export type PatchPolicyExclude = {
  name: string
  rule: string
}

export interface LinuxPatchPolicy {
  _id: string
  name: string
  organizationId: string
  patchPolicyTargets: {
    _id: string
    name: string
  }[]
  createdAt: Date
  updatedAt: Date
  targetGroupId: string
  excludes: PatchPolicyExclude[]
}

export type PatchPolicyTarget = {
  _id: string
  organizationId: string
  name: string
  // Por ahora con WSUS, no lo usaremos
  delayAfterApproval: number
  schedule: Schedule
  weeks: Array<number>
  timezone: string
  reportingGroups: Array<ReportingGroup>
  shouldRestart: boolean
  maxSimultaneousWorkspaces: number
  // Flag para parar el parcheado, revisar si se manda el valor o schedule vacio a FlexxWorkspaces
  isInMaintenance: boolean
  createdAt: string
  updatedAt: string
  windowsPatchPolicyId?: string
  linuxPatchPolicyId?: string
  wakeOnLAN: boolean
  forcePatchingConfiguration: boolean
}

export type PatchPolicy = {
  _id: string
  targetGroupId: string
  name: string
  reportingGroups: Array<ReportingGroup>
  shouldRestart: boolean
  schedule: Schedule
  weeks: Array<number>
  timezone: string
  maxSimultaneousWorkspaces: number
  operativeSystems: Array<OperativeSystem>
  products: Array<Products>
  createdAt?: string
  hasAutomaticApprovals?: boolean
}

export type WindowsPatches = {
  id: string
  kb: string
  title: string
  category: string
  product: string
  status: string
  infoUrl: string
  severity: string
  creationDate: Date
  arrivalDate: Date
  rebootBehavior: string
  rebootUninstallationBehavior: string
  state: string
  approvedAt?: Date
  approvedBy?: {
    _id: string
    name: string
    surname: string
  }
  declinedAt?: Date
  declinedBy?: {
    _id: string
    name: string
    surname: string
  }
  automatic?: boolean
}

export type WindowsPatchPolicy = {
  _id: string
  name: string
  patchPolicyTargets?: any[]
  automaticApprovals: AutomaticApproval[]
  createdAt: Date
}

/* export type WindowsPatchFilter = {
  categories: string[]
  products: string[]
  status?: string | null
  search?: string
  superseded?: string | null
  releaseDate?: Date | null
} */

export enum PatchSeverities {
  CRITICAL = 'Critical',
  IMPORTANT = 'Important',
  MODERATE = 'Moderate',
  LOW = 'Low',
  UNSPECIFIED = 'Unspecified',
}

export enum PatchStatus {
  APPROVED = 'APPROVED',
  NOT_APPROVED = 'NOT_APPROVED',
}

export type PatchesCount = {
  severity: string
  dateRange: string
  value: number
  status?: string
}

export type PatchesBySeverityAndStatusCount = {
  severity: string
  status: string
  value: number
}

export type WindowsPatchFilter = {
  categories?: string[]
  products?: string[]
  severity?: string[]
  status?: string | null
  search?: string
  superseded?: boolean | null
  releaseDate?: Date | null
  creationDate?: {
    start: Date | null
    end: Date | null
  }
}

export type PatchesDashboard = {
  totalAvailablePatches: number
  totalApprovedPatches: number
  totalNotApprovedPatches: number
  totalApprovedPatchesByPatchPolicy:
    | {
        patchPolicy: string
        value: number
      }[]
    | []
  totalNotApprovedPatchesByPatchPolicy:
    | {
        patchPolicy: string
        value: number
      }[]
    | []
  patchesBySeverityAndStatus:
    | {
        severity: string
        status: string
        value: number
      }[]
    | []
}

export type TargetsDashboard = {
  reportingGroupsCountByPatchPolicy:
    | {
        patchPolicy: string
        value: number
      }[]
    | []
  patchPolicySummaries:
    | {
        targetGroup: string
        unknownCount: number
        notApplicableCount: number
        notInstalledCount: number
        downloadedCount: number
        noStatus: number
        installedCount: number
        installedPendingRebootCount: number
        failedCount: number
      }[]
    | []
}

export type ReportingGroupsDashboard = {
  notApprovedPatchesInReportingGroupBySeverity:
    | {
        reportingGroup: string
        severity: string
        value: number
      }[]
    | []
  reportingGroupsInRisk:
    | {
        reportingGroup: string
        value: number
      }[]
    | []
  workspacesCount: {
    total: number
    windows: number
    linux: number
  }
  totalWorkspacesByReportingGroup:
    | {
        reportingGroup: string
        value: number
      }[]
    | []
}

export type AutomaticApproval = {
  classification: string
  products: string[]
  deadlineAfterRelease: number
}

export type Schedule = { [key in WeekDay]: Time }

export enum WeekDay {
  SUNDAY = 'SUNDAY',
  MONDAY = 'MONDAY',
  TUESDAY = 'TUESDAY',
  WEDNESDAY = 'WEDNESDAY',
  THURSDAY = 'THURSDAY',
  FRIDAY = 'FRIDAY',
  SATURDAY = 'SATURDAY',
}

export type Time = { [hour: number]: Array<number> }

export enum OperativeSystem {
  WINDOWS = 'WINDOWS',
  LINUX = 'LINUX',
}

export type Products = {
  id: string
  name: string
}

export const timezoneOptions: { key: string; text: string }[] = [
  { key: 'Afghanistan Standard Time', text: '(UTC+04:30) Kabul' },
  { key: 'Alaskan Standard Time', text: '(UTC-09:00) Alaska' },
  { key: 'Aleutian Standard Time', text: '(UTC-10:00) Aleutian Islands' },
  { key: 'Altai Standard Time', text: '(UTC+07:00) Barnaul, Gorno-Altaysk' },
  { key: 'Arab Standard Time', text: '(UTC+03:00) Kuwait, Riyadh' },
  { key: 'Arabian Standard Time', text: '(UTC+04:00) Abu Dhabi, Muscat' },
  { key: 'Arabic Standard Time', text: '(UTC+03:00) Baghdad' },
  { key: 'Argentina Standard Time', text: '(UTC-03:00) City of Buenos Aires' },
  { key: 'Astrakhan Standard Time', text: '(UTC+04:00) Astrakhan, Ulyanovsk' },
  { key: 'Atlantic Standard Time', text: '(UTC-04:00) Atlantic Time (Canada)' },
  { key: 'AUS Central Standard Time', text: '(UTC+09:30) Darwin' },
  { key: 'Aus Central W. Standard Time', text: '(UTC+08:45) Eucla' },
  { key: 'AUS Eastern Standard Time', text: '(UTC+10:00) Canberra, Melbourne, Sydney' },
  { key: 'Azerbaijan Standard Time', text: '(UTC+04:00) Baku' },
  { key: 'Azores Standard Time', text: '(UTC-01:00) Azores' },
  { key: 'Bahia Standard Time', text: '(UTC-03:00) Salvador' },
  { key: 'Bangladesh Standard Time', text: '(UTC+06:00) Dhaka' },
  { key: 'Belarus Standard Time', text: '(UTC+03:00) Minsk' },
  { key: 'Bougainville Standard Time', text: '(UTC+11:00) Bougainville Island' },
  { key: 'Canada Central Standard Time', text: '(UTC-06:00) Saskatchewan' },
  { key: 'Cape Verde Standard Time', text: '(UTC-01:00) Cabo Verde Is.' },
  { key: 'Caucasus Standard Time', text: '(UTC+04:00) Yerevan' },
  { key: 'Cen. Australia Standard Time', text: '(UTC+09:30) Adelaide' },
  { key: 'Central America Standard Time', text: '(UTC-06:00) Central America' },
  { key: 'Central Asia Standard Time', text: '(UTC+06:00) Astana' },
  { key: 'Central Brazilian Standard Time', text: '(UTC-04:00) Cuiaba' },
  { key: 'Central Europe Standard Time', text: '(UTC+01:00) Belgrade, Bratislava, Budapest, Ljubljana, Prague' },
  { key: 'Central European Standard Time', text: '(UTC+01:00) Sarajevo, Skopje, Warsaw, Zagreb' },
  { key: 'Central Pacific Standard Time', text: '(UTC+11:00) Solomon Is., New Caledonia' },
  { key: 'Central Standard Time', text: '(UTC-06:00) Central Time (US & Canada)' },
  { key: 'Central Standard Time (Mexico)', text: '(UTC-06:00) Guadalajara, Mexico City, Monterrey' },
  { key: 'Chatham Islands Standard Time', text: '(UTC+12:45) Chatham Islands' },
  { key: 'China Standard Time', text: '(UTC+08:00) Beijing, Chongqing, Hong Kong, Urumqi' },
  { key: 'Cuba Standard Time', text: '(UTC-05:00) Havana' },
  { key: 'Dateline Standard Time', text: '(UTC-12:00) International Date Line West' },
  { key: 'E. Africa Standard Time', text: '(UTC+03:00) Nairobi' },
  { key: 'E. Australia Standard Time', text: '(UTC+10:00) Brisbane' },
  { key: 'E. Europe Standard Time', text: '(UTC+02:00) Chisinau' },
  { key: 'E. South America Standard Time', text: '(UTC-03:00) Brasilia' },
  { key: 'Easter Island Standard Time', text: '(UTC-06:00) Easter Island' },
  { key: 'Eastern Standard Time', text: '(UTC-05:00) Eastern Time (US & Canada)' },
  { key: 'Eastern Standard Time (Mexico)', text: '(UTC-05:00) Chetumal' },
  { key: 'Egypt Standard Time', text: '(UTC+02:00) Cairo' },
  { key: 'Ekaterinburg Standard Time', text: '(UTC+05:00) Ekaterinburg' },
  { key: 'Fiji Standard Time', text: '(UTC+12:00) Fiji' },
  { key: 'FLE Standard Time', text: '(UTC+02:00) Helsinki, Kyiv, Riga, Sofia, Tallinn, Vilnius' },
  { key: 'Georgian Standard Time', text: '(UTC+04:00) Tbilisi' },
  { key: 'GMT Standard Time', text: '(UTC+00:00) Dublin, Edinburgh, Lisbon, London' },
  { key: 'Greenland Standard Time', text: '(UTC-02:00) Greenland' },
  { key: 'Greenwich Standard Time', text: '(UTC+00:00) Monrovia, Reykjavik' },
  { key: 'GTB Standard Time', text: '(UTC+02:00) Athens, Bucharest' },
  { key: 'Haiti Standard Time', text: '(UTC-05:00) Haiti' },
  { key: 'Hawaiian Standard Time', text: '(UTC-10:00) Hawaii' },
  { key: 'India Standard Time', text: '(UTC+05:30) Chennai, Kolkata, Mumbai, New Delhi' },
  { key: 'Iran Standard Time', text: '(UTC+03:30) Tehran' },
  { key: 'Israel Standard Time', text: '(UTC+02:00) Jerusalem' },
  { key: 'Jordan Standard Time', text: '(UTC+03:00) Amman' },
  { key: 'Kaliningrad Standard Time', text: '(UTC+02:00) Kaliningrad' },
  { key: 'Kamchatka Standard Time', text: '(UTC+12:00) Petropavlovsk-Kamchatsky - Old' },
  { key: 'Korea Standard Time', text: '(UTC+09:00) Seoul' },
  { key: 'Libya Standard Time', text: '(UTC+02:00) Tripoli' },
  { key: 'Line Islands Standard Time', text: '(UTC+14:00) Kiritimati Island' },
  { key: 'Lord Howe Standard Time', text: '(UTC+10:30) Lord Howe Island' },
  { key: 'Magadan Standard Time', text: '(UTC+11:00) Magadan' },
  { key: 'Magallanes Standard Time', text: '(UTC-03:00) Punta Arenas' },
  { key: 'Marquesas Standard Time', text: '(UTC-09:30) Marquesas Islands' },
  { key: 'Mauritius Standard Time', text: '(UTC+04:00) Port Louis' },
  { key: 'Mid-Atlantic Standard Time', text: '(UTC-02:00) Mid-Atlantic - Old' },
  { key: 'Middle East Standard Time', text: '(UTC+02:00) Beirut' },
  { key: 'Montevideo Standard Time', text: '(UTC-03:00) Montevideo' },
  { key: 'Morocco Standard Time', text: '(UTC+01:00) Casablanca' },
  { key: 'Mountain Standard Time', text: '(UTC-07:00) Mountain Time (US & Canada)' },
  { key: 'Mountain Standard Time (Mexico)', text: '(UTC-07:00) La Paz, Mazatlan' },
  { key: 'Myanmar Standard Time', text: '(UTC+06:30) Yangon (Rangoon)' },
  { key: 'N. Central Asia Standard Time', text: '(UTC+07:00) Novosibirsk' },
  { key: 'Namibia Standard Time', text: '(UTC+02:00) Windhoek' },
  { key: 'Nepal Standard Time', text: '(UTC+05:45) Kathmandu' },
  { key: 'New Zealand Standard Time', text: '(UTC+12:00) Auckland, Wellington' },
  { key: 'Newfoundland Standard Time', text: '(UTC-03:30) Newfoundland' },
  { key: 'Norfolk Standard Time', text: '(UTC+11:00) Norfolk Island' },
  { key: 'North Asia East Standard Time', text: '(UTC+08:00) Irkutsk' },
  { key: 'North Asia Standard Time', text: '(UTC+07:00) Krasnoyarsk' },
  { key: 'North Korea Standard Time', text: '(UTC+09:00) Pyongyang' },
  { key: 'Omsk Standard Time', text: '(UTC+06:00) Omsk' },
  { key: 'Pacific SA Standard Time', text: '(UTC-04:00) Santiago' },
  { key: 'Pacific Standard Time', text: '(UTC-08:00) Pacific Time (US & Canada)' },
  { key: 'Pacific Standard Time (Mexico)', text: '(UTC-08:00) Baja California' },
  { key: 'Pakistan Standard Time', text: '(UTC+05:00) Islamabad, Karachi' },
  { key: 'Paraguay Standard Time', text: '(UTC-04:00) Asuncion' },
  { key: 'Qyzylorda Standard Time', text: '(UTC+05:00) Qyzylorda' },
  { key: 'Romance Standard Time', text: '(UTC+01:00) Brussels, Copenhagen, Madrid, Paris' },
  { key: 'Russia Time Zone 10', text: '(UTC+11:00) Chokurdakh' },
  { key: 'Russia Time Zone 11', text: '(UTC+12:00) Anadyr, Petropavlovsk-Kamchatsky' },
  { key: 'Russia Time Zone 3', text: '(UTC+04:00) Izhevsk, Samara' },
  { key: 'Russian Standard Time', text: '(UTC+03:00) Moscow, St. Petersburg' },
  { key: 'SA Eastern Standard Time', text: '(UTC-03:00) Cayenne, Fortaleza' },
  { key: 'SA Pacific Standard Time', text: '(UTC-05:00) Bogota, Lima, Quito, Rio Branco' },
  { key: 'SA Western Standard Time', text: '(UTC-04:00) Georgetown, La Paz, Manaus, San Juan' },
  { key: 'Saint Pierre Standard Time', text: '(UTC-03:00) Saint Pierre and Miquelon' },
  { key: 'Sakhalin Standard Time', text: '(UTC+11:00) Sakhalin' },
  { key: 'Samoa Standard Time', text: '(UTC+13:00) Samoa' },
  { key: 'Sao Tome Standard Time', text: '(UTC+00:00) Sao Tome' },
  { key: 'Saratov Standard Time', text: '(UTC+04:00) Saratov' },
  { key: 'SE Asia Standard Time', text: '(UTC+07:00) Bangkok, Hanoi, Jakarta' },
  { key: 'Singapore Standard Time', text: '(UTC+08:00) Kuala Lumpur, Singapore' },
  { key: 'South Africa Standard Time', text: '(UTC+02:00) Harare, Pretoria' },
  { key: 'South Sudan Standard Time', text: '(UTC+02:00) Juba' },
  { key: 'Sri Lanka Standard Time', text: '(UTC+05:30) Sri Jayawardenepura' },
  { key: 'Sudan Standard Time', text: '(UTC+02:00) Khartoum' },
  { key: 'Syria Standard Time', text: '(UTC+03:00) Damascus' },
  { key: 'Taipei Standard Time', text: '(UTC+08:00) Taipei' },
  { key: 'Tasmania Standard Time', text: '(UTC+10:00) Hobart' },
  { key: 'Tocantins Standard Time', text: '(UTC-03:00) Araguaina' },
  { key: 'Tokyo Standard Time', text: '(UTC+09:00) Osaka, Sapporo, Tokyo' },
  { key: 'Tomsk Standard Time', text: '(UTC+07:00) Tomsk' },
  { key: 'Tonga Standard Time', text: "(UTC+13:00) Nuku'alofa" },
  { key: 'Transbaikal Standard Time', text: '(UTC+09:00) Chita' },
  { key: 'Turkey Standard Time', text: '(UTC+03:00) Istanbul' },
  { key: 'Turks And Caicos Standard Time', text: '(UTC-05:00) Turks and Caicos' },
  { key: 'Ulaanbaatar Standard Time', text: '(UTC+08:00) Ulaanbaatar' },
  { key: 'US Eastern Standard Time', text: '(UTC-05:00) Indiana (East)' },
  { key: 'US Mountain Standard Time', text: '(UTC-07:00) Arizona' },
  { key: 'UTC', text: '(UTC) Coordinated Universal Time' },
  { key: 'UTC+12', text: '(UTC+12:00) Coordinated Universal Time+12' },
  { key: 'UTC+13', text: '(UTC+13:00) Coordinated Universal Time+13' },
  { key: 'UTC-02', text: '(UTC-02:00) Coordinated Universal Time-02' },
  { key: 'UTC-08', text: '(UTC-08:00) Coordinated Universal Time-08' },
  { key: 'UTC-09', text: '(UTC-09:00) Coordinated Universal Time-09' },
  { key: 'UTC-11', text: '(UTC-11:00) Coordinated Universal Time-11' },
  { key: 'Venezuela Standard Time', text: '(UTC-04:00) Caracas' },
  { key: 'Vladivostok Standard Time', text: '(UTC+10:00) Vladivostok' },
  { key: 'Volgograd Standard Time', text: '(UTC+03:00) Volgograd' },
  { key: 'W. Australia Standard Time', text: '(UTC+08:00) Perth' },
  { key: 'W. Central Africa Standard Time', text: '(UTC+01:00) West Central Africa' },
  { key: 'W. Europe Standard Time', text: '(UTC+01:00) Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna' },
  { key: 'W. Mongolia Standard Time', text: '(UTC+07:00) Hovd' },
  { key: 'West Asia Standard Time', text: '(UTC+05:00) Ashgabat, Tashkent' },
  { key: 'West Bank Standard Time', text: '(UTC+02:00) Gaza, Hebron' },
  { key: 'West Pacific Standard Time', text: '(UTC+10:00) Guam, Port Moresby' },
  { key: 'Yakutsk Standard Time', text: '(UTC+09:00) Yakutsk' },
  { key: 'Yukon Standard Time', text: '(UTC-07:00) Yukon' },
]

export type ValueByPatchPolicy = {
  patchPolicy: string
  value: number
}

export type PatchesBySeverityAndStatusView = {
  severity: string
  status: string
  value: number
}

export type PatchPolicySummary = {
  targetGroup: string
  unknownCount: number
  notApplicableCount: number
  notInstalledCount: number
  downloadedCount: number
  noStatus: number
  installedCount: number
  installedPendingRebootCount: number
  failedCount: number
}

export type PatchManagementDashboardView = {
  totalAvailablePatches: number
  totalApprovedPatches: number
  totalNotApprovedPatches: number
  totalApprovedPatchesByPatchPolicy: ValueByPatchPolicy[]
  totalNotApprovedPatchesByPatchPolicy: ValueByPatchPolicy[]
  patchesBySeverityAndStatus: PatchesBySeverityAndStatusView[]
  totalReportingGroups: number
  totalPatchPolicies: number
  reportingGroupsCountByPatchPolicy: ValueByPatchPolicy[]
  patchPolicySummaries: PatchPolicySummary[]
}
