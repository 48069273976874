import graphClient from '../../../query-client/graphClient'
import { gql } from 'graphql-request'
import { useQuery } from '@tanstack/react-query'

const getQuery = () => {
  const queryName = 'getInstalledApps'
  const query = gql`
    query Request($businessId: ID!) {
      getInstalledApps(organizationId: $businessId) {
        data {
          id
          organizationId
          name
          discoveredAt
          reportedAt
          operatingSystem
          publisher
        }
      }
    }
  `

  return {
    query,
    queryName,
  }
}
const getInstalledApps = (organizationId: string) => {
  const { query } = getQuery()
  return graphClient.request(query, { businessId: organizationId }).then((response) => {
    return response.getInstalledApps.data || []
  })
}
export const useInstalledApps = (organizationId: string) => {
  return useQuery(['getInstalledApps', organizationId], () => getInstalledApps(organizationId), {
    refetchOnWindowFocus: false,
    enabled: false,
  })
}