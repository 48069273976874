import { FieldTypes, Filter, SearchBox } from "@flexxibleit/flexxible-ui";
import { Stack, Toggle } from "@fluentui/react";
import { TableFilter } from "app/components/TableFilter/TableFilter";
import { serverLessOperators } from "app/components/TableFilter/server-less-operators.helper";
import { MOBILE_QUERY } from "app/config/media-query";
import useMediaQuery from "app/hooks/utils/useMediaQuery";
import { getTranslation } from "app/models/microservices";
import i18next from "i18next";
import { ChangeEvent, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

interface TableFilterProps {
  onFilter: (filteredItems: any[]) => void;
  itemsToFilter: any[] | undefined;
  showAsTable: string;
  setShowAsTable: (displayMode: string) => void;
}

export const MicroservicesMarketplaceFilters = ({ onFilter, itemsToFilter, showAsTable, setShowAsTable }: TableFilterProps) => {

  const { t } = useTranslation('microservices')
  const [searchBoxFilter, setSearchBoxFilter] = useState<Filter[]>([])
  const [tableFilter, setTableFilter] = useState<Filter[]>([])
  const [isMobile] = useMediaQuery(MOBILE_QUERY)

  useEffect(() => {
    const filters: Filter[] = [...tableFilter, ...searchBoxFilter]
    const filteredItems = itemsToFilter?.filter((itemToFilter) => {
      return filters.every((filter) => {
        const value = itemToFilter[filter.field];
        if (typeof value === 'boolean') {
          const filterValue = filter.value === 'true';
          return value === filterValue
        }
        const normalizedValue = value instanceof Array
          ? getTranslation(value, i18next.language).text
          : value;
        return serverLessOperators[filter.operator](normalizedValue, filter.value);
      });
    });
    onFilter(filteredItems!);
  }, [searchBoxFilter, tableFilter, itemsToFilter]);

  return (
    <Stack horizontal={!isMobile} horizontalAlign="space-between" className="mb-sm-2 mt-2">
      <Stack.Item grow={1} style={{ maxWidth: 400, minWidth: 196 }}>
        <SearchBox
          style={{ maxWidth: 400, minWidth: 196 }}
          placeholder={t('MARKETPLACE.SEARCH_MICROSERVICES_PLACEHOLDER')}
          onChange={(newValue: ChangeEvent<HTMLInputElement> | undefined) => setSearchBoxFilter([{
            field: 'name',
            operator: '$containsi',
            value: newValue?.target.value || ''
          }])}
        />
      </Stack.Item>
      <Stack.Item
        style={{
          marginTop: isMobile ? 20 : 0,
          alignItems: 'center',
          display: 'flex',
          gap: 20,
        }}
      >
        <TableFilter onFilter={(filters: Filter[]) => setTableFilter(filters)} filterFields={[
          {
            field: 'category',
            text: t('MARKETPLACE.TABLE.CATEGORY'),
            type: FieldTypes.STRING
          },
          {
            field: 'library',
            text: t('MARKETPLACE.TABLE.LIBRARY'),
            type: FieldTypes.STRING
          },
          {
            field: 'active',
            text: t('MICROSERVICE_STATUS.ACTIVE'),
            type: FieldTypes.BOOLEAN,
          },
        ]} />

        <Toggle
          checked={showAsTable === 'true'}
          inlineLabel={true}
          styles={{
            root: { marginBottom: 0 },
            container: { alignItems: 'center' },
          }}
          onText={t('MARKETPLACE.TOGGLE.TABLE')}
          offText={t('MARKETPLACE.TOGGLE.BLOCKS')}
          onChange={(e, checked) => setShowAsTable(checked ? 'true' : 'false')}
        />
      </Stack.Item>
    </Stack>
  )
}
