import { useTranslation } from 'react-i18next'
import { Dropdown, IDropdownOption, SpinnerSize } from '@fluentui/react'
import i18next from 'i18next'
import { useUpdateMySettings } from '../../../hooks/userSettings/useUpdateMySettings'
import languages, { getLanguageText } from '../../../i18n/languages'
import { useState } from 'react'
import { LoadingSpinner } from '@flexxibleit/flexxible-ui'

export const DefaultLanguageSelector = () => {
  const { t } = useTranslation()
  const { updateMySettings } = useUpdateMySettings()
  const [isLoading, setIsLoading] = useState(false)

  const preferredLanguage = new Intl.Locale(i18next.language).language

  const handleOnChangeDefaultLanguage = (event: any, option?: any) => {
    setIsLoading(true)
    if (option.key !== preferredLanguage) {
      updateMySettings({ preferredLanguage: option.key, preferredRegion: '' })
        .then(() => {
          setIsLoading(false)
        })
        .catch(() => setIsLoading(false))
    }
  }

  const buildLanguageOptions = () => {
    return languages.map((language: IDropdownOption) => {
      return { key: language.key, text: getLanguageText(language.key as any) }
    })
  }

  return (
    <>
      <Dropdown
        label={t('user_settings:SELECT_LANGUAGE_LABEL')}
        selectedKey={preferredLanguage}
        onChange={handleOnChangeDefaultLanguage}
        options={buildLanguageOptions()}
        disabled={isLoading}
      />
      {isLoading && (
        <div
          style={{
            top: '-25px',
            position: 'relative',
          }}
        >
          <LoadingSpinner size={SpinnerSize.small} />
        </div>
      )}
    </>
  )
}
