import { gql } from 'graphql-request'
import graphClient from '../../query-client/graphClient'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { ModulesOrderInput } from '../../pages/organization-details/modules/ModulesOrderInput'

const reorderModulesQuery = gql`
  mutation Request($input: ModulesOrderInput!) {
    reorderModules(modulesOrderInput: $input)
  }
`

function reorderModules(modulesOrderInput: ModulesOrderInput) {
  return graphClient
    .request(reorderModulesQuery, { input: modulesOrderInput })
    .then((response) => response)
}

export function useReorderModules() {
  const queryClient = useQueryClient()

  return useMutation(
    ['reorderModules'],
    (modulesOrderInput: ModulesOrderInput) => reorderModules(modulesOrderInput),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['getOrganizationModules'])
        queryClient.invalidateQueries(['getBusinessById', { forAllRoles: true }])
      }
    }
  )
}