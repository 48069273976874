import { FieldTypes } from '@flexxibleit/flexxible-ui'
import { FilterField } from '@flexxibleit/flexxible-ui/dist/lib/components/paginated-table/filters/filters.types'
import { PaginationResponse } from '@flexxibleit/flexxible-ui/dist/lib/components/table/Paginator'
import { useQuery } from '@tanstack/react-query'
import graphClient from 'app/query-client/graphClient'
import { Activation, PaginationArgs } from 'app/query-client/types'
import { dateFormatter } from 'app/services/date-formatter'
import { gql } from 'graphql-request'
import { t } from 'i18next'
import { useSearchParams } from 'react-router-dom'

type FilterParams = {
  [key: string]: any
}

type SortingParams = {
  sortBy: string
  sortOrder: string
}

export const getActivationsQuery = gql`
  query GetActivations(
    $getActivationsId: String!
    $page: Int
    $perPage: Int
    $searchTerm: String
    $sorting: ActivationGraphqlSorting!
    $filters: ActivationGraphqlFilter
  ) {
    getActivations(
      id: $getActivationsId
      page: $page
      perPage: $perPage
      searchTerm: $searchTerm
      sorting: $sorting
      filters: $filters
    ) {
      data {
        _id
        name
        type
        product
        createdAt
        deleteAt
        lastDay
        last7Days
        last30Days
        last60Days
        last90Days
        children
        level
        productStatus
      }
      pageInfo {
        hasNextPage
        hasPreviousPage
        totalPages
        currentPage
        total
      }
    }
  }
`

function prepareDataTable(data: Activation[]) {
  data.forEach((item) => {
    item.createdAt = dateFormatter(t, new Date(item.createdAt))
    if (item.deleteAt) {
      item.deleteAt = dateFormatter(t, new Date(item.deleteAt))
    }
  })
  return data
}

async function getActivations(
  organizationId: string,
  filtersParams: FilterParams,
  sortingParams: SortingParams,
  paginationParams: PaginationArgs,
  searchTerm: string
): Promise<PaginationResponse<Activation>> {
  let data = (
    await graphClient.request(getActivationsQuery, {
      getActivationsId: organizationId,
      page: paginationParams.page,
      perPage: paginationParams.perPage,
      filters: filtersParams,
      sorting: sortingParams,
      searchTerm: searchTerm,
    })
  ).getActivations
  data.results = prepareDataTable(data.data)
  return data
}

async function getActivationsNoPagination(organizationId: string) {
  let data = (
    await graphClient.request(getActivationsQuery, {
      getActivationsId: organizationId,
      sorting: {},
      perPage: -1,
    })
  ).getActivations
  data.results = prepareDataTable(data.data)
  return data.results
}

export function useGetActivationsNoPagination(organizationId: string) {
  return useQuery(['getActivations', organizationId], () => getActivationsNoPagination(organizationId), {
    refetchOnWindowFocus: false,
    enabled: false,
  })
}

export default function (organizationId: string, filters: FilterField[] = []) {
  const [searchParams] = useSearchParams()

  const searchTerm = searchParams.get('searchTerm') || ''

  const filtersAndSorting = Object.fromEntries(searchParams.entries())
  const filterKeys = Object.keys(filtersAndSorting).filter((key) => filters.some((f) => f.field === key))

  let filtersParams: FilterParams = {}
  filterKeys.forEach((key) => {
    const filter = filters.find((f) => f.field === key)
    filtersParams[key] = filter?.type === FieldTypes.ENUM ? searchParams.getAll(key) : searchParams.get(key)
    if (filter?.type === FieldTypes.NUMBER) {
      filtersParams[key] = Number(filtersParams[key])
    } else if (filter?.type === FieldTypes.BOOLEAN) {
      filtersParams[key] = filtersParams[key] === 'true'
    }
  })

  const sortingParams: SortingParams = {
    sortBy: filtersAndSorting.sortBy,
    sortOrder: filtersAndSorting.sortOrder,
  }

  const paginationParams: PaginationArgs = {
    page: Number(filtersAndSorting.page) || 1,
    perPage: Number(filtersAndSorting.perPage) || 10,
  }

  return useQuery(
    ['getActivations', organizationId, filtersParams, sortingParams, paginationParams, searchTerm],
    () => getActivations(organizationId, filtersParams, sortingParams, paginationParams, searchTerm),
    {
      refetchOnWindowFocus: false,
    }
  )
}
