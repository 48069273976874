import React, { createContext, Dispatch, ReactNode, SetStateAction, useContext, useState } from 'react'
import { LateralPanelProps } from '../../../lateral-panel/LateralPanel.component';
import { ErrorStatusProps } from '../../../status/error-status';
import { SuccessStatusProps } from '../../../status/success-status';

export type StatusProps = SuccessStatusProps | ErrorStatusProps


export type Status = 'SUCCESS' | 'ERROR'
export interface FormSidebarStatus {
  status: Status;
  statusProps?: Partial<StatusProps>;
}

export interface FormSidebarContextType {
  isSidebarOpen: boolean;
  openSidebar: () => void;
  closeSidebar: () => void;
  status: FormSidebarStatus | undefined;
  setSuccess: (statusProps: Partial<SuccessStatusProps>) => void;
  setError: (statusProps: Partial<ErrorStatusProps>) => void;
  content: any;
  setContent: Dispatch<SetStateAction<any>>;
  formSidebarProps: Partial<LateralPanelProps>,
  setFormSidebarProps: Dispatch<SetStateAction<Partial<LateralPanelProps>>>;
}

export const FormSidebarContext = createContext<FormSidebarContextType | undefined>(undefined)

export function FormSidebarProvider({ children }: { children: ReactNode }) {
  const [isSidebarOpen, setIsOpen] = useState(false);
  const [status, setStatus] = useState<FormSidebarStatus | undefined>(undefined);
  const [content, setContent] = useState<any>(undefined);
  const [formSidebarProps, setFormSidebarProps] = useState<Partial<LateralPanelProps>>({});

  const openSidebar = () => {
    setIsOpen(true);
  }

  const closeSidebar = () => {
    setIsOpen(false)
    setContent(undefined)
    setStatus(undefined)
  }

  const setSuccess = (statusProps: Partial<SuccessStatusProps>) => {
    setStatus({ status: 'SUCCESS', statusProps })
  }

  const setError = (statusProps: Partial<ErrorStatusProps>) => {
    setStatus({ status: 'ERROR', statusProps })
  }

  return (
    <FormSidebarContext.Provider
      value={
        {
          isSidebarOpen,
          openSidebar,
          closeSidebar,
          status,
          setSuccess,
          setError,
          content,
          setContent,
          formSidebarProps,
          setFormSidebarProps
        }
      }
    >
      {children}
    </FormSidebarContext.Provider>
  )
}

export function useFormSidebarContext(): FormSidebarContextType {
  const formSidebarContext = useContext(FormSidebarContext)

  if (!formSidebarContext) {
    throw new Error('useFormSidebarContext must be used within the FormSidebarContext.Provider');
  }

  return formSidebarContext;
}