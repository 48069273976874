import { useQuery } from '@tanstack/react-query'
import graphClient from 'app/query-client/graphClient'
import { gql } from 'graphql-request'

const query = gql`
  query Request($businessId: ID!) {
    getBusinessEligibleProducts(businessId: $businessId) {
      name
      regions
    }
  }
`

function getBusinessEligibleProducts(businessId: string) {
  return graphClient.request(query, { businessId }).then((response) => {
    return response.getBusinessEligibleProducts || []
  })
}

export default function (businessId: string) {
  return useQuery(['getBusinessEligibleProducts', businessId], () => getBusinessEligibleProducts(businessId), {
    refetchOnWindowFocus: false,
    enabled: false,
  })
}
