import { FieldTypes, FilterField } from '@flexxibleit/flexxible-ui'
import { useLocalStorageSearchParams } from 'app/hooks/utils/useLocalStorageSearchParams'
import {
  analyzerPermissionLocalizationKeyDictionary,
  AnalyzerPermissionName,
  AnalyzerPermissions,
} from 'app/permissions/UserAnalyzerPermission.enum'
import {
  PortalPermission,
  portalPermissionLocalizationKeyDictionary,
  PortalPermissionName,
} from 'app/permissions/UserPortalPermission.enum'
import {
  workspacePermissionLocalizationKeyDictionary,
  WorkspacePermissionName,
  WorkspacePermissions,
} from 'app/permissions/UserWorkspacePermission.enum'
import { TFunction } from 'i18next'

type UseRolesFilters = {
  t: TFunction
}

export const useRolePermissionsFilters = ({ t }: UseRolesFilters) => {
  useLocalStorageSearchParams()

  const filterFields: FilterField[] = [
    {
      field: 'organization',
      text: t('ROLES.PERMISSIONS.TABLE.ORGANIZATION'),
      type: FieldTypes.STRING,
    },
    {
      field: 'portal',
      text: t('ROLES.PERMISSIONS.TABLE.PORTAL'),
      type: FieldTypes.ENUM,
      options: Object.values(PortalPermission).map((item) => {
        return {
          key: item,
          text: t(portalPermissionLocalizationKeyDictionary[item as PortalPermissionName]),
        }
      }),
    },
    {
      field: 'workspaces',
      text: t('ROLES.PERMISSIONS.TABLE.WORKSPACES'),
      type: FieldTypes.ENUM,
      options: Object.values(WorkspacePermissions).map((item) => {
        return {
          key: item,
          text: t(workspacePermissionLocalizationKeyDictionary[item as WorkspacePermissionName]),
        }
      }),
    },
    {
      field: 'analyzer',
      text: t('ROLES.PERMISSIONS.TABLE.ANALYZER'),
      type: FieldTypes.ENUM,
      options: Object.values(AnalyzerPermissions).map((item) => {
        return {
          key: item,
          text: t(analyzerPermissionLocalizationKeyDictionary[item as AnalyzerPermissionName]),
        }
      }),
    },
  ]

  return {
    filterFields,
  }
}
