import { MOBILE_QUERY, useMediaQuery } from '@flexxibleit/flexxible-ui'
import { BusinessType } from '../../../models/business/BusinessType'
import { getOrganizationTypeIcon } from '../../../services/organization-icon-service'
import { useTranslation } from 'react-i18next'
import { useOrganizationSelection } from '../../../globals/useOrganizationSelection'
import { useNavigate, useParams } from 'react-router-dom'
import useOrganizationById from '../../../hooks/organization/useOrganizationById'
import React, { useEffect } from 'react'
import PageBody from '../../../components/PageBody/PageBody.component'
import { FeedbackMessageBar } from '../../../components/FeedbackMessageBar/FeedbackMessageBar'
import { ProductsFeature } from '../products/Products.feature'
import { withFeatureRender } from '../../../permissions/withFeatureRender'
import { FEATURE_NAMES } from '../../../permissions/FeatureName.enum'
import { ModulesFeature } from './modules.feature'

const TitleComponent = ({ title, type }: { title: string; type?: BusinessType }) => {
  const [isMobile] = useMediaQuery(MOBILE_QUERY)

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'start',
      }}
    >
      {type && <div className="mr-1 vertical-center">{getOrganizationTypeIcon(type)}</div>}
      {isMobile ? <h3>{title}</h3> : <h1>{title}</h1>}
    </div>
  )
}
const OrgModules = () => {
  const { t } = useTranslation('organization_details')
  const navigate = useNavigate()
  const { selectedOrganizationId } = useOrganizationSelection()
  const { organizationId } = useParams()
  const { isLoading, data, isFetching, refetch } = useOrganizationById(organizationId || '')
  const baseUrl = location.pathname.split('/')[1]

  useEffect(() => {
    if (!selectedOrganizationId) {
      return
    }

    navigate(`/${baseUrl}/${selectedOrganizationId}/modules`)
  }, [selectedOrganizationId])

  useEffect(() => {
    refetch()
  }, [organizationId])

  return (
    <PageBody
      title={isLoading || isFetching ? '' : data?.name || ''}
      titleComponent={<TitleComponent title={`${data?.name} - ${t('MODULES_PIVOT')}`} type={data?.type} />}
      isLoading={isLoading || isFetching}
    >
      <FeedbackMessageBar />

      <div className="business-details pb-2">
        <ModulesFeature organizationId={data?._id || ''} />
      </div>
    </PageBody>
  )
}

export const OrgModulesPage = withFeatureRender(OrgModules, FEATURE_NAMES.MODULE_READ)
