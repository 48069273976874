import { MOBILE_QUERY } from 'app/config/media-query'
import useMediaQuery from 'app/hooks/utils/useMediaQuery'
import { FEATURE_NAMES } from 'app/permissions/FeatureName.enum'
import { useFeatureRender } from 'app/permissions/useFeatureRender'

import { withFeatureRender } from 'app/permissions/withFeatureRender'
import { RolePermission, UserRole } from 'app/query-client/types'
import { i18n } from 'i18next'
import { useEffect, useState } from 'react'
import { TFunction } from 'react-i18next'
import { useSearchParams } from 'react-router-dom'

import { ViewDetailsActionButton } from '@flexxibleit/flexxible-ui'
import { IPaginatedTableColumn } from '@flexxibleit/flexxible-ui/dist/lib/components/paginated-table/paginatedTable.types'
import { roleLocalizationKeyDictionary, RoleName } from 'app/permissions/RoleFeature.enum'
import {
  workspacePermissionLocalizationKeyDictionary,
  WorkspacePermissionName,
} from 'app/permissions/UserWorkspacePermission.enum'
import {
  analyzerPermissionLocalizationKeyDictionary,
  AnalyzerPermissionName,
} from 'app/permissions/UserAnalyzerPermission.enum'
import { CellWithFeatureGreenLink } from 'app/components/table-page-body/CellWithFeatureGreenLink'
import { useSidePanel } from 'app/hooks/useCreateForm'
import { RolPermissionsForm } from './RolPermissionsForm'

type UseRolesPermissionsBuildTableProps = {
  t: TFunction
  i18n: i18n
  userRole: UserRole
}

export const useRolePermissionsBuildTable = ({ t, i18n, userRole }: UseRolesPermissionsBuildTableProps) => {
  const [isMobile] = useMediaQuery(MOBILE_QUERY)
  const [columns, setColumns] = useState<IPaginatedTableColumn[]>([])
  const [searchParams] = useSearchParams()
  const { checkFeature } = useFeatureRender()
  const { openSidePanelWith } = useSidePanel()

  const tableActionsFeatures = [FEATURE_NAMES.ROLE_READ]
  const canSeeActions = tableActionsFeatures.some((feature) => checkFeature(feature))

  const openUpdatePermissionForm = (rolePermission: RolePermission) => () => {
    openSidePanelWith(
      {
        title: t('ROLES.PERMISSIONS.FORM.EDIT_TITLE'),
        isFooterAtBottom: true,
      },
      <RolPermissionsForm userRole={userRole} rolePermission={rolePermission} />
    )
  }

  useEffect(() => {
    setColumns([
      {
        key: 'organization',
        name: t('ROLES.PERMISSIONS.TABLE.ORGANIZATION'),
        fieldName: 'organization',
        minWidth: 25,
        flexGrow: 1,
        onRender: (item: RolePermission) => {
          if (item.allOrganizations) {
            return (
              <CellWithFeatureGreenLink feature={FEATURE_NAMES.ROLE_READ} callback={openUpdatePermissionForm(item)}>
                {t('ROLES.PERMISSIONS.TABLE.ALL_ORGANIZATIONS')}
              </CellWithFeatureGreenLink>
            )
          }
          return (
            <CellWithFeatureGreenLink feature={FEATURE_NAMES.ROLE_READ} callback={openUpdatePermissionForm(item)}>
              {item?.organization?.name}
            </CellWithFeatureGreenLink>
          )
        },
      },
      {
        key: 'portal',
        name: t('ROLES.PERMISSIONS.TABLE.PORTAL'),
        fieldName: 'portal',
        minWidth: 25,
        flexGrow: 1,
        onRender: (item: RolePermission) => {
          return <span>{t(roleLocalizationKeyDictionary[item?.portal as RoleName])}</span>
        },
      },

      {
        key: 'workspaces',
        name: t('ROLES.PERMISSIONS.TABLE.WORKSPACES'),
        fieldName: 'workspaces',
        minWidth: 25,
        flexGrow: 1,
        onRender: (item: RolePermission) => {
          return (
            <span>{t(workspacePermissionLocalizationKeyDictionary[item?.workspaces as WorkspacePermissionName])}</span>
          )
        },
      },
      {
        key: 'analyzer',
        name: t('ROLES.PERMISSIONS.TABLE.ANALYZER'),
        fieldName: 'analyzer',
        minWidth: 25,
        flexGrow: 1,
        onRender: (item: RolePermission) => {
          return <span>{t(analyzerPermissionLocalizationKeyDictionary[item?.analyzer as AnalyzerPermissionName])}</span>
        },
      },
      ...(canSeeActions
        ? [
            {
              key: 'actions',
              name: t('ROLES.PERMISSIONS.TABLE.ACTION'),
              minWidth: 25,
              flexGrow: 1,
              onRender: (item: RolePermission) => {
                return (
                  <span className="d-flex d-flexRow">
                    <span style={isMobile ? { marginRight: 10 } : { marginRight: 20 }}>
                      {withFeatureRender(
                        ViewDetailsActionButton,
                        FEATURE_NAMES.ROLE_READ
                      )({
                        locale: i18n.language,
                        isMobile: isMobile,
                        onClick: openUpdatePermissionForm(item),
                      })}
                    </span>
                  </span>
                )
              },
            },
          ]
        : []),
    ])
  }, [searchParams, canSeeActions, userRole])

  return {
    columns,
  }
}
