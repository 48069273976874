import { useMutation, useQueryClient } from '@tanstack/react-query'
import { gql } from 'graphql-request'
import graphClient from '../../query-client/graphClient'

interface UpdateElegibleProductInput {
  elegibleProductId: string
  deploymentRegions?: string[] | null
}

const updateElegibleQuery = gql`
  mutation Request($organizationId: ID!, $input: UpdateElegibleProductInput!) {
    updateElegibleProduct(organizationId: $organizationId, input: $input) {
      _id
    }
  }
`

function updateElegibleProduct(input: UpdateElegibleProductInput, organizationId: string) {
  return graphClient
    .request(updateElegibleQuery, {
      organizationId: organizationId,
      input,
    })
    .then((response) => response.updateElegibleProduct)
}

export default function (organizationId: string) {
  const queryClient = useQueryClient()

  return useMutation(
    ['updateElegibleProduct'],
    (input: UpdateElegibleProductInput) => updateElegibleProduct(input, organizationId),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['getBusinessById'])
        queryClient.invalidateQueries(['getBusinessById', 'elegibleProducts'])
      },
    }
  )
}
