import {
  ControlledTextField,
  DeleteActionButton,
  ErrorStatus,
  FormActionsContainer,
  RowColLayout,
  SuccessStatus,
} from '@flexxibleit/flexxible-ui'
import { IDropdownOption, IPersonaProps, ResponsiveMode } from '@fluentui/react'
import { useBoolean } from '@fluentui/react-hooks'
import { zodResolver } from '@hookform/resolvers/zod'
import Dialog from 'app/components/Dialog/Dialog'
import Dropdown from 'app/components/forms/Dropdown'
import FormActions from 'app/components/forms/FormActions'
import { useFormSidebarContext } from 'app/components/forms/FormSidebar/state/FormSidebarState'
import { CompactPeoplePicker } from 'app/components/forms/PeoplePicker/CompactPeoplePicker'
import useCreatePolicyReportingGroup from 'app/hooks/policies/useCreatePolicyReportingGroup'
import { useDeletePolicyReportingGroup } from 'app/hooks/policies/useDeletePolicyReportingGroup'
import { TenantPolicyReportingGroupView } from 'app/hooks/policies/useGetPolicy'
import { useUpdatePolicyReportingGroup } from 'app/hooks/policies/useUpdatePolicyReportingGroup'
import { User } from 'app/query-client/types'
import { useEffect } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import * as z from 'zod'

interface Props {
  policyId: string
  organizationId: string
  id?: string
  data?: FormValues
  users: User[]
  reportingGroups: TenantPolicyReportingGroupView[]
}

interface FormValues {
  name: string
}

const schema: z.ZodType<FormValues> = z.object({
  name: z.string({ required_error: 'required' }),
})

const getUserOptions = (users: User[]): IPersonaProps[] => {
  return users
    .map((user: User) => ({ key: user._id, text: [user.name, user.surname].join(' '), secondaryText: user.email }))
    .sort((a, b) => a.text.localeCompare(b.text))
}

export const PolicyReportingGroupForm = ({ id, organizationId, policyId, data, users, reportingGroups }: Props) => {
  const { t, i18n } = useTranslation('organization_details')
  const createReportingGroup = useCreatePolicyReportingGroup()
  const udpateReportingGroup = useUpdatePolicyReportingGroup()
  const deleteReportingGroup = useDeletePolicyReportingGroup()
  const [hideDeleteDialog, { toggle: toggleHideDeleteDialog }] = useBoolean(true)
  const { closeSidebar } = useFormSidebarContext()
  const isEdit = Boolean(id)

  const dropdownOptions = getUserOptions(users)

  const methods = useForm<FormValues>({
    resolver: zodResolver(schema),
    defaultValues: data ?? {
      name: '',
    },
  })

  const onSubmit = (values: FormValues) => {
    if (isEdit) {
      udpateReportingGroup.mutate({ id: id!, policyId, input: values })
    } else {
      createReportingGroup.mutate({
        name: values.name,
        organizationId,
        policyId,
      })
    }
  }

  const onDelete = (replaceWith: string) => {
    if (id) {
      deleteReportingGroup.mutateAsync({ id, policyId, input: { replaceWith, organizationId } }).then(() => {
        closeSidebar()
      })
    }
  }

  if (createReportingGroup.isSuccess) {
    return (
      <div className="d-flex h-100">
        <SuccessStatus message={t('POLICIES_REPORTING_GROUPS.FORM.ADD_SUCCESS_MESSAGE')} />
      </div>
    )
  }
  if (createReportingGroup.isError) {
    return (
      <div className="d-flex h-100">
        <ErrorStatus message={t('POLICIES_REPORTING_GROUPS.FORM.ADD_ERROR_MESSAGE')} />
      </div>
    )
  }

  if (udpateReportingGroup.isSuccess) {
    return (
      <div className="d-flex h-100">
        <SuccessStatus message={t('POLICIES_REPORTING_GROUPS.FORM.EDIT_SUCCESS_MESSAGE')} />
      </div>
    )
  }
  if (udpateReportingGroup.isError) {
    return (
      <div className="d-flex h-100">
        <ErrorStatus message={t('POLICIES_REPORTING_GROUPS.FORM.EDIT_ERROR_MESSAGE')} />
      </div>
    )
  }

  if (deleteReportingGroup.isError) {
    return (
      <div className="d-flex h-100">
        <ErrorStatus message={t('POLICIES_REPORTING_GROUPS.FORM.DELETE_ERROR_MESSAGE')} />
      </div>
    )
  }

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <RowColLayout rowProps={{ classNames: 'mt-2' }}>
          <ControlledTextField
            name="name"
            required={true}
            translator={t}
            textFieldProps={{
              label: t('POLICIES_REPORTING_GROUPS.FORM.NAME'),
              placeholder: t('USERS.FORM.ENTER_TEXT'),
            }}
          />
        </RowColLayout>

        <FormActionsContainer isEdit={isEdit}>
          {false && isEdit && <DeleteActionButton locale={i18n.language} onClick={toggleHideDeleteDialog} />}
          <FormActions
            submitProps={
              isEdit
                ? {
                    text: t('general:BUTTON.SAVE'),
                    iconProps: { iconName: 'Save' },
                  }
                : {
                    text: t('general:BUTTON.CREATE'),
                    iconProps: { iconName: 'Add' },
                  }
            }
          />
        </FormActionsContainer>
      </form>
      <ReplacementDialog
        hidden={hideDeleteDialog}
        onDismiss={toggleHideDeleteDialog}
        callback={(replaceWith: string) => {
          onDelete(replaceWith)
        }}
        options={reportingGroups.filter((ele) => ele._id !== id).map((ele) => ({ key: ele._id, text: ele.name }))}
      />
    </FormProvider>
  )
}

interface DeleteConfirmationProps {
  options: IDropdownOption[]
  callback: (replaceWith: string) => void
  onDismiss?: () => void
  hidden: boolean
}
interface DeleteFormData {
  replaceWith: string
}
const deleteSchema: z.ZodType<DeleteFormData> = z.object({
  replaceWith: z.string({ required_error: 'required' }),
})
const ReplacementDialog = (props: DeleteConfirmationProps) => {
  const { t, i18n } = useTranslation('organization_details')

  const methods = useForm<DeleteFormData>({
    resolver: zodResolver(deleteSchema),
  })

  useEffect(() => {
    methods.reset()
  }, [props.hidden])

  return (
    <Dialog
      title={t('POLICIES_REPORTING_GROUPS.DELETE_CONFIRMATION.TITLE')}
      description={t('POLICIES_REPORTING_GROUPS.DELETE_CONFIRMATION.MESSAGE')}
      actionButton={t('POLICIES_REPORTING_GROUPS.DELETE_CONFIRMATION.BUTTON_ACCEPT')}
      dismissButton={t('POLICIES_REPORTING_GROUPS.DELETE_CONFIRMATION.BUTTON_CANCEL')}
      actionButtonDisabled={!methods.formState.isValid}
      hidden={props.hidden}
      onDismiss={props.onDismiss}
      callback={() => {
        props.callback(methods.getValues().replaceWith)
      }}
    >
      <FormProvider {...methods}>
        <RowColLayout rowProps={{ classNames: 'mt-2' }}>
          <Dropdown
            name="replaceWith"
            required={true}
            translator={t}
            dropdownProps={{
              responsiveMode: ResponsiveMode.large,
              label: t('POLICIES_REPORTING_GROUPS.DELETE_CONFIRMATION.REPORTING_GROUP'),
              placeholder: t('POLICIES_REPORTING_GROUPS.DELETE_CONFIRMATION.REPORTING_GROUP_PLACEHOLDER'),
              options: props.options,
            }}
          />
        </RowColLayout>
      </FormProvider>
    </Dialog>
  )
}
