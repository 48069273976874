import { MOBILE_QUERY } from 'app/config/media-query'
import useMediaQuery from 'app/hooks/utils/useMediaQuery'
import i18next from 'i18next'
import { Suspense, useEffect, useState } from 'react'
import { TFunction } from 'react-i18next'
import { useSearchParams } from 'react-router-dom'

import { ViewDetailsActionButton } from '@flexxibleit/flexxible-ui'
import { Spinner } from '@fluentui/react'
import { IPaginatedTableColumn } from '@flexxibleit/flexxible-ui/dist/lib/components/paginated-table/paginatedTable.types'
import BootstrapIcon from 'app/components/BootstrapIcon/BootstrapIcon'
import { Badge } from 'app/components/Badge/Badge'
import { renderMicroserviceCategory } from './renderers/renderMicroserviceCategory'
import { renderMicroserviceName } from './renderers/renderMicroserviceName'
import { PaginatedEnabledMicroservicePageViewData } from './PaginatedEnabledMicroservicePageViewData'

type UseEnabledMicroservicesBuildTableProps = {
  t: TFunction
  handleOnClickViewDetails: (item: PaginatedEnabledMicroservicePageViewData) => void
}

export const useEnabledMicroservicesBuildTable = ({
  t,
  handleOnClickViewDetails,
}: UseEnabledMicroservicesBuildTableProps) => {
  const [isMobile] = useMediaQuery(MOBILE_QUERY)
  const [columns, setColumns] = useState<IPaginatedTableColumn[]>([])
  const [searchParams] = useSearchParams()

  useEffect(() => {
    setColumns([
      {
        key: 'iconName',
        name: t('MARKETPLACE.TABLE.ICON'),
        fieldName: 'iconName',
        minWidth: 25,
        isIconOnly: true,
        maxWidth: 25,
        isSortable: false,
        onRender: (item: PaginatedEnabledMicroservicePageViewData) => {
          if (!item.iconName) return null

          return (
            <Suspense fallback={<Spinner />}>
              <BootstrapIcon
                iconName={item.iconName}
                size={24}
                style={item.iconBackground ? { color: item.iconBackground } : {}}
              />
            </Suspense>
          )
        },
      },
      {
        key: 'microserviceName',
        name: t('MARKETPLACE.TABLE.NAME'),
        fieldName: 'name',
        flexGrow: 2,
        onRender: (item) => renderMicroserviceName(t, item, handleOnClickViewDetails),
      },
      {
        key: 'category',
        name: t('MARKETPLACE.TABLE.CATEGORY'),
        fieldName: 'category',
        flexGrow: 1,
        onRender: renderMicroserviceCategory,
      },
      {
        key: 'organizationName',
        name: t('MARKETPLACE.TABLE.LIBRARY'),
        fieldName: 'library',
        flexGrow: 1,
        onRender: (item: PaginatedEnabledMicroservicePageViewData) => {
          return (
            <>
              {item.organization.isTrial && <Badge>{t('organizations:TRIAL')}</Badge>}
              <span>{item.organization.name}</span>
            </>
          )
        },
      },
      {
        key: 'actions',
        name: t('MARKETPLACE.TABLE.ACTIONS'),
        flexGrow: 1,
        onRender: (item: PaginatedEnabledMicroservicePageViewData) => {
          return (
            <ViewDetailsActionButton
              locale={i18next.language}
              isMobile={isMobile}
              onClick={() => handleOnClickViewDetails(item)}
            />
          )
        },
      },
    ])
  }, [searchParams])

  return {
    columns,
  }
}
