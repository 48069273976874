import { gql } from 'graphql-request'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { CreateWindowsPatchPolicyFormType } from 'app/pages/patch-management/windows-patch-policies/components/CreateWindowsPatchPolicyForm'
import { CreateLinuxPatchPolicyFormType } from 'app/pages/patch-management/linux-path-policies/components/CreateLinuxPatchPolicyForm'
import graphClient from 'app/query-client/graphClient'

const createLinuxPatchPolicyRequest = gql`
  mutation CreateLinuxPatchPolicy($input: CreateLinuxPatchPolicyGraphqlRequest!, $organizationId: String!) {
    createLinuxPatchPolicy(input: $input, organizationId: $organizationId) {
      id
    }
  }
`

const createLinuxPatchPolicy = async (linuxPatchPolicy: CreateLinuxPatchPolicyFormType, organizationId: string) => {
  const response = await graphClient.request(createLinuxPatchPolicyRequest, {
    organizationId: organizationId,
    input: linuxPatchPolicy,
  })

  return response.createLinuxPatchPolicy
}

export const useCreateLinuxPatchPolicy = (organizationId: string) => {
  const queryClient = useQueryClient()

  return useMutation(
    ['createLinuxPatchPolicy'],
    (linuxPatchPolicy: CreateLinuxPatchPolicyFormType) => createLinuxPatchPolicy(linuxPatchPolicy, organizationId),
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries(['getLinuxPatchPoliciesByOrganization'])
      },
    }
  )
}
