import { Dropdown, IDropdownOption } from '@fluentui/react'
import { MOBILE_QUERY } from 'app/config/media-query'
import useMediaQuery from 'app/hooks/utils/useMediaQuery'
import { useTranslation } from 'react-i18next'

interface Props {
  dropdownDateSelected: string | null
  onDropdownChange: (event?: React.FormEvent<HTMLDivElement>, item?: IDropdownOption) => void
  translationModule: string
}

export const DateOptionsDropDown = ({ dropdownDateSelected, onDropdownChange, translationModule }: Props) => {
  const { t } = useTranslation(translationModule)
  const [isMobile] = useMediaQuery(MOBILE_QUERY)

  let dropdownOptions: IDropdownOption[] = [
    { key: 'last_30_days', text: t('reports:LAST_30_DAYS') },
    { key: 'current_month', text: t('reports:CURRENT_MONTH') },
    { key: 'last_3_months', text: t('reports:LAST_3_MONTHS') },
  ]
  return (
    <Dropdown
      style={isMobile ? { width: '100%', marginBottom: 15 } : { width: 150, marginBottom: 5, marginRight: 27 }}
      selectedKey={dropdownDateSelected}
      onChange={onDropdownChange}
      placeholder={t('reports:CUSTOM_DATE')}
      options={dropdownOptions}
    ></Dropdown>
  )
}
