import { EmptyStatus } from '@flexxibleit/flexxible-ui'
import { Icon } from '@fluentui/react'
import { TFunction } from 'i18next'

type EmptyWorkspaceGroupsProps = {
  t: TFunction,
  onAddWorkspaceGroupClicked: () => void
}

export const EmptyWorkspaceGroups = ({ t, onAddWorkspaceGroupClicked }: EmptyWorkspaceGroupsProps) => {
  return (
    <div style={{ marginTop: 100 }}>
      <EmptyStatus
        message={t('WORKSPACE_GROUPS.EMPTY_MESSAGE')}
        img={<Icon iconName="PC1" style={{ fontSize: 68 }} />}
        buttonProps={{
          text: t('general:BUTTON.CREATE'),
          iconProps: { iconName: 'Add' },
          onClick: onAddWorkspaceGroupClicked,
        }}
      />
    </div>
  )
}
