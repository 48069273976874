import { IColumn, Selection, SelectionMode } from '@fluentui/react'
import Table from 'app/components/Table/Table.component'
import { PaginationArgs, PaginationResponse } from 'app/query-client/types'
import { useTranslation } from 'react-i18next'

interface Props {
  columns: IColumn[]
  items: any[]
  editable: boolean
  isLoading: boolean
  isFetching: boolean
  selection?: Selection
  pagination?: PaginationArgs
  paginatedData?: PaginationResponse<any>
  onPaginationChange?: (pagination: PaginationArgs) => void
}

export const TablaSelector = ({
  columns,
  items,
  selection,
  editable,
  isLoading,
  isFetching,
  pagination,
  paginatedData,
  onPaginationChange,
}: Props) => {
  const { t } = useTranslation('organization_details')

  return (
    <Table
      isLoading={isLoading}
      isEmpty={items?.length === 0}
      emptyMessage={t('general:EMPTY_FILTER')}
      tableProps={{
        columns: columns,
        items: items,
        selection: selection as Selection,
        selectionMode: editable ? SelectionMode.multiple : SelectionMode.none,
        onShouldVirtualize: () => false,
      }}
      paginationProps={
        pagination
          ? {
              pagination: pagination,
              onPaginationChange: onPaginationChange!,
              paginatedData: paginatedData!,
              isFetching,
            }
          : undefined
      }
    />
  )
}
