import { useQuery } from '@tanstack/react-query'
import graphClient from 'app/query-client/graphClient'
import { PaginationArgs } from 'app/query-client/types'
import { gql } from 'graphql-request'

export interface OrganizationView {
  _id: string
  name: string
  active: boolean
  isTrial: boolean
  users: string[]
  productConfigs: string[]
}

const query = gql`
  query ($page: Int!, $perPage: Int!, $searchTerm: String!, $active: Boolean) {
    getBusinessPaginated(page: $page, perPage: $perPage, searchTerm: $searchTerm, active: $active) {
      data {
        _id
        name
        active
        isTrial
        users
        productConfigs
      }
      pageInfo {
        hasNextPage
        hasPreviousPage
        totalPages
        currentPage
        total
      }
    }
  }
`

function getOrganizationsPaginated(searchTerm: string, active: boolean | null, pagination: PaginationArgs) {
  return graphClient
    .request(query, {
      page: pagination.page,
      perPage: pagination.perPage,
      searchTerm,
      active,
    })
    .then((response) => {
      return {
        results: response.getBusinessPaginated.data as OrganizationView[],
        pageInfo: response.getBusinessPaginated.pageInfo,
      }
    })
}

export default function (searchTerm: string, active: boolean | null, pagination: PaginationArgs) {
  return useQuery(
    ['getOrganizationsPaginated', pagination, searchTerm, active],
    () => getOrganizationsPaginated(searchTerm, active, pagination),
    {
      refetchOnWindowFocus: false,
      keepPreviousData: true,
    }
  )
}
