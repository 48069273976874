import { IPivotItemProps, MessageBar, MessageBarType } from '@fluentui/react'
import { useBoolean } from '@fluentui/react-hooks'
import Dialog from 'app/components/Dialog/Dialog'
import { FeedbackMessageBar } from 'app/components/FeedbackMessageBar/FeedbackMessageBar'
import PageBody from 'app/components/PageBody/PageBody.component'
import { TabPanel } from 'app/components/tab-panel/TabPanel'
import { MOBILE_QUERY } from 'app/config/media-query'
import { MicroserviceTabsContext, MicroserviceTabsState } from 'app/context/MicroserviceTabsContext'
import { useOrganizationSelection } from 'app/globals/useOrganizationSelection'
import { useGetEnabledMicroserviceById } from 'app/hooks/microservices/useGetEnabledMicroserviceById'
import useMediaQuery from 'app/hooks/utils/useMediaQuery'
import { MicroserviceAction, getTranslation } from 'app/models/microservices'
import { MicroserviceDetailsScript } from 'app/pages/microservices/details/script/MicroserviceDetailsScript'
import { MicroserviceOrganizationTargetsTab } from 'app/pages/microservices/details/target-groups/MicroserviceOrganizationTargetsTab'
import NotFoundPage from 'app/pages/not-found/not-found.page'
import { DateFormat, dateFormatter } from 'app/services/date-formatter'
import i18next from 'i18next'
import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { StringParam, useQueryParam } from 'use-query-params'
import history, { Transition } from '../../../routing/history'
import { MicroserviceLicense } from './license/MicroserviceLicense'
import { EnabledMicroserviceOverview } from './overview/EnabledMicroserviceOverview'
import { EnabledMicroserviceSettingsTab } from './settings/EnabledMicroserviceSettingsTab'
import { PRODUCT_ENV } from 'app/config/Consts'
import { ProductEnvTypes } from 'app/config/productEnv.enum'
import { LoadingSpinner } from '@flexxibleit/flexxible-ui'

const onRenderItemLink = (props?: IPivotItemProps) => {
  return (
    <span>
      <span>{props?.headerText}</span>
    </span>
  )
}

const EnabledMicroservicePage = () => {
  const { microserviceId } = useParams()
  const { selectedOrganizationId } = useOrganizationSelection()
  const { data: microservice, isLoading } = useGetEnabledMicroserviceById(microserviceId!, selectedOrganizationId ?? '')
  const navigate = useNavigate()
  const { t } = useTranslation('microservices')
  const location = useLocation()

  const [tabsState, setTabsState] = useState<MicroserviceTabsState>({})
  const globalContextValue = useMemo(() => ({ state: tabsState, setState: setTabsState }), [tabsState, setTabsState])
  const [tabQueryParameter] = useQueryParam('tab', StringParam)
  const [hideDialog, { toggle: toggleHideDialog }] = useBoolean(true)
  const [modalCallback, setModalCallback] = useState<() => void>(() => () => {
    toggleHideDialog()
  })

  useEffect(() => {
    if (!tabsState || !Object.keys(tabsState).length) return

    const unblock = history.block((tx: Transition) => {
      const hasChanges = Object.keys(tabsState).length
      const isSamePage = tx.location.pathname === location.pathname
      if (!hasChanges || isSamePage) {
        unblock()
        tx.retry()
        return
      }

      setModalCallback(() => {
        return () => {
          unblock()
          tx.retry()
        }
      })

      toggleHideDialog()
    })

    return () => unblock()
  }, [history, tabsState, tabQueryParameter])

  if (isLoading) {
    return <LoadingSpinner />
  }

  if (!microservice) {
    return <NotFoundPage />
  }

  const name = getTranslation(microservice.name, i18next.language)
  const itemName = name.text
  return (
    <MicroserviceTabsContext.Provider value={globalContextValue}>
      <PageBody
        isLoading={isLoading}
        titleComponent={
          <TitleComponent
            title={`${itemName}`}
            titleTag={PRODUCT_ENV !== ProductEnvTypes.FXXONE ? 'PREVIEW' : undefined}
          />
        }
        titleTag={'PREVIEW'}
        title={itemName}
        breadcrumbItems={[
          {
            text: location.state?.fromEnabledList ? `${t('ENABLED_MICROSERVICES.TITLE')}` : `${t('MARKETPLACE.TITLE')}`,
            key: 'microservices',
            onClick: () => {
              location.state?.fromEnabledList
                ? navigate('/microservices-enabled')
                : navigate('/microservices-marketplace')
            },
          },
          { text: itemName, key: 'current', isCurrentItem: true },
        ]}
      >
        <FeedbackMessageBar />

        {microservice.gracePeriod && <GracePeriodWarning limitTime={microservice.gracePeriod} />}

        <TabPanel
          pivotProps={{ styles: { root: { marginTop: '10px' } } }}
          tabs={[
            {
              title: t('DETAILS.OVERVIEW_TAB'),
              key: 'overview',
              component: <EnabledMicroserviceOverview microservice={microservice} />,
            },
            ...(microservice.permissions.includes(MicroserviceAction.VIEW_SCRIPT)
              ? [
                  {
                    title: t('DETAILS.SCRIPT_TAB'),
                    key: 'script',
                    component: <MicroserviceDetailsScript microservice={microservice} canEdit={false} />,
                  },
                ]
              : []),
            ...(microservice.active
              ? [
                  {
                    title: t('DETAILS.TARGET_GROUP_TAB'),
                    key: 'target-group',
                    component: (
                      <MicroserviceOrganizationTargetsTab
                        microservice={{
                          _id: microservice._id,
                          workspaceGroupExecution: microservice.executionSettings.workspaceGroupExecution,
                          individualExecution: microservice.executionSettings.individualExecution,
                          endUserExecutionWorkspaceGroups:
                            microservice.executionSettings.endUserExecutionWorkspaceGroups,
                        }}
                        canEdit={microservice.permissions.includes(MicroserviceAction.EDIT_ORGANIZATION_TARGETS)}
                      />
                    ),
                  },
                  {
                    title: t('DETAILS.SETTINGS_TAB'),
                    key: 'settings',
                    component: <EnabledMicroserviceSettingsTab microservice={microservice} />,
                  },
                ]
              : []),
            {
              title: t('DETAILS.LICENSE_TAB'),
              key: 'license',
              component: (
                <MicroserviceLicense microserviceId={microservice._id} license={microservice.license} canEdit={false} />
              ),
            },
          ]}
          onRenderItemLink={onRenderItemLink}
        />
      </PageBody>
      <UnsavedChangesDialog hidden={hideDialog} onDismiss={toggleHideDialog} callback={modalCallback} />
    </MicroserviceTabsContext.Provider>
  )
}

export default EnabledMicroservicePage

const TitleComponent = ({ title, titleTag }: { title: string; titleTag?: string }) => {
  const [isMobile] = useMediaQuery(MOBILE_QUERY)

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'start',
      }}
    >
      {isMobile ? <h3>{title}</h3> : <h1>{title}</h1>}
      {titleTag && (
        <div id="titleTagPage" className={`content-header__title-tag`}>
          {titleTag}
        </div>
      )}
    </div>
  )
}

const UnsavedChangesDialog = ({
  hidden,
  onDismiss,
  callback,
}: {
  hidden: boolean
  onDismiss?: () => void
  callback: () => void
}): JSX.Element => {
  const { t } = useTranslation('microservices')

  return (
    <Dialog
      title={t('DETAILS.UNSAVED_CHANGES_DIALOG.TITLE')}
      description={t('DETAILS.UNSAVED_CHANGES_DIALOG.DESCRIPTION')}
      actionButton={t('DETAILS.UNSAVED_CHANGES_DIALOG.BUTTON_ACCEPT')}
      dismissButton={t('DETAILS.UNSAVED_CHANGES_DIALOG.BUTTON_CANCEL')}
      hidden={hidden}
      onDismiss={onDismiss}
      callback={callback}
    >
      <MessageBar
        messageBarType={3}
        isMultiline={true}
        dismissButtonAriaLabel={t('general:CLOSE_LABEL')}
        className="mb-2"
        style={{ width: '100%' }}
      >
        {t('DETAILS.UNSAVED_CHANGES_DIALOG.WARNING')}
      </MessageBar>
    </Dialog>
  )
}

const GracePeriodWarning = ({ limitTime }: { limitTime: Date }) => {
  const { t } = useTranslation('microservices')

  return (
    <MessageBar
      messageBarType={MessageBarType.warning}
      isMultiline={false}
      dismissButtonAriaLabel="Close"
      style={{ width: '100%' }}
    >
      {t('DETAILS.GRACE_PERIOD', { date: dateFormatter(t, limitTime, DateFormat.DATETIME) })}
    </MessageBar>
  )
}
