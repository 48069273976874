import { Separator as FluentSeparator } from '@fluentui/react'
interface Props {
  text: string
}

export const Separator = ({ text }: Props) => {
  return (
    <>
      <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', marginTop: 24 }}>
        <hr style={{ width: 24 }} />
        <div style={{ whiteSpace: 'nowrap', marginLeft: 8, marginRight: 8, fontWeight: 'bold' }}>{text}</div>
        <hr style={{ width: '100%' }} />
      </div>
    </>
  )
}
