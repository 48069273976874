import { useMutation, useQueryClient } from '@tanstack/react-query'
import graphClient from '../../query-client/graphClient'
import { gql } from 'graphql-request'
import { ImportUsersInputType } from 'app/pages/organization-details/users/ImportUsersForm'

const importUsersByOrganizationQuery = gql`
  mutation ($organizationId: String!, $input: ImportUsersBaseInput!) {
    importUsersByOrganization(organizationId: $organizationId, input: $input) {
      organization {
        _id
      }
      createdSuccess {
        email
      }
      updatedSuccess {
        email
      }
      deletedSuccess {
        email
      }
      info {
        email
        message
        action
      }
      errors {
        email
        message
        action
      }
    }
  }
`

async function importUsersByOrganization(usersList: ImportUsersInputType, organizationId: string) {
  return graphClient
    .request(importUsersByOrganizationQuery, {
      organizationId,
      input: usersList,
    })
    .then((response) => response.importUsersByOrganization)
}

export default function (organizationId: string | undefined) {
  if (!organizationId) {
    return
  }
  const queryClient = useQueryClient()

  return useMutation(
    ['importUsersByOrganization'],
    (usersList: ImportUsersInputType) => importUsersByOrganization(usersList, organizationId),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['getBusinessById'])
      },
    }
  )
}

export const importActionsI18nMap: Record<string, string> = {
  Create: 'USERS.IMPORT_ACTIONS.CREATE',
  Update: 'USERS.IMPORT_ACTIONS.UPDATE',
  Delete: 'USERS.IMPORT_ACTIONS.DELETE',
}

export const errorsMessagesI18nMap: Record<string, string> = {
  'Own user can not be deleted': 'USERS.IMPORT_ERRORS.OWN_USER',
  'Email already exists.': 'USERS.IMPORT_ERRORS.EMAIL_EXISTS',
  'Invalid email.': 'USERS.IMPORT_ERRORS.INVALID_EMAIL',
  'User role not found.': 'USERS.IMPORT_ERRORS.ROLE_NOT_FOUND',
  'There are no changes.': 'USERS.IMPORT_ERRORS.NO_CHANGES',
  'You dont have permission.': 'USERS.IMPORT_ERRORS.UNAUTHORIZED',
  'User not found.': 'USERS.IMPORT_ERRORS.USER_NOT_FOUND',
}