import { FormActionsContainer } from '@flexxibleit/flexxible-ui'
import Col from 'app/components/Layouts/Col'
import Row from 'app/components/Layouts/Row'
import RowColLayout from 'app/components/Layouts/RowColLayout'
import FormActions from 'app/components/forms/FormActions'
import { useFormSidebarContext } from 'app/components/forms/FormSidebar/state/FormSidebarState'
import ControlledTextField from 'app/components/forms/TextField'
import { useGetMultilanguageFields } from 'app/hooks/microservices/useGetMultilanguageFields'
import { Languages, MultiLanguageField, getPreferredLanguage } from 'app/models/microservices'
import React, { ReactNode, useEffect, useState } from 'react'
import { FormProvider } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

interface MultilanguageFormFieldsProps {
  fields: MultiLanguageField[]
  updateField: (code: string, text: string) => void
  fieldName: string
  shouldFetchTranslations: boolean
  textForTranslation: string
  labelKey?: string
  placeholderKey?: string
  methods: any
}

interface LanguageField {
  code: string
  label: string
  placeholder: string
}

export const MultilanguageFormFields: React.FC<MultilanguageFormFieldsProps> = ({
  fields,
  updateField,
  fieldName,
  shouldFetchTranslations,
  textForTranslation,
  methods,
  labelKey,
  placeholderKey,
}) => {
  const { t, i18n } = useTranslation('microservices')
  const { closeSidebar } = useFormSidebarContext()
  const [refetch, setRefetch] = useState(shouldFetchTranslations)
  const preferredLanguage = getPreferredLanguage(i18n.language)
  const [translatedByIAState, setTranslatedByIAState] = useState<{ [code: string]: boolean }>({})

  const getTranslations: any = useGetMultilanguageFields(textForTranslation, preferredLanguage, fields, refetch)

  useEffect(() => {
    const initialState = fields.reduce((acc: any, field) => {
      acc[field.code] = field.translatedByIA || false
      return acc
    }, {})

    setTranslatedByIAState(initialState)
  }, [fields])

  useEffect(() => {
    if (getTranslations.data && getTranslations.data?.translations?.length > 0) {
      setRefetch(false)
      const translations: MultiLanguageField[] = getTranslations.data.translations
      translations.forEach((translation) => {
        const fieldIndex = fields.findIndex((field) => field.code === translation.code)
        if (fieldIndex !== -1) {
          methods.setValue(`${fieldName}[${fieldIndex}].text`, translation.text)
          methods.setValue(`${fieldName}[${fieldIndex}].translatedByIA`, translation.translatedByIA)
          setTranslatedByIAState((prevState) => ({
            ...prevState,
            [translation.code]: translation.translatedByIA,
          }))
        }
      })
    }
  }, [getTranslations.data, methods, fieldName, fields])

  const languageFields = Object.values(Languages).map((code) => {
    return {
      code: code,
      label: labelKey
        ? t(labelKey, {
            language: t(`general:LANGUAGE.${code.toUpperCase()}`).toLowerCase(),
          })
        : t(`DESIGNER.MICROSERVICE_FORM.${fieldName.toUpperCase()}_${code.toUpperCase()}_INPUT_LABEL`),
      placeholder: placeholderKey
        ? t(placeholderKey, {
            language: t(`general:LANGUAGE.${code.toUpperCase()}`).toLowerCase(),
          })
        : t(`DESIGNER.MICROSERVICE_FORM.${fieldName.toUpperCase()}_${code.toUpperCase()}_INPUT_PLACEHOLDER`),
    }
  })
  const preferredLangIndex = languageFields.findIndex((field) => field.code === preferredLanguage)
  if (preferredLangIndex > 0) {
    const preferredLangField = languageFields[preferredLangIndex]
    languageFields.splice(preferredLangIndex, 1)
    languageFields.unshift(preferredLangField)
  }

  const renderField = (field: LanguageField) => {
    const fieldIndex = fields.findIndex((f) => f.code === field.code)
    const newFieldName = `${fieldName}[${fieldIndex}].text`
    const showIcon = translatedByIAState[field.code] || false

    const handleFieldChange = (e: any) => {
      const textValue = e.target.value
      updateField(field.code, textValue)
      methods.setValue(newFieldName, textValue)
      methods.setValue(`${fieldName}[${fieldIndex}].translatedByIA`, false)
      setTranslatedByIAState((prevState) => ({
        ...prevState,
        [field.code]: false,
      }))
    }

    return (
      <Row key={field.code}>
        <Col className={'col-xs-12 col-lg-12'}>
          <RowColLayout rowProps={{ classNames: 'mt-2' }}>
            <ControlledTextField
              name={newFieldName}
              key={newFieldName}
              multiLangIcon={showIcon}
              translator={t}
              textFieldProps={{
                label: field.label,
                placeholder: field.placeholder,
                value: methods.watch(newFieldName),
                onChange: handleFieldChange,
              }}
            />
          </RowColLayout>
        </Col>
      </Row>
    )
  }

  const renderFormActionButtons = (): ReactNode => {
    return (
      <FormActionsContainer>
        <FormActions
          submitProps={{
            text: t('general:BUTTON.OK'),
            onClick: () => closeSidebar(),
          }}
        />
      </FormActionsContainer>
    )
  }

  return (
    <FormProvider {...methods}>
      <div>{languageFields.map(renderField)}</div>
      {renderFormActionButtons()}
    </FormProvider>
  )
}
