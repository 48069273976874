import { FieldTypes, FilterField } from '@flexxibleit/flexxible-ui'
import { useLocalStorageSearchParams } from 'app/hooks/utils/useLocalStorageSearchParams'
import { TFunction } from 'react-i18next'

type UseOrgTenantsFilters = {
  t: TFunction
}

export const useOrgTenantsFilters = ({ t }: UseOrgTenantsFilters) => {
  useLocalStorageSearchParams()

  const filterFields: FilterField[] = [
    {
      field: 'name',
      text: t('TENANTS.TABLE.NAME'),
      type: FieldTypes.STRING,
    },
    {
      field: 'product',
      text: t('TENANTS.TABLE.PRODUCT'),
      type: FieldTypes.STRING,
    },
    {
      field: 'policy',
      text: t('TENANTS.TABLE.POLICY'),
      type: FieldTypes.STRING,
    },
  ]

  return {
    filterFields,
  }
}
