import { useQuery } from '@tanstack/react-query'
import graphClient from 'app/query-client/graphClient'
import { WorkspaceGroupWithWorkspaceCount } from 'app/query-client/types'
import { gql } from 'graphql-request'

const workspaceGroupsByBusinessQuery = gql`
  query ($businessId: ID!) {
    getWorkspaceGroupsByBusiness(business_id: $businessId) {
      workspaceGroup {
        _id
        name
        manuallyAddedWorkspaces
        type
      }
      workspaceCount
    }
  }
`

function getWorkspaceGroupsByBusiness(businessId: string): Promise<any[]> {
  return graphClient.request(workspaceGroupsByBusinessQuery, { businessId }).then((response) => {
    return (response.getWorkspaceGroupsByBusiness as WorkspaceGroupWithWorkspaceCount[]).map(
      (workspaceGroupWithCounter) => {
        return {
          name: workspaceGroupWithCounter.workspaceGroup.name,
          type: workspaceGroupWithCounter.workspaceGroup.type,
          workspaceCount: workspaceGroupWithCounter.workspaceCount,
        }
      }
    )
  })
}

export default function (businessId: string) {
  return useQuery(['getWorkspaceGroupsByBusiness'], () => getWorkspaceGroupsByBusiness(businessId), {
    refetchOnWindowFocus: false,
    enabled: false,
  })
}
