import { gql } from 'graphql-request'
import { getPaginatedQueryParamsBlock, pageInfoBlock, paginatedVarsBlock } from '../../../../../config/pagination'

export const usePaginatedInstalledAppVersionListQuery = (): { queryName: string, query: string } => {
  const queryName = 'getInstalledAppVersions'
  const query = gql`
    query Request(
      $installedAppId: ID!
      ${getPaginatedQueryParamsBlock('InstalledAppVersionFiltersGraphql', 'InstalledAppVersionSortingGraphql')}
    ) {
      getInstalledAppVersions(
        installedAppId: $installedAppId
        ${paginatedVarsBlock}
      ) {
        data {
          version,
          reportedAt,
          discoveredAt,
          workspacesCount
        }
        ${pageInfoBlock}
      }
    }
  `

  return {
    queryName,
    query,
  }
}