import { PaginationArgs } from '@flexxibleit/flexxible-ui/dist/lib/components/table/Paginator'
import { useQuery } from '@tanstack/react-query'
import graphClient from 'app/query-client/graphClient'
import { gql } from 'graphql-request'

export interface FlexxBuildProcess {
  _id: string
  business: {
    _id: string
    name: string
  }
  flexxBuildProcessId: string
  processStatus: string
  processName: string
  error: string
  initiatorFeature: string
  created_at: string
  updated_at: string
}

interface Response {
  findAllFlexxBuildProcess: Array<FlexxBuildProcess>
}

const query = gql`
  query ($page: Int!, $perPage: Int!) {
    findAllFlexxBuildProcess(page: $page, perPage: $perPage) {
      data {
        _id
        business {
          _id
          name
        }
        flexxBuildProcessId
        processStatus
        processName
        error
        initiatorFeature
        created_at
        updated_at
      }
      pageInfo {
        hasNextPage
        hasPreviousPage
        totalPages
        currentPage
        total
      }
    }
  }
`

export async function findAllFlexxBuildProcess(pagination: PaginationArgs) {
  const { findAllFlexxBuildProcess } = await graphClient.request(query, pagination)

  return {
    results: findAllFlexxBuildProcess.data as FlexxBuildProcess[],
    pageInfo: findAllFlexxBuildProcess.pageInfo,
  }
}

export const useFindAllFlexxBuildProcess = (pagination: PaginationArgs) => {
  return useQuery(['flexxBuildProcess', pagination], () => findAllFlexxBuildProcess(pagination), {
    refetchOnWindowFocus: false,
    keepPreviousData: true,
  })
}
