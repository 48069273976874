import { useMutation, useQueryClient } from '@tanstack/react-query'
import graphClient from 'app/query-client/graphClient'
import { gql } from 'graphql-request'

interface DeleteReportingGroupRequest {
  id: string
  replaceWith: string
}

interface Response {
  deleteReportingGroup: boolean
}

const mutation = gql`
  mutation Request($id: ID!, $replaceWith: ID!) {
    deleteReportingGroup(id: $id, replaceWith: $replaceWith)
  }
`

const deleteReportingGroup = async (id: string, replaceWith: string) => {
  const response: Response = await graphClient.request(mutation, {
    id,
    replaceWith,
  })
  return response.deleteReportingGroup
}

export const useDeleteReportingGroup = () => {
  const queryClient = useQueryClient()

  return useMutation(
    ['deleteReportingGroup'],
    ({ id, replaceWith }: DeleteReportingGroupRequest) => deleteReportingGroup(id, replaceWith),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['getBusinessById'])
      },
    }
  )
}
