import { PaginatedTable, LoadingSpinner } from '@flexxibleit/flexxible-ui'
import { ReportingGroup, User } from 'app/query-client/types'
import { useTranslation } from 'react-i18next'
import usePaginatedData from 'app/hooks/usePaginatedData'
import { usePaginatedReportingGroupUsersQuery } from './usePaginatedReportingGroupUsersQuery'
import { useReportingGroupUsersBuildTable } from './useReportingGroupUsersBuildTable'
import { EmptyRoleUsers } from 'app/pages/organization-details/roles/tabs/users/EmptyRoleUsers'

interface Props {
  reportingGroup: ReportingGroup
  organizationId: string
}

export const ReportingGroupUsers = ({ reportingGroup, organizationId }: Props) => {
  const { t, i18n } = useTranslation('organization_details')
  const { query } = usePaginatedReportingGroupUsersQuery()

  const {
    data: paginatedUsers,
    isFetching,
    isLoading,
  } = usePaginatedData<User>(query, 'getPaginatedReportingGroupUsers', [], {
    organizationId: organizationId,
    reportingGroupId: reportingGroup._id,
  })

  // Actions and columns
  const { columns } = useReportingGroupUsersBuildTable({ t, i18n })

  return (
    <div style={{ display: 'flex', flexDirection: 'column', height: '76vh' }}>
      <div
        style={{
          padding: '16px',
          display: 'flex',
          flexDirection: 'column',
          overflow: 'hidden',
          height: '100%',
        }}
      >
        {isLoading ? (
          <LoadingSpinner></LoadingSpinner>
        ) : (
          <>
            <PaginatedTable
              tableId="reporting-group-users"
              locale={i18n.language}
              paginatedData={paginatedUsers}
              columns={columns}
              filters={{
                filterFields: [],
                smartSearch: true,
                advancedSearch: false,
              }}
              isFetching={isFetching}
              emptyInitialLoadContent={<EmptyRoleUsers {...{ t }} />}
            />
          </>
        )}
      </div>
    </div>
  )
}
