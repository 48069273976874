import { useQuery } from '@tanstack/react-query'
import { gql } from 'graphql-request'
import graphClient from '../../query-client/graphClient'

export interface ListOrganizationOperationsView {
  results: [
    {
      operationName: string
      operationType: string
      status: string
      module: string
      startedAt: Date
      endedAt: Date
      userFQDN: string
      userName: string
    }
  ]
  pageInfo: {
    hasNextPage: boolean
    hasPreviousPage: boolean
    totalPages: number
    currentPage: number
    total: number
  }
}

interface Response {
  listOrganizationOperations: ListOrganizationOperationsView
}

const listOrganizationOperationsHistoryQuery = gql`
  query ($input: ListOrganizationOperationsGraphqlRequest!) {
    listOrganizationOperations(input: $input) {
      results {
        operationName
        operationType
        status
        module
        startedAt
        endedAt
        userFQDN
        userName
      }
      pageInfo {
        hasNextPage
        hasPreviousPage
        totalPages
        currentPage
        total
      }
    }
  }
`

export async function listOrganizationOperationsHistory(
  organizationId: string,
  startDate: Date,
  endDate: Date,
  page: number | null,
  perPage: number | null
) {
  const response: Response = await graphClient.request(listOrganizationOperationsHistoryQuery, {
    input: {
      organizationId,
      startDate,
      endDate,
      page,
      perPage,
    },
  })
  return {
    results: response.listOrganizationOperations.results,
    pageInfo: response.listOrganizationOperations.pageInfo,
  }
}

export const useViewOrganizationOperationsListHistory = (
  organizationId: string,
  startDate: Date,
  endDate: Date,
  page: number = 1,
  perPage: number = 20
) => {
  return useQuery(
    ['listOperations', organizationId, startDate, endDate, page, perPage],
    () => listOrganizationOperationsHistory(organizationId, startDate, endDate, page, perPage),
    {
      refetchOnWindowFocus: false,
    }
  )
}
