import { SummaryRule } from '../../usePatchManagementRuleEngine'
import { PatchSummary } from '../../../entities/PatchSummary'
import { PatchSummaryRuleSentences, SummarySectionType, SummaryType } from '../../types'
import { MustHaveApprovedPatchesCard } from './MustHaveApprovedPatchesCard'

export const MustHaveApprovedPatchesRule: SummaryRule = {
  rule: PatchSummaryRuleSentences.MUST_HAVE_APPROVED_PATCHES,
  alertSummaryItem: {
    card: <MustHaveApprovedPatchesCard />,
    type: SummaryType.Alert,
    section: SummarySectionType.Patches,
  },
  infoSummaryItem: {
    type: SummaryType.Information,
    section: SummarySectionType.Patches,
    card: <MustHaveApprovedPatchesCard isInfo />,
  },
  passRule: (patchSummary: PatchSummary) => {
    const totalApprovedPatchCount = patchSummary.patchesBySeverityAndStatus.reduce((acc, patch) => {
      if (patch.status === 'APPROVED') {
        return acc + patch.value
      }
      return acc
    }, 0)

    if (totalApprovedPatchCount > 0) {
      return true
    }

    const totalPatchCount = patchSummary.patchesBySeverityAndStatus.reduce((acc, patch) => acc + patch.value, 0)
    const totalNotApprovedPatchCount = patchSummary.patchesBySeverityAndStatus.reduce((acc, patch) => {
      if (patch.status === 'APPROVED') {
        return acc
      }
      return acc + patch.value
    }, 0)

    return totalNotApprovedPatchCount !== totalPatchCount
  },
}
