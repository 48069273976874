import { useMutation, useQueryClient } from '@tanstack/react-query'
import { gql } from 'graphql-request'
import graphClient from '../../query-client/graphClient'

interface Response {
  cloneUserRole: {
    _id: string
  }
}
const cloneUserRoleQuery = gql`
  mutation Request($userRoleId: ID!, $organizationId: ID!) {
    cloneUserRole(userRoleId: $userRoleId, organizationId: $organizationId) {
      _id
    }
  }
`

const cloneUserRole = async (userRoleId: string, organizationId: string): Promise<Response> => {
  const response: Response = await graphClient.request(cloneUserRoleQuery, { userRoleId, organizationId })

  return response
}

export default function () {
  const queryClient = useQueryClient()

  return useMutation(
    ['cloneUserRole'],
    ({ userRoleId, organizationId }: { userRoleId: string; organizationId: string }) =>
      cloneUserRole(userRoleId, organizationId),
    {
      onSuccess: (id) => {
        queryClient.invalidateQueries(['getPaginatedUserRoles'])
      },
    }
  )
}
