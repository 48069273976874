import { PaginatedTable } from '@flexxibleit/flexxible-ui'
import { useTranslation } from 'react-i18next'
import { WorkspaceGroup } from '../../../query-client/types'
import { useWorkspaceGroupsBuildTable } from './useWorkspaceGroupsBuildTable'
import { useWorkspaceGroupsActions } from './useWorkspaceGroupsActions'
import { EmptyWorkspaceGroups } from './EmptyWorkspaceGroups'
import { useWorkspaceGroupsFilters } from './useWorkspaceGroupsFilters'
import { useWorkspaceGroupsPaginatedQuery } from './useWorkspaceGroupsPaginatedQuery'
import usePaginatedData from 'app/hooks/usePaginatedData'
import { useWorkspaceGroupsBuildExport } from './useWorkspaceGroupsBuildExport'
import { useTableExport } from 'app/hooks/utils/useTableExport'
import useWorkspaceGroups from './useWorkspaceGroups'

interface Props {
  organizationId: string
  detailPageUrlBase: string
}

export const WorkspaceGroupsFeature = ({ organizationId, detailPageUrlBase }: Props) => {

  const { t, i18n } = useTranslation('organization_details')

  // Request data
  const { filterFields } = useWorkspaceGroupsFilters(t)
  const { query, queryName } = useWorkspaceGroupsPaginatedQuery();
  const { data: paginatedWorkspaceGroups, isFetching } = usePaginatedData<WorkspaceGroup>(query, queryName, filterFields, { businessId: organizationId });

  // Export
  const { refetch: refetchExportWorkSpaceGroups } = useWorkspaceGroups(organizationId)
  const { userTableExportColumns } = useWorkspaceGroupsBuildExport(t)
  const { onExportClicked } = useTableExport({
    query: refetchExportWorkSpaceGroups,
    columns: userTableExportColumns,
    fileName: 'Workspace Groups',
  })

  // Actions and columns
  const { columns } = useWorkspaceGroupsBuildTable({ t, i18n, detailPageUrlBase })
  const { onAddWorkspaceGroupClicked, commandBarActions } = useWorkspaceGroupsActions({ t, organizationId, onExportClicked })

  return (
    <PaginatedTable
      tableId="workspace-groups"
      locale={i18n.language}
      paginatedData={paginatedWorkspaceGroups}
      filters={{
        filterFields,
        smartSearch: true,
        advancedSearch: true,
      }}
      emptyInitialLoadContent={<EmptyWorkspaceGroups {...{ t, onAddWorkspaceGroupClicked }} />}
      commands={commandBarActions}
      {...{ columns, isFetching }}
    />
  )
}


