import FlexxibleCard from 'app/components/FlexxibleCard/FlexxibleCard.component'
import { FlowDetail } from 'app/hooks/flows/useGetFlow'
import { FlowEditor } from './editor/FlowEditor'

interface Props {
  flow: FlowDetail
}

const cardProps = {
  style: { marginTop: 30 },
}

export const FlowFlow = ({ flow }: Props) => {
  return (
    <FlexxibleCard cardProps={cardProps} styleNames="col-md-12 d-flex d-flexCol d-flexJustifyBetween">
      <div style={{ height: '630px' }}>
        <FlowEditor flow={flow} />
      </div>
    </FlexxibleCard>
  )
}
