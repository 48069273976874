export const useOrgProductsBuildExport = () => {
  const productTableExportColumns = [
    {
      key: 'environment',
      header: 'Environment',
      width: 14,
    },
    {
      key: 'product_name',
      header: 'Product Type',
      width: 20,
    },
    {
      key: 'region',
      header: 'Region',
      width: 14,
    },
    {
      key: 'created_at_date',
      header: 'Creation date',
      width: 10,
      isDate: true,
    },
    {
      key: 'active',
      header: 'Status',
      width: 20,
    },
  ]

  return { productTableExportColumns }
}
