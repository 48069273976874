import {
  CreateButton,
  EmptyStatus,
  LoadingSpinner,
  MOBILE_QUERY,
  ViewDetailsActionButton,
  useMediaQuery,
} from '@flexxibleit/flexxible-ui'
import {
  ColumnActionsMode,
  DetailsListLayoutMode,
  IColumn,
  Icon,
  SelectionMode,
  Stack,
  TooltipHost,
} from '@fluentui/react'
import { AutomaticApproval, WindowsPatchPolicy } from 'app/query-client/types'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Table from 'app/components/Table/Table.component'
import useCreateForm from 'app/hooks/useCreateForm'
import {
  AutomaticApprovalClassification,
  AutomaticApprovalProducts,
  AutomaticPatchFormType,
  CreateAutomaticPatchForm,
} from '../components/CreateAutomaticPatchForm'
import useGetWindowsProducts, { WindowsProduct } from 'app/hooks/patchPolicies/useGetWindowsProducts'
import useGetWindowsPatchCategories from 'app/hooks/patchPolicies/useGetWindowsPatchCategories'
import { useFeatureRender } from '../../../../permissions/useFeatureRender'
import { FEATURE_NAMES } from '../../../../permissions/FeatureName.enum'
import { FeatureRender } from '../../../../permissions/FeatureRender'

interface WindowsPatchPolicyPatchesTabProps {
  windowsPatchPolicy: WindowsPatchPolicy
  isLoading: boolean
}

export const WindowsPatchPolicyAutomaticTab = ({
  windowsPatchPolicy,
  isLoading,
}: WindowsPatchPolicyPatchesTabProps) => {
  const { t, i18n } = useTranslation('patch_management')

  const { data: productsBase, isLoading: isLoadingProducts } = useGetWindowsProducts()
  const { data: categoriesBase, isLoading: isLoadingCategories } = useGetWindowsPatchCategories()
  const { checkFeature } = useFeatureRender()

  const [isMobile] = useMediaQuery(MOBILE_QUERY)
  const [items, setItems] = useState<AutomaticPatchFormType[]>([])

  useEffect(() => {
    const newItems: AutomaticPatchFormType[] = []

    if (!productsBase || !categoriesBase || !windowsPatchPolicy) {
      setItems([])
      return
    }

    const approvals: AutomaticApproval[] = windowsPatchPolicy?.automaticApprovals || []
    approvals.forEach((approval: AutomaticApproval, index: number) => {
      const classification = categoriesBase.find((c) => c.id === approval.classification)
      if (!classification) return
      const obj: AutomaticPatchFormType = {
        ...approval,
        classification,
        products:
          productsBase && approval.products.length > 0
            ? approval.products
                .map((pId) => productsBase.find((p) => p.id === pId))
                .filter((p): p is WindowsProduct => p !== undefined)
            : [],
        index,
      }
      newItems.push(obj)
    })
    setItems(newItems)
  }, [windowsPatchPolicy, productsBase, categoriesBase])

  const onCreate = useCreateForm(
    {
      title: t('WINDOWS_PATCH_POLICIES.AUTOMATIC.CREATE_FORM'),
      isFooterAtBottom: true,
    },
    <CreateAutomaticPatchForm windowsPatchPolicy={windowsPatchPolicy} />
  )

  const openEditPatchPolicyForm = (item: AutomaticPatchFormType) => {
    return useCreateForm(
      {
        title: t('WINDOWS_PATCH_POLICIES.AUTOMATIC.EDIT_FORM'),
        isFooterAtBottom: true,
      },
      <CreateAutomaticPatchForm windowsPatchPolicy={windowsPatchPolicy} automaticApproval={item} />
    )
  }

  const automaticApprovalColumns: IColumn[] = [
    {
      key: 'classification',
      name: t('PATCH_POLICIES.TABLE.CLASSIFICATION'),
      fieldName: 'classification',
      minWidth: 100,
      maxWidth: 200,
      isRowHeader: true,
      columnActionsMode: ColumnActionsMode.disabled,
      onRender: (item: AutomaticPatchFormType) => {
        return <span>{item?.classification?.title}</span>
      },
    },
    {
      key: 'products',
      name: t('PATCH_POLICIES.TABLE.PRODUCTS'),
      fieldName: 'products',
      minWidth: 100,
      flexGrow: 1,
      isRowHeader: true,
      columnActionsMode: ColumnActionsMode.disabled,
      onRender: (item: AutomaticPatchFormType) => {
        const productsTitles = item.products ? item.products.map((product) => product.title) : []
        const tooltipStyles = {
          root: {
            cursor: 'pointer',
            whiteSpace: 'pre-wrap',
          },
        }
        return (
          <TooltipHost content={productsTitles.join(',\n')} styles={tooltipStyles}>
            {productsTitles.join(', ')}
          </TooltipHost>
        )
      },
    },
    {
      key: 'deadlineAfterRelease',
      name: t('PATCH_POLICIES.TABLE.DEADLINE_AFTER_RELEASE'),
      fieldName: 'deadlineAfterRelease',
      minWidth: 50,
      maxWidth: 150,
      isRowHeader: true,
      columnActionsMode: ColumnActionsMode.disabled,
    },
    {
      key: 'actions',
      name: t('PATCH_POLICIES.TABLE.ACTIONS'),
      minWidth: 100,
      maxWidth: 150,
      isRowHeader: true,
      columnActionsMode: ColumnActionsMode.disabled,
      onRender: (item: AutomaticPatchFormType) => {
        return <ViewDetailsActionButton isMobile={isMobile} onClick={openEditPatchPolicyForm(item)} />
      },
    },
  ]

  return (
    <>
      <div>
        {isLoading || isLoadingProducts || isLoadingCategories ? (
          <LoadingSpinner />
        ) : !items?.length ? (
          <div style={{ marginTop: 100 }}>
            <EmptyStatus
              message={t('WINDOWS_PATCH_POLICIES.AUTOMATIC.EMPTY_MESSAGE')}
              img={<Icon iconName="EntitlementPolicy" style={{ fontSize: 68 }} />}
              {...(checkFeature(FEATURE_NAMES.PATCH_MANAGEMENT_CREATE) && {
                buttonProps: {
                  text: t('general:BUTTON.CREATE'),
                  iconProps: { iconName: 'Add' },
                  onClick: onCreate,
                },
              })}
            />
          </div>
        ) : (
          <>
            <Stack
              horizontal={!isMobile}
              horizontalAlign="space-between"
              tokens={{ padding: '20px 0 0 0' }}
              className="mb-sm-2"
            >
              <div></div>
              <FeatureRender feature={FEATURE_NAMES.PATCH_MANAGEMENT_CREATE}>
                <CreateButton locale={i18n.language} onClick={onCreate} />
              </FeatureRender>
            </Stack>
            <Table
              tableId="windows-patch-policies-automatic"
              isEmpty={items.length === 0}
              emptyMessage={t('general:EMPTY_FILTER')}
              tableProps={{
                items: items,
                columns: automaticApprovalColumns,
                compact: false,
                selectionMode: SelectionMode.none,
                layoutMode: DetailsListLayoutMode.justified,
                isHeaderVisible: true,
              }}
            />
          </>
        )}
      </div>
    </>
  )
}
