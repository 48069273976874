import { getPaginatedQueryParamsBlock, pageInfoBlock, paginatedVarsBlock } from 'app/config/pagination'
import { gql } from 'graphql-request'

export const usePaginatedReportingGroupUsersQuery = () => {
  const queryName = 'getPaginatedReportingGroupUsers'
  const query = gql`
query Request(
  $organizationId: ID!
  $reportingGroupId: ID!
  ${getPaginatedQueryParamsBlock('UserFilter', 'UserSorting')}
) {
  getPaginatedReportingGroupUsers(
    organizationId: $organizationId
    reportingGroupId: $reportingGroupId
    ${paginatedVarsBlock}
  ) {
    data {
      _id
      name
      surname
      email
      department
    }
    ${pageInfoBlock}
  }
}
`
  return {
    query,
    queryName,
  }
}
