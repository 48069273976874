import { useMutation, useQueryClient } from "@tanstack/react-query";
import { gql } from "graphql-request";
import graphClient from "../../query-client/graphClient";
import { ProductFormType } from "app/pages/organization-details/products/ProductForm";

const createProductConfigQuery = gql`
mutation Request($input: ProductConfigInput!) {
  createProductConfig(input: $input) {
    _id
    environment
    license
  }
}
`

function createProductConfig(productConfig: any) {
  return graphClient
    .request(createProductConfigQuery, {
      input: productConfig
    })
    .then(response => response)
}

export default function () {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (productConfig: ProductFormType) => createProductConfig(productConfig),
    onSuccess: () => {
      queryClient.invalidateQueries(['getBusinessById'])
      queryClient.invalidateQueries(['getOrganizationProducts'])
    },
  })
}
