import PageBody from '../../../components/PageBody/PageBody.component'
import Table from '../../../components/Table/Table.component'
import { Dropdown, Link, MessageBar } from '@fluentui/react'
import { useTranslation } from 'react-i18next'
import { Col, Row, RowColLayout } from '@flexxibleit/flexxible-ui'
import { SearchableDropdown } from '../../../components/SearchableDropdown/SearchableDropdown.component'
import { withFeatureRender } from '../../../permissions/withFeatureRender'
import { FEATURE_NAMES } from '../../../permissions/FeatureName.enum'

const PMWorkspaces = () => {
  const { t } = useTranslation('patch_management')

  const vulnerableWorkspacesMock: string | any[] = [
    {
      workspaceName: 'Workspace Alpha',
      reportingGroup: 'Group A',
      target: 'Target 1',
      os: 'Windows',
      lastUser: 'User 1',
      pendingPatches: 356,
      type: 'Physical device',
    },
    {
      workspaceName: 'Workspace Beta',
      reportingGroup: 'Group B',
      target: 'Target 2',
      os: 'Linux',
      lastUser: 'User 2',
      pendingPatches: 45,
      type: 'Virtual Desktop',
    },
    {
      workspaceName: 'Workspace Gamma',
      reportingGroup: 'Group C',
      target: 'Target 3',
      os: 'Windows',
      lastUser: 'User 3',
      pendingPatches: 21,
      type: 'Physical device',
    },
    {
      workspaceName: 'Workspace Delta',
      reportingGroup: 'Group D',
      target: 'Target 4',
      os: 'Linux',
      lastUser: 'User 4',
      pendingPatches: 14,
      type: 'Physical device',
    },
    {
      workspaceName: 'Workspace Epsilon',
      reportingGroup: 'Group E',
      target: 'Target 5',
      os: 'Windows',
      lastUser: 'User 5',
      pendingPatches: 1,
      type: 'Virtual Desktop',
    },
  ]

  const columns: any[] = [
    {
      key: 'workspaceName',
      name: 'Workspace',
      fieldName: 'workspaceName',
      minWidth: 60,
      maxWidth: 80,
      isRowHeader: true,
    },
    {
      key: 'lastUser',
      name: 'Last User',
      fieldName: 'lastUser',
      minWidth: 60,
      maxWidth: 80,
      isRowHeader: true,
    },
    {
      key: 'reportingGroup',
      name: 'Reporting Group',
      fieldName: 'reportingGroup',
      minWidth: 100,
      isRowHeader: true,
      onRender: (item: any) => {
        return (
          <Link
            onClick={() => {
              console.log('redirect')
            }}
          >
            {item.reportingGroup}
          </Link>
        )
      },
    },
    {
      key: 'target',
      name: 'Target',
      minWidth: 80,
      maxWidth: 100,
      isRowHeader: true,
      fieldName: 'target',
      onRender: (item: any) => {
        return (
          <Link
            onClick={() => {
              console.log('redirect')
            }}
          >
            {item.target}
          </Link>
        )
      },
    },
    {
      key: 'os',
      name: 'OS',
      minWidth: 200,
      maxWidth: 200,
      isRowHeader: true,
      fieldName: 'os',
    },
    {
      key: 'pendingPatches',
      name: 'Pending Patches',
      fieldName: 'pendingPatches',
      minWidth: 60,
      maxWidth: 80,
      isRowHeader: true,
    },
    {
      key: 'type',
      name: 'Workspace Type',
      minWidth: 200,
      maxWidth: 200,
      isRowHeader: true,
      fieldName: 'type',
    },
  ]

  return (
    <PageBody title={'Patch Management Workspaces'} isLoading={false}>
      <MessageBar messageBarType={0} isMultiline={true} className="mb-2" style={{ width: '100%' }}>
        {t('DASHBOARD.WORKSPACES.INFORMATION_BAR')}
      </MessageBar>
      <section style={{ marginBottom: 20 }}>
        <Row>
          <Col className="col-xs-12 col-lg-4">
            <RowColLayout rowProps={{ classNames: 'mt-2' }}>
              <Dropdown
                label={'Workspace Name'}
                placeholder={'Workspace Name'}
                options={[{ key: 'workspace1', text: 'Workspace 1' }]}
                onChange={() => {}}
                selectedKeys={['workspace1']}
                multiSelect
              />
            </RowColLayout>
          </Col>

          <Col className="col-xs-12 col-lg-4">
            <RowColLayout rowProps={{ classNames: 'mt-2' }}>
              <Dropdown
                label={'Last User'}
                placeholder={'Last User'}
                options={[{ key: 'user1', text: 'User 1' }]}
                onChange={() => {}}
                selectedKeys={['user1']}
                multiSelect
              />
            </RowColLayout>
          </Col>
          <Col className="col-xs-12 col-lg-4">
            <RowColLayout rowProps={{ classNames: 'mt-2' }}>
              <Dropdown
                label={'Reporting Group'}
                placeholder={'Reporting Group'}
                options={[
                  { key: 'groupE', text: 'Group E' },
                  { key: 'groupC', text: 'Group C' },
                ]}
                onChange={() => {}}
                selectedKeys={['groupE', 'groupC']}
                multiSelect
              />
            </RowColLayout>
          </Col>
        </Row>
      </section>
      <Table
        tableId="vulnerable-workspaces"
        isEmpty={vulnerableWorkspacesMock?.length === 0}
        emptyMessage={t('general:EMPTY_FILTER')}
        tableProps={{
          columns: columns,
          items: vulnerableWorkspacesMock,
          onShouldVirtualize: () => false,
        }}
        paginationProps={{
          pagination: {
            page: 1,
            perPage: 100,
          },
          onPaginationChange: () => {
            console.log('click')
          },
          paginatedData: {
            results: vulnerableWorkspacesMock,
            pageInfo: {
              hasNextPage: false,
              hasPreviousPage: false,
              totalPages: 1,
              currentPage: 1,
              total: 20,
            },
          },
          isFetching: false,
        }}
      />
    </PageBody>
  )
}

export const PMWorkspacesPage = withFeatureRender(PMWorkspaces, FEATURE_NAMES.PATCH_MANAGEMENT_READ)
