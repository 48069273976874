import { MOBILE_QUERY, TABLET_QUERY } from 'app/config/media-query'
import useMediaQuery from 'app/hooks/utils/useMediaQuery'
import React, { useEffect, useState } from 'react'
import { HeaderBar } from '../header-mobile/HeaderBar.component'
import { Sidebar } from '../sidebar/Sidebar.component'
import { useGettingStartedChecklist } from '../../user-journey/getting-started-checklist/useGettingStartedChecklist'
import { ReleaseBanner } from "../ReleaseBanner";

export const Navigation = () => {
  const { gettingStartedSidebar } = useGettingStartedChecklist()
  const [sidebarOpen, setSidebarOpen] = useState(false)
  const [isMobile] = useMediaQuery(MOBILE_QUERY)
  const [isTablet] = useMediaQuery(TABLET_QUERY)

  useEffect(() => {
    if (isMobile || isTablet) {
      setSidebarOpen(false)
    }
  }, [isMobile, isTablet])

  return (
    <>
      <HeaderBar onOpen={() => setSidebarOpen((prev) => !prev)} />
      <Sidebar isOpen={sidebarOpen} />
      {gettingStartedSidebar}
      {sidebarOpen && (
        <div
          style={{ width: '100vw', height: '100vh', position: 'absolute', zIndex: '1' }}
          onClick={() => setSidebarOpen(false)}
        />
      )}
      <ReleaseBanner/>
    </>
  )
}
