import { CancelButton } from '@flexxibleit/flexxible-ui'
import { PrimaryButton } from '@fluentui/react'
import { useBoolean } from '@fluentui/react-hooks'
import { CodeEditor } from 'app/components/CodeEditor/CodeEditor'
import { MarkdownEditor } from 'app/components/MarkdownEditor/MarkdownEditor'
import { LicenseType } from 'app/config/licenses'
import { useFeedbackMessage } from 'app/context/feedback-message/FeedbackMessageContext'
import { useCreateConditionMicroservice } from 'app/hooks/microservices/useCreateConditionMicroservice'
import { useCreateMicroservice } from 'app/hooks/microservices/useCreateMicroservice'
import { getPreferredLanguage } from 'app/models/microservices'
import { MicroserviceType } from 'app/models/microservices/MicroserviceType'
import { MicroserviceCreateLicense } from 'app/pages/microservices/create/MicroserviceCreateLicense'
import { MicroserviceCreatePrivacy } from 'app/pages/microservices/create/MicroserviceCreatePrivacy'
import i18next from 'i18next'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'
import Dialog from '../../../components/Dialog/Dialog'
import PageBody from '../../../components/PageBody/PageBody.component'
import ErrorStatus from '../../../components/status/error-status'
import history, { Transition } from '../../../routing/history'
import { MicroserviceForm, MicroserviceFormType } from '../components/MicroserviceForm'
import { MicroserviceConditionForm } from './MicroserviceConditionForm'
import { useOrganizationSelection } from 'app/globals/useOrganizationSelection'
import { withFeatureRender } from '../../../permissions/withFeatureRender'
import { FEATURE_NAMES } from '../../../permissions/FeatureName.enum'

interface Props {
  type: MicroserviceType
}

const MicroserviceCreation = ({ type }: Props) => {
  const navigate = useNavigate()
  const { state } = useLocation()
  const { t, i18n } = useTranslation('microservices')
  const [hideDialog, { toggle: toggleHideDialog }] = useBoolean(true)
  const [currentStep, setCurrentStep] = useState(0)
  const [microservice, setMicroservice] = useState<MicroserviceFormType>({ ...state?.microservice, type })
  const createMicroservice = useCreateMicroservice()
  const createConditionMicroservice = useCreateConditionMicroservice()
  const { setSuccessMessage, setErrorMessage } = useFeedbackMessage()
  const [isError, setIsError] = useState(false)
  const [block, setBlock] = useState(true)
  const [modalCallback, setModalCallback] = useState<() => void>(() => () => {
    toggleHideDialog()
    setBlock(false)
    setTimeout(() => navigate(-1), 0)
  })
  const { rolesOnBusiness } = useOrganizationSelection()

  useEffect(() => {
    if (!rolesOnBusiness?.isPortalAdmin()) {
      setMicroservice({ ...microservice, isPrivate: true, visibleTo: [] })
    }
  }, [rolesOnBusiness])

  useEffect(() => {
    if (!block) return

    const unblock = history.block((tx: Transition) => {
      if (!block) {
        unblock()
        tx.retry()
        return
      }

      setModalCallback(() => {
        return () => {
          unblock()
          tx.retry()
        }
      })

      toggleHideDialog()
    })

    return () => unblock()
  }, [history, block])

  useEffect(() => {
    setIsError(!!createMicroservice.isError)
  }, [createMicroservice.isError])

  const onCreate = (microservice: MicroserviceFormType) => {
    const preferredLanguage = getPreferredLanguage(i18next.language)
    const isCondition = type === MicroserviceType.CONDITION

    return (!isCondition ? createMicroservice : createConditionMicroservice)
      .mutateAsync({
        microservice: microservice!,
        defaultLang: preferredLanguage,
      })
      .then((response) => {
        setSuccessMessage(t('DESIGNER.MICROSERVICE_FORM.ADD_SUCCESS'))
        setBlock(false)
        setTimeout(() => navigate(`/microservices-designer/${response._id}`), 0)
      })
      .catch(() => {
        setErrorMessage(t('DESIGNER.MICROSERVICE_FORM.ADD_ERROR'))
      })
  }

  const renderExitFormConfirmation = (): JSX.Element => {
    return (
      <Dialog
        title={`${t('DESIGNER.CONFIRMATION_CANCEL_CREATION')}`}
        description={t('DESIGNER.CONFIRMATION_CANCEL_CREATION_MESSAGE')}
        actionButton={t('DESIGNER.BUTTON_ACCEPT')}
        dismissButton={t('DESIGNER.BUTTON_CANCEL')}
        hidden={hideDialog}
        onDismiss={toggleHideDialog}
        callback={() => {
          modalCallback()
          setModalCallback(() => () => {
            toggleHideDialog()
            setBlock(false)
            setTimeout(() => navigate(-1), 0)
          })
        }}
      />
    )
  }

  const renderResultPanel = () => {
    const error: any = createMicroservice.error
    return renderError(error?.response?.errors[0]?.message)
  }
  const renderError = (error?: string) => {
    let errorMessage = t('DESIGNER.MICROSERVICE_FORM.ADD_ERROR')
    if (error?.includes('Duplicated entity.')) {
      errorMessage = t('DESIGNER.MICROSERVICE_FORM.ADD_ERROR_DUPLICATED')
    } else if (error?.includes('Custom fields microservice already exists')) {
      errorMessage = t('DESIGNER.MICROSERVICE_FORM.ADD_ERROR_DUPLICATED_CUSTOM_FIELDS')
    }

    return (
      <PageBody title="" isLoading={false}>
        <ErrorStatus message={errorMessage} />
        <PrimaryButton
          text={t('general:BUTTON.KEEP_EDITING')}
          onClick={() => {
            createMicroservice.reset()
            setCurrentStep(0)
          }}
          iconProps={{ iconName: 'NavigateBack' }}
        />
      </PageBody>
    )
  }

  if (isError) {
    return renderResultPanel()
  }

  const steps = [
    {
      hideButtons: true,
      component: (
        <MicroserviceForm
          onCancel={toggleHideDialog}
          isCreate={true}
          editedData={microservice}
          onSubmit={(values) => {
            setMicroservice({ ...microservice, ...values })
            if (type === MicroserviceType.CUSTOM_FIELDS) {
              setMicroservice((prev) => ({ ...prev, isPrivate: true, visibleTo: [] }))
            }
            setCurrentStep(currentStep + 1)
          }}
        />
      ),
    },
    ...(type !== MicroserviceType.CUSTOM_FIELDS && rolesOnBusiness?.isPortalAdmin()
      ? [
          {
            title: t('DETAILS.PRIVACY_TAB'),
            hideButtons: true,
            component: (
              <MicroserviceCreatePrivacy
                initialData={{
                  isPrivate: microservice?.isPrivate ?? false,
                  visibleTo: microservice?.visibleTo ?? [],
                }}
                onCancel={toggleHideDialog}
                onSubmit={(values) => {
                  setMicroservice({ ...microservice!, ...values })
                  setCurrentStep(currentStep + 1)
                }}
              />
            ),
          },
        ]
      : []),
    {
      title: t('DETAILS.LICENSE_TAB'),
      hideButtons: true,
      component: (
        <MicroserviceCreateLicense
          initialData={{
            type:
              type === MicroserviceType.CONDITION
                ? LicenseType.PROPRIETARY
                : microservice?.licenseType ?? LicenseType.MIT,
            year: new Date().getFullYear(),
            holder: '[Organization name]',
          }}
          disabled={type === MicroserviceType.CONDITION}
          onCancel={toggleHideDialog}
          onSubmit={(values) => {
            setMicroservice({ ...microservice!, licenseType: values.license })
            setCurrentStep(currentStep + 1)
          }}
        />
      ),
    },
    {
      title: t('DESIGNER.MICROSERVICE_FORM.README_INPUT_LABEL'),
      component: (
        <MarkdownEditor
          value={microservice?.readme ?? undefined}
          readonly={false}
          onChange={(value) => setMicroservice({ ...microservice!, readme: value })}
        />
      ),
    },
    {
      title: t('DESIGNER.MICROSERVICE_FORM.SCRIPT_INPUT_LABEL'),
      component: (
        <CodeEditor
          language={microservice?.language ?? ''}
          styles={{ marginBottom: 20 }}
          value={microservice?.script ?? ''}
          readOnly={false}
          onChange={(value) => setMicroservice({ ...microservice!, script: value })}
        />
      ),
    },
    ...(type === MicroserviceType.CONDITION
      ? [
          {
            title: t('DETAILS.CONDITION_TAB'),
            hideButtons: true,
            component: (
              <MicroserviceConditionForm
                onCancel={toggleHideDialog}
                onSubmit={(values) => {
                  setMicroservice({ ...microservice!, ...values })
                  setTimeout(() => onCreate({ ...microservice!, ...values }), 0)
                }}
              />
            ),
          },
        ]
      : []),
  ]

  const titleAppend = steps[currentStep].title ? ' - ' + steps[currentStep].title : ''

  return (
    <PageBody
      title={
        t('DESIGNER.MICROSERVICES_CREATION_PAGE.TITLE', { step: currentStep + 1, total: steps.length }) + titleAppend
      }
      isLoading={false}
    >
      {renderExitFormConfirmation()}

      {steps[currentStep].component}

      {!steps[currentStep].hideButtons && (
        <div className="d-flex d-flexAlignItemsEnd d-flexJustifyEnd" style={{ gap: 20 }}>
          <CancelButton locale={i18n.language} onClick={toggleHideDialog} />
          {currentStep < steps.length - 1 ? (
            <PrimaryButton
              onClick={() => setCurrentStep(Math.min(currentStep + 1, steps.length - 1))}
              text={t('general:PAGINATION.NEXT')}
              iconProps={{ iconName: 'Next' }}
            />
          ) : (
            <PrimaryButton
              onClick={() => onCreate(microservice)}
              text={t('general:BUTTON.SAVE')}
              iconProps={{ iconName: 'Save' }}
            />
          )}
        </div>
      )}
    </PageBody>
  )
}

export const MicroserviceCreationPage = withFeatureRender(MicroserviceCreation, FEATURE_NAMES.MICROSERVICES_CREATE)
