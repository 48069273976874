import { RowColLayout } from '@flexxibleit/flexxible-ui'
import { TooltipInfo } from 'app/components/Tooltip/TooltipInfo'
import { WrapOverwriteAgentSettingsForm } from './WrapOverwriteAgentSettingsForm'
import Controlled from 'app/components/forms/Controlled'
import { ITag, Label, TagPicker } from '@fluentui/react'
import { SEPARATOR_AGENT_SETTINGS_EVENT_LOG_IDS } from '../../../../models/agentSettings/separatorEventLogsIds'

interface Props {
  overridable?: boolean
  name: string
  label: string
  tooltip: string
  isOverwrite?: boolean
  disabled?: boolean
  placeholder: string
  callbackOverwrite?: () => void
}

export const sanitizeDataForAgentSettingsTagPicker = (value: string | undefined) => {
  if (value === undefined || value === null) {
    return undefined
  }
  if (value === '') {
    return []
  }
  return value.split(SEPARATOR_AGENT_SETTINGS_EVENT_LOG_IDS)
}
const AgentSettingsTagPicker = ({
  placeholder,
  overridable = false,
  disabled = false,
  name,
  label,
  tooltip,
  isOverwrite = true,
  callbackOverwrite,
}: Props) => {
  const child = (
    <RowColLayout rowProps={{ classNames: 'mt-2' }}>
      <Controlled
        name={name}
        render={({ field }) => (
          <div className="col">
            <div className="row-12">
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'flex-start',
                  alignItems: 'end',
                }}
              >
                <Label required={false} disabled={(overridable && !isOverwrite) || disabled}>
                  {label}
                </Label>
                {tooltip && <TooltipInfo text={tooltip} />}
              </div>
            </div>
            <div className="row-12">
              <TagPicker
                disabled={(overridable && !isOverwrite) || disabled}
                selectedItems={field.value ? field.value.map((item: ITag) => ({ key: item, name: item })) : []}
                onChange={(items) => {
                  const selection = (items ?? []).map((item) => item.key.toString())
                  const isAddingNewItem = selection.length >= 1 && selection.length > field.value.length

                  if (!isAddingNewItem) {
                    return field.onChange(selection)
                  }

                  const addedItem = selection[selection.length - 1]
                  const normalizedItems =
                    addedItem.indexOf(SEPARATOR_AGENT_SETTINGS_EVENT_LOG_IDS) > -1
                      ? addedItem.split(SEPARATOR_AGENT_SETTINGS_EVENT_LOG_IDS)
                      : [addedItem]
                  const itemsWithoutDuplicates = Array.from(new Set<string>([...field.value, ...normalizedItems]))

                  field.onChange(itemsWithoutDuplicates)
                }}
                inputProps={{
                  placeholder: field.value?.length > 0 ? '' : placeholder,
                }}
                styles={{
                  root: { backgroundColor: 'white' },
                }}
                onResolveSuggestions={function (filter: string, _?: ITag[] | undefined): ITag[] | PromiseLike<ITag[]> {
                  return [
                    {
                      key: filter,
                      name: filter,
                    },
                  ]
                }}
                onRenderSuggestionsItem={(_) => {
                  return <> </>
                }}
              />
            </div>
          </div>
        )}
      />
    </RowColLayout>
  )
  return overridable ? (
    <WrapOverwriteAgentSettingsForm
      onClick={() => callbackOverwrite && callbackOverwrite()}
      overWritable={!isOverwrite}
      disabled={disabled}
    >
      {child}
    </WrapOverwriteAgentSettingsForm>
  ) : (
    child
  )
}

export default AgentSettingsTagPicker
