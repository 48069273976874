import { ILabelProps, Label } from '@fluentui/react'
import FlexxibleCard from 'app/components/FlexxibleCard/FlexxibleCard.component'
import IconLanguage from 'app/components/IconLanguage/IconLanguage'
import { MOBILE_QUERY, TABLET_QUERY } from 'app/config/media-query'
import useMediaQuery from 'app/hooks/utils/useMediaQuery'
import './summaryCard.scss'

interface SummaryCardProps {
  Icon: any
  title: string
  text: string
  showMultiLangIcon?: boolean
}

const labelTitleProperties: ILabelProps = {
  styles: {
    root: {
      fontWeight: 400,
      fontSize: 13,
      color: '#8e8e93',
    },
  },
}

const SummaryCard = ({ Icon, title, text, showMultiLangIcon = false }: SummaryCardProps) => {
  const [isTablet] = useMediaQuery(TABLET_QUERY)
  const [isMobile] = useMediaQuery(MOBILE_QUERY)

  const cardProps = {
    style: isMobile ? { width: 'calc(46% - 35px)' } : isTablet ? { width: 'calc(48% - 30px)' } : {},
  }

  return (
    <FlexxibleCard cardProps={cardProps}>
      <div className="summary-card">
        {!isMobile && <img src={Icon} className="icon" alt={title} />}
        <div className="d-flex d-flexCol d-flexAlignItemsStart d-flexJustifyCenter">
          <Label {...labelTitleProperties}> {title} </Label>
          <span className="card-body">
            {text} {showMultiLangIcon && <IconLanguage tam="16" />}
          </span>
        </div>
      </div>
    </FlexxibleCard>
  )
}

export default SummaryCard
