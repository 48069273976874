import { MockChartConfiguration } from './chart-configutations/MockChartConfiguration';
import HighchartsReact from 'highcharts-react-official'
import Highcharts from 'highcharts/highstock'
import { DateFormat, dateFormatter } from '../../services/date-formatter'
import { TFunction } from 'i18next'

interface Props {
  categories: any[],
  stacking?: string,
  t:  TFunction,
  series: any[]
}

export const TimelineColumnChart = ({categories, stacking, series, t}: Props) => {

  return (
    <div style={{ marginTop: '16px', padding: '14px' }}>
      <HighchartsReact
        highcharts={Highcharts}
        allowChartUpdate={true}
        options={{
          ...MockChartConfiguration,
          chart: {
            type: 'column',
            width: null
          },
          title: {
            text: '',
          },
          xAxis: {
            categories: categories
          },
          yAxis: {
            title: undefined,
            allowDecimals: false,
            min: 0,
          },
          legend: {
            enabled: false,
          },
          series: series,
          plotOptions: {
            series: {
              keys: ['y', 'custom.date'],
            },
            column: {
              stacking: stacking,
              groupPadding: 0,
            },
          },
          tooltip: {
            useHTML: true,
            borderWidth: 0,
            shadow: false,
            format: () => {
              const currentPoint: any = this
              const date = new Date(Number(currentPoint.point.options.custom.date))
              return (
                '<div style="display: flex, flex-direction: column">' +
                '<div style="color: white">' +
                dateFormatter(t, date, DateFormat.Medium) +
                '</div>' +
                '<div style="color: white">' +
                ' Test' +
                '<b style="color: white"> ' +
                currentPoint.y +
                '</b> </div>' +
                '</div>'
              )
            },
          },
        }}
      />
    </div>
  )
}

