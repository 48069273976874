import { useQuery } from '@tanstack/react-query'
import { MultiLanguageField } from 'app/models/microservices'
import { MicroserviceAction } from 'app/models/microservices/MicroserviceAction'
import { MicroserviceContext } from 'app/models/microservices/MicroserviceContext'
import { MicroserviceLanguage } from 'app/models/microservices/MicroserviceLanguage'
import { MicroserviceOperatingSystem } from 'app/models/microservices/MicroserviceOperatingSystem'
import { MicroserviceScope } from 'app/models/microservices/MicroserviceScope'
import { MicroserviceTargetGroup } from 'app/models/microservices/MicroserviceTargetGroup'
import graphClient from 'app/query-client/graphClient'
import { gql } from 'graphql-request'

export interface EnabledMicroserviceView {
  _id: string
  created_at: string
  name: MultiLanguageField[]
  description: MultiLanguageField[]
  iconName: string
  iconBackground: string
  readme: string
  script?: string
  author: {
    name: string
    surname: string
  }
  operatingSystem: MicroserviceOperatingSystem[]
  language: MicroserviceLanguage
  version: number
  scope: MicroserviceScope[]
  context?: MicroserviceContext
  category: MultiLanguageField[] | null
  efficiency: number
  active: boolean
  permissions: MicroserviceAction[]
  license: {
    type: string
    year: number
    holder: string
  }
  executionSettings: {
    workspaceGroupExecution: MicroserviceTargetGroup
    individualExecution: MicroserviceTargetGroup
    endUserExecutionWorkspaceGroups: string[]
  }
  gracePeriod: Date | null
}

interface Response {
  getEnabledMicroserviceById: EnabledMicroserviceView
}

const query = gql`
  query ($id: ID!, $businessId: ID!) {
    getEnabledMicroserviceById(id: $id, businessId: $businessId) {
      _id
      name {
        code
        text
        translatedByIA
      }
      description {
        code
        text
        translatedByIA
      }
      iconName
      iconBackground
      language
      operatingSystem
      version
      scope
      context
      category {
        code
        text
        translatedByIA
      }
      efficiency
      created_at

      readme
      active

      script(id: $id, businessId: $businessId)

      permissions
      license {
        type
        year
        holder
      }

      author {
        _id
        name
        surname
      }

      executionSettings {
        workspaceGroupExecution
        individualExecution
        endUserExecutionWorkspaceGroups
      }

      gracePeriod
    }
  }
`

const getEnabledMicroserviceById = async (id: string, businessId: string) => {
  const { getEnabledMicroserviceById } = await graphClient.request<Response>(query, { id, businessId })

  return getEnabledMicroserviceById
}

export const useGetEnabledMicroserviceById = (id: string, businessId: string) => {
  return useQuery(['enabled-microservice', id, businessId], () => getEnabledMicroserviceById(id, businessId))
}
