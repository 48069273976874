import { Col, Row, RowColLayout } from '@flexxibleit/flexxible-ui'
import { PrimaryButton } from '@fluentui/react'
import { useBoolean } from '@fluentui/react-hooks'
import { zodResolver } from '@hookform/resolvers/zod'
import Dialog from 'app/components/Dialog/Dialog'
import CheckBox from 'app/components/forms/CheckBox'
import Dropdown from 'app/components/forms/Dropdown'
import FormActions from 'app/components/forms/FormActions'
import MultiLangTextField from 'app/components/forms/MultiLangTextField'
import { ControlledSlider } from 'app/components/forms/Slider'
import ErrorStatus from 'app/components/status/error-status'
import { useFeedbackMessage } from 'app/context/feedback-message/FeedbackMessageContext'
import { FlowDetail } from 'app/hooks/flows/useGetFlow'
import { useUpdateFlow } from 'app/hooks/flows/useUpdateFlow'
import { Languages, MultiLanguageField } from 'app/models/microservices'
import { secondsToLabel } from 'app/models/flows/FlowCooldown'
import { flowTypeOptions } from 'app/models/flows/FlowType'
import { useEffect, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import * as z from 'zod'

interface FormData {
  name: MultiLanguageField[]
  description: MultiLanguageField[]
  detectionOnly: boolean
  cooldown: number
}

const multiLanguageFieldSchema = z.object({
  code: z.nativeEnum(Languages),
  text: z.string(),
  translatedByIA: z.boolean(),
})

const schema: z.ZodType<Partial<FormData>> = z.object({
  name: z.array(multiLanguageFieldSchema).refine((array) => array.some((field) => field.text.trim().length > 0), {
    message: 'at-least-one-text-field-must-be-non-empty',
  }),
  description: z
    .array(multiLanguageFieldSchema)
    .refine((array) => array.some((field) => field.text.trim().length > 0), {
      message: 'at-least-one-text-field-must-be-non-empty',
    }),
  detectionOnly: z.boolean(),
  cooldown: z
    .custom<number>()
    .refine((value) => !isNaN(value), 'required')
    .refine((value) => Number(value) >= 0, 'min')
    .transform((value) => Number(value)),
})

interface Props {
  flow: FlowDetail
}
export const FlowOverviewForm = ({ flow }: Props) => {
  const navigate = useNavigate()
  const { t, i18n } = useTranslation('flows')
  const { mutateAsync, isError, error } = useUpdateFlow()
  const { setSuccessMessage } = useFeedbackMessage()
  const [showError, setShowError] = useState(false)
  const [hideDialog, { toggle: toggleHideDialog }] = useBoolean(true)

  useEffect(() => {
    setShowError(!!isError)
  }, [isError])

  const methods = useForm<FormData>({
    resolver: zodResolver(schema),
    defaultValues: {
      name: flow.name,
      description: flow.description,
      detectionOnly: flow.detectionOnly,
      cooldown: flow.cooldown,
    },
  })

  const onSubmit = (data: FormData) => {
    mutateAsync({
      id: flow.id,
      input: {
        name: data.name,
        description: data.description,
        detectionOnly: data.detectionOnly,
        cooldown: data.cooldown,
        language: i18n.language,
      },
    })
      .then(() => {
        setSuccessMessage(t('FORM.ADD_SUCCESS'))
        navigate(`/flows/${flow.id}`)
      })
      .catch((error) => {
        console.error('Error creating flow', error)
      })
  }

  const renderExitFormConfirmation = (): JSX.Element => {
    return (
      <Dialog
        title={`${t('DIALOG.CONFIRMATION_CANCEL_UPDATE')}`}
        description={t('DIALOG.CONFIRMATION_CANCEL_UPDATE_MESSAGE')}
        actionButton={t('general:BUTTON.OK')}
        dismissButton={t('general:BUTTON.CANCEL')}
        hidden={hideDialog}
        onDismiss={toggleHideDialog}
        callback={() => {
          navigate(-1)
        }}
      />
    )
  }

  if (showError) {
    const errorMessage = (error as any)?.response?.errors[0]?.message

    return (
      <>
        <ErrorStatus message={t('ERRORS.UPDATE', { message: errorMessage })} />
        <PrimaryButton
          text={t('general:BUTTON.KEEP_EDITING')}
          onClick={() => {
            setShowError(false)
          }}
          iconProps={{ iconName: 'NavigateBack' }}
        />
      </>
    )
  }

  const isDirty = methods.formState.isDirty

  return (
    <>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <RowColLayout rowProps={{ classNames: 'mt-2' }}>
            <MultiLangTextField
              name="name"
              required
              labelKey="flows:FORM.NAME"
              placeholderKey="flows:FORM.NAME_PLACEHOLDER"
              translator={t}
            />
          </RowColLayout>
          <RowColLayout rowProps={{ classNames: 'mt-2' }}>
            <MultiLangTextField
              name="description"
              required
              labelKey="flows:FORM.DESCRIPTION"
              placeholderKey="flows:FORM.DESCRIPTION_PLACEHOLDER"
              translator={t}
            />
          </RowColLayout>
          <Row>
            <Col className="col-xs-12 col-lg-6 mt-2">
              <Dropdown
                name="type"
                required
                translator={t}
                dropdownProps={{
                  disabled: true,
                  label: t('FORM.TYPE'),
                  placeholder: t('FORM.TYPE_PLACEHOLDER'),
                  options: flowTypeOptions(t),
                  selectedKey: flow.type,
                }}
              />
            </Col>
            <Col className="col-xs-12 col-lg-6 mt-2">
              <ControlledSlider
                label={t('FORM.COOLDOWN')}
                name="cooldown"
                required
                sliderProps={{
                  min: 0,
                  max: 3600,
                  step: 60,
                  showValue: true,
                  styles: {
                    valueLabel: { minWidth: 80 },
                  },
                  valueFormat: (value) => secondsToLabel(t, value),
                }}
              />
            </Col>
          </Row>
          <RowColLayout rowProps={{ classNames: 'mt-2' }}>
            <CheckBox
              name="detectionOnly"
              checkboxProps={{
                label: t('FORM.DETECTIONONLY'),
              }}
            />
          </RowColLayout>

          <div className="d-flex d-flexAlignItemsEnd d-flexJustifyEnd" style={{ gap: 20, marginTop: 100 }}>
            <FormActions
              cancelProps={{
                onClick: () => {
                  if (isDirty) {
                    toggleHideDialog()
                  } else {
                    navigate(-1)
                  }
                },
              }}
              submitProps={{
                text: t('general:BUTTON.SAVE'),
                iconProps: { iconName: 'Save' },
              }}
            />
          </div>
        </form>
      </FormProvider>
      {renderExitFormConfirmation()}
    </>
  )
}
