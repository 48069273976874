export class PathChecker {
  public static isAllowedPath(
    pathToCheck: string,
    allowedPaths: string[]
  ): boolean {
    const pathParamsToCheck = pathToCheck.split('/').filter(Boolean)

    return allowedPaths.some((allowedPath) => {
      const allowedPathParams = allowedPath.split('/').filter(Boolean)

      if (allowedPathParams.length !== pathParamsToCheck.length) {
        return false
      }

      return allowedPathParams.every((allowedPathParam: string, i: number) => {
        if (allowedPathParam.includes(':')) {
          return true
        }

        return pathParamsToCheck[i] === allowedPathParam
      })
    })
  }
}
