import { LoadingSpinner, PaginatedTable } from '@flexxibleit/flexxible-ui'
import { TablePageBody } from '../../components/table-page-body/TablePageBody'
import { useViewUserConnectionsListPaginatedQuery } from './useViewUserConnectionsListPaginatedQuery'
import usePaginatedData from '../../hooks/usePaginatedData'
import { useUserLoginsBuildTable } from './useUserLoginsBuildTable'
import { useUserLoginsBuildExport } from './useUserLoginsBuildExport'
import useViewUserConnectionsList from '../../hooks/connection/useViewUserConnectionsList'
import { useTableExport } from '../../hooks/utils/useTableExport'
import { useUserLoginsActions } from './useUserLoginsActions'
import { useTranslation } from 'react-i18next'
import { useMe } from 'app/hooks/me/useMe'

export type ConnectionListViewItem = {
  id: string
  ip: string
  userAgent: string
  application: string
  createdAt: string
}

export function UserLoginsPage() {
  const { t, i18n } = useTranslation('user_logins')
  const { data: user, isLoading } = useMe()

  // Request data
  const graphQlQuery = useViewUserConnectionsListPaginatedQuery()
  const { data: paginatedConnections, isFetching } = usePaginatedData<ConnectionListViewItem>(
    graphQlQuery,
    'viewUserConnectionsListPaginated'
  )

  // Export
  const { exportColumns } = useUserLoginsBuildExport()
  const { refetch: refetchUserLogins } = useViewUserConnectionsList()
  const { onExportClicked } = useTableExport({
    query: refetchUserLogins,
    columns: exportColumns,
    fileName: 'User Logins',
  })

  // Actions and columns
  const { commandBarActions } = useUserLoginsActions({ t, onExportClicked })
  const { columns } = useUserLoginsBuildTable({ t })

  if (!user) {
    return <LoadingSpinner />
  }

  return (
    <TablePageBody title={t('TITLE')} titleTag="PREVIEW" isLoading={false}>
      <PaginatedTable
        tableId="logins"
        locale={i18n.language}
        paginatedData={paginatedConnections}
        columns={columns}
        isFetching={isFetching}
        commands={commandBarActions}
        filters={{
          filterFields: [],
          smartSearch: false,
          advancedSearch: false,
        }}
        emptyInitialLoadContent={<LoadingSpinner />}
      />
    </TablePageBody>
  )
}
