import { FormActionsContainer, LoadingSpinner } from '@flexxibleit/flexxible-ui'
import { zodResolver } from '@hookform/resolvers/zod'
import * as z from 'zod'
import { FormProvider, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { IDropdownOption } from '@fluentui/react'
import RowColLayout from 'app/components/Layouts/RowColLayout'
import Dropdown from 'app/components/forms/Dropdown'
import FormActions from 'app/components/forms/FormActions'
import SuccessStatus from 'app/components/status/success-status'
import ErrorStatus from 'app/components/status/error-status'
import { ProductConfig } from 'app/query-client/types'
import { useEffect, useState } from 'react'
import { RemoteSupport, remoteSupportOptions } from 'app/models/reporting-groups/RemoteSupport'
import { ProductEnvTypes } from 'app/config/productEnv.enum'
import useEditProductSettings from 'app/hooks/productConfig/useEditProductSettings'
import { Separator } from 'app/components/separator'
import { anyDeskActionsOptions } from 'app/models/agentSettings/anyDesk'
import AgentSettingsToggleButton from '../components/agent-settings/AgentSettingsToggleButton'
import AgentSettingsDropdown from '../components/agent-settings/AgentSettingsDropdown'
import AgentSettingsSlider, {
  calculateSliderValueFromStepRealValue,
  calculateStepRealValueFromSliderValue,
} from '../components/agent-settings/AgentSettingsSlider'
import { useFeatureRender } from 'app/permissions/useFeatureRender'
import { FEATURE_NAMES } from 'app/permissions/FeatureName.enum'
import { useMinutesToLabel } from 'app/hooks/utils/useMinutesToLabel'
import AgentSettingsTagPicker, {
  sanitizeDataForAgentSettingsTagPicker,
} from '../components/agent-settings/AgentSettingsTagPicker'
import { SEPARATOR_AGENT_SETTINGS_EVENT_LOG_IDS } from '../../../models/agentSettings/separatorEventLogsIds'

interface Props {
  organizationId?: string
  productConfig?: ProductConfig
}

export interface ProductFormType {
  autoUpdateAgents: boolean
  resourcesReportRecurrence: number
  profileStorageReportRecurrence?: number
  syncBrokerRecurrence?: number
  detectNewCitrixSubscriptions?: boolean
  collectEventLogs: boolean
  eventLogIds?: string[]
  eventLogsRecurrence?: number
  collectDisks: boolean
  collectServices: boolean
  collectPnPEvents: boolean
  collectPublicIP: boolean
  anyDeskSystemActionsRole: string
}

const schema: z.ZodType<Partial<ProductFormType>> = z.object({
  autoUpdateAgents: z.boolean(),
  resourcesReportRecurrence: z
    .custom<number>()
    .refine((value) => !isNaN(value), 'required')
    .refine((value) => Number(value) >= 1, 'min')
    .refine((value) => Number(value) <= 60, 'max')
    .transform((value) => Number(value)),
  profileStorageReportRecurrence: z
    .custom<number>()
    .refine((value) => !isNaN(value), 'required')
    .refine((value) => Number(value) >= 15, 'min')
    .refine((value) => Number(value) <= 480, 'max')
    .transform((value) => Number(value))
    .optional(),
  syncBrokerRecurrence: z
    .custom<number>()
    .refine((value) => !isNaN(value), 'required')
    .refine((value) => Number(value) >= 1, 'min')
    .refine((value) => Number(value) <= 480, 'max')
    .transform((value) => Number(value))
    .optional(),
  detectNewCitrixSubscriptions: z.boolean().optional(),
  collectEventLogs: z.boolean(),
  eventLogIds: z.array(z.string()).optional(),
  eventLogsRecurrence: z
    .custom<number>()
    .refine((value) => !isNaN(value), 'required')
    .refine((value) => Number(value) >= 1, 'min')
    .refine((value) => Number(value) <= 240, 'max')
    .transform((value) => Number(value))
    .optional(),
  collectDisks: z.boolean(),
  collectServices: z.boolean(),
  collectPnPEvents: z.boolean(),
  collectPublicIP: z.boolean(),
  anyDeskSystemActionsRole: z.string().optional(),
  remoteSupport: z.string(),
})

const ProductAgentSettingsForm = ({ productConfig, organizationId }: Props) => {
  const { t } = useTranslation('organization_details')

  const editProductSettings = useEditProductSettings(productConfig?._id || '')

  const [isSuccess, setIsSuccess] = useState(false)
  const [isError, setIsError] = useState(false)
  const [isLoading, setIsLoading] = useState(true)

  const { checkFeature } = useFeatureRender()
  const readOnly = !checkFeature(FEATURE_NAMES.ENVIRONMENT_AGENT_SETTINGS_UPDATE)

  const dropdownOptions: IDropdownOption[] = [
    {
      key: productConfig?.product._id || '',
      text: productConfig?.product.name ? `${productConfig?.environment} (${productConfig?.product.name})` : '',
    },
  ]

  useEffect(() => {
    setIsSuccess(editProductSettings.isSuccess)
    setIsError(editProductSettings.isError)
    setIsLoading(editProductSettings.isLoading)
  }, [editProductSettings])

  const defaultValues = () => {
    return {
      autoUpdateAgents: productConfig?.agentSettings?.autoUpdateAgents ?? true,
      resourcesReportRecurrence: calculateSliderValueFromStepRealValue(
        productConfig?.agentSettings?.resourcesReportRecurrence ?? 5
      ),
      profileStorageReportRecurrence: calculateSliderValueFromStepRealValue(
        productConfig?.agentSettings?.profileStorageReportRecurrence ?? 240
      ),
      syncBrokerRecurrence: calculateSliderValueFromStepRealValue(
        productConfig?.agentSettings?.syncBrokerRecurrence ?? 1
      ),
      detectNewCitrixSubscriptions: productConfig?.agentSettings?.detectNewCitrixSubscriptions ?? false,
      collectEventLogs: productConfig?.agentSettings?.collectEventLogs ?? true,
      eventLogIds: sanitizeDataForAgentSettingsTagPicker(productConfig?.agentSettings?.eventLogIds) ?? [],
      eventLogsRecurrence: calculateSliderValueFromStepRealValue(
        productConfig?.agentSettings?.eventLogsRecurrence ?? 30
      ),
      collectDisks: productConfig?.agentSettings?.collectDisks ?? true,
      collectServices: productConfig?.agentSettings?.collectServices ?? true,
      collectPnPEvents: productConfig?.agentSettings?.collectPnPEvents ?? false,
      collectPublicIP: productConfig?.agentSettings?.collectPublicIP ?? false,
      remoteSupport: productConfig?.agentSettings?.remoteSupport ?? RemoteSupport.UNASSISTED,
      anyDeskSystemActionsRole: productConfig?.agentSettings?.anyDeskSystemActionsRole ?? '',
    }
  }

  const methods = useForm<ProductFormType>({
    resolver: zodResolver(schema),
    defaultValues: { ...defaultValues() },
  })

  const watchCollectEventLogs = methods.watch('collectEventLogs')

  const calculateRealValuesSlider = (data: ProductFormType) => {
    if (data.eventLogsRecurrence) {
      data.eventLogsRecurrence = calculateStepRealValueFromSliderValue(data.eventLogsRecurrence)
    }
    if (data.resourcesReportRecurrence) {
      data.resourcesReportRecurrence = calculateStepRealValueFromSliderValue(data.resourcesReportRecurrence)
    }
    if (data.profileStorageReportRecurrence) {
      data.profileStorageReportRecurrence = calculateStepRealValueFromSliderValue(data.profileStorageReportRecurrence)
    }
    if (data.syncBrokerRecurrence) {
      data.syncBrokerRecurrence = calculateStepRealValueFromSliderValue(data.syncBrokerRecurrence)
    }
    return data
  }

  const sanitizeUpdaeData = (data: ProductFormType) => {
    let dataToSend = { ...calculateRealValuesSlider(data) }
    if (
      productConfig?.product.uniqueCode !== ProductEnvTypes.FLEXXDESKTOPSTD &&
      productConfig?.product.uniqueCode !== ProductEnvTypes.FLEXXDESKTOPEDGE &&
      productConfig?.product.uniqueCode !== ProductEnvTypes.FLEXXDESKTOPADV &&
      productConfig?.product.uniqueCode !== ProductEnvTypes.FLEXXDESKTOPENT
    ) {
      delete dataToSend.profileStorageReportRecurrence
      delete dataToSend.syncBrokerRecurrence
      delete dataToSend.detectNewCitrixSubscriptions
    }

    if (watchCollectEventLogs === false) {
      delete dataToSend.eventLogIds
      delete dataToSend.eventLogsRecurrence
    }
    return {
      ...dataToSend,
      eventLogIds:
        dataToSend.eventLogIds !== undefined
          ? data.eventLogIds?.join(SEPARATOR_AGENT_SETTINGS_EVENT_LOG_IDS)
          : undefined,
    }
  }

  const onSubmit = (data: ProductFormType) => {
    if (productConfig && productConfig._id) {
      const dataToSend = sanitizeUpdaeData(data)
      editProductSettings.mutate(dataToSend)
    }
  }

  return (
    <>
      {!isLoading && !isSuccess && !isError && (
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            <RowColLayout rowProps={{ classNames: 'mt-2' }}>
              <Dropdown
                name="environment"
                translator={t}
                dropdownProps={{
                  disabled: true,
                  defaultSelectedKey: productConfig?.product._id,
                  options: dropdownOptions,
                  label: t('AGENT_SETTINGS.FORM.ENVIRONMENT_LABEL'),
                  placeholder: t('AGENT_SETTINGS.FORM.ENVIRONMENT_PLACEHOLDER'),
                }}
              />
            </RowColLayout>
            <AgentSettingsToggleButton
              name="autoUpdateAgents"
              label={t('AGENT_SETTINGS.FORM.AUTO_UPDATE')}
              tooltip={t('AGENT_SETTINGS.FORM.AUTO_UPDATE_TOOLTIP')}
              disabled={readOnly}
            />

            <Separator text={t('AGENT_SETTINGS.FORM.REPORT')} />
            <div>
              <AgentSettingsSlider
                label={t('AGENT_SETTINGS.FORM.REPORT_RESOURCES')}
                name="resourcesReportRecurrence"
                sliderProps={{
                  min: 1,
                  max: 60,
                  step: 1,
                  valueFormat: (value) => useMinutesToLabel(t, value),
                }}
                tooltip={t('AGENT_SETTINGS.FORM.REPORT_RESOURCES_TOOLTIP')}
                disabled={readOnly}
              />
            </div>
            <Separator text={t('AGENT_SETTINGS.FORM.METRICS')} />
            <div>
              <AgentSettingsToggleButton
                name="collectDisks"
                label={t('AGENT_SETTINGS.FORM.COLLECT_DISKS')}
                tooltip={t('AGENT_SETTINGS.FORM.COLLECT_DISKS_TOOLTIP')}
                disabled={readOnly}
              />
              <AgentSettingsToggleButton
                name="collectServices"
                label={t('AGENT_SETTINGS.FORM.COLLECT_SERVICES')}
                tooltip={t('AGENT_SETTINGS.FORM.COLLECT_SERVICES_TOOLTIP')}
                disabled={readOnly}
              />
              <AgentSettingsToggleButton
                name="collectPnPEvents"
                label={t('AGENT_SETTINGS.FORM.COLLECT_PNP_EVENTS')}
                tooltip={t('AGENT_SETTINGS.FORM.COLLECT_PNP_EVENTS_TOOLTIP')}
                disabled={readOnly}
              />
              <AgentSettingsToggleButton
                name="collectPublicIP"
                label={t('AGENT_SETTINGS.FORM.COLLECT_PUBLIC_IP')}
                tooltip={t('AGENT_SETTINGS.FORM.COLLECT_PUBLIC_IP_TOOLTIP')}
                disabled={readOnly}
              />
              <AgentSettingsToggleButton
                name="collectEventLogs"
                label={t('AGENT_SETTINGS.FORM.COLLECT_EVENTS_LOGS')}
                tooltip={t('AGENT_SETTINGS.FORM.COLLECT_EVENTS_LOGS_TOOLTIP')}
                disabled={readOnly}
              />
              <AgentSettingsTagPicker
                name="eventLogIds"
                label={t('AGENT_SETTINGS.FORM.EVENT_LOG_IDS')}
                tooltip={t('AGENT_SETTINGS.FORM.EVENT_LOG_IDS_TOOLTIP')}
                placeholder={t('AGENT_SETTINGS.FORM.EVENT_LOG_IDS_PLACEHOLDER')}
                disabled={!watchCollectEventLogs || readOnly}
              />
              <AgentSettingsSlider
                label={t('AGENT_SETTINGS.FORM.EVENT_LOG_RECURRENCE')}
                name="eventLogsRecurrence"
                sliderProps={{
                  min: 1,
                  max: 240,
                  step: 1,
                  valueFormat: (value) => useMinutesToLabel(t, value),
                }}
                disabled={!watchCollectEventLogs || readOnly}
                tooltip={t('AGENT_SETTINGS.FORM.EVENT_LOG_RECURRENCE_TOOLTIP')}
              />
            </div>
            <Separator text={t('AGENT_SETTINGS.FORM.REMOTE_ASSISTANCE')} />
            <div>
              <AgentSettingsDropdown
                name="remoteSupport"
                t={t}
                label={t('AGENT_SETTINGS.FORM.REMOTE_SUPPORT')}
                placeholder={t('AGENT_SETTINGS.FORM.REMOTE_SUPPORT_PLACEHOLDER')}
                optionsDropdown={remoteSupportOptions(t)}
                tooltip={''}
                disabled={readOnly}
              />
              <AgentSettingsDropdown
                name="anyDeskSystemActionsRole"
                t={t}
                label={t('AGENT_SETTINGS.FORM.ANYDESK_ACTIONS')}
                placeholder={t('AGENT_SETTINGS.FORM.ANYDESK_ACTIONS_PLACEHOLDER')}
                optionsDropdown={anyDeskActionsOptions(t)}
                tooltip={t('AGENT_SETTINGS.FORM.ANYDESK_ACTIONS_TOOLTIP')}
                disabled={readOnly}
              />
            </div>
            {(productConfig?.product.uniqueCode === ProductEnvTypes.FLEXXDESKTOPSTD ||
              productConfig?.product.uniqueCode === ProductEnvTypes.FLEXXDESKTOPEDGE ||
              productConfig?.product.uniqueCode === ProductEnvTypes.FLEXXDESKTOPADV ||
              productConfig?.product.uniqueCode === ProductEnvTypes.FLEXXDESKTOPENT) && (
              <>
                <Separator text={t('AGENT_SETTINGS.FORM.CITRIX_SEPARATOR')} />
                <div>
                  <AgentSettingsSlider
                    label={t('AGENT_SETTINGS.FORM.REPORT_USER_PROFILE_STORAGE')}
                    name="profileStorageReportRecurrence"
                    sliderProps={{
                      min: 15,
                      max: 480,
                      step: 1,
                      valueFormat: (value) => useMinutesToLabel(t, value),
                    }}
                    tooltip={t('AGENT_SETTINGS.FORM.REPORT_USER_PROFILE_STORAGE_TOOLTIP')}
                    disabled={readOnly}
                  />

                  <AgentSettingsSlider
                    label={t('AGENT_SETTINGS.FORM.CITRIX')}
                    name="syncBrokerRecurrence"
                    sliderProps={{
                      min: 1,
                      max: 480,
                      step: 1,
                      valueFormat: (value) => useMinutesToLabel(t, value),
                    }}
                    tooltip={t('AGENT_SETTINGS.FORM.CITRIX_TOOLTIP')}
                    disabled={readOnly}
                  />
                  <AgentSettingsToggleButton
                    name="detectNewCitrixSubscriptions"
                    label={t('AGENT_SETTINGS.FORM.AUTO_DETECT_CITRIX')}
                    tooltip={''}
                    disabled={readOnly}
                  />
                </div>
              </>
            )}
            <FormActionsContainer>
              {productConfig ? (
                <FormActions
                  submitProps={{
                    text: t('general:BUTTON.SAVE'),
                    iconProps: { iconName: 'Save' },
                    disabled: readOnly,
                  }}
                />
              ) : (
                <FormActions
                  submitProps={{
                    text: t('general:BUTTON.CREATE'),
                    iconProps: { iconName: 'Add' },
                    disabled: readOnly,
                  }}
                />
              )}
            </FormActionsContainer>
          </form>
        </FormProvider>
      )}

      {isLoading && <LoadingSpinner></LoadingSpinner>}

      {(isSuccess || isError) && (
        <>
          {editProductSettings.isSuccess && <SuccessStatus message={t('AGENT_SETTINGS.FORM.EDIT_SUCCESS_MESSAGE')} />}

          {editProductSettings.isError && <ErrorStatus message={t('AGENT_SETTINGS.FORM.EDIT_ERROR_MESSAGE')} />}
        </>
      )}
    </>
  )
}

export default ProductAgentSettingsForm
