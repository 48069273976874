import { useMutation, useQueryClient } from '@tanstack/react-query'
import graphClient from 'app/query-client/graphClient'
import { gql } from 'graphql-request'

interface UpdateRolePermissionRequest {
  userRoleId: string
  organizationId: string
  organization: string
  portal: string
  workspaces: string
  analyzer: string
  allReportingGroups: boolean
  reportingGroups: string[]
}

const query = gql`
  mutation updateRolePermission($input: UpdateRolePermissionGraphqlRequest!) {
    updateRolePermission(input: $input) {
      _id
    }
  }
`

interface UpdateRolePermissionResponse {
  updateRolePermission: {
    _id: string
  }
}

async function updateRolePermission(request: UpdateRolePermissionRequest) {
  const { updateRolePermission }: UpdateRolePermissionResponse = await graphClient.request(query, {
    input: request,
  })

  return updateRolePermission
}

export default function () {
  const queryClient = useQueryClient()

  return useMutation(
    ['updateRolePermission'],
    (request: UpdateRolePermissionRequest) => updateRolePermission(request),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['getPaginatedRolePermissions'])
      },
    }
  )
}
