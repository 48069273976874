import { FormActionsContainer, LoadingSpinner } from '@flexxibleit/flexxible-ui'
import { zodResolver } from '@hookform/resolvers/zod'
import { ProductEnvTypes } from 'app/config/productEnv.enum'
import { RemoteSupport, remoteSupportOptions } from 'app/models/reporting-groups/RemoteSupport'
import { useEffect, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { ProductConfig, ReportingGroup } from 'app/query-client/types'
import FormActions from 'app/components/forms/FormActions'
import SuccessStatus from 'app/components/status/success-status'
import ErrorStatus from 'app/components/status/error-status'
import { z } from 'zod'
import { Separator } from 'app/components/separator'
import { anyDeskActionsOptions } from 'app/models/agentSettings/anyDesk'
import AgentSettingsToggleButton from '../components/agent-settings/AgentSettingsToggleButton'
import AgentSettingsDropdown from '../components/agent-settings/AgentSettingsDropdown'
import AgentSettingsSlider, {
  calculateSliderValueFromStepRealValue,
  calculateStepRealValueFromSliderValue,
} from '../components/agent-settings/AgentSettingsSlider'
import { updateAgentSettingsReportingGroup } from 'app/hooks/reportingGroups/useUpdateAgentSettingsReportingGroup'
import { useFeatureRender } from 'app/permissions/useFeatureRender'
import { FEATURE_NAMES } from 'app/permissions/FeatureName.enum'
import { IDropdownOption } from '@fluentui/react'
import { useMinutesToLabel } from 'app/hooks/utils/useMinutesToLabel'
import Dropdown from 'app/components/forms/Dropdown'
import RowColLayout from 'app/components/Layouts/RowColLayout'
import AgentSettingsTagPicker, {
  sanitizeDataForAgentSettingsTagPicker,
} from '../components/agent-settings/AgentSettingsTagPicker'
import { SEPARATOR_AGENT_SETTINGS_EVENT_LOG_IDS } from '../../../models/agentSettings/separatorEventLogsIds'

interface Props {
  organizationId?: string
  reportingGroup?: ReportingGroup
  productConfig?: ProductConfig
}

interface ReportingGroupAgentSettingsFrom {
  autoUpdateAgents?: boolean
  collectEventLogs?: boolean
  eventLogIds?: string[]
  eventLogsRecurrence?: number
  collectDisks?: boolean
  collectServices?: boolean
  collectPnPEvents?: boolean
  collectPublicIP?: boolean
  remoteSupport?: string
  anyDeskSystemActionsRole?: string
  collectDeviceLocation?: boolean
}

const schema: z.ZodType<Partial<ReportingGroupAgentSettingsFrom>> = z.object({
  autoUpdateAgents: z.boolean().optional(),
  collectEventLogs: z.boolean().optional(),
  eventLogIds: z.array(z.string()).optional(),
  eventLogsRecurrence: z
    .custom<number>()
    .refine((value) => !isNaN(value), 'required')
    .refine((value) => Number(value) >= 1, 'min')
    .refine((value) => Number(value) <= 240, 'max')
    .transform((value) => Number(value))
    .optional(),
  collectDisks: z.boolean().optional(),
  collectServices: z.boolean().optional(),
  collectPnPEvents: z.boolean().optional(),
  collectPublicIP: z.boolean().optional(),
  collectDeviceLocation: z.boolean().optional(),
  remoteSupport: z.string().optional(),
  anyDeskSystemActionsRole: z.string().optional(),
})

export const SettingsReportingGroupForm = ({ reportingGroup, productConfig }: Props) => {
  const { t } = useTranslation('organization_details')

  const editAgentSettingsReportingGroup = updateAgentSettingsReportingGroup()

  const { checkFeature } = useFeatureRender()
  const onlyRead = !checkFeature(FEATURE_NAMES.REPORTING_GROUP_AGENT_SETTINGS_UPDATE)

  const [isSuccess, setIsSuccess] = useState(false)
  const [isError, setIsError] = useState(false)
  const [isLoading, setIsLoading] = useState(true)

  const sanitizeBooleanCheckbox = (value: boolean | undefined) => {
    return value !== undefined && value !== null
  }

  const sanitizeDropdownCheckbox = (value: string | undefined) => {
    return value || value === ''
  }

  const [checkboxValues, setCheckboxValues] = useState({
    autoUpdateAgents: sanitizeBooleanCheckbox(reportingGroup?.agentSettings?.autoUpdateAgents),
    collectEventLogs: sanitizeBooleanCheckbox(reportingGroup?.agentSettings?.collectEventLogs),
    eventLogIds: !!sanitizeDataForAgentSettingsTagPicker(reportingGroup?.agentSettings?.eventLogIds),
    eventLogsRecurrence: !!reportingGroup?.agentSettings?.eventLogsRecurrence ?? false,
    collectDisks: sanitizeBooleanCheckbox(reportingGroup?.agentSettings?.collectDisks),
    collectServices: sanitizeBooleanCheckbox(reportingGroup?.agentSettings?.collectServices),
    collectPnPEvents: sanitizeBooleanCheckbox(reportingGroup?.agentSettings?.collectPnPEvents),
    collectPublicIP: sanitizeBooleanCheckbox(reportingGroup?.agentSettings?.collectPublicIP),
    remoteSupport: !!sanitizeDropdownCheckbox(reportingGroup?.agentSettings?.remoteSupport),
    anyDeskSystemActionsRole: !!sanitizeDropdownCheckbox(reportingGroup?.agentSettings?.anyDeskSystemActionsRole),
  })

  useEffect(() => {
    setIsSuccess(editAgentSettingsReportingGroup.isSuccess)
    setIsError(editAgentSettingsReportingGroup.isError)
    setIsLoading(editAgentSettingsReportingGroup.isLoading)
  }, [editAgentSettingsReportingGroup])

  const defaultValueRemoteSupport = () => {
    if (productConfig) {
      return reportingGroup?.agentSettings?.remoteSupport ?? productConfig.agentSettings?.remoteSupport
    }
    return reportingGroup?.agentSettings?.remoteSupport
  }

  const defaultValueAnyDeskSystemActionsRole = () => {
    if (productConfig) {
      return (
        reportingGroup?.agentSettings?.anyDeskSystemActionsRole ??
        productConfig.agentSettings?.anyDeskSystemActionsRole ??
        ''
      )
    }
    return reportingGroup?.agentSettings?.anyDeskSystemActionsRole ?? ''
  }

  const defaultValues = () => {
    if (productConfig) {
      const { agentSettings } = productConfig
      return {
        autoUpdateAgents: reportingGroup?.agentSettings?.autoUpdateAgents ?? agentSettings?.autoUpdateAgents ?? true,
        collectEventLogs: reportingGroup?.agentSettings?.collectEventLogs ?? agentSettings?.collectEventLogs ?? true,
        eventLogIds:
          sanitizeDataForAgentSettingsTagPicker(reportingGroup?.agentSettings?.eventLogIds) ??
          sanitizeDataForAgentSettingsTagPicker(agentSettings?.eventLogIds) ??
          ([] as string[]),
        eventLogsRecurrence: calculateSliderValueFromStepRealValue(
          reportingGroup?.agentSettings?.eventLogsRecurrence ?? agentSettings?.eventLogsRecurrence ?? 30
        ),
        collectDisks: reportingGroup?.agentSettings?.collectDisks ?? agentSettings?.collectDisks ?? true,
        collectServices: reportingGroup?.agentSettings?.collectServices ?? agentSettings?.collectServices ?? true,
        collectPnPEvents: reportingGroup?.agentSettings?.collectPnPEvents ?? agentSettings?.collectPnPEvents ?? false,
        collectPublicIP: reportingGroup?.agentSettings?.collectPublicIP ?? agentSettings?.collectPublicIP ?? false,
        remoteSupport: defaultValueRemoteSupport(),
        anyDeskSystemActionsRole: defaultValueAnyDeskSystemActionsRole(),
        collectDeviceLocation: reportingGroup?.agentSettings?.collectDeviceLocation ?? false,
      }
    }
    return {
      autoUpdateAgents: reportingGroup?.agentSettings?.autoUpdateAgents ?? true,
      collectEventLogs: reportingGroup?.agentSettings?.collectEventLogs ?? true,
      eventLogIds: sanitizeDataForAgentSettingsTagPicker(reportingGroup?.agentSettings?.eventLogIds) ?? [],
      eventLogsRecurrence: reportingGroup?.agentSettings?.eventLogsRecurrence ?? 30,
      collectDisks: reportingGroup?.agentSettings?.collectDisks ?? true,
      collectServices: reportingGroup?.agentSettings?.collectServices ?? true,
      collectPnPEvents: reportingGroup?.agentSettings?.collectPnPEvents ?? false,
      collectPublicIP: reportingGroup?.agentSettings?.collectPublicIP ?? false,
      remoteSupport: reportingGroup?.agentSettings?.remoteSupport ?? RemoteSupport.INTERACTIVE,
      anyDeskSystemActionsRole: reportingGroup?.agentSettings?.anyDeskSystemActionsRole ?? '',
      collectDeviceLocation: reportingGroup?.agentSettings?.collectDeviceLocation ?? false,
    }
  }

  const getProductConfigValue = () => {
    return {
      autoUpdateAgents: productConfig?.agentSettings?.autoUpdateAgents ?? true,
      collectEventLogs: productConfig?.agentSettings?.collectEventLogs ?? true,
      eventLogIds: sanitizeDataForAgentSettingsTagPicker(productConfig?.agentSettings?.eventLogIds) ?? [],
      eventLogsRecurrence: productConfig?.agentSettings?.eventLogsRecurrence ?? 30,
      collectDisks: productConfig?.agentSettings?.collectDisks ?? true,
      collectServices: productConfig?.agentSettings?.collectServices ?? true,
      collectPnPEvents: productConfig?.agentSettings?.collectPnPEvents ?? false,
      collectPublicIP: productConfig?.agentSettings?.collectPublicIP ?? false,
      remoteSupport: productConfig?.agentSettings?.remoteSupport ?? RemoteSupport.UNASSISTED,
      anyDeskSystemActionsRole: productConfig?.agentSettings?.anyDeskSystemActionsRole ?? '',
    }
  }

  const methods = useForm<ReportingGroupAgentSettingsFrom>({
    resolver: zodResolver(schema),
    defaultValues: { ...defaultValues() },
  })

  const resetValueOverwrite = (field: keyof ReportingGroupAgentSettingsFrom, newValue: boolean) => {
    if (newValue) {
      methods.setValue(field, defaultValues()[field])
    } else if (field !== 'collectDeviceLocation') {
      methods.setValue(field, getProductConfigValue()[field])
    }
  }

  const watchCollectEventLogs = methods.watch('collectEventLogs')

  const calculateBooleanForEventLogs = () => {
    if (onlyRead) {
      return false
    }
    const isOverwrite = checkboxValues.collectEventLogs
    if (isOverwrite) {
      return !watchCollectEventLogs
    } else {
      return !productConfig?.agentSettings?.collectEventLogs
    }
  }

  const calculateRealValuesSlider = (data: ReportingGroupAgentSettingsFrom) => {
    if (data.eventLogsRecurrence) {
      data.eventLogsRecurrence = calculateStepRealValueFromSliderValue(data.eventLogsRecurrence)
    }

    return data
  }

  const deleteNonOverwriteValues = (data: ReportingGroupAgentSettingsFrom) => {
    if (!checkboxValues.autoUpdateAgents) {
      delete data.autoUpdateAgents
    }
    if (!checkboxValues.collectEventLogs) {
      delete data.collectEventLogs
    }
    if (!checkboxValues.eventLogIds) {
      delete data.eventLogIds
    }
    if (!checkboxValues.eventLogsRecurrence) {
      delete data.eventLogsRecurrence
    }
    if (!checkboxValues.collectDisks) {
      delete data.collectDisks
    }
    if (!checkboxValues.collectServices) {
      delete data.collectServices
    }
    if (!checkboxValues.collectPnPEvents) {
      delete data.collectPnPEvents
    }
    if (!checkboxValues.collectPublicIP) {
      delete data.collectPublicIP
    }
    if (!checkboxValues.remoteSupport) {
      delete data.remoteSupport
    }
    if (!checkboxValues.anyDeskSystemActionsRole) {
      delete data.anyDeskSystemActionsRole
    }
    return data
  }

  const sanitizeDataForUpdate = (data: ReportingGroupAgentSettingsFrom) => {
    if (watchCollectEventLogs === false) {
      delete data.eventLogIds
      delete data.eventLogsRecurrence
    }
    const allfieldsData = { ...calculateRealValuesSlider(data) }
    let tempValue = { ...deleteNonOverwriteValues(allfieldsData) }
    return {
      ...tempValue,
      eventLogIds:
        tempValue.eventLogIds !== undefined
          ? tempValue.eventLogIds.join(SEPARATOR_AGENT_SETTINGS_EVENT_LOG_IDS)
          : undefined,
    }
  }

  const onSubmit = (data: ReportingGroupAgentSettingsFrom) => {
    if (reportingGroup && reportingGroup._id) {
      const dataToSend = sanitizeDataForUpdate(data)
      editAgentSettingsReportingGroup.mutate({ id: reportingGroup._id, input: dataToSend })
    }
  }

  const dropdownOptions: IDropdownOption[] = [
    {
      key: reportingGroup?._id || '',
      text: reportingGroup ? `${reportingGroup?.name} (${productConfig?.environment})` : '',
    },
  ]

  return (
    <>
      {!isLoading && !isSuccess && !isError && (
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            <RowColLayout rowProps={{ classNames: 'mt-2' }}>
              <Dropdown
                name="environment"
                translator={t}
                dropdownProps={{
                  disabled: true,
                  defaultSelectedKey: reportingGroup?._id,
                  options: dropdownOptions,
                  label: t('AGENT_SETTINGS.FORM.ENVIRONMENT_LABEL'),
                  placeholder: t('AGENT_SETTINGS.FORM.ENVIRONMENT_PLACEHOLDER'),
                }}
              />
            </RowColLayout>
            <AgentSettingsToggleButton
              overridable={true}
              name="autoUpdateAgents"
              label={t('AGENT_SETTINGS.FORM.AUTO_UPDATE')}
              tooltip={t('AGENT_SETTINGS.FORM.AUTO_UPDATE_TOOLTIP')}
              isOverwrite={checkboxValues.autoUpdateAgents}
              callbackOverwrite={() => {
                setCheckboxValues({ ...checkboxValues, autoUpdateAgents: !checkboxValues.autoUpdateAgents })
                resetValueOverwrite('autoUpdateAgents', !checkboxValues.autoUpdateAgents)
              }}
              disabled={onlyRead}
            />

            <Separator text={t('AGENT_SETTINGS.FORM.METRICS')} />
            <div>
              <AgentSettingsToggleButton
                overridable={true}
                name="collectDisks"
                label={t('AGENT_SETTINGS.FORM.COLLECT_DISKS')}
                tooltip={t('AGENT_SETTINGS.FORM.COLLECT_DISKS_TOOLTIP')}
                isOverwrite={checkboxValues.collectDisks}
                callbackOverwrite={() => {
                  resetValueOverwrite('collectDisks', !checkboxValues.collectDisks)
                  setCheckboxValues({ ...checkboxValues, collectDisks: !checkboxValues.collectDisks })
                }}
                disabled={onlyRead}
              />
              <AgentSettingsToggleButton
                overridable={true}
                name="collectServices"
                label={t('AGENT_SETTINGS.FORM.COLLECT_SERVICES')}
                tooltip={t('AGENT_SETTINGS.FORM.COLLECT_SERVICES_TOOLTIP')}
                isOverwrite={checkboxValues.collectServices}
                callbackOverwrite={() => {
                  resetValueOverwrite('collectServices', !checkboxValues.collectServices)
                  setCheckboxValues({ ...checkboxValues, collectServices: !checkboxValues.collectServices })
                }}
                disabled={onlyRead}
              />
              <AgentSettingsToggleButton
                name="collectPnPEvents"
                overridable={true}
                label={t('AGENT_SETTINGS.FORM.COLLECT_PNP_EVENTS')}
                tooltip={t('AGENT_SETTINGS.FORM.COLLECT_PNP_EVENTS_TOOLTIP')}
                isOverwrite={checkboxValues.collectPnPEvents}
                callbackOverwrite={() => {
                  resetValueOverwrite('collectPnPEvents', !checkboxValues.collectPnPEvents)
                  setCheckboxValues({ ...checkboxValues, collectPnPEvents: !checkboxValues.collectPnPEvents })
                }}
                disabled={onlyRead}
              />

              <AgentSettingsToggleButton
                name="collectPublicIP"
                overridable={true}
                label={t('AGENT_SETTINGS.FORM.COLLECT_PUBLIC_IP')}
                tooltip={t('AGENT_SETTINGS.FORM.COLLECT_PUBLIC_IP_TOOLTIP')}
                isOverwrite={checkboxValues.collectPublicIP}
                callbackOverwrite={() => {
                  resetValueOverwrite('collectPublicIP', !checkboxValues.collectPublicIP)
                  setCheckboxValues({ ...checkboxValues, collectPublicIP: !checkboxValues.collectPublicIP })
                }}
                disabled={onlyRead}
              />

              {(productConfig?.product.uniqueCode === ProductEnvTypes.FLEXXCLIENT ||
                productConfig?.product.uniqueCode === ProductEnvTypes.FXXONE) && (
                <AgentSettingsToggleButton
                  name="collectDeviceLocation"
                  label={t('AGENT_SETTINGS.FORM.COLLECT_DEVICE_LOCATION')}
                  tooltip={t('AGENT_SETTINGS.FORM.COLLECT_DEVICE_LOCATION_TOOLTIP')}
                  disabled={onlyRead}
                />
              )}
              <AgentSettingsToggleButton
                name="collectEventLogs"
                overridable={true}
                label={t('AGENT_SETTINGS.FORM.COLLECT_EVENTS_LOGS')}
                tooltip={t('AGENT_SETTINGS.FORM.COLLECT_EVENTS_LOGS_TOOLTIP')}
                isOverwrite={checkboxValues.collectEventLogs}
                callbackOverwrite={() => {
                  resetValueOverwrite('collectEventLogs', !checkboxValues.collectEventLogs)
                  setCheckboxValues({ ...checkboxValues, collectEventLogs: !checkboxValues.collectEventLogs })
                }}
                disabled={onlyRead}
              />

              <AgentSettingsTagPicker
                name="eventLogIds"
                label={t('AGENT_SETTINGS.FORM.EVENT_LOG_IDS')}
                tooltip={t('AGENT_SETTINGS.FORM.EVENT_LOG_IDS_TOOLTIP')}
                placeholder={t('AGENT_SETTINGS.FORM.EVENT_LOG_IDS_PLACEHOLDER')}
                disabled={calculateBooleanForEventLogs()}
                overridable={true}
                isOverwrite={checkboxValues.eventLogIds}
                callbackOverwrite={() => {
                  resetValueOverwrite('eventLogIds', !checkboxValues.eventLogIds)
                  setCheckboxValues({ ...checkboxValues, eventLogIds: !checkboxValues.eventLogIds })
                }}
              />
              <AgentSettingsSlider
                label={t('AGENT_SETTINGS.FORM.EVENT_LOG_RECURRENCE')}
                name="eventLogsRecurrence"
                sliderProps={{
                  min: 1,
                  max: 240,
                  step: 1,
                  valueFormat: (value) => useMinutesToLabel(t, value),
                }}
                disabled={calculateBooleanForEventLogs()}
                tooltip={t('AGENT_SETTINGS.FORM.EVENT_LOG_RECURRENCE_TOOLTIP')}
                overridable={true}
                isOverwrite={checkboxValues.eventLogsRecurrence}
                callbackOverwrite={() => {
                  resetValueOverwrite('eventLogsRecurrence', !checkboxValues.eventLogsRecurrence)
                  setCheckboxValues({ ...checkboxValues, eventLogsRecurrence: !checkboxValues.eventLogsRecurrence })
                }}
              />
            </div>
            <Separator text={t('AGENT_SETTINGS.FORM.REMOTE_ASSISTANCE')} />
            <div>
              <AgentSettingsDropdown
                name="remoteSupport"
                t={t}
                label={t('AGENT_SETTINGS.FORM.REMOTE_SUPPORT')}
                placeholder={t('AGENT_SETTINGS.FORM.REMOTE_SUPPORT_PLACEHOLDER')}
                optionsDropdown={remoteSupportOptions(t)}
                tooltip={''}
                overridable={true}
                isOverwrite={checkboxValues.remoteSupport}
                callbackOverwrite={() => {
                  resetValueOverwrite('remoteSupport', !checkboxValues.remoteSupport)
                  setCheckboxValues({ ...checkboxValues, remoteSupport: !checkboxValues.remoteSupport })
                }}
                disabled={onlyRead}
              />
              <AgentSettingsDropdown
                name="anyDeskSystemActionsRole"
                t={t}
                label={t('AGENT_SETTINGS.FORM.ANYDESK_ACTIONS')}
                placeholder={t('AGENT_SETTINGS.FORM.ANYDESK_ACTIONS_PLACEHOLDER')}
                optionsDropdown={anyDeskActionsOptions(t)}
                tooltip={t('AGENT_SETTINGS.FORM.ANYDESK_ACTIONS_TOOLTIP')}
                overridable={true}
                isOverwrite={checkboxValues.anyDeskSystemActionsRole}
                callbackOverwrite={() => {
                  resetValueOverwrite('anyDeskSystemActionsRole', !checkboxValues.anyDeskSystemActionsRole)
                  setCheckboxValues({
                    ...checkboxValues,
                    anyDeskSystemActionsRole: !checkboxValues.anyDeskSystemActionsRole,
                  })
                }}
                disabled={onlyRead}
              />
            </div>

            <FormActionsContainer>
              {reportingGroup ? (
                <FormActions
                  submitProps={{
                    text: t('general:BUTTON.SAVE'),
                    iconProps: { iconName: 'Save' },
                    disabled: onlyRead,
                  }}
                />
              ) : (
                <FormActions
                  submitProps={{
                    text: t('general:BUTTON.CREATE'),
                    iconProps: { iconName: 'Add' },
                    disabled: onlyRead,
                  }}
                />
              )}
            </FormActionsContainer>
          </form>
        </FormProvider>
      )}

      {isLoading && <LoadingSpinner></LoadingSpinner>}

      {(isSuccess || isError) && (
        <>
          {editAgentSettingsReportingGroup.isSuccess && (
            <SuccessStatus message={t('AGENT_SETTINGS.FORM.EDIT_SUCCESS_MESSAGE')} />
          )}

          {editAgentSettingsReportingGroup.isError && (
            <ErrorStatus message={t('AGENT_SETTINGS.FORM.EDIT_ERROR_MESSAGE')} />
          )}
        </>
      )}
    </>
  )
}
