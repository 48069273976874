import { ContentHeader } from '@flexxibleit/flexxible-ui'
import { Text } from '@fluentui/react'
import { chartColors } from 'app/components/chart/ChartColors.constants'
import { MOBILE_QUERY } from 'app/config/media-query'
import { useOrganizationSelection } from 'app/globals/useOrganizationSelection'
import { useViewOrganizationOperationsHistory } from 'app/hooks/efficiencyOperations/useViewOrganizationOperationsHistory'
import useMediaQuery from 'app/hooks/utils/useMediaQuery'
import { DateFormat, dateFormatter } from 'app/services/date-formatter'
import { Interval } from 'luxon'
import { useTranslation } from 'react-i18next'
import {
  aggregateTotalOperations,
  formatOperationsColumnChart,
  formatPieChartOperationStatusSeries,
  formatPieChartOperationTypeSeries,
  getDisplayName,
} from './ViewOrganizationOperationsHistory.service'
import { PieChartComponent } from '../../../components/chart/PieChart.component'
import { TimelineColumnChart } from '../../../components/chart/TimelineColumnChart.component'
import { useOperationsFilter } from '../OperationsContext'

interface Props {
  startDate: Date
  endDate: Date
}

interface FilterValue {
  filter: 'success' | 'warning' | 'failed' | 'automatic' | 'manual' | 'endUserMicroservice'
  selected: boolean
}

export const ViewOrganizationOperationsHistoryFeature = ({ startDate, endDate }: Props) => {
  const { selectedOrganizationId } = useOrganizationSelection()
  const { t } = useTranslation('efficiency_operations')
  const [isMobile] = useMediaQuery(MOBILE_QUERY)
  const { state, setState } = useOperationsFilter()

  const { data: operations, isLoading: isLoadingView } = useViewOrganizationOperationsHistory(
    selectedOrganizationId as string,
    startDate,
    endDate
  )

  const interval = Interval.fromDateTimes(startDate, endDate)

  function* days(interval: Interval) {
    let cursor = interval.start?.startOf('day')
    while (cursor < interval.end) {
      yield cursor
      cursor = cursor.plus({ days: 1 })
    }
  }

  const onChartsClick = (filterValue: FilterValue) => {
    //TODO: Finish resolving the filter state context (delay)
    // setState({
    //   ...state,
    //   [filterValue.filter]: filterValue.selected
    // })
  }

  return (
    <>
      <div
        className={isMobile ? 'd-flex d-flexCol' : 'd-flex d-flexRow d-flexAlignItemsCenter d-flexJustifyBetween'}
        style={{ display: 'flex', marginTop: '32px', alignItems: 'stretch', gap: '16px' }}
      >
        <div
          style={{
            borderRadius: '15px',
            backgroundColor: 'white',
            padding: '20px',
            flexGrow: '2',
            display: 'flex',
            flexDirection: 'column',
            width: isMobile ? '100%' : '70%',
          }}
        >
          <ContentHeader title={t('TITLE_COLUMN_CHART')} />
          <TimelineColumnChart
            t={t}
            stacking="normal"
            categories={Array.from(days(interval)).map((date) =>
              dateFormatter(t, date.toJSDate(), DateFormat.MonthYear)
            )}
            series={[
              ...Array.from([
                getDisplayName('manual', t),
                getDisplayName('automatic', t),
                getDisplayName('endUserMicroservice', t),
              ]).map((seriesName: string, i: number) => ({
                type: 'column',
                name: seriesName,
                color: chartColors[i + 4],
                data: formatOperationsColumnChart(seriesName, operations, t, interval),
              })),
            ]}
          />
        </div>

        <div
          style={{
            borderRadius: '15px',
            backgroundColor: 'white',
            padding: '20px',
            width: isMobile ? '100%' : '30%',
            flexGrow: '1',
          }}
        >
          <ContentHeader title={t('TOTAL_OPERATIONS')} />
          <Text variant="mega">{aggregateTotalOperations(operations)}</Text>
          <div style={{ marginTop: '16px', display: 'flex', flexWrap: 'wrap', justifyContent: 'center' }}>
            <PieChartComponent
              height={200}
              width={300}
              size={100}
              onClick={onChartsClick}
              series={[
                {
                  name: 'Total',
                  colorByPoint: true,
                  data: formatPieChartOperationStatusSeries(operations, t, state),
                },
              ]}
            />
            <PieChartComponent
              height={200}
              width={300}
              size={100}
              onClick={onChartsClick}
              series={[
                {
                  name: 'Total',
                  colorByPoint: true,
                  data: formatPieChartOperationTypeSeries(operations, t, state),
                },
              ]}
            />
          </div>
        </div>
      </div>
    </>
  )
}
