import { useState } from 'react'
import { PatchPolicyTarget } from '../../../../query-client/types'
import { PatchPolicyTargetSchedulerReadMode } from '../components/PatchPolicyTargetSchedulerReadMode'
import { PatchPolicyTargetSchedulerEditMode } from '../components/PatchPolicyTargetSchedulerEditMode'

interface PatchPolicySchedulesTabProps {
  patchPolicyTarget: PatchPolicyTarget
}

export const PatchPolicySchedulesTabV2 = (props: PatchPolicySchedulesTabProps) => {
  const [editMode, setEditMode] = useState<boolean>(false)

  if (editMode) {
    return (
      <div
        style={{
          margin: '0 25px',
        }}
      >
        <PatchPolicyTargetSchedulerEditMode
          patchPolicyTarget={props.patchPolicyTarget}
          onClickCancel={() => setEditMode(false)}
          onClickSave={() => setEditMode(false)}
        />
      </div>
    )
  }

  return (
    <div
      style={{
        margin: '0 25px',
      }}
    >
      <PatchPolicyTargetSchedulerReadMode
        patchPolicyTarget={props.patchPolicyTarget}
        onClickEdit={() => setEditMode(true)}
      />
    </div>
  )
}
