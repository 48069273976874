import { useMutation } from "@tanstack/react-query";
import { gql } from "graphql-request";
import graphClient from "../../query-client/graphClient";
import queryClient from "../../query-client/queryClient";

const deleteWorkspaceGroupQuery = gql`
  mutation ($id: ID!) {
    deleteWorkspaceGroup(id: $id)
  }
`

function deleteWorkspaceGroup(id: string) {
  return graphClient
  .request(deleteWorkspaceGroupQuery, { id: id })
  .then(response => response)
}

export default function () {
  return useMutation(['deleteWorkspaceGroup'], (workspaceGroupId: any) => deleteWorkspaceGroup(workspaceGroupId), {
    onSuccess: () => {
      queryClient.invalidateQueries(['getWorkspaceGroupsByBusinessPaginated'])
      queryClient.invalidateQueries(['getWorkspaceGroupById'])
    },
  })
}
