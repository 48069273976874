import { useQuery } from '@tanstack/react-query'
import { gql } from 'graphql-request'
import graphClient from '../../query-client/graphClient'
import { WindowsPatchPolicy } from 'app/query-client/types'

const getWindowsPatchPoliciesByOrganizationQuery = gql`
  query ($organizationId: ID!) {
    getWindowsPatchPoliciesByOrganization(organizationId: $organizationId) {
      _id
      name
      organizationId
      createdBy
      targetGroupId
      createdAt
      updatedAt
      patchPolicyTargets {
        _id
        name
      }
      hasAutomaticApprovals
    }
  }
`

async function getWindowsPatchPoliciesByOrganization(organizationId: string): Promise<WindowsPatchPolicy[]> {
  return graphClient
    .request(getWindowsPatchPoliciesByOrganizationQuery, { organizationId })
    .then((r) => r.getWindowsPatchPoliciesByOrganization)
}

export default function (organizationId: string) {
  return useQuery(
    ['getWindowsPatchPoliciesByOrganization', organizationId],
    () => getWindowsPatchPoliciesByOrganization(organizationId),
    {
      refetchOnWindowFocus: false,
    }
  )
}
