import { LoadingSpinner } from '@flexxibleit/flexxible-ui'
import { Dropdown, SpinnerSize } from '@fluentui/react'
import { useUpdateMySettings } from 'app/hooks/userSettings/useUpdateMySettings'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

const regiones: Record<string, string[]> = {
  en: ['AU', 'BZ', 'CA', 'GB', 'IE', 'IN', 'JM', 'MY', 'NZ', 'PH', 'SG', 'TT', 'US', 'ZA', 'ZW'],
  es: [
    'AR',
    'BO',
    'CL',
    'CO',
    'CR',
    'DO',
    'EC',
    'ES',
    'GT',
    'HN',
    'MX',
    'NI',
    'PA',
    'PE',
    'PR',
    'PY',
    'SV',
    'US',
    'UY',
    'VE',
  ],
  pt: ['BR', 'PT'],
}

export const DefaultRegionSelector = () => {
  const { t, i18n } = useTranslation()
  const { updateMySettings } = useUpdateMySettings()
  const [isLoading, setIsLoading] = useState(false)

  const preferredLanguage = new Intl.Locale(i18n.language).language
  const preferredRegion = new Intl.Locale(i18n.language).region

  const handleOnChangeDefaultRegion = (event: any, option?: any) => {
    if (option.key !== preferredRegion) {
      setIsLoading(true)
      updateMySettings({ preferredRegion: option.key })
        .then(() => {
          setIsLoading(false)
        })
        .catch(() => setIsLoading(false))
    }
  }

  const options = regiones[preferredLanguage]?.map((region) => {
    const tr = new Intl.DisplayNames([preferredLanguage], { type: 'region' })
    return { key: region, text: tr.of(region) ?? region }
  })

  if (!options?.length) {
    return null
  }

  return (
    <>
      <Dropdown
        label={t('user_settings:SELECT_REGION_LABEL')}
        selectedKey={preferredRegion ?? ''}
        onChange={handleOnChangeDefaultRegion}
        options={options}
        disabled={isLoading}
      />
      {isLoading && (
        <div
          style={{
            top: '-25px',
            position: 'relative',
          }}
        >
          <LoadingSpinner size={SpinnerSize.small} />
        </div>
      )}
    </>
  )
}
