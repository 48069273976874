import { withFeatureRender } from '../../permissions/withFeatureRender'
import { FEATURE_NAMES } from '../../permissions/FeatureName.enum'
import PageBody from '../../components/PageBody/PageBody.component'
import { WorkspaceGroupsFeature } from '../organization-details/workspace-groups/WorkspaceGroupsFeature'
import { useTranslation } from 'react-i18next'
import { useOrganizationSelection } from '../../globals/useOrganizationSelection'
import { useEffect } from 'react'
import { useOrganizationByIdForAllRoles } from '../../hooks/organization/useOrganizationByIdForAllRoles'
import { WorkspacesFeature } from '../organization-details/workspaces/WorkspacesFeature'

const Workspaces = () => {
  const { selectedOrganizationId } = useOrganizationSelection()
  const { data, isLoading, isFetching } = useOrganizationByIdForAllRoles(selectedOrganizationId || '')
  const { t } = useTranslation('workspace_groups')

  return (
    <PageBody title={t('TITLE_WORKSPACES')} titleTag={data?.name} isLoading={isLoading || isFetching}>
      <WorkspacesFeature organizationId={data?._id ?? ''} detailPageUrlBase={`/workspace-groups/`} />
    </PageBody>
  )
}

export const WorkspacesPage = withFeatureRender(Workspaces, FEATURE_NAMES.WORKSPACES_READ)
