import ControlledTextField, { ControlledTextFieldProps } from "../TextField";

function MultilineTextField({ name, translator, required = false, textFieldProps }: ControlledTextFieldProps) {
  
  return <>
    <ControlledTextField
      name={name}
      translator={translator}
      required={required}
      textFieldProps={{
        multiline: true,
        rows: 3,
        ...textFieldProps
      }}
    />
  </>
}

export default MultilineTextField;