import { IContextualMenuItem, IconButton, MessageBar, MessageBarType } from '@fluentui/react'
import { ConditionData } from 'app/hooks/flows/useGetFlow'
import { useSidePanel } from 'app/hooks/useCreateForm'
import { getTranslation } from 'app/models/microservices'
import { operatorLabel } from 'app/models/flows/OperatorType'
import { useTranslation } from 'react-i18next'
import { NodeProps } from 'reactflow'
import { useShallow } from 'zustand/react/shallow'
import { ActionSelector } from '../../panels/ActionSelector'
import { ConditionForm } from '../../panels/ConditionForm'
import { SelectForm } from '../../panels/ConditionSelector'
import useStore, { MAX_CONDITIONS, RFState } from '../../store/store'
import { BaseNode } from '../base/BaseNode'

export interface ConditionNodeData {
  conditionData: Partial<ConditionData>
  leaf: boolean
  isValid: boolean
}

const selector = (state: RFState) => ({
  editing: state.editing,
  addAction: state.addAction,
  addCondition: state.addCondition,
  updateConditionNode: state.updateConditionNode,
  removeCondition: state.removeCondition,
  nodes: state.nodes,
})

export const ConditionNode = (props: NodeProps<ConditionNodeData>) => {
  const { t, i18n } = useTranslation('flows')
  const { editing, addAction, addCondition, updateConditionNode, removeCondition, nodes } = useStore(
    useShallow(selector)
  )
  const { openSidePanelWith, closeSidebar } = useSidePanel()

  const condition = props.data.conditionData.conditionType!
  const leaf = Boolean(props.data.leaf)
  const { text } = getTranslation(condition.name, i18n.language)

  const onShowConditionForm = (id: string, data: ConditionNodeData) => {
    const name = getTranslation(data.conditionData.conditionType!.name, i18n.language)

    openSidePanelWith(
      {
        title: name.text,
        isFooterAtBottom: true,
      },
      <ConditionForm
        condition={data.conditionData.conditionType!}
        data={data.conditionData ?? {}}
        onSubmit={(data) => {
          updateConditionNode(id, data)
          closeSidebar()
        }}
        onRemove={() => {
          removeCondition(id)
          closeSidebar()
        }}
      />
    )
  }

  const onAddCondition = () => {
    openSidePanelWith(
      {
        title: t('EDITOR.SELECTOR.CONDITION.TITLE'),
        isFooterAtBottom: true,
        className: 'p-0',
      },
      <SelectForm
        onSelect={(item) => {
          const added = addCondition(props.id, item)
          closeSidebar()

          onShowConditionForm(added.id, added.data)
        }}
      />
    )
  }

  const onAddAction = () => {
    openSidePanelWith(
      {
        title: t('EDITOR.SELECTOR.ACTION.TITLE'),
        isFooterAtBottom: true,
        className: 'p-0',
      },
      <ActionSelector
        onSelect={(item) => {
          addAction(props.id, item)
          closeSidebar()
        }}
      />
    )
  }

  const hasReachedMaxConditions = nodes.filter((node) => node.type === 'condition').length >= MAX_CONDITIONS

  const menuOptions: IContextualMenuItem[] = [
    ...(!hasReachedMaxConditions
      ? [
        {
          key: 'addCondition',
          text: t('EDITOR.MENU.ADD_CONDITION'),
          onClick: onAddCondition,
        },
      ]
      : []),
    ...(leaf
      ? [
        {
          key: 'addAction',
          text: t('EDITOR.MENU.ADD_ACTION'),
          onClick: onAddAction,
        },
      ]
      : []),
  ]

  return (
    <>
      <BaseNode
        title={text}
        icon={condition.iconName}
        color={condition.color}
        onClick={() => {
          if (!editing) return
          onShowConditionForm(props.id, props.data)
        }}
      >
        {props.data.isValid ? (
          <ConditionDataComponent data={props.data.conditionData} />
        ) : (
          <MessageBar messageBarType={MessageBarType.severeWarning} isMultiline={false} dismissButtonAriaLabel="Close">
            {t('EDITOR.INVALID_CONDITION')}
          </MessageBar>
        )}
      </BaseNode>
      <div className="edge-drop-zone-container" style={{ marginTop: !leaf ? '20px' : '8px' }}>
        <div
          className="msla-drop-zone-viewmanager2"
          style={{ display: 'grid', placeItems: 'center', width: '100%', height: '100%' }}
        >
          {editing && menuOptions.length > 0 && (
            <IconButton
              iconProps={{ iconName: 'Add' }}
              style={{ backgroundColor: 'white', borderRadius: 50, border: '1px solid #c8c8c8' }}
              styles={{ menuIcon: { display: 'none' } }}
              menuProps={
                menuOptions.length
                  ? {
                      isBeakVisible: true,
                      items: menuOptions,
                    }
                  : undefined
              }
            />
          )}
        </div>
      </div>
    </>
  )
}

const ConditionDataComponent = ({ data }: { data: Partial<ConditionData> }) => {
  const { t } = useTranslation('flows')
  return (
    <div style={{ padding: 5, gap: 5 }} className="d-flex d-flexJustifyEnd">
      <span
        style={{
          whiteSpace: 'nowrap',
          maxWidth: '100%',
          display: 'inline-block',
          backgroundColor: '#f3f2f1',
          padding: '2px 5px',
          borderRadius: 2,
        }}
      >
        {data.operator && operatorLabel(t)[data.operator]}
      </span>
      <span
        style={{
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          maxWidth: '100%',
          display: 'inline-block',
          backgroundColor: '#f3f2f1',
          padding: '2px 5px',
          borderRadius: 2,
        }}
      >
        {data.compareTo}
      </span>
    </div>
  )
}
