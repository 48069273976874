import { TablePageBody } from '@flexxibleit/flexxible-ui'
import { FeedbackMessageBar } from '../../../components/FeedbackMessageBar/FeedbackMessageBar'
import TitleComponent from '../../../components/PageBody/PageTitle.component'
import { ListInstalledApps } from './ListInstalledApps'
import { useOrganizationSelection } from '../../../globals/useOrganizationSelection'
import { useTranslation } from 'react-i18next'

export const InstalledAppsPage = () => {
  const { t } = useTranslation('analyzer')
  const { selectedOrganizationId } = useOrganizationSelection()

  if (!selectedOrganizationId) {
    return <></>
  }

  return (
    <TablePageBody title={t('INSTALLED_APPS.TITLE')} titleComponent={<TitleComponent title={t('INSTALLED_APPS.TITLE')} />}>
      <FeedbackMessageBar />
      <ListInstalledApps organizationId={selectedOrganizationId} />
    </TablePageBody>
  )
}
