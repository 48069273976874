import { FormActionsContainer, LoadingSpinner } from '@flexxibleit/flexxible-ui'
import { zodResolver } from '@hookform/resolvers/zod'
import { FormProvider, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import * as z from 'zod'
import RowColLayout from '../../../components/Layouts/RowColLayout'
import FormActions from '../../../components/forms/FormActions'
import ControlledTextField from '../../../components/forms/TextField'
import ErrorStatus from '../../../components/status/error-status'
import SuccessStatus from '../../../components/status/success-status'
import useEditOrganization from '../../../hooks/organization/useEditOrganization'
import { Business } from '../../../query-client/types'

export interface AzureActiveDirectryFormProps {
  organization: Business
}

interface FormData {
  clientId: string
  clientSecret: string
  tenantId: string
}



const EntraIdForm = ({ organization }: AzureActiveDirectryFormProps) => {
  const { t } = useTranslation('organizations')
  const { mutate, isLoading, isError, isSuccess } = useEditOrganization(organization?._id)

  const schema: z.ZodType<FormData> = z.object({
    clientId: z.string().min(1),
    clientSecret: z.string().min(1),
    tenantId: z.string().min(1),
  })

  const getDefaultValues = (): FormData => {
    const activeDirectoryCredentials = organization.activeDirectoryCredentials
    return {
      clientId: activeDirectoryCredentials?.clientId ?? '',
      clientSecret: activeDirectoryCredentials?.isClientSecretSet ? 'client-secret' : '',
      tenantId: activeDirectoryCredentials?.tenantId ?? '',
    }
  }

  const methods = useForm<FormData>({
    resolver: zodResolver(schema),
    defaultValues: { ...getDefaultValues() },
  })

  const onSubmit = (data: FormData) => {
    mutate({
      activeDirectoryCredentials: {
        clientId: data.clientId,
        tenantId: data.tenantId,
        ...(data.clientSecret !== 'client-secret' && { clientSecret: data.clientSecret }),
      },
    })
  }

  return (
    <>
      {isLoading && <LoadingSpinner></LoadingSpinner>}
      {isSuccess && <SuccessStatus message={t('FORM.EDIT_AAD_SUCCESS')} />}
      {isError && <ErrorStatus message={t('FORM.EDIT_AAD_ERROR')} />}

      {!isLoading && !isSuccess && !isError && (
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            <RowColLayout rowProps={{ classNames: 'mt-2' }}>
              <ControlledTextField
                name="clientId"
                required={true}
                translator={t}
                textFieldProps={{
                  label: t('FORM.AAD.CLIENT_ID'),
                  placeholder: t('FORM.ENTER_TEXT'),
                }}
              />
            </RowColLayout>

            <RowColLayout rowProps={{ classNames: 'mt-2' }}>
              <ControlledTextField
                name="clientSecret"
                required={true}
                translator={t}
                textFieldProps={{
                  label: t('FORM.AAD.SECRET'),
                  placeholder: t('FORM.ENTER_TEXT'),
                  type: 'password',
                }}
              />
            </RowColLayout>

            <RowColLayout rowProps={{ classNames: 'mt-2' }}>
              <ControlledTextField
                name="tenantId"
                required={true}
                translator={t}
                textFieldProps={{
                  label: t('FORM.AAD.TENANTID'),
                  placeholder: t('FORM.ENTER_TEXT'),
                }}
              />
            </RowColLayout>

            <FormActionsContainer>
              <FormActions
                submitProps={{
                  text: t('general:BUTTON.SAVE'),
                  iconProps: { iconName: 'Save' },
                }}
              />
            </FormActionsContainer>
          </form>
        </FormProvider>
      )}
    </>
  )
}

export default EntraIdForm
