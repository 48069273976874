import { useMutation, useQueryClient } from "@tanstack/react-query";
import { BusinessFormData } from "app/pages/organizations/organization-list/OrganizationForm";
import { gql } from "graphql-request";
import graphClient from "../../query-client/graphClient";

const createBusinessQuery = gql`mutation Request($input:BusinessInput!) {
  createBusiness(input:$input) {
    _id
    name
    email
    type
    country
    language
    description
    serviceNowCompanyId
  }
}`

function createBusiness(business: any) {
  return graphClient
    .request(createBusinessQuery, {
      input: business
    })
    .then(response => response.getBusiness)
}

export default function () {
  const queryClient = useQueryClient()

  return useMutation(['createBusiness'], (business: BusinessFormData) => createBusiness(business), {
    onSuccess: () => {
      queryClient.invalidateQueries(['business'])
      queryClient.invalidateQueries(['getOrganizationsPaginated'])
    },
  })
}
