import { FEATURE_NAMES } from 'app/permissions/FeatureName.enum'
import { useFeatureRender } from 'app/permissions/useFeatureRender'
import { useGettingStartedChecklist } from 'app/user-journey/getting-started-checklist/useGettingStartedChecklist'
import { useInitialSetupFlow } from 'app/user-journey/initial-setup/useInitialSetupFlow'

const DoptSettings = () => {
  const { gettingStartedSettings } = useGettingStartedChecklist()
  const { initialSetupFlowSettings } = useInitialSetupFlow()
  const { checkFeature } = useFeatureRender()

  if (checkFeature(FEATURE_NAMES.USER_JOURNEY_READ)) {
    return null
  }

  return (
    <>
      <div className={'mt-2'}>{initialSetupFlowSettings}</div>
      <div className={'mt-2'}>{gettingStartedSettings}</div>
    </>
  )
}

export default DoptSettings
