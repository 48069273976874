import { useQuery } from '@tanstack/react-query'
import graphClient from 'app/query-client/graphClient'
import { ReportingGroup } from 'app/query-client/types'
import { gql } from 'graphql-request'

interface GetReportingGroupByIdRequest {
  id: string
  organizationId: string
}

const query = gql`
  query Request($id: ID!, $organizationId: ID!) {
    getReportingGroupById(id: $id, organizationId: $organizationId) {
      _id
      name
      created_at
      fishingPattern
      productConfig {
        _id
        environment
      }
      agentSettings {
        autoUpdateAgents
        collectEventLogs
        eventLogIds
        eventLogsRecurrence
        collectDisks
        collectServices
        collectPnPEvents
        collectPublicIP
        remoteSupport
        anyDeskSystemActionsRole
        collectDeviceLocation
      }
    }
  }
`

interface GetReportingGroupByIdResponse {
  getReportingGroupById: ReportingGroup
}

async function getReportingGroupById(request: GetReportingGroupByIdRequest) {
  const { getReportingGroupById }: GetReportingGroupByIdResponse = await graphClient.request(query, {
    id: request.id,
    organizationId: request.organizationId,
  })

  return getReportingGroupById
}

export default function (request: GetReportingGroupByIdRequest, shouldFetch: boolean) {
  return useQuery(['getReportingGroupById', request.id], () => getReportingGroupById(request), {
    refetchOnWindowFocus: false,
    enabled: shouldFetch,
  })
}
