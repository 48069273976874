import { useQuery } from '@tanstack/react-query'
import { gql } from 'graphql-request'
import graphClient from '../../query-client/graphClient'
import { PatchPolicy, PatchPolicyTarget, ReportingGroup } from 'app/query-client/types'

interface GetPatchPolicyTargetRequest {
  id: string
}

const getPatchPolicyTargetQuery = gql`
  query ($id: String!) {
    getPatchPolicyTarget(id: $id) {
      _id
      name
      organizationId
      createdBy {
        _id
        name
        surname
      }
      reportingGroups {
        _id
        name
      }
      createdAt
      updatedAt
      shouldRestart
      schedule
      weeks
      timezone
      maxSimultaneousWorkspaces
      isInMaintenance
      windowsPatchPolicyId
      linuxPatchPolicyId
      wakeOnLAN
      forcePatchingConfiguration
    }
  }
`

async function getPatchPolicyTarget(request: GetPatchPolicyTargetRequest): Promise<PatchPolicyTarget> {
  const { getPatchPolicyTarget } = await graphClient.request(getPatchPolicyTargetQuery, { id: request.id })
  return {
    ...getPatchPolicyTarget,
    schedule: JSON.parse(getPatchPolicyTarget.schedule),
  }
}

export default function (request: GetPatchPolicyTargetRequest, shouldFetch: boolean) {
  return useQuery(['getPatchPolicyTarget', request.id], () => getPatchPolicyTarget(request), {
    refetchOnWindowFocus: false,
    enabled: shouldFetch,
  })
}
