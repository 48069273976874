import { FormActionsContainer, LoadingSpinner } from '@flexxibleit/flexxible-ui'
import { DeleteActionButton } from '@flexxibleit/flexxible-ui';
import * as z from 'zod';
import { FormProvider, useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import RowColLayout from '../../../components/Layouts/RowColLayout'
import ControlledTextField from '../../../components/forms/TextField'
import { useTranslation } from 'react-i18next'
import FormActions from '../../../components/forms/FormActions'
import useCreateWorkspaceGroup from '../../../hooks/workspaceGroup/useCreateWorkspaceGroup'
import SuccessStatus from '../../../components/status/success-status'
import ErrorStatus from '../../../components/status/error-status'
import { ReactNode, useEffect, useState } from 'react'
import { WorkspaceGroup } from '../../../query-client/types'
import useUpdateWorkspaceGroup from '../../../hooks/workspaceGroup/useUpdateWorkspaceGroup'
import useDeleteWorkspaceGroup from '../../../hooks/workspaceGroup/useDeleteWorkspaceGroup'
import Dialog from '../../../components/Dialog/Dialog'
import { useNavigate } from 'react-router-dom'
import MultilineTextField from '../../../components/forms/MultilineTextField'
import { useFormSidebarContext } from '../../../components/forms/FormSidebar/state/FormSidebarState'
import { WorkspaceGroupType } from 'app/models/workspaces-groups/WorkspaceGroupType'

export interface WorkspaceGroupFormType {
  name: string
  description: string | undefined | null
}

export interface CreateOrUpdateWorkspaceGroupFormProps {
  workspaceGroup?: WorkspaceGroup
  organizationId?: string
}

const schema: z.ZodType<Partial<WorkspaceGroupFormType>> = z.object({
  name: z
    .string({
      required_error: 'required',
    })
    .min(1, { message: 'required' }),
  description: z.string().max(280, { message: 'max' }).optional().nullable(),
})

const WorkspaceGroupForm = ({ workspaceGroup, organizationId }: CreateOrUpdateWorkspaceGroupFormProps) => {
  const { t, i18n } = useTranslation('organization_details')
  const navigate = useNavigate()

  const methods = useForm<WorkspaceGroupFormType>({
    resolver: zodResolver(schema),
    defaultValues: { ...workspaceGroup },
  })

  const { closeSidebar } = useFormSidebarContext()

  const [isDeletionDialogVisibleState, setIsDeletionDialogVisibleState] = useState(false)
  const showDeletionDialog = () => setIsDeletionDialogVisibleState(true)
  const hideDeletionDialog = () => setIsDeletionDialogVisibleState(false)

  const createWorkspaceGroup = useCreateWorkspaceGroup(organizationId || '')
  const updateWorkspaceGroup = useUpdateWorkspaceGroup()
  const deleteWorkspaceGroup = useDeleteWorkspaceGroup()

  const [isSuccess, setIsSuccess] = useState(false)
  const [isError, setIsError] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    setIsSuccess(createWorkspaceGroup.isSuccess || updateWorkspaceGroup.isSuccess || deleteWorkspaceGroup.isSuccess)
    setIsError(createWorkspaceGroup.isError || updateWorkspaceGroup.isError || deleteWorkspaceGroup.isError)
    setIsLoading(createWorkspaceGroup.isLoading || updateWorkspaceGroup.isLoading || deleteWorkspaceGroup.isLoading)
  }, [createWorkspaceGroup, updateWorkspaceGroup, deleteWorkspaceGroup])

  const handleSubmit = (data: WorkspaceGroupFormType) => {
    if (workspaceGroup) {
      updateWorkspaceGroup.mutate({
        workspaceGroupId: workspaceGroup._id,
        input: data,
      })
    } else if (organizationId) {
      createWorkspaceGroup.mutate(data)
    }
  }

  const renderForm = (): ReactNode => {
    return (
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(handleSubmit)}>
          <RowColLayout rowProps={{ classNames: 'mt-2' }}>
            <ControlledTextField
              name="name"
              required={true}
              translator={t}
              textFieldProps={{
                label: t('WORKSPACE_GROUPS.FORM.NAME'),
                placeholder: t('WORKSPACE_GROUPS.FORM.ENTER_TEXT'),
                disabled: workspaceGroup && workspaceGroup.type === WorkspaceGroupType.DYNAMIC ? true : false,
              }}
            />
          </RowColLayout>
          <RowColLayout rowProps={{ classNames: 'mt-2' }}>
            <MultilineTextField
              name="description"
              required={false}
              translator={t}
              textFieldProps={{
                label: t('WORKSPACE_GROUPS.FORM.DESCRIPTION'),
                placeholder: t('WORKSPACE_GROUPS.FORM.ENTER_TEXT'),
                disabled: workspaceGroup && workspaceGroup.type === WorkspaceGroupType.DYNAMIC ? true : false,
              }}
            />
          </RowColLayout>
          {renderFormActionButtons()}
        </form>
      </FormProvider>
    )
  }

  const renderFormActionButtons = (): ReactNode => {
    if (workspaceGroup) {
      return (
        <FormActionsContainer isEdit>
          <DeleteActionButton locale={i18n.language} onClick={showDeletionDialog} />
          <FormActions
            submitProps={{
              text: t('general:BUTTON.SAVE'),
              iconProps: { iconName: 'Save' },
              disabled: workspaceGroup && workspaceGroup.type === WorkspaceGroupType.DYNAMIC,
            }}
          />
        </FormActionsContainer>
      )
    }

    return (
      <FormActionsContainer>
        <FormActions
          submitProps={{
            text: t('general:BUTTON.CREATE'),
            iconProps: { iconName: 'Add' },
          }}
        />
      </FormActionsContainer>
    )
  }

  const renderResultPanel = (): ReactNode => {
    return (
      <>
        {createWorkspaceGroup.isSuccess && <SuccessStatus message={t('WORKSPACE_GROUPS.FORM.ADD_SUCCESS')} />}
        {createWorkspaceGroup.isError && renderWorkspaceGroupCreationError(createWorkspaceGroup.error)}
        {updateWorkspaceGroup.isSuccess && <SuccessStatus message={t('WORKSPACE_GROUPS.FORM.EDIT_SUCCESS')} />}
        {updateWorkspaceGroup.isError && renderWorkspaceGroupUpdateError(updateWorkspaceGroup.error)}
      </>
    )
  }

  const renderWorkspaceGroupCreationError = (error: any): ReactNode => {
    const errorMessage = error.response.errors[0].message.includes('Workspace group name already in use')
      ? 'WORKSPACE_GROUPS.FORM.NAME_ALREADY_EXISTS'
      : 'WORKSPACE_GROUPS.FORM.ADD_ERROR'

    return <ErrorStatus message={t(errorMessage)} />
  }

  const renderWorkspaceGroupUpdateError = (error: any): ReactNode => {
    const errorMessage = error.response.errors[0].message.includes('Workspace group name already in use')
      ? 'WORKSPACE_GROUPS.FORM.NAME_ALREADY_EXISTS'
      : 'WORKSPACE_GROUPS.FORM.EDIT_ERROR'

    return <ErrorStatus message={t(errorMessage)} />
  }

  const renderDeletionDialog = (): ReactNode => {
    return (
      <Dialog
        title={t('WORKSPACE_GROUPS.FORM.DELETION_CONFIRMATION_TITLE')}
        description={t('WORKSPACE_GROUPS.FORM.DELETION_CONFIRMATION_MESSAGE')}
        actionButton={t('general:BUTTON.DELETE')}
        dismissButton={t('general:BUTTON.CANCEL')}
        callback={() => {
          if (workspaceGroup) {
            hideDeletionDialog()
            deleteWorkspaceGroup.mutate(workspaceGroup._id)
            closeSidebar()
            navigate(-1)
          }
        }}
        hidden={!isDeletionDialogVisibleState}
        onDismiss={hideDeletionDialog}
      />
    )
  }

  return (
    <>
      {!isLoading && !isSuccess && !isError && renderForm()}

      {isLoading && <LoadingSpinner></LoadingSpinner>}

      {(isSuccess || isError) && renderResultPanel()}

      {renderDeletionDialog()}
    </>
  )
}

export default WorkspaceGroupForm;
