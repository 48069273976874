import { useMutation, useQueryClient } from "@tanstack/react-query";
import { gql } from "graphql-request";
import graphClient from "../../query-client/graphClient";


interface CreateElegibleProductInput {
  elegibleProductId: string
  deploymentRegions?: string[] | null
}

const createElegibleQuery = gql`
  mutation Request($organizationId: ID!, $input: CreateElegibleProductInput!) {
    createElegibleProduct(organizationId: $organizationId, input: $input) {
      _id
    }
  }
`

function createElegibleProduct(input: CreateElegibleProductInput, organizationId: string) {
  return graphClient
    .request(createElegibleQuery, {
      organizationId: organizationId,
      input,
    })
    .then((response) => response.createElegibleProduct)
}

export default function (organizationId: string) {
  const queryClient = useQueryClient()

  return useMutation(
    ['createElegibleProduct'],
    (input: CreateElegibleProductInput) => createElegibleProduct(input, organizationId),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['getBusinessById'])
        queryClient.invalidateQueries(['getBusinessById', 'elegibleProducts'])
      },
    }
  )
}
