import PageBody from 'app/components/PageBody/PageBody.component'
import { useTranslation } from 'react-i18next'
import { FlexxBuildOperationsLogTable } from './FlexxBuildOperationsLogTable'
import { withFeatureRender } from 'app/permissions/withFeatureRender'
import { FEATURE_NAMES } from 'app/permissions/FeatureName.enum'

const FlexxBuildOperationsLog = () => {
  const { t } = useTranslation('flexxbuild')

  return (
    <PageBody isLoading={false} title={t('TABLE.TITLE', 'FlexxBuild Operations')}>
      <FlexxBuildOperationsLogTable />
    </PageBody>
  )
}

export const FlexxBuildOperationsLogPage = withFeatureRender(
  FlexxBuildOperationsLog,
  FEATURE_NAMES.BUILD_OPERATIONS_LOG_READ
)
