import { gql } from 'graphql-request'
import { ApiKey } from '../../query-client/types'
import graphClient from '../../query-client/graphClient'
import { useMutation, useQueryClient } from '@tanstack/react-query'

const revokeApiKeyQuery = gql`
mutation($apiKeyId: String!) {
  revokeApiKey(apiKeyId: $apiKeyId) {
    _id
  }
}
`

const revokeApiKey = (apiKeyId: string): Promise<Partial<ApiKey>> => {
  return graphClient
    .request(revokeApiKeyQuery, { apiKeyId })
    .then((response) => response.revokeApiKey)
}

export const useRevokeApiKey = (organizationId: string) => {
  const queryClient = useQueryClient()

  return useMutation(
    ['revokeApiKey'],
    (apiKeyId: string) => revokeApiKey(apiKeyId),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['getApiKeysByBusiness', organizationId])
      }
    }
  )
}
