import { InstalledAppsPage } from './pages/installed-apps/InstalledAppsPage'
import { InstalledAppDetailsPage } from './pages/installed-apps/details/InstalledAppDetailsPage'

interface Route {
  path: string
  component: () => JSX.Element
}

export const analyzerRoutes: Array<Route> = [
  {
    path: '/analyzer/installed-apps',
    component: InstalledAppsPage,
  },
  {
    path: '/analyzer/installed-apps/:installedAppId',
    component: InstalledAppDetailsPage
  }
]
