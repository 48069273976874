import { CancelButton } from '@flexxibleit/flexxible-ui'
import { MessageBar, PrimaryButton } from '@fluentui/react'
import { useBoolean } from '@fluentui/react-hooks'
import Dialog from 'app/components/Dialog/Dialog'
import { useMicroserviceTabs } from 'app/context/MicroserviceTabsContext'
import { useUpdateMicroserviceReadme } from 'app/hooks/microservices/useUpdateMicroserviceReadme'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import EditButton from '../../../../components/Buttons/EditButton.component'
import FlexxibleCard from '../../../../components/FlexxibleCard/FlexxibleCard.component'
import { MarkdownEditor } from '../../../../components/MarkdownEditor/MarkdownEditor'
import { useFeedbackMessage } from '../../../../context/feedback-message/FeedbackMessageContext'

interface Props {
  microservice: {
    _id: string
    readme?: string
  },
  canEdit: boolean
}

export const MicroserviceDetailsReadme = ({
  microservice,
  canEdit,
}: Props) => {
  const tabsState = useMicroserviceTabs()
  const state = tabsState.state.overview
  const updateMicroservice = useUpdateMicroserviceReadme()
  const [editing, setEditing] = useState(!!state)
  const [editedValue, setEditedValue] = useState(state ? state.text : microservice.readme ?? '')
  const { t, i18n } = useTranslation('microservices')
  const [hideDialog, { toggle: toggleHideDialog }] = useBoolean(true)
  const { setSuccessMessage, setErrorMessage } = useFeedbackMessage()

  const cardProps = {
    style: { marginTop: 30 },
  }

  const submit = (value: string) => {
    updateMicroservice
      .mutateAsync({
        microserviceId: microservice._id,
        input: { readme: value },
      })
      .then(() => setSuccessMessage(t('DETAILS.README.SUCCESS')))
      .catch(() => setErrorMessage(t('DETAILS.README.ERROR')))
  }

  const renderCancelEditDialog = (): JSX.Element => {
    return (
      <Dialog
        title={t('DETAILS.README.CANCEL_EDIT_DIALOG.TITLE')}
        description={t('DETAILS.README.CANCEL_EDIT_DIALOG.DESCRIPTION')}
        actionButton={t('DETAILS.README.CANCEL_EDIT_DIALOG.BUTTON_ACCEPT')}
        dismissButton={t('DETAILS.README.CANCEL_EDIT_DIALOG.BUTTON_CANCEL')}
        hidden={hideDialog}
        onDismiss={toggleHideDialog}
        callback={() => {
          toggleHideDialog()
          setEditing(false)
          setEditedValue(microservice.readme ?? '')
        }}
      >
        <MessageBar
          messageBarType={3}
          isMultiline={true}
          dismissButtonAriaLabel="Close"
          className="mb-2"
          style={{ width: '100%' }}
        >
          {t('DETAILS.SCRIPT.CANCEL_EDIT_DIALOG.WARNING')}
        </MessageBar>
      </Dialog>
    )
  }

  const handleOnCancel = () => {
    const initialValue = microservice.readme ?? ''
    const isDirty = initialValue !== editedValue

    if (isDirty) {
      toggleHideDialog()
    } else {
      setEditing(false)
      setEditedValue(microservice.readme ?? '')
    }
  }

  const editionButtons = () =>
    editing ? (
      <>
        <PrimaryButton
          text={t('general:BUTTON.SAVE')}
          onClick={() => {
            setEditing(false)
            submit(editedValue)
          }}
        />
        <CancelButton locale={i18n.language} onClick={handleOnCancel} style={{ marginRight: '20px' }} />
      </>
    ) : (
      <EditButton onClick={() => setEditing(true)} />
    )

  useEffect(() => {
    if (!editing) {
      const { overview, ...newState } = { ...tabsState.state }

      return tabsState.setState(newState)
    }

    tabsState.setState({
      ...tabsState.state,
      overview: {
        text: editedValue,
      },
    })
  }, [editing, editedValue])

  return (
    <FlexxibleCard cardProps={cardProps} styleNames="col-md-12 d-flex d-flexCol d-flexJustifyBetween">
      <MarkdownEditor value={editedValue} onChange={(value) => setEditedValue(value)} readonly={!editing} />
      <div className="d-flex d-flexRowReverse">{canEdit && editionButtons()}</div>
      {renderCancelEditDialog()}
    </FlexxibleCard>
  )
}
