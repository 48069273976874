import { useQuery } from '@tanstack/react-query'
import { PatchSummary } from '../entities/PatchSummary'
import { gql } from 'graphql-request'
import graphClient from '../../../../query-client/graphClient'

const summaryQuery = gql`
  query ($organizationId: ID!, $organizationIdString: String!) {
    getBusinessById(id: $organizationId) {
      reportingGroups {
        patchPolicyTargetId
      }
    }
    getPatchPolicyTargetsByOrganization(organizationId: $organizationId) {
      _id
      schedule
      windowsPatchPolicyId
    }
    getPatchManagementDashboard(organizationId: $organizationIdString) {
      securityPatchesBySeverityAndStatus {
        severity
        status
        value
      }
    }
  }
`

const workspacesWithoutReportingGroupRequest = gql`
  query ($organizationId: String!) {
    getWorkspacesByBusiness(businessId: $organizationId, reportingGroupsIds: []) {
      pageInfo {
        total
      }
    }
  }
`

const workspacesWithPendingPatchesRequest = gql`
  query ($organizationId: String!) {
    getWorkspacesByBusiness(businessId: $organizationId, minPendingPatches: 1) {
      pageInfo {
        total
      }
    }
  }
`

const totalWorkspacesRequest = gql`
  query ($organizationId: String!) {
    getWorkspacesByBusiness(businessId: $organizationId) {
      pageInfo {
        total
      }
    }
  }
`

const getPatchSummaryRequest = async (): Promise<PatchSummary> => {
  const organizationId = window.location.pathname.split('/')[2]

  if (!organizationId) {
    throw new Error('Organization not found')
  }

  const response = await graphClient.request(summaryQuery, {
    organizationId,
    organizationIdString: organizationId,
  })

  const workspacesWithoutReportingGroupResponse = await graphClient.request(workspacesWithoutReportingGroupRequest, {
    organizationId,
  })
  const workspacesWithoutReportingGroup = workspacesWithoutReportingGroupResponse.getWorkspacesByBusiness.pageInfo.total

  const workspacesWithPendingPatchesResponse = await graphClient.request(workspacesWithPendingPatchesRequest, {
    organizationId,
  })
  const workspacesWithPendingPatches = workspacesWithPendingPatchesResponse.getWorkspacesByBusiness.pageInfo.total

  const totalWorkspacesResponse = await graphClient.request(totalWorkspacesRequest, {
    organizationId,
  })
  const totalWorkspaces = totalWorkspacesResponse.getWorkspacesByBusiness.pageInfo.total

  const reportingGroupsWithoutPatchPolicyTarget: number = response.getBusinessById.reportingGroups.filter(
    (reportingGroup: { patchPolicyTargetId: string }) => !reportingGroup.patchPolicyTargetId
  ).length

  return new PatchSummary({
    reportingGroupsWithoutPatchPolicyTarget: reportingGroupsWithoutPatchPolicyTarget,
    targets: response.getPatchPolicyTargetsByOrganization.map((target: any) => {
      return {
        ...target,
        schedule: JSON.parse(target.schedule),
      }
    }),
    patchesBySeverityAndStatus: response.getPatchManagementDashboard.securityPatchesBySeverityAndStatus,
    workspacesCount: {
      total: totalWorkspaces,
      totalWorkspacesWithoutReportingGroup: workspacesWithoutReportingGroup,
      totalWorkspacesWithPendingPatches: workspacesWithPendingPatches,
    },
  })
}

export const usePatchManagementSummaryData = () => {
  return useQuery(['patchManagementSummary'], getPatchSummaryRequest)
}
