import { useMutation, useQueryClient } from '@tanstack/react-query'
import graphClient from 'app/query-client/graphClient'
import { gql } from 'graphql-request'

interface Request {
  name: string
  organizationId: string
  policyId: string
}

const query = gql`
  mutation createPolicyReportingGroup($input: CreatePolicyReportingGroupGraphqlRequest!) {
    createPolicyReportingGroup(input: $input) {
      _id
    }
  }
`

interface Response {
  createPolicyReportingGroup: {
    _id: string
  }
}

async function createPolicyReportingGroup(request: Request) {
  const { createPolicyReportingGroup }: Response = await graphClient.request(query, {
    input: request,
  })

  return {
    reportingGroupId: createPolicyReportingGroup._id,
    policyId: request.policyId,
  }
}

export default function () {
  const queryClient = useQueryClient()

  return useMutation(['createPolicyReportingGroup'], (request: Request) => createPolicyReportingGroup(request), {
    onSuccess: ({ policyId }) => {
      queryClient.invalidateQueries(['getTenantPolicy', policyId])
    },
  })
}
