import { WorkspacePermissions } from 'app/permissions/UserWorkspacePermission.enum'
import { TFunction } from 'i18next'

export const anyDeskActionsOptions = (t: TFunction): { key: string; text: string }[] => {
  return [
    { key: '', text: t('general:NONE') },
    { key: WorkspacePermissions.ADMIN, text: t('AGENT_SETTINGS.FORM.ANYDESK_ACTIONS_ADMIN') },
    { key: WorkspacePermissions.L1_SUPPORT_TEAM, text: t('AGENT_SETTINGS.FORM.ANYDESK_ACTIONS_L1') },
    { key: WorkspacePermissions.L2_SUPPORT_TEAM, text: t('AGENT_SETTINGS.FORM.ANYDESK_ACTIONS_L2') },
    { key: WorkspacePermissions.L3_ENGINEERING_TEAM, text: t('AGENT_SETTINGS.FORM.ANYDESK_ACTIONS_L3') },
  ]
}
