import { useMutation, useQueryClient } from '@tanstack/react-query'
import { WorkspaceGroupFormType } from '../../pages/organization-details/workspace-groups/WorkspaceGroupForm'
import graphClient from '../../query-client/graphClient'
import { gql } from 'graphql-request'

const updateWorkspaceGroupQuery = gql`
  mutation ($input: WorkspaceGroupBaseUpdateInput!, $workspaceGroupId: ID!) {
    updateWorkspaceGroup(input: $input, id: $workspaceGroupId) {
      _id
      name
      description
    }
  }
`

function updateWorkspaceGroup(workspaceGroupId: string, input: WorkspaceGroupFormType) {
  return graphClient
    .request(updateWorkspaceGroupQuery, {
      workspaceGroupId,
      input,
    })
    .then((response) => response.createWorkspaceGroup)
}

export default function () {
  const queryClient = useQueryClient()

  return useMutation(
    ['updateWorkspaceGroup'],
    ({ workspaceGroupId, input }: { workspaceGroupId: string; input: WorkspaceGroupFormType }) =>
      updateWorkspaceGroup(workspaceGroupId, input),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['getWorkspaceGroupsByBusinessPaginated'])
        queryClient.invalidateQueries(['getWorkspaceGroupById'])
      },
    }
  )
}
