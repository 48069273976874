import useMediaQuery from 'app/hooks/utils/useMediaQuery'
import { useTranslation } from 'react-i18next'
import { ContentHeader } from '../../../components/content-header/content-header'
import { MOBILE_QUERY } from '../../../config/media-query'
import { Business } from '../../../query-client/types'
import { ApiKeysFeature } from '../api-keys/apiKeys.feature'
import EntraId from '../azure-active-directory/EntraId'

interface Props {
  organization: Business
}

const IntegrationsFeature = ({ organization }: Props) => {
  const { t } = useTranslation('organization_details')
  const [isMobile] = useMediaQuery(MOBILE_QUERY)

  return (
    <>
      <div style={{ marginTop: 20, marginLeft: 10, marginBottom: isMobile ? -50 : -70 }}>
        <ContentHeader title={t('API_KEYS_PIVOT')} />
      </div>
      <ApiKeysFeature organizationId={organization._id} />

      <div style={{ marginTop: 40, marginLeft: 10 }}>
        <ContentHeader title={t('AZURE_ACTIVE_DIRECTORY_PIVOT')} />
      </div>
      <EntraId organization={organization} />
    </>
  )
}

export default IntegrationsFeature
