import { Dispatch, SetStateAction } from 'react';
import { Selection } from '@fluentui/react'

export const usePaginatedSelection = <T>(
  getKey: (item: T, index?: number) => string | number,
  setSelectedItemsState: Dispatch<SetStateAction<T[]>>,
): Selection<T> => {

  // @ts-ignore
  const selection = new Selection<T>({
    getKey,
    onSelectionChanged: () => {
      setSelectedItemsFromPage(selection.getItems(), selection.getSelection())
    },
    onItemsChanged: () => {
      retrieveAlreadySelectedItemsFromPage(selection.getItems());
    },
  });

  const setSelectedItemsFromPage = (
    itemsInPage: T[],
    itemSelectionFromPage: T[],
  ) => {
    setSelectedItemsState(
      (prevState) => {
        const selectedItemsFromOtherPages = prevState.filter((item) => {
          return !itemsInPage
            .map((itemInPage) => selection.getKey(itemInPage))
            .includes(selection.getKey(item));
        });

        return [ ...selectedItemsFromOtherPages, ...itemSelectionFromPage ];
      }
    );
  }

  const retrieveAlreadySelectedItemsFromPage = (currentPageItems: T[]) => {
    setSelectedItemsState(
      (prevState) => {
        const selectedItemsFromPage = currentPageItems.filter((currentPageItem) => {
          return prevState
            .map((selectedItem) => selection.getKey(selectedItem))
            .includes(selection.getKey(currentPageItem));
        });

        selectedItemsFromPage.forEach((item) => {
          selection.setKeySelected(selection.getKey(item), true, false);
        });

        return prevState;
      }
    );
  }

  return selection;
}
