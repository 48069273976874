import { Col, EditButton, MOBILE_QUERY, Row, TABLET_QUERY, useMediaQuery } from '@flexxibleit/flexxible-ui'
import { DisplayField } from 'app/components/DisplayField'
import FlexxibleCard from 'app/components/FlexxibleCard/FlexxibleCard.component'
import { TenantPolicyView } from 'app/hooks/policies/useGetPolicy'
import useCreateForm from 'app/hooks/useCreateForm'
import { dateFormatter } from 'app/services/date-formatter'
import { useTranslation } from 'react-i18next'
import DataTableIcon from '../../../../../assets/img/data-table-svgrepo-com.svg'
import LayersIcon from '../../../../../assets/img/layers-svgrepo-com.svg'
import SummaryCard from '../../../components/SummaryCard/SummaryCard'
import { PolicyInfoForm } from './PolicyInfoForm'
import useGetProductTypes from 'app/hooks/product/useGetProductTypes'
import { useEffect, useState } from 'react'

const cardProps = {
  style: { marginTop: 30 },
}

interface Props {
  data: TenantPolicyView
}

export const PolicyDetails = ({ data }: Props) => {
  const { t } = useTranslation('organization_details')
  const [isMobile] = useMediaQuery(MOBILE_QUERY)
  const [isTablet] = useMediaQuery(TABLET_QUERY)
  const isDesktop = !isMobile && !isTablet
  const { data: productData } = useGetProductTypes()
  const [productName, setProductName] = useState('')

  useEffect(() => {
    if (productData) {
      const product = productData.find((p) => p._id === data.product)
      if (product) {
        setProductName(product.name)
      }
    }
  }, [productData])

  const onEdit = useCreateForm(
    {
      title: t('POLICIES.FORM.EDIT_TITLE'),
      isFooterAtBottom: true,
    },
    <PolicyInfoForm
      id={data._id}
      organizationId={data.organizationId}
      data={{
        name: data.name,
        description: data.description,
        product: data.product,
        accessAllUsers: data.accessAllUsers ?? false,
      }}
    />
  )

  return (
    <div
      className={!isDesktop ? 'd-flex d-flexCol d-flexColReverse' : 'd-flex d-flexRow col-xs-12'}
      style={!isDesktop ? { gap: 0 } : { gap: 30, paddingRight: 20 }}
    >
      <FlexxibleCard cardProps={cardProps} styleNames="col-md-9 d-flex d-flexCol d-flexJustifyBetween">
        <Row>
          <Col className="col-xs-12 col-lg-6">
            <DisplayField label={t('POLICIES.TABLE.NAME')}>{data.name}</DisplayField>
          </Col>
        </Row>
        <Row>
          <Col className="col-xs-12 col-lg-6">
            <DisplayField label={t('POLICIES.TABLE.DESCRIPTION')}>{data.description}</DisplayField>
          </Col>
        </Row>
        <Row>
          <Col className="col-xs-6 col-lg-6">
            <DisplayField label={t('POLICIES.TABLE.PRODUCT')}>{productName}</DisplayField>
          </Col>
          <Col className="col-xs-6 col-lg-6">
            <DisplayField label={t('POLICIES.TABLE.ACCESS_ALL_USERS')}>
              {data.accessAllUsers ? 'Yes' : 'No'}
            </DisplayField>
          </Col>
        </Row>

        <div className="d-flex d-flexRowReverse">
          <EditButton translator={t} onClick={onEdit} />
        </div>
      </FlexxibleCard>

      <div className="col-md-3 summary-container d-flexJustifyCenter d-flexAlignItemsCenter">
        <>
          <div
            className={isMobile || isTablet ? 'd-flex d-flexRow d-flexWrap' : 'd-flex d-flexCol '}
            style={{ gap: 20 }}
          >
            <SummaryCard
              Icon={LayersIcon}
              title={t('POLICIES.SUMMARY.NUMBER_OR_ORGANIZATIONS')}
              text={`${data.tenants.length}`}
            />

            <SummaryCard
              Icon={DataTableIcon}
              title={t('POLICIES.SUMMARY.CREATION_DATE')}
              text={data.created_at ? dateFormatter(t, data.created_at) : ''}
            />
          </div>
        </>
      </div>
    </div>
  )
}
