import { ErrorStatus, FormActionsContainer, LoadingSpinner, SuccessStatus } from '@flexxibleit/flexxible-ui'
import * as z from 'zod'
import { FormProvider, useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import RowColLayout from '../../../components/Layouts/RowColLayout'
import { useTranslation } from 'react-i18next'
import FormActions from '../../../components/forms/FormActions'
import { ReactNode, useEffect, useState } from 'react'
import FileInput from 'app/components/forms/FileInput'
import {
  DetailsList,
  IColumn,
  ILabelProps,
  Label,
  MessageBar,
  MessageBarType,
  Pivot,
  PivotItem,
  SelectionMode,
} from '@fluentui/react'
import useImportUsersByOrganization, {
  errorsMessagesI18nMap,
  importActionsI18nMap,
} from 'app/hooks/users/useImportUsersByOrganization'
import { useParams } from 'react-router-dom'
import { StringParam, useQueryParam } from 'use-query-params'
import * as Excel from 'exceljs'
import WarningStatus from '../../../components/status/warning-status'

export interface ImportUsersFormType {
  usersList: AnyObject[]
}

export interface ImportUsersInputType {
  usersList: AnyObject[]
}

export interface ImportWorkspacesFormProps {
  workspaceGroupId: string
}

export interface WorkspaceGroupId {
  _id: string
}

export enum requiredXLSXColumns {
  name = 'name',
  surname = 'surname',
  email = 'email',
  role = 'role',
}

export interface ImportUsersResult {
  createdSuccess: string[]
  updatedSuccess: string[]
  deletedSuccess: string[]
  organization: string
  errors: { email: string; message: string }[]
  info: { email: string; message: string }[]
}

type AnyObject = {
  [key: string]: any
}

const labelTitleProperties: ILabelProps = {
  styles: {
    root: {
      fontWeight: 600,
      fontSize: 18,
      color: '#8e8e93',
      textAlign: 'center',
      marginTop: 10,
      marginBottom: 10,
    },
  },
}

const userFieldSchema = z.object({
  action: z.string(),
  name: z.string(),
  surname: z.string(),
  email: z.string(),
  role: z.string(),
  department: z.string(),
})

const schema: z.ZodType<Partial<ImportUsersFormType>> = z.object({
  usersList: z.array(userFieldSchema),
})

const ImportUsersForm = () => {
  const { t, i18n } = useTranslation('organization_details')

  const methods = useForm<ImportUsersFormType>({
    resolver: zodResolver(schema),
    defaultValues: { usersList: [] },
  })

  const { organizationId } = useParams()
  const [processedData, setProcessedData] = useState<AnyObject[]>([])
  const [duplicated, setDuplicated] = useState<string[]>([])
  const [isSuccess, setIsSuccess] = useState(false)
  const [isError, setIsError] = useState(false)
  const [isProccessError, setIsProccessError] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [isProcessingFile, setIsProcessingFile] = useState(false)
  const [xslxColumnsError, setXslxColumnsError] = useState<string[]>([])
  const importUsersByOrganization = useImportUsersByOrganization(organizationId)
  const [resultImport, setResultImport] = useState<ImportUsersResult | null>(null)
  const [resultHasChanges, setResultHasChanges] = useState<boolean | null>(null)
  const [resultHasErrors, setResultHasErrors] = useState<boolean | null>(null)
  const [tabQueryParameter, setTabQueryParameter] = useQueryParam('filter', StringParam)

  useEffect(() => {
    let hasChanges = false;
    let hasErrors = false;
    if (importUsersByOrganization) {
      setIsSuccess(importUsersByOrganization.isSuccess)
      setIsError(importUsersByOrganization.isError)
      setIsLoading(importUsersByOrganization.isLoading)
      setResultImport(importUsersByOrganization.data)
      if (importUsersByOrganization.isSuccess && (
        importUsersByOrganization.data.createdSuccess.length > 0 ||
        importUsersByOrganization.data.updatedSuccess.length > 0 ||
        importUsersByOrganization.data.deletedSuccess.length > 0 )
      ) {
        hasChanges = true;
      }
      if (importUsersByOrganization.isSuccess && importUsersByOrganization.data.errors.length > 0) {
        hasErrors = true;
      }
    }

    setResultHasChanges(hasChanges)
    setResultHasErrors(hasErrors)
  }, [
    importUsersByOrganization?.isSuccess,
    importUsersByOrganization?.isError,
    importUsersByOrganization?.isLoading,
    importUsersByOrganization?.data,
  ])

  useEffect(() => {
    setTabQueryParameter('info')
  }, [])

  const _handleFilterChange = (item?: PivotItem) => {
    setTabQueryParameter(item?.props.itemKey)
  }
  const handleSubmit = (data: ImportUsersFormType) => {
    if (processedData.length > 0 && importUsersByOrganization) {
      importUsersByOrganization.mutate({ usersList: processedData })
    }
  }

  const processXLSX = (jsonObject: any) => {
    const columnsErrors: string[] = []
    const expectedKeysSet = new Set<string>(Object.values(requiredXLSXColumns).map((key) => key.toLowerCase()))
    const objectKeysSet = new Set<string>(Object.keys(jsonObject[0]).map((key) => key.toLowerCase()))

    expectedKeysSet.forEach((key) => {
      if (!objectKeysSet.has(key)) {
        const textError = `${t('USERS.TABLE.IMPORT_COLUMN')} '${key}' ${t('USERS.TABLE.NOT_FOUND_XLSX')} `
        columnsErrors.push(textError)
      }
    })
    if (columnsErrors.length > 0) {
      setXslxColumnsError(columnsErrors)
      return
    }
    const emailFrequencies: { [email: string]: number } = {}
    const arrayWithKeysLowerCase: AnyObject[] = []
    const duplicatedEmailsSet = new Set<string>()

    jsonObject.forEach((obj: any) => {
      const newObj: AnyObject = {}
      Object.keys(obj).forEach((key) => {
        const newKey = key.replace(/\s+/g, '_').toLowerCase()

        if (newKey === 'email') {
          if (!obj[key]) return
        }
        if (newKey === 'action') {
          if (!obj[key] || (obj[key].toLowerCase() !== 'add/update' && obj[key].toLowerCase() !== 'delete')) return
          newObj[newKey] = obj[key]
        } else {
          newObj[newKey] = obj[key]
        }
      })

      if (!newObj.email) return
      if (newObj.email?.text) {
        newObj.email = newObj.email.text
      }
      const email = newObj.email?.toLowerCase().replace(/\s+$/, '')
      if (emailFrequencies[email]) {
        emailFrequencies[email] += 1
        duplicatedEmailsSet.add(email)
      } else {
        emailFrequencies[email] = 1
        arrayWithKeysLowerCase.push(newObj)
      }
    })

    setDuplicated(Array.from(duplicatedEmailsSet))

    setProcessedData(arrayWithKeysLowerCase)

    return arrayWithKeysLowerCase
  }

  const handleFileRead = async (file: File) => {
    setIsProcessingFile(true)
    setIsProccessError(false)
    try {
      const reader = new FileReader()

      reader.onload = async (event) => {
        const arrayBuffer = event.target?.result
        const jsonObject = await readExcelFile(arrayBuffer)
        if (jsonObject.length > 0) {
          try {
            processXLSX(jsonObject)
          } catch (error) {
            setIsProccessError(true)
            setXslxColumnsError(['Error processing file'])
            console.error('Error processing file:', error)
          }
        } else {
          setIsProccessError(true)
          setXslxColumnsError(['No entries found'])
          console.error('Error processing file: No entries found')
        }
      }

      reader.onerror = (error) => {
        console.error('Error reading file:', error)
        setXslxColumnsError(['Error processing file'])
        setIsProccessError(true)
      }

      reader.readAsArrayBuffer(file)
    } catch (error) {
      console.error('Error processing file:', error)
      setXslxColumnsError(['Error processing file'])
      setIsProccessError(true)
    }
    setIsProcessingFile(false)
  }

  async function readExcelFile(arrayBuffer: any) {
    let workbook = new Excel.Workbook()

    await workbook.xlsx.load(arrayBuffer)

    const worksheet = workbook.getWorksheet(1)

    let jsonObject: any[] = []
    if (worksheet) {
      worksheet.eachRow({ includeEmpty: true }, (row, rowNumber) => {
        if (rowNumber === 1) {
          return
        }

        let rowData: Record<string, any> = {}
        row.eachCell({ includeEmpty: true }, (cell, colNumber) => {
          let header = worksheet.getRow(1).getCell(colNumber).value
          let headerString = typeof header === 'string' ? header : header ? header.toString() : `Column${colNumber}`

          rowData[headerString] = cell.value instanceof Date ? cell.value.toISOString() : cell.value
        })
        jsonObject.push(rowData)
      })
    }

    return jsonObject
  }

  const handleOnChange = (event: React.ChangeEvent<HTMLInputElement> | null) => {
    setProcessedData([])
    setXslxColumnsError([])

    const file = event?.target.files?.[0]
    if (file) {
      handleFileRead(file)
    }
  }

  const renderForm = (): ReactNode => {
    return (
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(handleSubmit)}>
          <RowColLayout rowProps={{ classNames: 'mt-2' }}>
            <FileInput
              name="usersList"
              translator={t}
              allowedExtensions={['xls', 'xlsx']}
              onChange={handleOnChange}
              setErrors={xslxColumnsError}
            />
          </RowColLayout>

          {renderFormActionButtons()}
        </form>
      </FormProvider>
    )
  }

  const renderFormActionButtons = (): ReactNode => {
    return (
      <FormActionsContainer>
        <FormActions
          submitProps={{
            text: t('general:BUTTON.IMPORT'),
            iconProps: { iconName: 'Add' },
            disabled: isProccessError || processedData?.length === 0,
          }}
        />
      </FormActionsContainer>
    )
  }

  const renderDuplicatedEmails = (): ReactNode => {
    return (
      <>
        {duplicated.map((email, index) => (
          <MessageBar key={index} messageBarType={MessageBarType.warning} isMultiline={false} className="mb-2 mt-2">
            {`${email} ${t('general:IS_DUPLICATED')}`}
          </MessageBar>
        ))}
      </>
    )
  }

  const renderImportPanel = (): ReactNode => {
    const columns: IColumn[] = [
      {
        key: 'action',
        name: t('USERS.TABLE.ACTION'),
        fieldName: 'action',
        minWidth: 80,
        isResizable: true,
      },
      {
        key: 'name',
        name: t('USERS.TABLE.IMPORT_NAME'),
        fieldName: 'name',
        minWidth: 80,
        isResizable: true,
      },
      {
        key: 'surname',
        name: t('USERS.TABLE.IMPORT_SURNAME'),
        fieldName: 'surname',
        minWidth: 80,
        isResizable: true,
      },
      {
        key: 'email',
        name: t('USERS.TABLE.EMAIL'),
        fieldName: 'email',
        minWidth: 150,
        isRowHeader: true,
        isResizable: true,
      },
      {
        key: 'role',
        name: t('USERS.TABLE.USER_ROLE'),
        fieldName: 'role',
        minWidth: 150,
      },
      {
        key: 'department',
        name: t('USERS.TABLE.DEPARTMENT'),
        fieldName: 'department',
        minWidth: 150,
      },
      {
        key: 'language',
        name: t('USERS.TABLE.LANGUAGE'),
        fieldName: 'language',
        minWidth: 150,
      },
    ]

    return (
      <>
        {processedData.length > 0 && (
          <div>
            <Label {...labelTitleProperties}>{t('USERS.TABLE.IMPORT_SUMMARY')}</Label>
            <div style={{ maxHeight: '400px', overflowY: 'auto' }}>
              <DetailsList columns={columns} items={processedData} selectionMode={SelectionMode.none} flexMargin={1} />
            </div>
            {renderDuplicatedEmails()}
          </div>
        )}
      </>
    )
  }

  const renderResultPanel = (): ReactNode => {
    const successColumns: IColumn[] = [
      {
        key: 'email',
        name: t('USERS.TABLE.EMAIL'),
        fieldName: 'email',
        minWidth: 100,
        isRowHeader: true,
        isResizable: true,
      },
    ]

    const errorsColumns: IColumn[] = [
      {
        key: 'email',
        name: t('USERS.TABLE.EMAIL'),
        fieldName: 'email',
        minWidth: 100,
        isRowHeader: true,
        isResizable: true,
        isPadded: true,
      },
      {
        key: 'action',
        name: t('USERS.TABLE.ACTION'),
        fieldName: 'action',
        minWidth: 40,
        isResizable: true,
        isPadded: true,
        onRender: (item: { action: string }) => {
          if (importActionsI18nMap[item.action]) {
            return t(importActionsI18nMap[item.action])
          }
          return item.action
        },
      },
      {
        key: 'message',
        name: t('USERS.TABLE.MESSAGE'),
        fieldName: 'message',
        minWidth: 200,
        isResizable: true,
        isPadded: true,
        onRender: (item: { message: string }) => {
          if (errorsMessagesI18nMap[item.message]) {
            return t(errorsMessagesI18nMap[item.message])
          }
          return item.message
        },
      },
    ]

    return (
      <>
        {importUsersByOrganization?.isSuccess && resultImport && (
          <>
            <div className="d-flex h-100" style={{ flexDirection: 'column', justifyContent: 'top' }}>
              <div style={{ marginTop: '30px', marginBottom: '30px' }}>
                {resultHasChanges && !resultHasErrors && (
                  <SuccessStatus message={t('USERS.FORM.IMPORT_SUCCESS')} />
                )}
                {(!resultHasChanges && !resultHasErrors) && (
                  <WarningStatus message={t('USERS.FORM.IMPORT_SUCCESS_WARNING_NO_CHANGES')} />
                )}
                {resultHasErrors && (
                  <WarningStatus message={t('USERS.FORM.IMPORT_SUCCESS_WARNING_ERRORS')} />
                )}
             
              </div>
              <div>
                <>
                  <Pivot overflowBehavior={"menu"} selectedKey={tabQueryParameter} onLinkClick={_handleFilterChange} headersOnly={true}>
                    <PivotItem
                      itemKey="info"
                      headerText={`${t('USERS.FORM.INFO_IMPORT')} (${resultImport.info.length})`}
                    />
                    <PivotItem
                      itemKey="created"
                      headerText={`${t('USERS.FORM.CREATED_SUCCESS')} (${resultImport.createdSuccess.length})`}
                    />
                    <PivotItem
                      itemKey="updated"
                      headerText={`${t('USERS.FORM.UPDATED_SUCCESS')} (${resultImport.updatedSuccess.length})`}
                    />
                    <PivotItem
                      itemKey="deleted"
                      headerText={`${t('USERS.FORM.DELETED_SUCCESS')} (${resultImport.deletedSuccess.length})`}
                    />
                    <PivotItem
                      itemKey="errors"
                      headerText={`${t('USERS.FORM.ERRORS_IMPORT')} (${resultImport.errors.length})`}
                    />
                  </Pivot>
                </>
                <div style={{ display: 'flex', flexDirection: 'column', marginTop: '20px' }}>
                  <Label {...labelTitleProperties}>{t('USERS.TABLE.RESULT_IMPORT')}</Label>
                  {tabQueryParameter === 'created' && (
                    <div style={{ maxHeight: '400px', overflowY: 'auto' }}>
                      {resultImport?.createdSuccess?.length > 0 ? (
                        <DetailsList
                          columns={successColumns}
                          items={resultImport?.createdSuccess}
                          selectionMode={SelectionMode.none}
                          flexMargin={1}
                        />
                      ) : (
                        <div style={{ textAlign: 'center' }}>{t('USERS.TABLE.NO_DATA')}</div> 
                      )}
                    </div>
                  )}
                  {tabQueryParameter === 'updated' && (
                    <div style={{ maxHeight: '400px', overflowY: 'auto' }}>
                      {resultImport?.updatedSuccess?.length > 0 ? (
                        <DetailsList
                          columns={successColumns}
                          items={resultImport?.updatedSuccess}
                          selectionMode={SelectionMode.none}
                          flexMargin={1}
                        />
                      ) : (
                        <div style={{ textAlign: 'center' }}>{t('USERS.TABLE.NO_DATA')}</div> 
                      )}
                    </div>
                  )}
                  {tabQueryParameter === 'deleted' && (
                    <div style={{ maxHeight: '400px', overflowY: 'auto' }}>
                      {resultImport?.deletedSuccess?.length > 0 ? (
                        <DetailsList
                          columns={successColumns}
                          items={resultImport?.deletedSuccess}
                          selectionMode={SelectionMode.none}
                          flexMargin={1}
                        />
                      ) : (
                        <div style={{ textAlign: 'center' }}>{t('USERS.TABLE.NO_DATA')}</div>
                      )}
                    </div>
                  )}
                  {tabQueryParameter === 'errors' && (
                    <div style={{ maxHeight: '400px', overflowY: 'auto' }}>
                      {resultImport?.errors?.length > 0 ? (
                        <DetailsList
                          columns={errorsColumns}
                          items={resultImport?.errors}
                          selectionMode={SelectionMode.none}
                          flexMargin={1}
                        />
                      ) : (
                        <div style={{ textAlign: 'center' }}>{t('USERS.TABLE.NO_DATA')}</div>
                      )}
                    </div>
                  )}
                  {tabQueryParameter === 'info' && (
                    <div style={{ maxHeight: '400px', overflowY: 'auto' }}>
                      {resultImport?.info?.length > 0 ? (
                        <DetailsList
                          columns={errorsColumns}
                          items={resultImport?.info}
                          selectionMode={SelectionMode.none}
                          flexMargin={1}
                        />
                      ) : (
                        <div style={{ textAlign: 'center' }}>{t('USERS.TABLE.NO_DATA')}</div>
                      )}
                    </div>
                  )}
                  
                </div>
              </div>
            </div>
          </>
        )}

        {importUsersByOrganization?.isError && <ErrorStatus message={t('USERS.FORM.IMPORT_ERROR')} />}
      </>
    )
  }

  return (
    <>
      {!isLoading && !isSuccess && !isError && renderForm()}

      {(isLoading || isProcessingFile) && <LoadingSpinner></LoadingSpinner>}

      {!isLoading && !isSuccess && !isError && renderImportPanel()}

      {(isSuccess || isError) && renderResultPanel()}
    </>
  )
}

export default ImportUsersForm
