import { IconButton } from '@fluentui/react';
import { useBoolean } from '@fluentui/react-hooks';
import { Editor, useMonaco } from '@monaco-editor/react';
import { FC, useEffect } from 'react';
import { powershellKeywords } from './powershellKeywords';

import './CodeEditor.scss';
import { bashKeywords } from './bashKeywords'
import { MicroserviceLanguage } from 'app/models/microservices'

interface CodeEditorProps {
  value: string
  readOnly?: boolean
  onChange?: (value: string) => void
  styles?: React.CSSProperties
  language: string
}

export const CodeEditor: FC<CodeEditorProps> = ({ value, readOnly, onChange, styles, language }) => {
  const monaco = useMonaco()
  const [fullscreen, { toggle: toggleFullscreen }] = useBoolean(false)

  const registerBash = (monaco: any) => {
    const completionDisposable = monaco?.languages.registerCompletionItemProvider('shell', {
      provideCompletionItems: () => {
        return {
          suggestions: bashKeywords.map((keyword) => ({
            label: keyword,
            kind: monaco.languages.CompletionItemKind.Keyword,
            insertText: keyword,
          })),
        }
      },
    })

    return completionDisposable?.dispose
  }

  const registerPowerShell = (monaco: any) => {
    const completionDisposable = monaco?.languages.registerCompletionItemProvider('powershell', {
      provideCompletionItems: () => {
        return {
          suggestions: powershellKeywords.map((keyword) => ({
            label: keyword,
            kind: monaco.languages.CompletionItemKind.Keyword,
            insertText: keyword,
          })),
        }
      },
    })

    return completionDisposable?.dispose
  }

  useEffect(() => {
    if (monaco && language === MicroserviceLanguage.BASH) {
      registerBash(monaco)
    } else {
      registerPowerShell(monaco)
    }
  }, [monaco])

  return (
    <div
      className={`${fullscreen ? 'code-editor-fullscreen' : ''} ${!readOnly ? 'code-editor-editing' : ''}`}
      style={{ ...styles }}
    >
      {!readOnly && (
        <div className="code-editor-topbar d-flex d-flexJustifyEnd">
          <IconButton
            iconProps={{
              iconName: fullscreen ? 'ChromeClose' : 'ChromeFullScreen',
              styles: {
                root: {
                  color: '#24292f',
                  fontSize: 12,
                },
              },
            }}
            onClick={toggleFullscreen}
          ></IconButton>
        </div>
      )}
      <Editor
        value={value}
        height={fullscreen ? '100%' : '60vh'}
        language={language === MicroserviceLanguage.BASH ? 'shell' : 'powershell'}
        options={{ minimap: { enabled: false }, readOnly }}
        onChange={(value) => onChange?.(value ?? '')}
      />
    </div>
  )
}
