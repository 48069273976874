import { LoadingSpinner, MOBILE_QUERY, useMediaQuery } from '@flexxibleit/flexxible-ui'
import { IPivotItemProps, MessageBar, MessageBarType } from '@fluentui/react'
import { useBoolean } from '@fluentui/react-hooks'
import Dialog from 'app/components/Dialog/Dialog'
import { FeedbackMessageBar } from 'app/components/FeedbackMessageBar/FeedbackMessageBar'
import IconLanguage from 'app/components/IconLanguage/IconLanguage'
import { UnsavedIcon } from 'app/components/Icons'
import PageBody from 'app/components/PageBody/PageBody.component'
import { TabPanel } from 'app/components/tab-panel/TabPanel'
import { useOrganizationSelection } from 'app/globals/useOrganizationSelection'
import { FlowDetail, useGetFlow } from 'app/hooks/flows/useGetFlow'
import { getTranslation } from 'app/models/microservices'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router'
import { StringParam, useQueryParam } from 'use-query-params'
import history, { Transition } from '../../../routing/history'
import { FlowFlow } from './FlowFlow'
import { FlowNotification } from './notification/FlowNotification'
import { FlowOverview } from './overview/FlowOverview'
import { useFlowTabStore } from './store'
import { FlowTarget } from './target/FlowTarget'

const TitleComponent = ({ flow }: { flow: FlowDetail }) => {
  const [isMobile] = useMediaQuery(MOBILE_QUERY)
  const { i18n } = useTranslation()
  const name = getTranslation(flow.name, i18n.language)
  const itemName = name.text
  const showNameIcon = name.translatedByIA

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'start',
      }}
    >
      {isMobile ? <h3>{itemName?.toString()}</h3> : <h1>{itemName?.toString()}</h1>}{' '}
      {showNameIcon && <IconLanguage tam="16" />}
    </div>
  )
}

export const FlowDetailsPage = () => {
  const { flowId } = useParams()
  const { isLoading, data } = useGetFlow(flowId ?? '')
  const { t, i18n } = useTranslation('flows')
  const navigate = useNavigate()
  const store = useFlowTabStore()
  const { selectedOrganizationId, setSelectedOrganizationId } = useOrganizationSelection()
  const [tabQueryParameter] = useQueryParam('tab', StringParam)
  const [hideDialog, { toggle: toggleHideDialog }] = useBoolean(true)
  const [modalCallback, setModalCallback] = useState<() => void>(() => () => {
    toggleHideDialog()
  })

  useEffect(() => {
    if (!selectedOrganizationId) {
      return
    }
    if (data?.business && data?.business !== selectedOrganizationId) {
      navigate(`/flows`)
    }
  }, [data, selectedOrganizationId])

  useEffect(() => {
    store.clear()
  }, [])

  useEffect(() => {
    if (!store.flow && !store.notification && !store.target) return

    const unblock = history.block((tx: Transition) => {
      const hasChanges = store.flow || store.notification || store.target
      const isSamePage = tx.location.pathname === location.pathname
      if (!hasChanges || isSamePage) {
        unblock()
        tx.retry()
        return
      }

      setModalCallback(() => {
        return () => {
          unblock()
          tx.retry()
        }
      })

      toggleHideDialog()
    })

    return () => unblock()
  }, [history, store.flow, store.notification, store.target, tabQueryParameter])

  if (!data) {
    return <LoadingSpinner />
  }

  const onRenderItemLink = (props?: IPivotItemProps) => {
    const hasUnsavedChanges = Boolean(store[props?.itemKey as keyof typeof store])

    return (
      <span>
        <span>{props?.headerText}</span>
        {hasUnsavedChanges && <UnsavedIcon />}
      </span>
    )
  }

  const renderHasUnsavedChangesDialog = (): JSX.Element => {
    return (
      <Dialog
        title={t('general:UNSAVED_CHANGES_DIALOG.TITLE')}
        description={t('general:UNSAVED_CHANGES_DIALOG.DESCRIPTION')}
        actionButton={t('general:UNSAVED_CHANGES_DIALOG.BUTTON_ACCEPT')}
        dismissButton={t('general:UNSAVED_CHANGES_DIALOG.BUTTON_CANCEL')}
        hidden={hideDialog}
        onDismiss={() => {
          toggleHideDialog()
          setSelectedOrganizationId(data.business)
        }}
        callback={modalCallback}
      >
        <MessageBar
          messageBarType={3}
          isMultiline={true}
          dismissButtonAriaLabel={t('general:CLOSE_LABEL')}
          className="mb-2"
          style={{ width: '100%' }}
        >
          {t('general:UNSAVED_CHANGES_DIALOG.WARNING')}
        </MessageBar>
      </Dialog>
    )
  }

  const name = getTranslation(data.name, i18n.language)

  return (
    <PageBody
      title={name.text}
      isLoading={isLoading}
      titleComponent={data ? <TitleComponent flow={data} /> : null}
      breadcrumbItems={[
        {
          text: t('TITLE'),
          key: 'Flows',
          onClick: () => {
            navigate('/flows')
          },
        },
        { text: name.text, key: 'current', isCurrentItem: true },
      ]}
    >
      <FeedbackMessageBar />

      {(!data.trigger || !data.microservice || !data.targetType) && <NotCompletedWarning />}

      <TabPanel
        pivotProps={{
          styles: {
            root: {
              marginTop: '10px',
            },
          },
        }}
        tabs={[
          {
            title: t('DETAILS.OVERVIEW_TAB'),
            key: 'overview',
            component: <FlowOverview flow={data} />,
          },
          {
            title: t('DETAILS.TARGET_TAB'),
            key: 'target',
            component: <FlowTarget flow={data} />,
          },
          {
            title: t('DETAILS.FLOW_TAB'),
            key: 'flow',
            component: <FlowFlow flow={data} />,
          },
          {
            title: t('DETAILS.NOTIFICATION_TAB'),
            key: 'notification',
            component: <FlowNotification flow={data} />,
          },
        ]}
        onRenderItemLink={onRenderItemLink}
      />
      {renderHasUnsavedChangesDialog()}
    </PageBody>
  )
}

const NotCompletedWarning = () => {
  const { t } = useTranslation('flows')

  return (
    <MessageBar
      messageBarType={MessageBarType.warning}
      isMultiline={false}
      dismissButtonAriaLabel={t('general:CLOSE_LABEL')}
      style={{ width: '100%' }}
    >
      {t('INCOMPLETE')}
    </MessageBar>
  )
}