import { useCreateFormV2, useSidePanel } from 'app/hooks/useCreateForm'
import { getTranslation } from 'app/models/microservices'
import { useTranslation } from 'react-i18next'
import { NodeProps } from 'reactflow'
import { useShallow } from 'zustand/react/shallow'
import useStore, { RFState } from '../../store/store'
import { BaseNode } from '../base/BaseNode'
import { ConditionForm } from '../../panels/ConditionForm'
import { SelectForm } from '../../panels/ConditionSelector'

const selector = (state: RFState) => ({
  editing: state.editing,
  changeToCondition: state.changeToCondition,
  updateConditionNode: state.updateConditionNode,
  removeCondition: state.removeCondition,
})

export const StarterNode = (props: NodeProps) => {
  const { t, i18n } = useTranslation('flows')
  const { editing, changeToCondition, updateConditionNode, removeCondition } = useStore(useShallow(selector))
  const { openSidePanelWith, closeSidebar } = useSidePanel()

  const selectCondition = useCreateFormV2(
    {
      title: t('EDITOR.SELECTOR.CONDITION.TITLE'),
      isFooterAtBottom: true,
      className: 'p-0',
    },
    <SelectForm
      onSelect={(item) => {
        const node = changeToCondition(props.id, item)
        closeSidebar()

        if (!node) {
          return
        }

        const name = getTranslation(node.data.conditionData.conditionType!.name, i18n.language)
        openSidePanelWith(
          {
            title: name.text,
            isFooterAtBottom: true,
          },
          <ConditionForm
            condition={node.data.conditionData.conditionType!}
            data={node.data.conditionData ?? {}}
            onSubmit={(data) => {
              updateConditionNode(node.id, data)
              closeSidebar()
            }}
            onRemove={() => {
              removeCondition(node.id)
              closeSidebar()
            }}
          />
        )
      }}
    />
  )

  if (!editing) {
    return <></>
  }

  return (
    <BaseNode
      title={t('EDITOR.STARTER_NODE.TITLE')}
      icon="Plus"
      onClick={() => {
        if (!editing) {
          return
        }

        selectCondition.open()
      }}
    />
  )
}
