import { Col, FlexxiblePrimaryButton, Row } from '@flexxibleit/flexxible-ui'
import { theme } from '../../../../config/theme'
import { PatchPolicyTarget, Schedule, timezoneOptions, WeekDay } from '../../../../query-client/types'
import { useTranslation } from 'react-i18next'
import { FeatureRender } from '../../../../permissions/FeatureRender'
import { FEATURE_NAMES } from '../../../../permissions/FeatureName.enum'

interface PatchPolicyTargetSchedulerReadModeProps {
  patchPolicyTarget: PatchPolicyTarget
  onClickEdit: () => void
}

export const PatchPolicyTargetSchedulerReadMode = (props: PatchPolicyTargetSchedulerReadModeProps) => {
  const { t } = useTranslation('patch_management')

  const schedule: Schedule = props.patchPolicyTarget.schedule
  const timeGapSizeInMinutes = 15

  const dailyHours = Array.from({ length: 24 }, (_, i) => i)

  const getTimeZone = () => {
    return props.patchPolicyTarget.timezone
      ? timezoneOptions.find((tz) => tz.key === props.patchPolicyTarget.timezone)?.text
      : t('SCHEDULE.TIMEZONE.NO_TIMEZONE')
  }

  const getWeeks = () => {
    return props.patchPolicyTarget.weeks.length > 0
      ? props.patchPolicyTarget.weeks
          .map((weekNumber: number) => {
            return `${t('SCHEDULE.WEEKS.WEEK')} ${weekNumber}`
          })
          .join(', ')
      : t('SCHEDULE.WEEKS.NO_WEEKS')
  }

  return (
    <>
      <div
        style={{
          display: 'flex',
          marginBottom: '16px',
          justifyContent: 'end',
          gap: '10px',
          marginTop: '16px',
        }}
      >
        <FeatureRender feature={FEATURE_NAMES.PATCH_MANAGEMENT_UPDATE}>
          <FlexxiblePrimaryButton onClick={props.onClickEdit}>{t('general:BUTTON.EDIT')}</FlexxiblePrimaryButton>
        </FeatureRender>
      </div>

      <Row>
        <Col className="col-xs-12 col-lg-6">
          <strong>{t('SCHEDULE.DETAILS.RECURRENCE')}</strong>
          <p>{getWeeks()}</p>
        </Col>
        <Col className="col-xs-12 col-lg-6">
          <strong>{t('SCHEDULE.DETAILS.TIMEZONE')}</strong>
          <p>{getTimeZone()}</p>
        </Col>
      </Row>

      <div
        style={{
          overflowX: 'auto',
          marginTop: '25px',
          scrollbarWidth: 'thin',
          scrollbarColor: `#ccc ${theme.palette?.neutralLighterAlt}`,
        }}
      >
        <table
          style={{
            borderCollapse: 'collapse',
            border: '1px solid #ccc',
            borderRadius: '15px',
          }}
        >
          <thead
            style={{
              backgroundColor: '#f0f0f0',
            }}
          >
            <tr>
              <th
                style={{
                  position: 'sticky',
                  left: 0,
                  backgroundColor: '#f0f0f0',
                }}
              ></th>

              {dailyHours.map((hour, index) => (
                <th
                  key={index}
                  colSpan={60 / timeGapSizeInMinutes}
                  style={{
                    border: '1px solid #ccc',
                    padding: '0.5rem',
                    margin: '0.5rem 0',
                  }}
                >
                  {hour}h
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {Object.values(WeekDay).map((day, index) => (
              <tr
                key={index}
                style={{
                  backgroundColor: '#f9f9f9',
                }}
              >
                <td
                  style={{
                    padding: '0.5rem',
                    margin: '0.5rem 0',
                    fontWeight: 'bold',
                    position: 'sticky',
                    left: 0,
                    backgroundColor: '#f0f0f0',
                  }}
                >
                  {day.charAt(0) + day.slice(1).toLowerCase()}
                </td>
                {dailyHours.map((hour) => {
                  const cellCount = 60 / timeGapSizeInMinutes

                  return Array.from({ length: cellCount }, (_, i) => (
                    <td
                      key={i}
                      style={{
                        border: '1px solid #ccc',
                        padding: '0.5rem',
                        margin: '0.5rem 0',
                        backgroundColor: schedule[day]?.[hour]?.includes(i * timeGapSizeInMinutes)
                          ? 'var(--theme-primary)'
                          : 'white',
                      }}
                    ></td>
                  ))
                })}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </>
  )
}
