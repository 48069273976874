import { SummaryRule } from '../../usePatchManagementRuleEngine'
import { PatchSummary } from '../../../entities/PatchSummary'
import { PatchSummaryRuleSentences, SummarySectionType, SummaryType } from '../../types'
import { MustNotHaveSecurityPatchesNotApprovedCard } from './MustNotHaveSecurityPatchesNotApprovedCard'

export const MustNotHaveSecurityPatchesNotApprovedRule: SummaryRule = {
  rule: PatchSummaryRuleSentences.MUST_NOT_HAVE_STALE_SECURITY_PATCHES_NOT_APPROVED,
  alertSummaryItem: {
    card: <MustNotHaveSecurityPatchesNotApprovedCard />,
    type: SummaryType.Alert,
    section: SummarySectionType.Security,
  },
  infoSummaryItem: {
    type: SummaryType.Information,
    section: SummarySectionType.Security,
    card: <MustNotHaveSecurityPatchesNotApprovedCard isInfo />,
  },
  passRule: (patchSummary: PatchSummary) => {
    const totalSecurityPatchesNotApproved =
      patchSummary.patchesBySeverityAndStatus.reduce((acc, patch) => {
        if (patch.severity === 'Critical' && patch.status === 'NOT_APPROVED') {
          acc += patch.value
        }
        return acc
      }, 0)
    return totalSecurityPatchesNotApproved === 0
  },
}
