import { useMutation, useQueryClient } from '@tanstack/react-query'
import graphClient from 'app/query-client/graphClient'
import { gql } from 'graphql-request'

interface CreateTenantPolicyRequest {
  name: string
  description?: string
  organizationId: string
  product?: string
}

const createTenantPolicyQuery = gql`
  mutation Request($input: CreateTenantPolicyGraphqlRequest!) {
    createTenantPolicy(input: $input) {
      _id
    }
  }
`

interface CreateTenantPolicyResponse {
  createTenantPolicy: {
    _id: string
  }
}

async function createTenantPolicy(policy: CreateTenantPolicyRequest) {
  const { createTenantPolicy }: CreateTenantPolicyResponse = await graphClient.request(createTenantPolicyQuery, {
    input: policy,
  })

  return createTenantPolicy
}

export default function () {
  const queryClient = useQueryClient()

  return useMutation(['createTenantPolicy'], (policy: CreateTenantPolicyRequest) => createTenantPolicy(policy), {
    onSuccess: () => {
      queryClient.invalidateQueries(['getBusinessById'])
    },
  })
}
