import PageBody from 'app/components/PageBody/PageBody.component'
import { useTranslation } from 'react-i18next'
import { FEATURE_NAMES } from '../../permissions/FeatureName.enum'
import { withFeatureRender } from '../../permissions/withFeatureRender'
import { OrganizationListFeature } from './organization-list/organization-list.feature'

const Organization = () => {
  const { t } = useTranslation('organizations')

  return (
    <PageBody title={t('TITLE')} isLoading={false}>
      <OrganizationListFeature />
    </PageBody>
  )
}

export const OrganizationPage = withFeatureRender(Organization, FEATURE_NAMES.ALL_ORGANIZATION_READ)
