import { TFunction } from 'i18next'
import {
  IPaginatedTableColumn,
} from '@flexxibleit/flexxible-ui/dist/lib/components/paginated-table/paginatedTable.types'
import { InstalledAppVersion } from '../InstalledAppVersion'
import { DateFormat, dateFormatter } from '../../../../../services/date-formatter'

export const useBuildInstalledAppVersionsTable = ({ t }: { t: TFunction }): { columns: IPaginatedTableColumn[] } => {
  const columns: IPaginatedTableColumn[] = [
    {
      key: 'version',
      name: t('INSTALLED_APPS.DETAILS.VERSIONS.TABLE.VERSION'),
      minWidth: 25,
      flexGrow: 1,
    },
    {
      key: 'workspacesCount',
      name: t('INSTALLED_APPS.DETAILS.VERSIONS.TABLE.WORKSPACES_COUNT'),
      minWidth: 25,
      flexGrow: 1,
    },
    {
      key: 'discoveredAt',
      name: t('INSTALLED_APPS.DETAILS.VERSIONS.TABLE.DISCOVERED_AT'),
      minWidth: 25,
      flexGrow: 1,
      onRender: (item: InstalledAppVersion) => dateFormatter(t, item.discoveredAt, DateFormat.Short),
    },
    {
      key: 'reportedAt',
      name: t('INSTALLED_APPS.DETAILS.VERSIONS.TABLE.REPORTED_AT'),
      minWidth: 25,
      flexGrow: 1,
      onRender: (item: InstalledAppVersion) => dateFormatter(t, item.reportedAt, DateFormat.Short),
    },
  ]

  return { columns }
}