import { Col, EditButton, MOBILE_QUERY, Row, TABLET_QUERY, useMediaQuery } from '@flexxibleit/flexxible-ui'
import { DisplayField } from 'app/components/DisplayField'
import FlexxibleCard from 'app/components/FlexxibleCard/FlexxibleCard.component'
import IconLanguage from 'app/components/IconLanguage/IconLanguage'
import { FlowDetail } from 'app/hooks/flows/useGetFlow'
import { getTranslation } from 'app/models/microservices'
import { secondsToLabel } from 'app/models/flows/FlowCooldown'
import { typeLabel } from 'app/models/flows/FlowType'
import SummaryCard from 'app/pages/organization-details/components/SummaryCard/SummaryCard'
import { FEATURE_NAMES } from 'app/permissions/FeatureName.enum'
import { FeatureRender } from 'app/permissions/FeatureRender'
import { DateFormat, dateFormatter } from 'app/services/date-formatter'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import DataTableIcon from '../../../../assets/img/data-table-svgrepo-com.svg'

interface Props {
  flow: FlowDetail
}

export const FlowOverview = ({ flow }: Props) => {
  const navigate = useNavigate()
  const { t, i18n } = useTranslation('flows')
  const [isMobile] = useMediaQuery(MOBILE_QUERY)
  const [isTablet] = useMediaQuery(TABLET_QUERY)

  const description = getTranslation(flow.description, i18n.language)
  return (
    <>
      <div className="d-flex d-flexCol col-xs-12" style={{ marginTop: '-38px' }}>
        <span className="d-flex d-flexJustifyEnd" style={{ height: 32, gap: 10 }}>
          {/* <PrimaryButton onClick={() => {}}>{t('ACTIONS.CLONE')}</PrimaryButton> */}
        </span>
        <div
          className={isMobile || isTablet ? 'd-flex d-flexCol d-flexColReverse' : ''}
          style={isMobile || isTablet ? {} : { display: 'grid', gridTemplateColumns: '2fr 1fr', gap: '1em' }}
        >
          <div>
            <FlexxibleCard
              cardProps={{
                style: { marginTop: 36 },
              }}
              styleNames="d-flex d-flexCol d-flexJustifyBetween"
            >
              <Row>
                <Col className="col-xs-12 col-lg-12">
                  <DisplayField label={t('TABLE.DESCRIPTION')}>
                    {description.text} {description.translatedByIA && <IconLanguage tam="16" />}
                  </DisplayField>
                </Col>
              </Row>
              <Row>
                <Col className="col-xs-12 col-lg-6">
                  <DisplayField label={t('TABLE.TYPE')}>{typeLabel(t)[flow.type]}</DisplayField>
                </Col>
                <Col className="col-xs-12 col-lg-6">
                  <DisplayField label={t('TABLE.VERSION')}>{'1'}</DisplayField>
                </Col>
              </Row>
              <Row>
                <Col className="col-xs-12 col-lg-6">
                  <DisplayField label={t('TABLE.COOLDOWN')}>{secondsToLabel(t, flow.cooldown)}</DisplayField>
                </Col>
                <Col className="col-xs-12 col-lg-6">
                  <DisplayField label={t('TABLE.DETECTIONONLY')}>
                    {flow.detectionOnly
                      ? t('organization_details:MODULES.FORM.YES')
                      : t('organization_details:MODULES.FORM.NO')}
                  </DisplayField>
                </Col>
              </Row>
              <div className="d-flex d-flexRowReverse">
                <FeatureRender feature={FEATURE_NAMES.FLOWS_UPDATE}>
                  <EditButton onClick={() => navigate(`/flows/${flow.id}/edit`)} style={{ marginLeft: '20px' }} />
                </FeatureRender>
              </div>
            </FlexxibleCard>
          </div>
          <div className="summary-container d-flexJustifyCenter d-flexAlignItemsCenter" style={{ marginTop: 6 }}>
            <div
              className={isMobile || isTablet ? 'd-flex d-flexRow d-flexWrap' : 'd-flex d-flexCol '}
              style={{ gap: 20 }}
            >
              <SummaryCard
                Icon={DataTableIcon}
                title={t('DETAILS.OVERVIEW.PUBLISHED')}
                text={dateFormatter(t, new Date(), DateFormat.Short)}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
