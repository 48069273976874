import { TFunction } from 'i18next'

interface Props {
  t: TFunction
}

export const useInstalledAppsBuildExport = ({ t }: Props) => {
  const installedAppsTableExportColumns = [
    {
      key: 'name',
      header: t('analyzer:INSTALLED_APPS.TABLE.PRODUCT_NAME'),
      width: 50
    },
    {
      key: 'publisher',
      header: t('analyzer:INSTALLED_APPS.TABLE.PUBLISHER'),
      width: 28
    },
    {
      key: 'discoveredAt',
      header: t('analyzer:INSTALLED_APPS.TABLE.DISCOVERED_AT'),
      width: 24,
      isDate: true
    },
    {
      key: 'reportedAt',
      header: t('analyzer:INSTALLED_APPS.TABLE.REPORTED_AT'),
      width: 24,
      isDate: true
    },
    {
      key: 'operatingSystem',
      header: t('analyzer:INSTALLED_APPS.TABLE.OPERATING_SYSTEM'),
      width: 10
    },
  ]
  
  return { installedAppsTableExportColumns }
}

