import { useMutation, useQueryClient } from '@tanstack/react-query'
import graphClient from 'app/query-client/graphClient'
import { gql } from 'graphql-request'

interface CreateUserRoleRequest {
  name: string
}

const createUserRoleQuery = gql`
  mutation Request($input: CreateUserRoleGraphqlRequest!, $organizationId: String!) {
    createUserRole(input: $input, organizationId: $organizationId) {
      _id
    }
  }
`

interface CreateUserRoleResponse {
  createUserRole: {
    _id: string
  }
}

async function createUserRole(userRole: CreateUserRoleRequest, organizationId: string) {
  const { createUserRole }: CreateUserRoleResponse = await graphClient.request(createUserRoleQuery, {
    organizationId,
    input: userRole,
  })

  return createUserRole
}

export default function (organizationId: string) {
  const queryClient = useQueryClient()

  return useMutation(
    ['createUserRole'],
    (userRole: CreateUserRoleRequest) => createUserRole(userRole, organizationId),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['getPaginatedUserRoles'])
      },
    }
  )
}
