import PageBody from '../../../components/PageBody/PageBody.component'
import { FC, useEffect } from 'react'
import { Tab, TabPanel } from 'app/components/tab-panel/TabPanel'
import { useNavigate, useParams } from 'react-router-dom'
import { IBreadcrumbItem } from '@fluentui/react'
import { useTranslation } from 'react-i18next'
import { useOrganizationSelection } from 'app/globals/useOrganizationSelection'
import { FeedbackMessageBar } from 'app/components/FeedbackMessageBar/FeedbackMessageBar'
import { WindowsPatchPolicyPatchesTab } from './tabs/WindowsPatchPolicyPatchesTab'
import { WindowsPatchPolicyAutomaticTab } from './tabs/WindowsPatchPolicyAutomaticTab'
import { WindowsPatchPolicyDetailsTab } from './tabs/WindowsPatchPolicyDetailsTab'
import { WindowsPatchPolicyAuditLogTab } from './tabs/WindowsPatchPolicyAuditLogTab'
import useGetWindowsPatchPolicy from 'app/hooks/patchPolicies/useGetWindowsPatchPolicy'

export const WindowsPatchPolicyDetailsPage: FC = () => {
  const { selectedOrganization } = useOrganizationSelection()
  const { organizationId, windowsPatchPolicyId } = useParams()
  const { isLoading, data } = useGetWindowsPatchPolicy({ id: windowsPatchPolicyId || '' }, true)
  const navigate = useNavigate()
  const { t } = useTranslation('patch_management')
  const baseUrl = location.pathname.split('/')[1]

  useEffect(() => {
    if (!selectedOrganization) {
      return
    }
    if (selectedOrganization._id !== organizationId) {
      navigate(`/${baseUrl}/${selectedOrganization._id}/patch-management/windows-patch-policies/`)
    }
  }, [selectedOrganization])

  const buildTabs = (): Tab[] => {
    if (!data) {
      return []
    }

    return [
      {
        title: t('WINDOWS_PATCH_POLICIES.TABS.DETAILS'),
        component: <WindowsPatchPolicyDetailsTab windowsPatchPolicy={data} />,
        key: 'details',
      },
      {
        title: t('WINDOWS_PATCH_POLICIES.TABS.MANUAL_PATCHES'),
        component: <WindowsPatchPolicyPatchesTab windowsPatchPolicy={data} />,
        key: 'manual-patches',
      },
      {
        title: t('WINDOWS_PATCH_POLICIES.TABS.AUTOMATIC_PATCHES'),
        component: <WindowsPatchPolicyAutomaticTab windowsPatchPolicy={data} isLoading={isLoading} />,
        key: 'automatic-patches',
      },
      /* {
        title: t('WINDOWS_PATCH_POLICIES.TABS.AUDIT_LOG'),
        component: <WindowsPatchPolicyAuditLogTab windowsPatchPolicy={data} />,
        key: 'audit-log',
      }, */
    ]
  }
  const onBreadcrumbItemClicked = (event?: React.MouseEvent, item?: IBreadcrumbItem) => {
    navigate(item?.key ?? '/home')
  }

  const breadcrumbItems = [
    {
      text: t('WINDOWS_PATCH_POLICIES.TITLE'),
      key: `/organization/${organizationId}/patch-management/windows-patch-policies`,
      onClick: onBreadcrumbItemClicked,
    },
    { text: data?.name || '', key: 'current', isCurrentItem: true },
  ]

  return (
    <PageBody title={data?.name || ''} isLoading={isLoading} breadcrumbItems={breadcrumbItems}>
      <FeedbackMessageBar />
      <TabPanel tabs={buildTabs()} />
    </PageBody>
  )
}
