import { getPaginatedQueryParamsBlock, pageInfoBlock, paginatedVarsBlock } from 'app/config/pagination'
import { gql } from 'graphql-request'

export const usePaginatedRolePermissionsQuery = () => {
  const queryName = 'getPaginatedRolePermissions'
  const query = gql`
query Request(
  $organizationId: ID!
  $userRoleId: ID!
  ${getPaginatedQueryParamsBlock('UserRolePermissionsFilter', 'UserRolePermissionsSorting')}
) {
  getPaginatedRolePermissions(
    organizationId: $organizationId,
    userRoleId: $userRoleId
    ${paginatedVarsBlock}
  ) {
    data {
      allOrganizations
      portal
      workspaces
      analyzer
      allReportingGroups
      organization {
        _id
        name
      }
      reportingGroups {
        _id
        name 
      }
    }
    ${pageInfoBlock}
  }
}
`
  return {
    query,
    queryName,
  }
}
