import { HighchartsReact } from 'highcharts-react-official';
import Highcharts from 'highcharts/highstock';
import HighchartsHeatmap from 'highcharts/modules/heatmap';
HighchartsHeatmap(Highcharts);


interface Props {
  series: Highcharts.SeriesHeatmapOptions[];
  categoriesXAxis: string[];
  categoriesYAxis: string[];
  tooltip: string;
  highchartsConfig?: Partial<Highcharts.Options>;
}

export const HeatmapChart = (props: Props) => {
  const chartOptions: Highcharts.Options = {
    chart: {
      type: 'heatmap',
      plotBorderWidth: 1,
      marginTop: 40,
      numberFormatter: (value: number) => value.toLocaleString(),
    },
    title: {
      text: ''
    },
    credits: {
      enabled: false
    },
    legend: {
      enabled: false
    },
    plotOptions: {
      series: {
        marker: {
          lineColor: '#000000'
        }
      }
    },
    xAxis: {
      opposite: true,
      categories: props.categoriesXAxis
    },
    yAxis: {
      categories: props.categoriesYAxis,
      title: {
        text: ''
      },
      reversed: true
    },
    tooltip: {
      formatter: function() {
        // @ts-ignore
        return `<b>${props.tooltip}</b>: ${this.point.value?.toLocaleString()}`
      },
    },
    series: props.series,
    ...props.highchartsConfig
  }

  return (
    <HighchartsReact
      highcharts={Highcharts}
      options={chartOptions}
    />
  )
}
