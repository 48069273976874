import { useMutation, useQueryClient } from '@tanstack/react-query'
import graphClient from 'app/query-client/graphClient'
import { gql } from 'graphql-request'

interface UpdateTenantPolicyRequest {
  name: string
  description?: string
  product?: string
  accessAllUsers?: boolean
}

interface Response {
  updateTenantPolicy: {
    _id: string
  }
}

const mutation = gql`
  mutation Request($id: ID!, $input: UpdateTenantPolicyGraphqlRequest!) {
    updateTenantPolicy(id: $id, input: $input) {
      _id
    }
  }
`

const updateTenantPolicy = async (id: string, input: UpdateTenantPolicyRequest): Promise<string> => {
  const response: Response = await graphClient.request(mutation, {
    id,
    input,
  })
  return response.updateTenantPolicy._id
}

export const useUpdateTenantPolicy = () => {
  const queryClient = useQueryClient()

  return useMutation(
    ['updateTenantPolicy'],
    ({ id, input }: { id: string; input: UpdateTenantPolicyRequest }) => updateTenantPolicy(id, input),
    {
      onSuccess: (id) => {
        queryClient.invalidateQueries(['getTenantPolicy', id])
      },
    }
  )
}
