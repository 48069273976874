import { IDropdownOption, MessageBar, ResponsiveMode, Separator } from '@fluentui/react'
import { useBoolean } from '@fluentui/react-hooks'
import { zodResolver } from '@hookform/resolvers/zod'
import { BasicList } from 'app/components/BasicList/BasicList'
import EditButton from 'app/components/Buttons/EditButton.component'
import Dialog from 'app/components/Dialog/Dialog'
import FlexxibleCard from 'app/components/FlexxibleCard/FlexxibleCard.component'
import Col from 'app/components/Layouts/Col'
import Row from 'app/components/Layouts/Row'
import Dropdown from 'app/components/forms/Dropdown'
import FormActions from 'app/components/forms/FormActions'
import { LicenseType, licenses } from 'app/config/licenses'
import { useMicroserviceTabs } from 'app/context/MicroserviceTabsContext'
import { useFeedbackMessage } from 'app/context/feedback-message/FeedbackMessageContext'
import { MicroserviceView } from 'app/hooks/microservices/useGetDesignerMicroserviceById'
import { useUpdateMicroserviceLicense } from 'app/hooks/microservices/useUpdateMicroserviceLicense'
import { useEffect, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import * as z from 'zod'
import LawIcon from '../../../../assets/img/Law.svg'

interface Props {
  microserviceId: string
  license: MicroserviceView['license']
  canEdit: boolean
}

interface LicenseForm {
  license: string
}

export const MicroserviceLicense = ({ microserviceId, license, canEdit }: Props) => {
  const { t } = useTranslation('microservices')
  const updateMicroserviceLicense = useUpdateMicroserviceLicense()
  const { setSuccessMessage, setErrorMessage } = useFeedbackMessage()
  const tabsState = useMicroserviceTabs()
  const state = tabsState.state.license
  const [editing, setEditing] = useState(!!state)
  const [hideDialog, { toggle: toggleHideDialog }] = useBoolean(true)

  const licenseOptions: IDropdownOption[] = Object.values(licenses).map((element: any) => ({
    key: element.key,
    text: element.title,
  }))
  const schema: z.ZodType<Partial<LicenseForm>> = z.object({
    license: z.string({ required_error: 'required' }).min(1, { message: 'required' }),
  })

  const methods = useForm<LicenseForm>({
    resolver: zodResolver(schema),
    defaultValues: { license: state?.text ?? license.type },
  })

  const watchLicense = methods.watch('license')

  const handleSubmit = (data: LicenseForm) => {
    updateMicroserviceLicense
      .mutateAsync({
        microserviceId: microserviceId,
        input: { license: data.license },
      })
      .then(() => {
        setEditing(false)
        setSuccessMessage(t('DETAILS.LICENSE.SUCCESS'))
      })
      .catch(() => setErrorMessage(t('DETAILS.LICENSE.ERROR')))
  }

  useEffect(() => {
    if (!editing) {
      const { license, ...newState } = { ...tabsState.state }

      return tabsState.setState(newState)
    }

    tabsState.setState({
      ...tabsState.state,
      license: { text: watchLicense },
    })
  }, [editing, watchLicense])

  const renderCancelEditDialog = (): JSX.Element => {
    return (
      <Dialog
        title={t('DETAILS.LICENSE.CANCEL_EDIT_DIALOG.TITLE')}
        description={t('DETAILS.LICENSE.CANCEL_EDIT_DIALOG.DESCRIPTION')}
        actionButton={t('DETAILS.LICENSE.CANCEL_EDIT_DIALOG.BUTTON_ACCEPT')}
        dismissButton={t('DETAILS.LICENSE.CANCEL_EDIT_DIALOG.BUTTON_CANCEL')}
        hidden={hideDialog}
        onDismiss={toggleHideDialog}
        callback={() => {
          setEditing(false)
          methods.reset({ license: license.type })
          toggleHideDialog()
        }}
      >
        <MessageBar
          messageBarType={3}
          isMultiline={true}
          dismissButtonAriaLabel={t('general:CLOSE_LABEL')}
          className="mb-2"
          style={{ width: '100%' }}
        >
          {t('DETAILS.SCRIPT.CANCEL_EDIT_DIALOG.WARNING')}
        </MessageBar>
      </Dialog>
    )
  }

  const editForm = () => {
    return (
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(handleSubmit)}>
          <Row classNames="mt-2 bottom-lg">
            <Col className="col-xs-12 col-lg-6">
              <Dropdown
                name="license"
                required={true}
                translator={t}
                dropdownProps={{
                  responsiveMode: ResponsiveMode.large,
                  options: licenseOptions,
                  label: t('DESIGNER.MICROSERVICE_FORM.LICENSE_INPUT_LABEL'),
                  placeholder: t('DESIGNER.MICROSERVICE_FORM.LICENSE_INPUT_PLACEHOLDER'),
                  defaultSelectedKey: watchLicense,
                }}
              />
            </Col>
            <Col className="col-xs-12 col-lg-6">
              <FormActions
                cancelProps={{
                  onClick: () => {
                    setEditing(false)
                    methods.reset()
                  },
                }}
                submitProps={{
                  text: t('general:BUTTON.SAVE'),
                  iconProps: { iconName: 'Save' },
                }}
              />
            </Col>
          </Row>
        </form>
      </FormProvider>
    )
  }

  return (
    <div className="col-xs-12">
      <FlexxibleCard
        cardProps={{ style: { marginTop: 30 } }}
        styleNames="col-md-12 d-flex d-flexCol d-flexJustifyBetween"
      >
        {editing && editForm()}

        {canEdit && !editing && (
          <div className="d-flex d-flexAlignItemsEnd d-flexRowReverse">
            <EditButton onClick={() => setEditing(true)} />
          </div>
        )}

        <LicenseView
          license={{
            type: watchLicense,
            year: license.year,
            holder: license.holder,
          }}
          showTitle={!editing}
        />
      </FlexxibleCard>
      {renderCancelEditDialog()}
    </div>
  )
}

export const LicenseView = ({ license, showTitle }: { license: MicroserviceView['license']; showTitle: boolean }) => {
  const licenseSelected = license?.type && licenses[license.type as LicenseType]

  if (!licenseSelected) return null

  const fullText = licenseSelected.fullText
    .replaceAll('{{year}}', license.year.toString())
    .replaceAll('{{holder}}', license.holder)

  return (
    <>
      {showTitle && (
        <div>
          <img src={LawIcon} className="icon" style={{ verticalAlign: 'middle' }} alt="license" />
          <span style={{ fontSize: 18, fontWeight: 'bold', verticalAlign: 'middle' }}>{licenseSelected.title}</span>
        </div>
      )}

      <p>{licenseSelected.summary}</p>

      <Row classNames="around-xs">
        <Col className="col-xs-12 col-md-4 col-lg-3">
          <BasicList items={licenseSelected.permissions} title="Permissions" iconName="CheckMark" iconColor="#3fb950" />
        </Col>
        <Col className="col-xs-12 col-md-4 col-lg-3">
          <BasicList items={licenseSelected.limitations} title="Limitations" iconName="Cancel" iconColor="#f85149" />
        </Col>
        <Col className="col-xs-12 col-md-4 col-lg-3">
          <BasicList items={licenseSelected.conditions} title="Conditions" iconName="Info" iconColor="#2f81f7" />
        </Col>
      </Row>
      <Separator />
      <pre style={{ whiteSpace: 'break-spaces' }}>{fullText}</pre>
    </>
  )
}
