import { TFunction } from 'react-i18next'
import { ValueType } from './ValueType'

export enum OperatorType {
  EQUAL = 'eq',
  GT = 'gt',
  LT = 'lt',
  GTE = 'gte',
  LTE = 'lte',
  STARTS_WITH = 'startsWith',
  ENDS_WITH = 'endsWith',
  CONTAINS = 'contains',
  AT = 'at'
}

export const OperatorByValueType: Record<ValueType, OperatorType[]> = {
  [ValueType.string]: [OperatorType.EQUAL, OperatorType.STARTS_WITH, OperatorType.ENDS_WITH, OperatorType.CONTAINS],
  [ValueType.int]: [OperatorType.EQUAL, OperatorType.GT, OperatorType.LT, OperatorType.GTE, OperatorType.LTE],
  [ValueType.double]: [OperatorType.EQUAL, OperatorType.GT, OperatorType.LT, OperatorType.GTE, OperatorType.LTE],
  [ValueType.id]: [OperatorType.EQUAL],
  [ValueType.bool]: [OperatorType.EQUAL],
  [ValueType.cron]: [OperatorType.AT],
}

export const operatorTypeOptions = (t: TFunction, valueType: ValueType) =>
  OperatorByValueType[valueType].map((type) => ({
    key: type,
    text: operatorLabel(t)[type],
  }))

export const operatorLabel = (t: TFunction): { [key in OperatorType]: string } => ({
  [OperatorType.EQUAL]: t('flows:OPERATOR_TYPE.EQUAL'),
  [OperatorType.GT]: t('flows:OPERATOR_TYPE.GT'),
  [OperatorType.LT]: t('flows:OPERATOR_TYPE.LT'),
  [OperatorType.GTE]: t('flows:OPERATOR_TYPE.GTE'),
  [OperatorType.LTE]: t('flows:OPERATOR_TYPE.LTE'),
  [OperatorType.STARTS_WITH]: t('flows:OPERATOR_TYPE.STARTS_WITH'),
  [OperatorType.ENDS_WITH]: t('flows:OPERATOR_TYPE.ENDS_WITH'),
  [OperatorType.CONTAINS]: t('flows:OPERATOR_TYPE.CONTAINS'),
  [OperatorType.AT]: t('flows:OPERATOR_TYPE.AT'),
})
