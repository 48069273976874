import { Icon, PrimaryButton } from '@fluentui/react'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router'

interface ErrorPageProps {
  error: Error
  resetError: () => void
}

export const ErrorPage: FC<ErrorPageProps> = (props: ErrorPageProps) => {
  const { t } = useTranslation('general')
  const navigate = useNavigate()

  const renderErrorMessages = (error: any) => {
    const errorTitle: string = t('ERRORS.COMMON.TITLE')
    const errorMessage: string = t('ERRORS.COMMON.MESSAGE')
    const iconName: string = 'ErrorBadge'

    return (
      <>
        <Icon iconName={iconName} style={{ fontSize: '10rem' }} />
        <h2>{errorTitle}</h2>
        <p>{errorMessage}</p>
      </>
    )
  }
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '80vh',
        gap: '1rem',
      }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        {renderErrorMessages(props.error)}
      </div>

      <PrimaryButton
        styles={{ flexContainer: { gap: '0.5rem' } }}
        onClick={() => {
          props.resetError()
          navigate('/')
        }}
      >
        <Icon iconName="SkypeArrow" />
        <span>{t('BUTTON.GO_HOME')}</span>
      </PrimaryButton>
    </div>
  )
}
