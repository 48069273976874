import {
  Col,
  Dialog,
  FlexxibleSecondaryButton,
  MOBILE_QUERY,
  Row,
  TABLET_QUERY,
  useMediaQuery,
} from '@flexxibleit/flexxible-ui'
import { DisplayField } from 'app/components/DisplayField'
import FlexxibleCard from 'app/components/FlexxibleCard/FlexxibleCard.component'
import useCreateForm from 'app/hooks/useCreateForm'
import { DateFormat, dateFormatter } from 'app/services/date-formatter'
import { useTranslation } from 'react-i18next'
import DataTableIcon from '../../../../../assets/img/data-table-svgrepo-com.svg'
import LayersIcon from '../../../../../assets/img/layers-svgrepo-com.svg'
import SummaryCard from '../../../components/SummaryCard/SummaryCard'
import { RolInfoForm } from './RolInfoForm'
import { UserRole } from 'app/query-client/types'
import { useBoolean } from '@fluentui/react-hooks'
import useCloneUserRole from 'app/hooks/roles/useCloneUserRole'
import { useNavigate } from 'react-router-dom'
import EditButton from 'app/components/Buttons/EditButton.component'
import { useState } from 'react'
import { useFeatureRender } from '../../../../../permissions/useFeatureRender'
import { FEATURE_NAMES } from '../../../../../permissions/FeatureName.enum'

const cardProps = {
  style: { marginTop: 30 },
}



interface Props {
  userRole: UserRole
}

export const RolDetails = ({ userRole }: Props) => {
  const { t } = useTranslation('organization_details')
  const [isMobile] = useMediaQuery(MOBILE_QUERY)
  const [isTablet] = useMediaQuery(TABLET_QUERY)
  const isDesktop = !isMobile && !isTablet
  const [hideCloneDialog, { toggle: toggleHideCloneDialog }] = useBoolean(true)
  const [cloneError, setCloneError] = useState<boolean>(false)
  const cloneUserRole = useCloneUserRole()
  const navigate = useNavigate()
  const { checkFeature } = useFeatureRender()
  const canCloneRoleFeature = checkFeature(FEATURE_NAMES.ROLE_CREATE)
  

  const onEdit = useCreateForm(
    {
      title: t('ROLES.FORM.EDIT_TITLE'),
      isFooterAtBottom: true,
    },
    <RolInfoForm organizationId={userRole?.organizationId} userRole={userRole!} />
  )

  const onClone = () => {
    toggleHideCloneDialog()
  }
  

  const renderCloneConfirmation = (): JSX.Element => {
    if (!cloneError) {
      return (
        <Dialog
          title={t('ROLES.CLONE_CONFIRMATION.TITLE')}
          description={t('ROLES.CLONE_CONFIRMATION.MESSAGE')}
          actionButton={t('ROLES.CLONE_CONFIRMATION.BUTTON_ACCEPT')}
          dismissButton={t('ROLES.CLONE_CONFIRMATION.BUTTON_CANCEL')}
          hidden={hideCloneDialog}
          onDismiss={toggleHideCloneDialog}
          callback={() => {
            handleCloneUserRole()
          }}
        />
      )
    }
    return (
      <Dialog
        title={t('ROLES.CLONE_CONFIRMATION.ERROR')}
        description={t('ROLES.CLONE_CONFIRMATION.MESSAGE_ERROR')}
        actionButton={t('ROLES.CLONE_CONFIRMATION.BUTTON_ACCEPT_ERROR')}
        hidden={hideCloneDialog}
        onDismiss={ () => {
          toggleHideCloneDialog()
          setTimeout(() => {
            setCloneError(false)
          }, 500)
        }
      }
        callback={() => {
          toggleHideCloneDialog()
          setTimeout(() => {
            setCloneError(false)
          }, 500)
        }}
      />
    )
  }

  const handleCloneUserRole = () => {
    cloneUserRole.mutateAsync({ userRoleId: userRole._id, organizationId: userRole.organizationId }).then((resp) => {
      const newRoleId = resp?.cloneUserRole?._id
      if (newRoleId) {
        navigate(`/organization/${userRole.organizationId}/roles/${newRoleId}`)
      }
    }).catch((e) => {
      setCloneError(true)
    })
  }

  return (
    <>
      <div
        className={!isDesktop ? 'd-flex d-flexCol d-flexColReverse' : 'd-flex d-flexRow col-xs-12'}
        style={!isDesktop ? { gap: 0 } : { gap: 30, paddingRight: 20 }}
      >
        <FlexxibleCard cardProps={cardProps} styleNames="col-md-9 d-flex d-flexCol d-flexJustifyBetween">
          <Row>
            <Col className="col-xs-12 col-lg-6">
              <DisplayField label={t('ROLES.TABLE.NAME')}>{userRole?.name}</DisplayField>
            </Col>
            <Col className="col-xs-12 col-lg-6">
              <DisplayField label={t('ROLES.TABLE.ASSIGNED_USERS')}>{userRole?.assignedUsers}</DisplayField>
            </Col>
          </Row>
          <Row>
            <Col className="col-xs-12 col-lg-6">
              <DisplayField label={t('ROLES.TABLE.ASSIGNED_ORGANIZATIONS')}>
                {userRole?.assignedOrganizations}
              </DisplayField>
            </Col>
          </Row>

          <div className="d-flex d-flexRowReverse">
            <EditButton style={{ marginLeft: 20 }} onClick={onEdit} />
            <FlexxibleSecondaryButton disabled={!canCloneRoleFeature || !userRole.canBeCloned} onClick={onClone}>{t('ROLES.CLONE')}</FlexxibleSecondaryButton>
          </div>
        </FlexxibleCard>

        <div className="col-md-3 summary-container d-flexJustifyCenter d-flexAlignItemsCenter">
          <>
            <div
              className={isMobile || isTablet ? 'd-flex d-flexRow d-flexWrap' : 'd-flex d-flexCol '}
              style={{ gap: 20 }}
            >
              <SummaryCard
                Icon={LayersIcon}
                title={t('ROLES.SUMMARY.CREATED_BY')}
                text={
                  userRole?.created_by?._id
                    ? `${userRole?.created_by?.name} ${userRole?.created_by?.surname} (${userRole?.created_by?.email})`
                    : '-'
                }
              />

              <SummaryCard
                Icon={LayersIcon}
                title={t('ROLES.SUMMARY.UPDATED_BY')}
                text={
                  userRole?.updated_by?._id
                    ? `${userRole?.updated_by?.name} ${userRole?.updated_by?.surname} (${userRole?.created_by?.email})`
                    : '-'
                }
              />

              <SummaryCard
                Icon={DataTableIcon}
                title={t('ROLES.SUMMARY.CREATED_AT')}
                text={dateFormatter(t, userRole?.created_at, DateFormat.Short)}
              />

              <SummaryCard
                Icon={DataTableIcon}
                title={t('ROLES.SUMMARY.UPDATED_AT')}
                text={dateFormatter(t, userRole?.updated_at, DateFormat.Short)}
              />
            </div>
          </>
        </div>
      </div>
      {renderCloneConfirmation()}
    </>
  )
}
