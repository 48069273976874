import FlexxibleCard from '../../../components/FlexxibleCard/FlexxibleCard.component'
import { useNavigate } from 'react-router-dom'
import { lazy, Suspense } from 'react'
import { Spinner } from '@fluentui/react'
import i18next from 'i18next'
import { getTranslation, MultiLanguageField } from 'app/models/microservices'
import { BadgeMode } from '@flexxibleit/flexxible-ui'
import StatusBadge from 'app/components/status-badge/status-badge.component'

const BootstrapIcon = lazy(() => import('app/components/BootstrapIcon/BootstrapIcon'))

export interface MicroserviceCardProps {
  id: string
  name: MultiLanguageField[]
  description: MultiLanguageField[]
  category: MultiLanguageField[]
  iconName?: string
  iconBackground?: string
  active?: boolean,
  onClick?: (id: string) => void
}

export const MicroservicesCard = ({
  description,
  category,
  iconBackground,
  iconName,
  id,
  name,
  active,
  onClick
}: MicroserviceCardProps) => {
  const navigate = useNavigate()

  const nameTranslated = getTranslation(name, i18next.language)
  const itemName = nameTranslated.text

  const descriptionTranslated = getTranslation(description, i18next.language)
  const descriptionName = descriptionTranslated.text

  const categoryTranslated = getTranslation(category, i18next.language)
  const categoryName = categoryTranslated.text

  const showStatus = active !== undefined

  return (
    <FlexxibleCard
      styleNames={'microservices-card'}
      cardProps={{
        onClick: () => onClick ? onClick(id) : navigate(`/microservices/${id}`),
        style: {
          width: '200px',
          height: '200px',
          display: 'flex',
          flexDirection: 'column',
          boxShadow: '0px 0px 10px 0px rgba(0,0,0,0.2)',
          borderRadius: '5px',
          overflow: 'hidden',
          padding: '20px',
          cursor: 'pointer',
        },
      }}
    >
      {showStatus &&
        <div style={{ display: 'flex', alignSelf: 'end', justifyContent: 'start' }}>
          <StatusBadge
            active={active || false}
            mode={BadgeMode.circle}
            withText={false}
            size={12}
          />
        </div>
      }
      <div
        style={{
          textAlign: 'center',
          textOverflow: 'ellipsis',
          justifyContent: 'center',
          alignItems: 'center',
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
          marginTop: showStatus && '-12px' || '0px',
        }}
      >
        {iconName && (
          <Suspense fallback={<Spinner />}>
            <BootstrapIcon
              className="mb-2"
              iconName={iconName}
              size={48}
              style={iconBackground ? { color: iconBackground } : {}}
            />
          </Suspense>
        )}
        <h3
          style={{
            fontSize: '16px',
            lineHeight: '20px',
            color: '#333',
            margin: '0px',
            padding: '0px',
          }}
          className="card-title"
        >
          {itemName}
          <span
            style={{
              marginLeft: '5px',
            }}
          ></span>
        </h3>
        <div
          style={{
            fontSize: '12px',
            color: 'var(--neutral-secondary)',
            margin: '5px 0px 0px 0px',
            padding: '0px',
            maxHeight: '50px',
            maxWidth: '200px',
            display: '-webkit-box',
            WebkitLineClamp: '3',
            WebkitBoxOrient: 'vertical',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }}
          className="card-body"
        >
          {descriptionName}
        </div>
        <div
          style={{
            fontSize: '12px',
            fontWeight: 'bold',
            color: 'var(--neutral-secondary)',
            margin: '5px 0px 0px 0px',
            padding: '0px',
            maxHeight: '50px',
            maxWidth: '200px',
            display: '-webkit-box',
            WebkitLineClamp: '3',
            WebkitBoxOrient: 'vertical',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }}
          className="card-body"
        >
          {categoryName}
        </div>
      </div>
    </FlexxibleCard>
  )
}
