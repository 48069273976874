import { TFunction } from 'i18next'
import {
  IPaginatedTableColumn,
} from '@flexxibleit/flexxible-ui/dist/lib/components/paginated-table/paginatedTable.types'
import { DateFormat, dateFormatter } from '../../../services/date-formatter'
import { ViewDetailsActionButton } from '@flexxibleit/flexxible-ui'
import { FEATURE_NAMES } from '../../../permissions/FeatureName.enum'
import { FeatureRender } from '../../../permissions/FeatureRender'
import { InstalledApp } from './InstalledApp'
import { CellWithFeatureGreenLink } from '../../../components/table-page-body/CellWithFeatureGreenLink'

interface Props {
  t: TFunction,
  openInstalledAppDetails: (installedApp: InstalledApp) => void
}

export const useBuildInstalledAppsTable: ({ t, openInstalledAppDetails }: Props) => { columns: IPaginatedTableColumn[] } = ({
  t, openInstalledAppDetails
}: Props) => {
  const columns: IPaginatedTableColumn[] = [
    {
      key: 'name',
      name: t('analyzer:INSTALLED_APPS.TABLE.PRODUCT_NAME'),
      minWidth: 25,
      flexGrow: 1,
      onRender: (item: InstalledApp) => {
        return (
          <CellWithFeatureGreenLink callback={() => openInstalledAppDetails(item)} feature={FEATURE_NAMES.INSTALLED_APPS}>
            {item.name}
          </CellWithFeatureGreenLink>
        )
      }
    },
    {
      key: 'publisher',
      name: t('analyzer:INSTALLED_APPS.TABLE.PUBLISHER'),
      minWidth: 25,
      flexGrow: 1,
    },
    {
      key: 'discoveredAt',
      name: t('analyzer:INSTALLED_APPS.TABLE.DISCOVERED_AT'),
      minWidth: 25,
      flexGrow: 1,
      onRender: (item: InstalledApp) => dateFormatter(t, new Date(item.discoveredAt), DateFormat.Short),
    },
    {
      key: 'reportedAt',
      name: t('analyzer:INSTALLED_APPS.TABLE.REPORTED_AT'),
      minWidth: 25,
      flexGrow: 1,
      onRender: (item: InstalledApp) => dateFormatter(t, new Date(item.reportedAt), DateFormat.Short),
    },
    {
      key: 'operatingSystem',
      name: t('analyzer:INSTALLED_APPS.TABLE.OPERATING_SYSTEM'),
      minWidth: 25,
      flexGrow: 1,
    },
    {
      key: 'actions',
      name: t('analyzer:INSTALLED_APPS.TABLE.ACTION'),
      minWidth: 25,
      flexGrow: 1,
      onRender: (installedApp: InstalledApp) => {
        return (
          <FeatureRender feature={FEATURE_NAMES.INSTALLED_APPS}>
            <ViewDetailsActionButton isMobile={false} onClick={() => openInstalledAppDetails(installedApp)} />
          </FeatureRender>
        )
      }
    },
  ]

  return { columns }
}
