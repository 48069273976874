import { EnabledMicroserviceView } from 'app/hooks/microservices/useGetEnabledMicroservices'
import { getTranslation } from 'app/models/microservices'
import i18next, { TFunction } from 'i18next'

export const useEnabledMicroservicesBuildExport = (t: TFunction) => {
  const exportColumns = [
    {
      key: 'name',
      header: t('MARKETPLACE.TABLE.NAME'),
      width: 10,
    },
    {
      key: 'category',
      header: t('MARKETPLACE.TABLE.CATEGORY'),
      width: 10,
    },
    {
      key: 'library',
      header: t('MARKETPLACE.TABLE.LIBRARY'),
      width: 10,
    },
  ]

  const exportMapper = (item: EnabledMicroserviceView) => {
    const name = getTranslation(item.name, i18next.language).text
    const category = getTranslation(item.category, i18next.language).text
    const library = item.organization.name

    return {
      name,
      category,
      library,
    }
  }

  return { exportColumns, exportMapper }
}
