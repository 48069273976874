export interface ColProps {
  children: any;
  className?: string;
}

function Col({ children, className }: ColProps) {
  return (
    <>
      <div className={className ? className : 'col-xs-12'}>
        {children}
      </div>
    </>
  )
}
export default Col