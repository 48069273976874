import { TooltipDelay, TooltipHost, TooltipOverflowMode } from '@fluentui/react'
import { MultiLanguageField, getTranslation } from 'app/models/microservices'
import { useTranslation } from 'react-i18next'
import IconLanguage from '../IconLanguage/IconLanguage'

interface Props {
  field: MultiLanguageField[]
}

const CellMultiLanguage = ({ field }: Props) => {
  const { i18n } = useTranslation()
  const { text, translatedByIA } = getTranslation(field, i18n.language)

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'flex-start',
      }}
    >
      <div
        style={{
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        }}
      >
        <TooltipHost content={text} delay={TooltipDelay.zero} overflowMode={TooltipOverflowMode.Parent}>
          {text} {translatedByIA && <IconLanguage tam="16" />}
        </TooltipHost>
      </div>
    </div>
  )
}

export default CellMultiLanguage
