import { useLocalStorageSearchParams } from 'app/hooks/utils/useLocalStorageSearchParams';
import { TFunction } from 'i18next';

import { FieldTypes, FilterField } from '@flexxibleit/flexxible-ui';
import { WorkspaceGroupType, label } from 'app/models/workspaces-groups/WorkspaceGroupType';

export const useWorkspaceGroupsFilters = (t: TFunction) => {
  useLocalStorageSearchParams();

  const filterFields: FilterField[] = [
    {
      field: 'name',
      text: t('REPORTING_GROUPS.TABLE.NAME'),
      type: FieldTypes.STRING
    },
    {
      field: 'type',
      text: t('WORKSPACE_GROUPS.TABLE.TYPE'),
      type: FieldTypes.ENUM,
      options: Object.values(WorkspaceGroupType).map((type) => ({
        key: type,
        text: t(`WORKSPACE_GROUPS.TYPE.${type}`)
      }))
    },
  ];

  return {
    filterFields
  }
}