import { useMutation } from '@tanstack/react-query'
import { MultiLanguageField } from 'app/models/microservices'
import graphClient from 'app/query-client/graphClient'
import queryClient from 'app/query-client/queryClient'
import { gql } from 'graphql-request'

const query = gql`
  mutation ($input: UpdateEnabledMicroserviceSettingsGraphqlRequest!, $microserviceId: ID!, $organizationId: ID!) {
    updateEnabledMicroserviceSettings(id: $microserviceId, organizationId: $organizationId, input: $input) {
      _id
    }
  }
`

interface Response {
  updateEnabledMicroserviceSettings: { _id: string }
}

interface Request {
  category: MultiLanguageField[]
  efficiency: number
}

interface Params {
  microserviceId: string
  organizationId: string
  input: Partial<Request>
}

const updateEnabledMicroserviceSettings = async (
  microserviceId: string,
  organizationId: string,
  input: Partial<Request>
) => {
  const response: Response = await graphClient.request(query, {
    microserviceId,
    organizationId,
    input,
  })
  return response.updateEnabledMicroserviceSettings._id
}

export const useUpdateEnabledMicroserviceSettings = () => {
  return useMutation(
    ['useUpdateEnabledMicroserviceSettings'],
    ({ microserviceId, organizationId, input }: Params) =>
      updateEnabledMicroserviceSettings(microserviceId, organizationId, input),
    {
      onSuccess: (microserviceId, params) => {
        queryClient.invalidateQueries(['enabled-microservices'])
        queryClient.invalidateQueries(['enabled-microservice', microserviceId, params.organizationId])
      },
    }
  )
}
