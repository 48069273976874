import { useQuery } from '@tanstack/react-query'
import graphClient from '../../query-client/graphClient'
import { gql } from 'graphql-request'
import { MultiLanguageField } from 'app/models/microservices'
import { MicroserviceType } from 'app/models/microservices/MicroserviceType'

export interface DesignerMicroserviceView {
  id: string
  name: MultiLanguageField[]
  description: MultiLanguageField[]
  category: MultiLanguageField[]
  organization: {
    name: string
    isTrial: boolean
  }
  archivedAt: Date | null
  createdAt: Date
  canEdit: boolean
  type: MicroserviceType
}

interface Response {
  getDesignerMicroservices: Array<DesignerMicroserviceView>
}

const getDesignerMicroservicesQuery = gql`
  query ($businessId: ID!) {
    getDesignerMicroservices(businessId: $businessId) {
      id
      name {
        code
        text
        translatedByIA
      }
      description {
        code
        text
        translatedByIA
      }
      category {
        code
        text
        translatedByIA
      }
      organization {
        name
        isTrial
      }
      createdAt
      archivedAt
      canEdit
      type
    }
  }
`

export async function getDesignerMicroservices(organizationId: string): Promise<DesignerMicroserviceView[]> {
  const response = await graphClient.request<Response>(getDesignerMicroservicesQuery, { businessId: organizationId })
  return response.getDesignerMicroservices
}

export const useGetDesignerMicroservices = (organizationId?: string) => {
  if (!organizationId) {
    return useQuery(['microservices'], () => [], {
      refetchOnWindowFocus: false,
      enabled: false,
    })
  }

  return useQuery(['microservices', organizationId], () => getDesignerMicroservices(organizationId))
}
