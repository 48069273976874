import { LoadingSpinner } from '@flexxibleit/flexxible-ui'
import * as z from 'zod'
import { FormProvider, useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import RowColLayout from '../../../components/Layouts/RowColLayout'
import ControlledTextField from '../../../components/forms/TextField'
import { useTranslation } from 'react-i18next'
import i18next from 'i18next'
import FormActions from '../../../components/forms/FormActions'
import ErrorStatus from '../../../components/status/error-status'
import { BaseSyntheticEvent, lazy, ReactNode, Suspense, useEffect, useMemo, useState } from 'react'
import { useCreateMicroservice } from '../../../hooks/microservices/useCreateMicroservice'
import Col from '../../../components/Layouts/Col'
import Row from '../../../components/Layouts/Row'
import {
  IDropdownOption,
  IRenderFunction,
  ISelectableOption,
  PrimaryButton,
  ResponsiveMode,
  Spinner,
} from '@fluentui/react'
import { useNavigate } from 'react-router-dom'
import { useUpdateMicroservice } from '../../../hooks/microservices/useUpdateMicroservice'
import Dropdown from '../../../components/forms/Dropdown'
import Multiselect from '../../../components/forms/multiselect/multiselect'
import {
  Languages,
  MicroserviceLanguage,
  MicroserviceOperatingSystem,
  MultiLanguageField,
  getPreferredLanguage,
  microserviceLanguageOptions,
} from '../../../models/microservices'
import { microserviceOperatingSystemOptions } from '../../../models/microservices'
import { MicroserviceScope, microserviceScopeOptions } from '../../../models/microservices'
import { useFeedbackMessage } from '../../../context/feedback-message/FeedbackMessageContext'
import ColorSelector from '../../../components/ColorSelector/ColorSelector'
import { MicroserviceView } from '../../../hooks/microservices/useGetDesignerMicroserviceById'
import { MicroserviceContext, microserviceContextOptions } from 'app/models/microservices/MicroserviceContext'
import useMultilanguageForm from 'app/hooks/useMultilanguageForm'
import { useGetOrganizationsForSelection } from 'app/hooks/organization/useGetOrganizationsForSelection'
import { useOrganizationSelection } from 'app/globals/useOrganizationSelection'

const IconSelector = lazy(() => import('../../../components/IconSelector/IconSelector'))

export interface MicroserviceFormType {
  organizationId: string | undefined | null
  name: MultiLanguageField[] | undefined | null
  description: MultiLanguageField[] | undefined | null
  iconName: string | undefined | null
  iconBackground: string | undefined | null
  readme: string | undefined | null
  script: string | undefined | null
  language: string | undefined | null
  version: number | undefined | null
  operatingSystem: string[] | undefined | null
  scope: string[] | undefined | null
  isPrivate?: boolean
  visibleTo?: string[]
  licenseType: string | undefined | null
  context: string | undefined | null
  category: MultiLanguageField[] | undefined | null
  efficiency: number
}

export interface MicroserviceFormProps {
  microservice?: MicroserviceView
  editedData?: MicroserviceFormType
  isCreate?: boolean
  onCancel: (isDirty: boolean) => void
  onSubmit?: (data: MicroserviceFormType) => void
}

export const MicroserviceForm = ({
  microservice,
  editedData,
  onCancel,
  isCreate = false,
  onSubmit,
}: MicroserviceFormProps) => {
  const { t } = useTranslation('microservices')
  const { data: organizationList } = useGetOrganizationsForSelection(false)
  const { selectedOrganizationId } = useOrganizationSelection()

  const [nameFields, setNameFields] = useState<MultiLanguageField[]>([])
  const [descriptionFields, setDescriptionFields] = useState<MultiLanguageField[]>([])
  const [categoryFields, setCategoryFields] = useState<MultiLanguageField[]>([])

  const options = useMemo(() => {
    return (
      organizationList?.filter((organization) => {
        return organization.active || organization._id === selectedOrganizationId
      }) ?? []
    ).map((organization) => {
      return {
        key: organization._id,
        text: organization.name,
        data: { isTrial: organization.isTrial, type: organization.type },
      }
    })
  }, [organizationList, selectedOrganizationId])

  useEffect(() => {
    if (isCreate) {
      methods.setValue('organizationId', selectedOrganizationId)
    }
  }, [isCreate, selectedOrganizationId])

  const updateNameField = (code: string, text: string) => {
    setNameFields(
      nameFields.map((field) => {
        if (field.code === code) {
          return { ...field, text }
        }
        return field
      })
    )
  }

  const updateDescriptionField = (code: string, text: string) => {
    setDescriptionFields(
      descriptionFields.map((field) => {
        if (field.code === code) {
          return { ...field, text }
        }
        return field
      })
    )
  }

  const updateCategoryField = (code: string, text: string) => {
    setCategoryFields(
      categoryFields.map((field) => {
        if (field.code === code) {
          return { ...field, text }
        }
        return field
      })
    )
  }

  const preferredLanguage = getPreferredLanguage(i18next.language)

  const multiLanguageFieldSchema = z.object({
    code: z.nativeEnum(Languages),
    text: z.string(),
    translatedByIA: z.boolean(),
  })

  const schema: z.ZodType<Partial<MicroserviceFormType>> = z.object({
    organizationId: z.string().nullable().optional(),
    name: z.array(multiLanguageFieldSchema).refine((array) => array.some((field) => field.text.trim().length > 0), {
      message: 'at-least-one-text-field-must-be-non-empty',
    }),
    description: z
      .array(multiLanguageFieldSchema)
      .refine((array) => array.some((field) => field.text.trim().length > 0), {
        message: 'at-least-one-text-field-must-be-non-empty',
      }),
    iconName: z.string().nullable().optional(),
    iconBackground: z.string().nullable().optional(),
    language: z.string({ required_error: 'required' }).min(1, { message: 'required' }),
    version: z.number(),
    operatingSystem: z
      .array(z.string(), { required_error: 'required', invalid_type_error: 'required' })
      .min(1, { message: 'required' }),
    scope: z
      .array(z.string(), { required_error: 'required', invalid_type_error: 'required' })
      .min(1, { message: 'required' }),
    context: z.string().nullable().optional(),
    category: z.array(multiLanguageFieldSchema).nullable().optional(),
    isPrivate: z.boolean().optional(),
    visibleTo: z.array(z.string()).optional(),
    efficiency: z
      .custom<number>()
      .refine((value) => value ?? false, 'required')
      .refine((value) => Number(value) >= 5, 'min')
      .transform((value) => Number(value)),
  })

  const createDefaultMultiLanguageFields = () => {
    return Object.values(Languages).map((code) => ({
      code,
      text: '',
      translatedByIA: false,
    }))
  }

  const defaultMultilanguageValues = createDefaultMultiLanguageFields()

  const defaultValues = isCreate
    ? {
        version: 1,
        organizationId: selectedOrganizationId,
        name: defaultMultilanguageValues,
        description: defaultMultilanguageValues,
        category: defaultMultilanguageValues,
        ...editedData,
      }
    : {
        ...microservice,
        name: microservice?.name || defaultMultilanguageValues,
        description: microservice?.description || defaultMultilanguageValues,
        category: microservice?.category || defaultMultilanguageValues,
        ...editedData,
      }

  const methods = useForm<MicroserviceFormType>({
    resolver: zodResolver(schema),
    defaultValues,
  })
  const isDirty = methods.formState.isDirty
  const watchName = methods.watch('name') ?? []
  const watchDescription = methods.watch('description') ?? []
  const watchCategory = methods.watch('category') ?? []

  const _onMultiLanguageNameClicked = useMultilanguageForm(
    {
      title: t('DESIGNER.MICROSERVICE_FORM.EDIT_MULTILANGUAGE_NAME'),
      isFooterAtBottom: true,
    },
    'name',
    watchName,
    updateNameField,
    methods
  )

  const _onMultiLanguageDescriptionClicked = useMultilanguageForm(
    {
      title: t('DESIGNER.MICROSERVICE_FORM.EDIT_MULTILANGUAGE_DESCRIPTION'),
      isFooterAtBottom: true,
    },
    'description',
    watchDescription,
    updateDescriptionField,
    methods
  )

  const _onMultiLanguageCategoryClicked = useMultilanguageForm(
    {
      title: t('DESIGNER.MICROSERVICE_FORM.EDIT_MULTILANGUAGE_CATEGORY'),
      isFooterAtBottom: true,
    },
    'category',
    watchCategory,
    updateCategoryField,
    methods
  )

  const createMicroservice = useCreateMicroservice()
  const updateMicroservice = useUpdateMicroservice()
  const [isSuccess, setIsSuccess] = useState(false)
  const [isError, setIsError] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [translatedByIAStateName, setTranslatedByIAStateName] = useState<{ [code: string]: boolean }>({})
  const [translatedByIAStateDescription, setTranslatedByIAStateDescription] = useState<{ [code: string]: boolean }>({})
  const [translatedByIAStateCategory, setTranslatedByIAStateCategory] = useState<{ [code: string]: boolean }>({})
  const navigate = useNavigate()
  const { setSuccessMessage, setErrorMessage } = useFeedbackMessage()
  const iconBackground = methods.watch('iconBackground')
  const watchScope = methods.watch('scope')
  const watchLanguage = methods.watch('language')
  const watchOperatingSystem = methods.watch('operatingSystem')

  useEffect(() => {
    if (watchLanguage !== MicroserviceLanguage.BASH) {
      if (watchOperatingSystem?.includes(MicroserviceOperatingSystem.WINDOWS)) {
        methods.setValue('operatingSystem', [MicroserviceOperatingSystem.WINDOWS])
      }
    }
  }, [watchLanguage])

  useEffect(() => {
    const initialStateName = watchName.reduce((acc: { [code: string]: boolean }, field) => {
      acc[field.code] = field.translatedByIA || false
      return acc
    }, {})
    setTranslatedByIAStateName(initialStateName)

    const initialStateDescription = watchDescription.reduce((acc: { [code: string]: boolean }, field) => {
      acc[field.code] = field.translatedByIA || false
      return acc
    }, {})
    setTranslatedByIAStateDescription(initialStateDescription)

    const initialStateCategory = watchCategory.reduce((acc: { [code: string]: boolean }, field) => {
      acc[field.code] = field.translatedByIA || false
      return acc
    }, {})
    setTranslatedByIAStateCategory(initialStateCategory)
  }, [watchName, watchDescription, watchCategory])

  useEffect(() => {
    const hasWorkspaceScope = watchScope?.includes(MicroserviceScope.WORKSPACE)
    const hasSystemScope = Boolean(methods.getValues().context)

    if (hasWorkspaceScope && !hasSystemScope) {
      methods.setValue('context', MicroserviceContext.SYSTEM)
    }

    if (!hasWorkspaceScope && hasSystemScope) {
      methods.setValue('context', null)
    }
  }, [watchScope])

  useEffect(() => {
    if (microservice?.name) {
      setNameFields(microservice.name)
    }
    if (microservice?.description) {
      setDescriptionFields(microservice.description)
    }
    if (microservice?.category) {
      setCategoryFields(microservice.category)
    }
  }, [microservice])

  const handleSubmit = (data: MicroserviceFormType) => {
    const dataWithoutVersion = { ...data }
    delete dataWithoutVersion.version

    if (onSubmit) return onSubmit(dataWithoutVersion)

    if (!isCreate) {
      delete dataWithoutVersion.organizationId

      return updateMicroservice
        .mutateAsync({
          microserviceId: microservice!._id,
          input: dataWithoutVersion,
          defaultLang: preferredLanguage,
        })
        .then(() => {
          setSuccessMessage(t('DESIGNER.MICROSERVICE_FORM.UPDATE_SUCCESS'))
          navigate(`/microservices-designer/${microservice!._id}`)
        })
        .catch(() => setErrorMessage(t('DESIGNER.MICROSERVICE_FORM.UPDATE_ERROR')))
    }

    return createMicroservice
      .mutateAsync({
        microservice: dataWithoutVersion,
        defaultLang: preferredLanguage,
      })
      .then((response) => {
        setSuccessMessage(t('DESIGNER.MICROSERVICE_FORM.ADD_SUCCESS'))
        navigate(`/microservices-designer/${response._id}`)
      })
      .catch(() => {
        setErrorMessage(t('DESIGNER.MICROSERVICE_FORM.ADD_ERROR'))
      })
  }

  const renderForm = (): ReactNode => {
    const languageFields = Object.values(Languages).map((code) => {
      return {
        code: code,
        label_name: t(`DESIGNER.MICROSERVICE_FORM.NAME_${code.toUpperCase()}_INPUT_LABEL`),
        placeholder_name: t(`DESIGNER.MICROSERVICE_FORM.NAME_${code.toUpperCase()}_INPUT_PLACEHOLDER`),
        label_description: t(`DESIGNER.MICROSERVICE_FORM.DESCRIPTION_${code.toUpperCase()}_INPUT_LABEL`),
        placeholder_description: t(`DESIGNER.MICROSERVICE_FORM.DESCRIPTION_${code.toUpperCase()}_INPUT_PLACEHOLDER`),
        label_category: t(`DESIGNER.MICROSERVICE_FORM.CATEGORY_${code.toUpperCase()}_INPUT_LABEL`),
        placeholder_category: t(`DESIGNER.MICROSERVICE_FORM.CATEGORY_${code.toUpperCase()}_INPUT_PLACEHOLDER`),
      }
    })

    const getControlName = (code: string, field: string) => {
      let index
      if (field === 'name') {
        index = watchName.findIndex((f) => f.code === code)
      } else if (field === 'description') {
        index = watchDescription.findIndex((f) => f.code === code)
      } else if (field === 'category') {
        index = watchCategory.findIndex((f) => f.code === code)
      }
      return `${field}[${index}].text`
    }

    const field = languageFields.filter((i) => i.code === preferredLanguage)[0]
    const index = languageFields.findIndex((field) => field.code === preferredLanguage)

    const showIconName = translatedByIAStateName[field.code] || false
    const showIconDescription = translatedByIAStateDescription[field.code] || false
    const showIconCategory = translatedByIAStateCategory[field.code] || false

    const handleNameChange = (e: BaseSyntheticEvent) => {
      const index = watchName.findIndex((field) => field.code === preferredLanguage)
      const updatedName = [...watchName]
      updatedName[index] = { ...updatedName[index], text: e.target.value, translatedByIA: false }
      methods.setValue('name', updatedName)

      setTranslatedByIAStateName((prevState) => ({
        ...prevState,
        [field.code]: false,
      }))
    }

    const handleDescriptionChange = (e: BaseSyntheticEvent) => {
      const index = watchDescription.findIndex((field) => field.code === preferredLanguage)
      const updatedDescription = [...watchDescription]
      updatedDescription[index] = { ...updatedDescription[index], text: e.target.value, translatedByIA: false }
      methods.setValue('description', updatedDescription)

      setTranslatedByIAStateDescription((prevState) => ({
        ...prevState,
        [field.code]: false,
      }))
    }

    const handleCategoryChange = (e: BaseSyntheticEvent) => {
      const index = watchCategory.findIndex((field) => field.code === preferredLanguage)
      const updatedCategory = [...watchCategory]
      updatedCategory[index] = { ...updatedCategory[index], text: e.target.value, translatedByIA: false }
      methods.setValue('category', updatedCategory)

      setTranslatedByIAStateCategory((prevState) => ({
        ...prevState,
        [field.code]: false,
      }))
    }

    const onRenderOption: IRenderFunction<ISelectableOption> = (option?: ISelectableOption, defaultRender?) => {
      if (option?.data?.preview) {
        return (
          <>
            {option?.text}
            <div id="titleTagPage" className={`content-header__title-tag`} style={{ fontSize: 12 }}>
              PREVIEW
            </div>
          </>
        )
      }
      return defaultRender?.(option) ?? <>{option?.text}</>
    }
    const onRenderTitle: IRenderFunction<IDropdownOption[]> = (options?: IDropdownOption[], defaultRender?) => {
      const option = options?.[0]
      if (option?.data?.preview) {
        return (
          <>
            {option?.text}
            <div
              id="titleTagPage"
              className={`content-header__title-tag`}
              style={{ fontSize: 12, display: 'inline', top: -1 }}
            >
              PREVIEW
            </div>
          </>
        )
      }
      return defaultRender?.(options) ?? <>{option?.text}</>
    }

    const languageWatch = methods.watch('language')
    return (
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(handleSubmit)}>
          <Row>
            <Col className={'col-xs-12 col-lg-6'}>
              <RowColLayout rowProps={{ classNames: 'mt-2' }}>
                <ControlledTextField
                  name={getControlName(field.code, 'name')}
                  key={getControlName(field.code, 'name')}
                  multiLang={true}
                  multiLangIcon={showIconName}
                  index={index}
                  translator={t}
                  textFieldProps={{
                    label: field.label_name,
                    placeholder: field.placeholder_name,
                    onChange: handleNameChange,
                    onRenderSuffix: () => {
                      if (preferredLanguage === field.code) {
                        return (
                          <span className="cursor-pointer" onClick={() => _onMultiLanguageNameClicked()}>
                            {t('DESIGNER.MICROSERVICE_FORM.SHOW_ALL_LANGUAGES')}
                          </span>
                        )
                      }
                      return null
                    },
                  }}
                />
              </RowColLayout>
            </Col>
            <Col className="col-xs-12 col-lg-6">
              <RowColLayout rowProps={{ classNames: 'mt-2' }}>
                <Dropdown
                  name="organizationId"
                  required={true}
                  translator={t}
                  dropdownProps={{
                    disabled: true,
                    responsiveMode: ResponsiveMode.large,
                    options: options,
                    label: t('DESIGNER.TABLE.LIBRARY'),
                    defaultSelectedKey:
                      editedData?.organizationId ?? microservice?.business._id ?? selectedOrganizationId,
                  }}
                />
              </RowColLayout>
            </Col>
          </Row>
          <Row>
            <Col className="col-xs-12 col-lg-6">
              <RowColLayout rowProps={{ classNames: 'mt-2' }}>
                <ColorSelector
                  name="iconBackground"
                  translator={t}
                  key={'iconBackground'}
                  label={t('DESIGNER.MICROSERVICE_FORM.ICON_COLOR_INPUT_LABEL')}
                  placeholder={t('DESIGNER.MICROSERVICE_FORM.ICON_COLOR_INPUT_PLACEHOLDER')}
                  searchBarPlaceholder={t('DESIGNER.MICROSERVICE_FORM.ICON_SEARCH_BAR_PLACEHOLDER')}
                  defaultSelectedKey={editedData?.iconBackground ?? microservice?.iconBackground}
                />
              </RowColLayout>
            </Col>
            <Col className="col-xs-12 col-lg-6">
              <RowColLayout rowProps={{ classNames: 'mt-2' }}>
                <Suspense fallback={<Spinner />}>
                  <IconSelector
                    name="iconName"
                    translator={t}
                    key={'iconName'}
                    background={iconBackground}
                    label={t('DESIGNER.MICROSERVICE_FORM.ICON_INPUT_LABEL')}
                    placeholder={t('DESIGNER.MICROSERVICE_FORM.ICON_INPUT_PLACEHOLDER')}
                    searchBarPlaceholder={t('DESIGNER.MICROSERVICE_FORM.ICON_SEARCH_BAR_PLACEHOLDER')}
                    defaultSelectedKey={editedData?.iconName ?? microservice?.iconName}
                  />
                </Suspense>
              </RowColLayout>
            </Col>
          </Row>
          <Row>
            <Col className={'col-xs-12 col-lg-12'}>
              <RowColLayout rowProps={{ classNames: 'mt-2' }}>
                <ControlledTextField
                  name={getControlName(field.code, 'description')}
                  key={getControlName(field.code, 'description')}
                  multiLang={true}
                  multiLangIcon={showIconDescription}
                  index={index}
                  translator={t}
                  textFieldProps={{
                    label: field.label_description,
                    placeholder: field.placeholder_description,
                    onChange: handleDescriptionChange,
                    onRenderSuffix: () => {
                      if (preferredLanguage === field.code) {
                        return (
                          <span className="cursor-pointer" onClick={() => _onMultiLanguageDescriptionClicked()}>
                            {t('DESIGNER.MICROSERVICE_FORM.SHOW_ALL_LANGUAGES')}
                          </span>
                        )
                      }
                      return null
                    },
                  }}
                />
              </RowColLayout>
            </Col>
          </Row>
          <Row classNames="mt-2">
            <Col className="col-xs-12 col-lg-6">
              <Dropdown
                name="language"
                required={true}
                translator={t}
                dropdownProps={{
                  responsiveMode: ResponsiveMode.large,
                  options: microserviceLanguageOptions(t),
                  label: t('DESIGNER.MICROSERVICE_FORM.LANGUAGE_INPUT_LABEL'),
                  placeholder: t('DESIGNER.MICROSERVICE_FORM.LANGUAGE_INPUT_PLACEHOLDER'),
                  selectedKey: watchLanguage,
                  onRenderOption: onRenderOption,
                  onRenderTitle: onRenderTitle,
                }}
              />
            </Col>
            <Col className="col-xs-12 col-lg-6">
              <ControlledTextField
                name="version"
                translator={t}
                textFieldProps={{
                  label: t('DESIGNER.MICROSERVICE_FORM.VERSION_INPUT_LABEL'),
                  placeholder: t('DESIGNER.MICROSERVICE_FORM.VERSION_INPUT_PLACEHOLDER'),
                  readOnly: true,
                }}
              />
            </Col>
          </Row>
          <Row>
            <Col className="col-xs-12 col-lg-6 mt-2">
              <Multiselect
                name="scope"
                options={microserviceScopeOptions(t)}
                translator={t}
                multiselectProps={{
                  label: t('DESIGNER.MICROSERVICE_FORM.SCOPE_INPUT_LABEL'),
                  placeholder: t('DESIGNER.MICROSERVICE_FORM.SCOPE_INPUT_PLACEHOLDER'),
                  required: true,
                }}
                selectedList={editedData?.scope ?? microservice?.scope}
              />
            </Col>
            <Col className="col-xs-12 col-lg-6 mt-2">
              <Dropdown
                name="context"
                translator={t}
                dropdownProps={{
                  disabled: !watchScope?.includes(MicroserviceScope.WORKSPACE),
                  responsiveMode: ResponsiveMode.large,
                  options: microserviceContextOptions(t),
                  label: t('DESIGNER.MICROSERVICE_FORM.CONTEXT_INPUT_LABEL'),
                  defaultSelectedKey:
                    editedData?.context ??
                    microservice?.context ??
                    (watchScope?.includes(MicroserviceScope.WORKSPACE) ? MicroserviceContext.SYSTEM : null),
                }}
              />
            </Col>
          </Row>
          <Row>
            <Col className="col-xs-12 col-lg-6 mt-2">
              <Multiselect
                name="operatingSystem"
                options={microserviceOperatingSystemOptions(t, languageWatch as MicroserviceLanguage)}
                translator={t}
                multiselectProps={{
                  label: t('DESIGNER.MICROSERVICE_FORM.OPERATING_SYSTEM_INPUT_LABEL'),
                  placeholder: t('DESIGNER.MICROSERVICE_FORM.OPERATING_SYSTEM_INPUT_PLACEHOLDER'),
                  required: true,
                }}
                onRenderOption={onRenderOption}
                selectedList={watchOperatingSystem ?? []}
              />
            </Col>
            <Col className={'col-xs-12 col-lg-6 mt-2'}>
              <ControlledTextField
                name={getControlName(field.code, 'category')}
                key={getControlName(field.code, 'category')}
                multiLang={true}
                multiLangIcon={showIconCategory}
                index={index}
                translator={t}
                textFieldProps={{
                  label: field.label_category,
                  placeholder: field.placeholder_category,
                  onChange: handleCategoryChange,
                  onRenderSuffix: () => {
                    if (preferredLanguage === field.code) {
                      return (
                        <span className="cursor-pointer" onClick={() => _onMultiLanguageCategoryClicked()}>
                          {t('DESIGNER.MICROSERVICE_FORM.SHOW_ALL_LANGUAGES')}
                        </span>
                      )
                    }
                    return null
                  },
                }}
              />
            </Col>
          </Row>
          <Row>
            <Col className="col-xs-12 col-lg-6 mt-2">
              <ControlledTextField
                name="efficiency"
                required={true}
                translator={t}
                textFieldProps={{
                  label: t('DESIGNER.MICROSERVICE_FORM.EFFICIENCY_INPUT_LABEL'),
                  placeholder: t('DESIGNER.MICROSERVICE_FORM.EFFICIENCY_INPUT_PLACEHOLDER'),
                  type: 'number',
                  min: 5,
                }}
              />
            </Col>
          </Row>
          <div
            style={{
              display: 'flex',
              justifyContent: microservice ? 'center' : 'flex-end',
              marginTop: '100px',
            }}
          >
            {renderFormActionButtons()}
          </div>
        </form>
      </FormProvider>
    )
  }
  const renderFormActionButtons = (): ReactNode => {
    if (isCreate) {
      return (
        <FormActions
          cancelProps={{ onClick: () => onCancel(isDirty) }}
          submitProps={{
            text: t('general:PAGINATION.NEXT'),
            iconProps: { iconName: 'Next' },
          }}
        />
      )
    }

    return (
      <FormActions
        cancelProps={{
          onClick: () => {
            onCancel(isDirty)
          },
        }}
        submitProps={{
          text: t('general:BUTTON.SAVE'),
          iconProps: { iconName: 'Save' },
        }}
      />
    )
  }
  const renderResultPanel = (): ReactNode => {
    if (createMicroservice.isError || updateMicroservice.isError) {
      const error: any = createMicroservice.error || updateMicroservice.error
      return renderError(error.response?.errors[0]?.message)
    }
  }
  const renderError = (error?: string): ReactNode => {
    const errorMessage = error?.includes('Duplicated entity.')
      ? t('DESIGNER.MICROSERVICE_FORM.ADD_ERROR_DUPLICATED')
      : updateMicroservice.isError
      ? t('DESIGNER.MICROSERVICE_FORM.UPDATE_ERROR')
      : t('DESIGNER.MICROSERVICE_FORM.ADD_ERROR')

    return (
      <>
        <ErrorStatus message={errorMessage} />
        <PrimaryButton
          text={t('general:BUTTON.KEEP_EDITING')}
          onClick={() => {
            createMicroservice.reset()
            updateMicroservice.reset()
          }}
          iconProps={{ iconName: 'NavigateBack' }}
        />
      </>
    )
  }

  useEffect(() => {
    setIsSuccess(createMicroservice.isSuccess || updateMicroservice.isSuccess)
    setIsError(createMicroservice.isError || updateMicroservice.isError)
    setIsLoading(createMicroservice.isLoading || updateMicroservice.isLoading)
  }, [createMicroservice])

  return (
    <>
      {!isLoading && !isSuccess && !isError && renderForm()}

      {isLoading && <LoadingSpinner />}

      {isError && renderResultPanel()}
    </>
  )
}

