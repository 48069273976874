import { LoadingSpinner } from '@flexxibleit/flexxible-ui'
import Dialog from '../../../components/Dialog/Dialog'
import { useTranslation } from 'react-i18next'
import { ApiKey } from '../../../query-client/types'
import { useEffect, useState } from 'react'
import { useRevokeApiKey } from '../../../hooks/apiKey/useRevokeApiKey'
import { mergeStyleSets, Modal } from '@fluentui/react'
import { roleLocalizationKeyDictionary } from '../../../permissions/RoleFeature.enum'
import { dateFormatter } from 'app/services/date-formatter'

type DialogState = 'prompt' | 'loading' | 'success' | 'error'

export const useApiKeyRevocationDialog = (organizationId: string) => {
  const { t } = useTranslation('organization_details')
  const [selectedApiKeyState, setSelectedApiKeyState] = useState<
    ApiKey | undefined
  >(undefined)
  const [dialogOpenState, setDialogOpenState] = useState<boolean>(false)
  const [dialogState, setDialogState] = useState<DialogState>('prompt')
  const {
    mutate: revokeApiKey,
    isLoading,
    isSuccess,
    isError,
  } = useRevokeApiKey(organizationId)

  const openApiKeyRevocationDialog = (apiKey: ApiKey) => {
    setSelectedApiKeyState(apiKey)
    setDialogState('prompt')
    setDialogOpenState(true)
  }

  const dismissDialog = () => {
    setDialogOpenState(false)
  }

  const confirmRevocation = () => {
    if (!selectedApiKeyState) {
      return
    }
    revokeApiKey(selectedApiKeyState._id)
  }

  useEffect(() => {
    if (isLoading) {
      setDialogState('loading')
    } else if (isSuccess) {
      setDialogState('success')
    } else if (isError) {
      setDialogState('error')
    }
  }, [isLoading, isSuccess, isError])

  const renderApiKeyDetails = () => {
    if (!selectedApiKeyState) {
      return
    }
    return (
      <>
        <div>{t('API_KEYS.REVOCATION_DIALOG.API_KEY_DETAILS')}</div>
        <ul>
          <li>{`${t('API_KEYS.TABLE.KEY_ID')}: ${selectedApiKeyState._id}`}</li>
          <li>{`${t('API_KEYS.TABLE.ROLE')}: ${t(
            roleLocalizationKeyDictionary[
              selectedApiKeyState.programmaticUser.role
            ]
          )}`}</li>
          <li>{`${t('API_KEYS.TABLE.CREATED_BY')}: ${
            selectedApiKeyState.createdBy.name
          } ${selectedApiKeyState.createdBy.surname} (${
            selectedApiKeyState.createdBy.email
          })`}</li>
          <li>{`${t('API_KEYS.TABLE.CREATED_AT')}: ${dateFormatter(
            t,
            selectedApiKeyState.created_at
          )}`}</li>
          <li>{`${t('API_KEYS.TABLE.LAST_USED_AT')}: ${
            selectedApiKeyState.lastUsedAt
              ? dateFormatter(t, selectedApiKeyState.lastUsedAt)
              : t('API_KEYS.REVOCATION_DIALOG.KEY_NEVER_USED')
          }`}</li>
          <li>{`${t('API_KEYS.TABLE.EXPIRES_AT')}: ${dateFormatter(
            t,
            selectedApiKeyState.expiresAt
          )}`}</li>
        </ul>
      </>
    )
  }

  const renderKeyRevocationPrompt = () => {
    return (
      <Dialog
        title={t('API_KEYS.REVOCATION_DIALOG.TITLE')}
        description={`${t('API_KEYS.REVOCATION_DIALOG.DESCRIPTION')}`}
        children={renderApiKeyDetails()}
        actionButton={t('general:BUTTON.OK')}
        callback={confirmRevocation}
        dismissButton={t('general:BUTTON.CANCEL')}
        onDismiss={dismissDialog}
        maxWidth={480}
        hidden={!(dialogOpenState && dialogState === 'prompt')}
      />
    )
  }

  const loadingModalStyles = mergeStyleSets({
    container: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
  })

  const renderLoadingModal = () => {
    return (
      <Modal
        isBlocking={true}
        isModeless={true}
        isOpen={dialogOpenState && dialogState === 'loading'}
        containerClassName={loadingModalStyles.container}
      >
        <LoadingSpinner />
      </Modal>
    )
  }

  const renderSuccessStateDialog = () => {
    return (
      <Dialog
        title={t('API_KEYS.REVOCATION_DIALOG.SUCCESS_TITLE')}
        description={t('API_KEYS.REVOCATION_DIALOG.SUCCESS_DESCRIPTION')}
        actionButton={t('general:BUTTON.OK')}
        callback={dismissDialog}
        hidden={!(dialogOpenState && dialogState === 'success')}
      />
    )
  }

  const renderErrorStateDialog = () => {
    return (
      <Dialog
        title={t('API_KEYS.REVOCATION_DIALOG.ERROR_TITLE')}
        description={t('API_KEYS.REVOCATION_DIALOG.ERROR_DESCRIPTION')}
        actionButton={t('general:BUTTON.OK')}
        callback={dismissDialog}
        hidden={!(dialogOpenState && dialogState === 'error')}
      />
    )
  }

  const renderApiKeyRevocationDialog = () => {
    return (
      <>
        {renderKeyRevocationPrompt()}
        {renderLoadingModal()}
        {renderSuccessStateDialog()}
        {renderErrorStateDialog()}
      </>
    )
  }

  return {
    renderApiKeyRevocationDialog,
    openApiKeyRevocationDialog,
  }
}
