import { TFunction } from 'react-i18next'

export const useFlowsBuildExport = (t: TFunction) => {
  const flowTableExportColumns = [
    {
      key: 'name',
      header: t('TABLE.NAME'),
      width: 20,
    },
    {
      key: 'description',
      header: t('TABLE.DESCRIPTION'),
      width: 20,
    },
    {
      key: 'type',
      header: t('TABLE.TYPE'),
      width: 20,
    },
    {
      key: 'detectionOnly',
      header: t('TABLE.DETECTIONONLY'),
      width: 20,
    },
    {
      key: 'cooldown',
      header: t('TABLE.COOLDOWN'),
      width: 20,
    },
  ]

  return { flowTableExportColumns }
}
