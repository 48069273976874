import {
  ControlledToggleButton,
  DeleteActionButton,
  Dialog,
  ErrorStatus,
  FormActionsContainer,
  LoadingSpinner,
  RowColLayout,
  SuccessStatus,
} from '@flexxibleit/flexxible-ui'
import { useBoolean } from '@fluentui/react-hooks'
import { zodResolver } from '@hookform/resolvers/zod'
import FormActions from 'app/components/forms/FormActions'
import { useFormSidebarContext } from 'app/components/forms/FormSidebar/state/FormSidebarState'
import { FormProvider, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import * as z from 'zod'
import { IDropdownOption, IPersonaProps, ResponsiveMode } from '@fluentui/react'
import Dropdown from 'app/components/forms/Dropdown'
import { CompactPeoplePicker } from 'app/components/forms/PeoplePicker/CompactPeoplePicker'
import { RolePermission, UserRole } from 'app/query-client/types'
import useGetOrganizationTree from 'app/hooks/organization/useGetOrganizationTree'
import { ReactNode, useEffect, useState } from 'react'
import useCreateRolePermission from 'app/hooks/roles/useCreateRolePermission'
import useUpdateRolePermission from 'app/hooks/roles/useUpdateRolePermission'
import useDeleteRolePermission from 'app/hooks/roles/useDeleteRolePermission'
import { useOrganizationSelection } from 'app/globals/useOrganizationSelection'
import { SearchableDropdown } from 'app/components/SearchableDropdown/SearchableDropdown.component'
import { hasEnoughPortalPermissions, PortalPermission } from '../../../../../permissions/UserPortalPermission.enum'
import { Roles } from '../../../../../permissions/RoleFeature.enum'
import { hasEnoughWorkspacesPermissions } from '../../../../../permissions/UserWorkspacePermission.enum'
import { hasEnoughAnalyzerPermissions } from '../../../../../permissions/UserAnalyzerPermission.enum'

export interface Props {
  userRole: UserRole
  rolePermission?: RolePermission
}

interface FormValues {
  allOrganizations: boolean
  organization: string
  portal: string
  workspaces: string
  analyzer: string
  allReportingGroups: boolean
  reportingGroups: string[]
}

const schema: z.ZodType<FormValues> = z.object({
  allOrganizations: z.boolean({ required_error: 'required' }),
  organization: z.string({ required_error: 'required' }),
  portal: z.string({ required_error: 'required' }).min(1, { message: 'required' }),
  workspaces: z.string({ required_error: 'required' }).min(1, { message: 'required' }),
  analyzer: z.string({ required_error: 'required' }).min(1, { message: 'required' }),
  allReportingGroups: z.boolean({ required_error: 'required' }),
  reportingGroups: z.array(z.string().min(1, { message: 'required' })),
})

export const RolPermissionsForm = (props: Props) => {
  const { userRole, rolePermission } = props
  const { t } = useTranslation('organization_details')
  const createRolePermission = useCreateRolePermission()
  const updateRolePermission = useUpdateRolePermission()
  const deleteRolePermission = useDeleteRolePermission()
  const [hideDeleteDialog, { toggle: toggleHideDeleteDialog }] = useBoolean(true)
  const { closeSidebar } = useFormSidebarContext()
  const isEdit = rolePermission ? true : false
  const { rolesOnBusiness } = useOrganizationSelection()
  const [reportingGroupsOptions, setReportingGroupsOptions] = useState<IPersonaProps[]>([])
  const { isLoading: organizationsLoading, data: organizationList } = useGetOrganizationTree({
    organizationId: userRole?.organizationId || '',
  })
  const [isLoading, setIsLoading] = useState(organizationsLoading)
  const [isSuccess, setIsSuccess] = useState(false)
  const [isError, setIsError] = useState(false)
  const [selectedOrganization, setSelectedOrganization] = useState<string | undefined>(undefined)
  const [canSeeDeleteButton, setCanSeeDeleteButton] = useState<boolean>(false)
  const canAllOrganizations = !userRole.permissions?.some((permission) => permission.allOrganizations === true)

  useEffect(() => {
    setIsSuccess(createRolePermission.isSuccess || updateRolePermission.isSuccess)
    setIsError(createRolePermission.isError || updateRolePermission.isError)
    setIsLoading(createRolePermission.isLoading || updateRolePermission.isLoading)
  }, [createRolePermission, updateRolePermission])

  useEffect(() => {
    const initialValue = rolePermission?.organization?._id || undefined
    setSelectedOrganization(initialValue)
  }, [])


  useEffect(() => {
    setCanSeeDeleteButton(false)
    if (!rolesOnBusiness) {
      return;
    }
    
    if (!props?.rolePermission) {
      return;
    }
    
    if (props.rolePermission && props.userRole?.permissions?.length === 1) {
      return;
    }
    
    if (rolesOnBusiness.portal === PortalPermission.ADMIN) {
      setCanSeeDeleteButton(true)
    } else {
      if (hasEnoughPortalPermissions(rolesOnBusiness.portal, props.rolePermission.portal) 
        && hasEnoughWorkspacesPermissions(rolesOnBusiness.workspaces, props.rolePermission.workspaces)
        && hasEnoughAnalyzerPermissions(rolesOnBusiness.analyzer, props.rolePermission.analyzer)
      ) {
        setCanSeeDeleteButton(true)
      }
    }
    
  }, [rolesOnBusiness, props?.rolePermission])

  const getDefaultValues = (): FormValues => {
    return {
      allOrganizations: rolePermission?.allOrganizations || false,
      organization: rolePermission?.organization?._id || '',
      portal: rolePermission?.portal || '',
      workspaces: rolePermission?.workspaces || '',
      analyzer: rolePermission?.analyzer || '',
      allReportingGroups: rolePermission?.allReportingGroups || false,
      reportingGroups: rolePermission?.reportingGroups.map((group) => group._id) || [],
    }
  }

  const methods = useForm<FormValues>({
    resolver: zodResolver(schema),
    defaultValues: { ...getDefaultValues() },
  })

  //const watchOrganization = methods.watch('organization')
  const watchAllReportingGroups = methods.watch('allReportingGroups')
  const watchAllOrganizations = methods.watch('allOrganizations')

  useEffect(() => {
    setReportingGroupsOptions([])
    if (watchAllOrganizations) {
      setSelectedOrganization(undefined)
      return
    }
    if (selectedOrganization) {
      const reportingGroups = organizationList?.find((org) => org._id === selectedOrganization)?.reportingGroups
      const reportingGroupsOpts = reportingGroups?.map((group) => ({ key: group._id, text: group.name }))
      setReportingGroupsOptions(reportingGroupsOpts || [])
    }
  }, [watchAllOrganizations, selectedOrganization, organizationList])

  const onSubmit = (values: FormValues) => {
    if (isEdit) {
      const updateItem = {
        organizationId: userRole?.organizationId || '',
        userRoleId: userRole?._id || '',
        allOrganizations: values.allOrganizations,
        organization: selectedOrganization || '',
        portal: values.portal,
        workspaces: values.workspaces,
        analyzer: values.analyzer,
        allReportingGroups: values.allReportingGroups,
        reportingGroups: values.reportingGroups,
      }
      updateRolePermission.mutate(updateItem)
    } else {
      const createItem = {
        organizationId: userRole?.organizationId || '',
        userRoleId: userRole?._id || '',
        allOrganizations: values.allOrganizations,
        organization: selectedOrganization || '',
        portal: values.portal,
        workspaces: values.workspaces,
        analyzer: values.analyzer,
        allReportingGroups: values.allReportingGroups,
        reportingGroups: values.reportingGroups,
      }

      createRolePermission.mutate(createItem)
    }
  }

  const onDelete = () => {
    toggleHideDeleteDialog()
  }

  const renderDeleteConfirmation = (): JSX.Element => {
    return (
      <Dialog
        title={t('ROLES.PERMISSIONS.DELETE_CONFIRMATION.TITLE')}
        description={t('ROLES.PERMISSIONS.DELETE_CONFIRMATION.MESSAGE')}
        actionButton={t('ROLES.PERMISSIONS.DELETE_CONFIRMATION.BUTTON_ACCEPT')}
        dismissButton={t('ROLES.PERMISSIONS.DELETE_CONFIRMATION.BUTTON_CANCEL')}
        hidden={hideDeleteDialog}
        onDismiss={toggleHideDeleteDialog}
        callback={() => {
          toggleHideDeleteDialog()
          handleDeleteRolePermission()
        }}
      />
    )
  }
  const handleDeleteRolePermission = () => {
    deleteRolePermission
      .mutateAsync({
        organizationId: userRole?.organizationId,
        userRoleId: userRole._id,
        organization: rolePermission?.organization?._id || '',
      })
      .then(() => {
        closeSidebar()
      })
  }

  const getDeleteErrorMessage = (error: any) => {
    const errorText = error?.response?.errors?.[0]?.message || ''
    let errorMessageKey: string

    switch (true) {
      case errorText.includes('You dont have permission.'):
        errorMessageKey = 'ROLES.PERMISSIONS.FORM.ERROR_PERMISSIONS_MESSAGE'
        break
      default:
        errorMessageKey = 'ROLES.PERMISSIONS.FORM.DELETE_ERROR_MESSAGE'
    }

    return t(errorMessageKey)
  }

  if (deleteRolePermission.isError) {
    return (
      <div className="d-flex h-100">
        <ErrorStatus message={getDeleteErrorMessage(deleteRolePermission?.error)} />
      </div>
    )
  }

  const dropdownOptionsPortal: IDropdownOption[] = [
    { key: 'USER', text: t('general:ROLE.USER') },
    { key: 'ORGANIZATION_ADMIN', text: t('general:ROLE.ORGANIZATION_ADMIN') },
    { key: 'ADMIN', text: t('general:ROLE.ADMIN') },
  ]


  const checkDisablePortal = ()  => {
    const portalAuthUserPermission = rolesOnBusiness?.portal;
    if (!portalAuthUserPermission) {
      return true
    }

    const portalPermission = rolePermission?.portal;
    if (!portalPermission) {
      return false
    }

    return !hasEnoughPortalPermissions(portalAuthUserPermission, portalPermission);

  }
  
  const filterRoleOptions = (): IDropdownOption[] => {

    let returnedOptions: IDropdownOption[] = [];

    if (rolesOnBusiness?.isPortalAdmin()) {
      returnedOptions = dropdownOptionsPortal;
    }

    if (rolesOnBusiness?.isOrganizationAdmin()) {
      returnedOptions = dropdownOptionsPortal.filter(option => option.key !== Roles.ADMIN);
    }

    if (props.rolePermission) {
      const portalPermission = props.rolePermission.portal;

      const isPortalPermissionIncluded = returnedOptions.some(option => option.key === portalPermission);

      if (!isPortalPermissionIncluded) {
        const portalPermissionOption = dropdownOptionsPortal.find(option => option.key === portalPermission);

        if (portalPermissionOption) {
          returnedOptions = [...returnedOptions, portalPermissionOption];
        }
      }
    }

    return returnedOptions;
  };



  const dropdownWorkspacesPermissionsOptions: IDropdownOption[] = [
    { key: 'NO_ACCESS', text: t('USERS.WORKSPACE_PERMISSION.NO_ACCESS') },
    { key: 'L1_SUPPORT_TEAM_READ_ONLY', text: t('USERS.WORKSPACE_PERMISSION.L1_RO') },
    { key: 'L1_SUPPORT_TEAM', text: t('USERS.WORKSPACE_PERMISSION.L1') },
    { key: 'L2_SUPPORT_TEAM_READ_ONLY', text: t('USERS.WORKSPACE_PERMISSION.L2_RO') },
    { key: 'L2_SUPPORT_TEAM', text: t('USERS.WORKSPACE_PERMISSION.L2') },
    { key: 'L3_ENGINEERING_TEAM_READ_ONLY', text: t('USERS.WORKSPACE_PERMISSION.L3_RO') },
    { key: 'L3_ENGINEERING_TEAM', text: t('USERS.WORKSPACE_PERMISSION.L3') },
    { key: 'ADMIN_READ_ONLY', text: t('USERS.WORKSPACE_PERMISSION.ADMIN_RO') },
    { key: 'ADMIN', text: t('USERS.WORKSPACE_PERMISSION.ADMIN') },
  ]

  const checkDisableWorkspaces = ()  => {

    const portalAuthUserPermission = rolesOnBusiness?.portal;
    if (portalAuthUserPermission === PortalPermission.ADMIN) {
      return false
    }
    
    const workspacesAuthUserPermission = rolesOnBusiness?.workspaces;
    if (!workspacesAuthUserPermission) {
      return true
    }

    const workspacesPermission = rolePermission?.workspaces;
    if (!workspacesPermission) {
      return false
    }

    return !hasEnoughWorkspacesPermissions(workspacesAuthUserPermission, workspacesPermission);

  }

  const filterDropdownOptionsByWorkspacePermissionLevel = (): IDropdownOption[] => {
    let returnedOptions: IDropdownOption[] = [];

    if (rolesOnBusiness?.isPortalAdmin()) {
      returnedOptions = dropdownWorkspacesPermissionsOptions;
    } else {
      const userPermissionIndex = dropdownWorkspacesPermissionsOptions.findIndex(
        (option) => option.key === rolesOnBusiness?.workspaces
      )

      returnedOptions = dropdownWorkspacesPermissionsOptions
        .map((option, index) => {
          if (index <= userPermissionIndex) {
            return option
          }
          return undefined
        })
        .filter((option): option is IDropdownOption => option !== undefined)
    }

    if (props.rolePermission) {
      const workspacePermission = props.rolePermission.workspaces;

      const isWorkspacePermissionIncluded = returnedOptions.some(option => option.key === workspacePermission);

      if (!isWorkspacePermissionIncluded) {
        const workspacePermissionOption = dropdownWorkspacesPermissionsOptions.find(option => option.key === workspacePermission);

        if (workspacePermissionOption) {
          returnedOptions = [...returnedOptions, workspacePermissionOption];
        }
      }
    }


    return returnedOptions;
  };


  const filterDropdownWorkspacePermissionsOptions: IDropdownOption[] =
    filterDropdownOptionsByWorkspacePermissionLevel()

  const dropdownAnalyzerPermissionsOptions: IDropdownOption[] = [
    { key: 'NO_ACCESS', text: t('USERS.ANALYZER_PERMISSION.NO_ACCESS') },
    { key: 'ACCESS', text: t('USERS.ANALYZER_PERMISSION.ACCESS') },
    { key: 'ADMIN', text: t('USERS.ANALYZER_PERMISSION.ADMIN') },
  ]

  const checkDisableAnalyzer = ()  => {

    const portalAuthUserPermission = rolesOnBusiness?.portal;
    if (portalAuthUserPermission === PortalPermission.ADMIN) {
      return false
    }

    
    const analyzerAuthUserPermission = rolesOnBusiness?.analyzer;
    if (!analyzerAuthUserPermission) {
      return true
    }

    const analyzerPermission = rolePermission?.analyzer;
    if (!analyzerPermission) {
      return false
    }

    return !hasEnoughAnalyzerPermissions(analyzerAuthUserPermission, analyzerPermission);

  }

  const filterDropdownOptionsByAnalyzerPermissionLevel = (): IDropdownOption[] => {

    let returnedOptions: IDropdownOption[] = [];

    if (rolesOnBusiness?.isPortalAdmin()) {
      returnedOptions = dropdownAnalyzerPermissionsOptions;
    } else {
      const userPermissionIndex = dropdownAnalyzerPermissionsOptions.findIndex(
        (option) => option.key === rolesOnBusiness?.analyzer
      )

      returnedOptions = dropdownAnalyzerPermissionsOptions
        .map((option, index) => {
          if (index <= userPermissionIndex) {
            return option
          }
          return undefined
        })
        .filter((option): option is IDropdownOption => option !== undefined)
    }

    if (props.rolePermission) {
      const analyzerPermission = props.rolePermission.analyzer;

      const isAnalyzerPermissionIncluded = returnedOptions.some(option => option.key === analyzerPermission);

      if (!isAnalyzerPermissionIncluded) {
        const analyzerPermissionOption = dropdownAnalyzerPermissionsOptions.find(option => option.key === analyzerPermission);

        if (analyzerPermissionOption) {
          returnedOptions = [...returnedOptions, analyzerPermissionOption];
        }
      }
    }
    
    return returnedOptions;
  }
  
  const filteredDropdownAnalyzerPermissionsOptions: IDropdownOption[] = filterDropdownOptionsByAnalyzerPermissionLevel()

  const buildOrganizationOptions = () => {
    if (watchAllOrganizations) {
      return []
    }
    return (
      organizationList?.map((org) => {
        const disabled = userRole.permissions?.some((permission) => permission?.organization?.toString() === org._id)
        return {
          key: org._id,
          text: org.name,
          disabled: disabled,
        }
      }) || []
    )
  }

  const renderCreateError = (error: any): ReactNode => {
    const errorText = error?.response?.errors?.[0]?.message || ''
    let errorMessageKey: string

    switch (true) {
      case errorText.includes('Permission with all organizations already exists.'):
        errorMessageKey = 'ROLES.PERMISSIONS.FORM.ALL_ORGANIZATIONS_ERROR'
        break
      case errorText.includes('Permission with organization already exists.'):
        errorMessageKey = 'ROLES.PERMISSIONS.FORM.ORGANIZATION_ERROR'
        break
      case errorText.includes('You dont have permission.'):
        errorMessageKey = 'ROLES.PERMISSIONS.FORM.ERROR_PERMISSIONS_MESSAGE'
        break
      default:
        errorMessageKey = 'ROLES.PERMISSIONS.FORM.ADD_ERROR_MESSAGE'
    }

    return <ErrorStatus message={t(errorMessageKey)} />
  }
  const renderUpdateError = (error: any): ReactNode => {
    const errorText = error?.response?.errors?.[0]?.message || ''
    let errorMessageKey: string

    switch (true) {
      case errorText.includes('You dont have permission.'):
        errorMessageKey = 'ROLES.PERMISSIONS.FORM.ERROR_PERMISSIONS_MESSAGE'
        break
      default:
        errorMessageKey = 'ROLES.PERMISSIONS.FORM.UPDATE_ERROR_MESSAGE'
    }

    return <ErrorStatus message={t(errorMessageKey)} />
  }

  const handleChangeOrganization = (option: IDropdownOption) => {
    setSelectedOrganization(option.key as string)
  }
  
  return (
    <>
      {!isLoading && !isSuccess && !isError && (
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            <RowColLayout rowProps={{ classNames: 'mt-2' }}>
              <ControlledToggleButton
                name="allOrganizations"
                toggleProps={{
                  disabled: rolePermission || !canAllOrganizations ? true : false,
                  inlineLabel: true,
                  label: t('ROLES.PERMISSIONS.FORM.ALL_ORGANIZATIONS_LABEL'),
                }}
              />
            </RowColLayout>

            {!watchAllOrganizations && (
              <RowColLayout rowProps={{ classNames: 'mt-2' }}>
                <SearchableDropdown
                  dropdownProps={{
                    label: t('ROLES.PERMISSIONS.FORM.ORGANIZATION'),
                    placeholder: t('ROLES.PERMISSIONS.FORM.ORGANIZATION_PLACEHOLDER'),
                    selectedKey: selectedOrganization,
                    options: buildOrganizationOptions(),
                    disabled: rolePermission ? true : false,
                    key: 'organization',
                    required: true,
                    onChange: (event, option) => {
                      handleChangeOrganization(option as IDropdownOption)
                    },
                  }}
                  searchBoxProps={{
                    key: 'organization',
                    name: 'organization',
                    placeholder: t('ROLES.PERMISSIONS.FORM.ORGANIZATION_SEARCH'),
                  }}
                />
              </RowColLayout>
            )}

            <RowColLayout rowProps={{ classNames: 'mt-2' }}>
              <Dropdown
                name="portal"
                translator={t}
                required={true}
                dropdownProps={{
                  responsiveMode: ResponsiveMode.large,
                  label: t('ROLES.PERMISSIONS.FORM.PORTAL'),
                  placeholder: t('ROLES.PERMISSIONS.FORM.PORTAL_PLACEHOLDER'),
                  options: filterRoleOptions(),
                  disabled: checkDisablePortal()
                }}
              />
            </RowColLayout>
            <RowColLayout rowProps={{ classNames: 'mt-2' }}>
              <Dropdown
                name="workspaces"
                translator={t}
                required={true}
                dropdownProps={{
                  responsiveMode: ResponsiveMode.large,
                  label: t('ROLES.PERMISSIONS.FORM.WORKSPACE_PERMISSION'),
                  placeholder: t('ROLES.PERMISSIONS.FORM.WORKSPACE_PERMISSION_PLACEHOLDER'),
                  options: filterDropdownWorkspacePermissionsOptions,
                  disabled: checkDisableWorkspaces()
                }}
              />
            </RowColLayout>
            <RowColLayout rowProps={{ classNames: 'mt-2' }}>
              <Dropdown
                name="analyzer"
                translator={t}
                required
                dropdownProps={{
                  responsiveMode: ResponsiveMode.large,
                  label: t('ROLES.PERMISSIONS.FORM.ANALYZER_PERMISSION'),
                  placeholder: t('ROLES.PERMISSIONS.FORM.ANALYZER_PERMISSION_PLACEHOLDER'),
                  options: filteredDropdownAnalyzerPermissionsOptions,
                  disabled: checkDisableAnalyzer()
                }}
              />
            </RowColLayout>
            <RowColLayout rowProps={{ classNames: 'mt-2' }}>
              <ControlledToggleButton
                name="allReportingGroups"
                toggleProps={{
                  inlineLabel: true,
                  label: t('ROLES.PERMISSIONS.FORM.ALL_REPORTING_GROUPS_LABEL'),
                }}
              />
            </RowColLayout>
            {!watchAllReportingGroups && (
              <RowColLayout rowProps={{ classNames: 'mt-2' }}>
                <CompactPeoplePicker
                  name="reportingGroups"
                  label={t('ROLES.PERMISSIONS.FORM.REPORTING_GROUPS')}
                  placeholder={t('ROLES.PERMISSIONS.FORM.REPORTING_GROUPS_PLACEHOLDER')}
                  options={reportingGroupsOptions}
                  translator={t}
                  peoplePickerProps={{
                    disabled: !selectedOrganization,
                  }}
                  selectedList={rolePermission?.reportingGroups.map((group) => group._id)}
                />
              </RowColLayout>
            )}

            <ActionButtons isEdit={isEdit} onDelete={onDelete} canSeeDeleteButton={canSeeDeleteButton} />
          </form>
          {renderDeleteConfirmation()}
        </FormProvider>
      )}

      {isLoading && <LoadingSpinner></LoadingSpinner>}

      {(isSuccess || isError) && (
        <>
          <div className="d-flex h-100">
            {createRolePermission.isSuccess && (
              <SuccessStatus message={t('ROLES.PERMISSIONS.FORM.ADD_SUCCESS_MESSAGE')} />
            )}

            {createRolePermission.isError && renderCreateError(createRolePermission.error)}

            {updateRolePermission.isSuccess && (
              <SuccessStatus message={t('ROLES.PERMISSIONS.FORM.UPDATE_SUCCESS_MESSAGE')} />
            )}

            {updateRolePermission.isError && renderUpdateError(updateRolePermission.error)}

            {deleteRolePermission.isSuccess && (
              <SuccessStatus message={t('ROLES.PERMISSIONS.FORM.DELETE_SUCCESS_MESSAGE')} />
            )}

            {deleteRolePermission.isError && <ErrorStatus message={t('ROLES.PERMISSIONS.FORM.DELETE_ERROR_MESSAGE')} />}
          </div>
        </>
      )}
    </>
  )
}


const ActionButtons = ({ isEdit, onDelete, canSeeDeleteButton }: { isEdit: boolean; onDelete: () => void, canSeeDeleteButton: boolean }) => {
  const { t, i18n } = useTranslation()

  
  if (isEdit) {
    return (
      <FormActionsContainer isEdit>
        {canSeeDeleteButton && (<DeleteActionButton locale={i18n.language} onClick={onDelete} />)}
        {!canSeeDeleteButton && (<span></span>)}
        
        <FormActions
          submitProps={{
            text: t('general:BUTTON.SAVE'),
            iconProps: { iconName: 'Save' },
          }}
        />
      </FormActionsContainer>
    )
  }

  return (
    <FormActionsContainer>
      <FormActions
        submitProps={{
          text: t('general:BUTTON.CREATE'),
          iconProps: { iconName: 'Add' },
        }}
      />
    </FormActionsContainer>
  )
}
