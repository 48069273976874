import {
  Col,
  ControlledTextField,
  ControlledToggleButton,
  DeleteActionButton,
  Dialog,
  ErrorStatus,
  FormActionsContainer,
  RowColLayout,
  SuccessStatus,
} from '@flexxibleit/flexxible-ui'
import { useBoolean } from '@fluentui/react-hooks'
import { zodResolver } from '@hookform/resolvers/zod'
import FormActions from 'app/components/forms/FormActions'
import { useFormSidebarContext } from 'app/components/forms/FormSidebar/state/FormSidebarState'
import MultilineTextField from 'app/components/forms/MultilineTextField'
import useCreatePolicy from 'app/hooks/policies/useCreatePolicy'
import useDeleteTenantPolicy from 'app/hooks/policies/useDeletePolicy'
import { useUpdateTenantPolicy } from 'app/hooks/policies/useUpdatePolicy'
import { FormProvider, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import * as z from 'zod'
import { GettingStartedChecklistTasks } from '../../../../../user-journey/getting-started-checklist/GettingStartedChecklistTasksEnum'
import { useGettingStartedChecklist } from '../../../../../user-journey/getting-started-checklist/useGettingStartedChecklist'
import useGetProductTypes from 'app/hooks/product/useGetProductTypes'
import { IDropdownOption } from '@fluentui/react'
import useDropdownOptions from 'app/components/hooks/useDropdownOptions'
import Dropdown from 'app/components/forms/Dropdown'

export interface Props {
  organizationId: string
  id?: string
  data?: FormValues
}

interface FormValues {
  name: string
  description?: string
  product?: string
  accessAllUsers?: boolean
}

const schema: z.ZodType<FormValues> = z.object({
  name: z.string({ required_error: 'required' }),
  description: z.string().max(280, { message: 'max' }).optional(),
  product: z.string().optional(),
  accessAllUsers: z.boolean().optional().default(true),
})

export const PolicyInfoForm = (props: Props) => {
  const { t } = useTranslation('organization_details')
  const createPolicy = useCreatePolicy()
  const updatePolicy = useUpdateTenantPolicy()
  const deletePolicy = useDeleteTenantPolicy()
  const [hideDeleteDialog, { toggle: toggleHideDeleteDialog }] = useBoolean(true)
  const { closeSidebar } = useFormSidebarContext()
  const isEdit = Boolean(props.id)
  const navigate = useNavigate()
  const { completeGettingStartedTask } = useGettingStartedChecklist()
  const { data: productData } = useGetProductTypes(true)

  let dropdownOptionsProducts: IDropdownOption[] = useDropdownOptions(productData, '_id', 'name')

  const methods = useForm<FormValues>({
    resolver: zodResolver(schema),
    defaultValues: props.data ?? {
      name: '',
      description: '',
      product: '',
      accessAllUsers: true,
    },
  })
  const onSubmit = (values: FormValues) => {
    if (isEdit) {
      updatePolicy.mutate({
        id: props.id!,
        input: {
          name: values.name,
          description: values.description,
          product: values.product,
          accessAllUsers: values.accessAllUsers,
        },
      })
    } else {
      completeGettingStartedTask(GettingStartedChecklistTasks.ASSIGN_POLICY)
      createPolicy.mutate({ ...values, organizationId: props.organizationId })
    }
  }

  const onDelete = () => {
    toggleHideDeleteDialog()
  }
  const renderDeleteConfirmation = (): JSX.Element => {
    return (
      <Dialog
        title={t('POLICIES.DELETE_CONFIRMATION.TITLE')}
        description={t('POLICIES.DELETE_CONFIRMATION.MESSAGE')}
        actionButton={t('POLICIES.DELETE_CONFIRMATION.BUTTON_ACCEPT')}
        dismissButton={t('POLICIES.DELETE_CONFIRMATION.BUTTON_CANCEL')}
        hidden={hideDeleteDialog}
        onDismiss={toggleHideDeleteDialog}
        callback={() => {
          toggleHideDeleteDialog()
          handleDeletePolicy()
        }}
      />
    )
  }
  const handleDeletePolicy = () => {
    if (props.id && props.organizationId) {
      deletePolicy.mutateAsync({ idTenantPolicy: props.id, idBusiness: props.organizationId }).then(() => {
        closeSidebar()
        navigate(-1)
      })
    }
  }
  const getDeleteErrorMessage = (error: any) => {
    if (error.response?.errors[0]?.message === 'Policy exists on tenant.') {
      return t('POLICIES.FORM.POLICY_EXISTS_ON_TENANT_ERROR')
    }
    return t('POLICIES.FORM.DELETE_ERROR')
  }

  if (createPolicy.isSuccess) {
    return (
      <div className="d-flex h-100">
        <SuccessStatus message={t('POLICIES.FORM.ADD_SUCCESS_MESSAGE')} />
      </div>
    )
  }
  if (createPolicy.isError) {
    return (
      <div className="d-flex h-100">
        <ErrorStatus message={t('POLICIES.FORM.ADD_ERROR_MESSAGE')} />
      </div>
    )
  }

  if (updatePolicy.isSuccess) {
    return (
      <div className="d-flex h-100">
        <SuccessStatus message={t('POLICIES.FORM.EDIT_SUCCESS_MESSAGE')} />
      </div>
    )
  }
  if (updatePolicy.isError) {
    return (
      <div className="d-flex h-100">
        <ErrorStatus message={t('POLICIES.FORM.EDIT_ERROR_MESSAGE')} />
      </div>
    )
  }

  if (deletePolicy.isError) {
    return (
      <div className="d-flex h-100">
        <ErrorStatus message={getDeleteErrorMessage(deletePolicy.error)} />
      </div>
    )
  }

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <RowColLayout rowProps={{ classNames: 'mt-2' }}>
          <ControlledTextField
            name="name"
            required={true}
            translator={t}
            textFieldProps={{
              label: t('POLICIES.FORM.NAME'),
              placeholder: t('USERS.FORM.ENTER_TEXT'),
            }}
          />
        </RowColLayout>
        <RowColLayout rowProps={{ classNames: 'mt-2 mb-2' }}>
          <MultilineTextField
            name="description"
            required={false}
            translator={t}
            textFieldProps={{
              label: t('POLICIES.FORM.DESCRIPTION'),
              placeholder: t('USERS.FORM.ENTER_TEXT'),
            }}
          />
        </RowColLayout>
        <RowColLayout rowProps={{ classNames: 'mt-2' }}>
          <Dropdown
            name="product"
            required={true}
            translator={t}
            dropdownProps={{
              defaultSelectedKey: props.data?.product,
              options: dropdownOptionsProducts,
              label: t('POLICIES.FORM.PRODUCT_LABEL'),
              placeholder: t('POLICIES.FORM.PRODUCT_PLACEHOLDER'),
            }}
          />
        </RowColLayout>

        <RowColLayout rowProps={{ classNames: 'mt-2' }}>
          <ControlledToggleButton
            name="accessAllUsers"
            toggleProps={{
              inlineLabel: true,
              label: t('POLICIES.FORM.ACCESS_ALL_USERS'),
              onText: t('general:Yes'),
              offText: t('general:No'),
            }}
          />
        </RowColLayout>

        <ActionButtons isEdit={isEdit} onDelete={onDelete} />
      </form>
      {renderDeleteConfirmation()}
    </FormProvider>
  )
}

const ActionButtons = ({ isEdit, onDelete }: { isEdit: boolean; onDelete: () => void }) => {
  const { t, i18n } = useTranslation()

  if (isEdit) {
    return (
      <FormActionsContainer isEdit>
        <DeleteActionButton locale={i18n.language} onClick={onDelete} />
        <FormActions
          submitProps={{
            text: t('general:BUTTON.SAVE'),
            iconProps: { iconName: 'Save' },
          }}
        />
      </FormActionsContainer>
    )
  }

  return (
    <FormActionsContainer>
      <FormActions
        submitProps={{
          text: t('general:BUTTON.CREATE'),
          iconProps: { iconName: 'Add' },
        }}
      />
    </FormActionsContainer>
  )
}
