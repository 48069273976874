import { ViewDetailsActionButton } from '@flexxibleit/flexxible-ui'
import { Module, ProductConfig } from '../../../query-client/types'
import {
  ColumnActionsMode,
  DetailsListLayoutMode,
  IColumn,
  IStackTokens,
  Link,
  SelectionMode,
  Separator,
  Stack,
  Text,
} from '@fluentui/react'
import { useTranslation } from 'react-i18next'
import { getProductIcon } from '../../../services/product-icon.service'
import './home.feature.scss'
import { getModuleIcon } from '../../../services/module-icon.service'
import { ModuleTypes } from '../../organization-details/modules/module-form/types'
import { withFeatureRender } from '../../../permissions/withFeatureRender'
import { FEATURE_NAMES } from '../../../permissions/FeatureName.enum'
import FlexxibleCard from 'app/components/FlexxibleCard/FlexxibleCard.component'
import { Accordion } from 'app/components/accordion/Accordion.component'
import useMediaQuery from 'app/hooks/utils/useMediaQuery'
import { MOBILE_QUERY, TABLET_QUERY } from 'app/config/media-query'
import Table from 'app/components/Table/Table.component'
import { NavigateFunction, useNavigate } from 'react-router-dom'
import { useMemo } from 'react'
import EmptyStatus from 'app/components/status/empty-status'

interface Props {
  products: ProductConfig[]
  modules: Module[]
  selectedOrganizationId: string
  canSeeDownload: boolean
}

const modulesTokens: IStackTokens = {
  childrenGap: 20,
}

const handleClick = (url?: string) => {
  window.open(url, '_blank')
}

const handleClickDownload = (navigate: NavigateFunction, selectedOrganizationId: string) => {
  navigate(`/organization/${selectedOrganizationId}/reporting-groups`)
}

const getModuleType = (moduleType: string): string => {
  return ModuleTypes.find((type: any) => (type.key as string) === moduleType)?.text || ''
}

const H1Card = ({ module, index }: { module: Partial<Module>; index: number }) => {
  const [isMobile] = useMediaQuery(MOBILE_QUERY)
  const [isTablet] = useMediaQuery(TABLET_QUERY)

  const cardProps = {
    style: isMobile
      ? { width: '100%' }
      : isTablet
      ? { width: 'calc(28% - 10px' }
      : { width: 'calc(20% - 50px)', minWidth: 152 },
    onClick: () => handleClick(module.url),
    key: `${index}`,
  }

  return (
    <>
      <FlexxibleCard key={index} styleNames="d-flex d-flexCol d-flexJustifyCenter cursor-pointer" cardProps={cardProps}>
        <div className="d-flex d-center d-flexCol modules__card-link__container">
          <div>{getModuleIcon(module.type || '', 38, 38)}</div>
          <div className="modules__card-link__type">
            {module.type === 'custom' ? module.name : getModuleType(module.type || '')}
          </div>
          <div className="modules__card-link__tag">{module.tag}</div>
        </div>
      </FlexxibleCard>
    </>
  )
}

const H1CardDownload = ({
  t,
  navigate,
  selectedOrganizationId,
}: {
  t: Function
  navigate: NavigateFunction
  selectedOrganizationId: string
}) => {
  const [isMobile] = useMediaQuery(MOBILE_QUERY)
  const [isTablet] = useMediaQuery(TABLET_QUERY)

  const cardProps = {
    style: isMobile
      ? { width: '100%' }
      : isTablet
      ? { width: 'calc(28% - 10px' }
      : { width: 'calc(20% - 50px)', minWidth: 152 },
    onClick: () => handleClickDownload(navigate, selectedOrganizationId),
  }

  return (
    <>
      <FlexxibleCard styleNames="d-flex d-flexCol d-flexJustifyCenter cursor-pointer" cardProps={cardProps}>
        <div className="d-flex d-center d-flexCol modules__card-link__container">
          <div>{getModuleIcon(`download` || '', 38, 38)}</div>
          <div className="modules__card-link__type">{t('home:DOWNLOAD')}</div>
        </div>
      </FlexxibleCard>
    </>
  )
}

const H2Card = ({ module, index }: { module: Partial<Module>; index: number }) => {
  return (
    <>
      <Stack horizontal key={`${index}`}>
        <Stack
          tokens={{ childrenGap: 5 }}
          horizontal
          verticalAlign="center"
          style={{ cursor: 'pointer' }}
          onClick={() => handleClick(module.url)}
        >
          <Stack.Item style={{ display: 'flex' }}>{getModuleIcon(module.type || '', 24, 24)}</Stack.Item>
          <Stack.Item
            style={{ fontWeight: 'bold', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}
          >
            {module.type === 'custom' ? module.name : getModuleType(module.type || '')}
          </Stack.Item>
          <Stack.Item style={{ fontSize: 12 }}>{module.tag} </Stack.Item>
        </Stack>
      </Stack>
    </>
  )
}

const Home = ({ products, modules, selectedOrganizationId, canSeeDownload }: Props) => {
  const { t, i18n } = useTranslation('organization_details')
  const [isMobile] = useMediaQuery(MOBILE_QUERY)
  const navigate = useNavigate()

  const visibility1List = useMemo(
    () => modules.filter((module) => module.visibility === 1).sort((a, b) => a.order - b.order),
    [modules]
  )
  const visibility2List = useMemo(
    () => modules.filter((module) => module.visibility === 2).sort((a, b) => a.order - b.order),
    [modules]
  )

  const AccordionHeader = () => {
    return (
      <Stack.Item grow>
        <Separator styles={{ content: { backgroundColor: '#faf9f8', paddingLeft: 0 } }} alignContent="start">
          {t('home:VIEW_MORE') + ' (' + visibility2List.length + ')'}
        </Separator>
      </Stack.Item>
    )
  }

  const openViewProduct = (product: ProductConfig) => {
    navigate(`/products/${product._id}`)
  }

  const columns: IColumn[] = [
    {
      key: 'environment',
      name: t('PRODUCTS.TABLE.ENVIRONMENT'),
      fieldName: 'environment',
      minWidth: isMobile ? 50 : 180,
      isRowHeader: true,
      columnActionsMode: ColumnActionsMode.disabled,
      onRender: (item: ProductConfig) => {
        return <Link onClick={() => openViewProduct(item)}>{item.environment}</Link>
      },
    },
    {
      key: 'type',
      name: t('PRODUCTS.TABLE.PRODUCT_TYPE'),
      fieldName: 'product',
      minWidth: 250,
      isRowHeader: true,
      isCollapsible: true,
      onRender: (item: ProductConfig) => {
        return (
          <span className="d-flex d-flexAlignItemsCenter">
            <span className="d-flex d-flexAlignItemsCenter" style={{ marginRight: 5 }}>
              {getProductIcon(item.product.name, 18, 18)}
            </span>
            <span>{item.product.name}</span>
          </span>
        )
      },
      columnActionsMode: ColumnActionsMode.disabled,
    },
    {
      key: 'actions',
      name: t('PRODUCTS.TABLE.ACTION'),
      minWidth: isMobile ? 50 : 180,
      onRender: (item: ProductConfig) => {
        return (
          <ViewDetailsActionButton locale={i18n.language} isMobile={isMobile} onClick={() => openViewProduct(item)} />
        )
      },
      columnActionsMode: ColumnActionsMode.disabled,
    },
  ]

  return (
    <>
      {visibility1List.length > 0 && (
        <div className="d-flex d-flexRow d-flex d-flexWrap" style={{ gap: 20, marginTop: 20 }}>
          {visibility1List.map((module, index) => (
            <H1Card module={module} index={index} key={module._id} />
          ))}
          {canSeeDownload && (
            <>
              <H1CardDownload t={t} navigate={navigate} selectedOrganizationId={selectedOrganizationId} />
            </>
          )}
        </div>
      )}

      {visibility2List.length > 0 && (
        <div style={{ marginTop: 41 }}>
          <Accordion headerContent={<AccordionHeader />} opened={false}>
            <Stack tokens={modulesTokens} horizontal style={{ width: '100%', padding: '5px 0 15px' }} wrap>
              {visibility2List.map((module, index) => (
                <Stack.Item
                  key={`${index}`}
                  style={
                    !isMobile
                      ? { minWidth: 'calc(33.33% - 10px)', maxWidth: 'calc(33.33% - 10px)' }
                      : { minWidth: 'calc(50% - 10px)', maxWidth: 'calc(50% - 10px)' }
                  }
                >
                  <H2Card module={module} index={index} />
                </Stack.Item>
              ))}
            </Stack>
          </Accordion>
        </div>
      )}

      {products.length > 0 && (
        <>
          <Stack style={{ paddingTop: 20 }}>
            <Text style={{ fontSize: 15, fontWeight: 600 }}>{t('home:YOUR_PRODUCTS')}</Text>
          </Stack>

          <div style={{ marginTop: 21 }}>
            <Table
              tableId="home"
              tableProps={{
                columns,
                items: products,
                compact: false,
                selectionMode: SelectionMode.none,
                layoutMode: DetailsListLayoutMode.justified,
                isHeaderVisible: true,
              }}
            />
          </div>
        </>
      )}

      {products.length === 0 && (
        <div style={{ marginTop: isMobile ? 40 : 140 }}>
          <EmptyStatus message={t('home:EMPTY_LIST')} />
        </div>
      )}
    </>
  )
}

export const HomeFeature = withFeatureRender(Home, FEATURE_NAMES.HOME_READ)
