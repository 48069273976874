import React from 'react'
import Highcharts from 'highcharts/highstock'
import { dashboard } from '../../summary/MockData'
import { useTranslation } from 'react-i18next'
import { DashboardChartCard } from '../../../../components/Dashboard/DashboardChartCard/DashboardChartCard'
import { ColumnChart } from '../../../../components/Charts/ColumnChart/ColumnChart'

interface Props {
  totalApprovedPatchesByPatchPolicy: {
    patchPolicy: string,
    value: number
  }[] | [],
  totalNotApprovedPatchesByPatchPolicy: {
    patchPolicy: string,
    value: number
  }[] | [],
}

export const PatchesStatusByPatchPolicyColumnChart = (props: Props) => {
  const { t } = useTranslation('patch_management')

  const series: Highcharts.SeriesColumnOptions[] = [
    {
      type: 'column',
      name: t('DASHBOARD.TABS.PATCHES_TAB.COLUMN_CHART.APPROVED'),
      color: 'green',
      data: props.totalApprovedPatchesByPatchPolicy.map((item) => item.value),
    },
    {
      type: 'column',
      name: t('DASHBOARD.TABS.PATCHES_TAB.COLUMN_CHART.NOT_APPROVED'),
      color: 'red',
      data: props.totalNotApprovedPatchesByPatchPolicy.map((item) => item.value),
    }
  ]

  const xAxisCategories = props.totalApprovedPatchesByPatchPolicy.map((item) => item.patchPolicy);

  return (
    <DashboardChartCard
      title={t('DASHBOARD.TABS.PATCHES_TAB.COLUMN_CHART.TITLE')}
      tooltip={t('DASHBOARD.TABS.PATCHES_TAB.COLUMN_CHART.TOOLTIP')}
    >
      <ColumnChart series={series} categories={xAxisCategories} />
    </DashboardChartCard>
  )
}
