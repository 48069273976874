import { PaginatedTable } from '@flexxibleit/flexxible-ui'
import { useTranslation } from 'react-i18next'
import { PaginatedEligibleProduct } from '../../../query-client/types'
import useGetProductTypes from 'app/hooks/product/useGetProductTypes'
import { useOrgEligibleProductsFilters } from './useOrgEligibleProductsFilters'
import usePaginatedData from 'app/hooks/usePaginatedData'
import { usePaginatedOrgEligibleProductsQuery } from './usePaginatedOrgEligibleProductsQuery'
import { useOrgEligibleProductsActions } from './useOrgEligibleProductsActions'
import { useOrgEligibleProductsBuildTable } from './useOrgEligibleProductsBuildTable'
import { EmptyOrgEligibleProducts } from './EmptyOrgEligibleProducts'
import { useOrgEligibleProductsBuildExport } from './useOrgEligibleProductsBuildExport'
import { useTableExport } from 'app/hooks/utils/useTableExport'
import useGetBusinessEligibleProducts from 'app/hooks/elegibleProducts/useGetBusinessEligibleProducts'

interface Props {
  organizationId: string
}

export const ElegibleProductsFeature = ({ organizationId }: Props) => {
  const { t, i18n } = useTranslation('organization_details')
  const { data: products } = useGetProductTypes(true)

  // Request data
  const { filterFields } = useOrgEligibleProductsFilters({ t })
  const { query, queryName } = usePaginatedOrgEligibleProductsQuery()
  const { data: paginatedProducts, isFetching } = usePaginatedData<PaginatedEligibleProduct>(
    query,
    queryName,
    filterFields,
    {
      businessId: organizationId,
    }
  )

  // Export
  const { exportColumns } = useOrgEligibleProductsBuildExport()
  const { refetch: refetchEligibleProducts } = useGetBusinessEligibleProducts(organizationId)
  const { onExportClicked } = useTableExport({
    query: refetchEligibleProducts,
    columns: exportColumns,
    fileName: 'Eligible Products',
  })

  // Actions and columns
  const { onAddElegibleProductClicked, openUpdateElegibleProductForm, commandBarActions } =
    useOrgEligibleProductsActions({
      t,
      organizationId,
      eligibleProducts: paginatedProducts?.results || [],
      onExportClicked,
    })
  const { columns } = useOrgEligibleProductsBuildTable({
    t,
    i18n,
    products: products || [],
    openUpdateElegibleProductForm,
  })

  return (
    <PaginatedTable
      tableId="elegible-products"
      locale={i18n.language}
      paginatedData={paginatedProducts}
      columns={columns}
      filters={{
        filterFields,
        smartSearch: true,
        advancedSearch: true,
      }}
      commands={commandBarActions}
      isFetching={isFetching}
      emptyInitialLoadContent={
        <EmptyOrgEligibleProducts t={t} onAddElegibleProductClicked={onAddElegibleProductClicked} />
      }
    />
  )
}
