import React, { PropsWithChildren, Suspense } from 'react'
import { createRoot } from 'react-dom/client'
import './index.scss'
import reportWebVitals from './reportWebVitals'
import { unstable_HistoryRouter as BrowserRouter } from 'react-router-dom'
import { initializeIcons, registerIcons, ThemeProvider } from '@fluentui/react'
import { theme } from './app/config/theme'
import './app/i18n/i18n'
import { IcoFlexx, IcoFlexxWhite } from './app/components/Icons'
import { QueryClientProvider } from '@tanstack/react-query'
import queryClient from './app/query-client/queryClient'
import 'flexboxgrid/dist/flexboxgrid.min.css'
import { Router } from './app/routing/Router'
import { Auth0Provider } from '@auth0/auth0-react'
import history from './app/routing/history'
import { GlobalConfigProvider, useGlobalConfig } from 'app/globals/context/GlobalConfigContext'
import { DoptUsersProvider } from '@dopt/react-users'
import { LinuxLogo32Icon } from '@fluentui/react-icons-mdl2'
import { AnalyzerSidebarIcon } from './app/analyzer/components/icons/AnalyzerSidebarIcon'

initializeIcons()
registerIcons({
  icons: {
    IcoFlexx: <IcoFlexx />,
    IcoFlexxWhite: <IcoFlexxWhite />,
    LinuxLogo: <LinuxLogo32Icon />,
    AnalyzerSidebar: <AnalyzerSidebarIcon />,
  },
})

const container = document.getElementById('root') as HTMLElement
const root = createRoot(container!)

const Auth0: React.FC<PropsWithChildren> = ({ children }) => {
  const globalConfig = useGlobalConfig()
  return (
    <Auth0Provider
      domain={globalConfig?.auth0Domain ?? ''}
      clientId={globalConfig?.auth0ClientId ?? ''}
      legacySameSiteCookie
      authorizationParams={{ redirect_uri: window.location.origin, prompt: 'select_account' }}
    >
      {children}
    </Auth0Provider>
  )
}
const DoptUsers: React.FC<PropsWithChildren> = ({ children }) => {
  const globalConfig = useGlobalConfig()
  return <DoptUsersProvider apiKey={globalConfig?.doptUsersApiKey ?? ''}>{children}</DoptUsersProvider>
}

root.render(
  <ThemeProvider theme={theme}>
    <Suspense fallback="loading">
      <BrowserRouter history={history}>
        <QueryClientProvider client={queryClient}>
          <GlobalConfigProvider>
            <DoptUsers>
              <Auth0>
                <Router />
                {/*<ReactQueryDevtools initialIsOpen={false} />*/}
              </Auth0>
            </DoptUsers>
          </GlobalConfigProvider>
        </QueryClientProvider>
      </BrowserRouter>
    </Suspense>
  </ThemeProvider>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
