import { RowColLayout } from '@flexxibleit/flexxible-ui'
import { TFunction } from 'i18next'
import { WrapOverwriteAgentSettingsForm } from './WrapOverwriteAgentSettingsForm'
import { ResponsiveMode } from '@fluentui/react'
import Dropdown from 'app/components/forms/Dropdown'

interface Props {
  overridable?: boolean
  name: string
  label: string
  tooltip: string
  isOverwrite?: boolean
  t: TFunction
  optionsDropdown: { key: string; text: string }[]
  defaultSelectedKey?: string
  placeholder: string
  disabled?: boolean
  callbackOverwrite?: () => void
}

const AgentSettingsDropdown = ({
  t,
  overridable = false,
  name,
  label,
  placeholder,
  tooltip,
  isOverwrite = true,
  optionsDropdown,
  defaultSelectedKey,
  disabled = false,
  callbackOverwrite,
}: Props) => {
  const child = (
    <RowColLayout rowProps={{ classNames: 'mt-2' }}>
      <Dropdown
        name={name}
        translator={t}
        dropdownProps={{
          responsiveMode: ResponsiveMode.large,
          label: label,
          placeholder: placeholder,
          options: optionsDropdown,
          defaultSelectedKey: defaultSelectedKey,
          disabled: (overridable && !isOverwrite) || disabled,
        }}
        tooltip={tooltip}
      />
    </RowColLayout>
  )
  return overridable ? (
    <WrapOverwriteAgentSettingsForm
      onClick={() => callbackOverwrite && callbackOverwrite()}
      overWritable={!isOverwrite}
      disabled={disabled}
    >
      {child}
    </WrapOverwriteAgentSettingsForm>
  ) : (
    child
  )
}

export default AgentSettingsDropdown
