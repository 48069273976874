import { useTranslation } from "react-i18next";

interface Props {
    tam: string
}



const IconLanguage = (props: Props) =>{
  const { t } = useTranslation('general');
  const toolTipText = t('MULTILANGUAGE.TOOLTIP');

  return (
  
    <svg className="icon-language" version="1.0" xmlns="http://www.w3.org/2000/svg"
    width={`${props.tam}pt`} height={`${props.tam}pt`} viewBox="0 0 64.000000 64.000000"
    preserveAspectRatio="xMidYMid meet">

        <g transform="translate(0.000000,64.000000) scale(0.100000,-0.100000)"
        fill="#000000" stroke="none">
            <path d="M315 478 c-28 -16 -35 -28 -35 -65 l0 -33 -94 0 c-90 0 -96 -1 -115
            -26 -38 -49 -24 -185 21 -199 13 -5 18 -16 18 -39 0 -18 3 -35 6 -39 3 -3 34
            12 69 34 43 27 73 39 99 39 55 0 75 15 82 60 8 51 27 52 97 5 28 -19 55 -35
            59 -35 5 0 8 15 8 33 0 25 7 39 30 55 29 22 30 25 30 102 0 73 -2 80 -26 99
            -23 18 -40 21 -128 21 -56 0 -110 -6 -121 -12z m249 -30 c34 -48 12 -178 -29
            -178 -8 0 -15 -13 -17 -35 l-3 -34 -55 34 c-30 19 -63 35 -72 35 -13 0 -18 8
            -18 29 0 35 -35 81 -61 81 -24 0 -26 45 -2 72 14 15 32 18 129 18 105 0 114
            -1 128 -22z m-236 -94 c19 -13 22 -24 22 -89 0 -88 -12 -105 -74 -105 -30 0
            -56 -9 -89 -30 -26 -16 -50 -30 -52 -30 -3 0 -5 14 -5 30 0 17 -5 30 -12 30
            -6 0 -20 7 -30 17 -14 13 -18 31 -18 89 0 97 10 104 140 104 70 0 101 -4 118
            -16z"/>
            <path d="M427 417 c-3 -9 -16 -17 -29 -20 -15 -3 -9 -5 15 -6 26 -1 37 -5 34
            -13 -2 -7 -15 -12 -28 -10 -22 2 -23 2 -6 -11 15 -13 16 -16 2 -27 -21 -18
            -11 -24 13 -9 17 10 23 10 36 -1 9 -7 19 -10 23 -7 3 4 -5 15 -17 25 l-23 19
            21 21 c21 21 21 22 2 22 -10 0 -23 7 -28 16 -8 14 -10 14 -15 1z"/>
            <path d="M175 270 c-16 -33 -26 -62 -23 -66 4 -3 9 4 13 15 9 30 81 30 90 0 4
            -11 9 -18 13 -15 6 7 -48 126 -58 126 -3 0 -19 -27 -35 -60z m55 -2 c0 -5 -9
            -8 -20 -8 -18 0 -19 3 -9 21 9 17 13 19 20 8 5 -8 9 -17 9 -21z"/>
        </g>
        <title>{toolTipText}</title> 

    </svg>
  );
}


  
  export default IconLanguage;
  