import { gql } from 'graphql-request'
import graphClient from '../../query-client/graphClient'
import { useMutation, useQueryClient } from '@tanstack/react-query'

const approveWindowsPatchesRequest = gql`
  mutation Request($windowsPatchPolicyId: String!, $patches: [String!]!) {
    approveWindowsPatchesV2(windowsPatchPolicyId: $windowsPatchPolicyId, patches: $patches)
  }
`

const approveWindowsPatches = async ({
  windowsPatchPolicyId,
  patches,
}: {
  windowsPatchPolicyId: string
  patches: string[]
}) => {
  const response = await graphClient.request(approveWindowsPatchesRequest, {
    windowsPatchPolicyId,
    patches,
  })

  return response.approveWindowsPatches
}

export const useApproveWindowsPatches = () => {
  const queryClient = useQueryClient()

  return useMutation(approveWindowsPatches, {
    onSuccess: async () => {
      await queryClient.invalidateQueries(['getWindowsPatchesV2'])
    },
  })
}
