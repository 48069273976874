import {
  ControlledTextField,
  DeleteActionButton,
  Dialog,
  FormActionsContainer,
  LoadingSpinner,
  RowColLayout,
} from '@flexxibleit/flexxible-ui'
import { zodResolver } from '@hookform/resolvers/zod'
import { FormProvider, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import * as z from 'zod'
import FormActions from '../../../../components/forms/FormActions'
import { useEffect, useState } from 'react'
import SuccessStatus from '../../../../components/status/success-status'
import ErrorStatus from '../../../../components/status/error-status'
import { useBoolean } from '@fluentui/react-hooks'
import { useFormSidebarContext } from 'app/components/forms/FormSidebar/state/FormSidebarState'
import useGetWindowsProducts from 'app/hooks/patchPolicies/useGetWindowsProducts'
import useGetWindowsPatchCategories from 'app/hooks/patchPolicies/useGetWindowsPatchCategories'
import Dropdown from 'app/components/forms/Dropdown'
import { CompactPeoplePicker } from 'app/components/forms/PeoplePicker/CompactPeoplePicker'
import { useUpdateWindowsPatchPolicy } from 'app/hooks/patchPolicies/useUpdateWindowsPatchPolicy'
import { WindowsPatchPolicy } from 'app/query-client/types'


export interface AutomaticApprovalClassification {
  id: string
  title: string
}

export interface AutomaticApprovalProducts {
  id: string
  title: string
}

interface DropdownOptions {
  key: string
  text?: string
}

export interface CreateAutomaticPatchFormType {
  classification: string
  products: string[]
  deadlineAfterRelease: number
  index: number
}

export interface AutomaticPatchFormType {
  classification: AutomaticApprovalClassification
  products: AutomaticApprovalProducts[]
  deadlineAfterRelease: number
  index: number
}

export interface CreateOrUpdatePatchPolicyFormProps {
  windowsPatchPolicy: WindowsPatchPolicy
  automaticApproval?: AutomaticPatchFormType
}

const schema: z.ZodType<Partial<CreateAutomaticPatchFormType>> = z.object({
  classification: z.string({
    required_error: 'required',
  }),
  products: z.array(z.string()).nonempty(),
  deadlineAfterRelease: z
    .custom<number>()
    .refine((value) => Number(value) >= 0, 'min')
    .transform((value) => Number(value)),
})

export const CreateAutomaticPatchForm = ({
  windowsPatchPolicy,
  automaticApproval,
}: CreateOrUpdatePatchPolicyFormProps) => {
  const { t } = useTranslation('patch_management')
  const createAutomaticApproval = useUpdateWindowsPatchPolicy()
  const updateAutomaticApproval = useUpdateWindowsPatchPolicy()
  const deleteAutomaticApproval = useUpdateWindowsPatchPolicy()
  const [hideDeleteDialog, { toggle: toggleHideDeleteDialog }] = useBoolean(true)
  const { closeSidebar } = useFormSidebarContext()
  const [isSuccess, setIsSuccess] = useState(false)
  const [isError, setIsError] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [selectedClassification, setSelectedClassification] = useState<string | null>(null)
  const [productFilteredOptions, setProductFilteredOptions] = useState<DropdownOptions[]>([])
  const [selectedProductList, setSelectedProductList] = useState<string[]>(
    automaticApproval?.products?.map((product) => product.id) ?? []
  )
  const { data: productList } = useGetWindowsProducts()
  const { data: categoryList } = useGetWindowsPatchCategories()

  const productOptions = [
    ...(productList ? productList.map((product) => ({ key: product.id, text: product.title })) : []),
  ]
  const categoryOptions = [
    ...(categoryList ? categoryList.map((category) => ({ key: category.id, text: category.title })) : []),
  ]

  useEffect(() => {
    if (automaticApproval?.classification) {
      classificationChangeHandler({ key: automaticApproval.classification.id })
    }
  }, [automaticApproval])

  useEffect(() => {
    setIsSuccess(
      createAutomaticApproval.isSuccess || updateAutomaticApproval.isSuccess || deleteAutomaticApproval.isSuccess
    )
    setIsError(createAutomaticApproval.isError || updateAutomaticApproval.isError || deleteAutomaticApproval.isError)
    setIsLoading(
      createAutomaticApproval.isLoading || updateAutomaticApproval.isLoading || deleteAutomaticApproval.isLoading
    )
  }, [createAutomaticApproval, updateAutomaticApproval, deleteAutomaticApproval])

  const methods = useForm<CreateAutomaticPatchFormType>({
    resolver: zodResolver(schema),
    defaultValues: automaticApproval
      ? ({
          ...automaticApproval,
          classification: automaticApproval.classification?.id,
          products: selectedProductList,
        } as CreateAutomaticPatchFormType)
      : {},
  })

  const handleSubmit = (data: CreateAutomaticPatchFormType) => {
    setSelectedProductList(data.products)
    if (automaticApproval) {
      const newAutomaticApprovals = windowsPatchPolicy?.automaticApprovals
      newAutomaticApprovals?.splice(automaticApproval.index, 1)
      newAutomaticApprovals?.splice(automaticApproval.index, 0, data)
      try {
        updateAutomaticApproval.mutate({
          id: windowsPatchPolicy._id!,
          input: {
            name: windowsPatchPolicy.name,
            automaticApprovals: newAutomaticApprovals,
          },
        })
      } catch (e) {
        console.error(e)
      }
    } else {
      const newAutomaticApprovals = windowsPatchPolicy?.automaticApprovals ?? []
      newAutomaticApprovals.push(data)
      try {
        createAutomaticApproval.mutate({
          id: windowsPatchPolicy._id!,
          input: {
            name: windowsPatchPolicy.name,
            automaticApprovals: newAutomaticApprovals,
          },
        })
      } catch (e) {
        console.error(e)
      }
    }
  }

  const onDelete = () => {
    toggleHideDeleteDialog()
  }

  const renderDeleteConfirmation = (): JSX.Element => {
    return (
      <Dialog
        title={t('DELETE_CONFIRMATION.TITLE')}
        description={t('DELETE_CONFIRMATION.MESSAGE')}
        actionButton={t('DELETE_CONFIRMATION.BUTTON_ACCEPT')}
        dismissButton={t('DELETE_CONFIRMATION.BUTTON_CANCEL')}
        hidden={hideDeleteDialog}
        onDismiss={toggleHideDeleteDialog}
        callback={() => {
          toggleHideDeleteDialog()
          handleDeleteAutomaticApproval()
        }}
      />
    )
  }
  const handleDeleteAutomaticApproval = () => {
    if (automaticApproval) {
      const newAutomaticApprovals = windowsPatchPolicy?.automaticApprovals
      newAutomaticApprovals?.splice(automaticApproval?.index, 1)
      try {
        deleteAutomaticApproval.mutate({
          id: windowsPatchPolicy._id!,
          input: {
            name: windowsPatchPolicy.name,
            automaticApprovals: newAutomaticApprovals,
          },
        })
        closeSidebar()
      } catch (e) {
        console.error(e)
      }
    }
  }

  const classificationChangeHandler = (e: DropdownOptions) => {
    setSelectedClassification(e.key)
    const forbidenProducts: string[] = []
    windowsPatchPolicy.automaticApprovals.forEach((approval, index) => {
      if (approval.classification === e.key && index !== automaticApproval?.index) {
        approval.products.forEach((p: string) => {
          forbidenProducts.push(p)
        })
      }
    })
    const newProductOptions = productOptions.filter((product) => !forbidenProducts.includes(product.key))
    setProductFilteredOptions(newProductOptions)
  }

  if (isLoading) {
    return <LoadingSpinner />
  }

  if (isSuccess || isError) {
    return (
      <>
        {createAutomaticApproval.isSuccess && (
          <SuccessStatus message={t('PATCH_POLICIES.FORM.CREATE_AUTO_APPROVAL_SUCCESS')} />
        )}

        {updateAutomaticApproval.isSuccess && (
          <SuccessStatus message={t('PATCH_POLICIES.FORM.UPDATE_AUTO_APPROVAL_SUCCESS')} />
        )}

        {createAutomaticApproval.isError && (
          <>
            <ErrorStatus message={t('PATCH_POLICIES.FORM.CREATE_AUTO_APPROVAL_ERROR')} />
          </>
        )}
        {updateAutomaticApproval.isError && (
          <>
            <ErrorStatus message={t('PATCH_POLICIES.FORM.UPDATE_AUTO_APPROVAL_ERROR')} />
          </>
        )}

        {deleteAutomaticApproval.isError && (
          <ErrorStatus message={t('PATCH_POLICIES.FORM.DELETE_AUTO_APPROVAL_ERROR')} />
        )}
      </>
    )
  }

  return (
    <>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(handleSubmit)}>
          <RowColLayout rowProps={{ classNames: 'mt-2' }}>
            <Dropdown
              name="classification"
              required={true}
              translator={t}
              onChange={classificationChangeHandler}
              dropdownProps={{
                selectedKey: automaticApproval?.classification?.id,
                options: categoryOptions,
                label: t('PATCH_POLICIES.FORM.CATEGORIES'),
                placeholder: t('PATCH_POLICIES.FORM.CATEGORIES_PLACEHOLDER'),
              }}
            />
          </RowColLayout>
          <RowColLayout rowProps={{ classNames: 'mt-2' }}>
            <CompactPeoplePicker
              name="products"
              label={t('PATCH_POLICIES.FORM.PRODUCTS')}
              placeholder={t('PATCH_POLICIES.FORM.PRODUCTS_PLACEHOLDER')}
              options={productFilteredOptions}
              translator={t}
              peoplePickerProps={{
                disabled: !selectedClassification,
                pickerCalloutProps: { calloutWidth: 430 }
              }}
              selectedList={selectedProductList}
            />
          </RowColLayout>
          <RowColLayout rowProps={{ classNames: 'mt-2' }}>
            <ControlledTextField
              name="deadlineAfterRelease"
              translator={t}
              textFieldProps={{
                type: 'number',
                label: t('PATCH_POLICIES.FORM.DEADLINEAFTERRELEASE'),
                placeholder: t('PATCH_POLICIES.FORM.DEADLINEAFTERRELEASE_PLACEHOLDER'),
                suffix: t('PATCH_POLICIES.FORM.DAYS'),
                min: 0,
              }}
            />
          </RowColLayout>

          <ActionButtons isEdit={!!automaticApproval} onDelete={onDelete} />
        </form>
        {renderDeleteConfirmation()}
      </FormProvider>
    </>
  )
}

const ActionButtons = ({ isEdit, onDelete }: { isEdit: boolean; onDelete: () => void }) => {
  const { t, i18n } = useTranslation()

  if (isEdit) {
    return (
      <FormActionsContainer isEdit>
        <DeleteActionButton locale={i18n.language} onClick={onDelete} />
        <FormActions
          submitProps={{
            text: t('general:BUTTON.SAVE'),
            iconProps: { iconName: 'Save' },
          }}
        />
      </FormActionsContainer>
    )
  }

  return (
    <FormActionsContainer>
      <FormActions
        submitProps={{
          text: t('general:BUTTON.CREATE'),
          iconProps: { iconName: 'Add' },
        }}
      />
    </FormActionsContainer>
  )
}
