import { useMutation, useQueryClient } from '@tanstack/react-query'
import graphClient from 'app/query-client/graphClient'
import { gql } from 'graphql-request'

interface DeletePolicyReportingGroupRequest {
  organizationId: string
  replaceWith: string
}

interface Response {
  deletePolicyReportingGroup: {
    _id: string
  }
}

const mutation = gql`
  mutation Request($id: ID!, $input: DeletePolicyReportingGroupGraphqlRequest!) {
    deletePolicyReportingGroup(id: $id, input: $input) {
      _id
    }
  }
`

const deletePolicyReportingGroup = async (
  id: string,
  policyId: string,
  input: DeletePolicyReportingGroupRequest
): Promise<string> => {
  await graphClient.request(mutation, {
    id: id,
    input,
  })

  return policyId
}

export const useDeletePolicyReportingGroup = () => {
  const queryClient = useQueryClient()

  return useMutation(
    ['deletePolicyReportingGroup'],
    ({ id, policyId, input }: { id: string; policyId: string; input: DeletePolicyReportingGroupRequest }) =>
      deletePolicyReportingGroup(id, policyId, input),
    {
      onSuccess: (id) => {
        queryClient.invalidateQueries(['getTenantPolicy', id])
      },
    }
  )
}
