import { useQuery } from '@tanstack/react-query'
import { WorkspaceGroupWithWorkspaceCount } from '../../query-client/types'
import { gql } from 'graphql-request'
import graphClient from '../../query-client/graphClient'

const workspaceGroupsByBusinessQuery = gql`
  query ($businessId: ID!) {
    getWorkspaceGroupsByBusiness(business_id: $businessId) {
      workspaceGroup {
        _id
        name
        manuallyAddedWorkspaces
        type
      }
      workspaceCount
    }
  }
`

function getWorkspaceGroupsByBusiness(businessId: string): Promise<WorkspaceGroupWithWorkspaceCount[]> {
  return graphClient
    .request(workspaceGroupsByBusinessQuery, { businessId })
    .then(response => response.getWorkspaceGroupsByBusiness)
}

export default function(businessId: string) {
  return useQuery(['getWorkspaceGroupsByBusiness'], () => getWorkspaceGroupsByBusiness(businessId), {
    refetchOnWindowFocus: false
  })
}
