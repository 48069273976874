import { EditButton } from '@flexxibleit/flexxible-ui'
import FlexxibleCard from 'app/components/FlexxibleCard/FlexxibleCard.component'
import { useFeedbackMessage } from 'app/context/feedback-message/FeedbackMessageContext'
import { FlowDetail } from 'app/hooks/flows/useGetFlow'
import { useUpdateFlowNotification } from 'app/hooks/flows/useUpdateFlowNotification'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useFlowTabStore } from '../store'
import { FlowNotificationDisplay } from './FlowNotificationDisplay'
import { FormNotificationData, FlowNotificationForm } from './FlowNotificationForm'
import { FeatureRender } from 'app/permissions/FeatureRender'
import { FEATURE_NAMES } from 'app/permissions/FeatureName.enum'

interface Props {
  flow: FlowDetail
}

export const FlowNotification = ({ flow }: Props) => {
  const { t, i18n } = useTranslation('flows')
  const { mutateAsync } = useUpdateFlowNotification()
  const { setSuccessMessage, setErrorMessage } = useFeedbackMessage()
  const setState = useFlowTabStore((state) => state.setNotification)
  const state = useFlowTabStore((state) => state.notification)
  const [editing, setEditing] = useState(Boolean(state))

  useEffect(() => {
    if (!editing) {
      return setState(undefined)
    }

    setState({
      initText: flow.initText || [],
      okText: flow.okText || [],
      koText: flow.koText || [],
      enabled: flow.initText && flow.okText && flow.koText ? true : false,
    })
  }, [editing])

  const onSubmit = (data: FormNotificationData) => {
    const request = data.enabled
      ? {
          initText: data.initText,
          okText: data.okText,
          koText: data.koText,
          language: i18n.language,
        }
      : { language: i18n.language }

    mutateAsync({
      id: flow.id,
      input: request,
    })
      .then(() => {
        setEditing(false)
        setSuccessMessage(t('EDITOR.SUCCESS'))
      })
      .catch(() => {
        setErrorMessage(t('EDITOR.ERROR'))
      })
  }

  return (
    <div className="d-flex d-flexCol col-xs-12">
      <div>
        <FlexxibleCard
          cardProps={{
            style: { marginTop: 36 },
          }}
          styleNames="d-flex d-flexCol d-flexJustifyBetween"
        >
          <FeatureRender feature={FEATURE_NAMES.FLOWS_UPDATE}>
            {!editing && (
              <div className="d-flex d-flexAlignItemsEnd d-flexRowReverse">
                <EditButton onClick={() => setEditing(true)} style={{ marginLeft: '20px' }} />
              </div>
            )}
          </FeatureRender>
          {editing ? (
            <FlowNotificationForm flow={flow} onSubmit={onSubmit} onCancel={() => setEditing(false)} />
          ) : (
            <FlowNotificationDisplay flow={flow} />
          )}
        </FlexxibleCard>
      </div>
    </div>
  )
}
