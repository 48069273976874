export const bashKeywords = [
  // Bash commands
  'alias',
  'echo',
  'printf',
  'read',
  'eval',
  'exit',
  'export',
  'unset',
  'set',
  'printenv',
  'source',
  'trap',
  'exec',
  'shopt',
  'hash',
  'pwd',
  'cd',
  'ls',
  'touch',
  'cp',
  'mv',
  'rm',
  'mkdir',
  'rmdir',
  'chmod',
  'chown',
  'find',
  'grep',
  'sed',
  'awk',
  'sort',
  'cut',
  'tar',
  'gzip',
  'gunzip',
  'zip',
  'unzip',
  'wget',
  'curl',
  'mount',
  'umount',
  'fsck',
  'kill',
  'ps',
  'top',
  'df',
  'du',
  'who',
  'uname',
  'sleep',
  'uptime',
  'ifconfig',
  'ping',
  'netstat',
  'ssh',
  'scp',
  'rsync',
  'ab',
  'bash',
  'beep',
  'cat',
  'cc',
  'chroot',
  'clear',
  'diff',
  'gawk',
  'gcc',
  'get',
  'git',
  'hg',
  'killall',
  'ln',
  'make',
  'openssl',
  'nc',
  'node',
  'npm',
  'restart',
  'service',
  'sh',
  'shred',
  'start',
  'stop',
  'su',
  'sudo',
  'svn',
  'tee',
  'telnet',
  'vi',
  'vim',
  'wall',
  'wc',
  'write',
  'yes',
  'zsh',

  // Bash keywords
  'if',
  'then',
  'else',
  'elif',
  'fi',
  'case',
  'esac',
  'for',
  'select',
  'while',
  'until',
  'do',
  'done',
  'in',
  'function',
  'time',
  'coproc',
  'return',
  'break',
  'continue',
]
