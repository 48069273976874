import { useTranslation } from 'react-i18next'
import { ProductConfig } from '../../query-client/types'
import { FEATURE_NAMES } from '../../permissions/FeatureName.enum'
import { withFeatureRender } from '../../permissions/withFeatureRender'
import { ProductsFeature } from '../organization-details/products/Products.feature'
import PageBody from 'app/components/PageBody/PageBody.component'
import { useOrganizationSelection } from '../../globals/useOrganizationSelection'
import { useOrganizationByIdForAllRoles } from '../../hooks/organization/useOrganizationByIdForAllRoles'

const Products = () => {
  const { selectedOrganizationId } = useOrganizationSelection()
  const { data, isLoading, isFetching } = useOrganizationByIdForAllRoles(selectedOrganizationId || '')
  const { t } = useTranslation('products')

  const filteredProducts: ProductConfig[] = data?.productConfigs || []

  return (
    <PageBody title={t('TITLE')} titleTag={data?.name} isLoading={isLoading || isFetching}>
      <ProductsFeature data={filteredProducts} organizationId={selectedOrganizationId} />
    </PageBody>
  )
}

export const ProductsPage = withFeatureRender(Products, FEATURE_NAMES.ENVIRONMENT_READ)
