import { useMutation, useQueryClient } from '@tanstack/react-query'
import graphClient from 'app/query-client/graphClient'
import { gql } from 'graphql-request'

interface CreateTenantRequest {
  name: string
  productId?: string
  region?: string
  productConfigId?: string
  analyzer?: string
  policyId?: string
  organizationId: string
  organizationEmail: string
  organizationLanguage: string
  organizationCountry: string
  organizationIndustry: string
}

const mutation = gql`
  mutation CreateTenant($input: CreateTenantGraphqlRequest!) {
    createTenant(input: $input) {
      _id
    }
  }
`

interface CreateTenantResponse {
  createTenant: {
    _id: string
  }
}

async function createTenant(tenant: CreateTenantRequest) {
  const { createTenant }: CreateTenantResponse = await graphClient.request(mutation, { input: tenant })

  return createTenant
}

export default function () {
  const queryClient = useQueryClient()

  return useMutation(['createTenant'], (tenant: CreateTenantRequest) => createTenant(tenant), {
    onSuccess: () => {
      queryClient.invalidateQueries(['tenant'])
      queryClient.invalidateQueries(['business'])
      queryClient.invalidateQueries(['getBusinessById'])
    },
  })
}
