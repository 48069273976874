import { gql } from 'graphql-request'
import { ApiKey, Role } from '../../query-client/types'
import graphClient from '../../query-client/graphClient'
import { useMutation, useQueryClient } from '@tanstack/react-query'

interface GenerateApiKeyInput {
  role: Role
}

const generateApiKeyQuery = gql`
mutation($input: GenerateApiKeyInput!, $businessId: String!) {
  generateApiKey(input: $input, businessId: $businessId) {
    _id
    token
  }
}
`

const generateApiKey = (organizationId: string, input: GenerateApiKeyInput): Promise<ApiKey> => {
  return graphClient
    .request(generateApiKeyQuery, { input, businessId: organizationId })
    .then((response) => response.generateApiKey)
}

export const useGenerateApiKey = (organizationId: string) => {
  const queryClient = useQueryClient()

  return useMutation(
    ['generateApiKey'],
    (input: GenerateApiKeyInput) => generateApiKey(organizationId, input),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['getApiKeysByBusiness', organizationId])
      }
    }
  )
}
