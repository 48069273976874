import { pageInfoBlock, paginatedVarsBlock } from 'app/config/pagination'
import { gql } from 'graphql-request'

export const usePaginatedProductsConfigQuery = () => {
  const queryName = 'getPaginatedProductsConfig'

  const query = gql`
	query Request(
		$businessId: ID!
		$page: Int!
    $perPage: Int!
    $searchTerm: String!
    $filters: ProductConfigFilter!
    $sorting: ProductConfigSorting!
	) {
		getPaginatedProductsConfig(
			businessId: $businessId
			${paginatedVarsBlock}
		) {
			data {
				_id
				active
				baseline
				created_at
				environment
				license
				lastReportDate
				serviceNowRelatedEnvironment
				product {
					_id
					name
					uniqueCode
					active
					fixedBaseline
				}
				region
				reports {
					_id
					date
				}
				agentSettings {
					autoUpdateAgents
					resourcesReportRecurrence
					profileStorageReportRecurrence
					syncBrokerRecurrence
					detectNewCitrixSubscriptions
					collectEventLogs
					eventLogIds
					eventLogsRecurrence
					collectDisks
					collectServices
					collectPnPEvents
					collectPublicIP
					anyDeskSystemActionsRole
					remoteSupport
				}
			}
			${pageInfoBlock}
		}
	}
`
  return {
    query,
    queryName,
  }
}
