import { OrganizationDailyOperationsView } from '../../../hooks/efficiencyOperations/useViewOrganizationOperationsHistory'
import { DateFormat, dateFormatter } from '../../../services/date-formatter'
import { DateTime, Interval } from 'luxon'
import { TFunction } from 'i18next'
import { chartColors } from '../../../components/chart/ChartColors.constants'
import { get } from 'lodash'

type DailyOperationsCounters = {
  successCounter: number
  warningCounter: number
  failedCounter: number
}

const aggregateTotalOperations = (operations: OrganizationDailyOperationsView[] | undefined) => {
  let totalCounter = 0
  operations?.forEach((operation) => {
    totalCounter += operation.successCounter
    totalCounter += operation.warningCounter
    totalCounter += operation.failedCounter
  })

  return totalCounter
}

const aggregateOperationsByStatus = (operations: OrganizationDailyOperationsView[] | undefined) => {
  let successCounter = 0
  let warningCounter = 0
  let failedCounter = 0
  operations?.forEach((operation) => {
    successCounter += operation.successCounter
    warningCounter += operation.warningCounter
    failedCounter += operation.failedCounter
  })

  return { successCounter, warningCounter, failedCounter }
}

const aggregateOperationsByType = (operations: OrganizationDailyOperationsView[] | undefined) => {
  let auto = 0
  let manual = 0
  let microservice = 0
  operations?.forEach((operation) => {
    auto += operation.auto.successCounter + operation.auto.warningCounter + operation.auto.failedCounter
    manual += operation.manual.successCounter + operation.manual.warningCounter + operation.manual.failedCounter
    microservice +=
      operation.endUserMicroservice.successCounter +
      operation.endUserMicroservice.warningCounter +
      operation.endUserMicroservice.failedCounter
  })

  return { auto, manual, microservice }
}

const getNameProperty = (seriesName: string, t: TFunction) => {
  switch (seriesName) {
    case t('MANUAL_TYPE'):
    case t('AUTOMATED_SUPPORT_OPERATIONS'):
      return 'manual'
    case t('AUTO_TYPE'):
    case t('FLOW_AUTOMATION'):
      return 'auto'
    case t('END_USER_MICROSERVICE_TYPE'):
    case t('END_USER_SELF_SERVICE'):
      return 'endUserMicroservice'
    default:
      return ''
  }
}

const getDisplayName = (type: string, t: TFunction) => {
  switch (type) {
    case 'manual':
      return t('AUTOMATED_SUPPORT_OPERATIONS')
    case 'auto':
    case 'automatic':
      return t('FLOW_AUTOMATION')
    case 'endUserMicroservice':
      return t('END_USER_SELF_SERVICE')
    default:
      return ''
  }
}

function* days(interval: Interval) {
  let cursor = interval.start?.startOf('day')
  while (cursor < interval.end) {
    yield cursor
    cursor = cursor.plus({ days: 1 })
  }
}

const getCounters = (
  report: OrganizationDailyOperationsView | undefined,
  property: keyof DailyOperationsCounters,
  seriesName: string,
  t: TFunction
) => {
  return report
    ? (
        report[getNameProperty(seriesName, t) as keyof OrganizationDailyOperationsView] as {
          successCounter: number
          warningCounter: number
          failedCounter: number
        }
      )[property] || 0
    : 0
}

const formatOperationsColumnChart = (
  seriesName: string,
  operations: OrganizationDailyOperationsView[] | undefined,
  t: TFunction,
  interval: Interval
) => {
  if (!operations) return []
  const operationsMap: { [date: string]: OrganizationDailyOperationsView } = {}

  operations?.forEach((operation) => {
    const reportDate = dateFormatter(t, new Date(operation.date), DateFormat.MonthYear)
    operationsMap[reportDate] = operation
  })

  return Array.from(days(interval)).map((day: DateTime) => {
    const formattedDate = dateFormatter(t, day.toJSDate(), DateFormat.MonthYear)
    const report: OrganizationDailyOperationsView | undefined = operationsMap[formattedDate]

    const successCounter = getCounters(report, 'successCounter', seriesName, t)
    const warningCounter = getCounters(report, 'warningCounter', seriesName, t)
    const failedCounter = getCounters(report, 'failedCounter', seriesName, t)

    return [successCounter + warningCounter + failedCounter, day, seriesName]
  })
}

const formatPieChartOperationStatusSeries = (
  operations: OrganizationDailyOperationsView[] | undefined,
  t: TFunction,
  filtersState: any
) => {
  const counters = aggregateOperationsByStatus(operations)

  return [
    {
      name: t('SUCCESS_STATUS'),
      category: 'success',
      y: counters.successCounter,
      color: chartColors[1],
      active: filtersState.success,
    },
    {
      name: t('WARNING_STATUS'),
      category: 'warning',
      y: counters.warningCounter,
      color: chartColors[0],
      active: filtersState.warning,
    },
    {
      name: t('FAILED_STATUS'),
      category: 'failed',
      y: counters.failedCounter,
      color: chartColors[3],
      active: filtersState.failed,
    },
  ]
}

const formatPieChartOperationTypeSeries = (
  operations: OrganizationDailyOperationsView[] | undefined,
  t: TFunction,
  filtersState: any
) => {
  const types = aggregateOperationsByType(operations)

  return [
    {
      name: getDisplayName('automatic', t),
      category: 'automatic',
      y: types.auto,
      color: chartColors[5],
      active: filtersState.automatic,
    },
    {
      name: getDisplayName('manual', t),
      category: 'manual',
      y: types.manual,
      color: chartColors[4],
      active: filtersState.manual,
    },
    {
      name: getDisplayName('endUserMicroservice', t),
      category: 'endUserMicroservice',
      y: types.microservice,
      color: chartColors[6],
      active: filtersState.endUserMicroservice,
    },
  ]
}

export {
  aggregateTotalOperations,
  formatPieChartOperationStatusSeries,
  formatPieChartOperationTypeSeries,
  formatOperationsColumnChart,
  getDisplayName,
}
