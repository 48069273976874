import { useTranslation } from 'react-i18next'
import { Business } from '../../../query-client/types'
import './Information.scss'
import useCreateForm from '../../../hooks/useCreateForm'
import BusinessSummary from '../components/Summary/Summary'
import FlexxibleCard from 'app/components/FlexxibleCard/FlexxibleCard.component'
import EditButton from 'app/components/Buttons/EditButton.component'
import useMediaQuery from 'app/hooks/utils/useMediaQuery'
import { MOBILE_QUERY, TABLET_QUERY } from 'app/config/media-query'
import InformationPanel from '../components/InformationPanel/InformationPanel'
import OrganizationForm from 'app/pages/organizations/organization-list/OrganizationForm'
import { FeatureRender } from '../../../permissions/FeatureRender'
import { FEATURE_NAMES } from '../../../permissions/FeatureName.enum'

interface Props {
  organization: Business
}

const BusinessInformation = ({ organization }: Props) => {
  const { t } = useTranslation('organizations')
  const [isMobile] = useMediaQuery(MOBILE_QUERY)
  const [isTablet] = useMediaQuery(TABLET_QUERY)

  const openCreateOrganizationForm = useCreateForm(
    {
      title: t('FORM.EDIT_TITLE'),
      isFooterAtBottom: true,
    },
    <OrganizationForm organization={organization} />
  )

  const cardProps = {
    style: { marginTop: 30 },
  }

  return (
    <>
      <div
        className={isMobile || isTablet ? 'd-flex d-flexCol d-flexColReverse' : 'd-flex d-flexRow col-xs-12'}
        style={isMobile || isTablet ? { gap: 0 } : { gap: 30, paddingRight: 20 }}
      >
        <FlexxibleCard cardProps={cardProps} styleNames="col-md-9 d-flex d-flexCol d-flexJustifyBetween">
          <InformationPanel business={organization} />

          <div className="d-flex d-flexRowReverse">
            <FeatureRender feature={FEATURE_NAMES.ORGANIZATION_UPDATE}>
              <EditButton onClick={openCreateOrganizationForm} />
            </FeatureRender>
          </div>
        </FlexxibleCard>

        <div className="col-md-3 summary-container d-flexJustifyCenter d-flexAlignItemsCenter">
          <BusinessSummary business={organization} />
        </div>
      </div>
    </>
  )
}

export default BusinessInformation
