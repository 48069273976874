import { FormActionsContainer, LoadingSpinner } from '@flexxibleit/flexxible-ui'
import { DeleteActionButton } from '@flexxibleit/flexxible-ui'
import { useTranslation } from 'react-i18next'
import { IDropdownOption, ResponsiveMode } from '@fluentui/react'
import { useBoolean } from '@fluentui/react-hooks'
import FormActions from '../../../components/forms/FormActions'
import { ElegibleProduct, PaginatedEligibleProduct, Product, Region } from '../../../query-client/types'
import { FormProvider, useForm } from 'react-hook-form'
import { useEffect, useState } from 'react'
import SuccessStatus from '../../../components/status/success-status'
import ErrorStatus from '../../../components/status/error-status'
import Dialog from '../../../components/Dialog/Dialog'
import * as z from 'zod'
import { zodResolver } from '@hookform/resolvers/zod'
import Dropdown from 'app/components/forms/Dropdown'
import RowColLayout from 'app/components/Layouts/RowColLayout'
import useGetProductTypes from 'app/hooks/product/useGetProductTypes'
import useDropdownOptions from 'app/components/hooks/useDropdownOptions'
import useDeleteElegibleProduct from 'app/hooks/elegibleProducts/useDeleteElegibleProduct'
import useCreateElegibleProduct from 'app/hooks/elegibleProducts/useCreateElegibleProduct'
import Multiselect from 'app/components/forms/multiselect/multiselect'
import useUpdateElegibleProduct from 'app/hooks/elegibleProducts/useUpdateElegibleProduct'
import { FeatureRender } from '../../../permissions/FeatureRender'
import { FEATURE_NAMES } from '../../../permissions/FeatureName.enum'

export interface CreateElegibleProductFormType {
  name: string
  deploymentRegions: string[] | null
}

export interface CreateOrUpdateUserFormProps {
  elegibleProduct?: PaginatedEligibleProduct
  organizationId: string
  isCreate?: boolean
  allElegibleProducts: PaginatedEligibleProduct[]
}

const schema: z.ZodType<Partial<CreateElegibleProductFormType>> = z.object({
  name: z.string({
    required_error: 'required',
  }),
  deploymentRegions: z.array(z.string()).nullable(),
})

const ElegibleProductsForm = ({
  allElegibleProducts,
  elegibleProduct,
  organizationId,
  isCreate,
}: CreateOrUpdateUserFormProps) => {
  const { data, isFetching: productsLoading } = useGetProductTypes(true)
  let dropdownOptions: IDropdownOption[] = useDropdownOptions(data, '_id', 'name')

  const { t, i18n } = useTranslation('organization_details')

  const [hideDialog, { toggle: toggleHideDialog }] = useBoolean(true)
  const [deploymentRegionsOptions, setDeploymentRegionsOptions] = useState<IDropdownOption[]>([])

  const methods = useForm<CreateElegibleProductFormType>({
    resolver: zodResolver(schema),
    defaultValues: elegibleProduct
      ? {
          name: elegibleProduct._id,
          deploymentRegions: elegibleProduct.regions,
        }
      : {},
  })

  const createElegibleProduct = useCreateElegibleProduct(organizationId || '')
  const updateElegibleProduct = useUpdateElegibleProduct(organizationId || '')
  const deleteElegibleProduct = useDeleteElegibleProduct()

  const [isSuccess, setIsSuccess] = useState(false)
  const [isError, setIsError] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    setIsSuccess(createElegibleProduct.isSuccess || deleteElegibleProduct.isSuccess || updateElegibleProduct.isSuccess)
    setIsError(createElegibleProduct.isError || deleteElegibleProduct.isError || updateElegibleProduct.isError)
    setIsLoading(
      createElegibleProduct.isLoading ||
        deleteElegibleProduct.isLoading ||
        updateElegibleProduct.isLoading ||
        productsLoading
    )
  }, [createElegibleProduct, deleteElegibleProduct, updateElegibleProduct])

  const handleSubmit = (data: CreateElegibleProductFormType) => {
    if (!elegibleProduct) {
      if (organizationId) {
        const payload = {
          elegibleProductId: data.name,
          deploymentRegions: data.deploymentRegions,
        }
        createElegibleProduct.mutate(payload)
      }
    } else {
      updateElegibleProduct.mutate({ elegibleProductId: data.name, deploymentRegions: data.deploymentRegions })
    }
  }

  const nameWatch = methods.watch('name')
  useEffect(() => {
    const isDisabled = (region: Region) => {
      const code = region.deploymentCode
      for (const product of allElegibleProducts) {
        if (product.regions.includes(code) && product._id !== nameWatch) {
          return true
        }
      }

      return false
    }

    const regions = data?.filter((p) => p._id === nameWatch)[0]?.regions || []
    setDeploymentRegionsOptions(
      regions?.map((region) => ({
        key: region.deploymentCode,
        text: `${region.name} (${region.deploymentCode})`,
        disabled: isDisabled(region),
      }))
    )
  }, [nameWatch, data])

  return (
    <>
      {!isLoading && !isSuccess && !isError && (
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(handleSubmit)}>
            <RowColLayout rowProps={{ classNames: 'mt-2' }}>
              <Dropdown
                name="name"
                required={true}
                translator={t}
                dropdownProps={{
                  responsiveMode: ResponsiveMode.large,
                  defaultSelectedKey: elegibleProduct?._id,
                  label: t('ELEGIBLE_PRODUCTS.FORM.NAME'),
                  placeholder: t('ELEGIBLE_PRODUCTS.FORM.NAME_PLACEHOLDER'),
                  options: dropdownOptions,
                  disabled: !isCreate,
                }}
              />
            </RowColLayout>
            <RowColLayout rowProps={{ classNames: 'mt-2' }}>
              <Multiselect
                name="deploymentRegions"
                options={deploymentRegionsOptions}
                translator={t}
                multiselectProps={{
                  label: t('ELEGIBLE_PRODUCTS.FORM.DEPLOYMENT_REGIONS'),
                  placeholder: t('ELEGIBLE_PRODUCTS.FORM.DEPLOYMENT_REGIONS_PLACEHOLDER'),
                  required: true,
                  disabled: !nameWatch,
                }}
                selectedList={isCreate ? [] : elegibleProduct?.regions?.map((r) => r)}
              />
            </RowColLayout>

            <FormActionsContainer isEdit={!!elegibleProduct}>
              {elegibleProduct ? (
                <>
                  <FeatureRender feature={FEATURE_NAMES.ELIGIBLE_PRODUCT_DELETE}>
                    <DeleteActionButton locale={i18n.language} onClick={toggleHideDialog} />
                  </FeatureRender>
                  <FormActions
                    submitProps={{
                      text: t('general:BUTTON.SAVE'),
                      iconProps: { iconName: 'Save' },
                    }}
                  />
                </>
              ) : (
                <FormActions
                  submitProps={{
                    text: t('general:BUTTON.CREATE'),
                    iconProps: { iconName: 'Add' },
                  }}
                />
              )}
            </FormActionsContainer>
          </form>
        </FormProvider>
      )}

      {isLoading && <LoadingSpinner></LoadingSpinner>}

      {(isSuccess || isError) && (
        <>
          {createElegibleProduct.isSuccess && <SuccessStatus message={t('ELEGIBLE_PRODUCTS.FORM.ADD_SUCCESS')} />}
          {updateElegibleProduct.isSuccess && <SuccessStatus message={t('ELEGIBLE_PRODUCTS.FORM.UPDATE_SUCCESS')} />}

          {createElegibleProduct.isError && (
            <>
              {(createElegibleProduct.error as any).response.errors[0].message.includes('Duplicated entity') ? (
                <ErrorStatus message={t('ELEGIBLE_PRODUCTS.FORM.PRODUCT_ALREADY_EXIST')} />
              ) : (createElegibleProduct.error as any).response.errors[0].message.includes(
                  'Deployment region already used'
                ) ? (
                <ErrorStatus message={t('ELEGIBLE_PRODUCTS.FORM.REGION_ALREADY_EXIST')} />
              ) : (
                <ErrorStatus message={t('ELEGIBLE_PRODUCTS.FORM.ADD_ERROR')} />
              )}
            </>
          )}

          {updateElegibleProduct.isError && <ErrorStatus message={t('ELEGIBLE_PRODUCTS.FORM.UPDATE_ERROR')} />}

          {deleteElegibleProduct.isSuccess && <SuccessStatus message={t('ELEGIBLE_PRODUCTS.FORM.DELETE_SUCCESS')} />}

          {deleteElegibleProduct.isError && <ErrorStatus message={t('ELEGIBLE_PRODUCTS.FORM.DELETE_ERROR')} />}
        </>
      )}

      <Dialog
        title={t('ELEGIBLE_PRODUCTS.FORM.CONFIRMATION_TITLE')}
        description={t('ELEGIBLE_PRODUCTS.FORM.CONFIRMATION_MESSAGE')}
        actionButton={t('general:BUTTON.DELETE')}
        dismissButton={t('general:BUTTON.CANCEL')}
        callback={() => {
          if (elegibleProduct) {
            toggleHideDialog()
            deleteElegibleProduct.mutate({
              elegibleProductId: elegibleProduct._id,
              organizationId: organizationId,
            })
          }
        }}
        hidden={hideDialog}
        onDismiss={toggleHideDialog}
      />
    </>
  )
}

export default ElegibleProductsForm
