import { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { MOBILE_QUERY, useMediaQuery } from '@flexxibleit/flexxible-ui'
import { MessageBar} from '@fluentui/react'
import {
  DashboardMetricCard,
} from '../../../components/Dashboard/DashboardMetricCard/DashboardMetricCard'
import { SecurityPatchesStatus } from './components/SecurityPatchesStatus'
import { SecurityMissingPatches } from './components/SecurityMissingPatches'
import useGetPatchesBySeverityAndStatus from '../../../hooks/patches/useGetPatchesBySeverityAndStatus'
import { PatchesBySeverityAndStatusCount } from '../../../query-client/types'
import { Dashboard } from "../../../components/Dashboard/Dashboard";

interface Props {
  organizationId: string | undefined
}

export const SecurityPoliciesDashboardFeature = (props: Props) => {
  const { t } = useTranslation('patch_management')
  const [isMobile] = useMediaQuery(MOBILE_QUERY);
  // const navigate = useNavigate();
  // const location = useLocation();
  const [securityPolicies, setSecurityPolicies] = useState< PatchesBySeverityAndStatusCount[] | []>([])

  const {
    data: securityPatchesData,
    isLoading
  } = useGetPatchesBySeverityAndStatus(props.organizationId);

  useEffect(() => {
    if(!isLoading && securityPatchesData) {
      setSecurityPolicies(securityPatchesData);
    }
  }, [securityPatchesData])

  // const workspacesWithPendingSecurityPatches = dashboard.totalWorkspacesWithPendingSecurityPatches;
  //
  // const action = (): DashboardCardAction | undefined => {
  //   if (!workspacesWithPendingSecurityPatches) {
  //     return undefined
  //   }
  //
  //   return {
  //     onClick: () => {
  //       const currentPath = location.pathname;
  //       const newPath = currentPath.replace('security', 'workspaces');
  //       navigate(newPath);
  //     },
  //     label: 'See vulnerable workspaces',
  //   }
  // }

  const getTotalPatches = (data: PatchesBySeverityAndStatusCount[]) => {
    return data.reduce((acc, patches) => { return acc + patches.value; }, 0)
  }

  return (
    <Dashboard>
      <MessageBar messageBarType={0} isMultiline={true} className="mb-2" style={{ width: '100%' }}>
        {t('DASHBOARD.SECURITY.INFORMATION_BAR')}
      </MessageBar>
      {/*{ workspacesWithPendingSecurityPatches && (*/}
      {/*  <MessageBar messageBarType={3} isMultiline={true} className="mb-2" style={{ width: '100%' }}>*/}
      {/*    {t('DASHBOARD.SECURITY.ALERT_BAR')}*/}
      {/*  </MessageBar>*/}
      {/*)}*/}
      <div
        style={{
          display: 'flex',
          alignItems: 'stretch',
          justifyContent: 'center',
          gap: 20,
          flexWrap: 'wrap',
          marginTop: '20px',
        }}
      >
        <DashboardMetricCard
          title={t('DASHBOARD.SECURITY.TOTAL_SECURITY_PATCHES')}
          value={getTotalPatches(securityPolicies)}
          icon={{
            name: 'ShieldSolid',
          }}
          tooltip={t('DASHBOARD.SECURITY.TOTAL_SECURITY_PATCHES_TOOLTIP')}
        />
        {/*<DashboardMetricCard*/}
        {/*  title={t('DASHBOARD.SECURITY.TOTAL_SECURITY_WORKSPACES')}*/}
        {/*  value={workspacesWithPendingSecurityPatches}*/}
        {/*  icon={{*/}
        {/*    name: 'ShieldAlert',*/}
        {/*    color: 'orange',*/}
        {/*  }}*/}
        {/*  action={action()}*/}
        {/*/>*/}
      </div>
      <section
        style={{
          display: 'flex',
          flexDirection: isMobile ? 'column' : 'row',
          justifyContent: 'space-evenly',
          gap: 20,
          marginTop: '20px',
        }}
      >
        <SecurityPatchesStatus items={securityPolicies}/>
        {/*<SecurityWorkspacesStatus items={dashboard.totalWorkspacesByHealth} />*/}
      </section>
      <section
        style={{
          display: 'flex',
          flexDirection: isMobile ? 'column' : 'row',
          marginTop: '20px',
          width: '100%',
        }}
      >
        <SecurityMissingPatches/>
      </section>
    </Dashboard>
  )
}
