import {
  CreateButton,
  EmptyStatus,
  LoadingSpinner,
  MOBILE_QUERY,
  SearchBox,
  ViewDetailsActionButton,
  useMediaQuery,
} from '@flexxibleit/flexxible-ui'
import { ColumnActionsMode, DetailsListLayoutMode, IColumn, Icon, SelectionMode, Stack } from '@fluentui/react'
import PageBody from 'app/components/PageBody/PageBody.component'
import { CellWithFeatureLink } from 'app/components/Table/CellWithFeatureLink'
import Table from 'app/components/Table/Table.component'
import { useOrganizationSelection } from 'app/globals/useOrganizationSelection'
import useGetLinuxPatchPoliciesByOrganization from 'app/hooks/patchPolicies/linux-patch-policy/useGetLinuxPatchPoliciesByOrganization'
import useCreateForm from 'app/hooks/useCreateForm'
import { FEATURE_NAMES } from 'app/permissions/FeatureName.enum'
import { FC, Fragment, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import { CreateLinuxPatchPolicyForm } from './components/CreateLinuxPatchPolicyForm'
import { LinuxPatchPolicy } from 'app/query-client/types'
import { useFeatureRender } from '../../../permissions/useFeatureRender'
import { FeatureRender } from '../../../permissions/FeatureRender'
import { withFeatureRender } from '../../../permissions/withFeatureRender'

const LinuxPatchPolicies: FC = () => {
  const { t, i18n } = useTranslation('patch_management')
  const navigate = useNavigate()
  const [isMobile] = useMediaQuery(MOBILE_QUERY)
  const { organizationId } = useParams()
  const { isLoading, data } = useGetLinuxPatchPoliciesByOrganization(organizationId || '')
  const { selectedOrganization } = useOrganizationSelection()
  const [textFilter, setTextFilter] = useState<string>()
  const [items, setItems] = useState<LinuxPatchPolicy[]>([])
  const baseUrl = location.pathname.split('/')[1]
  const { checkFeature } = useFeatureRender()

  useEffect(() => {
    if (!selectedOrganization) {
      return
    }
    if (selectedOrganization._id !== organizationId) {
      navigate(`/${baseUrl}/${selectedOrganization._id}/patch-management/linux-patch-policies/`)
    }
  }, [selectedOrganization])

  const _onFilter = (ev: React.ChangeEvent<HTMLInputElement> | undefined, text?: string): void => {
    setTextFilter(text)
  }

  useEffect(() => {
    if (data && data.length > 0) {
      let filteredItems = data

      if (textFilter) {
        filteredItems = filteredItems.filter((item: any) => {
          return item.name.toLowerCase().includes(textFilter.toLowerCase())
        })
      }

      setItems(filteredItems)
    } else {
      setItems([])
    }
  }, [data, textFilter])

  const onClickViewDetails = (linuxPatchPolicyId: string) => {
    navigate(`/organization/${selectedOrganization?._id}/patch-management/linux-patch-policies/${linuxPatchPolicyId}`)
  }
  const onClickViewDetailsTarget = (patchPolicyTargetId: string) => {
    navigate(`/organization/${selectedOrganization?._id}/patch-management/patch-policy-targets/${patchPolicyTargetId}`)
  }

  const patchPoliciesColumns: IColumn[] = [
    {
      key: 'name',
      name: t('LINUX_PATCH_POLICIES.TABLE.NAME'),
      fieldName: 'name',
      minWidth: 100,
      flexGrow: 1,
      maxWidth: 600,
      isRowHeader: true,
      columnActionsMode: ColumnActionsMode.disabled,
      onRender: (item: LinuxPatchPolicy) => {
        return (
          <CellWithFeatureLink
            feature={FEATURE_NAMES.PATCH_MANAGEMENT_READ}
            callback={() => onClickViewDetails(item._id)}
          >
            {item.name}
          </CellWithFeatureLink>
        )
      },
    },
    {
      key: 'patchPolicyTargets',
      name: t('LINUX_PATCH_POLICIES.TABLE.PATCH_POLICY_TARGETS'),
      fieldName: 'patchPolicyTargets',
      minWidth: 100,
      maxWidth: 500,
      isRowHeader: true,
      columnActionsMode: ColumnActionsMode.disabled,
      onRender: (item: LinuxPatchPolicy) => {
        const patchPolicyTargets = item.patchPolicyTargets ?? []

        return (
          <span>
            {patchPolicyTargets.map((i, index) => (
              <Fragment key={i._id}>
                <CellWithFeatureLink
                  feature={FEATURE_NAMES.PATCH_MANAGEMENT_READ}
                  callback={() => onClickViewDetailsTarget(i._id)}
                >
                  {i.name}
                </CellWithFeatureLink>
                {index < patchPolicyTargets.length - 1 && ', '}
              </Fragment>
            ))}
          </span>
        )
      },
    },
    {
      key: 'actions',
      name: t('LINUX_PATCH_POLICIES.TABLE.ACTIONS'),
      minWidth: 100,
      isRowHeader: true,
      columnActionsMode: ColumnActionsMode.disabled,
      onRender: (item: LinuxPatchPolicy) => {
        return <ViewDetailsActionButton isMobile={isMobile} onClick={() => onClickViewDetails(item._id)} />
      },
    },
  ]

  const onCreate = useCreateForm(
    {
      title: t('LINUX_PATCH_POLICIES.CREATE_FORM'),
      isFooterAtBottom: true,
    },
    <CreateLinuxPatchPolicyForm />
  )

  return (
    <PageBody title={t('LINUX_PATCH_POLICIES.TITLE')} isLoading={false}>
      {isLoading ? (
        <LoadingSpinner />
      ) : !data?.length ? (
        <div style={{ marginTop: 100 }}>
          <EmptyStatus
            message={t('LINUX_PATCH_POLICIES.EMPTY_MESSAGE')}
            img={<Icon iconName="EntitlementPolicy" style={{ fontSize: 68 }} />}
            {...(checkFeature(FEATURE_NAMES.PATCH_MANAGEMENT_CREATE) && {
              buttonProps: {
                text: t('general:BUTTON.CREATE'),
                iconProps: { iconName: 'Add' },
                onClick: onCreate,
              },
            })}
          />
        </div>
      ) : (
        <>
          <Stack
            horizontal={!isMobile}
            horizontalAlign="space-between"
            tokens={{ padding: '20px 0 0 0' }}
            className="mb-sm-2"
          >
            <SearchBox
              placeholder={t('LINUX_PATCH_POLICIES.SEARCH_PLACEHOLDER')}
              onChange={_onFilter}
              style={{ width: isMobile ? '100%' : '300px' }}
            />
            <FeatureRender feature={FEATURE_NAMES.PATCH_MANAGEMENT_CREATE}>
              <CreateButton locale={i18n.language} onClick={onCreate} />
            </FeatureRender>
          </Stack>
          <Table
            tableId="linux-patch-policies"
            isEmpty={items.length === 0}
            emptyMessage={t('general:EMPTY_FILTER')}
            tableProps={{
              items: items,
              columns: patchPoliciesColumns,
              compact: false,
              selectionMode: SelectionMode.none,
              layoutMode: DetailsListLayoutMode.justified,
              isHeaderVisible: true,
            }}
          />
        </>
      )}
    </PageBody>
  )
}

export const LinuxPatchPoliciesPage = withFeatureRender(LinuxPatchPolicies, FEATURE_NAMES.PATCH_MANAGEMENT_READ)
