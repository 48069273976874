import { IButtonProps } from "@fluentui/react";
import WarningCheck from '../../assets/img/warning_icon.svg';
import StatusLayout from "./status-layout/status-layout";

export interface WarningStatusProps {
  message: string;
  buttonProps?: IButtonProps;
}

const WarningStatus = ({ message, buttonProps }: WarningStatusProps) => {
  return (
    <StatusLayout
      img={WarningCheck}
      message={message}
      buttonProps={buttonProps}
    ></StatusLayout>
  )
}

export default WarningStatus