import { Icon, PrimaryButton } from '@fluentui/react'
import { TablePageBody } from '@flexxibleit/flexxible-ui'
import React from 'react'
import { useNavigate } from 'react-router'
import { useTranslation } from 'react-i18next'
import './unauthorized.page.css'

export const UnauthorizedPage = () => {
  const navigate = useNavigate()
  const { t } = useTranslation('general')

  const goToHomePage = () => {
    navigate('/home')
  }

  return (
    <TablePageBody isLoading={false}>
      <div className="unauthorized-page-wrapper">
        <Icon iconName="AuthenticatorApp" style={{ fontSize: '10rem' }} />
        <div>
          <h2>{t('403_FORBIDDEN_TITLE')}</h2>
          <p>{t('403_FORBIDDEN_EXPLANATION')}</p>
        </div>
        <PrimaryButton onClick={goToHomePage}>{t('BUTTON.GO_HOME')}</PrimaryButton>
      </div>
    </TablePageBody>
  )
}
