import { IButtonProps } from "@fluentui/react"
import CancelFormButton from "./CancelFormButton"
import SubmitFormButton from "./SubmitFormButton"

export interface FormActionsProps {
  cancelProps?: IButtonProps;
  submitProps?: IButtonProps;
}

function FormActions({ cancelProps, submitProps }: FormActionsProps) {

  return (
    <div className="d-flex d-flexJustifyEnd">
      <CancelFormButton {...cancelProps} />
      <SubmitFormButton {...submitProps} />
    </div>
  )
}

export default FormActions
