import { Breadcrumb, IBreadcrumbItem } from '@fluentui/react';
import { ReactNode } from 'react';
import './TablePageBody.scss';
import { ContentHeader, LoadingSpinner } from '@flexxibleit/flexxible-ui';


interface Props {
  children: ReactNode;
  title: string;
  isLoading: boolean;
  titleComponent?: ReactNode;
  breadcrumbItems?: IBreadcrumbItem[] | undefined;
  titleTag?: string;
}

// This is designed for pages with specially ONE TABLE (whith nothing else below)
export const TablePageBody = ({ children, title, titleTag, titleComponent, breadcrumbItems, isLoading }: Props) => {
  return (
    <div
      // className={'page-body'}
      style={{ display: 'flex', flexDirection: 'column', height: '100vh' }}
    >
      <div
        style={{
          padding: '85px 15px 15px 15px',
          display: 'flex',
          flexDirection: 'column',
          overflow: 'hidden',
          height: '100%'
        }}
      >
        {isLoading ? (
          <LoadingSpinner></LoadingSpinner>
        ) : (
          <>
            {breadcrumbItems && <Breadcrumb items={breadcrumbItems} />}
            {titleComponent ? titleComponent : <ContentHeader title={title} titleTag={titleTag} />}
            {children}
          </>
        )}
      </div>
    </div>
  );
};
