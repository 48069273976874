import LayersIcon from '../../../../assets/img/layers-svgrepo-com.svg';
import UserMultipleIcon from '../../../../assets/img/user-multiple-svgrepo-com.svg';
import DataTableIcon from '../../../../assets/img/data-table-svgrepo-com.svg';
import BuildingIcon from '../../../../assets/img/building-business-management-svgrepo-com.svg';
import SummaryCard from '../SummaryCard/SummaryCard';
import { Business } from '../../../../query-client/types';
import { useTranslation } from 'react-i18next';
import useMediaQuery from 'app/hooks/utils/useMediaQuery';
import { MOBILE_QUERY, TABLET_QUERY } from 'app/config/media-query';
import { dateFormatter } from 'app/services/date-formatter';
import { BusinessType } from 'app/models/business/BusinessType';
import { getIcon } from 'app/services/organization-icon-service';

interface BusinessInformationPanelProps {
  business: Business;
}

function BusinessSummary({ business }: BusinessInformationPanelProps) {
  const { t } = useTranslation('organizations')
  const [ isMobile ] = useMediaQuery(MOBILE_QUERY);
  const [ isTablet ] = useMediaQuery(TABLET_QUERY);

  function getTypeText() {
    switch (business.type) {
      case BusinessType.END_CUSTOMER:
        return t('TYPE.END_CUSTOMER');
      case BusinessType.PARTNER:
        return t('TYPE.PARTNER');
      case BusinessType.WHOLESALER:
        return t('TYPE.WHOLESALER');
      default:
        return '';
    }
  }

  return (
    <>
      <div className={ isMobile || isTablet ? "d-flex d-flexRow d-flexWrap" : "d-flex d-flexCol " } style={ { gap: 20 } }>
        <SummaryCard
            Icon={ UserMultipleIcon }
            title={ t('TABLE.MEMBERS') }
            text={ `${business.users.length}` }
          />

        <SummaryCard
            Icon={ LayersIcon }
            title={ t('TABLE.PRODUCTS') }
            text={ `${business.productConfigs.length}` }
          />

        <SummaryCard
            Icon={ DataTableIcon }
            title={ t('CREATION_DATE') }
            text={ dateFormatter(t, business.created_at) }
          />

        <SummaryCard
          Icon={ BuildingIcon }
          title={ t('FORM.PARTNER') }
          text={ business.partner?.name ?? <i>{t('FORM.NO_PARTNER')}</i> }
        />

        <SummaryCard
          Icon={ getIcon(business.type) }
          title={ t('FORM.TYPE') }
          text={ getTypeText() }
        />
      </div>
    </>
  )
}

export default BusinessSummary;
