import { LoadingSpinner } from '@flexxibleit/flexxible-ui'
import { MicroservicesAuditLogTable } from './MicroservicesAuditLogTable'
import { useGetMicroserviceEvents } from '../../../hooks/microservices/useGetMicroserviceEvents'
import { useOrganizationSelection } from '../../../globals/useOrganizationSelection'

export const MicroservicesAuditLog = () => {
  const { selectedOrganizationId } = useOrganizationSelection()
  const { data: microserviceEvents, isLoading } = useGetMicroserviceEvents(selectedOrganizationId)

  if (isLoading || !microserviceEvents || !selectedOrganizationId) {
    return <LoadingSpinner />
  }

  return <MicroservicesAuditLogTable microserviceEvents={microserviceEvents} />
}
