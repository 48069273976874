import { HighchartsReact } from 'highcharts-react-official'
import Highcharts from 'highcharts/highstock'


interface Props {
  series: Highcharts.SeriesPieOptions[];
  highchartsConfig?: Partial<Highcharts.Options>;
}

export const PieChart = (props: Props) => {
  const chartOptions: Highcharts.Options = {
    chart: {
      type: 'pie',
      numberFormatter: (value: number) => value.toLocaleString(),
    },
    title: {
      text: ''
    },
    credits: {
      enabled: false,
    },
    legend: {
      enabled: true,
      align: 'right',
      layout: 'vertical',
      verticalAlign: 'middle',
      itemMarginTop: 10,
    },
    plotOptions: {
      pie: {
        allowPointSelect: true,
        cursor: 'pointer',
        dataLabels: {
          enabled: false
        },
        showInLegend: true
      }
    },
    series: props.series,
    ...props.highchartsConfig
  }

  return (
    <HighchartsReact
      highcharts={Highcharts}
      options={chartOptions}
    />
  )
}
