import { TFunction } from 'react-i18next'

export enum MicroserviceScope {
  WORKSPACE = 'WORKSPACE',
  PLATFORM = 'PLATFORM',
}

export const microserviceScopeOptions = (t: TFunction) =>
  Object.values(MicroserviceScope).map((scope) => ({
    key: scope,
    text: scopeLabel(t)[scope],
  }))

export const scopeLabel = (t: TFunction): { [key in MicroserviceScope]: string } => ({
  [MicroserviceScope.WORKSPACE]: t('MICROSERVICE_SCOPE.WORKSPACE'),
  [MicroserviceScope.PLATFORM]: t('MICROSERVICE_SCOPE.PLATFORM'),
})
