import { PRODUCT_ENV } from 'app/config/Consts'
import { MOBILE_QUERY } from 'app/config/media-query'
import { ProductEnvTypes } from 'app/config/productEnv.enum'
import useMediaQuery from 'app/hooks/utils/useMediaQuery'
import { FEATURE_NAMES } from 'app/permissions/FeatureName.enum'
import { useFeatureRender } from 'app/permissions/useFeatureRender'
import { withFeatureRender } from 'app/permissions/withFeatureRender'
import { User } from 'app/query-client/types'
import { i18n } from 'i18next'
import { useEffect, useState } from 'react'
import { TFunction } from 'react-i18next'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { ViewDetailsActionButton } from '@flexxibleit/flexxible-ui'
import { IPaginatedTableColumn } from '@flexxibleit/flexxible-ui/dist/lib/components/paginated-table/paginatedTable.types'
import { CellWithFeatureGreenLink } from 'app/components/table-page-body/CellWithFeatureGreenLink'

type UseUsersBuildTableProps = {
  t: TFunction
  i18n: i18n
  openUpdateUserForm: (user: User) => () => void
  organizationId: string
}

export const useUsersBuildTable = ({ t, i18n, openUpdateUserForm, organizationId }: UseUsersBuildTableProps) => {
  const [isMobile] = useMediaQuery(MOBILE_QUERY)
  const [columns, setColumns] = useState<IPaginatedTableColumn[]>([])
  const [searchParams] = useSearchParams()
  const navigate = useNavigate()

  const { checkFeature } = useFeatureRender()
  const tableActionsFeatures = [FEATURE_NAMES.USER_UPDATE]
  const canSeeActions = tableActionsFeatures.some((feature) => checkFeature(feature))

  const goToRoleDetails = (id: string) => {
    navigate(`/organization/${organizationId}/roles/${id}`)
  }

  useEffect(() => {
    setColumns([
      {
        key: 'name',
        name: t('USERS.TABLE.NAME'),
        fieldName: 'name',
        minWidth: 25,
        flexGrow: 2,
        onRender: (item: User) => {
          return (
            <CellWithFeatureGreenLink feature={FEATURE_NAMES.USER_UPDATE} callback={openUpdateUserForm(item)}>
              {item.name} {item.surname}
            </CellWithFeatureGreenLink>
          )
        },
      },
      {
        key: 'email',
        name: t('USERS.TABLE.EMAIL'),
        fieldName: 'email',
        minWidth: 25,
        flexGrow: 2,
      },
      {
        key: 'department',
        name: t('USERS.TABLE.DEPARTMENT'),
        fieldName: 'department',
        minWidth: 25,
        flexGrow: 1,
      },
      {
        key: 'userRole',
        name: t('USERS.TABLE.USER_ROLE'),
        fieldName: 'userRole',
        minWidth: 25,
        flexGrow: 1,
        onRender: (item: User) => {
          return (
            <CellWithFeatureGreenLink
              feature={FEATURE_NAMES.ROLE_READ}
              callback={() => goToRoleDetails(item.userRole?._id!)}
            >
              <span>{item.userRole?.name}</span>
            </CellWithFeatureGreenLink>
          )
        },
      },
      ...(canSeeActions
        ? [
            {
              key: 'actions',
              name: t('USERS.TABLE.ACTION'),
              minWidth: 25,
              flexGrow: 1,
              onRender: (item: User) => {
                return withFeatureRender(
                  ViewDetailsActionButton,
                  FEATURE_NAMES.USER_UPDATE
                )({
                  locale: i18n.language,
                  isMobile: isMobile,
                  onClick: openUpdateUserForm(item),
                })
              },
            },
          ]
        : []),
    ])
  }, [searchParams])

  const headerRenderPreviewWorkspaces = () => {
    return PRODUCT_ENV !== ProductEnvTypes.FXXONE ? (
      <div>
        <span>{t('USERS.TABLE.WORKSPACE_PERMISSION')}</span>
        <span id="titleTagPage" className={`content-header__title-tag`}>
          PREVIEW
        </span>
      </div>
    ) : (
      <span>{t('USERS.TABLE.WORKSPACE_PERMISSION')}</span>
    )
  }

  return {
    columns,
  }
}
