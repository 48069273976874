import { useMutation, useQueryClient } from '@tanstack/react-query'
import graphClient from '../../query-client/graphClient'
import { gql } from 'graphql-request'
import { ImportWorkspacesInputType } from 'app/pages/organization-details/workspace-groups/ImportWorkspacesForm'

const setWorkspacesByNameInWorkspaceGroupQuery = gql`
  mutation ($workspaceGroupId: String!, $input: ImportWorkspacesBaseInput!) {
    setWorkspacesByNameInWorkspaceGroup(workspaceGroupId: $workspaceGroupId, input: $input) {
      workspaceGroup {
        _id
      }
      successMatch
      errorMatch
      existsMatch
    }
  }
`

async function setWorkspacesByNameInWorkspaceGroup(
  workspacesList: ImportWorkspacesInputType,
  workspaceGroupId: string
) {
  return graphClient
    .request(setWorkspacesByNameInWorkspaceGroupQuery, {
      workspaceGroupId,
      input: workspacesList,
    })
    .then((response) => response.setWorkspacesByNameInWorkspaceGroup)
}

export default function (workspaceGroupId: string) {
  const queryClient = useQueryClient()

  return useMutation(
    ['setWorkspacesByNameInWorkspaceGroup'],
    (workspacesList: ImportWorkspacesInputType) =>
      setWorkspacesByNameInWorkspaceGroup(workspacesList, workspaceGroupId),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['getWorkspaceGroupsByBusiness']),
          queryClient.invalidateQueries(['getWorkspaceGroupById'])
      },
    }
  )
}
